/**
 * [Please add a description.]
 */

"use strict";

exports.__esModule = true;
// istanbul ignore next

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Hub = function Hub(file) {
  _classCallCheck(this, Hub);

  this.file = file;
};

exports["default"] = Hub;
module.exports = exports["default"];