'use strict'

module.exports = function(str, endsWith){

    str += ''

    if (!str){
        return typeof endsWith == 'string'?
                    !endsWith:
                    false
    }

    endsWith += ''

    if (str.length < endsWith.length){
        return false
    }

    return str.lastIndexOf(endsWith) == str.length - endsWith.length
}