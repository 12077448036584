const React = require('react');
const ReactDOM = require('react-dom');
const IconButOver = require('../matui/icon_but_over.jsx');
const Contstafunc = require('./cont_func.jsx');
const DateTime = require('../matui/utils/date-time');
const AlgFuncUpd = require('../functions/algemeen');

module.exports = {
  GetFixture(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetFixture',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },

  UitvSQL(hdata,url){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function='+url,
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },

  GetFixtureCons(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetFixtureCons',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },

  DelActiv(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=DelActiviteit',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },  


  UpdSubFixt(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=UpdSubFixt',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },  
  UpdSubFixtCons(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=UpdSubFixtCons',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },  


  BewaarText(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=FixtUpdTxt',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },  
  BewaarTextCons(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=FixtUpdTxtCons',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },  
  SubFixtStep(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=SubFixtStep',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },  





  DelSubFixture(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=DelSubFixture',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
  },  
  DelSubFixtureCons(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=DelSubFixtureCons',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
  },  

  DelFixture(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=DelFixture',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
  },  

  DelFixtureCons(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=DelFixtureCons',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
  },  



  CopyFixture(hdata){
        return new Promise(
            function (resolve, reject) {

                let foutdisp = {errnaam:'',errklant:'',errhigh:'',errlow:'',errstep:'', error:false};
                let fout = false;
                let Controle  = '';
               
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=CopyFixture',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      if (data.error) 
                      {
                        foutdisp.errnaam = data.errnaam;
                        reject(foutdisp);
                      }
                      else
                      {
                          resolve(data);
                      }                      
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
  },  
  CopyFixtureMy(hdata){
        return new Promise(
            function (resolve, reject) {

                let foutdisp = {errnaam:'',errklant:'',errhigh:'',errlow:'',errstep:'', error:false};
                let fout = false;
                let Controle  = '';
               
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=CopyFixtureMy',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      if (data.error) 
                      {
                        foutdisp.errnaam = data.errnaam;
                        reject(foutdisp);
                      }
                      else
                      {
                          resolve(data);
                      }                      
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
  },  

  CopyFixtureCons(hdata){
        return new Promise(
            function (resolve, reject) {

                let foutdisp = {errnaam:'',errklant:'',errhigh:'',errlow:'',errstep:'', error:false};
                let fout = false;
                let Controle  = '';
               
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=CopyFixtureCons',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      if (data.error) 
                      {
                        foutdisp.errnaam = data.errnaam;
                        reject(foutdisp);
                      }
                      else
                      {
                          resolve(data);
                      }                      
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
  },  
  Bewaarschema(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
    

            let foutdisp = {errnaam:'', error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.name, '','');
            foutdisp.errnaam = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};

            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let insql = (Sql == 'UPD') ? 'ISchema' : 'ISchema';
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function='+insql,
                data: JSON.stringify(hdata),
                success: function(data){
                  if (data.error) 
                  {
                    foutdisp.errnaam = data.errnaam;
                    reject(foutdisp);
                  }
                  else
                  {
                      resolve(data);
                  }
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    }, 
    Bewaarlibrary(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
    

            let foutdisp = {errnaam:'', error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.name, '','');
            foutdisp.errnaam = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};

            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let insql = (Sql == 'UPD') ? 'ULibrary' : 'ILibrary';
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function='+insql,
                data: JSON.stringify(hdata),
                success: function(data){
                  if (data.error) 
                  {
                    foutdisp.errnaam = data.errnaam;
                    reject(foutdisp);
                  }
                  else
                  {
                      resolve(data);
                  }
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    }, 




    CreaAdres(hdata) {
        return new Promise(
            function (resolve, reject) {
                let foutdisp = {errnaam:'',errklant:'', error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.name, '','');
            foutdisp.errnaam = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.accountid, '','');
            foutdisp.errklant = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function=CreaAdres',
                data: JSON.stringify(hdata),
                success: function(data){
                  if (data.error) 
                  {
                    foutdisp.errnaam = data.errnaam;
                    reject(foutdisp);
                  }
                  else
                  {
                      resolve(data);
                  }
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    }, 


    UpdAdres(hdata) {
        return new Promise(
            function (resolve, reject) {
                let foutdisp = {errnaam:'',errklant:'', error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.name, '','');
            foutdisp.errnaam = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.accountid, '','');
            foutdisp.errklant = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function=UpdAdres',
                data: JSON.stringify(hdata),
                success: function(data){
                  if (data.error) 
                  {
                    foutdisp.errnaam = data.errnaam;
                    reject(foutdisp);
                  }
                  else
                  {
                      resolve(data);
                  }
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    }, 

    DelAdres(hdata) {
        return new Promise(
            function (resolve, reject) {
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function=DelAdres',
                data: JSON.stringify(hdata),
                success: function(data){
                  resolve(data);
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    },     


  Bewaren(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
    

            let foutdisp = {errnaam:'',errklant:'',errhigh:'',errlow:'',errstep:'', error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.name, '','');
            foutdisp.errnaam = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let insql = (Sql == 'UPD') ? 'IFixture' : 'IFixture';
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function='+insql,
                data: JSON.stringify(hdata),
                success: function(data){
                  if (data.error) 
                  {
                    foutdisp.errnaam = data.errnaam;
                    foutdisp.errhigh = data.errhigh;
                    foutdisp.errlow = data.errlow;
                    foutdisp.errstep = data.errstep;
                    reject(foutdisp);
                  }
                  else
                  {
                      resolve(data);
                  }
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    }, 
  Bewarencons(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
    

            let foutdisp = {errnaam:'',errklant:'',errhigh:'',errlow:'',errstep:'', error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.name, '','');
            foutdisp.errnaam = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};

            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.accountid, '','');
            foutdisp.errklant = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};


            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let insql = (Sql == 'UPD') ? 'IFixtureCons' : 'IFixtureCons';
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function='+insql,
                data: JSON.stringify(hdata),
                success: function(data){
                  if (data.error) 
                  {
                    foutdisp.errnaam = data.errnaam;
                    foutdisp.errhigh = data.errhigh;
                    foutdisp.errlow = data.errlow;
                    foutdisp.errstep = data.errstep;
                    reject(foutdisp);
                  }
                  else
                  {
                      resolve(data);
                  }
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    }, 




};
