const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const Table = require('../matui/table/table.jsx');
const StylePropable = require('../matui/mixins/style-propable');
const StyleResizable = require('../matui/mixins/style-resizable');
const DataGrid = require('../datagrid/src/data-grid.jsx');
const DgFunc = require('../datagrid/src/functions.jsx');
const List = require('../matui/lists/list.jsx');
const TextField = require('../matui/text-field.jsx');
const AlgFunc = require('../pages/functions.jsx');
const Checkbox = require('../matui/checkbox.jsx');
const FontIcon = require('../matui/font-icon.jsx');

const ListItem = require('../matui/lists/list-item.jsx');
const ListDivider = require('../matui/lists/list-divider.jsx');
const Colors = require('../matui/styles/colors');
var Panel = require("react-bootstrap").Panel;
var Grid = require("react-bootstrap").Grid;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Popover = require("react-bootstrap").Popover;
var Button = require("react-bootstrap").Button;


var OverlayTrigger = require("react-bootstrap").OverlayTrigger;



const IconButton = require('../matui/icon-button.jsx');
const IconRotate = require('../matui/iconsrotate.jsx');
const FlatButton = require('../matui/flat-button.jsx');

import TinyMCE from 'react-tinymce';


module.exports = {
  _rowInhoud (index, hdata, type, editfunc, editfunc2, editfunc3, editfunc4, editfunc5, editfunc6){

    if (hdata == null) {return null};

    let vld1,vld2,vld3,vld4,vld5,vld6,vld7,vld8,vld9,vld10,vld11,vld12,vld13,vld14,vld15,vld16,vld17,vld18,vld19,vld20 = null;
    let vld21,vld22,vld23,vld24,vld25,vld26,vld27,vld28,vld29,vld30,vld31,vld32,vld33,vld34,vld35,vld36,vld37,vld38,vld39,vld40 = null;
    let pan1, pan2, pan3, pan4, pan5, pan6, pan7 = null;
    let hpan1, hpan2, hpan3, hpan4, inhoud = null;
    let icouncheck=(<FontIcon
      className="fa fa-square-o"
      style={{fontSize: '100%',marginTop: '3px'}}
    />);
    let iconcheck=(<FontIcon
      className="fa fa-check-square-o"
      style={{fontSize: '100%',marginTop: '3px'}}
    />);



    if (type == 'ActLijstConsOud')
    {
      vld1 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
              <Col xs={12} md={12} sm={12} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.primaryText}</p>
              </Col>
            </Row>);

      let nwhoog = Math.ceil((hdata.secondaryText.length / 70) * 60);
      let dhoog = nwhoog+'px';
      let subhoog = Math.ceil((hdata.secondaryText.length / 70) * 60) + 20;
      let dhoog2 = subhoog+'px';
      if (nwhoog >= 300)
      {
        dhoog = '300px';
        dhoog2 = '320px';
      }
      vld2 = (hdata.secondaryText.length > 2) ? (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
                <Col xs={12} md={12} sm={12} >
                  <TextField style={{cursor: 'pointer',width: '100%', height:dhoog}}
                    inputStyle={{fontSize:'80%', cursor: 'pointer'}}
                    tahoog={dhoog}                
                    defaultValue={hdata.secondaryText} 
                    disabled={true}  
                    fullWidth={true}
                    multiLine={true} />
                </Col>
              </Row>) : null;


      pan1 = (hdata.secondaryText.length > 2) ? (<Panel  style={{background: '#fff', overflow: 'hidden', height:dhoog2, cursor: 'pointer' }}>
                {vld2}
              </Panel>) : null;

      pan2 = (<Panel  collapsible defaultExpanded style={{background: '#fff', overflow: 'hidden', height: '128px', cursor: 'pointer' }}>
        {vld3}
        </Panel>)
    
      hpan1 = (<Row className="show-grid" >
                <Col xs={12} md={12} sm={12} >
                  {pan1}
                </Col>
              </Row>)

      inhoud = (
        <div>
          {vld1}
          {hpan1}
        </div>);
        return inhoud;
    }

    if (type == 'ActLijstCons')
    {
      if (screen.width < 750)
      {
        inhoud = (<Row style={{borderBottom: 'lightgrey', borderBottomStyle: 'solid', borderBottomWidth: '1px'}}><Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', cursor: 'pointer', overflow: 'hidden'}}>{hdata.date}</p>
        </Col>
        <Col xs={4} md={4} sm={4}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', cursor: 'pointer', overflow: 'hidden'}}>{hdata.typetxt}</p>
        </Col>
        </Row>
        <Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={11} md={11} sm={11} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', cursor: 'pointer', overflow: 'hidden'}}>{hdata.primaryText}</p>
        </Col>
        </Row></Row>);
      }
      else
      {
        inhoud = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal', paddingTop: '5px', cursor: 'pointer', overflow: 'hidden'}}>{hdata.date}</p>
        </Col>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal', paddingTop: '5px', cursor: 'pointer', overflow: 'hidden'}}>{hdata.primaryText}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal', paddingTop: '5px', cursor: 'pointer', overflow: 'hidden'}}>{hdata.usrname}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal', paddingTop: '5px', cursor: 'pointer', overflow: 'hidden'}}>{hdata.typetxt}</p>
        </Col>
        </Row>);
      }
      return inhoud;
    }





    if (type == 'GetAccAllRequestNw')
    {
      if (screen.width < 750)
      {
        inhoud = (<div style={{borderBottom: 'lightgrey', borderBottomStyle: 'solid', borderBottomWidth: '1px'}}><Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
        </Col>
        <Col xs={7} md={7} sm={7}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
        </Col>
        </Row>
        <Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={6} md={6} sm={6} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.contname}</p>
        </Col>
        <Col xs={6} md={6} sm={6}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.clienttxt}</p>
        </Col>
        </Row></div>);
      }
      else
      {
        const popoverFocus = (
          <Popover id={hdata.id}>
            <p style={{fontSize:'80%'}}>Status: {hdata.statustxt}<br/></p>
          </Popover>
        );

        vld1 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
          <Col xs={2} md={2} sm={2} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
          </Col>
          <Col xs={4} md={4} sm={4} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
          </Col>
          <Col xs={3} md={3} sm={3} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.contname}</p>
          </Col>
          <Col xs={3} md={3} sm={3} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.clienttxt}</p>
          </Col>
        </Row>);      
        pan1 = (<OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={popoverFocus}>
                {vld1}
              </OverlayTrigger>)

        inhoud = (
        <div>
          {pan1}
        </div>);
      }
      return inhoud;
    }

    if (type == 'GetContAllRequestNw')
    {
      if (screen.width < 750)
      {
        inhoud = (<div style={{borderBottom: 'lightgrey', borderBottomStyle: 'solid', borderBottomWidth: '1px'}}><Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
        </Col>
        <Col xs={7} md={7} sm={7}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
        </Col>
        </Row>
        <Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={6} md={6} sm={6} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.clienttxt}</p>
        </Col>
        <Col xs={6} md={6} sm={6}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
        </Col>
        </Row></div>);
      }
      else
      {
          inhoud = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
            <Col xs={2} md={2} sm={2} >
              <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
            </Col>
            <Col xs={4} md={4} sm={4} >
              <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
            </Col>
            <Col xs={3} md={3} sm={3} >
              <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.clienttxt}</p>
            </Col>
            <Col xs={3} md={3} sm={3} >
              <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
            </Col>
          </Row>);      
      }
      return inhoud;
    }


    if (type == 'GetConsAllRequest')
    {

      if (screen.width < 750)
      {
        inhoud = (<div style={{borderBottom: 'lightgrey', borderBottomStyle: 'solid', borderBottomWidth: '1px'}}><Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
        </Col>
        <Col xs={7} md={7} sm={7}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
        </Col>
        </Row>
        <Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={6} md={6} sm={6} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.accounttxt}</p>
        </Col>
        <Col xs={6} md={6} sm={6}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.clienttxt}</p>
        </Col>
        </Row></div>);
      }
      else
      {
        const popoverFocus = (
          <Popover id={hdata.id}>
            <p style={{fontSize:'80%'}}>Tarief: {hdata.Tarief}<br/>Status: {hdata.statustxt}<br/>Contactp: {hdata.contname}<br/></p>
          </Popover>);

        vld1 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
          <Col xs={2} md={2} sm={2} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
          </Col>
          <Col xs={4} md={4} sm={4} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
          </Col>
          <Col xs={3} md={3} sm={3} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.accounttxt}</p>
          </Col>
          <Col xs={3} md={3} sm={3} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.clienttxt}</p>
          </Col>
          </Row>);      

        pan1 = (<OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={popoverFocus}>
                {vld1}
              </OverlayTrigger>)

        inhoud = (
          <div>
            {pan1}
          </div>);
      }
      return inhoud;
    }

    if (type == 'GetAllKandReq')
    {
      let nietweerh = (<IconButton                       
                        style={{display:'block', float:'right', marginLeft: '.5em',fontSize:'80%',marginTop: '-10px'}}
                        iconClassName="fa fa-thumbs-down" 
                        tooltip="Niet weerhouden"
                        iconStyle={{color:'#008cc9', iconHoverColor:'darkslateblue',fontSize:'80%'}} 
                        eindex={index}
                        edata={hdata}
                        onTouchTap={editfunc4}/>);

      let naarcons = (<IconButton                       
                        style={{display:'block', float:'right', marginLeft: '.5em',fontSize:'80%',marginTop: '-10px'}}
                        iconClassName="fa fa-eye" 
                        tooltip="Naar Consultant"
                        iconStyle={{color:'#008cc9', iconHoverColor:'darkslateblue',fontSize:'80%'}} 
                        eindex={index}
                        edata={hdata}
                        onTouchTap={editfunc6}/>);

      let interv = (<IconButton                       
                        style={{display:'block', float:'right', marginLeft: '.5em',fontSize:'80%',marginTop: '-10px'}}
                        iconClassName="fa fa-users" 
                        tooltip="Interview"
                        iconStyle={{color:'#008cc9', iconHoverColor:'darkslateblue',fontSize:'80%'}} 
                        eindex={index}
                        edata={hdata}
                        onTouchTap={editfunc2}/>);

      let weerh = (<IconButton                       
                        style={{display:'block', float:'right', marginLeft: '.5em',fontSize:'80%',marginTop: '-10px'}}
                        iconClassName="fa fa-thumbs-up" 
                        tooltip="Mag Starten"
                        iconStyle={{color:'#008cc9', iconHoverColor:'darkslateblue',fontSize:'80%'}} 
                        eindex={index}
                        edata={hdata}
                        onTouchTap={editfunc3}/>);

      let nietmeer = (<IconButton                       
                        style={{display:'block', float:'right', marginLeft: '.5em',fontSize:'80%',marginTop: '-10px'}}
                        iconClassName="fa fa-ban" 
                        tooltip="Niet meer beschikbaar"
                        iconStyle={{color:'#008cc9', iconHoverColor:'darkslateblue',fontSize:'80%'}} 
                        eindex={index}
                        edata={hdata}
                        onTouchTap={editfunc5}/>);

     if (screen.width < 750)
      {
        inhoud = (<div style={{borderBottom: 'lightgrey', borderBottomStyle: 'solid', borderBottomWidth: '1px'}}><Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
        </Col>
        <Col xs={7} md={7} sm={7}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.consname}</p>
        </Col>
        </Row>
        <Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={8} md={8} sm={8} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Tarief}</p>
        </Col>
        <Col xs={4} md={4} sm={4}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
        </Col>
        </Row></div>);
      }
      else
      {
        inhoud = (<Row className="show-grid">
          <Col xs={2} md={2} sm={2} edata={hdata.id} onClick={editfunc}>
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
          </Col>
          <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc6}>
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.consname}</p>
          </Col>
          <Col xs={1} md={1} sm={1} edata={hdata.id} onClick={editfunc}>
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Tarief}</p>
          </Col>
          <Col xs={2} md={2} sm={2} edata={hdata.id} onClick={editfunc}>
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
          </Col>
          <Col xs={1} md={1} sm={1} >
            {nietweerh}
          </Col>
          <Col xs={1} md={1} sm={1} >
            {interv}
          </Col>
          <Col xs={1} md={1} sm={1} >
            {weerh}
          </Col>
          <Col xs={1} md={1} sm={1} >
            {nietmeer}
          </Col>
        </Row>);
      }
      return inhoud;
    }

    if (type == 'GetKandAcc')
    {
      inhoud = (screen.width < 750) ? (<Row style={{borderBottom: 'lightgrey', borderBottomStyle: 'solid', borderBottomWidth: '1px', overflow: 'hidden'}}><Row edata={hdata.id} onClick={editfunc}>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
        </Col>
        <Col xs={8} md={8} sm={8}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'bold',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
        </Col>
        </Row>
        <Row edata={hdata.id} onClick={editfunc}>
        <Col xs={4} md={4} sm={4}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Tarief}</p>
        </Col>
        <Col xs={8} md={8} sm={8}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',fontStyle:'oblique',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
        </Col>
        </Row></Row>) : (<Row className="show-grid">
          <Col xs={2} md={2} sm={2} edata={hdata.id} onClick={editfunc}>
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
          </Col>
          <Col xs={4} md={4} sm={4} edata={hdata.id} onClick={editfunc}>
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
          </Col>
          <Col xs={2} md={2} sm={2} edata={hdata.id} onClick={editfunc}>
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Tarief}</p>
          </Col>
          <Col xs={3} md={3} sm={3} edata={hdata.id} onClick={editfunc}>
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
          </Col>
        </Row>);
      return inhoud;
    }



    if (type == 'GetFaktLijstAcc')
    {
      inhoud = (hdata.crednum.length > 0 ) ? (<Row className="show-grid">
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.datum}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.faktnum}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.bedrag}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc2}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>'Creditnota : '{hdata.crednum}</p>
        </Col>
      </Row>) : 
      (hdata.nbedrag < 0 ) ? (<Row className="show-grid">
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.datum}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.faktnum}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.bedrag}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc2}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>'Creditnota van : ' {hdata.crednum}</p>
        </Col>
      </Row>) : (<Row className="show-grid">
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.datum}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.faktnum}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.bedrag}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.bnk_naam} ({hdata.betaald})</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.stortdatum}</p>
        </Col>
      </Row>)  ;
      return inhoud;
    }




    if (type == 'GetTeFakt')
    {
      inhoud = (<Row className="show-grid">
        <Col xs={10} md={10} sm={10} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.consnaam} ({hdata.klantnaam})</p>
        </Col>
      </Row>);
      return inhoud;
    }

    if (type == 'GetKandBesBlk')
    {
      inhoud = (<Row className="show-grid">
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.klantnaam}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Tarief}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
        </Col>
      </Row>);
      return inhoud;
    }


    if (type == 'GetOverzBlokContr')
    {
      inhoud = (<Row className="show-grid">
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.consname}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc2}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.klantnaam}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.enddate}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.rate}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.accmantxt}</p>
        </Col>
      </Row>);
      return inhoud;
    }




    if (type == 'GetActUser')
    {
      inhoud = (<Row className="show-grid">
        <Col xs={10} md={10} sm={10} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',paddingTop: '5px', cursor: 'pointer', overflow: 'hidden'}}>{hdata.date} - ({hdata.subject})</p>
        </Col>
      </Row>);
      return inhoud;
    }

    if (type == 'GetFakVerv')
    {
      inhoud = (<Row className="show-grid">
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vervald}</p>
        </Col>
        <Col xs={2} md={2} sm={2} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.faktnum}</p>
        </Col>
        <Col xs={4} md={4} sm={4} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.klantnaam}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.nbedrag}</p>
        </Col>

      </Row>);
      return inhoud;
    }






    if (type == 'GetKandVerw')
    {


      let mailen = (hdata.mailen == 'N') ? 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={false} label="Mail Kand"/>) : 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={true} label="Mail Kand"/>);
      inhoud = (<div>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Datum}</p>
        </Col>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.consname}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
        </Col>
        <Col xs={3} md={3} sm={3}>
          {mailen}
        </Col>
      </div>);
      return inhoud;
    }


    if (type == 'toevaanvrcons')
    {
      let mailen = (hdata.mailen == 'N') ? 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={false} label=""/>) : 
        (hdata.mailen == 'Y') ? 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={true} label=""/>) : null;

      let kennis='';
      let kennis1='';
      let kennis2 = '';
      let kennis3 = '';

      let naarcons = (<IconButton                       
                        style={{display:'block', float:'right', marginLeft: '.5em',fontSize:'80%'}}
                        iconClassName="fa fa-eye" 
                        iconStyle={{color:'#008cc9', iconHoverColor:'darkslateblue',fontSize:'80%'}} 
                        eindex={index}
                        edata={hdata}
                        onTouchTap={editfunc2}/>);


      if (hdata.know1.length > 1) {kennis = kennis + hdata.know1}
      if (hdata.know2.length > 1) {kennis = kennis + ';'+hdata.know2}
      if (hdata.know3.length > 1) {kennis = kennis + ';'+hdata.know3}
      if (hdata.know4.length > 1) {kennis = kennis + ';'+hdata.know4}
      if (hdata.know5.length > 1) {kennis1 = kennis1 + hdata.know5}
      if (hdata.know6.length > 1) {kennis1 = kennis1 + ';'+hdata.know6}
      if (hdata.know7.length > 1) {kennis1 = kennis1 + ';'+hdata.know7}
      if (hdata.know8.length > 1) {kennis1 = kennis1 + ';'+hdata.know8}
      if (hdata.know9.length > 1) {kennis1 = kennis1 + ';'+hdata.know9}

      if (hdata.know10.length > 1) {kennis2 = kennis2 + hdata.know10}
      if (hdata.know11.length > 1) {kennis2 = kennis2 + ';'+hdata.know11}
      if (hdata.know12.length > 1) {kennis2 = kennis2 + ';'+hdata.know12}
      if (hdata.know13.length > 1) {kennis2 = kennis2 + ';'+hdata.know13}
      if (hdata.know14.length > 1) {kennis2 = kennis2 + ';'+hdata.know14}
      if (hdata.know15.length > 1) {kennis3 = kennis3 + hdata.know15}
      if (hdata.know16.length > 1) {kennis3 = kennis3 + ';'+hdata.know16}
      if (hdata.know17.length > 1) {kennis3 = kennis3 + ';'+hdata.know17}
      if (hdata.know18.length > 1) {kennis3 = kennis3 + ';'+hdata.know18}
      if (hdata.know19.length > 1) {kennis3 = kennis3 + ';'+hdata.know19}
      if (hdata.know20.length > 1) {kennis3 = kennis3 + ';'+hdata.know20}


      const popoverFocus = (
        <Popover id={hdata.id}>
          <p style={{fontSize:'80%'}}>{kennis}<br/>{kennis1}<br/>{kennis2}<br/>{kennis3}<br/></p>
        </Popover>
      );

      vld1 = (<Row className="show-grid">
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.req_date}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          {naarcons}
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.accounttxt}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.clienttxt}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
        </Col>
        <Col xs={1} md={1} sm={1}>
          {mailen}
        </Col>
      </Row>);

      pan1 = (<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverFocus}>
                {vld1}
              </OverlayTrigger>)

      inhoud = (
        <div>
          {pan1}
        </div>);
      return inhoud;

    }

    if (type == 'GetTotAfwGoedTeKeur')
    {
      let mailen = (hdata.goedgekeurd == 'N') ? 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={false} label=""/>) : 
        (hdata.goedgekeurd == 'Y') ? 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={true} label=""/>) : null;

      vld1 = (<Row className="show-grid">
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.datum}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.consname}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vm_txt}({hdata.vm_uren})</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.nm_txt}({hdata.nm_uren})</p>
        </Col>
        <Col xs={1} md={1} sm={1}>
          {mailen}
        </Col>
      </Row>);

      inhoud = (
        <div>
          {vld1}
        </div>);
      return inhoud;

    }



    if (type == 'GetMogKandReq')
    {
      let mailen = (hdata.mailen == 'N') ? 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={false} label=""/>) : 
        (hdata.mailen == 'Y') ? 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={true} label=""/>) : null;

      let kennis='';
      let kennis1='';
      let kennis2 = '';
      let kennis3 = '';

      if (hdata.know1.length > 1) {kennis = kennis + hdata.know1}
      if (hdata.know2.length > 1) {kennis = kennis + ';'+hdata.know2}
      if (hdata.know3.length > 1) {kennis = kennis + ';'+hdata.know3}
      if (hdata.know4.length > 1) {kennis = kennis + ';'+hdata.know4}
      if (hdata.know5.length > 1) {kennis1 = kennis1 + hdata.know5}
      if (hdata.know6.length > 1) {kennis1 = kennis1 + ';'+hdata.know6}
      if (hdata.know7.length > 1) {kennis1 = kennis1 + ';'+hdata.know7}
      if (hdata.know8.length > 1) {kennis1 = kennis1 + ';'+hdata.know8}
      if (hdata.know9.length > 1) {kennis1 = kennis1 + ';'+hdata.know9}

      if (hdata.know10.length > 1) {kennis2 = kennis2 + hdata.know10}
      if (hdata.know11.length > 1) {kennis2 = kennis2 + ';'+hdata.know11}
      if (hdata.know12.length > 1) {kennis2 = kennis2 + ';'+hdata.know12}
      if (hdata.know13.length > 1) {kennis2 = kennis2 + ';'+hdata.know13}
      if (hdata.know14.length > 1) {kennis2 = kennis2 + ';'+hdata.know14}
      if (hdata.know15.length > 1) {kennis3 = kennis3 + hdata.know15}
      if (hdata.know16.length > 1) {kennis3 = kennis3 + ';'+hdata.know16}
      if (hdata.know17.length > 1) {kennis3 = kennis3 + ';'+hdata.know17}
      if (hdata.know18.length > 1) {kennis3 = kennis3 + ';'+hdata.know18}
      if (hdata.know19.length > 1) {kennis3 = kennis3 + ';'+hdata.know19}
      if (hdata.know20.length > 1) {kennis3 = kennis3 + ';'+hdata.know20}


      const popoverFocus = (
        <Popover id={hdata.id}>
          <p style={{fontSize:'80%'}}>{kennis}<br/>{kennis1}<br/>{kennis2}<br/>{kennis3}<br/></p>
        </Popover>
      );

      vld1 = (<Row className="show-grid">
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.consname}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.type}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.woonplaats}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.date_available}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.tarief}</p>
        </Col>
        <Col xs={2} md={2} sm={2}>
          {mailen}
        </Col>
      </Row>);

      pan1 = (<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverFocus}>
                {vld1}
              </OverlayTrigger>)

      inhoud = (
        <div>
          {pan1}
        </div>);
      return inhoud;

    }


    if (type == 'GetKandNtBes')
    {


      let mailen = (hdata.mailen == 'N') ? 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={false} label="Mail Klant"/>) : 
        (hdata.mailen == 'Y') ? 
        (<Checkbox onCheck={editfunc} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={true} label="Mail Klant"/>) : null;

      let afsluiten = (hdata.afsluiten == 'N') ? 
        (<Checkbox onCheck={editfunc2} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={false} label="Afsluiten"/>) : 
        (hdata.afsluiten == 'Y') ? 
        (<Checkbox onCheck={editfunc2} edata={hdata.id} eindex={index}
                  checkedIcon={iconcheck} unCheckedIcon={icouncheck} 
                  defaultChecked={true} label="Afsluiten"/>) : null;



      const popoverFocus = (
        <Popover id={hdata.id}>
          <p style={{fontSize:'80%'}}>Datum: {hdata.Datum} <br />
             Contactpersoon: {hdata.contname} <br />
             Eindklant: {hdata.clienttxt} <br />
             Status: {hdata.statustxt} <br /></p>
        </Popover>
      );

      vld1 = (<Row className="show-grid">
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.profile}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.account_name}</p>
        </Col>
        <Col xs={3} md={3} sm={3}>
          {mailen}
        </Col>
        <Col xs={2} md={2} sm={2}>
          {afsluiten}
        </Col>
      </Row>);

      pan1 = (<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverFocus}>
                {vld1}
              </OverlayTrigger>)

      inhoud = (
        <div>
          {pan1}
        </div>);
      return inhoud;

    }


    if (type == 'GetAllContAcc')
    {
      if (screen.width < 750)
      {
        inhoud = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={12} md={12} sm={12} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
        </Col>
        </Row>);
      }
      else
      {      
        inhoud = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.Mobile_phone}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.main_phone}</p>
        </Col>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.E_mail}</p>
        </Col>
        </Row>);
      }
      return inhoud;
    }
    if (type == 'lijntefactnw')
    {

      inhoud = (hdata.vmstat == 'AD' &&  hdata.nmstat == 'AD' ) ? (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld4}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld5}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld6}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld7}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld8}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld9}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld10}</p>
        </Col>
      </Row>) :
      (hdata.vmstat != 'AD' &&  hdata.nmstat == 'AD' ) ? (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:'red', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld4}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:'red', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld5}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld6}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld7}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld8}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld9}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld10}</p>
        </Col>
      </Row>) :  
        (hdata.vmstat == 'AD' &&  hdata.nmstat != 'AD' ) ? (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld4}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld5}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:'red',paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld6}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:'red',paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld7}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld8}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld9}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld10}</p>
        </Col>
      </Row>) : (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:'red',paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld4}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:'red',paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld5}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:'red',paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld6}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:'red',paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld7}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld8}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld9}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld10}</p>
        </Col>
      </Row>);
      return inhoud;
    }


    if (type == 'GetBijContr')
    {

      inhoud = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.bijlage_num}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.description}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.consname}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.startdate}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.enddate}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.rate}</p>
        </Col>
      </Row>);
      return inhoud;
    }


    if (type == 'GetBijCons')
    {

      const popoverFocus = (
        <Popover id={hdata.id}>
          <p style={{fontSize:'80%'}}>Contnum: {hdata.contract_num} <br />
            Bijlnum: {hdata.bijlage_num} <br />
            Ponum: {hdata.po_number} <br />
            Eindklant: {hdata.clienttxt} <br />
            Status: {hdata.statustxt} <br /></p>
        </Popover>)


      inhoud = (<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverFocus}><Row className="show-grid" edata={hdata.contract_id} onClick={editfunc}>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.description}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.accounttxt}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.startdate}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.enddate}</p>
        </Col>
        <Col xs={1} md={1} sm={1} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.rate}</p>
        </Col>
      </Row></OverlayTrigger>);
      return inhoud;
    }




    if (type == 'GetAccContr')
    {

      inhoud = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={4} md={4} sm={4} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.contract_date}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.statustxt}</p>
        </Col>
      </Row>);
      return inhoud;
    }





    if (type == 'faktlijnen')
    {
      vld1 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
                <Col xs={12} md={12} sm={12} >
                  <TextField style={{cursor: 'pointer'}}
                    inputStyle={{fontSize:'80%', cursor: 'pointer'}}
                    ref='InpNaam'
                    defaultValue={hdata.primtxt} 
                    disabled={true}  
                    fullWidth={true}
                    multiLine={true} />
                </Col>
              </Row>) 

      vld2 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
                <Col xs={6} md={6} sm={6} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.aantal}</p>
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.prijs}</p>
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.eenheid}</p>
                </Col>
                <Col xs={6} md={6} sm={6} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.sectxtva}</p>
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.sectxtvb}</p>
                </Col>
              </Row>)

      pan1 = (<Panel  collapsible defaultExpanded style={{background: '#fff', overflow: 'auto', height: '128px', cursor: 'pointer' }}>
                {vld1}
              </Panel>)

      pan2 = (<Panel  collapsible defaultExpanded style={{background: '#fff',cursor: 'pointer'}}>
                {vld2}
              </Panel>)

      hpan1 = (<Row className="show-grid">
                <Col xs={12} md={6} sm={6} >
                  {pan1}
                </Col>
                <Col xs={12} md={6} sm={6} >
                  {pan2}
                </Col>
              </Row>)


      inhoud = (<div> {hpan1} </div>);
      return inhoud;
    }

    if (type == 'LstVerl')
    {
      if (screen.width < 544)
      {
        inhoud = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={6} md={6} sm={6} >
           <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:hdata.vldkl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.primtxt}</p>
        </Col>
        <Col xs={4} md={4} sm={4} >
           <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:hdata.vldkl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.sectxtva}</p>
        </Col>
        <Col xs={2} md={2} sm={2} >
           <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:hdata.vldkl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.duur}</p>
        </Col>
        </Row>);

      }
      else
      {

        inhoud = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
        <Col xs={3} md={3} sm={3} >
           <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:hdata.vldkl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.primtxt}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
           <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:hdata.vldkl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.sectxtva}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
           <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:hdata.vldkl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.duur}</p>
        </Col>
        <Col xs={3} md={3} sm={3} >
           <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vldkl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.sectxtvb}</p>
        </Col>
        </Row>);
      }
      return inhoud;
    }

    if (type == 'Feestdagen')
    {
      inhoud = (<Row className="show-grid">
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld2}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld3}</p>
        </Col>
        <Col xs={3} md={3} sm={3} edata={hdata} onClick={editfunc}>
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld4}</p>
        </Col>

      </Row>);
      return inhoud;
    }

    if (type == 'Mailtemp')
    {
      vld1 = (<Row className="show-grid" edata={hdata} onClick={editfunc}>
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color:'#66696a', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer'}}>{hdata.vld1}</p>
                </Col>
              </Row>);

      inhoud = (<div> {vld1} </div>);
      return inhoud;
    }


    if (type == 'GetConsFin')
    {

      let naarcons = (<IconButton                       
                        style={{display:'block', float:'right', marginLeft: '.5em',fontSize:'80%'}}
                        iconClassName="fa fa-envelope-o" 
                        iconStyle={{color:'#008cc9', iconHoverColor:'darkslateblue',fontSize:'80%'}} 
                        eindex={index}
                        edata={hdata.id}
                        onTouchTap={editfunc2}/>);

      vld1 = (<Row className="show-grid" >
                <Col xs={3} md={3} sm={3} edata={hdata.id} onClick={editfunc}>
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld1}</p>
                </Col>
                <Col xs={3} md={3} sm={3} edata={hdata.id} onClick={editfunc}>
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld2}</p>
                </Col>
                <Col xs={4} md={4} sm={4} edata={hdata.id} onClick={editfunc}>
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld3}</p>
                </Col>
                <Col xs={2} md={2} sm={2} >
                  {naarcons}
                </Col>
              </Row>) 
      pan1 = vld1;

      inhoud = (<div> {pan1} </div>);
      return vld1;
    }

    if (type == 'GetConsRef')
    {

      vld1 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
                <Col xs={2} md={2} sm={2} >
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.datum}</p>
                </Col>
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.contname}</p>
                </Col>
                <Col xs={6} md={6} sm={6} >
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.referentie}</p>
                </Col>
              </Row>) 
      pan1 = vld1;

      inhoud = (<div> {pan1} </div>);
      return vld1;
    }


    if (type == 'GetConsTimeParam')
    {

      vld1 = (<Row className="show-grid" edata={hdata} onClick={editfunc}>
                <Col xs={3} md={3} sm={3} >
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.sectxtva}</p>
                </Col>
                <Col xs={3} md={3} sm={3} >
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.verlof}</p>
                </Col>
                <Col xs={3} md={3} sm={3} >
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.adv}</p>
                </Col>
                <Col xs={3} md={3} sm={3} >
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.over_verl}</p>
                </Col>
              </Row>) 
      return vld1;
    }


    if (type == 'GetConsDocs')
    {
      vld1 = (<Row className="show-grid" edata={hdata} onClick={editfunc}>
                <Col xs={5} md={5} sm={5} >
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.titel}</p>
                </Col>
                <Col xs={5} md={5} sm={5} >
                  <p dir="ltr" style={{fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.name}</p>
                </Col>
              </Row>) 
      return vld1;
    }





    if (type == 'Tijdparam')
    {
      vld1 = (<Row className="show-grid" edata={hdata} onClick={editfunc}>
                <Col xs={12} md={6} sm={6} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.jaar}</p>
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.tot_dagen}</p>
                </Col>
                <Col xs={12} md={6} sm={6} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.verlof}</p>
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.adv}</p>
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.feestdagen}</p>
                </Col>
              </Row>)

      pan1 = (<Panel  collapsible defaultExpanded style={{background: '#fff', overflow: 'auto', height: '128px' }}>
                {vld1}
              </Panel>)

      hpan1 = (<Row className="show-grid">
                <Col xs={12} md={12} sm={12} >
                  {vld1}
                </Col>
              </Row>)
      inhoud = (<div> {hpan1} </div>);
      return inhoud;
    }

    if (type == 'Tijdconsparam')
    {
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        vld1 = (<Row className="show-grid" edata={hdata} onClick={editfunc}>
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.primtxt}</p>
                </Col>
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.sectxtva}</p>
                </Col>
              </Row>);
      }
      else
      {
        vld1 = (<Row className="show-grid" edata={hdata} onClick={editfunc}>
                <Col xs={6} md={6} sm={6} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.primtxt}</p>
                </Col>
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.sectxtva}</p>
                </Col>
              </Row>);
      }

      vld6 =  
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.verlof}</p>
          </Col>
        </Row>);
    
      vld7 = 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.adv}</p>
          </Col>
        </Row>);
      vld8 = 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.tot_dagen}</p>
          </Col>
        </Row>) ;

      pan1 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
        {vld6}
        {vld7}
        {vld8}
        </Panel>)
    
      vld11 = 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.over_verl}</p>
          </Col>
        </Row>) ;

      vld12 = 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.feestdagen}</p>
          </Col>
        </Row>);

      pan2 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
              {vld11}
              {vld12}
            </Panel>)


      hpan1 = (<Row className="show-grid" >
                <Col xs={12} md={6} sm={6} >
                  {pan1}
                </Col>
                <Col xs={12} md={6} sm={6} >
                  {pan2}
                </Col>
              </Row>)

      inhoud = (
        <div>
          {vld1}
          {hpan1}
        </div>);
        return inhoud;
    }

    if (type == 'Allkennis')
    {
        const popoverFocus = (
          <Popover id={hdata.id}>
            <p style={{fontSize:'80%'}}>{hdata.uitleg}<br/></p>
          </Popover>
        );

        vld1 = (<Row className="show-grid">
                <Col xs={4} md={4} sm={4} edata={hdata} onClick={editfunc}>
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
                </Col>
                <Col xs={8} md={8} sm={8} edata={hdata} onClick={editfunc}>
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld2}</p>
                </Col>
              </Row>);      

        if (hdata.uitleg)
        {
          if (hdata.uitleg.length > 3)
          {
            vld1 = (<Row className="show-grid">
              <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" positionLeft={20} overlay={popoverFocus}>          
                <Col xs={4} md={4} sm={4} edata={hdata} onClick={editfunc}>
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
                </Col>
              </OverlayTrigger>          
              <Col xs={8} md={8} sm={8} edata={hdata} onClick={editfunc}>
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld2}</p>
              </Col>
              </Row>);      
          }
        }

      return vld1;
    }


    if (type == 'GetAfpFakturen')
    {
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        vld1 = (<Row className="show-grid">
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld2kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld2}</p>
                </Col>
                <Col xs={6} md={6} sm={6} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld1kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld1}</p>
                </Col>
                <Col xs={6} md={6} sm={6} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld1kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.checknaam}</p>
                </Col>

              </Row>);

      }
      else
      {
        vld1 = (<Row className="show-grid">
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld2kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld2}</p>
                </Col>
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld1kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld1}</p>
                </Col>
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld1kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.checknaam}</p>
                </Col>

              </Row>);
      }
      vld6 = (hdata.vld6.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld6kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld6}</p>
          </Col>
        </Row>) : null;
    
      vld7 = (hdata.vld7.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld7kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld7}</p>
          </Col>
        </Row>) : null;
      vld8 = (hdata.vld8.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld8kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld8}</p>
          </Col>
        </Row>) : null;

      pan1 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
        {vld6}
        {vld7}
        {vld8}
        </Panel>)
    
      vld11 = (hdata.vld11.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld11kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld11}</p>
          </Col>
        </Row>) : null;

      vld12 = (hdata.vld12.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld12kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld12}</p>
          </Col>
        </Row>) : null;

      vld13 = (hdata.vld13.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld13kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld13}</p>
          </Col>
        </Row>) : null;

      pan2 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
              {vld11}
              {vld12}
              {vld13}
            </Panel>)



      vld21 = (hdata.vld21.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld21kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld21}</p>
          </Col>
        </Row>) : null;
      vld23 = (hdata.vld23.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld23kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld23}</p>
          </Col>
        </Row>) : null;
      vld24 = (hdata.vld24.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld24kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld24}</p>
          </Col>
        </Row>) : null;

      pan3 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
              {vld21}
              {vld23}
              {vld24}
            </Panel>)

      hpan1 = (<Row className="show-grid" >
                <Col xs={12} md={4} sm={4} >
                  {pan1}
                </Col>
                <Col xs={12} md={4} sm={4} >
                  {pan2}
                </Col>
                <Col xs={12} md={4} sm={4} >
                  {pan3}
                </Col>
              </Row>)

      inhoud = (
        <div>
          {vld1}
          {hpan1}
        </div>);
        return inhoud;
    }

    if (type == 'tefakt')
    {
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        vld1 = (<Row className="show-grid">
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld1kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld1}</p>
                </Col>
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld2kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld2}</p>
                </Col>
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld3kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld3}</p>
                </Col>

              </Row>);

      }
      else
      {
        vld1 = (<Row className="show-grid">
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld1kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld1}</p>
                </Col>
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld2kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld2}</p>
                </Col>
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld3kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld3}</p>
                </Col>

              </Row>);
      }





      if (hdata.vld4)
      {
        vld4 = (hdata.vld4.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld4kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld4}</p>
          </Col>
        </Row>) : null;
      }

      if (hdata.vld5)
      {
      vld5 = (hdata.vld5.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld5kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld5}</p>
          </Col>
        </Row>) : null;
      }

      if (hdata.vld6)
      {
      vld6 = (hdata.vld6.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld6kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld6}</p>
          </Col>
        </Row>) :(<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Gewerkt: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld6 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Gewerkt: 0</p>
          </Col>
        </Row>) ;
      }
    
      if (hdata.vld7)
      {
        vld7 = (hdata.vld7.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld7kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld7}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ouren150: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld7 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ouren150: 0</p>
          </Col>
        </Row>) ;
      }
      if (hdata.vld8)
      {
      vld8 = (hdata.vld8.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld8kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld8}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ouren200: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld8 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ouren200: 0</p>
          </Col>
        </Row>) ;
      }

      if (hdata.vld9)
      {
        vld9 = (hdata.vld9.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld9kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld9}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Standby: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld9 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Standby: 0</p>
          </Col>
        </Row>) ;
      }

      if (hdata.vld10)
      {
        vld10 = (hdata.vld10.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld10kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld10}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Verlof: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld10 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Verlof: 0</p>
          </Col>
        </Row>) ;
      }

      if (hdata.vld11)
      {
        vld11 = (hdata.vld11.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld11kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld11}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Kompensatie: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld11 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Kompensatie: 0</p>
          </Col>
        </Row>) ;
      }

      if (hdata.vld12)
      {
        vld12 = (hdata.vld12.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld12kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld12}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ziek: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld12 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ziek: 0</p>
          </Col>
        </Row>) ;
      }

      if (hdata.vld13)
      {
        vld13 = (hdata.vld13.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld13kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld13}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Andere: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld13 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Andere: 0</p>
          </Col>
        </Row>) ;
      }

      pan1 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
        {vld4}
        {vld5}
        </Panel>)
    
      pan2 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
              {vld6}
              {vld7}
              {vld8}
              {vld9}
            </Panel>)

      pan3 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
              {vld10}
              {vld11}
              {vld12}
              {vld13}
            </Panel>)

      hpan1 = (<Row className="show-grid" >
                <Col xs={12} md={4} sm={4} >
                  {pan1}
                </Col>
                <Col xs={12} md={4} sm={4} >
                  {pan2}
                </Col>
                <Col xs={12} md={4} sm={4} >
                  {pan3}
                </Col>
              </Row>)

      inhoud = (
        <div>
          {vld1}
          {hpan1}
        </div>);
        return inhoud;
    }



    if (type == 'GetRepAfwMnd')
    {
      let vld1 = null;
      if (hdata.voorm == hdata.nam)
      { 
        let voorm, nam = 0;
        if(!isNaN (parseFloat(hdata.vm_uren)))
        {
          voorm = parseFloat(hdata.vm_uren);
        }      
        if(!isNaN (parseFloat(hdata.nm_uren)))
        {
          nam = parseFloat(hdata.nm_uren);
        }      

         let totverl = voorm + nam;
         vld1 = (<Row className="show-grid">
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld1}</p>
              </Col>
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{hdata.voorm}</p>
              </Col>
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{totverl}</p>
              </Col>
            </Row>);

      }
      else
      {
        let voorm, nam = 0;
        if(!isNaN (parseFloat(hdata.vm_uren)))
        {
          voorm = parseFloat(hdata.vm_uren);
        }      
        if(!isNaN (parseFloat(hdata.nm_uren)))
        {
          nam = parseFloat(hdata.nm_uren);
        }      
        if (voorm == 0) 
        {
           vld1 = (<Row className="show-grid">
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld1}</p>
              </Col>
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{hdata.nam}</p>
              </Col>
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{nam}</p>
              </Col>
            </Row>);
        }
        else
        {
          if (nam == 0) 
          {
            vld1 = (<Row className="show-grid">
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld1}</p>
              </Col>
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{hdata.voorm}</p>
              </Col>
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{voorm}</p>
              </Col>
            </Row>);
          }
          else
          {
            vld1 = (<Row className="show-grid">
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld1}</p>
              </Col>
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{hdata.voorm}</p>
              </Col>
              <Col xs={1} md={1} sm={1} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{voorm}</p>
              </Col>
              <Col xs={3} md={3} sm={3} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{hdata.nam}</p>
              </Col>
              <Col xs={1} md={1} sm={1} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', paddingTop: '5px', maxHeight: '24px'}}>{nam}</p>
              </Col>
            </Row>);
          }
        }
      }
      return vld1;
    }


    if (type == 'klanten')
    {
      vld1 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
              <Col xs={12} md={12} sm={12} >
                <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld1}</p>
              </Col>
            </Row>);


      vld2 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
              <Col xs={12} md={12} sm={12} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld2}</p>
              </Col>
              <Col xs={12} md={12} sm={12} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld3}</p>
              </Col>
              </Row>);

      vld3 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
              <Col xs={12} md={12} sm={12} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld4}</p>
              </Col>
              <Col xs={12} md={12} sm={12} >
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld5}</p>
              </Col>
              </Row>);

      pan1 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
        {vld2}
        </Panel>)

      pan2 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
        {vld3}
        </Panel>)
    
      hpan1 = (<Row className="show-grid" >
                <Col xs={12} md={6} sm={6} >
                  {pan1}
                </Col>
                <Col xs={12} md={6} sm={6} >
                  {pan2}
                </Col>
              </Row>)

      inhoud = (
        <div>
          {vld1}
          {hpan1}
        </div>);
        return inhoud;
    }

    if (type == 'lijntefact')
    {

      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        vld1 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld1}</p>
                </Col>
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld2}</p>
                </Col>
                <Col xs={12} md={12} sm={12} >
                  <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld3}</p>
                </Col>

              </Row>);

      }
      else
      {
        vld1 = (<Row className="show-grid" edata={hdata.id} onClick={editfunc}>
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld1}</p>
                </Col>
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px', cursor: 'pointer'}}>{hdata.vld2}</p>
                </Col>
                <Col xs={4} md={4} sm={4} >
                  <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: '#008cc9', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld3}</p>
                </Col>

              </Row>);
      }


      if (hdata.vld4)
      {
        vld4 = (hdata.vld4.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld4}</p>
          </Col>
        </Row>) : null;
      }

      if (hdata.vld5)
      {
      vld5 = (hdata.vld5.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld5}</p>
          </Col>
        </Row>) : null;
      }

      if (hdata.vld6)
      {
      vld6 = (hdata.vld6.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld6}</p>
          </Col>
        </Row>) :(<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Gewerkt: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld6 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Gewerkt: 0</p>
          </Col>
        </Row>) ;
      }
    
      if (hdata.vld7)
      {
        vld7 = (hdata.vld7.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld7}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ouren150: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld7 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ouren150: 0</p>
          </Col>
        </Row>) ;
      }
      if (hdata.vld8)
      {
      vld8 = (hdata.vld8.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld8}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ouren200: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld8 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Ouren200: 0</p>
          </Col>
        </Row>) ;
      }

      if (hdata.vld9)
      {
        vld9 = (hdata.vld9.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld9}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Standby: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld9 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Standby: 0</p>
          </Col>
        </Row>) ;
      }

      if (hdata.vld10)
      {
        vld10 = (hdata.vld10.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld10}</p>
          </Col>
        </Row>) : (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Verlof: 0</p>
          </Col>
        </Row>) ;
      } else {
        vld10 = (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: '#66696a', paddingTop: '5px', maxHeight: '24px'}}>Verlof: 0</p>
          </Col>
        </Row>) ;
      }

      pan1 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
        {vld4}
        {vld5}
        </Panel>)
    
      pan2 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
              {vld6}
              {vld7}
            </Panel>)

      pan3 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
              {vld8}
              {vld9}
              {vld10}
            </Panel>)

      hpan1 = (<Row className="show-grid" >
                <Col xs={12} md={4} sm={4} >
                  {pan1}
                </Col>
                <Col xs={12} md={4} sm={4} >
                  {pan2}
                </Col>
                <Col xs={12} md={4} sm={4} >
                  {pan3}
                </Col>
              </Row>)

      inhoud = (
        <div>
          {vld1}
          {hpan1}
        </div>);
        return inhoud;
    }

    if (type == 'fakturen')
    {
      vld1 = (<Row className="show-grid" edata={hdata} onClick={editfunc}>
        <Col xs={12} md={12} sm={12} >
          <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld1kl, paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer'}}>{hdata.vld1}</p>
        </Col>
      </Row>);
      vld2 = (<Row className="show-grid" edata={hdata} onClick={editfunc}>
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld2kl, paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer'}}>{hdata.vld2}</p>
          </Col>
        </Row>);

      vld6 = (hdata.vld6.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld6kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld6}</p>
          </Col>
        </Row>) : null;

      vld7 = (hdata.vld7.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld7kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld7}</p>
          </Col>
        </Row>) : null;

      vld8 = (hdata.vld8.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld8kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld8}</p>
          </Col>
        </Row>) : null;

      vld11 = (hdata.vld11.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld11kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld11}</p>
          </Col>
        </Row>) : null;

      vld12 = (hdata.vld12.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld12kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld12}</p>
          </Col>
        </Row>) : null;

      vld13 = (hdata.vld13.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld13kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld13}</p>
          </Col>
        </Row>) : null;


      vld16 = (hdata.vld16.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld16kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld16}</p>
          </Col>
        </Row>) : null;

      vld21 = (hdata.vld21.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld21kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld21}</p>
          </Col>
        </Row>) : null;

      vld22 = (hdata.vld22.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld22kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld22}</p>
          </Col>
        </Row>) : null;

      vld23 = (hdata.vld23.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld23kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld23}</p>
          </Col>
        </Row>) : null;

      vld24 = (hdata.vld24.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld24kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld24}</p>
          </Col>
        </Row>) : null;

      pan1 = (<Panel style={{background: '#fff'}}>
              {vld6}
              {vld7}
              {vld8}
            </Panel>)

      pan2 = (<Panel style={{background: '#fff'}}>
              {vld11}
              {vld12}
              {vld13}
            </Panel>)

      hpan1 = (<Row className="show-grid" >
                <Col xs={12} md={6} sm={6} >
                  {pan1}
                </Col>
                <Col xs={12} md={6} sm={6} >
                  {pan2}
                </Col>
              </Row>)

      pan3 = (<Panel style={{background: '#fff'}}>
               {vld16}
              </Panel>)

      pan4 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
                {vld21}
                {vld22}
                {vld23}
                {vld24}
              </Panel>)

      hpan2 = (<Row className="show-grid" >
                  <Col xs={12} md={6} sm={6} >
                    {pan3}
                  </Col>
                  <Col xs={12} md={6} sm={6} >
                    {pan4}
                  </Col>
                </Row>)
      
      inhoud = (
        <div>
          {vld1}
          {vld2}
          {hpan1}
          {hpan2}
        </div>);
      return inhoud;

    }

    if (type == 'consnw111')
    {
      inhoud = (<Row className="show-grid" edata={hdata} onClick={editfunc}>
        <Col xs={12} md={12} sm={12} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: 'gray', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer', overflow: 'hidden'}}>{hdata.vld1}</p>
        </Col>
      </Row>);
      return inhoud;

    }

    if (type == 'consnw')
    {
      vld1 = (<div className="show-grid" onClick={(event)=>editfunc(event,hdata)}>
        <Col xs={12} md={12} sm={12} >
          <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: 'gray', paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer'}}>{hdata.vld1}</p>
        </Col>
      </div>);

      const popoverFocus = (hdata.vorm == 'REQ') ? (
        <Popover id={hdata.id}>
          <p style={{fontSize:'80%'}}>Datum: {hdata.req_date} <br />
            Klant: {hdata.accounttxt} <br />
            Eindklant: {hdata.clienttxt} <br />
            Status: {hdata.statustxt} <br /></p>
        </Popover>
      ) :
      (hdata.vorm == 'TIK') ? (
        <Popover id={hdata.id}>
          <p style={{fontSize:'80%'}}>Datum: {hdata.crea_date} <br />
            Klant: {hdata.accounttxt} <br />
            Contact: {hdata.contname} <br />
            Status: {hdata.statustxt} <br /></p>
        </Popover>
      ) :       
      (hdata.vorm == 'CONS') ? (
        <Popover id={hdata.id}>
          <p style={{fontSize:'80%'}}>{hdata.jobtitle} <br/>
            {hdata.typetxt} <br/>
            {hdata.account_name} <br/></p>
        </Popover>
      ) : 
      (hdata.vorm == 'CONT') ? (
        <Popover id={hdata.id}>
          <p style={{fontSize:'80%'}}>{hdata.jobtitletxt} <br/>
            {hdata.account_name} <br/></p>
        </Popover>
      ) : 
      (hdata.vorm == 'ACC') ? (
        <Popover id={hdata.id}>
          <p style={{fontSize:'80%'}}>{hdata.website} <br/>
            {hdata.sectortxt} <br/>
            {hdata.ownertxt} <br/></p>
        </Popover>
      ) : null;


      if (screen.width < 750)
      {
        pan1 = vld1;
      }
      else
      {
        pan1 = (<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popoverFocus}>
                  {vld1}
                </OverlayTrigger>);
      }


      inhoud = (
        <div>
          {pan1}
        </div>);
      return inhoud;
    }



    if (hdata.vld1) 
    {
      if (hdata.vld1.length < 3) { return null};
    }
    else
    {
      return null;
    };

    vld1 = (hdata.vld1.length > 0) ? 
      (<Row className="show-grid" edata={hdata} onClick={editfunc}>
        <Col xs={12} md={12} sm={12} >
          <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld1kl, paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer'}}>{hdata.vld1}</p>
        </Col>
      </Row>):null;



    if (hdata.vld2)
    {
      vld2 = (hdata.vld2.length > 0) ? 
        (<Row className="show-grid" edata={hdata} onClick={editfunc}>
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld2kl, paddingTop: '5px', maxHeight: '24px',  cursor: 'pointer'}}>{hdata.vld2}</p>
          </Col>
        </Row>) : null;
    }

    if (hdata.vld3)
    {
      vld3 = (hdata.vld3.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld3kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld3}</p>
          </Col>
        </Row>) : null;
    }
    

    if (hdata.vld4)
    {
      vld4 = (hdata.vld4.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld4kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld4}</p>
          </Col>
        </Row>) : null;
    }


    if (hdata.vld5)
    {
      vld5 = (hdata.vld5.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'medium',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld5kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld5}</p>
          </Col>
        </Row>) : null;
    }


    // pan 1 (vld 6 t/m vld 10)
    if (hdata.vld6)
    {
      vld6 = (hdata.vld6.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld6kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld6}</p>
          </Col>
        </Row>) : null;
    }

    if (hdata.vld7)
    {
      vld7 = (hdata.vld7.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld7kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld7}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld8)
    {
      vld8 = (hdata.vld8.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld8kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld8}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld9)
    {
      vld9 = (hdata.vld9.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld9kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld9}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld10)
    {
      vld10 = (hdata.vld10.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld10kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld10}</p>
          </Col>
        </Row>) : null;
    }

    if (vld6 != null || vld7 != null || vld8 != null || vld9 != null || vld10 != null )
    {
      pan1 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
              {vld6}
              {vld7}
              {vld8}
              {vld9}
              {vld10}
            </Panel>)
    }

    // pan 2 (vld 11 t/m vld 15)
    if (hdata.vld11)
    {
      vld11 = (hdata.vld11.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld11kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld11}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld12)
    {
      vld12 = (hdata.vld12.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld12kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld12}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld13)
    {
      vld13 = (hdata.vld13.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld13kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld13}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld14)
    {
      vld14 = (hdata.vld14.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld14kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld14}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld15)
    {
      vld15 = (hdata.vld15.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld15kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld15}</p>
          </Col>
        </Row>) : null;
    }

    if (vld11 != null || vld12 != null || vld13 != null || vld14 != null || vld15 != null )
    {
      pan2 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
              {vld11}
              {vld12}
              {vld13}
              {vld14}
              {vld15}
            </Panel>)
    }
    if (pan1 != null || pan2 != null )
    {
      hpan1 = (<Row className="show-grid" >
                <Col xs={12} md={6} sm={6} >
                  {pan1}
                </Col>
                <Col xs={12} md={6} sm={6} >
                  {pan2}
                </Col>
              </Row>)
    }



 


    // pan 3 (vld 16 t/m vld 20)
    if (hdata.vld16)
    {
      vld16 = (hdata.vld16.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld16kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld16}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld17)
    {
      vld17 = (hdata.vld17.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld17kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld17}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld18)
    {
      vld18 = (hdata.vld18.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld18kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld18}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld19)
    {
      vld19 = (hdata.vld19.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld19kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld19}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld20)
    {
      vld20 = (hdata.vld20.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld20kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld20}</p>
          </Col>
        </Row>) : null;
    }

    if (vld16 != null || vld17 != null || vld18 != null || vld19 != null || vld20 != null )
    {
      pan3 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
               {vld16}
               {vld17}
               {vld18}
               {vld19}
               {vld20}
              </Panel>)
    }

    // pan 4 (vld 21 t/m vld 25)
    if (hdata.vld21)
    {
      vld21 = (hdata.vld21.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld21kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld21}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld22)
    {
      vld22 = (hdata.vld22.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld22kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld22}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld23)
    {
      vld23 = (hdata.vld23.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld23kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld23}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld24)
    {
      vld24 = (hdata.vld24.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld24kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld24}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld25)
    {
      vld25 = (hdata.vld25.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld25kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld25}</p>
          </Col>
        </Row>) : null;
    }

    if (vld21 != null || vld22 != null || vld23 != null || vld24 != null || vld25 != null )
    {
      pan4 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
                {vld21}
                {vld22}
                {vld23}
                {vld24}
                {vld25}
              </Panel>)
    }
    if (pan3 != null || pan4 != null )
    {
      hpan2 = (<Row className="show-grid" >
                  <Col xs={12} md={6} sm={6} >
                    {pan3}
                  </Col>
                  <Col xs={12} md={6} sm={6} >
                    {pan4}
                  </Col>
                </Row>)
    }

    // pan 5 (vld 26 t/m vld 30)
    if (hdata.vld26)
    {
      vld26 = (hdata.vld26.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld26kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld26}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld27)
    {
      vld27 = (hdata.vld27.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld27kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld27}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld28)
    {
      vld28 = (hdata.vld28.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld28kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld28}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld29)
    {
      vld29 = (hdata.vld29.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld29kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld29}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld30)
    {
      vld30 = (hdata.vld30.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld30kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld30}</p>
          </Col>
        </Row>) : null;
    }

    if (vld26 != null || vld27 != null || vld28 != null || vld29 != null || vld30 != null )
    {
      pan5 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
                {vld26}
                {vld27}
                {vld28}
                {vld29}
                {vld30}
              </Panel>)
    }

    // pan 6 (vld 31 t/m vld 35)
    if (hdata.vld31)
    {
      vld31 = (hdata.vld31.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld31kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld31}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld32)
    {
      vld32 = (hdata.vld32.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld32kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld32}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld33)
    {
      vld33 = (hdata.vld33.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld33kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld33}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld34)
    {
      vld34 = (hdata.vld34.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld34kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld34}</p>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld35)
    {
      vld35 = (hdata.vld35.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld35kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld35}</p>
          </Col>
        </Row>) : null;
    }

    if (vld31 != null || vld32 != null || vld33 != null || vld34 != null || vld35 != null )
    {
      pan6 = (<Panel  collapsible defaultExpanded style={{background: '#fff'}}>
                {vld31}
                {vld32}
                {vld33}
                {vld34}
                {vld35}
              </Panel>)
    }
    if (pan5 != null || pan6 != null )
    {
      hpan3 = (<Row className="show-grid" >
                  <Col xs={12} md={6} sm={6} >
                    {pan5}
                  </Col>
                  <Col xs={12} md={6} sm={6} >
                    {pan6}
                  </Col>
                </Row>)
    }

    // pan 7 (vld 36 t/m vld 40)
    if (hdata.vld36)
    {
      vld36 = (hdata.vld36.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <Panel  collapsible defaultExpanded style={{background: '#fff',overflowY: 'auto',overflowX: 'hidden', height: '150px'}}>
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld36kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld36}</p>
            </Panel>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld37)
    {
      vld37 = (hdata.vld37.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <Panel  collapsible defaultExpanded style={{background: '#fff',overflowY: 'auto',overflowX: 'hidden', height: '150px'}}>
              <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld37kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld37}</p>
            </Panel>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld38)
    {
      vld38 = (hdata.vld38.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <Panel  collapsible defaultExpanded style={{background: '#fff',overflowY: 'auto',overflowX: 'hidden', height: '150px'}}>
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld38kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld38}</p>
            </Panel>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld39)
    {
      vld29 = (hdata.vld39.length > 0) ? 
        (<Row className="show-grid" >
          <Col xs={12} md={12} sm={12} >
            <Panel  collapsible defaultExpanded style={{background: '#fff',overflowY: 'auto',overflowX: 'hidden', height: '150px'}}>
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld39kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld39}</p>
            </Panel>
          </Col>
        </Row>) : null;
    }
    if (hdata.vld40)
    {
      vld40 = (hdata.vld40.length > 0) ? 
        (<Row className="show-grid">
          <Col xs={12} md={12} sm={12} >
            <Panel  collapsible defaultExpanded style={{background: '#fff',overflowY: 'auto',overflowX: 'hidden', height: '150px'}}>
                <p dir="ltr" style={{fontSize:'80%',fontWeight: 'normal',lineHeight: '100%', color: hdata.vld40kl, paddingTop: '5px', maxHeight: '24px'}}>{hdata.vld40}</p>
            </Panel>
          </Col>
        </Row>) : null;
    }

    if (vld36 != null || vld37 != null || vld38 != null || vld39 != null || vld40 != null )
    {
      pan7 = (<Row className="show-grid" >
                <Col xs={12} md={12} sm={12} >
                  {vld36}
                  {vld37}
                  {vld38}
                  {vld39}
                  {vld40}
                </Col>
              </Row>) 
    }
    // einde panels

    inhoud = (
      <div>
        {vld1}
        {vld2}
        {vld3}
        {vld4}
        {vld5}
        {hpan1}
        {hpan2}
        {hpan3}
        {pan7}
      </div>);
    return inhoud;
  },

  _getRowHeight (index, hdata, type) {
    let size = 0;
    let pan1 =0;
    let pan1b =0;
    let pan2 =0;
    let pan2b =0;
    let pan3 =0;
    let pan3b =0;
    let pan4 =0;

    if (hdata == null) {return 0};

    if (type == 'faktlijnen')
    {
      size = 80;
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        size=400;
      }
      else
      {
        size = 225;
      }
      return size;
    }

    if (type == 'GetConsFin')
    {
      size = 110;
      return size;
    }

    if (type == 'ActLijstConsOud')
    {
      size = 80;
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        if (hdata.secondaryText.length > 2)
        {
          let tabhoog = Math.ceil((hdata.secondaryText.length / 70) * 60);
          if (tabhoog > 540)
          {
            size = 460;
          }
          else
          {
            size=130 + tabhoog;
          }
        }
        else
        {
          size=130;
        }

      }
      else
      {
        if (hdata.secondaryText.length > 2)
        {
          let tabhoog = Math.ceil((hdata.secondaryText.length / 70) * 60);
          if (tabhoog >= 540)
          {
            size = 460;
          }
          else
          {
            size=130 + tabhoog;
          }
        }
        else
        {
          size=130;
        }
      }
      return size;
    }



    if (type == 'klanten')
    {
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        size=350;
      }
      else
      {
        size = 30;
      }
      return size;
    }    

    if (type == 'LstVerl')
    {
      size = 180;
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        size=180;
      }
      else
      {
        size = 100;
      }
      return size;
    }



    if (type == 'GetAfpFakturen')
    {
      size = 265;
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        if (hdata.vld21.length > 0 || hdata.vld23.length > 0 || hdata.vld24.length > 0  )
        {
          size = size + 325;
        }
        else
        {
          size = size + 230;
        }
      }
      return size;
    }

    if (type == 'GetRepAfwMnd')
    {
      size = 265;
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        size = size + 230;
      }
      return size;
    }



    if (type == 'Tijdconsparam')
    {
      size = 265;
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        size = 425;
      }
      return size;
    }

    if (type == 'Allkennis')
    {
      return 30;
    }

    if (type == 'Feestdagen')
    {
      return 30;
    }

    if (type == 'Mailtemp')
    {
      size = 120;
      return size;
    }

    if (type == 'Tijdparam')
    {
      size = 190;
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        size = 260;
      }
      return size;
    }

    if (type == 'consnw')
    {
      return 30;
    }

    if (type == 'reqnw')
    {
      if (screen.width < 500)
      {
        var lengte = (hdata.vld1.length / 35);
        if (lengte < 1)
        {
          return 30;
        }
        if (lengte < 2)
        {
          return 60;
        }
        if (lengte < 3)
        {
          return 90;
        }
        if (lengte < 4)
        {
          return 120;
        }
      }
      if (screen.width < 750)
      {
        var lengte = (hdata.vld1.length / 70);
        if (lengte < 1)
        {
          return 30;
        }
        if (lengte < 2)
        {
          return 60;
        }
        if (lengte < 3)
        {
          return 90;
        }
        if (lengte < 4)
        {
          return 120;
        }
      }
      return 30;
    }

    if (type == 'ActLijstCons')
    {
      if (screen.width < 750)
      {
        return 70;
      }
      else
      {
        var vlengte = hdata.primaryText.length + 12;
        var w = window.innerWidth - 5;
        var hulph = (w * 0.7) *0.03;
        var hoog1 = vlengte / hulph;
        if (hoog1 < 1) {hoog1 = 1};
        let hoog = hoog1 * 30;  
        return hoog;
      }
    }
    

    if (type == 'GetConsAllRequest')
    {
      if (screen.width < 750)
      {
        return 70;
      }
      else
      {
        return 30;
      }
    }



    if (type == 'GetAllKandReq')
    {
      if (screen.width < 750)
      {
        return 70;
      }
      else
      {
        return 30;
      }      
    }


    if (type == 'GetAllContAcc')
    {
      return 30;
    }



    if (type == 'tefakt')
    {
      size = 289;
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        size = size + 370;
      }
      return size;
    }

    if (type == 'lijntefact')
    {
      size = 260;
      if (window.innerWidth < 500 || window.innerHeight < 500)
      {
        size = size + 296;
      }
      return size;
    }




    if (hdata.vld1) {
      if (hdata.vld1.length < 3) { return 0};
    }
    else
    {
      return 0;
    };

    size = 80;
    if (hdata.vld1.length > 1) {size = size + 32;} 
    if (hdata.vld2)
    {
      if (hdata.vld2.length > 1) {size = size + 32}
    }
    if (hdata.vld3)
    {
      if (hdata.vld3.length > 1) {size = size + 32}
    }
    if (hdata.vld4)
    {
      if (hdata.vld4.length > 1) {size = size + 32}
    }
    if (hdata.vld5)
    {
      if (hdata.vld5.length > 1) {size = size + 32}
    }
    if (hdata.vld6)
    {
      if (hdata.vld6.length > 1) 
      {
        pan1 = pan1 + 1;
      }
    }
    if (hdata.vld7)
    {
      if (hdata.vld7.length > 1)       
      {
        pan1 = pan1 + 1;
      }
    }
    if (hdata.vld8)
    {
      if (hdata.vld8.length > 1) 
      {
        pan1 = pan1 + 1;
      }
    }
    if (hdata.vld9)
    {
      if (hdata.vld9.length > 1) 
      {
        pan1 = pan1 + 1;
      }

    }
    if (hdata.vld10)
    {
      if (hdata.vld10.length > 1) 
      {
        pan1 = pan1 + 1;
      }
    }
    if (hdata.vld11)
    {
      if (hdata.vld11.length > 0) 
      {
        pan1b = pan1b + 1;
      }
    }
    if (hdata.vld12)
    {
      if (hdata.vld12.length > 0)       
      {
        pan1b = pan1b + 1;
      }

    }
    if (hdata.vld13)
    {
      if (hdata.vld13.length > 0) 
      {
        pan1b = pan1b + 1;
      }
    }
    if (hdata.vld14)
    {
      if (hdata.vld14.length > 0) 
      {
        pan1b = pan1b + 1;
      }
    }
    if (hdata.vld15)
    {
      if (hdata.vld15.length > 0) 
      {
        pan1b = pan1b + 1;
      }
    }
    if (pan1 > 0 || pan1b > 0)
    {
       if (pan1 > pan1b)
       {
          if (window.innerWidth < 500 || window.innerHeight < 500)
          {
            if (pan1b > 0)
            {
                size = size + (32 * pan1) + (32 * pan1b) + 104;
            }
            else
            {
                size = size + (32 * pan1) + (32 * pan1b) + 52;
            }
          }
          else
          {
            size = size + (32 * pan1) + 52;
          }
       }
       else
       {
          if (window.innerWidth < 500 || window.innerHeight < 500)
          {
            if (pan1 > 0)
            {
                size = size + (32 * pan1) + (32 * pan1b) + 104;
            }
            else
            {
                size = size + (32 * pan1) + (32 * pan1b) + 52;
            }
          }
          else
          {
              size = size + (32 * pan1b) + 52;
          }
       }
    }

    if (hdata.vld16)
    {
      if (hdata.vld16.length > 0) 
      {
        pan2 = pan2 + 1
      }
    }
    if (hdata.vld17)
    {
      if (hdata.vld17.length > 0)       
      {
        pan2 = pan2 + 1
      }

    }
    if (hdata.vld18)
    {
      if (hdata.vld18.length > 0) 
      {
        pan2 = pan2 + 1
      }
    }
    if (hdata.vld19)
    {
      if (hdata.vld19.length > 0) 
      {
        pan2 = pan2 + 1
      }

    }
    if (hdata.vld20)
    {
      if (hdata.vld20.length > 0) 
      {
        pan2 = pan2 + 1
      }
    }
    if (hdata.vld21)
    {
      if (hdata.vld21.length > 0) 
      {
        pan2b = pan2b + 1
      }
    }
    if (hdata.vld22)
    {
      if (hdata.vld22.length > 0)       
      {
        pan2b = pan2b + 1
      }

    }
    if (hdata.vld23)
    {
      if (hdata.vld23.length > 0) 
      {
        pan2b = pan2b + 1
      }
    }
    if (hdata.vld24)
    {
      if (hdata.vld24.length > 0) 
      {
        pan2b = pan2b + 1
      }
    }
    if (hdata.vld25)
    {
      if (hdata.vld25.length > 0) 
      {
        pan2b = pan2b + 1
      }
    }


    if (pan2 > 0 || pan2b > 0)
    {
       if (pan2 > pan2b)
       {
          if (window.innerWidth < 500 || window.innerHeight < 500)
          {
            if (pan2b > 0)
            {
                size = size + (32 * pan2) + (32 * pan2b) + 104;
            }
            else
            {
                size = size + (32 * pan2) + (32 * pan2b) + 52;
            }
          }
          else
          {
            size = size + (32 * pan2) + 52;
          }
       }
       else
       {
          if (window.innerWidth < 500 || window.innerHeight < 500)
          {
            if (pan2 > 0)
            {
                size = size + (32 * pan2) + (32 * pan2b) + 104;
            }
            else
            {
                size = size + (32 * pan2) + (32 * pan2b) + 52;
            }
          }
          else
          {
              size = size + (32 * pan2b) + 52;
          }
       }
    }




    if (hdata.vld26)
    {
      if (hdata.vld26.length > 0) 
      {
        pan3 = pan3 + 1
      }
    }
    if (hdata.vld27)
    {
      if (hdata.vld27.length > 0)       
      {
        pan3 = pan3 + 1
      }

    }
    if (hdata.vld28)
    {
      if (hdata.vld28.length > 0) 
      {
        pan3 = pan3 + 1
      }
    }
    if (hdata.vld29)
    {
      if (hdata.vld29.length > 0) 
      {
        pan3 = pan3 + 1
      }

    }
    if (hdata.vld30)
    {
      if (hdata.vld30.length > 0) 
      {
        pan3 = pan3 + 1
      }
    }
    if (hdata.vld31)
    {
      if (hdata.vld31.length > 0) 
      {
        pan3b = pan3b + 1
      }
    }
    if (hdata.vld32)
    {
      if (hdata.vld32.length > 0)       
      {
        pan3b = pan3b + 1
      }

    }
    if (hdata.vld33)
    {
      if (hdata.vld33.length > 0) 
      {
        pan3b = pan3b + 1
      }
    }
    if (hdata.vld34)
    {
      if (hdata.vld34.length > 0) 
      {
        pan3b = pan3b + 1
      }
    }
    if (hdata.vld35)
    {
      if (hdata.vld35.length > 0) 
      {
        pan3b = pan3b + 1
      }
    }

    if (pan3 > 0 || pan3b > 0)
    {
       if (pan3 > pan3b)
       {
          if (window.innerWidth < 500 || window.innerHeight < 500)
          {
            if (pan3b > 0)
            {
                size = size + (32 * pan3) + (32 * pan3b) + 104;
            }
            else
            {
                size = size + (32 * pan3) + (32 * pan3b) + 52;
            }
          }
          else
          {
            size = size + (32 * pan3) + 52;
          }
       }
       else
       {
          if (window.innerWidth < 500 || window.innerHeight < 500)
          {
            if (pan3 > 0)
            {
                size = size + (32 * pan3) + (32 * pan3b) + 104;
            }
            else
            {
                size = size + (32 * pan3) + (32 * pan3b) + 52;
            }
          }
          else
          {
              size = size + (32 * pan3b) + 52;
          }
       }
    }


    if (hdata.vld36)
    {
      if (hdata.vld36.length > 0) 
      {
        size = size + 170;
        pan4 = 52;
      }
    }
    if (hdata.vld37)
    {
      if (hdata.vld37.length > 0)       
      {
        size = size + 170;
        pan4 = 52;
      }

    }
    if (hdata.vld38)
    {
      if (hdata.vld38.length > 0) 
      {
        size = size + 170;
        pan4 = 52;
      }
    }
    if (hdata.vld39)
    {
      if (hdata.vld39.length > 0) 
      {
        size = size + 170;
        pan4 = 52;
      }

    }
    if (hdata.vld40)
    {
      if (hdata.vld40.length > 0) 
      {
        size = size + 170;
        pan4 = 52;
      }
    }
    size = size;
    return size;
  },  



};
