const React = require('react');
const ReactDOM = require('react-dom');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const TextField = require('../matui/text-field.jsx');
const IconButOver = require('../matui/icon_but_over.jsx');
const Contstafunc = require('./cont_func.jsx');
const AlgFuncUpd = require('../functions/algemeen');
const SubLijst = require('./sublijstnw.jsx');
const BlokLijst = require('./lijstblok.jsx');

var Panel = require("react-bootstrap").Panel;
var PanelGroup = require("react-bootstrap").PanelGroup;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Nav = require("react-bootstrap").Nav;
var NavItem = require("react-bootstrap").NavItem;
const DateTime = require('../matui/utils/date-time');
const Actpopup = require('./popupfixtdef.jsx');
const PopupAcc = require('./popupacc.jsx');
const ClsAct = require('./cls_activ.jsx');
const PopupFix = require('./popdeftexture.jsx');
const ClsFixt = require('./cls_fixtures.jsx');

const Lampvorm = require('./lampvorm.jsx');
const PopupLamp = require('./popdefadres.jsx');
const Chanvorm = require('./schemavorm.jsx');
const PopupChan = require('./popdeftexture.jsx');


let Accvormnew = React.createClass({
  mixins: [PureRenderMixin],

  contextTypes:{
    router: React.PropTypes.object    
  },
  getInitialState() {
    return {
      seltxtcol:0,
      txtdata:[],
      popupact:null,
      popupfix:null,
      popupacc:null,
      popdocaanm:null,
      popupcontr:null,
      popupbijl:null,
      popupdoc:null,
      filename:'',
      expa:false,      
      bestandnaam:'',
      vorm:'ACC',
      data: Contstafunc.Dat_account(),
      sqldata: {param: 0},   
      edit:0,
      activkey:1,
      naam:'',
      route: '',
      searchkey: 'id',
      schemadata: [],
      height: (window.innerHeight).toString(),
      dgcolumns:[],      
      colFilter: [],
    };
  },
  render() {
    // Algemeen voor render
    let tot_zichtpop = null;
    let tot_zicht1 = null;
    let tot_zicht2 = null;
    var w = window.innerWidth - 5;
    var h = window.innerHeight - 300;
    var winbreed = window.innerWidth;
    let gridbreed = (winbreed > 1850) ? ((winbreed / 100) * 95) -800 : ((winbreed / 100) * 95) -500;   
    if (winbreed > 1850 && gridbreed > 900) {gridbreed = 900}
    if (winbreed < 1850 && gridbreed > 2000) {gridbreed = 2000}
    if (gridbreed < 1000) {gridbreed = 1000}

   // Einde afhandeling documenten

    let navigatie = (
        <Nav bsStyle="pills" activeKey={this.state.activkey} onSelect={this.handleSelect}>
            <NavItem eventKey={1} title="Item">Fixtures</NavItem>
            <NavItem eventKey={2} title="Item">Channels</NavItem>
        </Nav>);


    // bovenste paneel
    let webref = this.props.data.website;    
    let mailref = "mailto:"+this.props.data.email;    
    let geg5 = (<p dir="ltr" style={{fontSize:'100%',fontWeight: '90%',lineHeight: '100%', paddingTop: '5px'}}><span style={{color: '#2fa5c7', fontWeight: '90%'}}>Email: </span> <a href={mailref}>{this.props.data.email}</a></p>);
    let hmobphone = '';
    if (this.props.data.main_phone)
    {
      hmobphone = this.props.data.main_phone.replace('(0)','');
      hmobphone = hmobphone.replace('(','');
      hmobphone = hmobphone.replace(')','');
      hmobphone = hmobphone.replace('/','');
      hmobphone = hmobphone.replace('-','');
      hmobphone = hmobphone.replace('.','');
      hmobphone = hmobphone.replace(' ','');
      hmobphone = "tel:"+hmobphone;
    }
    let geg6 = (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'normal'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Phone: </span> {this.props.data.main_phone}</p>);
    let geg7 = (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'normal'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Fax: </span> {this.props.data.fax}</p>);
    let geg8 = (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'normal'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Adress: </span> {this.props.data.street}</p>);
    let geg9 = (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'normal'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>City: </span> {this.props.data.zipcode_txt}</p>);
      // einde adres
    // Gegevens voor de facturatie deze komen in panact
    let lst_lamp = (App.KlantId.length > 3 && this.state.activkey == 1) ? (<SubLijst ref="ActLijst" route='GetAllLampen' hdata={{accountid:App.KlantId}} height={(window.innerHeight) - 300} mhoog={250} breed={gridbreed} hoog={(window.innerHeight) - 300} sleutel={App.KlantId} behandel={false} zoeken={false} klik={this.ClickLamp} />) : null;
    let lst_fixt = (App.KlantId.length > 3 && this.state.activkey == 2) ? (<SubLijst ref="ActLijst" route='GetAllFixtures' hdata={{accountid:App.KlantId}} height={(window.innerHeight) - 300} mhoog={250} breed={gridbreed} hoog={(window.innerHeight) - 300} sleutel={App.KlantId} behandel={false} zoeken={false} klik={this.ClickChan}/>) : null;

    // Einde lijsten

    let PTitedit = (<h3 className="panel-title">
                      <Col xs={4} md={4} sm={4} >
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                        <IconButOver                       
                          style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                          iconClassName="fa fa-pencil-square-o" 
                          tooltip="Aanpassen Gegevens" 
                          onClick={this.EditClick}/>
                      </Col></h3>);    

    let pan2 = (  <div id='cns_hfdpan' style={{marginBottom: '16px', marginTop:'70px', padding: '24px', wordWrap: 'break-word', boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2)', display: 'block', backgroundColor: '#fff', borderRadius: '2px',position: 'relative', listStyle: 'none', outline: '0', fontSize: '100%', verticalAlign: 'baseline', boxSizing: 'inherit',}}>
                     <div id='cns_hfdpan1' style={{margin:'0',padding:'0',border:'0',outline:'0',fontSize:'100%',verticalAlign:'baseline',background: '0 0', boxSizing: 'inherit', display: 'block'}}>
                       <div id='card_header1' style={{margin: '-85px auto 0', position: 'relative', width: 'auto'}}>
                          <div id='photo_header1' style={{backgroundColor:'rgba(47, 165, 199, 0.85)',  textAlign: 'center', border: '4px solid #fff', boxSizing: 'content-box', margin: 'auto', position: 'relative', boxShadow: 'inset 0 1.5px 3px 0 rgba(0,0,0,.15), 0 1.5px 3px 0 rgba(0,0,0,.15)',marginTop:'-65px',width:'128px',height:'128px',borderRadius:'50%'}}>
                            <div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox', display: 'flex', WebkitFlexDirection: 'column', marginTop:'10px',
                                    MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '88px', width: '88px', borderRadius: '50%'}}>

                              <div dir="ltr" style={{fontSize: '14px',fontWeight: '400',lineHeight: '16px;margin-bottom: 2px',color: 'white',width: '128px',height: 'auto', borderRadius: '50%', marginTop: '20px'}}>{this.props.data.vld1}
                              </div>
                            </div>
                          </div>
 
                          <div id='carbody1' style={{textAlign:'center',position: 'relative',marginBottom:'30px'}}>
                            <div style={{padding: '0 40px',marginBottom:'0'}}>
                              <div style={{WebkitJustifyContent: 'center',MsFlexPack: 'center', justifyContent: 'center',display: '-webkit-flex', display: '-ms-flexbox',display: 'flex',WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems:'center'}}>
                                <p dir="ltr" style={{cursor: 'pointer',fontSize:'18px',fontWeight: '400',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}><a href={webref}>{this.props.data.website}</a></p>
                              </div>         
                              <div style={{marginBottom: '0', display: '-webkit-flex', display: '-ms-flexbox',display: 'flex', margin: '12px 0', marginLeft: '0', marginRight: '0', padding: '0', wordWrap: 'break-word',
                                    boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 0px 0px rgba(0,0,0,.2)', backgroundColor: '#fff',borderRadius:'2px', position: 'relative', listStyle: 'none'}}>
                                    <div style={{border: '0', textDecoration: 'none', MsTouchAction: 'manipulation', touchAction: 'manipulation', padding: '8px 12px 12px', WebkitFlex: '1 1 0',MsFlex: '1 1 0px', flex: '1 1 0',
                                            fontWeight: '700',backgroundColor: 'transparent', color: '#0084bf', margin: '0',fontSize: '100%', background: '0 0', verticalAlign: 'baseline'}}>
                                      {geg5}
                                      {geg6}
                                      {geg7}
                                      {geg8}
                                      {geg9}
                                    </div>
                              </div>
                             </div>
                          </div>
                          <div id='card_header1' style={{right: '24px', position: 'absolute', top: '75px'}}>
                            {PTitedit}
                          </div>
                       </div>
                     </div>
                  </div>)

    let icdel = (this.state.seltxtcol > 0) ? (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                  iconClassName="fa fa-trash-o" 
                  tooltip="Delete selected fixtures" 
                  onClick={this.DeleteClick}/>) : null;

    let PTitact = (App.KlantId.length > 3 && this.state.activkey == 1) ? (<h3 className="panel-title">
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        tooltip="New Fixture"
                        iconClassName="fa fa-plus" 
                        onClick={this.NewLamp}/>
                        {icdel}
                        </h3>) :
                        (App.KlantId.length > 3 && this.state.activkey == 2) ? (<h3 className="panel-title">
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        tooltip="New Channel"
                        iconClassName="fa fa-plus" 
                        onClick={this.NewChan}/>
                        {icdel}
                        </h3>) : null;    


    // enkel voor mobile
    // SubPaneel Motivering / Opmerkingen
    

    // einde enkel voor mobile

    let vldpanact = (
        <Panel  header={PTitact} eventKey="14act"  bsStyle="grblok" style={{background: '#fff'}}>
          <Row className="show-grid">
            <Col xs={12} md={12} sm={12} >
              {lst_lamp}
              {lst_fixt}
            </Col>
          </Row>
        </Panel>);
    let panken = (<div id='cns_hfdpan2' style={{marginTop:'16px',padding:'0',border:'0',outline:'0',fontSize:'100%',verticalAlign:'baseline',background: '0 0', boxSizing: 'inherit', display: 'block',position:'relative'}}>
                       <div style={{WebkitAnimationName:'fade-in',animationName: 'ade-in', WebkitAnimationDuration:'.5s',animationDuration: '.5s', WebkitAnimationIterationCount:'1', 
                            animationIterationCount: '1',WebkitAnimationTimingFunction: 'cubic-bezier(0,0,.2,1)', AnimationTimingFunction: 'cubic-bezier(0,0,.2,1)', WebkitAnimationFillMode: 'forwards', animationFillMode: 'forwards'}}>
                          <section style={{marginBottom:'0',backgroundColor:'white',position: 'relative',zIndex: '0', margin: '16px 0', padding: '24px', wordWrap: 'break-word', boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2)',
                          display: 'block', borderRadius: '2px', listStyle: 'none', fontSize:'11px',overflow:'hidden'}}>
                            {navigatie}
                            {vldpanact}
                          </section>
                       </div>
                     </div>);

    tot_zicht2 = (App.KlantId == 0  || App.KlantId.length > 4) ? (
    <div className='rowlist' style={{marginLeft:'15px', marginBottom: '60px', marginRight: '10px'}}>
        {pan2}
        {panken}
      </div>) : null;    

    tot_zichtpop = (<div>
            {this.state.popupact}
            {this.state.popupacc}
            {this.state.popuplib}
            {this.state.popupfix}
            </div>);
    // Einde opbouw 

    return (<div>
        {tot_zichtpop}
        {tot_zicht2}
      </div>)
  },
  DeleteClick() {
    let hudata = {data:this.state.txtdata}
    ClsFixt.DelFixture(hudata)
    .then(data => {
      this.setState({seltxtcol:0});
      if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.KlantId,'GetAllFixturesAcc', {id:App.KlantId});}   
    })
    .catch(data => {
        return true;
    });
  },
  zetedit(skey){
    if (App.master.state.AppHfdMenKeuze)
    {
      if (App.master.state.AppHfdMenKeuze.FiltData) {   
        skey = App.master.state.AppHfdMenKeuze.FiltData[0].id;
      }
      App.master.setState({AppHfdMenKeuze:null});
    }      
    this.setState({edit:0});
    this.setState({seltxtcol:0});
    this.state.activkey = 0;
    this.setState({activkey:1});
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.KlantId,'GetAllLampen', {accountid:App.KlantId});}   

  },  
  handleSelect(selectedKey) {
    this.setState({activkey:selectedKey});
  },

  // Functies naar gegevens gaan
  EditClick(selectedKey) {
    let hdata = {id:App.KlantId}; 
    AlgFuncUpd.InsLog(App.KlantId,'AC','EDIT','GetAllAccountsNw',this.props.RefreshDataNw);
    this.setState({popupacc:(<PopupAcc  id='popupacc' zindex={999999} data={hdata} zetleeg={this.editsluiten} Sluiten={this.editsluiten}></PopupAcc>)})
    return;
    // Einde ophalen gegevens
  },
  CtrlKlik(data) {
    var index, column;    
    let arra;
    // controleren of arra && of || bevat
    let hudata = data;
    let ColFilter = ['checked'];
    let value = '2';
    var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = eval('row.'+filCol);            
          }
          else
          {
            arra = ''; 
          }
          if (typeof arra === 'string' || arra instanceof String)
          {

          }
          else
          {
            arra='';
          }
          if(arra.indexOf(value) >= 0)
          {
            return arra.indexOf(value) >= 0
          }
        }
      }) : data;
      this.setState({seltxtcol:filteredRows.length});
      this.setState({txtdata:data});
    },
  NewLamp() {
    let hdata = {id:'0',name:'',accountname:this.props.data.vld1,accountid:App.KlantId,vischan:0,unvischan:0,ClampId:0,schemid:0,adresid:0,consid:0};
    this.setState({popuplib:(<PopupLamp  id='popupact1' data={hdata} route = 'activiteit' zindex={999999} Sluiten={this.sluitlamppopup}  ></PopupLamp>)})
    return;
  },
  sluitlamppopup(data) {
    this.setState({popuplib:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllLampen', {accountid:App.KlantId});}   
  },
  ClickLamp(waarde, data) {
    this.setState({popuplib:(<Lampvorm  id='popuplib1' popup={true} data={data} zindex={999999} Sluiten={this.sluitlamppopup}  ></Lampvorm>)})
  },
  NewChan(){
    let hdata = {
        id:'0',
        name:'',
        consid:0,
        accountname:this.props.data.vld1,
        accountid:App.KlantId,        
        mihigh:'0',
        mahigh:'0',
        stphigh:'0',
        milow:'0',
        malow:'0',
        stplow:'0',
        statextnumb:'0',
        stptextnumb:'0',
        textchar:''};
    this.setState({popuplib:(<PopupChan  id='popupact1' data={hdata} route = 'activiteit' zindex={999999} Sluiten={this.sluitchanpopup}  ></PopupChan>)})
  },
  ClickChan(waarde, data) {
    this.setState({popuplib:(<Chanvorm  id='popuplib1' popup={true} data={data} zindex={999999} Sluiten={this.sluitchanpopup}  ></Chanvorm>)})
  },

  sluitchanpopup(data) {
    this.setState({popuplib:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllFixtures', {accountid:App.KlantId});}   
  },


  NewFix(){
    let hudata = {
        id:'0',
        name:'',
        accountname:this.props.data.vld1,
        accountid:App.KlantId,
        mihigh:'0',
        mahigh:'0',
        stphigh:'0',
        milow:'0',
        malow:'0',
        stplow:'0',
        statextnumb:'0',
        stptextnumb:'0',
        textchar:''};

    this.setState({popupfix:(<PopupFix  id='popupact1' data={hudata} route = 'activiteit' zindex={999999} Sluiten={this.sluitfixpopup}  ></PopupFix>)})
  },
  sluitfixpopup(data) {
    this.setState({popupfix:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.KlantId,'GetAllFixturesAcc', {id:App.KlantId});}   
  },
  KlikAct(waarde, data) {
    this.setState({laden:1});
    this.setState({popupact:(<Actpopup  id='popupact1' data={data} route = 'activiteit' zindex={999999} Sluiten={this.sluitactpopup} ></Actpopup>)})
  },    
  sluitactpopup(data) {
    this.setState({popupact:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.KlantId,'GetAllFixturesAcc', {id:App.KlantId});}   
  },
  zetklantgeg(data) {
      if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.KlantId,'ActLijstAcc', {id:App.KlantId});}    
  },
  editsluiten(data) {
    this.setState({popupacc:null})
    if (this.props.loadDataNw && data) {this.props.loadDataNw(data,'ACC',this.state.activkey) }
  },
});
module.exports = Accvormnew;






