const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const StylePropable = require('../matui/mixins/style-propable');
const StyleResizable = require('../matui/mixins/style-resizable');
const TextField = require('../matui/text-field.jsx');
const Zoekpopup = require('./zoekpopup.jsx');
const DateTime = require('../matui/utils/date-time');
const Checkbox = require('../matui/checkbox.jsx');
const Dialog = require('../matui/dialog.jsx');
var Panel = require("react-bootstrap").Panel;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Button = require("react-bootstrap").Button;
const ClsCons = require('./cls_consultant.jsx');

// Definietie classe
const Detpopup = React.createClass({
  mixins: [StylePropable, StyleResizable,PureRenderMixin],
  getInitialState() {
    return {
      disabelen: false,
      data:{},
      dopen:false,
      sleutel:0,
      bedrag:0,
      err_naam: '',
      err_afk:'',
      consname:'',
      Type:new Immutable.List ([
              { payload: 'F', text: 'Feestdag' },
              { payload: 'BF', text: 'Betaalde feestdag'}]),        
    };
  },
  componentDidMount() {
    if (this.props.data) 
    {
      if (this.props.route == 'afpbank') {
        if (this.refs.InpNaam) 
        {
          this.refs.InpNaam.zetmenu(App.master.AppBanken())
        };
      }
      this.openen(this.props.data.id,this.props.data);
    }
  },   
  componentWillMount() {
    App.master.AppBanken();    
    App.master.AppConsultants();
    App.master.AppAccount();
    App.master.AppOwners();
  },
  render() {
    var h = window.innerHeight * .8;
    var ch = window.innerHeight * .78;

    var maxhdial = window.innerHeight + 'px';
    var maxhdialrol = (window.innerHeight - 250) + 'px';

    let formatChars = {"9": "[0-9,.]",};
    // Velden definitie
    let vld_naam = 
      (this.props.route == 'allkennis') ? ( <TextField
          ref='InpNaam'
          floatingLabelText="Alternatieven"
          fullWidth={true}
          errorText={this.state.err_naam}          
          multiLine={true} />):
      (this.props.route == 'afpuntdet') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Bedrag"
          ref="InpNaam" 
          mask='99999999'
          formatChars={formatChars}
          errorText={this.state.err_naam} />):
      (this.props.route == 'opmerking') ? ( <TextField
          ref='InpNaam'
          hintText="Opmerking"
          fullWidth={true}
          errorText={this.state.err_naam}          
          multiLine={true} />):
      (this.props.route == 'afpbank') ?  (<TextField
            style={{marginRight: '15px', marginLeft:'20px'}}
            floatingLabelText="Bank"
            ref="InpNaam" 
            PopUp={false}
            menu={true}
            setfilter={false}
            autotext={true}
            errorText={this.state.err_naam}
            menuItems={App.banken}
            zien={true}  />):
      (this.props.route == 'afsluiten') ? (<TextField
            ref='InpNaam'
            hintText="Oplossing"
            fullWidth={true}
            multiLine={true} />):(<TextField
            style={{marginRight: '15px', marginLeft:'20px',display:'none'}}
            floatingLabelText="Bank"
            ref="InpNaam" 
            PopUp={false}
            menu={true}
            setfilter={false}
            autotext={true}
            errorText={this.state.err_naam}
            menuItems={App.banken}
            zien={true}  />);  

    // Alles nodig voor een feestdag
    let vld_omschrijving = (this.props.route == 'feestdag') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Omschrijving"
          ref="InpOmschrijving" 
          errorText={this.state.err_omschrijving} />):null;  
    let vld_oms2 = (this.props.route == 'allkennis') ? ( <TextField
          ref='InpOmschrijving'
          floatingLabelText="Uitleg"
          fullWidth={true}
          rows={7}
          errorText={this.state.err_naam}          
          multiLine={true} />):null;  
    let vld_datum = (this.props.route == 'feestdag') ? (<TextField
          style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Datum"
          disabled={this.state.disabelen}
          errorText={this.state.err_datum}           
          ref="InpDatum" 
          type="date"/>): null;   
    let vld_datbank = (this.props.route == 'afpbank') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Datum Boeking"
          disabled={this.state.disabelen}
          ref="InpDatum" 
          errorText={this.state.err_datum}           
          type="date"/>): null;   
    let vld_typeafp = 
      (this.props.route == 'afpuntdet2') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Type afpunting"
          ref="InpTypeAfp" 
          errorText={this.state.err_naam} />): null;
    let vld_type = (this.props.route == 'feestdag') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
            floatingLabelText="Type"
            disabled={this.state.disabelen}
            veld='vld_type'
            ref="InpType" 
            menu={true}
            menuItems={this.state.Type}
            errorText={this.state.err_type} 
            zien={true}  />):
        (this.props.route == 'afpcredit') ? (<TextField
            style={{marginRight: '15px', marginLeft: '20px'}}
            floatingLabelText="Creditnota"
            veld='vld_type'
            ref="InpType" 
            autotext={true}
            PopUp={true}
            CalPopup={this.CreditZoek}               
            onMenuChange={this._onMenuChange}
            menuItems={this.state.Type}
            errorText={this.state.err_type} 
            zien={false}  />):null; 
    let vld_afk = (this.props.route == 'allkennis') ? (<TextField
            ref='InpAfk'
            errorText={this.state.err_afk}            
            hintText="Omschrijving"/>): (null);  
    let vldzoekpopup = (<Zoekpopup  id='zoekpopup1' route = '' klik={this.klikzkpop} ref='RefZoekPopup'></Zoekpopup>);
    let vld_opnemen= (this.props.route == 'feestdag') ? (<Checkbox
        style={{marginTop: '35px', marginLeft: '20px'}}
        disabled={this.state.disabelen}
        name="Chk_opnemen"
        ref="Chk_opnemen"
        label="Vrij op te nemen"/>):null; 
    let feestzicht1 = (this.props.route == 'feestdag') ? (
      <Row className="show-grid">
        <Col xs={12} md={6} sm={6} >
          {vld_datum}
          {vld_omschrijving}
        </Col>
        <Col xs={12} md={6} sm={6} >
          {vld_opnemen}
          {vld_type}
        </Col>
      </Row>) : null;   
    let afpcredit = (this.props.route == 'afpcredit') ? (
      <div className='row' style={{width:'100%',marginBottom:'15px',display:'inline-block'}}>
            {vld_type}
      </div>) : null;   
    // Declaratie tijdparam
    let vld_jaar = (this.props.route == 'tijdparam' || this.props.route == 'tijdconsparam') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Jaar"
          defaultValue={this.state.data.jaar}  
          ref="InpJaar" 
          mask='9999'
          type="DEC"            
          disabled={this.state.disajaar}
          errorText={this.state.err_jaar} />):null;  
    let vld_verlof = (this.props.route == 'tijdparam' || this.props.route == 'tijdconsparam') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Verlofdagen"
          defaultValue={this.state.data.verlof}  
          ref="InpVerlof" 
          mask='99999'
          formatChars={formatChars}
          disabled={this.state.disabelen}
          errorText={this.state.err_verlof} />):null;  
    let vld_adv = (this.props.route == 'tijdparam' || this.props.route == 'tijdconsparam') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Compensatie"
          defaultValue={this.state.data.adv}  
          ref="InpAdv" 
          mask='99999'
          formatChars={formatChars}
          disabled={this.state.disabelen}
          errorText={this.state.err_comp} />):null;  
    let vld_feestd = (this.props.route == 'tijdparam' || this.props.route == 'tijdconsparam') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Feestdagen"
          defaultValue={this.state.data.feestdagen}  
          ref="InpFeest" 
          mask='99999'
          formatChars={formatChars}
          disabled={this.state.disabelen}
          errorText={this.state.err_feest} />):null;  
    let vld_budget = (this.props.route == 'tijdparam' || this.props.route == 'tijdconsparam') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Dagen gebudgeteerd"
          defaultValue={this.state.data.tot_dagen}  
          ref="InpBudget" 
          mask='99999'
          formatChars={formatChars}
          disabled={this.state.disabelen}
          errorText={this.state.err_budget} />): null;
    let vld_bedrag = (this.props.route == 'afpbank') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Bedrag"
          defaultValue={this.state.bedrag}
          disabled={true}
          ref="InpBudget" 
          mask='99999999'
          formatChars={formatChars}
          errorText={this.state.err_budget} />): null;
    let vld_ziek = (this.props.route == 'tijdparam') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Budget dagen ziek (jaar - medewerker)"
          ref="InpZiek" 
          mask='99999'
          formatChars={formatChars}
          disabled={this.state.disabelen}
          errorText={this.state.err_ziek} />): null;  
   let vld_oververl = (this.props.route == 'tijdconsparam') ? (<TextField
        style={{marginRight: '15px', marginLeft: '20px'}}
          floatingLabelText="Overgedragen verlof"
          defaultValue={this.state.data.over_verl}  
          ref="InpVover" 
          mask='99999'
          formatChars={formatChars}
          disabled={this.state.disabelen}
          errorText={this.state.err_budget} />):null; 
    let paramzicht1 = (this.props.route == 'tijdparam' || this.props.route == 'tijdconsparam') ? (
      <Row className="show-grid">
        <Col xs={12} md={6} sm={6} >
          {vld_jaar}
          {vld_verlof}
          {vld_adv}
          {vld_feestd}
        </Col>
        <Col xs={12} md={6} sm={6} >
          {vld_oververl}
          {vld_budget}
          {vld_ziek}
        </Col>
      </Row>) : null;   
    // Einde declaratie tijdparam
    let tot_zicht1 = (
      <div className='row' style={{width:'100%',marginBottom:'15px',display:'inline-block'}}>
        {afpcredit}
        {feestzicht1}
        {paramzicht1}
        {vld_afk}
        {vld_naam}
        {vld_oms2}
        {vld_datbank}
        {vld_bedrag}
      </div>);    
    // Einde velden definitei
    let titel = (this.props.route == 'opmerking') ? "Opmerkingen detail" : 
                (this.props.route == 'allkennis') ? "Vakgebied detail" : 
                (this.props.route == 'uitvoerder') ? "Veranderen uitvoerder" : 
                (this.props.route == 'tijdconsparam') ? "Parameters tijdsregistratie ("+this.state.consname+")" :                 
                (this.props.route == 'feestdag') ? "Detail feestdag" : 
                (this.props.route == 'tijdparam') ? "Detail tijdreg param" : 
                (this.props.route == 'afpuntdet') ? "Gestort bedrag" : 
                (this.props.route == 'afpbank') ? "Afpunten via bank" : 
                (this.props.route == 'afpcredit') ? "Selecteer Creditnota" : 
                (this.props.route == 'afsluiten') ? "Afsluiten klacht" : "";    
    let pop_but2 = (this.props.route == 'feestdag' || this.props.route == 'tijdparam' || this.props.route == 'tijdconsparam' || this.props.route == 'allkennis') ? 
      (   <Button 
          key={2}
          id='ButUpdPop'
          style={{float:'right', marginRight:'10px'}}          
          bsStyle="primary" 
          bsSize="small" 
          onClick={this.Butbewaren}>Bewaren
        </Button>):
      (this.props.route == 'afpuntdet') ? 
        ( <Button 
          key={2}
          id='ButUpdPop'
          style={{float:'right', marginRight:'10px'}}          
          bsStyle="primary" 
          bsSize="small" 
          onClick={this.Butafpunt}>Afpunten
        </Button>): 
      (this.props.route == 'afpbank') ? 
        ( <Button 
          key={2}
          id='ButUpdPop'
          style={{float:'right', marginRight:'10px'}}          
          bsStyle="primary" 
          bsSize="small" 
          onClick={this.ButafpBank}>Afpunten
        </Button>):null;

    let pop_but3 = (this.props.route == 'feestdag') ? 
      (   <Button 
          key={3}
          id='ButVerwPop'
          style={{float:'right', marginRight:'10px'}}          
          bsStyle="primary" 
          bsSize="small" 
          onClick={this.DelFeestdag}>Verwijderen
        </Button>): null;

    let voet = (<div style = {{margin:0,paddingTop:'10px',paddingLeft:'10px',paddingBottom:'10px',color:'white',lineHeight:'32px',bottom:'0px',position:'absolute',width: '100%' }} >
                {pop_but2}
                {pop_but3}
                </div>);

    let mwdth = (screen.width < 750) ? '100%' : '90%';
    let wdth = (screen.width < 750) ? '100%' : '90%';
    let cmwdth = (screen.width < 750) ? '100%' : '98%';
    let cwdth = (screen.width < 750) ? '100%' : '98%';
    let clft = (screen.width < 750) ? '0' : '5%';
    return ( 
      <Dialog
        ref='Ddetpopup'
        title={titel}
        footer= {voet}
        second={true}
        sluiten={this.props.Sluiten}
        style={{fontSize: '100%', width:wdth, maxWidth:mwdth}}
        contentStyle={{width:cwdth, maxWidth:cmwdth, left:clft,maxHeight:maxhdial,overflowY:'hidden'}}
        id="cont1"
        notoolb={true}
        hidetoolb={true}        
        paddingtop={this.props.paddingtop}
        zindex={this.props.zindex}        
        repositionOnUpdate={false}
        openImmediately={false}
        modal={true}>
        {tot_zicht1}
        {vldzoekpopup}
      </Dialog>)
  },
  sluiten(){
    this.refs.Ddetpopup.dismiss();
  },
  openen(sleutel,data){
    let that = this;
    this.state.sleutel = sleutel;
    this.state.data = data;    
    this.setState({err_naam: ""});

    if (sleutel == 0)
    {
      switch (this.props.route) 
      {
        case 'afpcredit':
          this.refs.InpType.setValue('');
          this.refs.InpType.setKey(0);
          break;
        case 'afpbank':
          this.setState({data: {id:sleutel,
           bankid:"",
           datum: "",
           bedrag: data.bedrag.replace('.','')}});
          this.setState({bedrag:data.bedrag.replace('.','')});
          if (that.refs.InpBudget) {that.refs.InpBudget.setValue(data.bedrag.replace('.',''))};
          break;        
        case 'feestdag':
          that.setState({data: {
           description:"",
           datum: "",
           type: "",
           opnemen: "0"}});
          if (that.refs.InpOmschrijving) {that.refs.InpOmschrijving.setValue("")};
          if (that.refs.InpDatum) {that.refs.InpDatum.setValue("")};
          if (that.refs.InpType) {that.refs.InpType.setValue("")};
          if (that.refs.InpType) {that.refs.InpType.setKey(0)};
          if (that.refs.Chk_opnemen) {that.refs.Chk_opnemen.setChecked(false)};
          that.setState({disabelen:false});
          break;        
        case 'tijdparam':
          that.setState({data: {
           jaar:"",
           verlof: 0,
           adv: 0,
           tot_dagen: 0,
           feestdagen: 0,
           ziek: 0}});        
          if (that.refs.InpJaar) {that.refs.InpJaar.setValue("")};
          if (that.refs.InpVerlof) {that.refs.InpVerlof.setValue("")};
          if (that.refs.InpAdv) {that.refs.InpAdv.setValue("")};
          if (that.refs.InpBudget) {that.refs.InpBudget.setValue("")};
          if (that.refs.InpFeest) {that.refs.InpFeest.setValue("")};
          if (that.refs.InpZiek) {that.refs.InpZiek.setValue("")};
          this.setState({disajaar:false});
          break;            
        case 'tijdconsparam':
          that.setState({data:data});  
          if (that.refs.InpJaar) {that.refs.InpJaar.setValue(data.jaar)};
          if (that.refs.InpVerlof) {that.refs.InpVerlof.setValue(data.verlof)};
          if (that.refs.InpAdv) {that.refs.InpAdv.setValue(data.adv)};
          if (that.refs.InpBudget) {that.refs.InpBudget.setValue(data.tot_dagen)};
          if (that.refs.InpFeest) {that.refs.InpFeest.setValue(data.feestdagen)};
          if (that.refs.InpVover) {that.refs.InpVover.setValue(data.over_verl)};
          that.setState({consname:data.consultant});
          that.setState({disajaar:false});
          break;            
        case 'allkennis':
          this.setState({data: {naam:""}});
          this.setState({data: {afk:""}});
          this.setState({data: {uitleg:""}});
          break;
        case 'opmerking':
          this.setState({data: {naam:""}});
          break;        
        case 'afpuntdet':
          this.setState({data: {bedrag:0}});
          break;        
      }          
      if (that.refs.InpNaam) {that.refs.InpNaam.setValue("")}

    }   
    else
    {
      let hdata = {id:sleutel};
      let vurl =(this.props.route == 'allkennis') ? './server/index.php?function=GetDetKennis':
                (this.props.route == 'feestdag') ? './server/index.php?function=GetDetFeestdag':
                (this.props.route == 'tijdparam') ? './server/index.php?function=GetDetTimeParam':
                (this.props.route == 'tijdconsparam') ? './server/index.php?function=GetDetConsTimeParam' :
                (this.props.route == 'fincons') ? './server/index.php?function=GetDetFinCons' : '';

      if (vurl.length > 2)
      {
        var aj2 = $.ajax({
          type: "POST",
          dataType: "json",
          url: vurl,
          data: JSON.stringify(hdata),
          success: function(data){
            that.setState({data:data});
            switch (that.props.route) 
            {
              case 'allkennis':
                that.refs.InpNaam.setValue(data.alternatief);
                that.refs.InpAfk.setValue(data.omschrijving);
                that.refs.InpOmschrijving.setValue(data.uitleg);
                break;
              case 'feestdag':
                that.refs.InpOmschrijving.setValue(data.description);
                that.refs.InpDatum.setValue(data.datum);
                that.refs.InpType.setValue(data.typetxt);
                that.refs.InpType.setKey(data.type);
                that.refs.Chk_opnemen.setChecked(false);
                if (data.opnemen == '1')
                {
                  that.refs.Chk_opnemen.setChecked(true);
                }
                that.setState({disabelen:true});                
                break;
              case 'tijdparam':
                if (that.refs.InpJaar) {that.refs.InpJaar.setValue(data.jaar)};
                if (that.refs.InpVerlof) {that.refs.InpVerlof.setValue(data.verlof)};
                if (that.refs.InpAdv) {that.refs.InpAdv.setValue(data.adv)};
                if (that.refs.InpBudget) {that.refs.InpBudget.setValue(data.tot_dagen)};
                if (that.refs.InpFeest) {that.refs.InpFeest.setValue(data.feestdagen)};
                if (that.refs.InpZiek) {that.refs.InpZiek.setValue(data.ziek)};
                that.setState({disajaar:true});
                break;
              case 'tijdconsparam':
                if (that.refs.InpJaar) {that.refs.InpJaar.setValue(data.jaar)};
                if (that.refs.InpVerlof) {that.refs.InpVerlof.setValue(data.verlof)};
                if (that.refs.InpAdv) {that.refs.InpAdv.setValue(data.adv)};
                if (that.refs.InpBudget) {that.refs.InpBudget.setValue(data.tot_dagen)};
                if (that.refs.InpFeest) {that.refs.InpFeest.setValue(data.feestdagen)};
                if (that.refs.InpZiek) {that.refs.InpZiek.setValue(data.budopleiding)};
                if (that.refs.InpVover) {that.refs.InpVover.setValue(data.over_verl)};
                that.setState({disajaar:true});
                that.setState({consname:data.consultant});
                break;
              case 'opmerking':
                that.refs.InpNaam.setValue(data.omschrijving);
                break;
            }    
          },
          error: function(data){
            return null;
          }
          });
      }
      if (this.props.route == 'afpcredit')
      {
        var aj2 = $.ajax({
          type: "POST",
          dataType: "json",
          url: './server/index.php?function=GetMenuCred',
          data: JSON.stringify(hdata),
          success: function(data){
            that.setState({Type:Immutable.List(data.data)});

          },
          error: function(data){
            that.setState({Type:new Immutable.List()});
            return null;
          }
          });
      }
      if (this.props.route == 'afpbank')
      {
          this.setState({data: {id:sleutel,
           bankid:"",
           datum: "",
           bedrag: data.bedrag.replace('.','')}});
          this.setState({bedrag:data.bedrag.replace('.','')});
          if (that.refs.InpBudget) {that.refs.InpBudget.setValue(data.bedrag.replace('.',''))};
          if (that.refs.InpNaam) {that.refs.InpNaam.zetmenu(App.master.AppBanken())};
      }
    }
    this.refs.Ddetpopup.show();
  },
  Butbewaren() {
    switch (this.props.route) 
    {
      case 'feestdag':
        this.UpdFeestdag();        
        break;        
      case 'tijdparam':
        this.UpdTimeParam();        
        break;      
      case 'tijdconsparam':
        this.UpdTimeConsParam();        
        break;                
      case 'allkennis':
        this.UpdAllKennis();
        break;
    }    
  },
  Butafpunt() {
     this.props.Afpunten(this.refs.InpNaam.getValue());
  },
  ButafpBank() {
    let fout = false;
    let cdatum = this.refs.InpDatum.getValue();
    let hdatum = '';
    let strpost = '';
    this.setState({err_datum: ""});
    this.setState({err_naam: ""});
    this.setState({err_budget: ""});
    if (cdatum && cdatum.length > 9)
    {
      var datum = cdatum;
      var y=datum.split("-")[0];
      var m=datum.split("-")[1];
      var d=datum.split("-")[2]; 
      if ((isNaN(d)==false) && (isNaN(m)==false) && (isNaN(y)==false) )
      {
        hdatum = y+"-"+m+"-"+d;
        this.state.data.datum = hdatum;
      }
      else
      {
        this.setState({err_datum:"Datum niet correct"});
        return;
      }
    }
    else
    {
      this.setState({err_datum:"Datum verplichte ingave"});
      return;
    }
    strpost = this.refs.InpNaam.getValue();
    if (strpost.length > 0)
    {
      this.state.data.bankid = this.refs.InpNaam.getKey();
    }
    else
    {
      this.setState({err_naam:"Verplichte ingave"});
      return;
    }
    let that = this;
    // Update Data
    let aj2 = $.ajax({
    type: "POST",
    dataType: "json",
    url: './server/index.php?function=AfpBank',
    data: JSON.stringify(that.state.data),
    success: function(data){
        that.props.Sluiten(that.state.data);
    },
    error: function(data){
      that.props.Sluiten();
    }
    });
  },
  UpdFeestdag() {
    let fout = false;
    let cdatum = this.refs.InpDatum.getValue();
    let hdatum = '';
    let strpost = '';
    this.setState({err_datum: ""});
    this.setState({err_omschrijving: ""});
    this.setState({err_type: ""});
    if (cdatum && cdatum.length > 9)
    {
      var datum = cdatum;
      var y=datum.split("-")[0];
      var m=datum.split("-")[1];
      var d=datum.split("-")[2]; 
      if ((isNaN(d)==false) && (isNaN(m)==false) && (isNaN(y)==false) )
      {
        hdatum = y+"-"+m+"-"+d;
        this.state.data.maand = m;
        this.state.data.dag = d;
        this.state.data.jaar = y;
      }
      else
      {
        this.setState({err_datum:"Datum niet correct"});
        return;
      }
    }
    else
    {
      this.setState({err_datum:"Datum verplichte ingave"});
      return;
    }
    this.state.data.description= this.refs.InpOmschrijving.getValue();
    this.state.data.datum = hdatum;
    strpost = this.refs.InpType.getValue();
    if (strpost.length > 0)
    {
      this.state.data.type = this.refs.InpType.getKey();
    }
    else
    {
      this.setState({err_type:"Verplichte ingave"});
      return;
    }
    if (this.refs.Chk_opnemen.isChecked())
    {
      this.state.data.opnemen = '1';
    }
    else
    {
      this.state.data.opnemen = '0';
    }
    let that = this;
    // Update Data
    if (this.state.sleutel.length > 3)
    {
      this.state.data.id = this.state.sleutel;
      let aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=UpdFeestdag',
      data: JSON.stringify(that.state.data),
      success: function(data){
        if (data.error) {
          that.setState({err_datum: data.errdatum});
          that.setState({err_omschrijving: data.erromschrijving});
          that.setState({err_type: data.errtype});
          return;
        }
        else
        {
          that.props.Sluiten();
        }
      },
      error: function(data){
        that.setState({err_naam: "Insert niet correct uitgevoerd"});
        return null;
      }
      });
    }
    else
    {
      let aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=CreaFeestdag',
      data: JSON.stringify(that.state.data),
      success: function(data){
        if (data.error) {
          that.setState({err_datum: data.errdatum});
          that.setState({err_omschrijving: data.erromschrijving});
          that.setState({err_type: data.errtype});
          return;
        }
        else
        {
          that.props.Sluiten();
        }
      },
      error: function(data){
        that.setState({err_naam: "Insert niet correct uitgevoerd"});
        return null;
      }
      });
    }
  },  
  DelFeestdag() {
      let hsleutel = this.state.data
      let cdatum = hsleutel.datum;
      let hdata = hsleutel;
      let that = this;
      let aj2 = $.ajax({
        type: "POST",
        dataType: "json",
        url: './server/index.php?function=DeleteFeestdag',
        data: JSON.stringify(hdata),
        success: function(data){
          that.props.Sluiten();
        },
        error: function(data){
          that.props.Sluiten();
        }
      });      
  },
  UpdTimeParam() {
    this.state.data.jaar= this.refs.InpJaar.getValue();
    this.state.data.verlof= this.refs.InpVerlof.getValue();
    this.state.data.adv= this.refs.InpAdv.getValue();
    this.state.data.tot_dagen= this.refs.InpBudget.getValue();
    this.state.data.feestdagen= this.refs.InpFeest.getValue();
    this.state.data.ziek= this.refs.InpZiek.getValue();
    this.state.data.id = this.state.sleutel;

    this.setState({err_jaar: ""});
    if (this.state.data.jaar.length < 4)
    {
      this.setState({err_jaar:"Verplichte ingave jaar"});
      return;
    }

    let that = this;
    if (that.state.sleutel.length > 2)
    {
      let aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=UpdTimeParam',
      data: JSON.stringify(that.state.data),
      success: function(data){
        if (data.error) 
        {
          that.setState({err_jaar: data.errjaar});
        }
        else
        {
          that.VerwConsParam( that.state.data.jaar);                    
          that.props.Sluiten();
        }
      },
      error: function(data){
        that.setState({err_jaar: "Update niet correct uitgevoerd"});
        return null;
      }
      });
    }
    else
    {
      let aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=CreaTimeParam',
      data: JSON.stringify(that.state.data),
      success: function(data){
        if (data.error) 
        {
          that.setState({err_jaar: data.errjaar});
        }
        else
        {
          that.VerwConsParam( that.state.data.jaar);                    
          that.props.Sluiten();
        }
      },
      error: function(data){
        that.setState({err_jaar: "Insert niet correct uitgevoerd"});
        return null;
      }
      });
    }
  }, 
  UpdTimeConsParam() {
    this.state.data.jaar= this.refs.InpJaar.getValue();
    this.state.data.verlof= this.refs.InpVerlof.getValue();
    this.state.data.adv= this.refs.InpAdv.getValue();
    this.state.data.tot_dagen= this.refs.InpBudget.getValue();
    this.state.data.feestdagen= this.refs.InpFeest.getValue();
    this.state.data.over_verl= this.refs.InpVover.getValue();
    this.state.data.id = this.state.sleutel;

    this.setState({err_jaar: ""});
    if (this.state.data.jaar.length < 4)
    {
      this.setState({err_jaar:"Verplichte ingave jaar"});
      return;
    }

    let that = this;
    if (that.state.sleutel.length > 2)
    {
      let aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=UpdConsTimeParam',
      data: JSON.stringify(that.state.data),
      success: function(data){
        if (data.error) 
        {
          that.setState({err_jaar: data.errjaar});
        }
        else
        {
          that.VerwSingConsParam(that.state.data.jaar,that.state.data.cons_id) 
          that.props.Sluiten();
        }
      },
      error: function(data){
        that.setState({err_jaar: "Update niet correct uitgevoerd"});
        return null;
      }
      });
    }
    else
    {
      this.state.data.cons_id = App.ConsId;

      let aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=InsertConsTimeParam',
      data: JSON.stringify(that.state.data),
      success: function(data){
        if (data.error) 
        {
          that.setState({err_jaar: data.errjaar});
        }
        else
        {
          that.VerwSingConsParam(that.state.data.jaar,that.state.data.cons_id) 
          that.props.Sluiten();
        }
      },
      error: function(data){
        that.setState({err_jaar: "Update niet correct uitgevoerd"});
        return null;
      }
      });
    }
  }, 
  VerwConsParam(jaar) {
    ClsCons.GetAllCons({actief:1})
      .then(data => {
          this.DoeVerwConsParam(jaar,data.data);
      })
      .catch(data => {
          return true;
    });    
  },
  DoeVerwConsParam(jaar,data) {
    let i = 0;
    for (i = 0; i < data.length; i++) { 
      let row = data[i];
      ClsCons.CreaConsTimel({id:row.id,jaar:jaar})
        .then(data => {
        })
        .catch(data => {
      });         
    }
  },
  VerwSingConsParam(jaar,consid) {
      ClsCons.CreaConsTimel({id:consid,jaar:jaar})
        .then(data => {
        })
        .catch(data => {
      });         
  },
  UpdAllKennis() {

    // Update Data
    this.state.data.alternatief = this.refs.InpNaam.getValue();
    this.state.data.omschrijving = this.refs.InpAfk.getValue();
    this.state.data.uitleg = this.refs.InpOmschrijving.getValue();
    this.state.data.id = this.state.sleutel;

    this.setState({err_naam: ""});
    this.setState({err_afk: ""});

    if (this.state.data.omschrijving.length < 1)
    {
      this.setState({err_afk:"Verplichte ingave"});
      return;
    }

    let that = this;
    if (that.state.sleutel == 0)
    {
      let aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=CreaKennis',
      data: JSON.stringify(that.state.data),
      success: function(data){
        if (data.error) 
        {
          that.setState({err_afk: data.errafk});          
        }
        else
        {
          App.master.ZetKennis();
          that.props.Sluiten();
        }
      },
      error: function(data){
        that.setState({err_naam: "Insert niet correct uitgevoerd"});
        return null;
      }
      });
    }
    else      
    {
      let aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=UpdKennis',
      data: JSON.stringify(that.state.data),
      success: function(data){
        if (data.error) 
        {
          that.setState({err_afk: data.errafk});
        }
        else
        {
          App.master.ZetKennis();
          that.props.Sluiten();
        }
      },
      error: function(data){
        that.setState({err_naam: "Update niet correct uitgevoerd"});
        return null;
      }
      });
    }
  },
  ConsZoek() {
    this.refs.RefZoekPopup.openen('consbrow','InpNaam');
  },  
  CreditZoek() {
    let hdata = {id:this.state.sleutel}
    this.refs.RefZoekPopup.openen('GetLijstCred','InpType',hdata);
  },  
  klikzkpop(waarde,txtterug,veld) {
    switch (veld) {
        case 'InpNaam':
          this.refs.InpNaam.setValue(txtterug);
          this.refs.InpNaam.setKey(waarde);
          break;
    }
  },
});
module.exports = Detpopup;






