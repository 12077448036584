module.exports = {

  addDays(d, days) {
    let newDate = new Date(d);
    newDate.setDate(newDate.getDate() + parseInt(days));
    return newDate;
  },

  addMonths(d, months) {
    let newDate = this.clone(d);
    newDate.setMonth(d.getMonth() + months);
    return newDate;
  },

  addYears(d, years) {
    let newDate = this.clone(d);
    newDate.setFullYear(d.getFullYear() + years);
    return newDate;
  },

  clone(d) {
    return new Date(d.getTime());
  },

  cloneAsDate(d) {
    let clonedDate = this.clone(d);
    clonedDate.setHours(0, 0, 0, 0);
    return clonedDate;
  },

  getDaysInMonth(d) {
    let resultDate = this.getFirstDayOfMonth(d);

    resultDate.setMonth(resultDate.getMonth() + 1);
    resultDate.setDate(resultDate.getDate() - 1);

    return resultDate.getDate();
  },

  getFirstDayOfMonth(d) {
    return new Date(d.getFullYear(), d.getMonth(), 1);
  },

  formatZoek(date) {
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let y = date.getFullYear();
    if (d < 10)
    {
      d='0'+d;
    }
    if (m < 10)
    {
      m='0'+m;
    }

    return y + '-' + m + '-' + d;
  },  

  formatEur(date) {
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let y = date.getFullYear();
    if (d < 10)
    {
      d='0'+d;
    }
    if (m < 10)
    {
      m='0'+m;
    }

    return d + '-' + m + '-' + y;
  },  


  getFullMonth(d) {
    let month = d.getMonth();
    switch (month) {
      case 0: return 'Januari';
      case 1: return 'Februari';
      case 2: return 'Maart';
      case 3: return 'April';
      case 4: return 'Mei';
      case 5: return 'Juni';
      case 6: return 'Juli';
      case 7: return 'Augustus';
      case 8: return 'September';
      case 9: return 'Oktober';
      case 10: return 'November';
      case 11: return 'December';
    }
  },

  getShortMonth(d) {
    let month = d.getMonth();
    switch (month) {
      case 0: return 'Jan';
      case 1: return 'Feb';
      case 2: return 'Mar';
      case 3: return 'Apr';
      case 4: return 'Mei';
      case 5: return 'Jun';
      case 6: return 'Jul';
      case 7: return 'Aug';
      case 8: return 'Sep';
      case 9: return 'Okt';
      case 10: return 'Nov';
      case 11: return 'Dec';
    }
  },

  getDayOfWeek(d) {
    let dow = d.getDay();
    switch (dow) {
      case 0: return 'Zon';
      case 1: return 'Maa';
      case 2: return 'Din';
      case 3: return 'Woe';
      case 4: return 'Don';
      case 5: return 'Vri';
      case 6: return 'Zat';
    }
  },

  getDayOfWeekLong(d) {
    let dow = d.getDay();
    switch (dow) {
      case 0: return 'Zondag';
      case 1: return 'Maandag';
      case 2: return 'Dinsdag';
      case 3: return 'Woensdag';
      case 4: return 'Donderdag';
      case 5: return 'Vrijdag';
      case 6: return 'Zaterdag';
    }
  },

  getWeekArray(d) {
    let dayArray = [];
    let daysInMonth = this.getDaysInMonth(d);
    let daysInWeek;
    let emptyDays;
    let firstDayOfWeek;
    let week;
    let weekArray = [];

    for (let i = 1; i <= daysInMonth; i++) {
      dayArray.push(new Date(d.getFullYear(), d.getMonth(), i));
    }
    let start = 0;
    while (dayArray.length) {
      firstDayOfWeek = dayArray[0].getDay();
      if (start == 0)
      {
        if (firstDayOfWeek == 0)
        {
         daysInWeek = 1;  
        }
        if (firstDayOfWeek == 1)
        {
         daysInWeek = 7;  
        }
        if (firstDayOfWeek == 2)
        {
         daysInWeek = 6;  
        }
        if (firstDayOfWeek == 3)
        {
         daysInWeek = 5;  
        }
        if (firstDayOfWeek == 4)
        {
         daysInWeek = 4;  
        }
        if (firstDayOfWeek == 5)
        {
         daysInWeek = 3;  
        }
        if (firstDayOfWeek == 6)
        {
         daysInWeek = 2;  
        }
        start = 1;
      }
      else
      {
        daysInWeek = 8 - (firstDayOfWeek );
      }
      emptyDays = 7 - daysInWeek;
      week = dayArray.splice(0, daysInWeek);

      for (let i = 0; i < emptyDays; i++) {
        week.unshift(null);
      }

      weekArray.push(week);
    }

    return weekArray;
  },

  format(date) {
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let y = date.getFullYear();
    return m + '/' + d + '/' + y;
  },

  isEqualDate(d1, d2) {
    return d1 && d2 &&
      (d1.getFullYear() === d2.getFullYear()) &&
      (d1.getMonth() === d2.getMonth()) &&
      (d1.getDate() === d2.getDate());
  },

  isBeforeDate(d1, d2) {
    let date1 = this.cloneAsDate(d1);
    let date2 = this.cloneAsDate(d2);

    return (date1.getTime() < date2.getTime());
  },

  isAfterDate(d1, d2) {
    let date1 = this.cloneAsDate(d1);
    let date2 = this.cloneAsDate(d2);

    return (date1.getTime() > date2.getTime());
  },

  isBetweenDates(dateToCheck, startDate, endDate) {
    return (!(this.isBeforeDate(dateToCheck, startDate)) &&
            !(this.isAfterDate(dateToCheck, endDate)));
  },

  isDateObject(d) {
    return d instanceof Date;
  },

  monthDiff(d1, d2) {
    let m;
    m = (d1.getFullYear() - d2.getFullYear()) * 12;
    m += d1.getMonth();
    m -= d2.getMonth();
    return m;
  },

  yearDiff(d1, d2) {
    return ~~(this.monthDiff(d1, d2) / 12);
  },

  Get_VandaagTxt() {
    let d = new Date();
    let maand = d.getMonth() + 1;
    let dag = d.getDate();
    let jaar = d.getFullYear();
    let sjaar = jaar.toString();
    let sdag = dag.toString();
    let smaand = maand.toString();
    if (dag < 10)
    {
      sdag = '0'+sdag;
    }
    if (maand < 10)
    {
      smaand = '0'+smaand;
    }

    let datum = sdag+"-"+smaand+"-"+sjaar;
    return datum;
  },

  Get_VandaagTxtDat() {
    let d = new Date();
    let maand = d.getMonth() + 1;
    let dag = d.getDate();
    let jaar = d.getFullYear();
    let sjaar = jaar.toString();
    let sdag = dag.toString();
    let smaand = maand.toString();
    if (dag < 10)
    {
      sdag = '0'+sdag;
    }
    if (maand < 10)
    {
      smaand = '0'+smaand;
    }

    let datum = sjaar+"-"+smaand+"-"+sdag;
    return datum;
  },

  Zet_DatTxt(d) {
    let maand = d.getMonth() + 1;
    let dag = d.getDate();
    let jaar = d.getFullYear();
    let sjaar = jaar.toString();
    let sdag = dag.toString();
    let smaand = maand.toString();
    if (dag < 10)
    {
      sdag = '0'+sdag;
    }
    if (maand < 10)
    {
      smaand = '0'+smaand;
    }

    let datum = sdag+"-"+smaand+"-"+sjaar;
    return datum;
  },  
  Zet_DatTxt_db(d) {
    let maand = d.getMonth() + 1;
    let dag = d.getDate();
    let jaar = d.getFullYear();
    let sjaar = jaar.toString();
    let sdag = dag.toString();
    let smaand = maand.toString();
    if (dag < 10)
    {
      sdag = '0'+sdag;
    }
    if (maand < 10)
    {
      smaand = '0'+smaand;
    }

    let datum = sjaar+"-"+smaand+"-"+sdag;
    return datum;
  },  

  Zet_Datum_Db(cdatum) {
    if (cdatum && cdatum.length > 9)
    {
      let datum = cdatum;
      let d=datum.split("-")[0];
      let m=datum.split("-")[1];
      let y=datum.split("-")[2]; 
      if ((isNaN(d)==false) && (isNaN(m)==false) && (isNaN(y)==false) )
      {
        let hdatum = y+"-"+m+"-"+d;
        return hdatum;
      }
      else
      {
        return '';
      }
    }
    else
    {
      return '';
    }
  },
  GetMaand(cdatum) {
    if (cdatum && cdatum.length > 9)
    {
      let datum = cdatum;
      let y=datum.split("-")[0];
      let m=datum.split("-")[1];
      let d=datum.split("-")[2]; 
      if ((isNaN(d)==false) && (isNaN(m)==false) && (isNaN(y)==false) )
      {
        return m;
      }
      else
      {
        return '';
      }
    }
    else
    {
      return '';
    }
  },

  Zet_Datum_Input(cdatum) {
    if (cdatum && cdatum.length > 9)
    {
      let datum = cdatum;
      let y=datum.split("-")[0];
      let m=datum.split("-")[1];
      let d=datum.split("-")[2]; 
      if ((isNaN(d)==false) && (isNaN(m)==false) && (isNaN(y)==false) )
      {
        let hdatum = d+"-"+m+"-"+y;
        return hdatum;
      }
      else
      {
        return '';
      }
    }
    else
    {
      return '';
    }
  },
  Zet_Datum_kort(cdatum) {
    let m = cdatum.getMonth() + 1;
    let d = cdatum.getDate();
    let y = cdatum.getFullYear();
    if (d < 10)
    {
      d='0'+d;
    }
    if (m < 10)
    {
      m='0'+m;
    }
    return y + '-' + m + '-' + d;
  },
  Zet_nu() {
    let cdatum = new Date();
    let m = cdatum.getMonth() + 1;
    let d = cdatum.getDate();
    let y = cdatum.getFullYear();
    let uur = cdatum.getHours();
    let min = cdatum.getMinutes();
    let sec = cdatum.getSeconds();    
    if (d < 10)
    {
      d='0'+d;
    }
    if (m < 10)
    {
      m='0'+m;
    }
    if (min < 10)
    {
      min='0'+min;
    }
    if (sec < 10)
    {
      sec='0'+sec;
    }
    if (uur < 10)
    {
      uur='0'+uur;
    }
    return y + '-' + m + '-' + d+' '+uur+':'+min+':'+sec;
  },


};
