const React = require('react');
const ReactDOM = require('react-dom');
const StylePropable = require('./mixins/style-propable');
const WindowListenable = require('./mixins/window-listenable');
const Transitions = require('./styles/transitions');
const ColorManipulator = require('./utils/color-manipulator');
const PropTypes = require('./utils/prop-types');
const UniqueId = require('./utils/unique-id');
const EnhancedButton = require('./enhanced-button.jsx');
const Menu = require('./menu/menu.jsx');
const FontIcon = require('./font-icon.jsx');
const Paper = require('./paper.jsx');
const Children = require('./utils/children');
const DefaultRawTheme = require('./styles/raw-themes/light-raw-theme');
const ThemeManager = require('./styles/theme-manager');
const Tooltip = require('./tooltip.jsx');
const ContextPure = require('./mixins/context-pure');
const ClickAwayable = require('./mixins/click-awayable');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const FlatButtonLabel = require('./buttons/flat-button-label.jsx');
const DraggableCore = require('react-draggable');
var Draggable = require('react-draggable');
var Resizable = require("react-resizable-box");



let getZDepth = function(disabled,diepte) {
  let zDepth = disabled ? 0 : diepte;
  return {
    zDepth: zDepth,
    initialZDepth: zDepth,
  };
};



const Mattag = React.createClass({



  mixins: [StylePropable,ContextPure,ClickAwayable],

  contextTypes: {
    muiTheme: React.PropTypes.object,
  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
  },

 windowListeners: {
    keyup: '_handleWindowKeyUp',
    resize: '_setZoom',
  },

  _setZoom() {
    let zoomStyle = (window.innerWidth < 650) ? {zoom:'1'} : {zoom:'1'};
    this.setState({zoomStyle:zoomStyle});
    this.render;

  },

  statics: {
    getRelevantContextKeys(muiTheme) {
      const spacing = muiTheme.rawTheme.spacing;
      const palette = muiTheme.rawTheme.palette;

      return {
        iconSize: spacing.iconSize,
        textColor: palette.textColor,
        disabledColor: palette.disabledColor,
      };
    },

    getChildrenClasses() {
      return [
        EnhancedButton,
        FontIcon,
        Tooltip,
      ];
    },
  },

  getChildContext () {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

  getDefaultProps() {
    return {
      autoWidth: true,
      valueMember:'payload',
      displayMember:'text',      
      iconStyle: {},
      tooltipPosition: 'bottom-center',
      menuItems:Immutable.List(),
    };
  },  

  propTypes: {
    backgroundColor: React.PropTypes.string,
    disabled: React.PropTypes.bool,
    disabledColor: React.PropTypes.string,
    iconClassName: React.PropTypes.string,
    iconStyle: React.PropTypes.object,
    menuItemStyle: React.PropTypes.object,    
    mini: React.PropTypes.bool,
    onMouseDown: React.PropTypes.func,
    onMouseUp: React.PropTypes.func,
    onMouseLeave: React.PropTypes.func,
    onTouchEnd: React.PropTypes.func,
    onTouchStart: React.PropTypes.func,
    secondary: React.PropTypes.bool,
    tooltip: React.PropTypes.node,
    tooltipStyles: React.PropTypes.object,
    onMenuChange: React.PropTypes.func,
    tooltipPosition: PropTypes.cornersAndCenter,    
    selectedIndex: React.PropTypes.number
  },

  getInitialState() {

    let zDepth = this.props.disabled ? 0 : this.props.diepte;
    return {
      zindex:2,
      overflow:'hidden',
      tooltipShown: false,
      hovered: false,
      initialZDepth: zDepth,
      touch: false,
      iconfoc: false,
      zDepth: zDepth,
      open: false,
      isHovered: false,
      selectedIndex: 0,       
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  componentWillMount() {
    this.setState(getZDepth(this.props.disabled,this.props.diepte));
  },

  componentWillReceiveProps(newProps, nextContext) {
    let newMuiTheme = nextContext.muiTheme ? nextContext.muiTheme : this.state.muiTheme;
    this.setState({muiTheme: newMuiTheme});

    if (newProps.disabled !== this.props.disabled) {
      this.setState(getZDepth(newProps.disabled,this.props.diepte));
    }
  },

  componentDidMount() {
    if (process.env.NODE_ENV !== 'production') {
      if (this.props.iconClassName && this.props.children) {
        let warning = 'You have set both an iconClassName and a child icon. ' +
          'It is recommended you use only one method when adding ' +
          'icons to FloatingActionButtons.';
        console.warn(warning);
      }
    }
  },

  componentClickAway() {
    this.setState({zindex:2});
    this.setState({open:false});
    this.setState({overflow:'hidden'});

  },

  _getBackgroundColor() {
    return this.props.disabled ? ( this.props.disabledColor || this.getTheme().disabledColor) :
      this.props.backgroundColor ? this.props.backgroundColor :
      this.props.secondary ? this.getTheme().secondaryColor :
      this.props.primary ? this.getTheme().primaryIconColor :        
      this.getTheme().color;
  },


  getTheme() {
    return this.state.muiTheme.floatingActionButton;
  },

  _getIconColor() {
    return this.props.disabled ? this.getTheme().disabledTextColor :
      (this.props.secondary ? this.getTheme().secondaryIconColor :
      this.getTheme().iconColor);
  },

  _handlePapLeave(){
  },
  
  _handlePapEnter(){
  },


  getStyles() {

    const spacing = this.state.muiTheme.rawTheme.spacing;
    let zIndex = 5;
    let themeVariables = this.state.muiTheme.floatingActionButton;
    let mleft =  this.props.left ? this.props.left : 0; 
    let zien = (this.props.zien) ? "" : "-1000px";
    let positie = (this.props.zien) ? "" : "absolute";
    let rechts = (this.props.rechts) ? this.props.rechts : "0px";    
    let vierkant = (this.props.vierkant) ?  '0%' : '50%';

    const {
      iconSize,
      textColor,
      disabledColor,
    } = this.constructor.getRelevantContextKeys(this.state.muiTheme);

    let styles = {
      root: {
        transition: Transitions.easeOut(),
        display: 'inline-flex',
        position: 'relative',
        boxSizing: 'border-box',
        transition: Transitions.easeOut(),
        padding: 0,
        overflow:this.state.overflow,
        zIndex:this.state.zindex,
        marginLeft:'5px',
        marginTop:'5px',
        fontSize: 'x-small',        
        borderRadius:'5%',
        backgroundColor:'rgba(47, 165, 199, 0.50)'
      },

      rootWhenOpen: {
        opacity: 1
      },      
      menu: {
        zIndex: this.state.zindex + 2,
        left:mleft,
      },      
      menuItem: {
        color:'black',
        cursor:'pointer',
        paddingRight: spacing.iconSize +
                      spacing.desktopGutterLess +
                      spacing.desktopGutterMini,
        height: spacing.desktopDropDownMenuItemHeight,
        lineHeight: spacing.desktopDropDownMenuItemHeight + 'px',
        whiteSpace: 'nowrap',
        fontSize:'x-small',
      },      
      tooltip: {
        boxSizing: 'border-box',
      },      
      container: {
        transition: Transitions.easeOut(),
        position: 'relative',
        height: '20px',
        width: '20px',
        padding: 0,
        overflow: 'hidden',
        backgroundColor: 'transparent',
        borderRadius: '5%',
        textAlign: 'center',
        verticalAlign: 'bottom',
        marginRight:'8px',
        right:'-20px',
        top: '2px',        
        //This is need so that ripples do not bleed
        //past border radius.
        //See: http://stackoverflow.com/questions/17298739/css-overflow-hidden-not-working-in-chrome-when-parent-has-border-radius-and-chil
        transform: 'translate3d(0, 0, 0)',
      },
      containerWhenMini: {
        height: '20px',
        width: '20px',
        cursor:'pointer',
        backgroundColor:'transparent',
      },
      overlay: {
        transition: Transitions.easeOut(),
        top: 0,
      },
      overlayWhenHovered: {
        backgroundColor: 'rgba(47, 165, 199, 0.85)',
      },
      icon: {
        height: '20px',
        lineHeight: '20px',
        fill: 'silver',
        color: 'red',
        backgroundColor:'transparent',
        fontSize:'x-small',
        cursor:'pointer',        
      },

      AvStyles: {
        height: 20,
        width:  20,
        userSelect: 'none',
        backgroundColor: this._getBackgroundColor(),
        borderRadius: vierkant,
        border: 'solid 1px',
        display:'inline-block',

        //Needed for letter avatars
        textAlign: 'center',
        lineHeight: '20px',
        fontSize: 'x-small',
        color: this._getIconColor()
      },      
      iconWhenMini: {
        height: '20px',
        lineHeight: '20px',
        cursor:'pointer',
        backgroundColor:'transparent',
      },
      iconWhenDropd: {
        lineHeight: '20px',
        height: '20px',
        width: '20px',
        fontSize:'x-small',
        cursor:'pointer',
        backgroundColor:'transparent',
      },
      contWhenDropd: {
        lineHeight: '20px',
        height: '20px',
        width: '20px',
        float: this.getTheme().dropdFloat,
        fontSize:'x-small',
        marginTop: this.getTheme().dropdTop,
      },
      iconWhenAccord: {
        lineHeight: '20px',
        height: '20px',
        width: '20px',
        fontSize:'x-small',
        cursor:'pointer',
        backgroundColor:'transparent',
      },
      iconWhenAccordIns: {
        lineHeight: '20px',
        height: '20px',
        width: '20px',
        fontSize:'x-small',
        cursor:'pointer',
        backgroundColor:'transparent',
      },      
      contWhenAccord: {
        lineHeight: '20px',
        height: '20px',
        width: '20px',
        float: 'left',
        marginTop: '0px',
        marginRight: '10px',
        fontSize:'x-small'
      },

     contWhenAccordIns: {
        lineHeight: '20px',
        height: '20px',
        width: '20px',
        float: 'none',
        marginTop: '0px',
        marginRight: '10px',
        marginLeft: '15px',
        fontSize:'x-small'

      },

    };
    return styles;
  },


  render() {
    let {
      disabled,
      mini,
      tooltip,
      touch,
      secondary,
      iconStyle,
      iconClassName,
      aantal,
      nodrag,
      menu,
      nodelete,
      menuitems,
      label,
      foto,
      likey,
      hfdsleutel,
      accord,
      column,
      accordin,
      dropd,
      onMenuChange, 
      onStart, 
      onStop, 
      autoWidth, 
      valueMember, 
      displayMember, 
      tooltipPosition, 
      menuItems,
      LabClick,
      ...other } = this.props;
    let zoomStyle = (dropd) ? {zoom:'1'}:
                    (this.state.zoomStyle) ? this.state.zoomStyle :
                    (window.innerWidth < 600) ? {zoom:'1'} : {zoom:'1'};


    let cirkel = (this.props.vierkant) ? false : true;


    let _this = this;
    let styles = this.getStyles();
    let selectedIndex = this.state.selectedIndex;
    tooltipPosition = this.props.tooltipPosition.split('-');
    let tooltipElement = tooltip ? (
      <Tooltip
        ref="tooltip"
        label={tooltip}
        show={this.state.tooltipShown}
        touch={touch}
        style={this.mergeStyles(styles.tooltip, this.props.tooltipStyles)}
        verticalPosition={tooltipPosition[0]}
        horizontalPosition={tooltipPosition[1]}/>
    ) : null;    


    let AvaElement = foto ? (<img {...other} src={this.props.foto} style={styles.AvStyles}>{menuElement}</img>):null;


    let iconElement;
    if (iconClassName) {
      iconElement =
        <FontIcon
          className={iconClassName}
          style={this.mergeStyles(
            styles.icon,
            mini && styles.iconWhenMini,
            dropd &&styles.iconWhenDropd,
            accord &&styles.iconWhenAccord,            
            accordin &&styles.iconWhenAccordIns, 
            iconStyle)}></FontIcon>;
    }

    let tel_style = menu ? {
      backgroundColor: 'rgb(1, 115, 199)',
      display: 'inline-block',
      position: 'relative',
      width: '20%',
      cursor:'pointer',
      height: '100%',
      borderRadius:'20%',
      textAlign: 'center',
      zIndex:this.state.zindex,
      color: 'white'} : {
      backgroundColor: 'rgb(1, 115, 199)',
      display: 'inline-block',
      position: 'relative',
      width: '20%',
      cursor:'auto',
      height: '100%',
      borderRadius:'20%',
      textAlign: 'center',
      zIndex:this.state.zindex,
      color: 'white'};    

    let tel_style2 = menu ? {
      fontSize:'x-small',
      cursor:'pointer',      
      verticalAlign:'middle',
      textAlign: 'center',
      marginRight:'3px',
      marginLeft:'2px',
      color: 'white'} : {
      fontSize:'x-small',
      cursor:'pointer',      
      verticalAlign:'middle',
      textAlign: 'auto',
      marginRight:'3px',
      marginLeft:'2px',
      color: 'white'};    


    let teller = (aantal) ? (
      <Paper
        style={tel_style}
        onMouseDown={this._handleTelMouseDown}
        onMouseLeave={this._handleMouseLeave}
        onMouseEnter={this._handleMouseEnter}
        zDepth={0}>
        <FontIcon style={tel_style2}> {aantal}</FontIcon> </Paper>) : null;

    // Menu
    menuItems = {};
    if (menu)
    {
      menuItems = this.props.menuitems.map((item) => {
        item.text = item[_this.props.displayMember];
        item.payload = item[_this.props.valueMember];
        return item;
      });    
    }


    let menuElement = menu ? (         
      <Menu
        ref="menuItems"
        autoWidth={this.props.autoWidth}
        selectedIndex={selectedIndex}
        menuItems={menuItems}
        style={styles.menu}
        menuItemStyle={this.mergeStyles(styles.menuItem, this.props.menuItemStyle)}
        hideable={true}
        visible={this.state.open}
        onRequestClose={this._onMenuRequestClose}
        onItemTap={this._onMenuItemClick} />) : null;

    let labcur = nodrag ? 'auto' : 'move';

    let labelElement = label ? (
      <div style = {{position: 'relative', padding: '0 16px', cursor:{labcur}, marginRight:'5px',color: 'black', width: '60%',fontSize: 'x-small',textAlign: 'center'}} 
      onClick={(event)=>this.LabClick(column)} >{label} </div>
     ) : null;

    if (this.props.LabClick)
    {
      labelElement = label ? (
          <div style = {{position: 'relative', padding: '0 16px', cursor:'pointer', marginRight:'5px',color: 'black', width: '60%',fontSize: 'x-small',textAlign: 'center'}} 
          onClick={(event)=>this.LabClick(column)} >{label} </div>) : null;
    }


    // Place label before or after children.
 
    let children = Children.extend(this.props.children, {
      style: this.mergeStyles(
        styles.icon,
        mini && styles.iconWhenMini,
        iconStyle),
    });

    let buttonEventHandlers = disabled ? null : {
      onMouseDown: this._handleMouseDown,
      onMouseUp: this._handleMouseUp,
      onMouseLeave: this._handleMouseLeave,
      onMouseEnter: this._handleMouseEnter,
      onTouchStart: this._handleTouchStart,
      onTouchEnd: this._handleTouchEnd,
      onKeyboardFocus: this._handleKeyboardFocus,
      onBlur:this._handleBlur,
      onFocus:this._handleFocus,
    };


    return (
      <Draggable       
        onStart={this.handleStart}
        onDrag={this.handleDrag}
        onStop={this.handleStop}
        onDragOver={this.handleDragOver}
        bounds="parent"
        zIndex={this.state.zindex}>
      <Paper
        style={this.mergeStyles(styles.root, this.props.style, zoomStyle)}
        zDepth={0}
        onMouseLeave= {this._handlePapLeave}
        onDragOver= {this._handlePapEnter}
        circle={false}>
        {teller}
        {labelElement}
        <EnhancedButton
          {...other}
          {...buttonEventHandlers}
          ref="container"
          disabled={disabled}
          style={this.mergeStyles(
            styles.container,
            this.props.mini && styles.containerWhenMini,
            this.props.dropd &&styles.iconWhenDropd,  
            this.props.accord &&styles.iconWhenAccord,   
            this.props.accordin &&styles.iconWhenAccordIns,   

          )}
          focusRippleColor={styles.icon.color}
          touchRippleColor={styles.icon.color}>
            <div ref="overlay">
                {iconElement}
                {AvaElement}
                {children}
            </div>
        </EnhancedButton>
        {tooltipElement}
        {menuElement}
      </Paper>
      </Draggable>

    );
  },
  LabClick(column) {
      if (this.props.LabClick)
      {
        this.props.LabClick(column);
      }
  },      



  handleStart(event, ui){
    if (this.props.nodrag) {return false}; 
    if (this.state.iconfoc == true) {return false};
    this.setState({zindex:9999});
    if (this.props.onStart) this.props.onStart(event, ui);
  },

  handleStop(event, ui){
    this.setState({zindex:2});
    if (this.props.onStop) this.props.onStop(event, ui);

  },

  handleDragOver(event, ui){
  },

  handleDrag(event, ui){
    if (this.props.onDrag) this.props.onDrag(event, ui);
  },

  
  _showTooltip() {
    if (!this.props.disabled && this.props.tooltip) {
      this.setState({ tooltipShown: true });
    }
  },

  _hideTooltip() {
    if (this.props.tooltip) this.setState({ tooltipShown: false });
  },

  _handleBlur(e) {
    this._hideTooltip();
    if (this.props.onBlur) this.props.onBlur(e);
  },

  _handleFocus(e) {
    this._showTooltip();
    if (this.props.onFocus) this.props.onFocus(e);
  },

  _handleMouseDown(e) {
    //only listen to left clicks
    if (e.button === 0) {
      this.setState({ zDepth: this.state.initialZDepth + 1 });
    }
    if (this.props.onMouseDown) this.props.onMouseDown(e);
  },

  _handleTelMouseDown(e) {
    //only listen to left clicks
    if (e.button === 0) {
      this.setState({ zDepth: this.state.initialZDepth + 1 });
      if (this.state.open)
      {
          this.setState({overflow:'hidden'});
          this.setState({zindex:2});
      }
      else
      {
          this.setState({zindex:9999});
          this.setState({overflow:'visible'});
      }

      this.setState({ open: !this.state.open });

    }
  },

  _handleMouseUp(e) {
    if (this.props.nodelete) {return};
    this.setState({ zDepth: this.state.initialZDepth });
    if (this.props.column && this.props.onMouseUp) {
      this.props.onMouseUp(e, this.props.column);
      return;
    }
  },

  _handleMouseLeave(e) {
    this._hideTooltip();
    this.setState({iconfoc: false});
    if (!this.refs.container.isKeyboardFocused()) this.setState({ zDepth: this.state.initialZDepth, hovered: false });
    if (this.props.onMouseLeave) this.props.onMouseLeave(e);
  },

  _handleMouseEnter(e) {
    this._showTooltip();
    this.setState({iconfoc: true});
    if (!this.refs.container.isKeyboardFocused() && !this.state.touch) {
      this.setState({hovered: true});
    }
    if (this.props.onMouseEnter) this.props.onMouseEnter(e);
  },

  _handleTouchStart(e) {
    this.setState({
      touch: true,
      zDepth: this.state.initialZDepth + 1,
    });
    if (this.props.onTouchStart) this.props.onTouchStart(e);
  },

  _handleTouchEnd(e) {
    this.setState({ zDepth: this.state.initialZDepth });
    if (this.props.onTouchEnd) this.props.onTouchEnd(e);
  },

  _handleKeyboardFocus(e, keyboardFocused) {
    if (keyboardFocused && !this.props.disabled) {
      this._showTooltip();
      this.setState({ zDepth: this.state.initialZDepth + 1 });
      ReactDOM.findDOMNode(this.refs.overlay).style.backgroundColor = ColorManipulator.fade(this.getStyles().icon.color, 0.4);
    }
    else if (!this.state.hovered) {
      this._hideTooltip();
      this.setState({ zDepth: this.state.initialZDepth });
      ReactDOM.findDOMNode(this.refs.overlay).style.backgroundColor = 'transparent';
    }
  },
  _onMenuItemClick(e, key, payload) {
      let selectedItem = this.props.menuItems[key];
      this.setState({zindex:2});
      this.setState({open:false});
      this.setState({overflow:'hidden'});
      if (this.props.onMenuChange) this.props.onMenuChange(key,this.props.column);
  },

  _onMenuRequestClose() {
    this.setState({zindex:2});
    this.setState({open:false});
    this.setState({overflow:'hidden'});
  }    

});

module.exports = Mattag;
