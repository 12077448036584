let React = require('react');
const SvgIcon = require('../matui/svg-icon.jsx');
const TextField = require('../matui/text-field.jsx');
const MenLabel = require('./mainmenlabel.jsx');
const DgFunc = require('../datagrid/src/functions.jsx');
const PopupCons = require('./popupprofile.jsx');
 
let Homemen = React.createClass({
contextTypes:{
    router: React.PropTypes.object
},
componentDidMount() {
  if (App.master) 
  {
    App.master.zetbalk2(0);
    App.master.setState({oldstatblk2:0});
  }
},   
getInitialState() {
    return {
      popupcons:null,
      iconcolor:'rgb(255,255,255)',
      image1: null,
      label1: null,
      aantal1:0,
      image2: null,
      label2: null,
      aantal2:0,
      image3: null,
      label3: null,
      aantal3:0,
      image4:"/img/lib2.png",
      label4:'Libraries',
      aantal4:0,
      image5: null,
      label5: null,
      aantal5:0,
      image6: null,
      label6: null,
      aantal6:0,
      image7: null,
      label7: null,
      aantal7:0,
      image11: null,
      label11: null,
      aantal11:0,
      image12: null,
      label12: null,
      aantal12:0,
      image13:"/img/fixtur.png",
      label13:'Fixtures',
      aantal13:0,
      image14:"/img/slidcol.png",
      label14:'Channels',
      aantal14:0,
      image15:"/img/virtcont.png",
      label15:'Virtual Controllers',
      aantal15:0,
      image16: null,
      label16: null,
      aantal16:0,
      image17: null,
      label17: null,
      aantal17:0,
      image21: null,
      label21: null,
      aantal21:0,
      image22:"/img/manuf2.png",
      label22:'Manufacturers',
      aantal22:0,
      image26: "/img/users2.png",
      label26: (App.Admin == 'GB') ? "My Profile" : "Users",
      aantal26:0,
      image27: null,
      label27: null,
      aantal27:0,
      image31: null,
      label31: null,
      aantal31:0,
      image32: null,
      label32: null,
      aantal32:0,
      image33: null,
      label33: null,
      aantal33:0,
      image34: null,
      label34: null,
      aantal34:0,
      image35: null,
      label35: null,
      aantal35:0,
      image36: null,
      label36: null,
      aantal36:0,
      image37: null,
      label37: null,
      aantal37:0,
      search:'',
    };
},

render: function() {
    var w = window.innerWidth - 5;
    var h = window.innerHeight - 100;  	
    let icon1 = (<SvgIcon color='#2fa5c7' hoverColor='darkslateblue' viewBox="0 0 448 512" width="70" height="70" >
    		<path d="M10 160c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-20V48c0-26.51-21.49-48-48-48H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h320c26.51 0 48-21.49 48-48v-48h20c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-20v-64h20c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-20v-64h20zm-74 304H54a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h308a6 6 0 0 1 6 6v404a6 6 0 0 1-6 6zM128 208c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80zm208 133.477V360c0 13.255-10.745 24-24 24H104c-13.255 0-24-10.745-24-24v-18.523c0-22.026 14.99-41.225 36.358-46.567l35.657-8.914c29.101 20.932 74.509 26.945 111.97 0l35.657 8.914C321.01 300.252 336 319.452 336 341.477z" />
  		</SvgIcon>);
    let keuze1 =(<MenLabel image={this.state.image1} label={this.state.label1} aantal={this.state.aantal1}></MenLabel>)
    let keuze2 =(<MenLabel image={this.state.image2} label={this.state.label2} aantal={this.state.aantal2}></MenLabel>)
    let keuze3 = (<MenLabel image={this.state.image3} label={this.state.label3} aantal={this.state.aantal3}></MenLabel>)

    let keuze4 =(<MenLabel image={this.state.image4} label={this.state.label4} aantal={this.state.aantal4} OpenMenu={this.OpenMenu} id={0.4} data={{url:'GetAllLibrary', key:{param: ''}}} search={this.state.search}></MenLabel>)
    let keuze5 =(<MenLabel image={this.state.image5} label={this.state.label5} aantal={this.state.aantal5}></MenLabel>)
    let keuze6 =(<MenLabel image={this.state.image6} label={this.state.label6} aantal={this.state.aantal6}></MenLabel>)
    let keuze7 =(<MenLabel image={this.state.image7} label={this.state.label7} aantal={this.state.aantal7}></MenLabel>)

    let keuze11 =(<MenLabel image={this.state.image11} label={this.state.label11} aantal={this.state.aantal11}></MenLabel>)
    let keuze12 =(<MenLabel image={this.state.image12} label={this.state.label12} aantal={this.state.aantal12}></MenLabel>)
    let keuze13 =(<MenLabel image={this.state.image13} label={this.state.label13} OpenMenu={this.OpenMenu} id={1.3} data={{url:'GetAllLampen', key:{param: ''}}} search={this.state.search}></MenLabel>)
    let keuze14 =(<MenLabel image={this.state.image14} label={this.state.label14} OpenMenu={this.OpenMenu} id={1.4} data={{url:'GetAllFixtures', key:{param: ''}}} search={this.state.search}></MenLabel>)
    let keuze15 =(<MenLabel image={this.state.image15} label={this.state.label15} OpenMenu={this.OpenMenu} id={1.5} data={{url:'GetAllSchemas', key:{param: ''}}} search={this.state.search}></MenLabel>)
    let keuze16 =(<MenLabel image={this.state.image16} label={this.state.label16} aantal={this.state.aantal16}></MenLabel>)
    let keuze17 =(<MenLabel image={this.state.image17} label={this.state.label17} aantal={this.state.aantal17}></MenLabel>)

    let keuze21 =(<MenLabel image={this.state.image21} label={this.state.label21} aantal={this.state.aantal21}></MenLabel>)
    let keuze22 =(App.Admin == 'GB' ) ? (<MenLabel image={this.state.image21} label={this.state.label21} aantal={this.state.aantal21}></MenLabel>) : 
                 (<MenLabel image={this.state.image22} label={this.state.label22} OpenMenu={this.OpenMenu} id={2.2} data={{url:'GetAllAccountsNw', key:{param: ''}}} search={this.state.search}></MenLabel>)
    let keuze26 =(<MenLabel image={this.state.image26} label={this.state.label26} OpenMenu={this.OpenMenu} id={2.6} data={{url:'ConsAllOverz', key:{param: ''}}} search={this.state.search}></MenLabel>)
    let keuze27 =(<MenLabel image={this.state.image27} label={this.state.label27} aantal={this.state.aantal27}></MenLabel>)

    let keuze31 =(<MenLabel image={this.state.image31} label={this.state.label31} aantal={this.state.aantal31}></MenLabel>)
    let keuze32 =(<MenLabel image={this.state.image32} label={this.state.label32} aantal={this.state.aantal32}></MenLabel>)
    let keuze33 = (<MenLabel image={this.state.image33} label={this.state.label33} aantal={this.state.aantal33}></MenLabel>)
    let keuze34 =(<MenLabel image={this.state.image34} label={this.state.label34} aantal={this.state.aantal34}></MenLabel>)
    let keuze35 =(<MenLabel image={this.state.image35} label={this.state.label35} aantal={this.state.aantal35}></MenLabel>)
    let keuze36 =(<MenLabel image={this.state.image36} label={this.state.label36} aantal={this.state.aantal36}></MenLabel>)
    let keuze37 =(<MenLabel image={this.state.image37} label={this.state.label37} aantal={this.state.aantal37}></MenLabel>)

    let vld_zoeken = (<TextField
        style={{marginRight: '15px', width: '90%',marginLeft: '10px'}}
            floatingLabelText="Search"
            defaultValue=''
            Key='1'
            ref="InpFix" 
            PopUp={false}
            autotext={true}
            detail={true}
            onChange = {this.ZetFilter}
            zien={true}  />);    
	let maintable = (<div id="tblDesktop" 
						style={{borderRadius: '50%', width: '1000px', height: '1000px', marginTop: '-50px', marginRight:'auto', marginLeft: 'auto', marginBottom:'20px',
						 minHeight: '1000px', maxHeight: '1000px', minWidth: '1000px', maxWidth: '1000px',  border: '2px solid #fff', display:'table',
						 background: 'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.9)) rgb(0, 143, 183)', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 14px 45px, rgba(0, 0, 0, 0.01) 0px 10px 18px', cellspacing:'0', cellpadding:'0'}}>
						 <tbody >
						 	<td style={{padding: '10', verticalAlign: 'middle', align: 'center', display: 'table-cell'}}>
						 		<table id="tablecells" style={{marginRight:'auto', marginLeft: 'auto'}} >
						 			<tbody style={{display: 'inline-grid'}}>
						 				<tr style={{paddingTop: '10px'}}>
						 					{keuze1}
						 					{keuze2}
						 					{keuze3}
						 					{keuze4}
						 					{keuze5}
						 					{keuze6}
						 					{keuze7}
						 				</tr>
						 				<tr style={{paddingTop: '10px'}}>
						 					{keuze11}
						 					{keuze12}
						 					{keuze13}
						 					{keuze14}
						 					{keuze15}
						 					{keuze16}
						 					{keuze17}
						 				</tr>
						 				<tr style={{paddingTop: '10px'}}>
						 					{keuze21}
						 					{keuze22}
											<td class="CellFilled droppable" style={{width: '330px', height: '100px', textAlign:'center'}} draggable="True">
												<div style={{marginLeft:'10px',background:'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.9)) rgba(0, 143, 183,.02)',width:'320px',height:'100%', boxShadow: 'rgba(0, 0, 0, 0.10) 5px 4px 45px, rgba(0, 0, 0, 0.025) 1px 10px 17px'}}>
													{vld_zoeken}
												</div>
											</td>
						 					{keuze26}
						 					{keuze27}
						 				</tr>
						 				<tr style={{paddingTop: '10px'}}>
						 					{keuze31}
						 					{keuze32}
						 					{keuze33}
						 					{keuze34}
						 					{keuze35}
						 					{keuze36}
						 					{keuze37}
						 				</tr>

						 			</tbody>

						 		</table>


						 	</td>

						 </tbody>
					</div>)  	
    return ( 
		<div>
			{maintable}
      {this.state.popupcons}
    </div>)
},
editsluiten(data) {
   this.setState({popupcons:null});
},
// Einde funkties popup sluiten

// Openen popup vensters
EditClick(selectedKey) {
  let hdata = {id:App.ConsId}; 
  this.setState({popupcons:(<PopupCons  id='popupcons' data={hdata} zindex={999999} Sluiten={this.editsluiten}></PopupCons>)})
  return;
},

OpenMenu(waarde) {
    let balk2zien = 0;
    let oudroute = App.Route;
    if (waarde.aantal > 0)
    {
      App.master.setState({AppHfdMenKeuze:waarde});
    }
    else
    {
      App.master.setState({AppHfdMenKeuze:null});
    }
    let selectedKey = waarde.keuze;

    switch (selectedKey) {
        case 1.5:
          App.Route = '/schema';
          balk2zien = 1;
          break;
        case 1.4:
          App.Route = '/fixt';
          balk2zien = 1;
          break;
        case 2.2:
          App.Route = '/manuf';
          balk2zien = 1;
          break;
        case 1.3:
          App.Route = '/lampen';
          balk2zien = 1;
          break;
        case 0.4:
          App.Route = '/libraries';
          balk2zien = 1;
          break;
        case 2.6:


          App.Route = '/user';
          balk2zien = 1;
          break;
    }
    if (selectedKey == 2.6 && App.Admin == 'GB')
    {
      this.EditClick();
      return;
    }              
    App.master.zetbalk2(balk2zien);
    App.master.setState({oldstatblk2:balk2zien});
    App.master.ZetRoute(App.Route);
},


ZetFilter(e) {
    let value = e.target.value;   
  	this.setState({search:value});
},
handleFilter(inhoud){

    if (this.state.colFilter)
    {
      let fildata = DgFunc.SetFilter(value,this.state.OrgData,this.state.colFilter,this.state.colNum);
      this.setState({immuData:Immutable.List(fildata)});
      this.setState({rowCount:Immutable.List(fildata).size});
      this.setState({scrollToIndex:0});
      this.setState({huidig:0}); 
      if (zet) {this.zetgeg(fildata[0],this.state.route);}
    }
},


});

module.exports = Homemen;






