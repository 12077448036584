import PropTypes from 'prop-types'
import React, { Component } from 'react'

import SocialLogin from './index.js'
var ReaButton = require("react-bootstrap").Button;



class Button extends Component {
  
  render () {
    const { children, triggerLogin, btext,triggerLogout, ...props } = this.props
    const style = {
      background: '#eee',
      border: '1px solid black',
      borderRadius: '3px',
      display: 'inline-block',
      margin: '5px',
      padding: '10px 20px'
    }

    return (
     <ReaButton id="logclick" style={{float:'right', marginRight:'10px'}} bsStyle="primary" bsSize="small" onClick={triggerLogin}>
        { children }
      </ReaButton>
    )
  }
}

export default SocialLogin(Button)
