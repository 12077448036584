function traverse(root, options) {
    "use strict";

    options = options || {};
    var pre = options.pre;
    var post = options.post;
    var skipProperty = options.skipProperty;

    function visit(node, parent, prop, idx) {
        if (!node || typeof node.type !== "string") {
            return;
        }

        var res = undefined;
        if (pre) {
            res = pre(node, parent, prop, idx);
        }

        if (res !== false) {
            for (var prop in node) {
                if (skipProperty ? skipProperty(prop, node) : prop[0] === "$") {
                    continue;
                }

                var child = node[prop];

                if (Array.isArray(child)) {
                    for (var i = 0; i < child.length; i++) {
                        visit(child[i], node, prop, i);
                    }
                } else {
                    visit(child, node, prop);
                }
            }
        }

        if (post) {
            post(node, parent, prop, idx);
        }
    }

    visit(root, null);
};

if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
    module.exports = traverse;
}
