const Colors = require('./colors');
const ColorManipulator = require('../utils/color-manipulator');
const Extend = require('../utils/extend');
const update = require('react-addons-update');

module.exports = {

  //get the MUI theme corresponding to a raw theme
  getMuiTheme: function (rawTheme) {
    let returnObj = {
      appBar: {
        color: rawTheme.palette.primary1Color,
        textColor: rawTheme.palette.alternateTextColor,
        height: rawTheme.spacing.desktopKeylineIncrement,
      },
      avatar: {
        borderColor: 'rgba(0, 0, 0, 0.08)',
      },
      button: {
        height: 36,
        minWidth: 88,
        iconButtonSize: rawTheme.spacing.iconSize * 2,
      },
      checkbox: {
        boxColor: rawTheme.palette.textColor,
        checkedColor: rawTheme.palette.primary1Color,
        requiredColor: rawTheme.palette.primary1Color,
        disabledColor: rawTheme.palette.disabledColor,
        labelColor: rawTheme.palette.textColor,
        labelDisabledColor: rawTheme.palette.disabledColor,
      },
      datePicker: {
        color: rawTheme.palette.primary1Color,
        textColor: rawTheme.palette.alternateTextColor,
        calendarTextColor: rawTheme.palette.textColor,
        selectColor: rawTheme.palette.primary2Color,
        selectTextColor: rawTheme.palette.alternateTextColor,
      },
      dropDownMenu: {
        accentColor: rawTheme.palette.borderColor,
      },
      flatButton: {
        color: rawTheme.palette.alternateTextColor,
        textColor: rawTheme.palette.textColor,
        primaryTextColor: rawTheme.palette.primary1Color,        
        secondaryTextColor: rawTheme.palette.accent1Color,
      },
      floatingActionButton: {
        buttonSize: 56,
        miniSize: 40,
        iconSize:55,
        miniIconSize: 40,
        dropdSize: 20,
        dropdHeight: 20,
        dropdWidth: 20,      
        dropdFloat: 'right',
        dropdTop: '-45px',          
            color: rawTheme.palette.accent1Color,
        iconColor: rawTheme.palette.alternateTextColor,
        secondaryColor: rawTheme.palette.primary1Color,
        secondaryIconColor: rawTheme.palette.alternateTextColor,
        primaryIconColor: rawTheme.palette.primary1Color,
        disabledTextColor: rawTheme.palette.disabledColor,
      },

      gridTile: {
        textColor: Colors.white,
      },
      inkBar: {
        backgroundColor: rawTheme.palette.accent1Color,
      },
      leftNav: {
        width: rawTheme.spacing.desktopKeylineIncrement * 4,
        color: rawTheme.palette.canvasColor,
      },
      listItem: {
        nestedLevelDepth: 18,
      },
      menu: {
        backgroundColor: rawTheme.palette.canvasColor,
        containerBackgroundColor: rawTheme.palette.canvasColor,
      },
      menuItem: {
        dataHeight: 32,
        height: 48,
        hoverColor: 'rgba(0, 0, 0, .035)',
        padding: rawTheme.spacing.desktopGutter,
        selectedTextColor: rawTheme.palette.accent1Color,
      },
      menuSubheader: {
        padding: rawTheme.spacing.desktopGutter,
        borderColor: rawTheme.palette.borderColor,
        textColor: rawTheme.palette.primary1Color,
      },
      paper: {
        backgroundColor: rawTheme.palette.canvasColor,
      },
      radioButton: {
        borderColor:  rawTheme.palette.textColor,
        backgroundColor: rawTheme.palette.alternateTextColor,
        checkedColor: rawTheme.palette.primary1Color,
        requiredColor: rawTheme.palette.primary1Color,
        disabledColor: rawTheme.palette.disabledColor,
        size: 24,
        labelColor: rawTheme.palette.textColor,
        labelDisabledColor: rawTheme.palette.disabledColor,
      },
      raisedButton: {
        color: rawTheme.palette.alternateTextColor,
        textColor: rawTheme.palette.textColor,
        primaryColor: rawTheme.palette.accent1Color,
        primaryTextColor: rawTheme.palette.alternateTextColor,
        secondaryColor: rawTheme.palette.primary1Color,
        secondaryTextColor: rawTheme.palette.alternateTextColor,
      },
      refreshIndicator: {
        strokeColor: rawTheme.palette.borderColor,
        loadingStrokeColor: rawTheme.palette.primary1Color,
      },
      slider: {
        trackSize: 2,
        trackColor: rawTheme.palette.primary3Color,
        trackColorSelected: rawTheme.palette.accent3Color,
        handleSize: 12,
        handleSizeDisabled: 8,
        handleSizeActive: 18,
        handleColorZero: rawTheme.palette.borderColor,
        handleFillColor: rawTheme.palette.alternateTextColor,
        selectionColor: rawTheme.palette.primary1Color,
        rippleColor: rawTheme.palette.primary1Color,
      },
      snackbar: {
        textColor: rawTheme.palette.alternateTextColor,
        backgroundColor: rawTheme.palette.textColor,
        actionColor: rawTheme.palette.accent1Color,
      },
      table: {
        backgroundColor: rawTheme.palette.canvasColor,
      },
      tableHeader: {
        borderColor: rawTheme.palette.borderColor,
      },
      tableHeaderColumn: {
        textColor: rawTheme.palette.primary3Color,
        height: 56,
        spacing: 24,
      },
      tableFooter: {
        borderColor: rawTheme.palette.borderColor,
        textColor: rawTheme.palette.primary3Color,
      },
      tableRow: {
        hoverColor: rawTheme.palette.accent2Color,
        stripeColor: ColorManipulator.lighten(rawTheme.palette.primary1Color, 0.55),
        selectedColor: rawTheme.palette.borderColor,
        textColor: rawTheme.palette.textColor,
        borderColor: rawTheme.palette.borderColor,
      },
      tableRowColumn: {
        height: 48,
        spacing: 24,
      },
      timePicker: {
        color: rawTheme.palette.alternateTextColor,
        textColor: rawTheme.palette.accent3Color,
        accentColor: rawTheme.palette.primary1Color,
        clockColor: rawTheme.palette.primary3Color,
        selectColor: rawTheme.palette.primary2Color,
        selectTextColor: rawTheme.palette.alternateTextColor,
      },
      toggle: {
        thumbOnColor: rawTheme.palette.primary1Color,
        thumbOffColor: rawTheme.palette.accent2Color,
        thumbDisabledColor: rawTheme.palette.borderColor,
        thumbRequiredColor: rawTheme.palette.primary1Color,
        trackOnColor: ColorManipulator.fade(rawTheme.palette.primary1Color, 0.5),
        trackOffColor: rawTheme.palette.primary3Color,
        trackDisabledColor: rawTheme.palette.primary3Color,
        labelColor: rawTheme.palette.textColor,
        labelDisabledColor: rawTheme.palette.disabledColor,
      },
      toolbar: {
        backgroundColor: ColorManipulator.darken(rawTheme.palette.accent2Color, 0.05),
        height: 50,
        titleFontSize: 20,
        iconColor: 'rgba(0, 0, 0, .40)',
        separatorColor: 'rgba(0, 0, 0, .175)',
        menuHoverColor: 'rgba(0, 0, 0, .10)',

      },
      tabs: {
        backgroundColor: rawTheme.palette.primary1Color,
      },
      textField: {
        textColor: rawTheme.palette.textColor,
        hintColor: rawTheme.palette.disabledColor,
        floatingLabelColor: rawTheme.palette.textColor,
        disabledTextColor: rawTheme.palette.disabledColor,
        errorColor: Colors.red500,
        focusColor: rawTheme.palette.primary1Color,
        backgroundColor: 'transparent',
        borderColor: rawTheme.palette.borderColor,
      },
     dowContainer: {
        lineHeight: '12px',
        marginTop:'20px',
        fontWeight: '500',
        height: '32px',
        backgroundColor: 'rgb(1, 115, 199)',
        borderRadius: '2px 2px 0 0',
      },
      diadowContainer: {
        padding: '0 0px',
        lineHeight: '12px',
        opacity: '0.5',
        marginTop:'20px',
        fontWeight: '500',
        margin: 0,
        height: '32px',
        width:'100%',
        textAlign: 'center',
        backgroundColor: 'rgb(1, 115, 199)',
        borderRadius: '2px 2px 0 0',
      },
      opldia: {
        maxWidth: '80%',
        width: '80%',
        position: 'absolute',
        marginLeft: '10%',
        marginTop:'20px',
        top:'0px'
      },
      opldiaZonder: {
        maxWidth: '80%',
        width: '80%',
        position: 'absolute',
        marginLeft: '10%'
      },     
      opldiaMidden: {
        maxWidth: '80%',
        width: '80%',
        position: 'absolute',
        marginLeft: '10%',
        paddingTop: '0px',
      },            
      opldiaZonderCont:{
        maxWidth: '80%',
        width: '80%',
        position: 'absolute',
        marginLeft: '10%',
        overflow:'hidden'
      },      
      opldiaonder: {
        maxWidth: '90%',
        width: '80%',
        position: 'absolute',
        marginLeft: '10%',
        marginTop:'20px',
        bottom:'150px',
      },


      diadow: {
        listStyle: 'none',
        width: '100%',
        textAlign: 'center',
        marginTop: '10px',
        color: 'white'
      },


      dow: {
        listStyle: 'none',
        float: 'left',
        width: '20%',
        textAlign: 'center',
        margin: '0 2px',
        marginTop: '10px',
        fontSize: '13px',
        lineHeight: '13px',
        color: 'white'
      },

      dowContainer1: {
        lineHeight: '12px',
        opacity: '0.8',
        marginTop:'20px',
        fontWeight: '500',
        height: '32px',
        backgroundColor: 'white',
        borderRadius: '2px 2px 0 0',
      },

      dow1: {
        listStyle: 'none',
        float: 'left',
        width: '20%',
        textAlign: 'center',
        margin: '0 2px',
        marginTop: '10px',
        fontSize: '13px',
        lineHeight: '13px',
        color: 'black'
      },

      dow2: {
        listStyle: 'none',
        float: 'left',
        width: '100%',
        textAlign: 'left',
        margin: '0 2px',
        marginTop: '10px',
        fontSize: '150%',
        fontWeight: '500',
        lineHeight: '13px',
        color: 'white',
      },

      dow3: {
        listStyle: 'none',
        float: 'left',
        width: '20%',
        textAlign: 'left',
        margin: '0 2px',
        marginTop: '10px',
        fontSize: '25px',
        lineHeight: '13px',
        color: 'white'
      },      

      weekTitle: {
        padding: '0 14px',
        lineHeight: '12px',
        opacity: '0.5',
        height: '12px',
        fontWeight: '500',
        margin: 0
      },

      weekTitleDay: {
        listStyle: 'none',
        float: 'left',
        width: '32px',
        textAlign: 'center',
        margin: '0 2px'
      },

      content6HG: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '50%',
        left:'5px'
      },
      content6HK: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '100%',
        left:'5px'

      },
      content6DG: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '50%',
        left:'5px'

      },
      content6DK: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '100%',
        left:'5px'
      },
      content2HG: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        paddingRight: '15px',
        paddingLeft: '10px',
        marginTop: '15px',
        float: 'left',
        width: '20%',
        left:'5px'        
      },
      content2HK: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        marginTop: '15px',        
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '100%',
        left:'5px'
      },
      content2DG: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        marginTop: '15px',        
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '20%',
        left:'5px'        
      },
      content2DK: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        marginTop: '15px',        
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '100%',
        left:'5px'
      },      
      content10HG: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        marginTop: '15px',
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '80%',
        left:'5px'
      },
      content10HK: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        marginTop: '15px',        
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '100%',
        left:'5px'        
      },
      content10DG: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        paddingRight: '15px',
        marginTop: '15px',        
        paddingLeft: '10px',
        float: 'left',
        width: '80%',
        left:'5px'
      },
      content10DK: {
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '1px',
        marginTop: '15px',        
        paddingRight: '15px',
        paddingLeft: '10px',
        float: 'left',
        width: '100%',
        left:'5px'
      },

      actions: {
        marginRight: 8,
      },   

      dialogContents: {
        width: '280px'
      },         
            
      isRtl: false,
    };

    //add properties to objects inside 'returnObj' that depend on existing properties
    returnObj.flatButton.disabledTextColor = ColorManipulator.fade(returnObj.flatButton.textColor, 0.3);
    returnObj.raisedButton.disabledColor = ColorManipulator.darken(returnObj.raisedButton.color, 0.1);
    returnObj.raisedButton.disabledTextColor = ColorManipulator.fade(returnObj.raisedButton.textColor, 0.3);
    returnObj.toggle.trackRequiredColor = ColorManipulator.fade(returnObj.toggle.thumbRequiredColor, 0.5);

    //append the raw theme object to 'returnObj'
    returnObj.rawTheme = rawTheme;

    //set 'static' key as true (by default) on return object. This is to support the ContextPure mixin.
    returnObj.static = true;

    return returnObj;
  },

  //functions to modify properties of raw theme, namely spacing, palette
  //and font family. These functions use the React update immutability helper
  //to create a new object for the raw theme, and return a new MUI theme object

  //function to modify the spacing of the raw theme. This function recomputes
  //the MUI theme and returns it based on the new theme.
  modifyRawThemeSpacing: function (muiTheme, newSpacing) {
    let newRawTheme = update (muiTheme.rawTheme, {spacing: {$set: newSpacing}});
    return this.getMuiTheme(newRawTheme);
  },


  //function to modify the palette of the raw theme. This function recomputes
  //the MUI theme and returns it based on the new raw theme.
  //keys inside 'newPalette' override values for existing keys in palette
  modifyRawThemePalette: function (muiTheme, newPaletteKeys) {
    let newPalette = Extend(muiTheme.rawTheme.palette, newPaletteKeys);
    let newRawTheme = update (muiTheme.rawTheme, {palette: {$set: newPalette}});
    return this.getMuiTheme(newRawTheme);
  },

  //function to modify the font family of the raw theme. This function recomputes
  //the MUI theme and returns it based on the new raw theme.
  modifyRawThemeFontFamily: function (muiTheme, newFontFamily) {
    let newRawTheme = update (muiTheme.rawTheme, {fontFamily: {$set: newFontFamily}});
    return this.getMuiTheme(newRawTheme);
  },

};
