const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const ThemeManager = require('../matui/styles/theme-manager');
const LightRawTheme = require('../matui/styles/raw-themes/light-raw-theme');
const Colors = require('../matui/styles/colors');
const StylePropable = require('../matui/mixins/style-propable');
const StyleResizable = require('../matui/mixins/style-resizable');

const List = require('../matui/lists/list.jsx');
const ListItem = require('../matui/lists/list-item.jsx');
const Checked = require('../matui/svg-icons/toggle/check-box.jsx');
const UnChecked = require('../matui/svg-icons/toggle/check-box-outline-blank.jsx');
const DateTime = require('../matui/utils/date-time');
const Algfunc = require('../functions/algemeen');
var Panel = require("react-bootstrap").Panel;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
const Home = React.createClass({
  mixins: [StylePropable,StyleResizable,PureRenderMixin],
  contextTypes: {
    router: React.PropTypes.object
  },
  
  getInitialState: function() {
    return {
      foto: '',
      lijstGnkVm: '',
      teller:0,
      vmafw_list_gnk:[],
      vmaanw_list_gnk:[],
      nmafw_list_gnk:[],
      nmaanw_list_gnk:[],
      agandadata: [],
    };
  },


  componentWillMount() {
    if (!App.master)
    {
      window.location.hash = "/";
      return;
    }    
    let d1 = new Date();

    let jaar = d1.getFullYear();
    let maand = d1.getMonth() + 1;
    this.GetAgendaData(jaar,maand);     
    this.vul_afw_lijst(d1);
  },   

  componentDidMount() {
    if (App.Mobile) {
      window.scrollTo(0, 1);
      window.addEventListener("load",function() {
        // Set a timeout...
      setTimeout(function(){window.scrollTo(0, 1);
        }, 0);
      });
    }    
    if (!App.master)
    {
      window.location.hash = "/";
      return;
    }

  },   


  render() {
    const wingrote = parseInt(window.innerWidth);

    // VELDEN OP GEWOON DETAIL SCHERM  
    let graf_klct, graf_act;

    // Velden enkel verlof
    // VELDEN OP GEWOON DETAIL SCHERM  

    let verl_tab_vm_gnk = (
      <Panel  header="Voormiddag" bsStyle="warning" style={{background: '#fff', overflow:'hidden'}}>
          <Row className="show-grid">
            <Col xs={12} md={12} sm={12} >
              <Row className="show-grid">
                <Panel  header="Afwezig" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff', overflow:'hidden'}}>
                    <Col xs={12} md={12} sm={12} >
                      <List 
                        listItems={this.state.vmafw_list_gnk}
                        onKlik={this.onlistKlik}>
                      </List>
                    </Col>
                </Panel>
              </Row>
              <Row className="show-grid">
                <Panel  header="Aanwezig" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff', overflow:'hidden'}}>
                    <Col xs={12} md={12} sm={12} >
                      <List 
                        listItems={this.state.vmaanw_list_gnk}
                        onKlik={this.onlistKlik}>
                      </List>
                    </Col>
                </Panel>
              </Row>
            </Col>
          </Row>
      </Panel>);    
    let verl_tab_nm_gnk = (
      <Panel  header="Namiddag" bsStyle="warning" style={{background: '#fff', overflow:'hidden'}}>
          <Row className="show-grid">
            <Col xs={12} md={12} sm={12} >
              <Row className="show-grid">
                <Panel  header="Afwezig" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff', overflow:'hidden'}}>
                    <Col xs={12} md={12} sm={12} >
                      <List 
                        listItems={this.state.nmafw_list_gnk}
                        onKlik={this.onlistKlik}>
                      </List>
                    </Col>
                </Panel>
              </Row>
              <Row className="show-grid">
                <Panel  header="Aanwezig" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff', overflow:'hidden'}}>
                    <Col xs={12} md={12} sm={12} >
                      <List 
                        listItems={this.state.nmaanw_list_gnk}
                        onKlik={this.onlistKlik}>
                      </List>
                    </Col>
                </Panel>
              </Row>
            </Col>
          </Row>
      </Panel>);    

    return ( 
      <Row className="show-grid" style={{ position: 'relative'}}>
        <Col  xs={4} md={4} sm={4} ref='GrdLinks' style={{height:'auto'}}>
        </Col>
        <Col  xs={4} md={4} sm={4} ref='GrdLinks' style={{overflow: 'auto'}}>
              {verl_tab_vm_gnk}
        </Col>
        <Col  xs={4} md={4} sm={4} ref='GrdLinks' style={{overflow: 'auto'}}>
              {verl_tab_nm_gnk}
        </Col>
      </Row>)
  },

  GetAgendaData(jaar,maand) {
    var that = this;
    let hudata={jaar:jaar,maand:maand};
    var aj2 = $.ajax({
        type: "POST",
        dataType: "json",
        url: './server/index.php?function=GTotAfwLinesAgenda',
        data: JSON.stringify(hudata),
        success: function(data){
          that.setState({agandadata:data.data});
        },
        error: function(data){
          return null;
        }
      });
  },  


  vul_afw_lijst(datum) {
      var that = this;
      let hdata = {datum:datum};
      let listItem = {
        leftAvatar:'',
        text:''
      };

      let listItemAfw = {
        leftAvatar:'',
        text:''
      };

      let keyaccount = 0;
      let vmaanw_list_gnk = [];
      let nmaanw_list_gnk = [];
      let vmafw_list_gnk = [];
      let nmafw_list_gnk = [];
      
      var aj2 = $.ajax({
        type: "POST",
        dataType: "json",
        url: './server/index.php?function=GetAdmDayAfw',
        data: JSON.stringify(hdata),
        success: function(data){
          if (data)
          {
            if (data.data)
            {
              hdata = data.data;
              for (let i=0; i < hdata.length; i++) {
                let listItem = {
                  leftAvatar:'',
                  primaryText:'',
                  hfdsleutel:0,
                  secondaryText:'',
                  hoogte:50,
                };     
                let listItemAfw = {
                  leftAvatar:'',
                  primaryText:'',
                  hfdsleutel:0,
                  secondaryText:'',
                  hoogte:50,
                };                                
                listItem.leftAvatar = hdata[i].foto;
                listItem.key = i;
                listItem.hfdsleutel = hdata[i].cons_id;
                listItemAfw.key = i;
                listItemAfw.hfdsleutel = hdata[i].cons_id;
                listItemAfw.leftAvatar = hdata[i].foto;

                if (hdata[i].vm_aanw)
                {
                  listItem.secondaryText = '';
                  if (hdata[i].vm_aanw.length > 2)
                  {
                    listItem.primaryText = hdata[i].vm_aanw;
                    vmaanw_list_gnk.push(listItem);  
                  }
                }
                if (hdata[i].nm_aanw)
                {
                  listItem.secondaryText = '';
                  if (hdata[i].nm_aanw.length > 2)
                  {
                    listItem.primaryText = hdata[i].nm_aanw;
                    nmaanw_list_gnk.push(listItem);          
                  }
                }

                if (hdata[i].vm_afw)
                {
                  if (hdata[i].vm_afw.length > 2)
                  {
                    listItemAfw.primaryText = hdata[i].vm_afw;
                    listItemAfw.secondaryText = '';
                    listItemAfw.secondaryText = hdata[i].vm_txt;


                    if (hdata[i].goedgekeurd == 'JA')
                    {
                      listItemAfw.rightIcon= (<Checked hoogte={50}/>);
                    }
                    else
                    {
                      listItemAfw.rightIcon= (<UnChecked hoogte={50}/>);
                    }
                    vmafw_list_gnk.push(listItemAfw);          
                  }
                }
                if (hdata[i].nm_afw)
                {
                  listItemAfw.secondaryText = '';
                  if (hdata[i].goedgekeurd == 'JA')
                  {
                    listItemAfw.rightIcon= (<Checked hoogte='50px'/>);
                  }
                  else
                  {
                    listItemAfw.rightIcon= (<UnChecked hoogte='50px'/>);
                  }


                  if (hdata[i].nm_afw.length > 2)
                  {
                    listItemAfw.primaryText = hdata[i].nm_afw;
                    listItemAfw.secondaryText = hdata[i].nm_txt;
                     nmafw_list_gnk.push(listItemAfw);          
                  }
                }
              }
            }
          }
          that.state.vmaanw_list_gnk = vmaanw_list_gnk;
          that.state.nmaanw_list_gnk= nmaanw_list_gnk;
          that.state.vmafw_list_gnk= vmafw_list_gnk;
          that.setState({nmafw_list_gnk: nmafw_list_gnk})
        },
        error: function(data){
          return null;
        }
      });
  },

  zetmaand(date){
    let maand = date.getMonth() + 1;
    let jaar = date.getFullYear();   
    this.GetAgendaData(jaar,maand);     
    var datzoek = DateTime.formatZoek(date);
    this.vul_afw_lijst(datzoek);
  },

  _onSelectedDate(e, date){
    var datzoek = DateTime.formatZoek(date);
    this.vul_afw_lijst(datzoek);

  },

  onlistKlik(sleutel){
    let hdata = {id:sleutel};
    let that = this;
    var aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=GConsultant',
      data: JSON.stringify(hdata),
      success: function(data){
        App.master.setState({overzdata:data});
        App.Route = '/consultants';
        that.context.router.push(App.Route);    

      },
      error: function(data){
          return null;
      }

    });
  }

  

});

module.exports = Home;






