'use strict'

var SLICE = Array.prototype.slice

module.exports = function(fn, args){
    return function(){
        var thisArgs = SLICE.call(args || [])

        if (arguments.length){
            thisArgs.push.apply(thisArgs, arguments)
        }

        return fn.apply(this, thisArgs)
    }
}