'use strict';

module.exports = require('has-touch')?
	{
		onMouseDown: 'onTouchStart',
		onMouseUp  : 'onTouchEnd',
		onMouseMove: 'onTouchMove'
	}:
	{
		onMouseDown: 'onMouseDown',
		onMouseUp  : 'onMouseUp',
		onMouseMove: 'onMouseMove'
	}