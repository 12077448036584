const React = require('react');
const ReactDOM = require('react-dom');
const IconButOver = require('../matui/icon_but_over.jsx');
const Contstafunc = require('./cont_func.jsx');
const DateTime = require('../matui/utils/date-time');
const AlgFuncUpd = require('../functions/algemeen');

module.exports = {
  Act_buttons(ParFunc) {
    let PTitact = (<h3 className="panel-title">
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        iconClassName="fa fa-phone" 
                        tooltip='Telefoongesprek'
                        onClick={(event)=>ParFunc('TE')}/>
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        tooltip="Mail"
                        iconClassName="fa fa-envelope-o" 
                        onClick={(event)=>ParFunc('MA')}/>
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        tooltip="Taak"
                        iconClassName="fa fa-tasks" 
                        onClick={(event)=>ParFunc('TK')}/>
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        tooltip="Afspraak"
                        iconClassName="fa fa-calendar" 
                        onClick={(event)=>ParFunc('AF')}/></h3>);
    return PTitact;
  },

  NewActivity(type, hdata, scherm) {
    var hudata = Contstafunc.Dat_activity();
    switch (type) {
        case 'TE':
          hudata.TypeTxt='Telefoon';
          break;
        case 'MA':
          hudata.TypeTxt='Mail';
          break;
        case 'AF':
          hudata.TypeTxt='Afspraak';
          break;
        case 'TK':
          hudata.TypeTxt='Taak';
          break;
    }
    hudata.Type=type;
    hudata.Aanvrid=hdata.Aanvrid;
    hudata.Aanvrtxt=hdata.Aanvrtxt;
    hudata.Acountid=hdata.Acountid;
    hudata.Accountname=hdata.Accountname;
    hudata.Contid=hdata.Contid;
    hudata.ContName=hdata.ContName;
    hudata.ConsId=hdata.ConsId;
    hudata.Consnaam=hdata.Consnaam;
    hudata.Origin=hdata.Origin;
    hudata.id = 0;
    scherm.openen(hudata);
  },
  NewActiv(type, hdata) {
    var hudata = Contstafunc.Dat_activity();
    let vhdatum = DateTime.Get_VandaagTxtDat();
    switch (type) {
        case 'TE':
          hudata.typetxt='Telefoon';
          break;
        case 'MA':
          hudata.typetxt='Mail';
          break;
        case 'AF':
          hudata.typetxt='Afspraak';
          break;
        case 'TK':
          hudata.typetxt='Taak';
          break;
    }
    hudata.type=type;
    hudata.request_id=hdata.request_id;
    hudata.reqname=hdata.reqname;
    hudata.accountid=hdata.accountid;
    hudata.accountname=hdata.accountname;
    hudata.contactid=hdata.contactid;
    hudata.contname=hdata.contname;
    hudata.cons_id=hdata.cons_id;
    hudata.consname=hdata.consname;
    hudata.Origin=hdata.Origin;
    hudata.date=vhdatum;
    hudata.status = 'O';
    hudata.statustxt = 'Open';
    hudata.username=App.username;
    hudata.id = 0;
    hudata.userid=App.userid;
    hudata.username=App.username;
    hudata.creanaam=App.username;
    hudata.crea_id=App.userid;
    return(hudata);
  },
  GetActiv(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=Gactiviteit',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },
  DelActiv(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=DelActiviteit',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
  },  
  Bewaren(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
            let foutdisp = {err_oms:'',err_soort:'',err_statklacht:'',err_creadat: '', err_afsldat:'', err_klacht:'', err_klant:'', err_creator:'',err_uitvoerder:'',err_uur:'', err_end_time:'', error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.subject, '','');
            foutdisp.err_oms = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.type, '','');
            foutdisp.err_soort = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let insql = (Sql == 'UPD') ? 'UpdActiviteit' : 'InsActiviteit';
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function='+insql,
                data: JSON.stringify(hdata),
                success: function(data){
                  if (data.error) 
                  {
                    foutdisp.err_oms = data.erroms;
                    foutdisp.err_soort = data.errsoort;
                    foutdisp.err_statklacht = data.errstat;
                    foutdisp.err_creadat = data.errdatontv;
                    foutdisp.err_afsldat = data.errdatafhandel;
                    foutdisp.err_klacht = data.errklacht;
                    foutdisp.err_klant = data.errklant;
                    foutdisp.err_creator = data.errcreator;
                    foutdisp.err_uitvoerder = data.erruitvoerder;
                    reject(foutdisp);
                  }
                  else
                  {
                      resolve(data);
                  }
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    }, 


};
