const React = require('react');
const ReactDOM = require('react-dom');
const IconButOver = require('../matui/icon_but_over.jsx');
const Contstafunc = require('./cont_func.jsx');
const AlgFuncUpd = require('../functions/algemeen');

module.exports = {
    GetConsultant(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GConsultant',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      if (data.error) 
                      {
                        reject(data);
                      }
                      else
                      {
                        resolve(data);
                      }
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },

    GetConsultantSoc(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GConsultantSoc',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
    },



    GetAllCons(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=ConsAllOverz',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },



    CreaConsTimel(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=CreaConsTimel',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
     },


    GetZkCons(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=Gzkcons',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },
    GetZkReq(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=Gzkreq',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },


    ZetMogKand(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=ZetMogKand',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },   
    GetDetFinCons(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetDetFinCons',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },   
    GetDetFinConsFree(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetDetFinConsFree',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },   
    PrepConsFinMail(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=prepConsFinMail',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },   
    GetReferentie(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetReferentie',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
    },   
    GetProject(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetProject',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
    },   
    GetProjectSoc(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetProjectSoc',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
    },   

    PrepConsContract(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=prepConsContract',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
    }, 
    PrepConsDimona(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=prepConsDimona',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
    }, 
    PrepConsKennismaak(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=prepKennismaak',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
    }, 


    SendConsFinMail(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=sendConsFinMail',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
    },  

    Bewaren(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
                let foutdisp = {err_mail:'',err_assmail:'',err_naam:'',err_vnaam:'',err_login:'',err_paswoord:'',err_indienst:'',err_uitdienst:'',error:false};
                let fout = false;
                let Controle  = '';
                let arra = [];                
                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.last_name, '','');
                foutdisp.err_naam = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};
                if (App.Admin == 'SA' && hdata.actief == '1' )
                {
                    if (hdata.login)
                    {
                        Controle = AlgFuncUpd.HandleVeldLeeg(hdata.login, '','');
                        foutdisp.err_login = Controle.boodschap;
                        if (!foutdisp.error) {foutdisp.error = Controle.fout};
                    }
                    else
                    {
                      foutdisp.err_login = 'Login verplicht';
                      if (!foutdisp.error) {foutdisp.error = true};
                    }
                }

                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.first_name, '','');
                foutdisp.err_vnaam = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};
                Controle = AlgFuncUpd.HandleChkMail(hdata.E_mail,'','');
                if (!foutdisp.error) {foutdisp.error = Controle.fout};
                if (Controle.fout) {foutdisp.err_assmail = Controle.boodschap}
                if (hdata.adressen) {arra = hdata.adressen};
                if (arra.length > 0)
                {
                    for (let rowIdx = 0; rowIdx < arra.length; rowIdx++) 
                    {
                        let filrow = arra[rowIdx];
                        Controle = AlgFuncUpd.HandleChkMail(filrow.email,'','');
                        if (!foutdisp.error) {foutdisp.error = Controle.fout};
                        if (Controle.fout) {foutdisp.err_mail = Controle.boodschap}
                    }
                }
                if (foutdisp.error) {
                    reject(foutdisp);
                    return;
                }
                if (hdata.actief == 1)
                {
                    hdata.dateuit = '';
                }
                else
                {
                    hdata.login= '';
                    hdata.paswoord = '';
                }
                let insql = (Sql == 'UPD') ? 'UConsultant' : 'IConsultant';
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function='+insql,
                    data: JSON.stringify(hdata),
                    success: function(data){
                        if (data.error) 
                        {
                          reject(data);
                        }
                        else
                        {
                            resolve(data);
                        }
                    },
                    error: function(data){
                        reject({error:'Aanpassingen niet doorgevoerd'});
                    }
                });
            });
        },     

    BewarenSoc(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
                let foutdisp = {err_mail:'',err_naam:'',err_vnaam:'',error:false};
                let fout = false;
                let Controle  = '';
                let arra = [];                
                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.last_name, '','');
                foutdisp.err_naam = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};
                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.first_name, '','');
                foutdisp.err_vnaam = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};
                Controle = AlgFuncUpd.HandleChkMail(hdata.E_mail,'','');
                if (!foutdisp.error) {foutdisp.error = Controle.fout};
                if (hdata.adressen) {arra = hdata.adressen};
                if (arra.length > 0)
                {
                    for (let rowIdx = 0; rowIdx < arra.length; rowIdx++) 
                    {
                        let filrow = arra[rowIdx];
                        Controle = AlgFuncUpd.HandleChkMail(filrow.email,'','');
                        if (!foutdisp.error) {foutdisp.error = Controle.fout};
                        if (Controle.fout) {foutdisp.err_mail = Controle.boodschap}
                    }
                }
                if (foutdisp.error) {
                    reject(foutdisp);
                    return;
                }
                let insql = (Sql == 'UPD') ? 'UConsultantSoc' : 'UConsultantSoc';
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function='+insql,
                    data: JSON.stringify(hdata),
                    success: function(data){
                        if (data.error) 
                        {
                          reject(data);
                        }
                        else
                        {
                            resolve(data);
                        }
                    },
                    error: function(data){
                        reject({error:'Aanpassingen niet doorgevoerd'});
                    }
                });
            });
        },     

    BewaarFinCons(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
                let foutdisp = {error:false};
                let fout = false;
                let Controle  = '';
                let arra = [];                
                let insql = (Sql == 'UPD') ? 'UpdFinCons' : 'InsFinCons';
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function='+insql,
                    data: JSON.stringify(hdata),
                    success: function(data){
                        if (data.error) 
                        {
                          reject(data);
                        }
                        else
                        {
                            resolve(data);
                        }
                    },
                    error: function(data){
                        reject({error:'Aanpassingen niet doorgevoerd'});
                    }
                });
            });
        },     
    BewaarReferentie(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
                let foutdisp = {err_mail: "",err_bedrijf: "",err_contact: ""};
                let fout = false;
                let Controle  = '';
                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.accountid, '','');
                foutdisp.err_bedrijf = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};

                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.contactid, '','');
                foutdisp.err_contact = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};

                Controle = AlgFuncUpd.HandleChkMail(hdata.E_mail, '','');
                foutdisp.err_mail = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};

                if (foutdisp.error) {
                   reject(foutdisp);
                   return;
                }

                let insql = (Sql == 'UPD') ? 'UpdReferentie' : 'InsReferentie';
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function='+insql,
                    data: JSON.stringify(hdata),
                    success: function(data){
                        if (data.error) 
                        {
                          reject(data);
                        }
                        else
                        {
                            resolve(data);
                        }
                    },
                    error: function(data){
                        reject({error:'Aanpassingen niet doorgevoerd'});
                    }
                });
            });
        },  
    BewaarProject(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
                let foutdisp = {err_funktie: "",err_bedrijf: "",err_contact: ""};
                let fout = false;
                let Controle  = '';
                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.accountid, '','');
                foutdisp.err_bedrijf = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};

                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.projfuncid, '','');
                foutdisp.err_funktie = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};
                if (foutdisp.error) {
                   reject(foutdisp);
                   return;
                }

                let insql = (Sql == 'UPD') ? 'UpdProject' : 'InsProject';
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function='+insql,
                    data: JSON.stringify(hdata),
                    success: function(data){
                       resolve(data);
                    },
                    error: function(data){
                        reject({error:'Aanpassingen niet doorgevoerd'});
                    }
                });
            });
        },  
    BewaarProjectSoc(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
                let foutdisp = {err_funktie: "",err_bedrijf: "",err_contact: ""};
                let fout = false;
                let Controle  = '';
                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.accountname, '','');
                foutdisp.err_bedrijf = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};

                Controle = AlgFuncUpd.HandleVeldLeeg(hdata.proj_function, '','');
                foutdisp.err_funktie = Controle.boodschap;
                if (!foutdisp.error) {foutdisp.error = Controle.fout};
                if (foutdisp.error) {
                   reject(foutdisp);
                   return;
                }

                let insql = (Sql == 'UPD') ? 'UpdProjectSoc' : 'InsProjectSoc';
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function='+insql,
                    data: JSON.stringify(hdata),
                    success: function(data){
                        if (data.error) 
                        {
                          reject(data);
                        }
                        else
                        {
                            resolve(data);
                        }
                    },
                    error: function(data){
                        reject({error:'Aanpassingen niet doorgevoerd'});
                    }
                });
            });
        },  

    DelProjectSoc(hdata) {
        return new Promise(
            function (resolve, reject) {
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=DelProjectSoc',
                    data: JSON.stringify(hdata),
                    success: function(data){
                        if (data.error) 
                        {
                          reject(data);
                        }
                        else
                        {
                            resolve(data);
                        }
                    },
                    error: function(data){
                        reject({error:'Aanpassingen niet doorgevoerd'});
                    }
                });
            });
        },  
    DeleteSoc(hdata) {
        return new Promise(
            function (resolve, reject) {
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=DeleteSoc',
                    data: JSON.stringify(hdata),
                    success: function(data){
                        if (data.error) 
                        {
                          reject(data);
                        }
                        else
                        {
                            resolve(data);
                        }
                    },
                    error: function(data){
                        reject({error:'Aanpassingen niet doorgevoerd'});
                    }
                });
            });
        },  




    BewaarFinConsFree(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
                let foutdisp = {error:false};
                let fout = false;
                let Controle  = '';
                let arra = [];                
                let insql = (Sql == 'UPD') ? 'UpdFinConsFree' : 'InsFinConsFree';
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function='+insql,
                    data: JSON.stringify(hdata),
                    success: function(data){
                        if (data.error) 
                        {
                          reject(data);
                        }
                        else
                        {
                            resolve(data);
                        }
                    },
                    error: function(data){
                        reject({error:'Aanpassingen niet doorgevoerd'});
                    }
                });
            });
        },     
    Bewaarzktype(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {


            let foutdisp = {err_naam:'', error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.profile, '','');
            foutdisp.err_naam = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let insql = (Sql == 'UPD') ? 'UZkcons' : 'IZkcons';
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function='+insql,
                data: JSON.stringify(hdata),
                success: function(data){
                  if (data.error) 
                  {
                    foutdisp.err_naam = data.errnaam;
                    foutdisp.err_vnaam = data.errvnaam;
                    reject(foutdisp);
                  }
                  else
                  {
                      resolve(data);
                  }
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    }, 

    Bewaarzkreq(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {


            let foutdisp = {err_naam:'', error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.profile, '','');
            foutdisp.err_naam = Controle.boodschap;
            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let insql = (Sql == 'UPD') ? 'UZkreq' : 'IZkreq';
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function='+insql,
                data: JSON.stringify(hdata),
                success: function(data){
                  if (data.error) 
                  {
                    foutdisp.err_naam = data.errnaam;
                    foutdisp.err_vnaam = data.errvnaam;
                    reject(foutdisp);
                  }
                  else
                  {
                      resolve(data);
                  }
                },
                error: function(data){
                    reject({error:'Aanpassingen niet doorgevoerd'});
                }
               });
            });
    }, 

       
};


