'use strict';
import React from 'react';
import ReactDOM from 'react-dom';
import {Motion, StaggeredMotion, spring} from 'react-motion';
import range from 'lodash.range';
const RaisedButton = require('./raised-button.jsx');

// Components 



//Constants 

// Diameter of the main button in pixels
const MAIN_BUTTON_DIAM = 45;
const CHILD_BUTTON_DIAM = 24;
// The number of child buttons that fly out from the main button
const NUM_CHILDREN = 5;
// Hard code the position values of the mainButton
const M_X = 490;
const M_Y = 450;

//should be between 0 and 0.5 (its maximum value is difference between scale in finalChildButtonStyles a
// nd initialChildButtonStyles)
const OFFSET = 0.4;

const SPRING_CONFIG = [400, 28];

// How far away from the main button does the child buttons go
const FLY_OUT_RADIUS = 80,
  SEPARATION_ANGLE = 40, //degrees
  FAN_ANGLE = (NUM_CHILDREN - 1) * SEPARATION_ANGLE, //degrees
  BASE_ANGLE = ((180 - FAN_ANGLE)/2); // degrees

// Names of icons for each button retreived from fontAwesome, we'll add a little extra just in case 
// the NUM_CHILDREN is changed to a bigger value
let childButtonIcons = ['pencil', 'at', 'camera', 'bell', 'comment', 'bolt', 'ban', 'code'];


// Utility functions

function toRadians(degrees) {
  return degrees * 0.0174533;
}



class IconRotate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenRot: false,
      childButtons: []
    };

    // Bind this to the functions
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  finalChildDeltaPositions(index) {
    let FLY_OUT_RADIUS = 80;
    let SEPARATION_ANGLE = 40; 
    let FAN_ANGLE = (this.props.NUM_CHILDREN - 1) * SEPARATION_ANGLE;
    let BASE_ANGLE = ((180 - FAN_ANGLE)/2); // degrees

  let angle = BASE_ANGLE + (index* SEPARATION_ANGLE);
  return {
    deltaX: this.props.ver * Math.cos(toRadians(angle)) - (this.props.childbgroot/2),
    deltaY: this.props.ver * Math.sin(toRadians(angle)) + (this.props.childbgroot/2)
  };
}

  componentDidMount() {
    window.addEventListener('click', this.closeMenu);
    let childButtons = [];

    this.setState({childButtons: childButtons.slice(0)});
  }

  mainButtonStyles() {
    return {
      width: this.props.mainbgroot,
      height: this.props.mainbgroot,
      top: this.props.top - (this.props.mainbgroot/2),
      left: this.props.left - (this.props.mainbgroot/2),
      scale: spring(0.5, SPRING_CONFIG),
      position: 'absolute',
      borderRadius: '100%',
      backgroundColor: '#68AEF0',
      cursor: 'pointer',
      display: 'flex',
      justifyContent : 'center',
      alignItems: 'center',
      color: '#ffffff',
      fontWeight: 'lighter',
      border: '1px solid rgba(0, 0, 0, 0.1)',
    };
  }

  initialChildButtonStyles() {
    return {
      width: this.props.childbgroot,
      height: this.props.childbgroot,
      top: spring((this.props.childbgroot/2), SPRING_CONFIG),
      left: spring((this.props.childbgroot/2), SPRING_CONFIG),
      rotate: spring(-180, SPRING_CONFIG),
      scale: spring(0.5, SPRING_CONFIG),
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
      backgroundColor: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 3px',
      color: '#8898A5',
    };
  }

  finalChildButtonStyles(childIndex) {
    let {deltaX, deltaY} = this.finalChildDeltaPositions(childIndex);
    return {
      width: this.props.childbgroot,
      height: this.props.childbgroot,
      top: spring(deltaY, SPRING_CONFIG),
      left: spring(deltaX, SPRING_CONFIG),
      rotate: spring(0, SPRING_CONFIG),
      scale: spring(1, SPRING_CONFIG),
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
      backgroundColor: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 3px',
      color: '#8898A5',

    };
  }

  toggleMenu(e) {
    e.stopPropagation();
    let{isOpenRot} = this.state;
    this.setState({
      isOpenRot: !isOpenRot
    });
  }

  closeMenu() {
    this.setState({ isOpenRot: false});
  }

  renderChildButtons() {
    const {isOpenRot} = this.state;
    const targetButtonStyles = range(this.props.NUM_CHILDREN).map(i => {
      return isOpenRot ? this.finalChildButtonStyles(i) : this.initialChildButtonStyles();
    });

    const scaleMin = this.initialChildButtonStyles().scale.val;
    const scaleMax = this.finalChildButtonStyles(0).scale.val;

    //This function returns target styles for each child button in current animation frame
    //according to actual styles in previous animation frame.
    //Each button could have one of two target styles
    // - defined in initialChildButtonStyles (for collapsed buttons)
    // - defined in finalChildButtonStyles (for expanded buttons)
    // To decide which target style should be applied function uses css 'scale' property
    // for previous button in previous animation frame.
    // When 'scale' for previous button passes some 'border' which is a simple combination one of
    // two 'scale' values and some OFFSET the target style for next button should be changed.
    //
    // For example let's set the OFFSET for 0.3 - it this case border's value for closed buttons will be 0.8.
    //
    // All buttons are closed
    //                INITIAL-BUTTON-SCALE-(0.5)-----------BORDER-(0.8)------FINAL-BUTTON-SCALE-(1)
    //                |------------------------------------------|--------------------------------|
    // BUTTON NO 1    o------------------------------------------|---------------------------------
    // BUTTON NO 2    o------------------------------------------|---------------------------------
    //
    // When user clicks on menu button no 1 changes its target style according to finalChildButtonStyles method
    // and starts growing up. In this frame this button doesn't pass the border so target style for button no 2
    // stays as it was in previous animation frame
    // BUTTON NO 1    -----------------------------------o-------|---------------------------------
    // BUTTON NO 2    o------------------------------------------|---------------------------------
    //
    //
    //
    // (...few frames later)
    // In previous frame button no 1 passes the border so target style for button no 2 could be changed.
    // BUTTON NO 1    -------------------------------------------|-o-------------------------------
    // BUTTON NO 2    -----o-------------------------------------|---------------------------------
    //
    //
    // All buttons are expanded - in this case border value is 0.7 (OFFSET = 0.3)
    //                INITIAL-BUTTON-SCALE-(0.5)---BORDER-(0.7)--------------FINAL-BUTTON-SCALE-(1)
    //                |------------------------------|--------------------------------------------|
    // BUTTON NO 1    -------------------------------|--------------------------------------------O
    // BUTTON NO 2    -------------------------------|--------------------------------------------O
    //
    // When user clicks on menu button no 1 changes its target style according to initialChildButtonStyles method
    // and starts shrinking down. In this frame this button doesn't pass the border so target style for button no 2
    // stays as it was defined in finalChildButtonStyles method
    // BUTTON NO 1    -------------------------------|------------------------------------O--------
    // BUTTON NO 2    -------------------------------|--------------------------------------------O
    //
    //
    //
    // (...few frames later)
    // In previous frame button no 1 passes the border so target style for button no 2 could be changed
    // and this button starts to animate to its default state.
    // BUTTON NO 1    -----------------------------o-|---------------------------------------------
    // BUTTON NO 2    -------------------------------|------------------------------------O--------
    let calculateStylesForNextFrame = prevFrameStyles => {
      prevFrameStyles = isOpenRot ? prevFrameStyles : prevFrameStyles.reverse();

      let nextFrameTargetStyles =  prevFrameStyles.map((buttonStyleInPreviousFrame, i) => {
        //animation always starts from first button
        if (i === 0) {
          return targetButtonStyles[i];
        }

        const prevButtonScale = prevFrameStyles[i - 1].scale;
        const shouldApplyTargetStyle = () => {
          if (isOpenRot) {
            return prevButtonScale >= scaleMin + OFFSET;
          } else {
            return prevButtonScale <= scaleMax - OFFSET;
          }
        };

        return shouldApplyTargetStyle() ? targetButtonStyles[i] : buttonStyleInPreviousFrame;
      });

      return isOpenRot ? nextFrameTargetStyles : nextFrameTargetStyles.reverse();
    };

    return (
      <StaggeredMotion
        defaultStyles={targetButtonStyles}
        styles={calculateStylesForNextFrame}>
        {interpolatedStyles =>
          <div style={{position: 'relative', display: 'inline-flex'}}>
            {interpolatedStyles.map(({height, left, rotate, scale, top, width}, index) =>
              <div
                className="child-button"
                key={index}
                style={{
                  left,
                  height,
                  top,
                  transform: `rotate(${rotate}deg) scale(${scale})`,
                  width
                }}
               onClick={this.props.ArrFunc[index]} 
              >
                <img src={this.props.ArrAfb[index]} onClick={this.props.ArrFunc[index]} style={{height: '15px',width: '15px', display: '-webkit-box', cursor:'pointer' ,fontSize:'small'}}/>
              </div>
            )}
          </div>
        }
      </StaggeredMotion>
    );
  }




  render() {
    let {isOpenRot} = this.state;
    let mainButtonRotation = isOpenRot ? {rotate: spring(-135, [500, 30])} : {rotate: spring(0, [500, 30])};
    let lbdisplay = isOpenRot ? 'none':'initial';
    let transf = isOpenRot ? 'scale(0.6)' : 'scale(1.0)'
    let labletxt = isOpenRot ? (<p style={{display:'table-row-group'}}><br/><span></span></p>) : this.props.labl1

    return (
      <div  style={{display:'inline-block', marginLeft: '42px'}}>
        {this.renderChildButtons()}
        <Motion style={mainButtonRotation}>
          {({rotate}) =>
          <RaisedButton label={labletxt}
            labelStyle={{fontSize:'x-small', color:'#333', fontWeight:'normal',textTransform:'initial',lineHeight:'12px',bottom:'10px',textAlign:'center',height:'30px',position:'relative'}}
            imgStyle={{height: '30px',width: '30px', left:'12px', top:'15px', display: '-webkit-box', marginBottom: '30px',fontSize:'small',transform: transf}}
            style={{display:'table-caption', right: '42px', bottom: '15px', minWidth: '88px', position:'relative',height:''}}
            image={this.props.Afb}
            backgroundColor='rgb(241, 241, 241)'
            onKlik={this.toggleMenu}
            hoverColor='rgb(42, 138, 212)'
            diep={0}/>          }
        </Motion>
      </div>
    );
  }
};


module.exports = IconRotate;
