const React = require('react');
const ReactDOM = require('react-dom');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const TextField = require('../matui/text-field.jsx');
const Checkbox = require('../matui/checkbox.jsx');
const IconButton = require('../matui/icon-button.jsx');
const IconButOver = require('../matui/icon_but_over.jsx');
const FontIcon = require('../matui/font-icon.jsx');

const AlgFuncUpd = require('../functions/algemeen');
var ReactTags = require('react-tag-input').WithContext;
const Mattag = require('../matui/mattag.jsx');
const Taglist = require('../matui/lists/taglist.jsx');
const SubLijst = require('./sublijstnw.jsx');
var Button = require("react-bootstrap").Button;
const FlatButton = require('../matui/flat-button.jsx');

const DateTime = require('../matui/utils/date-time');
const Snackbar = require('../matui/snackbar.jsx');
var Panel = require("react-bootstrap").Panel;
var PanelGroup = require("react-bootstrap").PanelGroup;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Nav = require("react-bootstrap").Nav;
var NavItem = require("react-bootstrap").NavItem;
const Contstafunc = require('./cont_func.jsx');
var Image = require("react-bootstrap").Image;
var MenuItem = require("react-bootstrap").MenuItem;
const Actpopup = require('./popupfixtdefcons.jsx');
const PopupCons = require('./popupcons.jsx');
const Detpopup = require('./detpopup.jsx');
const ClsAct = require('./cls_activ.jsx');
const ClsCons = require('./cls_consultant.jsx');
const PopupFix = require('./popdefconstexture.jsx');
const ClsFixt = require('./cls_fixtures.jsx');
const PopupLib = require('./popdeflibrary.jsx');
const Libvorm = require('./libraryvorm.jsx');
const Lampvorm = require('./lampvorm.jsx');
const PopupLamp = require('./popdefadres.jsx');
const Virtvorm = require('./schemamainvorm.jsx');
const PopupSchema = require('./popdefschema.jsx');
const Chanvorm = require('./schemavorm.jsx');
const PopupChan = require('./popdeftexture.jsx');

let Consvormnew = React.createClass({
  mixins: [PureRenderMixin],
  contextTypes:{
    router: React.PropTypes.object    
  },
  getInitialState() {
    return {
      listroute:'GetAllLibrary',
      seltxtcol:0,
      txtdata:[],      
      dispmeer:'none',
      popuplib:null,
      libvorm:null,
      popupfix:null,
      popupgraf:null,
      popupact:null,
      popupcons:null,
      popupbijl:null,
      popupmogkand:null,
      popupfincons:null,
      popupfinconsfree:null,
      popupconsfinmail:null,
      popupconscontract:null,
      popupconsdimona:null,
      popupreferentie:null,
      popupconskennismaak:null,
      popupproject:null,
      filename:'',
      bestandnaam:'',
      contcons:Immutable.List(),
      txtzien:(<div style={{marginTop: 'auto',marginBottom: 'auto', display: 'inline-block'}}><span style={{fontSize:'10px',fontWeight: 'normal',color: '#2fa5c7'}} >Meer zien</span>
                <FontIcon
                  className="fa fa-sort-desc"
                  style={{color:'#2fa5c7', iconHoverColor:'darkslateblue',fontSize:'15px',paddingLeft: '5px',bottom:'2px'}}>
                </FontIcon> </div>),
      tagpadtop:'0px',
      jaar:0,
      seldatagenda:[],
      toevken:false,
      tit_verlof:"Verlof (0 - 0) (0)",
      tit_adv:"Kompensatie (0 - 0) (0)",
      tit_feest:"Feestdagen (0 - 0) (0)",
      tit_vorig:"Verlof vorig jaar (0 - 0) (0)",
      expa:false,      
      ConsId:'',
      vorm:'CONS',
      paramdata: Contstafunc.Cons_param(),
      data: Contstafunc.Dat_cons(),
      nwfoto:this.props.nwfoto,
      sqldata: {param: 0},   
      edit:0,
      activkey:1,
      tags: [ {id: 1, text: "Apples"} ],   
      tagkey:0,
      multitoevoeg:false,
      tagtoevoeg:false,
      ListData:[],
      MailListData:[],
      err_naam:'',
      updatecol:'',
      updatetxt:'', 
      data: {},      
      naam:'',
      route: '',
      searchkey: 'id',
      height: (window.innerHeight).toString(),
      data:{id:0},
      HListData:[],
      dgcolumns:[],      
      colFilter: [],
      immuData:Immutable.List(),
      contcons:Immutable.List(),
    };
  },
  componentWillMount() {
    let nw_date = new Date();
    this.state.jaar = nw_date.getFullYear();
  },
  componentDidMount() {
    this.setState({activkey:1})
  },
  render() {
    // Algemeen voor render
    let tot_zichtpop = null;
    let tot_zicht1 = null;
    let tot_zicht2 = null;
    let brgrrechts = (this.props.brgrrechts) ? (this.props.brgrrechts * .8) :296;

    // Definieren van de headers
    var w = window.innerWidth - 5;
    var h = window.innerHeight - 300;
    let beeld = screen.width;
    let beeldh = screen.height;
    var winbreed = window.innerWidth;

    let gridbreed = (winbreed > 1850) ? ((winbreed / 100) * 95) -800 : ((winbreed / 100) * 95) -500;   
    if (winbreed > 1850 && gridbreed > 900) {gridbreed = 900}
    if (winbreed < 1850 && gridbreed > 2000) {gridbreed = 2000}
    if (gridbreed < 1000) {gridbreed = 1000}

    let icdel = (this.state.seltxtcol > 0) ? (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                  iconClassName="fa fa-trash-o" 
                  tooltip="Delete selected fixtures" 
                  onClick={this.DeleteClick}/>) : null;


    let PTitact = (App.ConsId.length > 3 && this.state.activkey == 1) ? (<h3 className="panel-title">
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        tooltip="New Library"
                        iconClassName="fa fa-plus" 
                        onClick={this.NewLib}/>
                        {icdel}
                        </h3>) :
                        (App.ConsId.length > 3 && this.state.activkey == 2) ? (<h3 className="panel-title">
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        tooltip="New Virtual Controller"
                        iconClassName="fa fa-plus" 
                        onClick={this.NewVirt}/>
                        {icdel}
                        </h3>) :
                        (App.ConsId.length > 3 && this.state.activkey == 3) ? (<h3 className="panel-title">
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        tooltip="New Fixture"
                        iconClassName="fa fa-plus" 
                        onClick={this.NewLamp}/>
                        {icdel}
                        </h3>) :
                        (App.ConsId.length > 3 && this.state.activkey == 4) ? (<h3 className="panel-title">
                      <IconButOver                       
                        style={{display:'block', float:'right', marginLeft: '.5em', top:'-25px',fontSize:'80%'}}
                        tooltip="New Channel"
                        iconClassName="fa fa-plus" 
                        onClick={this.NewChan}/>
                        {icdel}
                        </h3>) : null;

    let lst_lib = (App.ConsId.length > 3 && this.state.activkey == 1) ? (<SubLijst ref="ActLijst" route='GetAllLibrary' hdata={{consid:App.ConsId}} height={(window.innerHeight) - 300} mhoog={250} breed={gridbreed} hoog={(window.innerHeight) - 300} sleutel={App.ConsId} behandel={false} zoeken={false} klik={this.ClickLib} />) : null;
    let lst_schem = (App.ConsId.length > 3 && this.state.activkey == 2) ? (<SubLijst ref="ActLijst" route='GetAllSchemas' hdata={{consid:App.ConsId}} height={(window.innerHeight) - 300} mhoog={250} breed={gridbreed} hoog={(window.innerHeight) - 300} sleutel={App.ConsId} behandel={false} zoeken={false} klik={this.ClickVirt} />) : null;
    let lst_lamp = (App.ConsId.length > 3 && this.state.activkey == 3) ? (<SubLijst ref="ActLijst" route='GetAllLampen' hdata={{consid:App.ConsId}} height={(window.innerHeight) - 300} mhoog={250} breed={gridbreed} hoog={(window.innerHeight) - 300} sleutel={App.ConsId} behandel={false} zoeken={false} klik={this.ClickLamp} />) : null;
    let lst_fixt = (App.ConsId.length > 3 && this.state.activkey == 4) ? (<SubLijst ref="ActLijst" route='GetAllFixtures' hdata={{consid:App.ConsId}} height={(window.innerHeight) - 300} mhoog={250} breed={gridbreed} hoog={(window.innerHeight) - 300} sleutel={App.ConsId} behandel={false} zoeken={false} klik={this.ClickChan}/>) : null;


    // einde definietie lijsten

    // definitie velden
    let kfoto = (this.state.edit == 0 ) ? this.props.nwfoto : this.state.nwfoto;
    let geg1 = (<p dir="ltr" style={{fontSize:'large',fontWeight: 'normal', color: 'rgb(47, 165, 199)'}}>{this.props.data.vld1}</p>);         
    // Adres consultant
    let mailref = "mailto:"+this.props.data.E_mail;    
    let hmobgsm = '';
    let hmobphone = '';
    if (this.props.data.Mobile_phone)
    {
      hmobgsm = this.props.data.Mobile_phone.replace('(0)','');
      hmobgsm = hmobgsm.replace('(','');
      hmobgsm = hmobgsm.replace(')','');
      hmobgsm = hmobgsm.replace('/','');
      hmobgsm = hmobgsm.replace('-','');
      hmobgsm = hmobgsm.replace('.','');
      hmobgsm = hmobgsm.replace(' ','');
      hmobgsm = "tel:"+hmobgsm;    
    }
    if (this.props.data.main_phone)
    {
      hmobphone = this.props.data.main_phone.replace('(0)','');
      hmobphone = hmobphone.replace('(','');
      hmobphone = hmobphone.replace(')','');
      hmobphone = hmobphone.replace('/','');
      hmobphone = hmobphone.replace('-','');
      hmobphone = hmobphone.replace('.','');
      hmobphone = hmobphone.replace(' ','');
      hmobphone = "tel:"+hmobphone;
    }
    let geg5 = (this.props.data.E_mail && beeld < 750) ? 
        (<p dir="ltr" style={{fontSize:'smaller',fontWeight: 'normal',lineHeight: '17px', paddingTop: '5px', textAlign: 'center'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Email: </span> <a href={mailref}>{this.props.data.E_mail}</a></p>) :
        (this.props.data.main_phone && beeld > 749) ? 
        (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'normal'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Email: </span> <a href={mailref}>{this.props.data.E_mail}</a></p>) : null;
    let geg6 = (this.props.data.main_phone && beeld < 750) ? 
        (<p dir="ltr" style={{fontSize:'smaller',fontWeight: 'normal',lineHeight: '17px', paddingTop: '5px', textAlign: 'center'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Phone: </span> <a href={hmobphone}>{this.props.data.main_phone}</a></p>) :
        (this.props.data.main_phone && beeld > 749) ? 
        (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'normal'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Phone: </span> {this.props.data.main_phone}</p>) : null;
    let geg7 = (this.props.data.Mobile_phone && beeld < 750) ? 
        (<p dir="ltr" style={{fontSize:'smaller',fontWeight: 'normal',lineHeight: '17px', paddingTop: '5px', textAlign: 'center'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Mobile: </span> <a href={hmobgsm}>{this.props.data.Mobile_phone}</a></p>) :
        (this.props.data.Mobile_phone && beeld > 749) ? 
        (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'normal'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Mobile: </span> {this.props.data.Mobile_phone}</p>) : null ;
    let geg8 = (this.props.data.street) ? 
        (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'normal'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>Adress: </span> {this.props.data.street}</p>) : null;
    let geg9 = (this.props.data.zipcode_txt) ? 
        (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'normal'}}><span style={{color: '#2fa5c7', fontWeight: 'normal'}}>City: </span> {this.props.data.zipcode_txt}</p>) : null;

    let vld_ad= (this.props.data.actief == 1) ? (<p dir="ltr" style={{fontSize:'100%',fontWeight: 'bold'}}><span style={{color: 'lightgreen', fontWeight: 'bold'}}>AKTIEF</span> </p>) : null;
    let iccont = null;
    let icdim = null;
    let icgraf = null;

    // einde def velden
    // definietie pop ups
    // einde def pop ups

    let navigatie = (
        <Nav bsStyle="pills" activeKey={this.state.activkey} onSelect={this.handleSelect}>
            <NavItem eventKey={1} title="Item">Libraries</NavItem>
            <NavItem eventKey={2} title="Item">Virtual Controllers</NavItem>
            <NavItem eventKey={3} title="Item">Fixtures</NavItem>
            <NavItem eventKey={4} title="Item">Channels</NavItem>
        </Nav>);

    let hfdpanFooter =  (<div style={{height: '40px'}}>
                          {navigatie}
                          <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-35px',fontSize:'80%'}}
                            iconClassName="fa fa-pencil-square-o" 
                            tooltip="Edit data" 
                            onClick={this.EditClick}/>
                        </div>) 
    // Gegevens consultant
    // kennis
      // bovenste paneel
    let PTitsocial = null;
    let PTitedit = (this.state.edit == 0) ? 
                    (<h3 className="panel-title">
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                        <IconButOver                       
                          style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                          iconClassName="fa fa-pencil-square-o" 
                          tooltip="Edit Data" 
                          onClick={this.EditClick}/>
                      </Col></h3>) : null;
    let pan2deel1 = (<div style={{marginBottom: '0', display: '-webkit-flex', display: '-ms-flexbox',display: 'flex', margin: '12px 0', marginLeft: '0', marginRight: '0', padding: '0', wordWrap: 'break-word',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 0px 0px rgba(0,0,0,.2)', backgroundColor: '#fff',borderRadius:'2px', position: 'relative', listStyle: 'none'}}>
                        <div style={{border: '0', textDecoration: 'none', MsTouchAction: 'manipulation', touchAction: 'manipulation', padding: '8px 12px 12px', WebkitFlex: '1 1 0',MsFlex: '1 1 0px', flex: '1 1 0',
                             fontWeight: '700',backgroundColor: 'transparent', color: '#0084bf', margin: '0',fontSize: '100%', background: '0 0', verticalAlign: 'baseline'}}>
                          {geg5}
                          {geg6}
                          {geg7}
                          {geg8}
                          {geg9}
                          {vld_ad}
                        </div>
                        </div>);
    let pan2 = (
                  <div id='cns_hfdpan' style={{marginBottom: '16px', marginTop:'70px', padding: '24px', wordWrap: 'break-word', boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2)', display: 'block', backgroundColor: '#fff', borderRadius: '2px',position: 'relative', listStyle: 'none', outline: '0', fontSize: '100%', verticalAlign: 'baseline', boxSizing: 'inherit',}}>
                     <div id='cns_hfdpan1' style={{margin:'0',padding:'0',border:'0',outline:'0',fontSize:'100%',verticalAlign:'baseline',background: '0 0', boxSizing: 'inherit', display: 'block'}}>
                       <div id='card_header1' style={{margin: '-85px auto 0', position: 'relative', width: 'auto'}}>
                          <div id='photo_header1' style={{backgroundColor:'rgba(47, 165, 199, 0.85)',  textAlign: 'center', border: '4px solid #fff', boxSizing: 'content-box', margin: 'auto', position: 'relative', boxShadow: 'inset 0 1.5px 3px 0 rgba(0,0,0,.15), 0 1.5px 3px 0 rgba(0,0,0,.15)',marginTop:'-65px',width:'128px',height:'128px',borderRadius:'50%'}}>
                            <div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox', display: 'flex', WebkitFlexDirection: 'column', marginTop:'10px',
                                    MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '88px', width: '88px', borderRadius: '50%'}}>
                              <div dir="ltr" style={{fontSize: '14px',fontWeight: '400',lineHeight: '16px;margin-bottom: 2px',color: 'white',width: '128px',height: 'auto', borderRadius: '50%', marginTop: '20px'}}>{this.props.data.vld1}
                              </div>
                            </div>
                          </div>
                          <div id='card_header1' style={{left: '24px', position: 'absolute', top: '75px'}}>
                            {PTitsocial}
                          </div>
 
                          <div id='carbody1' style={{textAlign:'center',position: 'relative',marginBottom:'30px'}}>
                            <div style={{padding: '0 40px',marginBottom:'0'}}>
                              {pan2deel1}
                             </div>
                          </div>
                          <div id='card_header1' style={{right: '24px', position: 'absolute', top: '75px'}}>
                            {PTitedit}
                          </div>
                       </div>
                     </div>
                  </div>)
    // einde bovenste paneel
    // einde enkel voor mobile
    let vldpanact = (
          <Panel  header={PTitact} eventKey="14act"  bsStyle="grblok" style={{background: '#fff'}}>
            <Row className="show-grid">
              <Col xs={12} md={12} sm={12} >
                {lst_lib}
                {lst_schem}
                {lst_lamp}
                {lst_fixt}
              </Col>
            </Row>
          </Panel>);

    let panken = (<div id='cns_hfdpan2' style={{marginTop:'16px',padding:'0',border:'0',outline:'0',fontSize:'100%',verticalAlign:'baseline',background: '0 0', boxSizing: 'inherit', display: 'block',position:'relative'}}>
                       <div style={{WebkitAnimationName:'fade-in',animationName: 'ade-in', WebkitAnimationDuration:'.5s',animationDuration: '.5s', WebkitAnimationIterationCount:'1', 
                            animationIterationCount: '1',WebkitAnimationTimingFunction: 'cubic-bezier(0,0,.2,1)', AnimationTimingFunction: 'cubic-bezier(0,0,.2,1)', WebkitAnimationFillMode: 'forwards', animationFillMode: 'forwards'}}>
                          <section style={{marginBottom:'0',backgroundColor:'white',position: 'relative',zIndex: '0', margin: '16px 0', padding: '24px', wordWrap: 'break-word', boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2)',
                          display: 'block', borderRadius: '2px', listStyle: 'none', fontSize:'11px',overflow:'hidden'}}>
                            {navigatie}
                            {vldpanact}
                          </section>
                       </div>
                     </div>);
    tot_zicht2 = (App.ConsId == 0  || App.ConsId.length > 4 && beeld > 749) ? (
      <div className='rowlist' style={{marginLeft:'15px', marginBottom: '60px', marginRight: '10px'}}>
        {pan2}
        {panken}
      </div>) : null;    

    tot_zichtpop = (<div>
            {this.state.popupact}
            {this.state.popuplib}
            {this.state.popupcons}
            {this.state.popupfix}
            </div>);
    
    // Einde opbouw 

    return (<div id='consvdiv' style={{display:'block'}}>
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol, bottom:'60px'}}
          message={this.state.updatetxt}/>
        {tot_zichtpop}
        {tot_zicht2}
      </div>)
  },  
  CtrlKlik(data) {
    var index, column;    
    let arra;
    // controleren of arra && of || bevat
    let hudata = data;
    let ColFilter = ['checked'];
    let value = '2';
    var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = eval('row.'+filCol);            
          }
          else
          {
            arra = ''; 
          }
          if (typeof arra === 'string' || arra instanceof String)
          {

          }
          else
          {
            arra='';
          }
          if(arra.indexOf(value) >= 0)
          {
            return arra.indexOf(value) >= 0
          }
        }
      }) : data;
      this.setState({seltxtcol:filteredRows.length});
      this.setState({txtdata:data});
  },

  DeleteClick() {
    let hudata = {data:this.state.txtdata}
    ClsFixt.DelFixtureCons(hudata)
    .then(data => {
      this.setState({seltxtcol:0});
      if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllFixturesCons', {id:App.ConsId});}   
    })
    .catch(data => {
        return true;
    });
  },

  NewFix(){
    let hudata = {
        id:'0',
        name:'',
        accountname:'',
        accountid:'',
        consid:App.ConsId,
        mihigh:'0',
        mahigh:'0',
        stphigh:'0',
        milow:'0',
        malow:'0',
        stplow:'0',
        statextnumb:'0',
        stptextnumb:'0',
        textchar:''};
    this.setState({popupfix:(<PopupFix  id='popupact1' data={hudata} route = 'activiteit' zindex={999999} Sluiten={this.sluitfixpopup}  ></PopupFix>)})
  },
  sluitfixpopup(data) {
    this.setState({popupfix:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllFixturesCons', {consid:App.ConsId});}   
  },

  NewLib(){
   let hdata = {
        id:'0',
        name:'',
        consid:App.ConsId};    
    this.setState({popuplib:(<PopupLib  id='popupact1' data={hdata} route = 'activiteit' zindex={999999} Sluiten={this.sluitlibpopup}  ></PopupLib>)})
  },
  NewLamp() {
    let hdata = {id:'0',name:'',accountid:0,accountname:'',vischan:0,unvischan:0,ClampId:0,schemid:0,adresid:0,consid:App.ConsId};
    this.setState({popuplib:(<PopupLamp  id='popupact1' data={hdata} route = 'activiteit' zindex={999999} Sluiten={this.sluitlamppopup}  ></PopupLamp>)})
    return;
  },
  NewVirt(){
   let hdata = {
        id:'0',
        name:'',
        consid:App.ConsId};    
    this.setState({popuplib:(<PopupSchema  id='popupact1' data={hdata} route = 'activiteit' zindex={999999} Sluiten={this.sluitvirtpopup}  ></PopupSchema>)})
  },

  NewChan(){
    let hdata = {
        id:'0',
        name:'',
        consid:App.ConsId,
        accountname:'',
        accountid:'',
        mihigh:'0',
        mahigh:'0',
        stphigh:'0',
        milow:'0',
        malow:'0',
        stplow:'0',
        statextnumb:'0',
        stptextnumb:'0',
        textchar:''};
    this.setState({popuplib:(<PopupChan  id='popupact1' data={hdata} route = 'activiteit' zindex={999999} Sluiten={this.sluitchanpopup}  ></PopupChan>)})
  },

  sluitlamppopup(data) {
    this.setState({popuplib:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllLampen', {consid:App.ConsId});}   
  },

  sluitlibpopup(data) {
    this.setState({popuplib:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllLibrary', {consid:App.ConsId});}   
  },

  KlikAct(waarde, data) {
    this.setState({laden:1});
    this.setState({popupact:(<Actpopup  id='popupact1' data={data} route = 'activiteit' zindex={999999} Sluiten={this.sluitactpopup} ></Actpopup>)})
  },    

  ClickLib(waarde, data) {
    this.setState({popuplib:(<Libvorm  id='popuplib1' popup={true} data={data} zindex={999999} Sluiten={this.sluitlibpopup}  ></Libvorm>)})
  },

  sluitlibpopup(data) {
    this.setState({popuplib:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllLibrary', {consid:App.ConsId});}   
  },

  ClickLamp(waarde, data) {
    this.setState({popuplib:(<Lampvorm  id='popuplib1' popup={true} data={data} zindex={999999} Sluiten={this.sluitlamppopup}  ></Lampvorm>)})
  },

  sluitlamppopup(data) {
    this.setState({popuplib:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllLampen', {consid:App.ConsId});}   
  },

  ClickVirt(waarde, data) {
    this.setState({popuplib:(<Virtvorm  id='popuplib1' popup={true} data={data} zindex={999999} Sluiten={this.sluitvirtpopup}  ></Virtvorm>)})
  },

  sluitvirtpopup(data) {
    this.setState({popuplib:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllSchemas', {consid:App.ConsId});}   
  },

  ClickChan(waarde, data) {
    this.setState({popuplib:(<Chanvorm  id='popuplib1' popup={true} data={data} zindex={999999} Sluiten={this.sluitchanpopup}  ></Chanvorm>)})
  },

  sluitchanpopup(data) {
    this.setState({popuplib:null})    
    if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllFixtures', {consid:App.ConsId});}   
  },

  


  // Algemene funkties
  zetedit(skey){
    if (this.isMounted)
    {
      if (App.master.state.AppHfdMenKeuze)
      {
        if (App.master.state.AppHfdMenKeuze.FiltData) {   
          skey = App.master.state.AppHfdMenKeuze.FiltData[0].id;
        }
        App.master.setState({AppHfdMenKeuze:null});
      }      
      this.state.activkey = 0;
      this.setState({edit:0});
      this.setState({ConsId:App.ConsId});
      this.setState({activkey:1});
      if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllLibrary', {consid:App.ConsId});}   

    }
  },
  handleSelect(selectedKey) {
    this.setState({activkey:selectedKey});
  },
  // einde Algemene funkties
  editsluiten(data) {
    this.setState({popupcons:null});
    if (this.props.loadDataNw && data) {this.props.loadDataNw(data,'CONS',this.state.activkey) }
  },
  // Einde funkties popup sluiten

  // Openen popup vensters
  EditClick(selectedKey) {
    let hdata = {id:App.ConsId}; 
    this.setState({popupcons:(<PopupCons  id='popupcons' data={hdata} zindex={999999} Sluiten={this.editsluiten}></PopupCons>)})
    return;
  },
  // functies activiteiten
  sluitactpopup(data) {
    this.setState({popupact:null})    
    if (data) {if (this.refs.ActLijst) {this.refs.ActLijst.loadNwData(App.ConsId,'GetAllFixturesCons', {id:App.ConsId});}}   
  },
  
  KlikAct(waarde, data) {
    this.setState({laden:1});
    this.setState({popupact:(<Actpopup  id='popupact1' data={data} route = 'activiteit' zindex={999999} Sluiten={this.sluitactpopup} ></Actpopup>)})
  },    

  NewActivity(type) {
    var hudata = {
        type:type,
        request_id:'',
        reqname:'',
        accountid:this.props.data.parent_account,
        accountname:this.props.data.account_name,
        contactid:'',
        contname:'',
        cons_id:App.ConsId,
        consname:this.props.data.vld1,
        origin:'CONS',        
        Origin:'CONS',}
    let hdata = ClsAct.NewActiv(type, hudata);
    this.setState({popupact:(<Actpopup  id='popupact1' data={hdata} route = 'activiteit' zindex={999999} Sluiten={this.sluitactpopup}  ></Actpopup>)})
  },
  zetconsgeg(data) {
    this.setState({tagkey:0});
    this.state.actief = data.actief;
    this.setState({actief:data.actief});
    if (data.actief == '1' && this.refs.Chk_ad)
    {
      this.refs.Chk_ad.setChecked(true);
    }
    else
    {
      if (this.refs.Chk_ad)
      {
        this.refs.Chk_ad.setChecked(false);
      }
    }
  },
  Toevoegen(contdata) {
    let hdata = {id:0}; 
    this.setState({popupcons:(<PopupCons  id='popupcons' ata={hdata} zindex={999999} Sluiten={this.editsluiten}></PopupCons>)})
    return;
  }
});

module.exports = Consvormnew;






