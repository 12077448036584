/** In this file, we create a React component which incorporates components provided by material-ui */

const React = require('react');
const Immutable = require('./immutab/dist/immutable');
const PureRenderMixin = require('react-addons-pure-render-mixin');
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
const ThemeManager = require('./matui/styles/theme-manager');
const LightRawTheme = require('./matui/styles/raw-themes/light-raw-theme');
const Colors = require('./matui/styles/colors');
const AppCanvas = require('./matui/app-canvas.jsx');
const Toolbar = require('./matui/toolbar/toolbar.jsx');
const Snackbar = require('./matui/snackbar.jsx');
const ToolbarGroup = require('./matui/toolbar/toolbar-group.jsx');
const ToolbarSeparator = require('./matui/toolbar/toolbar-separator.jsx');
const ToolbarTitle = require('./matui/toolbar/toolbar-title.jsx');
const LeftNav = require('./matui/left-nav.jsx');
const DropDownMenu = require('./matui/drop-down-menu.jsx');
const FloatingActionButton = require('./matui/floating-action-button.jsx');
const FlatButton = require('./matui/flat-button.jsx');
const RaisedButton = require('./matui/raised-button.jsx');

const IconButton = require('./matui/icon-button.jsx');

const FontIcon = require('./matui/font-icon.jsx');
const Login = require('./pages/login.jsx');
const ChangePassw = require('./pages/changepassw.jsx');

//const MenuItem = require('./matui/menu/menu-item.jsx');
const Table = require('./matui/table/table.jsx');
const Router = require('react-router');
//const {State, History} = require('react-router');

var Navbar = require("react-bootstrap").Navbar;
var Nav = require("react-bootstrap").Nav;
var NavItem = require("react-bootstrap").NavItem;
var NavDropdown = require("react-bootstrap").NavDropdown;
var MenuItem = require("react-bootstrap").MenuItem;

const ClsAlgemeen = require('./pages/cls_algemeen.jsx');
const PopupCons = require('./pages/popupprofile.jsx');

var DragDropContext = require('react-dnd').DragDropContext;
import HTML5Backend from 'react-dnd-html5-backend';

const Main = React.createClass({
  mixins: [PureRenderMixin],
  contextTypes: {
    muiTheme: React.PropTypes.object,
    router: React.PropTypes.object

  },
  childContextTypes: {
    muiTheme: React.PropTypes.object
  },

  getInitialState () {
    return {
      balkhfd:0,
      popupcons:null,
      AppHfdMenKeuze:null,
      AppAccstatus:0,
      AppAccadrtype:0,
      AppBanken:0,
      AppZkcons:0,
      AppZkreq:0,
      AppConstype:0,
      AppConsadrtype:0,
      AppJobtitle:0,
      AppMarital:0,
      AppOwners:0,
      AppReqstatus:0,
      AppReqkandstatus:0,
      AppSalutation:0,
      AppTimestat:0,
      AppActtype:0,
      AppActstatus:0,
      AppSector:0,
      AppUnit:0,
      AppVat:0,
      overzdata:{},
      dfonts:'16px',
      connectie:0,
      updatecol:'',
      updatetxt:'', 
      act_aantal:0,
      gtk_aantal:0,
      loggedIn:false,
      balktop:'none',
      oldstatblk2:0,
      klikmen:false,
      balk1schad:'0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22)',
      balktop2:'0',
      btop2top:'0px',
      topchild:'0px',
      cursor:'auto',
      popopen:false,
      olbut1:null,
      lbut1:null,
      lbut2:null,
      lbut3:null,
      lbut4:null,
      lbut5:null,
      cbut1:null,
      cbut2:null,
      cbut3:null,
      cbut4:null,
      cbut5:null,
      rbut1:null,
      rbut2:null,
      rbut3:null,
      rbut4:null,
      rbut5:null,
      dispzkvld:'none',
      foto: "images/uxceo-128.jpg",
      primkleur:'#00bcd4',
      seckleur:'#ff4081',
      thrdkleur:'darkseagreen',
      txt_terug: 'Terug',
      opl_aantal:1,
      afw_aantal:10,
      but_terug:false,
      hfdbuttons:{
      menuzien: false,
      but_ins_vis: false,
      but_upd_vis: false,
      but_del_vis: false,
      but_terug:false,
      but_user_vis:false,
      but_taak_vis:false,
      but_verantw_vis:false,
      but_opm_vis:false,
      but_afsl_vis:false,
      but_klnw_vis:false,
      but_opl_vis:false,
      but_verlof_vis:false,
      but_logoff_vis:false,
      but_nwklacht_vis:false,
      but_nwlid_vis:false,
      but_nwactiv_vis:false},
      leftmenu:[],
      func_terug: this.func_terug,
      func_filter:this.func_filter,
      muiTheme: ThemeManager.getMuiTheme(LightRawTheme),
    };
  },

  getChildContext() {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

  componentWillMount() {

//    initApp();


  },

  componentDidMount() {
    this.state.func_newklacht = this.func_newklacht;
    this.state.func_newlid = this.func_newlid;
    this.state.func_newactiv = this.func_newactiv;
    this.state.func_filter = this.func_filter;
    this.AppAccstatus();
    this.AppAccadrtype();
    this.AppZkCons();
    this.AppConsadrtype();
    this.AppAccount();
    this.AppPostcode();
    let breed = window.innerWidth;
    if (breed < 1000) { this.setState({dfonts:'12px'}) } else
    if (breed < 1500) { this.setState({dfonts:'13px'}) } else
    if (breed < 2000) { this.setState({dfonts:'14px'}) } else
    if (breed < 2500) { this.setState({dfonts:'15px'}) } else
    if (breed < 250000) { this.setState({dfonts:'16px'}) } 

    window.addEventListener('resize', this.handleResize);
    window.onhashchange = function(e){
      var oldURL = e.oldURL; 
      var newURL = e.newURL; 
      var oldHash = oldURL.split("#")[1];
      var newHash = newURL.split("#")[1];
    };
  },     

  handleResize(){
    let breed = window.innerWidth;
    if (breed < 1000) { this.setState({dfonts:'12px'}) } else
    if (breed < 1500) { this.setState({dfonts:'13px'}) } else
    if (breed < 2000) { this.setState({dfonts:'14px'}) } else
    if (breed < 2500) { this.setState({dfonts:'15px'}) } else
    if (breed < 250000) { this.setState({dfonts:'16px'}) } 

    this.forceUpdate();
  },

  render() {
    let {
      hfdbuttons,
      primkleur,
      seckleur,
      thrdkleur,
      opl_aantal,
      afw_aantal,
      foto,
      but_terug,
      ...other } = this.state;    


    let containerStyle = {
      textAlign: 'center',
      paddingTop: '200px'
    };

    let balkStyle = {
      width: '100%', 
      position: 'static', 
      fontSize: '100%', 
      height: '30px',    
      backgroundColor: Colors.hfdkl1,  
      zIndex:'9999999',
      paddingTop:'55px',
      transition:'450ms cubic-bezier(0.23, 1, 0.32, 1); top: 0px',
      display:this.state.balktop,      
    };

    let balkStyle2 = {
      width: '100%', 
      position: 'fixed', 
      fontSize: 'small', 
      height: '50px',    
      top:'51px',
      backgroundColor: Colors.hfdkl2, 
      overflow: 'hidden', 
      zIndex:'999999',
      marginTop:this.state.btop2top,
      display:'inline-block',      
    };


    // teksten in balk 1 die als menu 
    let vld_toolbar, vld_botbar,vld_toolbar2, vld_subbalk, vld_subbalk2;

    let vld_but_menu = (App.Admin == 'GB') ? null : (
      <IconButton
        iconClassName="fa fa-bars" 
        color={Colors.blue500}
        iconStyle={{color:'white', iconHoverColor:'darkslateblue',fontSize:'small'}}
        onClick={this._onLeftIconButtonTouchTap}        
      />);

    let vld_but_logoff = (
      <IconButton
        iconClassName="fa fa-bars" 
        color={Colors.blue500}
        iconStyle={{color:'white', iconHoverColor:'darkslateblue',fontSize:'small'}}
        onClick={this._onLeftIconButtonTouchTap}        
      />      
    );

    // Menu definitie
    let submen1a0 = (<MenuItem eventKey={0.0} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>Home</MenuItem>);

    let submen1a = (<MenuItem eventKey={1.0} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>Virtual Controllers</MenuItem>);
    let submen1aa = (<MenuItem eventKey={1.3} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>Fixtures</MenuItem>);
    let submen14 = (<MenuItem eventKey={1.4} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>Libraries</MenuItem>);

    let submen1b = (<MenuItem eventKey={1.1} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>Channels</MenuItem>);
    let submen1c = (<MenuItem eventKey={1.2} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>Manufacturers</MenuItem>);
    let submen2 = (<MenuItem eventKey={2.1} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>Users</MenuItem>);
 

    let submen31 = (<MenuItem eventKey={3.1} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>My Channels</MenuItem>);
    let submen32 = (<MenuItem eventKey={3.2} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>General Channels</MenuItem>);
    let submen33 = (<MenuItem eventKey={3.3} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>My Fixtures</MenuItem>);
    let submen34 = (<MenuItem eventKey={3.4} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>General Fixtures</MenuItem>);
    let submen35 = (<MenuItem eventKey={3.5} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>General Schemas</MenuItem>);
    let submen36 = (<MenuItem eventKey={3.6} disabled={this.state.klikmen} onSelect={this.handleSelect} style={{fontSize:'12px'}}>My Schemas</MenuItem>);
 
 
    let hfdbar = (App.Admin == 'GB' && this.state.balkhfd == 1) ? (
          <Navbar id='HFD1' fixedTop={true} style={{backgroundColor:'#2fa5c7',borderColor:'#2fa5c7', zIndex:'9999999', boxShadow:this.state.balk1schad, margin: 'auto', minWidth: '1320px'}}>
            <div id='HFDLFT' style={{width:'150px'}} >
               {this.state.lbut1}
            </div>
            <div id='HFDCENT' style={{margin:'auto', float: 'right', top: '0px', left:'0px', position: 'absolute', width: '1000px'}} >
              <Nav pullRight>
                {submen1a0}
                {submen14}
                {submen1a}
                {submen1aa}
                {submen1b}
              </Nav>
            </div>
            <div id='HFDCENT' style={{margin:'auto', float: 'right', top: '0px', position: 'absolute', width: '300px', right:'50px'}} >
              <Nav pullRight>
               <IconButton
                  iconClassName="fa fa-user" 
                  tooltip="Edit my profile" 
                  tooltipPosition="bottom-center" 
                  iconStyle={{color:'white', iconHoverColor:'darkslateblue',fontSize:'small'}} 
                  onClick={this.EditClick}/> 
              </Nav>
            </div>
            <div id='NWID'>
            <Navbar.Collapse style={{color:'white'}}>
            </Navbar.Collapse>
            </div>
          </Navbar>) : 
        (this.state.loggedIn && screen.width > 749 && this.state.balkhfd == 1) ?
        (
          <Navbar id='HFD1' fixedTop={true} style={{backgroundColor:'#2fa5c7',borderColor:'#2fa5c7', zIndex:'9999999', boxShadow:this.state.balk1schad, margin: 'auto', minWidth: '1320px'}}>
            <div id='HFDLFT' style={{width:'150px'}} >
               {this.state.lbut1}
            </div>
            <div id='HFDCENT' style={{margin:'auto', float: 'right', top: '0px', left:'0px', position: 'absolute', width: '1000px'}} >
              <Nav  pullRight>
                {submen1a0}
                {submen14}
                {submen1a}
                {submen1aa}
                {submen1b}
                {submen1c}
                {submen2}
              </Nav>
            </div>
            <div id='HFDCENT' style={{margin:'auto', float: 'right', top: '0px', position: 'absolute', width: '300px', right:'50px'}} >
              <Nav pullRight>
              </Nav>
            </div>
            <div id='NWID'>
            <Navbar.Collapse style={{color:'white'}}>
            </Navbar.Collapse>
            </div>
          </Navbar>):        
          (this.state.loggedIn && screen.width < 750 && this.state.balkhfd == 1) ?
          (
          <Navbar id='HFD1' fixedTop={true} 
              style={{backgroundColor:'#2fa5c7',borderColor:'#2fa5c7', zIndex:'9999999', boxShadow:this.state.balk1schad}}>
            <Navbar.Header>
              <Navbar.Brand>
                <a onClick={(event)=>this.handleSelect(0.0)} style={{marginLeft:'10px', cursor:'pointer'}}>Apollo Assign</a>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse style={{color:'white'}}>
              <Nav>
                {submen1a}
                {submen1b}
                {submen1c}
                {submen2}
              </Nav>
            </Navbar.Collapse>
          </Navbar>):null;
    // einde menu definitie
    let blkhfdtop = (this.state.balktop2 == '0') ? '0px' : '50px';
    let hfdbar2 = (App.Admin == 'GB' ) ? null : 
        (this.state.loggedIn) ?
        (
          <Navbar fixedTop={true} style={{backgroundColor:'#eee', WebkitTransition: 'opacity .51s linear', transition:'opacity .51s linear', borderColor:'#eee', color:'red', boxShadow:'0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22)', zIndex:'999999',top:blkhfdtop,opacity:this.state.balktop2}}>
            <Nav>
              {this.state.lbut1}
            </Nav>
            <Nav pullRight>
              {this.state.rbut1}
            </Nav>
          </Navbar>):null;


    // Mobiele gedeelte
    let MobNav = (App.Mobile) ? (<LeftNav style={{zoom:'.7'}} header={hoofd} ref="leftNav" docked={false} menuItems={this.state.leftmenu} onChange={this.leftNavChange}/>) : null;
    // Opbouw leftnav
    let hoogte = '56px';
    let hoofd = (<div style={{margin: '0px',padding: '0px', width: '100%', height: hoogte,  backgroundColor: '#00bcd4', whiteSpace: 'nowrap',  display: 'table'}}>
      <h1
        style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  margin: '0', paddingTop: '0', letterSpacing: '0',
        fontSize: '24px', fontWeight: '400', color: 'rgba(255, 255, 255, 0.87)', lineHeight: '64px', WebkitBoxFlex: '1',   textAlign: 'center'}}>
        HoofdMenu
      </h1> 
      </div>);

    return (
      <div id='APPCANV' style={{ 
                        fontFamily: 'Source Sans Pro,Helvetica,Arial,sans-serif,Hiragino Kaku Gothic Pro,Meiryo,Hiragino Sans GB W3,Noto Naskh Arabic,Droid Arabic Naskh,Geeza Pro,Simplified Arabic,Noto Sans Thai,Thonburi,Dokchampa,Droid Sans Thai,Droid Sans Fallback,-apple-system, Heiti SC,Microsoft Yahei,Segoe UI',
                        margin: '0',
                        padding: '0',
                        boxSizing: 'border-box',                        
                        lineHeight: '20px',
                        fontWeight: '400',
                        color: 'rgba(0,0,0,.7)',
                        fontSize: '12px',
                        border: '0',
                        outline: '0',
                        verticalAlign: 'baseline',
                        direction: 'ltr',
                        unicodeBidi: 'isolate',
                        display: 'block',     
                        cursor:this.state.cursor,                   
                      }} onmouseover={this.muisover} onmouseout={this.muisuit}>
        <div id='hfdeug' style={{minWidth: '1320px', margin: 'auto', position:'fixed', zIndex:'99999999', padding: '0px',top: '0' }}>
          {hfdbar}
        </div>
        <iframe style={{display: 'none'}} id='iframeconoffice' name='iframeupconoffice' />
        <Login  id='cont1' ref='DialLogin' onLogin={this._onlogin} Hoofd={this}></Login>
        <ChangePassw id='contchp1' ref='DialChange' onLogin={this._onchangepasw} Hoofd={this}></ChangePassw>
        <div id='hfdchild' style={{WebkitTransition: 'margin-top 1s linear', transition:'margin-top 1s linear', marginTop:'70px', lineHeight:'100%'}}>
          {this.props.children}
          {this.state.popupcons}
          <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol, bottom:'60px'}}
          message={this.state.updatetxt}/>
        </div>
      </div>
    );
  },
  editsluiten(data) {
    this.setState({popupcons:null});
  },
  // Einde funkties popup sluiten

  // Openen popup vensters
  EditClick(selectedKey) {
    let hdata = {id:App.ConsId}; 
    this.setState({popupcons:(<PopupCons  id='popupcons' data={hdata} zindex={999999} Sluiten={this.editsluiten}></PopupCons>)})
    return;
  },
  ZetRoute(route){
    App.Route = route;
    this.context.router.push(App.Route,null);    

  },

  handleSelect(selectedKey) {
    let balk2zien = 0;
    let oudroute = App.Route;

    switch (selectedKey) {
        case 0.0:
          App.Route = '/homemen';
          balk2zien = 1;
          this.lstconsroute();
          break;
        case 1.0:
        case 3.5:
          App.Route = '/schema';
          balk2zien = 1;
          this.lstconsroute();
          break;

        case 3.6:
          App.Route = '/schemacons';
          balk2zien = 1;
          this.lstconsroute();
          break;

        case 1.1:
          App.Route = '/fixt';
          balk2zien = 1;
          this.lstconsroute();
          break;
        case 1.2:
          App.Route = '/manuf';
          balk2zien = 1;
          this.lstconsroute();
          break;

        case 3.4:
        case 1.3:
          App.Route = '/lampen';
          balk2zien = 1;
          this.lstconsroute();
          break;

        case 1.4:
          App.Route = '/libraries';
          balk2zien = 1;
          this.lstconsroute();
          break;


        case 2.1:
          App.Route = '/user';
          balk2zien = 1;
          this.lstconsroute();
          break;
        case 3.1:
          App.Route = '/homec';
          balk2zien = 1;
          this.lstconsroute();
          break;
        case 3.2:
          App.Route = '/homeg';
          balk2zien = 1;
          this.lstconsroute();
          break;

        case 3.3:
          App.Route = '/lampencons';
          balk2zien = 1;
          this.lstconsroute();
          break;


    }
    this.zetbalk2(balk2zien);
    this.setState({oldstatblk2:balk2zien});
    this.context.router.push(App.Route,null);    
  },


  zetmencolor(nummenu) {
    this.setState({men1bg:'rgb(1, 115, 199)'});
    this.setState({men2bg:'rgb(1, 115, 199)'});
    this.setState({men3bg:'rgb(1, 115, 199)'});
    this.setState({men4bg:'rgb(1, 115, 199)'});
    this.setState({men5bg:'rgb(1, 115, 199)'});
    this.setState({men6bg:'rgb(1, 115, 199)'});
    this.setState({men1tc:'white'});
    this.setState({men2tc:'white'});
    this.setState({men3tc:'white'});
    this.setState({men4tc:'white'});
    this.setState({men5tc:'white'});          
    this.setState({men6tc:'white'});          

    switch (nummenu) {
        case 1:
          this.setState({men1bg:'rgb(241, 241, 241)'});
          this.setState({men1tc:'rgb(1, 115, 199)'});
          break;
        case 2:
          this.setState({men2bg:'rgb(241, 241, 241)'});
          this.setState({men2tc:'rgb(1, 115, 199)'});
          break;
        case 3:
          this.setState({men3bg:'rgb(241, 241, 241)'});
          this.setState({men3tc:'rgb(1, 115, 199)'});
          break;
        case 4:
          this.setState({men4bg:'rgb(241, 241, 241)'});
          this.setState({men4tc:'rgb(1, 115, 199)'});
          break;

        case 5:
          this.setState({men5bg:'rgb(241, 241, 241)'});
          this.setState({men5tc:'rgb(1, 115, 199)'});
          break;

        case 6:
          this.setState({men6bg:'rgb(241, 241, 241)'});
          this.setState({men6tc:'rgb(1, 115, 199)'});
          break;
      }
  },

  HfdbClick(e) {
    if (this.state.klikmen == true)
    {
      this.zetbalk2(0);
    }

  },
  zethfdmen(aan) {
    if (aan == 2)
    {
      this.state.olbut1 = this.state.lbut1;
      this.setState({klikmen:true});
      this.setState({lbut1:null});
      return;
    }
    if (aan == 1)
    {
      if (this.state.olbut1 != null)
      {      
        this.setState({lbut1:this.state.olbut1});      
        this.state.lbut1 = this.state.olbut1;
        this.state.olbut1 = null;
      }
      this.setState({klikmen:false});

    }
    else
    {
      if (this.state.lbut1 != null)
      {
        this.state.olbut1 = this.state.lbut1;
        this.setState({lbut1:null});      
      }
      this.setState({klikmen:true});
    }
    this.forceUpdate();
  },


  zetbalk2(aan) {
    if (aan == 0)
    {
      this.setState({balktop2:'0'});
      this.setState({balk1schad:'0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22)'});
      this.setState({topchild:'0px'});
//      this.setState({klikmen:false});
      this.setState({balkhfd:0});
    }
    else
    {
     this.setState({balk1schad:''});
     this.setState({balktop2:'1'});
     this.setState({topchild:'50px'});
     this.setState({balkhfd:1});

    }
  },
  zetpointer(type) {
    this.setState({cursor:type});
  },
  MenTouchTap(e) {
    var offset = $( this ).offset();
  },
  lstconsroute(){
    return;
  },
  func_filter(){
    return;
  },  

  AppAccount() {
      $.ajax({
        url: './server/index.php?function=GetAccDropd',
        dataType: 'json',
        type: 'POST'
        }).done(function(data){
             App.account = Immutable.List(data.data);
        }.bind(this));   
  },
  AppAccstatus(){
    if (this.state.AppAccstatus == 0)
    {  
      $.ajax({
        url: './server/index.php?function=GetDropAccStatus',
        dataType: 'json',
        type: 'POST'
        }).done(function(data){
             App.accstatus = Immutable.List(data.data);
        }.bind(this));
        this.state.AppAccstatus = 1;
    }
    else
    {
      return App.accstatus;
    }
  },
  AppAccadrtype(){
    if (this.state.AppAccadrtype == 0)
    {  

      $.ajax({
        url: './server/index.php?function=GetDropAccAdrType',
        dataType: 'json',
        type: 'POST'
        }).done(function(data){
             App.accadrtype = Immutable.List(data.data);
        }.bind(this));
      this.state.AppAccadrtype = 1;
    }
    else
    {
       return App.accadrtype;
    }
  },

    AppConstype() {
      if (this.state.AppConstype == 0)
      {  
        $.ajax({
          url: './server/index.php?function=GetDropConsType',
          dataType: 'json',
          type: 'POST'
          }).done(function(data){
             App.constype = Immutable.List(data.data);
             this.state.AppConstype = 1;
          }.bind(this));
      }
      else
      {
        return App.constype
      }
    },
    AppConsadrtype(){
      if (this.state.AppConsadrtype == 0)
      {  
        $.ajax({
          url: './server/index.php?function=GetDropConsAdrType',
          dataType: 'json',
          type: 'POST'
          }).done(function(data){
             App.consadrtype = Immutable.List(data.data);
             this.state.AppConsadrtype = 1;
        }.bind(this));
      }
      else
      {
        return App.consadrtype;
      }
    },

    AppZkCons(){
        $.ajax({
          url: './server/index.php?function=GetDropZkCons',
          dataType: 'json',
          type: 'POST'
          }).done(function(data){
             App.zkcons = Immutable.List(data.data);
        }.bind(this));
    },
    AppConsultants() {
        $.ajax({
        url: './server/index.php?function=GetDropConsultants',
        dataType: 'json',
        type: 'POST'
        }).done(function(data){
             App.consultants = Immutable.List(data.data);
        }.bind(this));
    },
    AppPostcode() {
      $.ajax({
        url: './server/index.php?function=Getpostcode',
        dataType: 'json',
        type: 'POST'
        }).done(function(data){
             App.postcode = Immutable.List(data.data);
        }.bind(this));
    },
    ZetConsultant() {
        $.ajax({
        url: './server/index.php?function=GetDropConsultants',
        dataType: 'json',
        type: 'POST'
        }).done(function(data){
             App.consultants = Immutable.List(data.data);
        }.bind(this));
    },
    ZetPostcode() {
      $.ajax({
        url: './server/index.php?function=Getpostcode',
        dataType: 'json',
        type: 'POST'
        }).done(function(data){
             App.postcode = Immutable.List(data.data);
        }.bind(this));
    },
    ZetAccount() {
      $.ajax({
        url: './server/index.php?function=GetAccDropd',
        dataType: 'json',
        type: 'POST'
        }).done(function(data){
             App.account = Immutable.List(data.data);
        }.bind(this));   
    },
    zetbalk(){
      if (this.state.balktop == 'inline-table')
      {
        this.setState({balktop:'none'});
        this.setState({btop2top:'50px'});
        this.zetmencolor(99);

      }
      else
      {
        this.setState({btop2top: '0px'});
        this.setState({balktop:'inline-table'});
      }
    },
    _onLeftIconButtonTouchTap() {
      window.scrollTo(0, 1);

      if (App.Mobile)
      {
        this.refs.leftNav.toggle();
      }
      else
      {
        this.zetbalk();
      }
    },
    leftNavChange(e, key, payload) {

      if (payload.route != App.Route)
      {
        App.Route = payload.route;
        this.context.router.push(App.Route);    
      }
    },    
    _onlogin(data) {
      if (this.state.loggedIn)
      {
        return;
      }

      let App = window.App;
      this.state.user =data.login;
      App.Admin = data.type;  
      App.userid = data.id;
      App.username = data.name;
      App.KlantId = '0';
      App.ConsId = '0';
      this.state.loggedIn = true;
      this.setState({foto:data.foto});

      App.master = this;
      App.Actdet = null;
      if (data.type == 'GB')
      {
          if (data.actief == '1')
          {
              App.ConsId = data.id;
              App.Route = '/homemen';
              this.context.router.push(App.Route);    
          }
          else
          {
              App.ConsId = data.id;
              App.Route = '/homemen';
              this.context.router.push(App.Route);    
          }

      }
      else
      {
        App.Route = '/homemen';
        this.context.router.push('/homemen');

      }
      this.refs.DialLogin.sluiten();

      // Get picture
    },    
});

//module.exports = Main;
module.exports = DragDropContext(HTML5Backend)(Main);

let docCookies = {
  getItem: function (sKey) {
      if (!sKey) { return null; }
      return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
      if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
      var sExpires = "";
      if (vEnd) {
        switch (vEnd.constructor) {
          case Number:
            sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
            break;
          case String:
            sExpires = "; expires=" + vEnd;
            break;
          case Date:
            sExpires = "; expires=" + vEnd.toUTCString();
            break;
        }
      }
      document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
      return true;
  },
  removeItem: function (sKey, sPath, sDomain) {
      if (!this.hasItem(sKey)) { return false; }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
  },
  hasItem: function (sKey) {
      if (!sKey) { return false; }
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },
  keys: function () {
      var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
      for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
      return aKeys;
  }

};
function initApp() {
    var App = window.App;
    App.FactId = 0;
    App.ConsId = 0;
    App.ContId = 0;
    App.HfdBlk = 0;
    App.InvfId = 0;
    App.LidId = 0;
    App.OpdgId = 0;
    App.ActId=0;
    App.ReqId=0;
    App.TikId=0;
    App.AssId=0;
    App.KlachtId=0;
    App.WerfId=0;
    App.SubActId=0;
    App.KlantId=0;
    App.ConTractId=0;    
    App.tfconsid=0;
    App.tfaccountid=0;
    App.tfmainid=0;
    App.ContNaam='';
    App.KlantNaam='';
    App.ConsName='';
    App.LidName='';
    App.KlachtName='';
    App.WerfName='';
    App.OpdgName='';
    App.ReqDoorGeef = {};
    App.ContDoorGeef = {};
    App.Mobile = false;
    var navig=navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
    if (navig) {
      App.Mobile = true;
      //For iPhone and Andriod To remove Address bar when viewing website on Safari Mobile
      // When ready...
      window.addEventListener("load",function() {
        // Set a timeout...
        setTimeout(function(){
          // Hide the address bar!
          window.scrollTo(0, 1);
        }, 0);
      });
    } 

    App.banken = Immutable.List();
    App.zkcons = Immutable.List();
    App.zkreq = Immutable.List();

    App.constype = Immutable.List();

    App.vat = Immutable.List();
    App.unit = Immutable.List();

    App.postcode = Immutable.List();
    App.product = Immutable.List();
    App.centra= Immutable.List();
    App.account= Immutable.List();
    App.klanten = Immutable.List();
    App.consultants = Immutable.List();
    App.klachtsoort = Immutable.List();
    App.klachtstatus = Immutable.List();;
    App.klachten = Immutable.List();
    App.actsoort = Immutable.List();
    App.opdgtype = Immutable.List();
    App.intro = Immutable.List();
    App.OpdrachtG = Immutable.List();
    App.werven = Immutable.List();
    App.timestat = Immutable.List();
    App.accadrtype = Immutable.List();
    App.consadrtype = Immutable.List();
    App.assettype = Immutable.List();
    App.ostype = Immutable.List();
    App.lictype = Immutable.List();
    App.assstaat = Immutable.List();
    App.accstatus = Immutable.List();
    App.sector = Immutable.List();
    App.owners = Immutable.List();
    App.contacten = Immutable.List();

    App.salutation = Immutable.List();
    App.jobtitle = Immutable.List();
    App.marital = Immutable.List ();
    App.kennis = Immutable.List ();
    App.kenniszk = Immutable.List ();
    App.actstatus = Immutable.List ();
    App.acttype = Immutable.List ();
    App.request = Immutable.List ();
    App.tickets = Immutable.List ();
    App.reqstatus = Immutable.List ();
    App.tikstatus = Immutable.List ();
    App.reqkandstatus = Immutable.List ();

    App.master= null;
    App.Orientation = 'P';
    App.afkomst=Immutable.List();
    App.TerugZoek='';
    App.TerugDetail='';
    App.TerugTab = [];
    App.VeldTerug='';
    App.VeldSleuteloud=0;
    App.VeldSleutelNew=0;
    App.FactKlantId=0;
    App.ZkKandKenId =0;

};

function readDeviceOrientation() {
    if (Math.abs(window.orientation) === 90) {
      App.Orientation = 'L';
    } else {
      App.Orientation = 'P';
    }
};