import React from 'react';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import PropTypes from '../utils/prop-types';
import StylePropable from '../mixins/style-propable';
import Typography from '../styles/typography';
import Paper from '../paper.jsx';
import ListItem from './list-item.jsx';
const TextField = require('../text-field.jsx');
import ListDivider from './list-divider.jsx';
import DefaultRawTheme from '../styles/raw-themes/light-raw-theme';
import ThemeManager from '../styles/theme-manager';
const Avatar = require('../avatar.jsx');


const List = React.createClass({

  mixins: [
    PureRenderMixin,
    StylePropable,
  ],

  contextTypes: {
    muiTheme: React.PropTypes.object,
  },

  propTypes: {
    children: React.PropTypes.node,
    insetSubheader: React.PropTypes.bool,

    /**
     * Override the inline-styles of the root element.
     */
    style: React.PropTypes.object,
    subheader: React.PropTypes.node,
    subheaderStyle: React.PropTypes.object,
    zDepth: PropTypes.zDepth,
  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
  },

  getChildContext() {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

  getDefaultProps() {
    return {
      zDepth: 0,
    };
  },

  getInitialState() {
    return {
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  //to update theme inside state whenever a new theme is passed down
  //from the parent / owner using context
  componentWillReceiveProps(nextProps, nextContext) {
    let newMuiTheme = nextContext.muiTheme ? nextContext.muiTheme : this.state.muiTheme;
    this.setState({muiTheme: newMuiTheme});
  },
  
  _filterRowsBy(e) {
      if (this.props.onFilter)
      {
        this.props.onFilter(e.target.value);
      }
  },      

 _handleListItemTouchTap(sleutel) {
    if (this.props.onKlik) this.props.onKlik(sleutel);
  },  
  _getChildren() {
    let  listItem,
      itemComponent,
      isSelected,
      isDisabled,
      rightAvatar,
      leftAvatar;

    this._children = [];
    //This array is used to keep track of all nested menu refs
    this._nestedChildren = [];

    for (let i=0; i < this.props.listItems.length; i++) {
      listItem = this.props.listItems[i];
      rightAvatar = listItem.rightAvatar ? (<Avatar src={listItem.rightAvatar} />) : null;
      leftAvatar = listItem.leftAvatar ? (<Avatar src={listItem.leftAvatar} />) : null;
       switch (listItem.type) {

        case "DIVIDER":
          itemComponent = (
            <ListDivider />);
          break;

        default:
          itemComponent = (
            <ListItem
              key={listItem.key}
              primaryText={listItem.primaryText}
              hfdsleutel={listItem.hfdsleutel}
              leftIcon={listItem.leftIcon}
              rightIcon={listItem.rightIcon}
              insetChildren={listItem.insetChildren}
              secondaryText={listItem.secondaryText}
              secondaryTextLines={listItem.secondaryTextLines}
              hoogte={listItem.hoogte}
              leftCheckbox={listItem.leftCheckbox}
              leftAvatar={leftAvatar} 
              rightAvatar={rightAvatar}
              handleListItemTouchTap={this._handleListItemTouchTap}>
            </ListItem>
          );
      }
      this._children.push(itemComponent);
      if (!listItem.Nodivider)
      {
        let sleutel = 'LD'+i;
        itemComponent = (<ListDivider key={sleutel}/>);
        this._children.push(itemComponent);
      }


    }

    return this._children;
  },  


  render() {
    const {
      children,
      insetSubheader,
      style,
      subheader,
      subheaderStyle,
      zDepth,
      onKlik,
      ...other,
    } = this.props;

    const styles = {
      root: {
        padding: 0,
        paddingBottom: 8,
        paddingTop: subheader ? 0 : 8,
      },

      subheader: {
        color: Typography.textLightBlack,
        fontSize: 14,
        fontWeight: Typography.fontWeightMedium,
        lineHeight: '48px',
        paddingLeft: insetSubheader ? 72 : 16,
      },
    };
  let vld_children = this._getChildren();
    let subheaderElement;
    if (subheader) {
      const mergedSubheaderStyles = this.prepareStyles(styles.subheader, subheaderStyle);
      subheaderElement = <div style={mergedSubheaderStyles}>{subheader}</div>;
    }

       let vldzoeken = (this.props.zoeken) ? (
        <TextField
          style={{marginRight: '15px',backgroundColor:'white', marginLeft: '0px', position: 'fixed', marginTop:'-24px', zIndex:'99999', width: '85%'}}
          hintText="Zoeken"
          ref="TabFilter" 
          onChange={this._filterRowsBy} />):null;
     

    return (
      <div>
        {vldzoeken}
        <Paper
          style={this.mergeStyles(styles.root, style)}
          zDepth={zDepth}>
          {subheaderElement}
          {vld_children}
        </Paper>
      </div>
    );
  },
});

export default List;
