import React, { PropTypes, PureComponent } from 'react';
const ReactDOM = require('react-dom');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const DgFunc = require('../datagrid/src/functions.jsx');
const TextField = require('../matui/text-field.jsx');
const IconButton = require('../matui/icon-button.jsx');
const IconButOver = require('../matui/icon_but_over.jsx');
const SubLijst = require('./sublijstnw.jsx');
const Consvorm = require('./lampvorm.jsx');
var Panel = require("react-bootstrap").Panel;
var PanelGroup = require("react-bootstrap").PanelGroup;
var Grid = require("react-bootstrap").Grid;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
const Contstafunc = require('./cont_func.jsx');
const PopupCons = require('./popdefadres.jsx');

import cn from 'classnames'
var OrgData  = [];
var GrData     = [];
 
let LstLamp = React.createClass({
  mixins: [PureRenderMixin],

  contextTypes:{
    router: React.PropTypes.object
  },
  getInitialState() {
    return {
      popupcons:null,
      rcons:'ConsAllOverz',
      rreq:'GetAllRequestNw',
      zoekconsdata:Contstafunc.Dat_zkcons(),
      zoekreqdata:Contstafunc.Dat_zkcons(),
      opladen:0,
      topposcons:'0px',
      topposreq:'0px',
      mousedown:0,
      collapsible:true,
      lbreed:3,
      rbreed:9,
      lhide:false,
      swidth:'100%',
      lpos:'0px',
      zoom:1,
      colexport:[],
      exportzien:false,
      exptitle:'',
      lmleft:'0px',
      rmleft:'1500px',
      ldisplay:'inherit',
      rdisplay:'none',
      vzien:'left',
      expa:false,      
      vorm:'',
      titalgvorm: 'Recent',
      sqlstat:'GetAllLog',
      sqldatacons: {param: 0},   
      sqldatareq: {param: 0},   
      edit:0,
      activkey:1,
      tags: [ {id: 1, text: "Apples"} ],   
      tagkey:0,
      listsbescons:[],
      typeadrafk:'HFD', 
      typeadres:'Hoofdadres',
      updatecol:'',
      updatetxt:'', 
      jaar:0,
      vldgrid:null,
      data: null,
      naam:'',
      nwfoto:'',
      route: '',
      nwgrid:true,
      menshow:true,
      DgrowData:[],
      searchkey: 'id',
      Detail:'',
      Instxt:'',
      Tabelnaam:'',
      height: (window.innerHeight).toString(),
      GrData:[],
      data:{id:0},
      ListData:[],
      HListData:[],
      dgcolumns:[],      
      colFilter: [],
      overscanRowCount: 0,
      rowCount: 0,
      scrollToIndex: undefined,
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false,
      virtualScrollHeight: 300,
      virtualScrollRowHeight: 100,
      RowHeight:0,                        
      immuData:Immutable.List(),
      gridhoog:(window.innerWidth * .20),
      gridhoog2:'',
      rijen: 1,
    };
  },
  componentWillMount() {
    this.setState({opladen:0});
    if (!App.master)
    {
      window.location.hash = "/";
      return;
    }

    this.state.route = App.Route;
    // zetten van icons in balk2
    let vldr1 = (screen.width > 7) ? (<TextField
      style={{width: '100%',zIndex: '9999999', fontSize:'115%'}}
      hintText="Search"
      underlineFocusStyle={{borderColor:'white'}}
      inputStyle={{color:'white'}}
      hintStyle={{color:'white'}}
      id='HfdZkVldId'
      defaultValue=""   
      onChange={this.handleFilter} />):null;

    let d1 = new Date();
    App.master.setState({lbut1:vldr1});

    if (screen.width < 750) {this.setState({swidth:'100%'})} ;
    let breed = window.innerWidth;
    if (breed > 749 && breed < 1550) 
    {
      this.setState({zoom:'.9'})
      this.setState({swidth:'130%'})
    } 
    else 
    {
      this.setState({zoom:'1'})
      this.setState({swidth:'100%'})
    };
  },
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  render() {
    // Algemeen voor render
    let beeld = screen.width;
    let beeldh = screen.height;
    let mhoogte = (screen.height - 50);
    let mshoogte = '-'+mhoogte+'px';
    let vldr1 = (<TextField
      style={{width: '100%', left: '15px'}}
      underlineFocusStyle={{color:'white'}}
      inputStyle={{color:'white'}}
      hintText="Zoeken"
      ref='zoeken'    
      onChange={this.handleFilter} />);
    let tot_zicht1_rec = null;
    let tot_zicht1_cons = null;
    let tot_zicht1_acc = null;
    let tot_zicht1_cont = null;
    let tot_zicht1_req = null;
    let pathnew = 'M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z';
    let tot_zicht2 = null;
    // Definieren van de headers
    var w = window.innerWidth - 5;
    var h = window.innerHeight - 100 ;
    if (window.innerWidth < 1500)
    {
      h = window.innerHeight - 40;
    }

    const {
      overscanRowCount,
      rowCount,
      scrollToIndex,
      showScrollingPlaceholder,
      useDynamicRowHeight,
      virtualScrollHeight,
      virtualScrollRowHeight,
      RowHeight,
      data
    } = this.state
    // Grid Links
    let winhoog = (window.innerHeight - 100);
    let panhoog = winhoog - 124;
    let gridhoog = winhoog - 224;
    let winhoogtwee = winhoog - 10;

    let sqlstat = 'GetAllLog';


    let vld_dgridcons = (<SubLijst ref='left_list_cons' id='ListConsL' route='GetAllLampen' hdata={{param: ''}} rijen={2} height={h} breed={350} hoog={h} mhoog={gridhoog} sleutel={0} behandel={false} zoeken={false} zetgeg={this.zetgeg} klik={this.LRowClick}/>);
    let winbreed = window.innerWidth;
    tot_zicht1_cons = (
          <Row style={{display: 'block', position: 'relative',marginBottom:'10px',height:'auto',top:this.state.topposcons}}>
            <div style={{width:'350px',float:'left'}} >
              {vld_dgridcons}
            </div>
          </Row>);    

    tot_zicht2 =  (<Consvorm ref="Rvorm" id='CONSVORM' sqlstat={this.state.sqlstat} newreq={this.NewRecord} vorm={this.state.vorm} clickreq={this.clickreq} nwfoto={this.state.nwfoto} clickaccount={this.Clickaccount}  loadDataNw={this.RefDataNw} clickcontr={this.clickcontr} data={this.state.data}/>);

    let telbij = (beeld < 1500 ) ? 125 : -100;

    let breedtel = this.state.gridhoog + 25;
    let breed = 'calc(100vw - '+breedtel+'px)';
    let algtot2 = (<div ref='GrdRechts' style={{overflow: 'hidden', height:'auto', left:this.state.gridhoog + 25, position: 'absolute', width: breed , minWidth:breed }}>
                    {tot_zicht2}
                  </div>) ;

    let midbreed = (winbreed > 1850) ? 'calc((100% * 1) - 750px)' : 'calc((100% * 1) - 400px)';   
    let midbreedc = (winbreed > 1850) ? 'calc((95% * 1) - 750px)' : 'calc((95% * 1) - 400px)';   
    let maxw = (winbreed > 1850) ? '900px' : '2000px';   
    let PTitCons = (<h3 className="panel-title"><span>Fixtures</span>
                      <IconButOver
                        style={{width:'30px',height:'30px', paddingLeft:'5px', paddingRight:'5px',fontSize:'90%',float:'right'}}
                        tooltip= "New Fixture"
                        onMouseLeave={this.MouseLeave}
                        onMouseEnter={this.MouseEnter}
                        svgStyle={{width:'18px',height:'18px', fill:'#008cc9'}}
                        path={pathnew}
                        onClick={(event)=>this.NewRecord('CONS',Contstafunc.Dat_cons())}/>
                      </h3>);        
    let segleft = (<div id='LstMainLinks' 
                        style={{width: 'calc(350px)', marginLeft: 'calc((576px * 0) - 24px + 49px)', marginBottom: '24px', boxSizing: 'border-box', float: 'left', marginRight: '-1px', position:'fixed',zIndex:'99',
                        WebkitAnimation: 'fade-in 330ms ease forwards', animation: 'fade-in 330ms ease forwards',margin: '0', padding: '0', border: '0', outline: '0', fontSize: '100%', verticalAlign: 'baseline', background: '0 0', display: 'block',left:'5px',height:winhoog}}>
                        <Panel  header={PTitCons} ref='PanCons' bsStyle="grblok" style={{background: 'transparent', width: '300px',overflow: 'hidden', maxHeight:panhoog, height:'auto'}}>
                          {tot_zicht1_cons}
                        </Panel>
                    </div>);


    let segmid = ( <div id='LstMainMid' 
                      style={{boxSizing: 'border-box', float: 'left', width: midbreed, marginBottom: '24px', WebkitAnimationDelay: '84ms', animationDelay: '84ms', WebkitAnimation: 'fade-in 330ms ease forwards', 
                      animation: 'fade-in 330ms ease forwards', paddingLeft: '20%', border: '0', outline: '0', fontSize: '100%', verticalAlign: 'baseline', display: 'block',height:winhoog,maxWidth:maxw,minWidth:'1000px'}}> 
                      <div id='LstMainMidCent' 
                          style={{boxSizing: 'border-box', width: midbreedc, margin: 'auto', overflowX: 'hidden',overflowY: 'auto',border: '0', outline: '0',verticalAlign: 'baseline',backgroundColor: '#fff', background: '0 0', display: 'block', position: 'absolute',height:winhoog,maxWidth:maxw ,minWidth:'1000px'}}>
                          <div style={{width:'101.5%',float:'left',height:winhoogtwee, overflowX: 'hidden',overflowY: 'auto'}} >
                            {tot_zicht2}
                          </div>                          
                          
                    </div>                      
                  </div>);

    let padhfd = (beeld < 750 && this.state.ldisplay == 'none') ? '50px' : '100px';
    return ( 
      <div id='LstMainTot' style={{width: 'auto', boxSizing: 'content-box', height:winhoog, padding: '0 32px', display: 'block', margin: 'auto', position: 'relative', border: '0', outline: '0', fontSize: '100%', verticalAlign: 'baseline', background: '0 0',maxWidth:'1950px',minWidth:'1400px'}}>
        {segleft}
        {segmid}
        {this.state.popupreq}
        {this.state.popupcont}
        {this.state.popupcons}
        {this.state.popupacc}
        {this.state.popupzkcons}
        {this.state.popupzkreq}

       </div>)
  },
  MouseLeave(){
    this.setState({collapsible:true});
    this.refs.PanCons.SetCollapse(true);     
  },
  MouseEnter(){
    this.setState({collapsible:false});
    this.refs.PanCons.SetCollapse(false);     

  },
  handleResize(){
    let breed = window.innerWidth;
    if (breed > 749 && breed < 1550) 
    {
      this.setState({zoom:'.9'})
      this.setState({swidth:'130%'})
    } 
    else 
    {
      this.setState({zoom:'1'})
      this.setState({swidth:'100%'})
      this.setState({lhide:false});
      this.setState({lpos:'0px'});
    };
    this.forceUpdate();
  },
  componentDidMount() {
    if (App.master) 
    {
      App.master.zetbalk2(1);
      App.master.setState({oldstatblk2:1});
      App.master.lstconsroute = this.laden;
      if (App.master.state.AppHfdMenKeuze)
      {
        if (App.master.state.AppHfdMenKeuze.FiltData) {   
          this.zetgeg(App.master.state.AppHfdMenKeuze.FiltData[0],'GetAllLampen');
        }
      }
    }
    window.addEventListener('resize', this.handleResize);

  },   
  laden() {
    if (this.isMounted()) 
    {
      this.filtleeg();
      this.setState({opladen:0});
    }
    return;
  },
  componentWillUpdate() {
    if (App.master) 
    {
      App.master.zetbalk2(1);
      App.master.setState({oldstatblk2:1});
    }
  },  
  filtleeg() {
    var elem = document.getElementById("HfdZkVldId");
    if (elem)
    {
      elem.value='';
    }
  },
  // Update consultants
  Clickaccount(hdata) {
    this.state.opladen = 0;
    App.master.setState({overzdata:{}}); 
    ClsAcc.GetAccount(hdata)
    .then(data => {
        this.zetgeg(data, 'ACC');
    })
    .catch(error => {
    });
    return true;
    // Einde ophalen gegevens
  },
  zetgeg(hudata,route,statkey) {
    let data = hudata;

    if (data) 
    {
      if (this.refs.Rvorm)
      {
        this.refs.Rvorm.zetedit(data.id);
      }
    }
  },
  RefDataNw(nwdata, vorm, statkey) {
    App.master.setState({overzdata:{}}); 
    var elemfil = document.getElementById("HfdZkVldId");
    let filtxt = (elemfil) ? elemfil.value : '';
    this.state.opladen = 0;
    this.state.rijen = 2;            
    this.refs.left_list_cons.loadNwData(0,'GetAllLampen', {param: ''}, '');  
    if (nwdata)   
    {
      Contstafunc.ZetApidLeeg();
      this.setState({vzien:'right'});
      this.setState({ldisplay:'none'});
      this.setState({rdisplay:'inherit'});
      if (this.refs.Rvorm)
      {
        this.refs.Rvorm.zetedit(nwdata.id);
      }
    }
  },
  handleFilter(e){
    let value = e.target.value;   
    App.master.setState({overzdata:{}}); 
    this.state.opladen = 0;
    this.refs.left_list_cons.handleFilter(value,true); 
    return;
  },
  zetFilter(value){
    if (this.state.colFilter)
    {
      this.state.GrData = DgFunc.SetFilter(value,GrData,this.state.colFilter);      
      this.setState({immuData:Immutable.List(this.state.GrData)});
      this.setState({rowCount:Immutable.List(this.state.GrData).size});
      this.setState({scrollToIndex:0});
    }
  },
  LRowClick(e,sleutel) {
    // Zetten van de naam
    Contstafunc.ZetApidLeeg();
    this.setState({vzien:'right'});
    this.setState({ldisplay:'none'});
    this.setState({rdisplay:'inherit'});
    if (this.refs.Rvorm)
    {
      this.refs.Rvorm.zetedit(sleutel.id);
    }
    return;
  },
  NewRecord(vorm,data) {
    App.master.setState({overzdata:{}}); 
    let hudata = {id:'0',name:'',accountid:0,accountname:'',vischan:0,unvischan:0,ClampId:0,schemid:0,adresid:0};
    this.setState({popupcons:(<PopupCons  id='popupconsMain' zindex={999999} data={hudata} Sluiten={this.conssluiten} ></PopupCons>)})
    return;
  },
  conssluiten(data) {
    this.setState({popupcons:null});
    this.RefDataNw(data,'CONS',2) 
  },
});

module.exports = LstLamp;






