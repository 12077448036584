'use strict';

var React   = require('react')
var Region  = require('region')
var DraggableCore = require('react-draggable');
var Draggable = require('react-draggable');
var ReactMenu = React.createFactory(require('react-menus'))
var assign  = require('object-assign')
var clone   = require('clone')
var asArray = require('../utils/asArray')
var findIndexBy = require('../utils/findIndexBy')
var findIndexByName = require('../utils/findIndexByName')
var Cell    = require('../Cell/index.jsx')
var setupColumnDrag   = require('./setupColumnDrag')
var setupColumnResize = require('./setupColumnResize')
const FloatingActionButton = require('../../../matui/floating-action-button.jsx');
const IconButton = require('../../../matui/icon-button.jsx');
const ReactDOM = require('react-dom');

var Tappable = require('react-tappable');

var normalize   = require('react-style-normalizer')

var dragColumn
var dragColumnIndex
var columnData
var shiftRegion

function emptyFn(){}

function getColumnSortInfo(column, sortInfo){

    sortInfo = asArray(sortInfo)

    var index = findIndexBy(sortInfo, function(info){
        return info.name === column.name
    })

    return sortInfo[index]
}

function removeColumnSort(column, sortInfo){
    sortInfo = asArray(sortInfo)

    var index = findIndexBy(sortInfo, function(info){
        return info.name === column.name
    })

    if (~index){
        sortInfo.splice(index, 1)
    }

    return sortInfo
}

function getDropState(){
    return {
        dragLeft  : null,
        dragColumn: null,
        dragColumnIndex: null,
        dragging  : false,
        dropIndex : null,
        shiftIndexes: null,
        shiftSize: null
    }
}

function range(start, end){
    var res = []
        for ( ; start <= end; start++){
            res.push(start)
        }
        return res
}

function buildIndexes(direction, index, dragIndex){
    var indexes = direction < 0 ?
                    range(index, dragIndex):
                    range(dragIndex, index)

    var result = {}

    indexes.forEach(function(value){
        result[value] = true
    })

    return result
}    

module.exports = React.createClass({

    displayName: 'ReactDataGrid.Header',

    propTypes: {
        columns: React.PropTypes.array
    },

      onDrop: function(event){

        var state = this.state
        var props = this.props

        if (state.dragging){
            event.stopPropagation()
        }

        var dragIndex = state.dragColumnIndex
        var dropIndex = state.dropIndex
        if (dropIndex != null){

            //since we need the indexes in the array of all columns
            //not only in the array of the visible columns
            //we need to search them and make this transform
            var dragColumn = props.columns[dragIndex]
            var dropColumn = props.columns[dropIndex]

            dragIndex = findIndexByName(props.allColumns, dragColumn.name)
            dropIndex = findIndexByName(props.allColumns, dropColumn.name)

            this.props.onDropColumn(dragIndex, dropIndex)
        }

        this.setState(getDropState())
//        event.target.style.border= 'none';
        document.getElementById(dropColumn.name).style.WebkitTransform= 'translate(0px,0px)';
        document.getElementById(dropColumn.name).style.transform = 'translate(0px,0px)'; 
        document.getElementById(dropColumn.name).style.msTransform = 'translate(0px,0px)';         
        document.getElementById(dragColumn.name).style.WebkitTransform= 'translate(0px,0px)';
        document.getElementById(dragColumn.name).style.transform = 'translate(0px,0px)'; 
        document.getElementById(dragColumn.name).style.msTransform = 'translate(0px,0px)';         
        this.render();



    },

    getDefaultProps: function(){
        return {
            defaultClassName : 'z-header-wrapper',
            draggingClassName: 'z-dragging',
            cellClassName    : 'z-column-header',
            defaultStyle    : {},
            sortInfo        : null,
            scrollLeft      : 0,
            scrollTop       : 0
        }
    },

    getInitialState: function(){

        return {
            mouseOver : true,
            translate:'translate(0px,0px)',
            dragging  : false,
            shiftSize : null,
            dragColumn: null,
            shiftIndexes: null,
            sbord:'none',
            startpos:999999,
            iconfoc:false,
            deltaPosition: {
              top: 0, left: 0
            },
            orgbreed:0,
            orgtarg:null,
            coldragsize:[],
        }
    },

    onStartDrag: function(column,e) {
//        e.target.style.borderStyle= 'ridge';
        if (this.state.iconfoc===true) 
        {
            this.handleSortMouseUp(e, column);            
            return
        };
        var dragColumn = column;
        var headerNode = ReactDOM.findDOMNode(this);
        var headerRegion = Region.from(headerNode);
        var dragColumnIndex
        var columnData
        var shiftRegion
        var columnHeaders = headerNode.querySelectorAll('.' + this.props.cellClassName)
        this.setState({
        deltaPosition: {
          left:0,
          top:0,
        }} )    ;   

        this.setState({
          dragColumn: column,
          dragging  : false
        })

        this.setState({columnData:null});
        this.setState({dragColumnIndex:0});
        this.setState({dragColumn:''});
        this.setState({shiftRegion:0});
        this.setState({orgtarg:null});
            columnData = this.props.columns.map(function(column, i){
                var region = Region.from(columnHeaders[i])
                if (column === dragColumn)
                {
                    dragColumnIndex = i
                    shiftRegion = region.clone()
                }

                return {
                    column: column,
                    index: i,
                    region: region
                }
            })

            this.setState({
                dragColumn: column,
                dragging  : true
            })

        this.setState({columnData:columnData});
        this.setState({dragColumnIndex:dragColumnIndex});
        this.setState({dragColumn:dragColumn});
        this.setState({shiftRegion:shiftRegion});
        this.setState({orgtarg:e.target});

    },



    onStartResize: function(column,e) {
        e.target.style.borderLeftStyle= 'inset';
        var dragcolumns = this.state.coldragsize;
        var dragindex = findIndexByName(dragcolumns, column.name);
        if (dragindex > -1)
        {
        }
        this.setState({starting:1});

        var proxyLeft = Region.from(e.target).right
        this.setState({
        deltaPosition: {
          left:0,
          top:0,
        }} )    ;   
        this.onResizeDragStart({
                resizing       : true,
                resizeColumn   : column,
                resizeProxyLeft: proxyLeft
            })
      this.setState({orgbreed:column.width});
      this.setState({orgtarg:e.target});

    },

    onStopResize: function(column,e) {
      this.setState({startpos:999999});

      this.state.orgtarg.style.WebkitTransform= 'translate(0px,0px)';
      this.state.orgtarg.style.transform = 'translate(0px,0px)'; 
      this.state.orgtarg.style.msTransform = 'translate(0px,0px)'; 
      this.state.orgtarg.style.borderLeftStyle= 'none';
      this.setState({orgtarg:null});

      var columns = this.props.columns;
      var index = findIndexByName(columns, column.name);
      var proxyLeft = Region.from(e.target).right;

      var headerNode = ReactDOM.findDOMNode(this);
      var constrainTo = Region.from(headerNode);
      var diff = this.state.deltaPosition.left;

      var columnHeaders = headerNode.querySelectorAll('.' + this.props.cellClassName)
      var nextColumn    = diff > 0? null: columns[index + 1];
      var columnSize = Region.from(columnHeaders[index]).width;
      var nextColumnSize;
      var firstSize  = columnSize + diff;
      var secondSize = 0;

      if (nextColumn){
            nextColumnSize = nextColumn?Region.from(columnHeaders[ index + 1]).width: 0;
            secondSize = nextColumnSize - diff;
      }

      var resizeInfo = [{
           name: column.name,
           size: firstSize,
           diff: diff
        }]

      var hresinfo = {
           name: column.name,
           size: firstSize,
           diff: diff
        }


      if (nextColumn){
            resizeInfo.push({
                name: nextColumn.name,
                size: secondSize,
                diff: -diff
            })
      }

      // Kijken of col bestaat
      var dragcolumns = this.state.coldragsize;
      var dragindex = findIndexByName(dragcolumns, column.name);
      if (dragindex < 0)
      {
            dragcolumns.push({
                name: column.name,
                size: firstSize,
                diff: diff
            });

      }

      else
      {
        dragcolumns[dragindex] = hresinfo;
      }
      this.setState({coldragsize:dragcolumns});
      this.onResizeDrop({
        resizing: false,
        resizeColumn: null,
        resizeProxyLeft: null
      }, resizeInfo, e)


    },

    onStopDrag: function(column,e) {
      if (this.state.iconfoc===true) 
      {
        return
      };

      this.setState({startpos:999999});
      this.state.orgtarg.style.WebkitTransform= 'translate(0px,0px)';
      this.state.orgtarg.style.transform = 'translate(0px,0px)'; 
      this.state.orgtarg.style.msTransform = 'translate(0px,0px)'; 
//      this.state.orgtarg.style.border= 'none';
      this.setState({orgtarg:null});
      this.onDrop(e);
     },



    handleDrag: function (e, ui) {
      var {left, top} = this.state.deltaPosition;

      this.setState({
        deltaPosition: {
          left: left + ui.deltaX,
          top: top + ui.deltaY,
        }
      });
      this.setState({startpos:0});
    },    



    handleDragDrag: function (e, ui) {
        if (this.state.iconfoc===true) 
        {
            return
        };

        var left = this.state.deltaPosition.left
        var top = this.state.deltaPosition.top
        var diff = this.state.deltaPosition.left + ui.deltaX;
        var directionSign = diff < 0? -1: 1
        var state = {
            dragColumnIndex  : this.state.dragColumnIndex,
            dragColumn  : this.state.dragColumn,
            dragLeft    : diff,
            dropIndex   : null,
            shiftIndexes: null,
            shiftSize   : null
        }
        if (this.state.dragColumnIndex == 0)
        {
            if (diff < 0)
            {
                e.target.style.WebkitTransform= 'translate(0px,0px)';
                e.target.style.transform = 'translate(0px,0px)'; 
                e.target.style.msTransform = 'translate(0px,0px)'; 
                return
            }
        }
        var shift
        var shiftSize
        var newLeft   = this.state.shiftRegion.left + diff
        var newRight  = newLeft + this.state.shiftRegion.width
        var shiftZone = { left: newLeft, right: newRight}
        this.setState({
            deltaPosition: {
            left: left + ui.deltaX,
            top: top + ui.deltaY,}
        });        
        let that=this;
        this.state.columnData.forEach(function(columnData, index, arr){
            var itColumn = columnData.column
            var itRegion = columnData.region

            if (shift || itColumn === dragColumn){
                return
            }

            var itLeft  = itRegion.left
            var itRight = itRegion.right

            var itZone  = directionSign == -1?
                { left: itLeft, right: itLeft + itRegion.width }:
                { left: itRight - itRegion.width, right: itRight }

            if (that.state.shiftRegion.width < itRegion.width){
                //shift region is smaller than itRegion
                shift = Region.getIntersectionWidth(
                    itZone,
                    shiftZone
                ) >= Math.min(
                    itRegion.width,
                    that.state.shiftRegion.width
                ) / 2

            } else {
                //shift region is bigger than itRegion
                shift = Region.getIntersectionWidth(itRegion, shiftZone) >= itRegion.width / 2
            }
            if (shift) {
                shiftSize = -directionSign * that.state.shiftRegion.width
                state.dropIndex = index
                state.shiftIndexes = buildIndexes(directionSign, index, that.state.dragColumnIndex)
                state.shiftSize = shiftSize
            }
        })
        that.setState({startpos:0});        
        that.setState(state)
    },    




    render: function() {
        var props = this.prepareProps(this.props)
        var state = this.state

        var cellMap = {}
        var cells = props.columns
                        .map(function(col, index){
                            var cell = this.renderCell(props, state, col, index)
                            cellMap[col.name] = cell

                            return cell
                        }, this)



        if (props.columnGroups && props.columnGroups.length){

            cells = props.columnGroups.map(function(colGroup){
                var cellProps = {}
                var columns = []

                var cells = colGroup.columns.map(function(colName){
                    var col = props.columnMap[colName]
                    columns.push(col)
                    return cellMap[colName]
                })

                return <Cell {...cellProps}>
                    {cells}
                </Cell>
            }, this)
        }

        var style = normalize(props.style)
        var headerStyle = normalize({
            paddingRight: props.scrollbarSize,
            transform   : 'translate3d(' + -props.scrollLeft + 'px, ' + -props.scrollTop + 'px, 0px)',
            background: 'white',
            borderTop: '1px solid #a8a8a8',
            fontSize: 'small',            

        })

        return (
            <div style={style} className={props.className}>
                <div className='z-header' style={headerStyle}>
                    {cells}
                </div>
            </div>
        )
    },

    renderCell: function(props, state, column, index){

        var resizing  = props.resizing
        var text      = column.title
        var className = props.cellClassName || ''
        var style     = {
            left: 0,
            display:'-webkit-box',            
            transform:this.state.translate,
            WebkitTransform:this.state.translate,            
            msTransform:this.state.translate ,           
        }

        var menu = this.renderColumnMenu(props, state, column, index)

        if (state.dragColumn && state.shiftIndexes && state.shiftIndexes[index]){
            style.left = state.shiftSize
        }

        if (state.dragColumn === column){
            className += ' z-drag z-over'
            style.zIndex = 1
            style.left = state.dragLeft || 0
        }


        var filterIcon = props.filterIcon || <svg version="1.1" style={{transform: 'translate3d(0,0,0)', height:'100%', width: '100%', padding: '0px 2px' }} viewBox="0 0 3 4">
                                <polygon points="0,0 1,2 1,4 2,4 2,2 3,0 " style={{fill: props.filterIconColor,strokeWidth:0, fillRule: 'nonZero'}} />
                            </svg>

    
        let filter = null;
        if (column.sortable)
        {
            filter  = column.filterable?
                        <div className="z-show-filter" 
                            onClick={this.handleFilterMouseUp.bind(this, column)}
                            onTouchEnd={this.handleFilterMouseUp.bind(this, column)}>
                            {filterIcon}
                        </div>
                        :
                        null
        }

        var resizer = column.resizable?
                <Draggable start={{x: this.state.startpos, y: 0}} axis="x" zIndex={120} dragx={0} onDrag={this.handleDrag} onStart={this.onStartResize.bind(this, column)} onStop={this.onStopResize.bind(this, column)}>
                  <div style= {{position: 'absolute', top: '0', right: '0',  borderLeftStyle: 'none', borderLeftWidth: 'thin', cursor: 'col-resize', width: '6', height: '100%', zIndex:'120'}} zIndex={120}> </div> 
                </Draggable> : null;

        if (column.sortable){
            text = <span >{text}<span className="z-icon-sort-info" /></span>

            var sortInfo = getColumnSortInfo(column, props.sortInfo)

            if (sortInfo && sortInfo.dir){
                className += (sortInfo.dir === -1 || sortInfo.dir === 'desc'?
                                ' z-desc':
                                ' z-asc')
            }

            className += ' z-sortable'
        }

        if (filter){
            className += ' z-filterable'
        }

        if (state.mouseOver === column.name && !resizing){
            className += ' z-over'
        }

        if (props.menuColumn === column.name){
            className += ' z-active'
        }

        className += ' z-unselectable'

        var events = {}

        events.onMouseDown = this.handleMouseDown.bind(this, column)
        events.onMouseUp = this.handleMouseUp.bind(this, column)
        events.onClick = this.handleClick.bind(this, column)

        events.onTouchStart = this.handleMouseDown.bind(this, column)
        events.onTouchEnd = this.handleMouseUp.bind(this, column)
        events.onTap = this.handleClick.bind(this, column)

  

        var tek =   (column.sorttype == 'A' && column.type == 'STR')? "fa fa-sort-alpha-asc" :
                    (column.sorttype == 'D' && column.type == 'STR')? "fa fa-sort-alpha-desc" :
                    (column.sorttype == 'A' && column.type == 'NUM')? "fa fa-sort-numeric-asc" :                    
                    (column.sorttype == 'D' && column.type == 'NUM')? "fa fa-sort-numeric-desc" :                    
                    (column.sorttype == 'A' && column.type == 'DAT')? "fa fa-sort-amount-asc" :                    
                    (column.sorttype == 'D' && column.type == 'DAT')? "fa fa-sort-amount-desc" :                    
                    "fa fa-sort";
    


       if (column.sortable)
       {
         filter = (<div id='ButDiv' className="Sorteer" style={{position: 'absolute', float:'right',right: '2px'}}>
                    <IconButton iconClassName={tek} mini={true} size={12} 
                    id='But'
                    onMouseLeave={this.IconLeave}  
                    onMouseEnter={this.IconEnter}                    
                    iconStyle={{color:'rgb(1, 115, 199)', iconHoverColor:'darkslateblue',fontSize:'small'}}
                    Column={column}
                    primary={true} 
                    style={{float:'right'}}
                    Left='0%'/> </div>);
      }
       
      var keydiv = 'Div'+column.name;
      var keydgg = 'Dgg'+column.name;


        return (

        <div key={keydiv} style= {{display:'-webkit-box',borderStyle:'none', borderColor: 'rgb(1, 115, 199)',position: 'relative',borderWidth:'thin'}}> 
            <Draggable key={keydgg} axis="x" zIndex={100}  onDrag={this.handleDragDrag} onStart={this.onStartDrag.bind(this, column)} onStop={this.onStopDrag.bind(this, column)}>
                <Cell
                key={column.name}
                id={column.name}
                contentPadding={props.cellPadding}
                columns={props.columns || []}
                index={index}
                column={props.columns[index]}
                className={className}
                style={style}
                text={text}
                header={true}
                onContextMenu = {this.handleConM}
                onMouseOut={this.handleMouseOut.bind(this, column)}
                onMouseOver={this.handleMouseOver.bind(this, column)}
                {...events}>
                {filter}
                </Cell>

            </Draggable>
            {resizer}
        </div>
        )
    },

    IconLeave: function(){
        this.setState({iconfoc:false})
    },
    IconEnter: function()
        {this.setState({iconfoc:true})
    },

    toggleSort: function(column){
        var sortInfo       = asArray(clone(this.props.sortInfo))
        var columnSortInfo = getColumnSortInfo(column, sortInfo)

        if (!columnSortInfo){
            columnSortInfo = {
                name: column.name,
                type: column.type,
                fn  : column.sortFn
            }

            sortInfo.push(columnSortInfo)
        }

        if (typeof column.toggleSort === 'function'){
            column.toggleSort(columnSortInfo, sortInfo)
        } else {

            var dir     = columnSortInfo.dir
            var dirSign = dir === 'asc'? 1 : dir === 'desc'? -1: dir
            var newDir  = dirSign === 1? -1: dirSign === -1?  0: 1

            columnSortInfo.dir = newDir

            if (!newDir){
                sortInfo = removeColumnSort(column, sortInfo)
            }
        }

        ;(this.props.onSortChange || emptyFn)(sortInfo)
    },

    handleConM(event) {
        event.preventDefault();
        var x = event.clientX;
        var y = event.clientY;
       if (this.props.OpenMenu) {this.props.OpenMenu(x,y)}        
        return false;        
    },

    renderColumnMenu: function(props, state, column, index){
        if (!props.withColumnMenu){
            return
        }

        var menuIcon = props.menuIcon || <svg version="1.1" style={{transform: 'translate3d(0,0,0)', height:'100%', width: '100%', padding: '0px 2px' }} viewBox="0 0 3 4">
                <polygon points="0,0 1.5,3 3,0 " style={{fill: props.menuIconColor,strokeWidth:0, fillRule: 'nonZero'}} />
            </svg>

        return <div className="z-show-menu" onMouseUp={this.handleShowMenuMouseUp.bind(this, props, column, index)}>
            {menuIcon}
        </div>
    },

    handleShowMenuMouseUp: function(props, column, index, event){
        event.nativeEvent.stopSort = true

        this.showMenu(column, event)
    },

    showMenu: function(column, event){

        var menuItem = function(column){
            var visibility = this.props.columnVisibility

            var visible = column.visible

            if (column.name in visibility){
                visible = visibility[column.name]
            }

            return {
                cls     : visible?'z-selected': '',
                selected: visible? <span style={{fontSize: '0.95em'}}>✓</span>: '',
                label   : column.title,
                fn      : this.toggleColumn.bind(this, column)
            }
        }.bind(this)

        function menu(eventTarget, props){

            var columns = props.gridColumns

            props.columns = [ 'selected', 'label']
            props.items = columns.map(menuItem)
            props.alignTo = eventTarget
            props.alignPositions = [
                'tl-bl',
                'tr-br',
                'bl-tl',
                'br-tr'
            ]
            props.style = {
                position: 'absolute'
            }

            var factory = this.props.columnMenuFactory || ReactMenu

            var result = factory(props)

            return result === undefined?
                    ReactMenu(props):
                    result
        }

        this.props.showMenu(menu.bind(this, event.currentTarget), {
            menuColumn: column.name
        })
    },

    showFilterMenu: function(column, event){

        function menu(eventTarget, props){

            var defaultFactory = this.props.filterMenuFactory
            var factory = column.filterMenuFactory || defaultFactory

            props.columns = ['component']
            props.column = column
            props.alignTo = eventTarget
            props.alignPositions = [
                'tl-bl',
                'tr-br',
                'bl-tl',
                'br-tr'
            ]
            props.style = {
                position: 'absolute'
            }

            var result = factory(props)

            return result === undefined?
                        defaultFactory(props):
                        result
        }

        this.props.showMenu(menu.bind(this, event.currentTarget), {
            menuColumn: column.name
        })
    },

    toggleColumn: function(column){
        this.props.toggleColumn(column)
    },

    hideMenu: function(){
        this.props.showColumnMenu(null, null)
    },

    handleResizeMouseDown: function(column, event){
        setupColumnResize(this, this.props, column, event)

        //in order to prevent setupColumnDrag in handleMouseDown
        // event.stopPropagation()

        //we are doing setupColumnDrag protection using the resizing flag on native event
        
        if (event.nativeEvent){
            event.nativeEvent.resizing = true
        }
    },

    handleFilterMouseUp: function(column, event){
        event.nativeEvent.stopSort = true

        this.showFilterMenu(column, event)
        // event.stopPropagation()
    },

    handleSortMouseUp: function(event, column){
       if (event.nativeEvent){
            event.nativeEvent.sorting = true;
            this.state.sorteren = true;
        }        
        this.state.sorteren = true;
        if (this.props.Sorteer) {this.props.Sorteer(column.name)}
    },

    handleMouseUp: function(column, event){
        

        if (this.state.dragging){
            return
        }

        if (this.state.resizing){
            return
        }

        if (event && event.nativeEvent && event.nativeEvent.stopSort){
            return
        }

        if (event && event.nativeEvent && event.nativeEvent.sorting){
            return
        }


        if (column.sortable){
//            this.toggleSort(column)
        }
    },

    handleClick: function(column, event){

        if (event && event.nativeEvent && event.nativeEvent.sorting){
            return
        }

        if (column.sortable){
            this.toggleSort(column)
        }
    },


    handleMouseOut: function(column){
        this.setState({
            mouseOver: false
        })
    },

    handleMouseOver: function(column){
        this.setState({
            mouseOver: column.name
        })
    },

    handleMouseDown: function(column, event){
        if (event && event.nativeEvent && event.nativeEvent.resizing){
            return
        }

        if (!this.props.reorderColumns){
            return
        }

        setupColumnDrag(this, this.props, column, event)
    },

    onResizeDragStart: function(config){
        this.setState({
            resizing: true
        })
        this.props.onColumnResizeDragStart(config)
    },

    onResizeDrag: function(config){
        this.props.onColumnResizeDrag(config)
    },

    onResizeDrop: function(config, resizeInfo, event){
        this.setState({
            resizing: false
        })

        this.props.onColumnResizeDrop(config, resizeInfo)
    },

    prepareProps: function(thisProps){
        var props = {}

        assign(props, thisProps)

        this.prepareClassName(props)
        this.prepareStyle(props)

        var columnMap = {}

        ;(props.columns || []).forEach(function(col){
            columnMap[col.name] = col
        })

        props.columnMap = columnMap

        return props
    },

    prepareClassName: function(props){
        props.className = props.className || ''
        props.className += ' ' + props.defaultClassName

        if (this.state.dragging){
            props.className += ' ' + props.draggingClassName
        }
    },

    prepareStyle: function(props){
        var style = props.style = {}

        assign(style, props.defaultStyle)
    }
})
