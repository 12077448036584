// required to safely use babel/register within a browserify codebase

"use strict";

exports.__esModule = true;

require("../../polyfill");

exports["default"] = function () {};

module.exports = exports["default"];