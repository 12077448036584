const React = require('react');
const ReactDOM = require('react-dom');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const TextField = require('../matui/text-field.jsx');
const Checkbox = require('../matui/checkbox.jsx');
const IconButton = require('../matui/icon-button.jsx');
const IconButOver = require('../matui/icon_but_over.jsx');
const FontIcon = require('../matui/font-icon.jsx');
const Zoekpopup = require('./zoekpopup.jsx');

const AlgFuncUpd = require('../functions/algemeen');
var ReactTags = require('react-tag-input').WithContext;
const Mattag = require('../matui/mattag.jsx');
const Taglist = require('../matui/lists/taglist.jsx');
const SubLijst = require('./sublijstnw.jsx');
var Button = require("react-bootstrap").Button;
const FlatButton = require('../matui/flat-button.jsx');

const DateTime = require('../matui/utils/date-time');
const Snackbar = require('../matui/snackbar.jsx');
var Panel = require("react-bootstrap").Panel;
var PanelGroup = require("react-bootstrap").PanelGroup;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Nav = require("react-bootstrap").Nav;
var NavItem = require("react-bootstrap").NavItem;
const Contstafunc = require('./cont_func.jsx');
var Image = require("react-bootstrap").Image;
var MenuItem = require("react-bootstrap").MenuItem;

const PopupSubFixt = require('./popsubfixt.jsx');
const PopupCopyFixt = require('./popcopyfixt.jsx');
const PopupSlider = require('./popdefslider.jsx');
const PopupChTxt = require('./popchgtxtfixt.jsx');

const Slider = require('../matui/slider.jsx');
const ClsFixt = require('./cls_fixtures.jsx');
const CircularProgress = require('../matui/circular-progress.jsx');

const Dialog = require('../matui/dialog.jsx');

//import {Radar} from 'react-chartjs-2';

let Libraryvorm = React.createClass({
  mixins: [PureRenderMixin],
  contextTypes:{
    router: React.PropTypes.object    
  },
  getInitialState() {
    return {
      selllamp:'',
      tagkey:0,
      circpro:null,
      selcol:0,
      slidtxt:'',
      adrname:'',
      adrmanuf:'',
      selectie:1,
      selchan:0,
      channame:'',
      accountid:'',
      slidmaindata:[],
      lichtData:[],
      sliddata1:[],
      sliddata2:[],
      sliddata3:[],
      sliddata4:[],
      sliddata5:[],
      sliddata6:[],
      sliddata7:[],
      sliddata8:[],
      sliddata9:[],
      sliddata10:[],
      sliddata11:[],
      sliddata12:[],
      sliddata13:[],
      sliddata14:[],
      bgcolor1:'rgba(47, 165, 199, 0.85)',
      bgcolor2:'rgba(47, 165, 199, 0.85)',
      bgcolor3:'rgba(47, 165, 199, 0.85)',
      bgcolor4:'rgba(47, 165, 199, 0.85)',
      bgcolor5:'rgba(47, 165, 199, 0.85)',
      bgcolor6:'rgba(47, 165, 199, 0.85)',
      bgcolor7:'rgba(47, 165, 199, 0.85)',
      bgcolor8:'rgba(47, 165, 199, 0.85)',
      bgcolor9:'rgba(47, 165, 199, 0.85)',
      bgcolor10:'rgba(47, 165, 199, 0.85)',
      bgcolor11:'rgba(47, 165, 199, 0.85)',
      bgcolor12:'rgba(47, 165, 199, 0.85)',
      bgcolor13:'rgba(47, 165, 199, 0.85)',
      bgcolor14:'rgba(47, 165, 199, 0.85)',
      bgcolor15:'rgba(47, 165, 199, 0.85)',
      bgcolor16:'rgba(47, 165, 199, 0.85)',
      bgcolor17:'rgba(47, 165, 199, 0.85)',
      bgcolor18:'rgba(47, 165, 199, 0.85)',
      bgcolor19:'rgba(47, 165, 199, 0.85)',
      bgcolor20:'rgba(47, 165, 199, 0.85)',
      bgcolor21:'rgba(47, 165, 199, 0.85)',
      bgcolor22:'rgba(47, 165, 199, 0.85)',
      bgcolor23:'rgba(47, 165, 199, 0.85)',
      slidvis1:false,
      slidvis2:false,
      slidvis3:false,
      slidvis4:false,
      slidvis5:false,
      slidvis6:false,
      slidvis7:false,
      slidvis8:false,                                          
      slidvis9:false,
      slidvis10:false,
      slidvis11:false,
      slidvis12:false,
      slidvis13:false,
      slidvis14:false,
      slidcol1:'rgb(47, 165, 199)',      
      slidcol2:'rgb(47, 165, 199)',      
      slidcol3:'rgb(47, 165, 199)',      
      slidcol4:'rgb(47, 165, 199)',      
      slidcol5:'rgb(47, 165, 199)',      
      slidcol6:'rgb(47, 165, 199)',      
      slidcol7:'rgb(47, 165, 199)',      
      slidcol8:'rgb(47, 165, 199)',      
      slidcol9:'rgb(47, 165, 199)',      
      slidcol10:'rgb(47, 165, 199)',      
      slidcol11:'rgb(47, 165, 199)',      
      slidcol12:'rgb(47, 165, 199)',      
      slidcol13:'rgb(47, 165, 199)',      
      slidcol14:'rgb(47, 165, 199)',      
      slidmax1:100,      
      slidmax2:100,      
      slidmax3:100,      
      slidmax4:100,      
      slidmax5:100,      
      slidmax6:100,      
      slidmax7:100,      
      slidmax8:100,      
      slidmax9:100,      
      slidmax10:100,      
      slidmax11:100,      
      slidmax12:100,      
      slidmax13:100,      
      slidmax14:100,
      adresdata: [],   
      channeldata: [],
      collapsible:true,
      seltxtcol:0,
      slider2:null,
      slidmax:0,
      dispmeer:'none',
      popadres:null,
      popslider:null,
      popsubfixt:null,
      popcopyfixt:null,      
      popupcons:null,
      popupchtxt:null,
      txtdata:null,  
      lichtkol:[{"datakey":"fixture_text","label":"","width":"40","sortable":"0","colsorttype":"Str","type":"","cellrender":"Y"},
          {"datakey":"fixture_low","label":"Level Low","width":"25","sortable":"0","colsorttype":"","type":"","cellrender":"Y"},
          {"datakey":"fixture_high","label":"Level High","width":"25","sortable":"0","colsorttype":"","type":"","cellrender":"Y"},],
      txtzien:(<div style={{marginTop: 'auto',marginBottom: 'auto', display: 'inline-block'}}><span style={{fontSize:'10px',fontWeight: 'normal',color: '#2fa5c7'}} >Meer zien</span>
                <FontIcon
                  className="fa fa-sort-desc"
                  style={{color:'#2fa5c7', iconHoverColor:'darkslateblue',fontSize:'15px',paddingLeft: '5px',bottom:'2px'}}>
                </FontIcon> </div>),
      tagpadtop:'0px',
      jaar:0,
      expa:false,      
      ConsId:'',
      vorm:'CONS',
      sqldata: {param: 0},   
      edit:0,
      activkey:1,
      err_naam:'',
      updatecol:'',
      updatetxt:'', 
      data: {},      
      route: '',
      searchkey: 'id',
      height: (window.innerHeight).toString(),
      data:{id:0},
      HListData:[],
      ListData:[],
      dgcolumns:[],      
      colFilter: [],
      immuData:Immutable.List(),
      contcons:Immutable.List(),
      dragstart:0,
    };
  },
  componentWillMount() {
    let nw_date = new Date();
    this.state.jaar = nw_date.getFullYear();
  },
  componentDidMount() {
    this.setState({activkey:1})
    if (this.props.popup && this.props.data)
    {
        this.zetedit(this.props.data.id);
        this.state.data.id = this.props.data.id;
    }    
  },
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data != null)
    {
      let data = nextProps.data;
      if (data.id)
      {
        if (data.id != this.state.data.id)
        {
          this.zetedit(data.id);
          this.state.data.id = data.id;
        }
      }
    }
  },

  render() {
    let mwdth = (screen.width < 750) ? '100%' : '90%';
    let wdth = (screen.width < 750) ? '100%' : '90%';
    let cmwdth = (screen.width < 750) ? '100%' : '98%';
    let cwdth = (screen.width < 750) ? '100%' : '98%';
    let clft = (screen.width < 750) ? '0' : '5%';
    var h = window.innerHeight * .8;
    var ch = window.innerHeight * .78;
    let formatChars = {"9": "[0-9,.-]",};

    var maxhdial = window.innerHeight + 'px';
    var maxhdialrol = (window.innerHeight - 250) + 'px';
    var maxhintern = (window.innerHeight - 110) + 'px';


    var slidertxt1 = (this.state.selchan == 1) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '-65px auto 0px -22px', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px', textAlign: 'center',fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt2 = (this.state.selchan == 2) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt3 = (this.state.selchan == 3) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt4 = (this.state.selchan == 4) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt5 = (this.state.selchan == 5) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt6 = (this.state.selchan == 6) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt7 = (this.state.selchan == 7) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt8 = (this.state.selchan == 8) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt9 = (this.state.selchan == 9) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt10 = (this.state.selchan == 10) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt11 = (this.state.selchan == 11) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt12 = (this.state.selchan == 12) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt13 = (this.state.selchan == 13) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt14 = (this.state.selchan == 14) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;


    let icont = (this.state.seltxtcol > 0) ? (<IconButOver
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
                  tooltip="Change text selected rows"
                  iconClassName="fa fa-newspaper-o" 
                  onClick={this.TextClick}/>) : null;


    let icdel = (this.state.seltxtcol > 0) ? (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                  iconClassName="fa fa-trash-o" 
                  tooltip="Delete selected rows" 
                  onClick={this.DeleteClick}/>) : null;

    let iccopy = (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                  iconClassName="fa fa-files-o" 
                  tooltip="Copy fixture" 
                  onClick={this.CopyClick}/>);


    let icexport = (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                  iconClassName="fa fa-file-excel-o" 
                  tooltip="Export Library" 
                  onClick={this.handleExport}/>);

    
    let vld_tags = (<Taglist 
          ref='KennisLijst'
          style={{paddingTop:this.state.tagpadtop}}
          tagkey={this.state.tagkey}
          LabClick={this.RowKlik}
          listItems={this.state.ListData}
          extraheight='10px'
          nodrag={true}
          menu={false}
          zoeken={false}
          onDeleteColumn={this.deletetag}>
          </Taglist>);

    let vldzoekpopup = (<Zoekpopup  id='zoekpopup1' route = '' zindex={9999998} notoolb={true} klik={this.klikzkpop} sluiten={this.sluitzkpop} paddingtop={true} third={true} ref='RefZoekPopup'></Zoekpopup>);
    

    let tot_zichtpop = null;
    let tot_zicht1 = null;
    let tot_zicht2 = null;
    let brgrrechts = (this.props.brgrrechts) ? (this.props.brgrrechts * .8) :296;

    // Definieren van de headers
    var w = window.innerWidth - 5;
    var h = window.innerHeight - 300;
    let beeld = screen.width;
    let beeldh = screen.height;
    var winbreed = window.innerWidth;

    let gridbreed = (winbreed > 1850) ? ((winbreed / 100) * 95) -1050 : ((winbreed / 100) * 95) -750;   
    if (winbreed > 1850 && gridbreed > 900) {gridbreed = 850}
    if (winbreed < 1850 && gridbreed > 2000) {gridbreed = 1650}
    if (gridbreed < 1000) {gridbreed = 650}

    let lst_licht = (this.state.adrname.length > 1 && this.state.channame.length > 1 && this.state.selchan > 0 && this.state.channeldata.length > 0 && this.state.lichtData.length > 0 ) ? (<SubLijst ref="LichtLijst" id='LichtLijstRef' route='lichtlijst'  
        hdata={{route:'lichtlijst', data:this.state.lichtData, kol:this.state.lichtkol}} height={300} mhoog={300} breed={300} hoog={300} 
        sleutel={this.state.channeldata[this.state.selchan -1].id} behandel={false} zoeken={false} ctrlklik={this.CtrlKlik} klik={this.KlikAct} klikken={this.KlikKennisM}/>) : null;

      // bovenste paneel
    let PTitsocial = (this.state.edit == 0 && beeld > 749 ) ? 
                    (<h3 className="panel-title">
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                        {icexport}
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col></h3>) :
                      (this.state.edit == 0 && beeld < 750 ) ? 
                    (<h3 className="panel-title">
                      <Col xs={4} md={4} sm={4} >
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                        {icexport}
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                      </Col></h3>) : null;
    let PTitLicht = (<h3 className="panel-title">
                      <Col xs={3} md={3} sm={3} >
                        {icdel}
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                        {icont}
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col></h3>) ;
    let PTitedit = null;
    // Definitie knoppen
    // einde definitie knoppen
    let PTitadr = (this.state.adrname.length > 2) ? (<h3 className="panel-title"><span>{this.state.adrname} - {this.state.adrmanuf}</span>
          <IconButOver
            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
            iconClassName="fa fa-plus" 
            onMouseLeave={this.MouseLeave}
            onMouseEnter={this.MouseEnter}
            tooltip="Add new fixture" 
            onClick={this.LampZoek}/>
      </h3>) : (<h3 className="panel-title"><span></span>
          <IconButOver
            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
            iconClassName="fa fa-plus" 
            onMouseLeave={this.MouseLeave}
            onMouseEnter={this.MouseEnter}
            tooltip="Add new fixture" 
            onClick={this.LampZoek}/>
      </h3>);    

    let PTitchan = (this.state.adrname.length > 1 && this.state.channame.length > 1 ) ? (<h3 className="panel-title"><span>Channels ({this.state.channame}) </span>
                            <IconButOver
                                style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
                                iconClassName="fa fa-pencil-square-o" 
                                tooltip="Aanpassen" 
                                onMouseLeave={this.MouseLeave}
                                onMouseEnter={this.MouseEnter}
                                onClick={this.EditSlid}/>
                                </h3>) : 
                              (<h3 className="panel-title"><span>Channels </span></h3>) ;    


    let panlicht = (<Panel  header={PTitLicht} bsStyle="blok" defaultExpanded style={{background: '#fff', width:'100%'}}>
                      <Row className="show-grid">
                        <Col xs={12} md={12} sm={12} >
                           {lst_licht}
                        </Col>
                      </Row>
                    </Panel>);



    let pan2deel1 = (<Row className="show-grid">
                          <Panel  header={PTitadr} bsStyle="blok" defaultExpanded style={{marginTop: '5px', background: '#fff', width:'100%'}}>
                            <Row className="show-grid">
                              <Col xs={12} md={12} sm={12} >
                                {vld_tags}
                              </Col>                             
                            </Row>
                            {this.state.circpro}
                          </Panel>
                      </Row>);

    // sliders

    var slider1 = (this.state.slidvis1) ? (<div style={{display: 'flex',height: 300,flexDirection: 'row',justifyContent: 'space-around'}} id= 'slid1' tabindex='0' onFocus= {(event)=>this.SliderClick(1)} >
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol1}
            scolor={this.state.slidcol1}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax1}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onChange={this.verander} />
        </div>) : null;
    var slider2 = (this.state.slidvis2) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol2}
            scolor={this.state.slidcol2}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax2}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(2)}
            onChange={this.verander} />
        </div>) : null;
    var slider3 = (this.state.slidvis3) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol3}
            scolor={this.state.slidcol3}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax3}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(3)}
            onChange={this.verander} />
        </div>) : null;
    var slider4 = (this.state.slidvis4) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol4}
            scolor={this.state.slidcol4}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax4}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(4)}
            onChange={this.verander} />
        </div>) : null;
    var slider5 = (this.state.slidvis5) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol5}
            scolor={this.state.slidcol5}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax5}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(5)}
            onChange={this.verander} />
        </div>) : null;
    var slider6 = (this.state.slidvis6) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol6}
            scolor={this.state.slidcol6}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax6}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(6)}
            onChange={this.verander} />
        </div>) : null;
    var slider7 = (this.state.slidvis7) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol7}
            scolor={this.state.slidcol7}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax7}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(7)}
            onChange={this.verander} />
        </div>) : null;
    var slider8 = (this.state.slidvis8) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol8}
            scolor={this.state.slidcol8}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax8}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(8)}
            onChange={this.verander} />
        </div>) : null;
    var slider9 = (this.state.slidvis9) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol9}
            scolor={this.state.slidcol9}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax9}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(9)}
            onChange={this.verander} />
        </div>) : null;
    var slider10 = (this.state.slidvis10) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol10}
            scolor={this.state.slidcol10}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax10}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(10)}
            onChange={this.verander} />
        </div>) : null;
    var slider11 = (this.state.slidvis11) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol11}
            scolor={this.state.slidcol11}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax11}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(11)}
            onChange={this.verander} />
        </div>) : null;
    var slider12 = (this.state.slidvis12) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol12}
            scolor={this.state.slidcol12}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax12}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(12)}
            onChange={this.verander} />
        </div>) : null;
    var slider13 = (this.state.slidvis13) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol13}
            scolor={this.state.slidcol13}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax13}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(13)}
            onChange={this.verander} />
        </div>) : null;
    var slider14 = (this.state.slidvis14) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol14}
            scolor={this.state.slidcol14}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax14}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(14)}
            onChange={this.verander} />
        </div>) : null;
    // einde slieders

    let pan2deel2 =  (this.state.adrname.length > 1) ? 
        (<Row className="show-grid">
                        <Panel  header={PTitchan} bsStyle="blok" defaultExpanded collapsible={this.state.collapsible} style={{marginTop: '0px', background: '#fff', width:'100%'}}>
                            <Row className="show-grid">
                              <Col xs={6} md={6} sm={6} style={{paddingRight: '0px'}} >
                                <Col xs={1} md={1} sm={1} >
                                  {slider1}
                                  {slidertxt1}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider2}
                                  {slidertxt2}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider3}
                                  {slidertxt3}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider4}
                                  {slidertxt4}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider5}
                                  {slidertxt5}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider6}
                                  {slidertxt6}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider7}
                                  {slidertxt7}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider8}
                                  {slidertxt8}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider9}
                                  {slidertxt9}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider10}
                                  {slidertxt10}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider11}
                                  {slidertxt11}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider12}
                                  {slidertxt12}
                                </Col>                             
                              </Col>                             
                              <Col xs={6} md={6} sm={6} style={{paddingLeft: '0px'}}>
                                <Col xs={1} md={1} sm={1} >
                                  {slider13}
                                  {slidertxt13}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider14}
                                  {slidertxt14}
                                </Col>                             
                                <Col xs={10} md={10} sm={10} >
                                  {panlicht}
                                </Col>                             
                              </Col>                             
                            </Row>
                          </Panel>
                      </Row>) : null;

    let pan2 = (
                  <div id='cns_hfdpan' style={{marginBottom: '16px', marginTop:'70px', padding: '24px', wordWrap: 'break-word', boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2)', display: 'block', backgroundColor: '#fff', borderRadius: '2px',position: 'relative', listStyle: 'none', outline: '0', fontSize: '100%', verticalAlign: 'baseline', boxSizing: 'inherit',}}>
                     <div id='cns_hfdpan1' style={{margin:'0',padding:'0',border:'0',outline:'0',fontSize:'100%',verticalAlign:'baseline',background: '0 0', boxSizing: 'inherit', display: 'block'}}>
                       <div id='card_header1' style={{margin: '-85px auto 0', position: 'relative', width: 'auto'}}>
                            <div id='photo_header1' style={{backgroundColor:'rgba(47, 165, 199, 0.85)',  textAlign: 'center', border: '4px solid #fff', boxSizing: 'content-box', margin: 'auto', position: 'relative', boxShadow: 'inset 0 1.5px 3px 0 rgba(0,0,0,.15), 0 1.5px 3px 0 rgba(0,0,0,.15)',marginTop:'-65px',width:'128px',height:'128px',borderRadius:'50%'}}>
                            <div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox', display: 'flex', WebkitFlexDirection: 'column', marginTop:'10px',
                                    MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '88px', width: '88px', borderRadius: '50%'}}>
                              <div dir="ltr" style={{fontSize: '14px',fontWeight: '400',lineHeight: '16px;margin-bottom: 2px',color: 'white',width: '128px',height: 'auto', borderRadius: '50%', marginTop: '20px'}}>{this.state.data.name}</div>
                            </div>
                          </div>
                          <div id='card_header1' style={{left: '24px', position: 'absolute', top: '75px'}}>
                            {PTitsocial}
                          </div>
 
                          <div id='carbody1' style={{position: 'relative',marginBottom:'30px'}}>
                            <div style={{padding: '0 40px',marginBottom:'0'}}>
                              {pan2deel1}
                              {pan2deel2}
                             </div>

                          </div>
                          <div id='card_header1' style={{right: '24px', position: 'absolute', top: '75px'}}>
                            {PTitedit}
                          </div>
                       </div>
                     </div>
                  </div>)
    // einde bovenste paneel
    // Gedeelte voor lezen documenten
    let voet = null;
    // einde enkel voor mobile
    tot_zicht2 = (
      <div className='rowlist' style={{marginLeft:'15px', marginBottom: '60px', marginRight: '10px'}}>
        {pan2}
      </div>);    

    tot_zichtpop = (<div>
          {vldzoekpopup}

            {this.state.popupchtxt}
            {this.state.popsubfixt}
            {this.state.popcopyfixt}
            {this.state.popadres}
            {this.state.popslider}
          </div>);
    let totzicht = (this.props.popup) ? (<div id='consvdiv' style={{display:'block',maxHeight:maxhintern,overflowY:'scroll'}}>
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol, bottom:'60px'}}
          message={this.state.updatetxt}/>
        {tot_zichtpop}
        {tot_zicht2}
      </div>) : (<div id='consvdiv' style={{display:'block'}}>
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol, bottom:'60px'}}
          message={this.state.updatetxt}/>
        {tot_zichtpop}
        {tot_zicht2}
      </div>)  ;

    // Einde opbouw 
    let terugwaarde = (this.props.popup) ? 
      (<Dialog 
        ref="dafwcontrole"
        title="Detail Library"
        second={true}
        sluiten={this.sluiten}
        onDismiss={this.zetsluiten}
        style={{fontSize: '100%', width:wdth, maxWidth:mwdth}}
        contentStyle={{width:cwdth, maxWidth:cmwdth, left:clft,maxHeight:maxhdial,overflowY:'visible'}}
        footer= {voet}
        id="actcont1"
        modal={true}
        hidetoolb={true}        
        notoolb={true}
        paddingtop={this.props.paddingtop}
        zindex={this.props.zindex}        
        openImmediately={false}
        repositionOnUpdate={false}>
        {totzicht}
      </Dialog>) : (<div>{totzicht}</div>);

    return (<div>{terugwaarde}</div>)
  },  
  // Algemene funkties
  sluiten(data){
    App.master.zethfdmen(1);
    if (this.props.Sluiten) {this.props.Sluiten(data)}
  },
  zetsluiten() {
    App.master.zethfdmen(1);
    if (this.props.Sluiten) {this.props.Sluiten()}
  },

  MouseLeave(){
    this.setState({collapsible:true});
  },
  MouseEnter(){
    this.setState({collapsible:false});
  },
  zetedit(hskey){
    let skey = hskey;
    if (this.isMounted)
    {
      if (App.master.state.AppHfdMenKeuze)
      {
        if (App.master.state.AppHfdMenKeuze.FiltData) {   
          skey = App.master.state.AppHfdMenKeuze.FiltData[0].id;
        }
        App.master.setState({AppHfdMenKeuze:null});
      }
      if (this.refs.dafwcontrole)  {this.refs.dafwcontrole.show()}

      this.setState({slider2:null});
      this.setState({sleutel:skey});
      this.setState({seltxtcol:0});
      ClsFixt.UitvSQL({id:skey},'GetLibrary')
        .then(data => {
          this.setState({data:data,ListData:data.subdata});
          var vtagkey = this.state.tagkey + 1;
          if (this.refs.KennisLijst)
          {
            this.refs.KennisLijst.zetLijst(data.subdata, vtagkey) ;
          }
        })
        .catch(data => {
            return true;
      });
      this.setState({edit:0});
    }
  },
  deletetag(column) {
    ClsFixt.UitvSQL({libid:this.state.sleutel, id:column.hfdsleutel},'DelSubLib')
    .then(data => {
      this.setState({ListData:data.data});
      this.refs.KennisLijst.zetLijst(data.data, this.state.tagkey + 1);
      this.setState({adrname:'', selchan:0, selllamp:'', channame:'', slidmaindata:[],selcol:0,slidtxt:'',lichtData:[],channeldata:[]});
      this.forceUpdate();
    })
     .catch(data => {
    });    
    return;
  },


  zetadresdata(data) {
    this.setState({
      bgcolor1:data[0].kleur,
      bgcolor2:data[1].kleur,
      bgcolor3:data[2].kleur,
      bgcolor4:data[3].kleur,
      bgcolor5:data[4].kleur,
      bgcolor6:data[5].kleur,
      bgcolor7:data[6].kleur,
      bgcolor8:data[7].kleur,
      bgcolor9:data[8].kleur,
      bgcolor10:data[9].kleur,
      bgcolor11:data[10].kleur,
      bgcolor12:data[11].kleur,
      bgcolor13:data[12].kleur,
      bgcolor14:data[13].kleur,
      bgcolor15:data[14].kleur,
      bgcolor16:data[15].kleur,
      bgcolor17:data[16].kleur,
      bgcolor18:data[17].kleur,
      bgcolor19:data[18].kleur,
      bgcolor20:data[19].kleur,
      bgcolor21:data[20].kleur,
      bgcolor22:data[21].kleur,
      bgcolor23:data[22].kleur})

    return;
  },
  zetsliderdata(data) {
    var deferred = $.Deferred();
    this.setState({
      sliddata1:[],
      sliddata2:[],
      sliddata3:[],
      sliddata4:[],
      sliddata5:[],
      sliddata6:[],
      sliddata7:[],
      sliddata8:[],
      sliddata9:[],
      sliddata10:[],
      sliddata11:[],
      sliddata12:[],
      sliddata13:[],
      sliddata14:[],
      selcol:0,
      slidvis1:false,
      slidvis2:false,
      slidvis3:false,
      slidvis4:false,
      slidvis5:false,
      slidvis6:false,
      slidvis7:false,
      slidvis8:false,                                          
      slidvis9:false,
      slidvis10:false,
      slidvis11:false,
      slidvis12:false,
      slidvis13:false,
      slidvis14:false,
      slidcol1:'rgb(47, 165, 199)',      
      slidcol2:'rgb(47, 165, 199)',      
      slidcol3:'rgb(47, 165, 199)',      
      slidcol4:'rgb(47, 165, 199)',      
      slidcol5:'rgb(47, 165, 199)',      
      slidcol6:'rgb(47, 165, 199)',      
      slidcol7:'rgb(47, 165, 199)',      
      slidcol8:'rgb(47, 165, 199)',      
      slidcol9:'rgb(47, 165, 199)',      
      slidcol10:'rgb(47, 165, 199)',      
      slidcol11:'rgb(47, 165, 199)',      
      slidcol12:'rgb(47, 165, 199)',      
      slidcol13:'rgb(47, 165, 199)',      
      slidcol14:'rgb(47, 165, 199)',      
      slidmax1:100,      
      slidmax2:100,      
      slidmax3:100,      
      slidmax4:100,      
      slidmax5:100,      
      slidmax6:100,      
      slidmax7:100,      
      slidmax8:100,      
      slidmax9:100,      
      slidmax10:100,      
      slidmax11:100,      
      slidmax12:100,      
      slidmax13:100,      
      slidmax14:100})

    for (let rowIdx = 0; rowIdx <= data.length; rowIdx++) 
    {
      let slidrow = data[rowIdx];
      let color = (slidrow.color == '#FFFFFF') ? 'rgb(47, 165, 199)' : slidrow.color;
      if (slidrow.steps == 0) {slidrow.steps = 1};
      switch (rowIdx) {
        case 0:

          this.setState({slidvis1:true,
            slidcol1:color,
            slidmax1:slidrow.steps -1,
            sliddata1:slidrow.subdata,});
          break;
        case 1:
          this.setState({slidvis2:true,
            slidcol2:color,
            slidmax2:slidrow.steps -1,
            sliddata2:slidrow.subdata});
          break;
        case 2:
          this.setState({slidvis3:true,
            slidcol3:color,
            slidmax3:slidrow.steps -1,
            sliddata3:slidrow.subdata});
          break;
        case 3:
          this.setState({slidvis4:true,
            slidcol4:color,
            slidmax4:slidrow.steps -1,
            sliddata4:slidrow.subdata});
          break;
        case 4:
          this.setState({slidvis5:true,
            slidcol5:color,
            slidmax5:slidrow.steps -1,
            sliddata5:slidrow.subdata});
          break;
        case 5:
          this.setState({slidvis6:true,
            slidcol6:color,
            slidmax6:slidrow.steps -1,
            sliddata6:slidrow.subdata});
          break;
        case 6:
          this.setState({slidvis7:true,
            slidcol7:color,
            slidmax7:slidrow.steps -1,
            sliddata7:slidrow.subdata});
          break;
        case 7:
          this.setState({slidvis8:true,
            slidcol8:color,
            slidmax8:slidrow.steps -1,
            sliddata8:slidrow.subdata});
          break;
        case 8:
          this.setState({slidvis9:true,
            slidcol9:color,
            slidmax9:slidrow.steps -1,
            sliddata9:slidrow.subdata});
          break;
        case 9:
          this.setState({slidvis10:true,
            slidcol10:color,
            slidmax10:slidrow.steps -1,
            sliddata10:slidrow.subdata});
          break;
        case 10:
          this.setState({slidvis11:true,
            slidcol11:color,
            slidmax11:slidrow.steps -1,
            sliddata11:slidrow.subdata});
          break;
        case 11:
          this.setState({slidvis12:true,
            slidcol12:color,
            slidmax12:slidrow.steps -1,
            sliddata12:slidrow.subdata});
          break;
        case 12:
          this.setState({slidvis13:true,
            slidcol13:color,
            slidmax13:slidrow.steps -1,
            sliddata13:slidrow.subdata});
          break;
        case 13:
          this.setState({slidvis14:true,
            slidcol14:color,
            slidmax14:slidrow.steps -1,
            sliddata14:slidrow.subdata});
          break;
      }
      if (rowIdx >= data.length -1)
      {
        return deferred.resolve('1');
      }      
    }
    return deferred.promise();    
  },  
  SliderClick(waarde) {
    if (waarde != this.state.selchan)
    {
      this.setState({seltxtcol:0,selchan:waarde, channame:this.state.channeldata[waarde -1].name, slidmaindata:this.state.channeldata[waarde -1].subdata,selcol:0,slidtxt:'',lichtData:this.state.channeldata[waarde -1].subdata,});
      ClsFixt.UitvSQL({id:this.state.channeldata[waarde -1].id},'GetSlider')
        .then(data => {
          if (this.state.channeldata[waarde -1].subdata.length > 0)
          {
            this.setState({selchan:waarde, channame:this.state.channeldata[waarde -1].name, slidmaindata:this.state.channeldata[waarde -1].subdata,selcol:0,slidtxt:this.state.channeldata[waarde -1].subdata[0].fixture_text,lichtData:this.state.channeldata[waarde -1].subdata});
          }
          else
          {
            this.setState({selchan:waarde, channame:this.state.channeldata[waarde -1].name, slidmaindata:this.state.channeldata[waarde -1].subdata,selcol:0,slidtxt:'',lichtData:this.state.channeldata[waarde -1].subdata});
          }
          if (this.refs.LichtLijst)
          {
            this.refs.LichtLijst.ZetSelect(0,this.state.channeldata[waarde -1].subdata); 
          }
        })
        .catch(data => {
            return true;
      });
    }
  },
  RowKlik(col) {
    this.setState({adrname:'', selchan:0, channame:'', slidmaindata:[],selcol:0,slidtxt:'',lichtData:[],channeldata:[]});
    ClsFixt.UitvSQL({id:col.lampid},'GetAdres')
      .then(data => {
        this.setState({channeldata:data.subdata});
        this.state.channeldata = data.subdata;
        this.setState({adrname:data.name, adrmanuf:data.accountname, accountid:data.accountid, selllamp:col.lampid});
        let that = this;
        var promise = this.zetsliderdata(data.subdata);
        promise.then(function(result) { 
            that.forceUpdate();
        });        
      })
      .catch(data => {
          return true;
    });
  },
  dragstop(e, ui) {
    this.setState({dragstart:0});
  },

  dragstart(e, ui) {
    this.setState({dragstart:1});
  },
  verander(e,value){
      let sdat = this.state.lichtData;
      if (!sdat) {return};
      if (sdat.length == 0) { return };
      if (this.state.selcol > sdat.length - 1) {this.state.selcol = sdat.length - 1};
      let vdat = sdat[this.state.selcol];
      if (!vdat) {return};
      vdat.checked = false;
      sdat[this.state.selcol] = vdat;
      this.state.selcol = value;
      vdat = sdat[value];
      vdat.checked = true;
      sdat[this.state.selcol] = vdat;
      this.setState({lichtData:sdat});
      this.setState({slidmaindata:sdat});
      this.setState({slidtxt:vdat.fixture_text});
      if (this.refs.LichtLijst)
      {
        this.refs.LichtLijst.ZetSelect(value,sdat); 
      }
  },
  txtsluiten(data) {
    this.setState({popupchtxt:null});
    if (this.props.popup)
    {
        App.master.zethfdmen(2);    
    }
    this.sluitactpopup(data);
  },
  adressluiten(data) {
    this.setState({popadres:null});
    if (this.state.selllamp.length > 2)
    {
      ClsFixt.UitvSQL({id:this.state.selllamp},'GetAdres')
      .then(data => {
        this.setState({channeldata:data.subdata});
        this.state.channeldata = data.subdata;
        this.setState({adrname:data.name, adrmanuf:data.accountname, accountid:data.accountid});
        let that = this;
        var promise = this.zetsliderdata(data.subdata);
        promise.then(function(result) { 
            that.forceUpdate();
        });        
      })
      .catch(data => {
          return true;
      });
    }
  },
  EditSlid(selectedKey) {
    let hdata = this.state.channeldata[this.state.selchan - 1];
    this.setState({channame:'', slidmaindata:[],selcol:0,slidtxt:''});
    hdata.accountid = this.state.accountid;
    this.setState({popslider:(<PopupSlider  id='popupcons' data={hdata} zindex={999999} Sluiten={this.slidsluiten}></PopupSlider>)});
    return;
  },
  slidsluiten(data) {
    this.setState({popslider:null});
    if (this.props.popup)
    {
        App.master.zethfdmen(2);    
    }
    if (this.state.selllamp.length > 2)
    {
      ClsFixt.UitvSQL({id:this.state.selllamp},'GetAdres')
      .then(data => {
        this.setState({channeldata:data.subdata});
        this.state.channeldata = data.subdata;
        this.setState({adrname:data.name, adrmanuf:data.accountname, accountid:data.accountid});
        let that = this;
        var promise = this.zetsliderdata(data.subdata);
        promise.then(function(result) { 
            let waarde = that.state.selchan;
            that.state.selchan = that.state.selchan - 1;
            that.SliderClick(waarde);
            that.forceUpdate();
        });        
      })
      .catch(data => {
          return true;
      });
    }
  },
  DeleteClick() {
    let hudata = {data:this.state.txtdata}
    this.setState({lichtData:[]});    
    ClsFixt.DelSubFixture(hudata)
    .then(data => {
      this.sluitactpopup(hudata);
    })
    .catch(data => {
        return true;
    });
  },
  zetfixgeg(){
    if (this.isMounted)
    {
      this.setState({slider2:null});
      let skey = this.state.sleutel; 
      this.setState({seltxtcol:0});
      ClsFixt.GetFixture({id:skey})
        .then(data => {
          this.setState({data:data});
          this.setState({lichtData:data.subdata});
          let maxslide = data.subdata.length - 1;
          this.setState({slidmax:maxslide});
          let sdat = data.subdata;
          let vdat = sdat[0];
          vdat.checked = true;
          sdat[0] = vdat;
          this.state.selcol = 0;
          this.setState({slidtxt:vdat.fixture_text});
          if (this.refs.LichtLijst)
          {
            this.refs.LichtLijst.ZetSelect(0,sdat); 
            var slider2 = (<div style={{display: 'flex',
              height: 400,
              flexDirection: 'row',
              justifyContent: 'space-around',}}>
              <Slider name="slider4" 
                  style={{height: 350}} 
                  axis="y" 
                  defaultValue={0} 
                  min={0}
                  max={maxslide}
                  step={1}
                  onDragStop={this.dragstop} 
                  onDragStart={this.dragstart} 
                  onChange={this.verander} />
              </div>);
            this.setState({slider2:slider2});

          }

        })
        .catch(data => {
            return true;
      });
      this.setState({edit:0});
    }
  },
  TextClick() {
    this.setState({popupchtxt:(<PopupChTxt  id='PopupChTxt' data={this.state.txtdata} zindex={999999} Sluiten={this.txtsluiten}></PopupChTxt>)})
    return;
  },
  CopyClick() {
    this.setState({popcopyfixt:(<PopupCopyFixt  id='PopupChTxt' data={this.state.data} zindex={999999} Sluiten={this.copysluiten}></PopupCopyFixt>)})
    return;
  },
  copysluiten(data) {
    this.setState({popcopyfixt:null});
    if (this.props.popup)
    {
        App.master.zethfdmen(2);    
    }
    if (this.props.loadDataNw && data) {this.props.loadDataNw(data,'CONT',this.state.activkey) }
  },


  // Einde funkties popup openen
  // functies activiteiten
  sluitactpopup(data) {
    this.setState({popsubfixt:null});
    let that = this;
    if (this.props.popup)
    {
        App.master.zethfdmen(2);    
    }
    if (this.state.selllamp.length > 2)
    {
      ClsFixt.UitvSQL({id:this.state.selllamp},'GetAdres')
      .then(data => {
        this.setState({channeldata:data.subdata});
        this.state.channeldata = data.subdata;
        this.setState({adrname:data.name, adrmanuf:data.accountname, accountid:data.accountid});
        var promise = this.zetsliderdata(data.subdata);
        promise.then(function(result) { 
            let waarde = that.state.selchan;
            that.state.selchan = that.state.selchan - 1;
            that.SliderClick(waarde);
            that.forceUpdate();
        });        
      })
      .catch(data => {
          return true;
      });
    }
  },
  CtrlKlik(data) {
    var index, column;    
    let arra;
    // controleren of arra && of || bevat
    let hudata = data;
    let ColFilter = ['checked'];
    let value = '2';
    var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = eval('row.'+filCol);            
          }
          else
          {
            arra = ''; 
          }
          if (typeof arra === 'string' || arra instanceof String)
          {

          }
          else
          {
            arra='';
          }
          if(arra.indexOf(value) >= 0)
          {
            return arra.indexOf(value) >= 0
          }
        }
      }) : data;
      this.setState({seltxtcol:filteredRows.length});
      this.setState({txtdata:data});
  },
  KlikAct(waarde, data) {
    this.setState({laden:1});
    let notoolb = (this.props.popup) ? true : false;
    this.setState({popsubfixt:(<PopupSubFixt  id='popupact1' data={data} notoolb={notoolb} route = 'activiteit' zindex={999999} Sluiten={this.sluitactpopup} ></PopupSubFixt>)})
  },  
  LampZoek() {
    this.setState({dispvoet:'none'});
    this.refs.RefZoekPopup.openen('GetAllLampen','Lampen');
  },
  klikzkpop(waarde,veld) {
    switch (veld) {
      case 'Lampen':
        let tdata = {libid:this.state.sleutel, lampid:waarde.id};
        ClsFixt.UitvSQL({libid:this.state.sleutel, lampid:waarde.id},'InSubLib')
        .then(data => {
          this.setState({ListData:data.data});
          this.refs.KennisLijst.zetLijst(data.data, this.state.tagkey + 1);
          this.forceUpdate();
        })
        .catch(data => {
        });
        break;
    }
    this.refs.RefZoekPopup.sluiten();
    this.setState({dispvoet:'inherit'});
  }, 
  sluitzkpop() {
    this.setState({dispvoet:'inherit'});
  }, 
  // einde functies activiteiten

  clickaccount() {
    if (this.props.clickaccount)
    {
      this.props.clickaccount({id:this.props.data.parent_account});
    }
    // Einde ophalen gegevens
  },

  InitExport(Data){
    let that = this;
    var deferred = $.Deferred();
    var ListData = Data;
    var Listlength = ListData.length;
    for (let rowIdx = 0; rowIdx < Listlength; rowIdx++) 
    {
      let Col = ListData[rowIdx];
      ClsFixt.UitvSQL({id:Col.lampid},'GetAdres')
        .then(data => {
          Col.subdata = data.subdata;
          ListData[rowIdx] = Col;

          if (rowIdx == Listlength -1)
          {
            return deferred.resolve(ListData);
          }
        })
        .catch(data => {
          if (rowIdx == Listlength -1)
          {
            return deferred.resolve(ListData);
          }
      });
    }
    return deferred.promise([]);
  },

  LusGetFix(Collook,CSV){
    var deferred = $.Deferred();
    ClsFixt.GetFixture({id:Collook.fixid})
      .then(data => {
        let sliddata = data.subdata;
        CSV += '\r\n'; 
        CSV += Collook.lookup;
        CSV += '\r\n'; 
        for (let SLDi = 0; SLDi < sliddata.length; SLDi++) 
        {
          let Slidrow = sliddata[SLDi];
          let desc = Slidrow.fixture_text;
          if (desc.length == 0)
          {
            desc = "      ";
          }
          if (desc.length > 6)
          {
            desc = desc.substr(0, 6);
          }
          switch (desc.length) 
          {
            case 1:
              desc = '     '+desc;
              break;
            case 2:
              desc = '    '+desc;
              break;
            case 3:
              desc = '   '+desc;
              break;
            case 4:
              desc = '  '+desc;
              break;
            case 5:
              desc = ' '+desc;
              break;
          }
          CSV += '    byte    "'+desc+'",'+Slidrow.fixture_low+","+Slidrow.fixture_high;
          CSV += '\r\n';    
        }
        return deferred.resolve(CSV,Collook.fixid);  
        })
        .catch(data => {
      });
    return deferred.promise();
  },
  Maaklookup(lookar,CSV){
    var deferred = $.Deferred();
    let that = this;
    let lwaarde = lookar.length - 1;
    let LCollook = lookar[lwaarde];
    let laatste = LCollook.fixid;

    for (let LkiLU = 0; LkiLU < lookar.length; LkiLU++) 
    {
      let Collook = lookar[LkiLU];
      var promise = this.LusGetFix(Collook,'');
        promise.then(function(result,lid) { 
          CSV += result;  
          if (lid == laatste)
          {
            ClsFixt.GetFixture({id:lid})
              .then(data => {
                let sliddata = data.subdata;
                for (let SLDi = 0; SLDi < sliddata.length; SLDi++) 
                {
                  let Slidrow = sliddata[SLDi];
                  let desc = Slidrow.fixture_text;
                  if (desc.length == 0)
                  {
                    desc = "      ";
                  }
                  if (desc.length > 6)
                  {
                    desc = desc.substr(0, 6);
                  }
                  switch (desc.length) 
                  {
                    case 1:
                      desc = '     '+desc;
                      break;
                    case 2:
                      desc = '    '+desc;
                      break;
                    case 3:
                      desc = '   '+desc;
                      break;
                    case 4:
                      desc = '  '+desc;
                      break;
                    case 5:
                      desc = ' '+desc;
                      break;
                  }
                  CSV += '\r\n';    
                  CSV += "END_OF_MBR     'MUST BE LAST or it will not be saved to EEPROM";
                  CSV += '\r\n';  
                  return deferred.resolve(CSV);  
                }
              })
              .catch(data => {
            });
          }
      });
    }           
    return deferred.promise();
  },

  handleExport() {
    let that = this;
    that.setState({circpro: (<div style={{textAlign:'center',position: 'absolute', marginLeft: '40%'}}> <CircularProgress/></div>)});
    var promise = this.InitExport(this.state.ListData);
    promise.then(function(result) { 
      that.DoExport(result);
    });
  },
  DoExport(Data){
    let verder = 0;
    let that = this;
    var CSV = '';    
    let ReportTitle = '';
    let ShowLabel = true;
    var row = "";
    var lookar = [];

    CSV += "'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "'XXXXXXXXXXXXXXXX  vanaf hier niets wijzigen  XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "con                                                                             'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "  _clkmode = xtal1 + pll8x                                                      'XX";
    CSV += '\r\n';    
    CSV += "  _xinfreq = 10_000_000                                                         'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "  MBR_base = $8300                                                              'XX";
    CSV += '\r\n';    
    CSV += "   ACK      = 0                                                                 'XX";
    CSV += '\r\n';    
    CSV += "   NAK      = 1                                                                 'XX";
    CSV += '\r\n';    
    CSV += "   Xmit     = 0                                                                 'XX";
    CSV += '\r\n';    
    CSV += "   Recv     = 1                                                                 'XX";
    CSV += '\r\n';    
    CSV += "   BootPin  = 28                                                                'XX";
    CSV += '\r\n';    
    CSV += "   EEPROM   = $A0                                                               'XX";
    CSV += '\r\n';    
    CSV += "var                                                                             'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "    long    run_ptr                                                             'XX";
    CSV += '\r\n';    
    CSV += "    long    run_size                                                            'XX";
    CSV += '\r\n';    
    CSV += "    long    total_bytes                                                         'XX";
    CSV += '\r\n';    
    CSV += "    long    startTime                                                           'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "PUB startup                                                                     'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "total_bytes := @END_OF_MBR - @MBR                                               'XX";
    CSV += '\r\n';    
    CSV += "run_ptr := 0                                                                    'XX";
    CSV += '\r\n';    
    CSV += "run_size := 0                                                                   'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "repeat                                                                          'XX";
    CSV += '\r\n';    
    CSV += "  if total_bytes <> run_ptr                                                     'XX";
    CSV += '\r\n';    
    CSV += "    run_size := (total_bytes - run_ptr) <# 128                                  'XX";
    CSV += '\r\n';    
    CSV += "    WritePage(BootPin, EEPROM, MBR_base + run_ptr, @MBR + run_ptr, run_size)    'XX";
    CSV += '\r\n';    
    CSV += "    startTime := cnt                                                            'XX";
    CSV += '\r\n';    
    CSV += "    repeat while WriteWait(BootPin, EEPROM, MBR_base + run_ptr)                 'XX";
    CSV += '\r\n';    
    CSV += "      if cnt - startTime > clkfreq                                              'XX";
    CSV += '\r\n';    
    CSV += "        abort                                                                   'XX";
    CSV += '\r\n';    
    CSV += "    run_ptr += run_size                                                         'XX";
    CSV += '\r\n';    
    CSV += "  else                                                                          'XX";
    CSV += '\r\n';    
    CSV += "    reboot                                                                      'XX";
    CSV += '\r\n';    
    CSV += "PUB Initialize(SCL) | SDA                                                       'XX";
    CSV += '\r\n';    
    CSV += "   SDA := SCL + 1                                                               'XX";
    CSV += '\r\n';    
    CSV += "   outa[SCL] := 1                                                               'XX";
    CSV += '\r\n';    
    CSV += "   dira[SCL] := 1                                                               'XX";
    CSV += '\r\n';    
    CSV += "   dira[SDA] := 0                                                               'XX";
    CSV += '\r\n';    
    CSV += "   repeat 9                                                                     'XX";
    CSV += '\r\n';    
    CSV += "      outa[SCL] := 0                                                            'XX";
    CSV += '\r\n';    
    CSV += "      outa[SCL] := 1                                                            'XX";
    CSV += '\r\n';    
    CSV += "      if ina[SDA]                                                               'XX";
    CSV += '\r\n';    
    CSV += "         quit                                                                   'XX";
    CSV += '\r\n';    
    CSV += "PUB Start(SCL) | SDA                                                            'XX";
    CSV += '\r\n';    
    CSV += "   SDA := SCL + 1                                                               'XX";
    CSV += '\r\n';    
    CSV += "   outa[SCL]~~                                                                  'XX";
    CSV += '\r\n';    
    CSV += "   dira[SCL]~~                                                                  'XX";
    CSV += '\r\n';    
    CSV += "   outa[SDA]~~                                                                  'XX";
    CSV += '\r\n';    
    CSV += "   dira[SDA]~~                                                                  'XX";
    CSV += '\r\n';    
    CSV += "   outa[SDA]~                                                                   'XX";
    CSV += '\r\n';    
    CSV += "   outa[SCL]~                                                                   'XX";
    CSV += '\r\n';    
    CSV += "PUB Stop(SCL) | SDA                                                             'XX";
    CSV += '\r\n';    
    CSV += "   SDA := SCL + 1                                                               'XX";
    CSV += '\r\n';    
    CSV += "   outa[SCL]~~                                                                  'XX";
    CSV += '\r\n';    
    CSV += "   outa[SDA]~~                                                                  'XX";
    CSV += '\r\n';    
    CSV += "   dira[SCL]~                                                                   'XX";
    CSV += '\r\n';    
    CSV += "   dira[SDA]~                                                                   'XX";
    CSV += '\r\n';    
    CSV += "PUB Write(SCL, data) : ackbit | SDA                                             'XX";
    CSV += '\r\n';    
    CSV += "   SDA := SCL + 1                                                               'XX";
    CSV += '\r\n';    
    CSV += "   ackbit := 0                                                                  'XX";
    CSV += '\r\n';    
    CSV += "   data <<= 24                                                                  'XX";
    CSV += '\r\n';    
    CSV += "   repeat 8                                                                     'XX";
    CSV += '\r\n';    
    CSV += "      outa[SDA] := (data <-= 1) & 1                                             'XX";
    CSV += '\r\n';    
    CSV += "      outa[SCL]~~                                                               'XX";
    CSV += '\r\n';    
    CSV += "      outa[SCL]~                                                                'XX";
    CSV += '\r\n';    
    CSV += "   dira[SDA]~                                                                   'XX";
    CSV += '\r\n';    
    CSV += "   outa[SCL]~~                                                                  'XX";
    CSV += '\r\n';    
    CSV += "   ackbit := ina[SDA]                                                           'XX";
    CSV += '\r\n';    
    CSV += "   outa[SCL]~                                                                   'XX";
    CSV += '\r\n';    
    CSV += "   outa[SDA]~                                                                   'XX";
    CSV += '\r\n';    
    CSV += "   dira[SDA]~~                                                                  'XX";
    CSV += '\r\n';    
    CSV += "PUB WritePage(SCL, devSel, addrReg, dataPtr, count) : ackbit                    'XX";
    CSV += '\r\n';    
    CSV += "   devSel |= addrReg >> 15 & %1110                                              'XX";
    CSV += '\r\n';    
    CSV += "   Start(SCL)                                                                   'XX";
    CSV += '\r\n';    
    CSV += "   ackbit := Write(SCL, devSel | Xmit)                                          'XX";
    CSV += '\r\n';    
    CSV += "   if addrReg & $300000 <> $200000                                              'XX";
    CSV += '\r\n';    
    CSV += "      if addrReg & $300000 == $000000                                           'XX";
    CSV += '\r\n';    
    CSV += "         ackbit := (ackbit << 1) | Write(SCL, addrReg >> 8 & $FF)               'XX";
    CSV += '\r\n';    
    CSV += "      ackbit := (ackbit << 1) | Write(SCL, addrReg & $FF)                       'XX";
    CSV += '\r\n';    
    CSV += "   repeat count                                                                 'XX";
    CSV += '\r\n';    
    CSV += "      ackbit := ackbit << 1 | ackbit & $80000000                                'XX";
    CSV += '\r\n';    
    CSV += "      ackbit |= Write(SCL, byte[dataPtr++])                                     'XX";
    CSV += '\r\n';    
    CSV += "   Stop(SCL)                                                                    'XX";
    CSV += '\r\n';    
    CSV += "   return ackbit                                                                'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "PUB WriteWait(SCL, devSel, addrReg) : ackbit                                    'XX";
    CSV += '\r\n';    
    CSV += "   devSel |= addrReg >> 15 & %1110                                              'XX";
    CSV += '\r\n';    
    CSV += "   Start(SCL)                                                                   'XX";
    CSV += '\r\n';    
    CSV += "   ackbit := Write(SCL, devSel | Xmit)                                          'XX";
    CSV += '\r\n';    
    CSV += "   Stop(SCL)                                                                    'XX";
    CSV += '\r\n';    
    CSV += "   return ackbit                                                                'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "                                                                                'XX";
    CSV += '\r\n';    
    CSV += "'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "'XXXXXXXXXXXXXXXX   tot hier niets wijzigen   XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "DAT";
    CSV += '\r\n';    
    CSV += "    long    0";
    CSV += '\r\n';    
    CSV += "MBR";
    CSV += '\r\n';    
    for (let rowIdx = 0; rowIdx < Data.length; rowIdx++) 
    {
      let Col = Data[rowIdx];
      let adresidnum = rowIdx + 1;
      if (Col.name.length > 1)
      {
        CSV += "word    @type_adr"+adresidnum+" - @MBR + MBR_base";
        CSV += '\r\n';    
      }
    }
    CSV += "long    0 'always put last to detect last light";
    CSV += '\r\n';    
    for (let rowIdx = 0; rowIdx < Data.length; rowIdx++) 
    {
      let Col = Data[rowIdx];
      if (Col.name.length > 1)
      {
        let adresidnum = rowIdx + 1;
        let accname = Col.accountname + '               ';
        let name = Col.name + '               ';
        let desc = Col.name;
        accname = accname.substr(0, 15);
        name = name.substr(0, 15);
        if (desc.length > 15)
        {
          desc = desc.substr(0, 15);
        }
        switch (desc.length) 
        {
          case 1:
            desc = '       '+desc+'       ';
            break;
          case 2:
            desc = '       '+desc+'      ';
            break;
          case 3:
            desc = '      '+desc+'      ';
            break;
          case 4:
            desc = '      '+desc+'     ';
            break;
          case 5:
            desc = '     '+desc+'     ';
            break;
          case 6:
            desc = '     '+desc+'    ';
            break;
          case 7:
            desc = '    '+desc+'    ';
            break;
          case 8:
            desc = '    '+desc+'   ';
            break;
          case 9:
            desc = '   '+desc+'   ';
            break;
          case 10:
            desc = '   '+desc+'  ';
            break;
          case 11:
            desc = '  '+desc+'  ';
            break;
          case 12:
            desc = '  '+desc+' ';
            break;
          case 13:
            desc = ' '+desc+' ';
            break;
          case 14:
            desc = ' '+desc;
            break;
        }
        CSV += "type_adr"+adresidnum;
        CSV += '\r\n';    
        CSV += '    byte    "'+accname+'",0                 '+"'manufacturer";
        CSV += '\r\n';    
        CSV += '    byte    "'+name+'",0                 '+"'name";
        CSV += '\r\n';    
        CSV += '    byte    "'+desc+'",0                 '+"'description";
        CSV += '\r\n';    
        CSV += "    word     0";
        CSV += '\r\n';    
        CSV += "    byte     "+Col.vischan+"                                  'number of visable channels";
        CSV += '\r\n';    
        CSV += "    byte     "+Col.unvischan+"                                  'number of unvisable channels";
        CSV += '\r\n';  
        // Nu sliders van deze lamp
        let chandata = Col.subdata;
        if (chandata.length > 0)
        {
          for (let Cidx = 0; Cidx < chandata.length; Cidx++) 
          {
            let Colc = chandata[Cidx];
            let channum = Cidx + 1;
            CSV += "channel"+channum;
            CSV += '\r\n'; 
            desc = Colc.name;
            if (desc.length == 0)
            {
              desc = "   Geen Naam   ";
            }
            if (desc.length > 15)
            {
              desc = desc.substr(0, 15);
            }
            switch (desc.length) 
            {
              case 1:
                desc = '       '+desc+'       ';
                break;
              case 2:
                desc = '       '+desc+'      ';
                break;
              case 3:
                desc = '      '+desc+'      ';
                break;
              case 4:
                desc = '      '+desc+'     ';
                break;
              case 5:
                desc = '     '+desc+'     ';
                break;
              case 6:
                desc = '     '+desc+'    ';
                break;
              case 7:
                desc = '    '+desc+'    ';
                break;
              case 8:
                desc = '    '+desc+'   ';
                break;
              case 9:
                desc = '   '+desc+'   ';
                break;
              case 10:
                desc = '   '+desc+'  ';
                break;
              case 11:
                desc = '  '+desc+'  ';
                break;
              case 12:
                desc = '  '+desc+' ';
                break;
              case 13:
                desc = ' '+desc+' ';
                break;
              case 14:
                desc = ' '+desc;
                break;
            }
            CSV += '    byte    "'+desc+'",0                 '+"'name of channel";
            CSV += '\r\n';    
            CSV += "    byte     "+Colc.dmx+"                                  'DMX adr of output";
            CSV += '\r\n';    
            CSV += "    byte     "+Colc.settings+"                      'dim,push,look,16/8,place";
            CSV += '\r\n';    
            let color1 = Colc.color.substring(1, 3);
            let color2 = Colc.color.substring(3, 5);
            let color3 = Colc.color.substring(5, 7);
            let color = "$"+color1+",$"+color2+",$"+color3;
            CSV += "    byte     "+color+"                       'RGB color of slider";
            CSV += '\r\n';    
            CSV += "    word     "+Colc.steps+"                                 '# steps";
            CSV += '\r\n'; 
            if (Colc.lookup.length > 2)
            {
              // Aanvullen fixid in tabel fixids
              CSV += "    word     "+Colc.lookup+"                                 'lookup_adr";
              CSV += '\r\n';    

              let gevonden = 0;
              let colinput = {lookup:Colc.lookup, fixid:Colc.fixid};
              if (lookar.length > 0)
              {
                for (let Lki = 0; Lki < lookar.length; Lki++) 
                { 
                  let Collook = lookar[Lki];
                  if (Collook.lookup == Colc.lookup)
                  {
                    gevonden = 1;
                  }
                }
              }
              if (gevonden == 0)
              {
                lookar.push(colinput);
              }
            }            
            else
            {
              CSV += "    word     0                                   'lookup_adr";
              CSV += '\r\n';    

            }
          }
        }
        // Einde lookup velden
      }    
    }
    // Einde sliders van deze lamp
    // Begin lookup velden
    CSV += "'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "'XXXXXXXX                         XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "'XXXXXXXX      LOOKUP TABLEs      XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "'XXXXXXXX                         XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';    
    CSV += "'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
    CSV += '\r\n';  

    var promise = this.Maaklookup(lookar,CSV);
    promise.then(function(result) { 
      CSV = result;      
      if (CSV == '') 
      {        
        alert("Invalid data");
        return;
      }   
      //Generate a file name
      var fileName = "library";
      //this will remove the blank-spaces from the title and replace it with an underscore
      //Initialize file format you want csv or xls
      var uri = 'data:text;charset=utf-8,' + escape(CSV);
      //this trick will generate a temp <a /> tag
      var link = document.createElement("a");    
      link.href = uri;
      //set the visibility hidden so it will not effect on your web-layout
      link.style = "visibility:hidden";
      link.download = fileName + ".spin";
      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); 
      that.setState({circpro: null});
    });
  },
});

module.exports = Libraryvorm;






