
const React = require('react');
const ReactDOM = require('react-dom');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const ThemeManager = require('../matui/styles/theme-manager');
const LightRawTheme = require('../matui/styles/raw-themes/light-raw-theme');
const Colors = require('../matui/styles/colors');
const StylePropable = require('../matui/mixins/style-propable');
const StyleResizable = require('../matui/mixins/style-resizable');
const TextField = require('../matui/text-field.jsx');
const Dialog = require('../matui/dialog.jsx');
const DgFunc = require('../datagrid/src/functions.jsx');
var Panel = require("react-bootstrap").Panel;
var Grid = require("react-bootstrap").Grid;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
const IconButton = require('../matui/icon-button.jsx');
var Modal = require("react-bootstrap").Modal;
var Button = require("react-bootstrap").Button;
var ButtonGroup = require("react-bootstrap").ButtonGroup;
const Toolbar = require('../matui/toolbar/toolbar.jsx');
const ToolbarGroup = require('../matui/toolbar/toolbar-group.jsx');
const ToolbarSeparator = require('../matui/toolbar/toolbar-separator.jsx');
const ToolbarTitle = require('../matui/toolbar/toolbar-title.jsx');
import {Column, Table, CellMeasurer, CellMeasurerCache, List, AutoSizer, SortDirection, SortIndicator } from 'react-virtualized';

const Paper = require('../matui/paper.jsx');


var OrgData  = [];
var GrData     = [];


// Definietie classe
const Zoekpopup = React.createClass({
  mixins: [StylePropable, StyleResizable,PureRenderMixin],
  childContextTypes: {
    muiTheme: React.PropTypes.object
  },


  getInitialState() {
    return {
      disabelen: false,
      dkol:[],
      colFilter: [],
      titel:'',
      Tabelnaam:'',
      GrData:[],
      dopen:false,
      dgcolumns:[],
      sleutel:0,
      veld:'',
      err_naam: '',
      route:'',
      oldroute:'',
      ListData:[],
      HListData:[],
      muiTheme: ThemeManager.getMuiTheme(LightRawTheme),
      overscanRowCount: 0,
      rowCount: 0,
      hoog:500,
      breed:500,
      scrollToIndex: undefined,
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false,
      virtualScrollHeight: 300,
      virtualScrollRowHeight: 100,
      RowHeight:0,                        
      immuData:Immutable.List(),
      sortBy: '',
      sortDirection: SortDirection.ASC,                         
    };
  },

  getChildContext() {
    return {
      muiTheme: this.state.muiTheme,
    };
  },    

  sluiten(){
    this.refs.zoekpopcontrole.dismiss();
  },

  componentDidUpdate() {

    this.setState({hoog:this._getHoog()});
    this.setState({breed:this._getBreed()});
  },  

  componentDidMount() {
    this.setState({hoog:this._getHoog()});
    this.setState({breed:this._getBreed()});
  },  

  componentWillMount() {
    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 25
    });
  },

  openen(sleutel, veld, veldid){
    this.setState({veld:veld});
    this.setState({route:sleutel});
    let that = this;
   
    let vurl = './server/index.php?function='+sleutel;                 
    if (vurl.length > 2)
    {

       let jqxhr =  $.ajax({
        url: vurl,
        dataType: 'json',
        type: 'POST',
        data: JSON.stringify(veldid)
        }).always(function(data){
        if (that.isMounted()) 
        {
          let hulpmenu = [];
          let hkol = [];
          let hitem, hicon, lengte;
          OrgData    = data.data;
          GrData     = data.data;
          that.setState({titel:data.titel});
          that.setState({colFilter:data.colFilter});
          that.setState({dkol:data.kol});
          that.setState({GrData:data.data});
          that.setState({immuData:Immutable.List(data.data)});
          that.setState({rowCount:Immutable.List(data.data).size});
          that.refs.zoekpopcontrole.show();
        }
      });
    } 
  },
  _getRowData (list,index) {
    if (list.size > 0)
    {
      return list.get(index % list.size)
    }
    else
    {
      return null;
    }
  },
  _getBreed() {
    if (this.refs.zoekpopcontrole)
    {
      let wrapper = ReactDOM.findDOMNode(this.refs.zoekpopcontrole);
      let fsize = (window.innerWidth < 500 || window.innerHeight < 500) ? 1 : 1;
      return parseInt(((wrapper.offsetWidth * fsize) - ((wrapper.offsetWidth * fsize ) * .10)*.75));
    }
    else
    {
      return 0;
    }
  },

  _getHoog() {
    if (this.refs.zoekpopcontrole)
    {
      let wrapper = ReactDOM.findDOMNode(this.refs.zoekpopcontrole);
      return parseInt(wrapper.offsetHeight - (wrapper.offsetHeight * .2) - 80);
    }
    else
    {
      return 0;
    }
  },



  _columnCellRenderer ({ columnIndex,cellData, columnData, dataKey, parent, rowData, rowIndex }) {
    let content = cellData;
    let hfdcont = (<div className='.VtableColumn' onClick={(event)=>this.LRowClick(event,rowIndex,rowData)} style={{whiteSpace: 'normal'}} >
                    {content}
                  </div>); 

    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={0}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
      {hfdcont}
      </CellMeasurer>
    )
  },
  _columnCellRendererDef ({ columnIndex,cellData, columnData, dataKey, parent, rowData, rowIndex }) {
    let content = cellData;
    return (
         <div
          className='.VtableColumn'
          onClick={(event)=>this.LRowClick(event,rowIndex,rowData)}
          style={{whiteSpace: 'normal'}}>
          {content}
        </div>
 
    )
  },
 _rowStyle ({ index }) {
    let rowclass = index % 2 === 0 ? 'VtableRow' : 'VtableRowOd';
    return rowclass;
  },
 _isSortEnabled () {
    if (this.state.immuData)
    {
      const list = this.state.immuData;
      return this.state.rowCount <= list.size
    }
    else
    {
      return false;
    }
  },
 _sort ({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection })
  },  
 _headerRenderer ({columnData,dataKey,disableSort,label,sortBy,sortDirection}) {
    return (
      <div>
        Full Name
        {sortBy === dataKey &&
          <SortIndicator sortDirection={sortDirection} />
        }
      </div>
    )
  },
  stringToDate(_date,_format,_delimiter) {
      if (_date)
      {
            var formatLowerCase=_format.toLowerCase();
            var formatItems=formatLowerCase.split(_delimiter);
            var dateItems=_date.split(_delimiter);
            var monthIndex=formatItems.indexOf("mm");
            var dayIndex=formatItems.indexOf("dd");
            var yearIndex=formatItems.indexOf("yyyy");
            var month=parseInt(dateItems[monthIndex]);
            month-=1;
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
            return formatedDate;
      }
      else
      {
        return null;
      }
  },   
  getSortType(tabel,label) {
    let sorttype = 'STR';
    if (tabel)
    {
      tabel.forEach((item, i) => {
      if (item.datakey == label)
      {
        sorttype = item.colsorttype;
      }

      });
    }
    return sorttype;
  },   
 _rowGetter ({ index }) {
    const  list = this.state.immuData;
    return list.get(index % list.size)
  },

  render() {
    document.body.style.cursor = 'wait';
    var h = window.innerHeight * .8;
    var ch = window.innerHeight * .78;
    var maxhdial = window.innerHeight + 'px';
    var maxhdialrol = (window.innerHeight * .72) + 'px';

    const {
      overscanRowCount,
      rowCount,
      scrollToIndex,
      showScrollingPlaceholder,
      useDynamicRowHeight,
      virtualScrollHeight,
      virtualScrollRowHeight,
      RowHeight,
      data,
      dkol,
      sortBy,
      route,
      immuData,
      sortDirection
    } = this.state

    let fsize = (window.innerWidth < 500 || window.innerHeight < 500) ? "100%" : "80%";
    let that = this;
    let arra;
    let arrb;   
    let vld_dgrid = null;
    if (immuData.size < 1)
    {
    }
    else
    {
      const sorttype = this.getSortType(dkol,sortBy); 
      const list = immuData;
      var sortedList = list;

      if (sorttype == 'STR' && sortBy.length > 0 )
      {
        sortedList = this._isSortEnabled()
        ? list
        .sortBy(item => item[sortBy].toLowerCase())
        .update(list =>
          sortDirection === SortDirection.DESC
            ? list.reverse()
            : list
        )
        : list
      }
      if (sorttype == 'NUM' && sortBy.length > 0 )
      {
        sortedList = this._isSortEnabled()
        ? list
        .sortBy(item => parseFloat(item[sortBy].replace('.','')))
        .update(list =>
          sortDirection === SortDirection.DESC
            ? list.reverse()
            : list
        )
        : list
      }
      if (sorttype == 'DAT' && sortBy.length > 0 )
      {
        sortedList = this._isSortEnabled()
        ? list
        .sortBy(item => that.stringToDate(item[sortBy],"dd-mm-yyyy","-"))
        .update(list =>
          sortDirection === SortDirection.DESC
            ? list.reverse()
            : list
        )
        : list
      }

      const rowGetter = ({ index }) => this._getRowData(sortedList, index);

      let zoeken = this.props.zoeken;

      let huref = 'Lst'+this.state.route;
 
      let cardList = [];
      if (this.state.dkol)
      {
        this.state.dkol.forEach((item, i) => {
        let colw = (this.state.breed/100)*item.width;
        let hkey = 'VTCOL'+i;
        let sorteer = (item.sortable == '1') ? false : true;
        let colstyle = (item.colsorttype == 'NUM') ? { textAlign:'right'} : { textAlign:'left'};
        let headclass = (item.colsorttype == 'NUM') ? 'VHeaderNum' : 'VHeaderStr';




        if (item.cellrender == 'N')
        {
          cardList.push(<Column
                    dataKey={item.datakey}
                    key={hkey}
                    cellRenderer={this._columnCellRendererDef}
                    headerClassName= {headclass}
                    disableSort={sorteer}
                    style={colstyle}
                    className='VtableColumn'
                    label={item.label}
                    width={colw}
                  />);
        }
        else
        {
          cardList.push(<Column
                    dataKey={item.datakey}
                    key={hkey}
                    headerClassName= {headclass}
                    disableSort={sorteer}
                    style={colstyle}
                    cellRenderer={this._columnCellRenderer}
                    className='VtableColumn'
                    label={item.label}
                    width={colw}
                  />);
        }

        });
      }
      
      vld_dgrid = (<div style={{postion:'relative',display:'block'}}>
                  <Table
                    deferredMeasurementCache={this._cache}
                    headerHeight={20}
                    height={this.state.hoog}
                    overscanRowCount={2}
                    rowClassName={this._rowStyle}
                    rowHeight={this._cache.rowHeight}
                    rowGetter={rowGetter}
                    rowCount={this.state.rowCount}
                    sort={this._sort}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    width={this.state.breed}
                  >
                  {cardList}
                  </Table>
                </div>);  
    }


    let tot_zicht1 = (
      <div className='row' style={{width:'100%',marginBottom:'15px',display:'inline-block'}}>
          {vld_dgrid}
      </div>);    

  
    let zkvld = (<TextField
        style={{width: '80%', marginLeft: '1%', marginTop: '-35px'}}
        floatingLabelText="Search"
        defaultValue=""   
        onChange={this.handleFilter} />);

  
   document.body.style.cursor = 'auto';


    let voet = (<div style = {{margin:0,paddingTop:'10px',paddingLeft:'10px',paddingBottom:'10px',color:'white',lineHeight:'32px',bottom:'0px',background: 'rgba(0, 143, 183, .05)',position:'absolute', width: '100%', borderTop: 'rgba(0, 143, 183, .2)', borderTopStyle: 'solid', borderTopWidth: 'thin', }} >
                <TextField style={{width: '50%', left: '15px'}} hintText="Search" onChange={this.handleFilter} />
                <Button id="ButUpdPop" style={{float:'right', marginRight:'10px'}}bsStyle="primary" bsSize="small" onClick={this.Butbewaren}>Terug</Button> 
                </div>);
    let mbot = this.props.third ? '100px' : '50px';
    let zindex = this.props.third ? '9999999999' : '9999999996';


    if (screen.width < 750)
    {
      voet = (<Toolbar style={{height:'40px',top:'50px',padding:'',zIndex:zindex}} zbottom={true}>
          <TextField style={{width: '50%', left: '15px'}} hintText="Search" onChange={this.handleFilter} />
        </Toolbar>);
    }
    else
    {
      voet = (<div style = {{margin:0,paddingTop:'10px',paddingLeft:'10px',paddingBottom:'10px',color:'white',lineHeight:'32px',bottom:'0px',background: 'rgba(0, 143, 183, .05)',position:'absolute', width: '100%', borderTop: 'rgba(0, 143, 183, .2)', borderTopStyle: 'solid', borderTopWidth: 'thin', }} >
                <TextField style={{width: '50%', left: '15px'}} hintText="Search" onChange={this.handleFilter} />
                </div>);

    }


    let mwdth = (screen.width < 750) ? '100%' : '90%';
    let wdth = (screen.width < 750) ? '100%' : '90%';
    let cmwdth = (screen.width < 750) ? '100%' : '98%';
    let cwdth = (screen.width < 750) ? '100%' : '98%';
    let clft = (screen.width < 750) ? '0' : '5%';
    let notoolb = (this.props.notoolb) ? true : false;

    
    return ( 
      <Dialog
        ref='zoekpopcontrole'
        style={{fontSize: '100%', width:wdth, maxWidth:mwdth}}
        contentStyle={{width:cwdth, maxWidth:cmwdth, left:clft,maxHeight:maxhdial,overflowY:'hidden'}}
        title={this.state.titel}
        sluiten={this.Butbewaren}
        paddingtop={this.props.paddingtop}
        footer= {voet}
        second={true}
        third={this.props.third}
        id="cont1"
        zindex={this.props.zindex}
        hidetoolb={notoolb}        
        notoolb={notoolb}
        openImmediately={false}
        modal={true}
        repositionOnUpdate={false}>
        {tot_zicht1}
      </Dialog>)
  },

 
  Butbewaren() {
    this.refs.zoekpopcontrole.dismiss();
    if (this.props.sluiten) this.props.sluiten();    
  },

  handleFilter(e){
   let value = e.target.value;    
    if (this.state.colFilter)
    {
      let fildata = DgFunc.SetFilter(value,GrData,this.state.colFilter);
      this.setState({immuData:Immutable.List(fildata)});
      this.setState({rowCount:Immutable.List(fildata).size});
      this.setState({scrollToIndex:0});
    }
  },

  LRowClick(event,index,rowData) {
    if (this.props.notoolb) {App.master.zethfdmen(1)}
    if (this.props.klik) this.props.klik(rowData, this.state.veld);       
  },



  RowKlik(waarde) {
    this.OpenMod(this.state.HListData[waarde],null,null);
  },

  OpenMod(data, rowProps, event) {
    // Eerst ophalen van de text die doorgegeven wordt
    if (this.props.notoolb) {App.master.zethfdmen(1)}
    if (this.props.klik) this.props.klik(data,this.state.veld);

  }  

});
module.exports = Zoekpopup;


 




