const React = require('react');
const StylePropable = require('../mixins/style-propable');
const ClickAwayable = require('../mixins/click-awayable');
const TableHeader = require('./table-header.jsx');
const TableRow = require('./table-row.jsx');
const TableFooter = require('./table-footer.jsx');
const DOM = require('../utils/dom');
const TextField = require('../text-field.jsx');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../../immutab/dist/immutable');


let Table = React.createClass({

  mixins: [StylePropable, ClickAwayable,PureRenderMixin],


  contextTypes: {
    muiTheme: React.PropTypes.object
  },

  propTypes: {
    rowData:React.PropTypes.object,
    headerColumns: React.PropTypes.object,
    footerColumns: React.PropTypes.object,
    header: React.PropTypes.element,
    footer: React.PropTypes.element,
    height: React.PropTypes.string,
    defaultColumnWidth: React.PropTypes.string,
    fixedHeader: React.PropTypes.bool,
    fixedFooter: React.PropTypes.bool,
    stripedRows: React.PropTypes.bool,
    showRowHover: React.PropTypes.bool,
    selectable: React.PropTypes.bool,
    multiSelectable: React.PropTypes.bool,
    displayRowCheckbox: React.PropTypes.bool,
    canSelectAll: React.PropTypes.bool,
    displaySelectAll: React.PropTypes.bool,
    onRowSelection: React.PropTypes.func,
    onCellClick: React.PropTypes.func,
    onRowHover: React.PropTypes.func,
    onRowHoverExit: React.PropTypes.func,
    onCellHover: React.PropTypes.func,
    onCellHoverExit: React.PropTypes.func
  },

  getDefaultProps() {
    return {
      fixedHeader: true,
      fixedFooter: true,
      height: 'inherit',
      rowData:Immutable.List(),
      defaultColumnWidth: '50px',
      stripedRows: false,
      showRowHover: false,
      selectable: true,
      displayRowCheckbox: true,
      multiSelectable: false,
      canSelectAll: false,
      displaySelectAll: true
    };
  },

  getInitialState() {
    return {
      selectedRows:Immutable.List(),
      headerColumns:this.props.headerColumns,
      columnOrder: this.props.columnOrder,
      colFilter:this.props.colFilter,
      getrecords:true,
      OrgData: this.props.rowData,
      rowData: this.props.rowData,
      tBody:this._getBody(),
      tHead:this._getHeader(),
      sortcol: '',
      sorttype: '',
      fl_search:true,
      timeclick:0,
      allRowsSelected:false,
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },


  getTheme() {
    return this.state.muiTheme.table;
  },


  getStyles() {
    let styles = {
      root: {
        backgroundColor: this.getTheme().backgroundColor,
        padding: '0 ' + this.state.muiTheme.rawTheme.spacing.desktopGutter + 'px',
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: 0,
        tableLayout: 'fixed'
      },
      bodyTable: {
        height: (this.props.fixedHeader || this.props.fixedFooter) ? this.props.height : 'auto',
        overflowX: 'hidden',
        overflowY: 'auto'
      },
      tableWrapper: {
        height: (this.props.fixedHeader || this.props.fixedFooter) ? 'auto' : this.props.height,
        overflow: 'auto'
      }
    };

    return styles;
  },

  componentClickAway() {
    if (this.state.selectedRows.size) this.setState({ selectedRows:Immutable.List()});
  },


  render() {
  
    document.body.style.cursor = 'wait';
    let className = 'mui-table';
    let styles = this.getStyles();
    if (!this.state.rowData) {return};
    if (!this.state.OrgData)
    {
      this.state.OrgData = this.props.rowData;
      this.state.headerColumns = this.props.headerColumns;
      this.state.columnOrder = this.props.columnOrder;
      this.state.colFilter = this.props.colFilter;
    }
    else
    {
      if ( (this.state.OrgData.size != this.props.rowData.size)||(this.props.update == true) )
      {
        this.state.headerColumns = this.props.headerColumns;
        this.state.columnOrder = this.props.columnOrder;
        this.state.colFilter = this.props.colFilter;
        this.state.rowData = this.props.rowData;
        this.state.OrgData = this.props.rowData;
      }
    }


    this.state.tHead = this._getHeader();
    this.state.tBody = this._getBody()
    let tFoot = this._getFooter();
    let headerTable, footerTable;
    let inlineHeader, inlineFooter;
    let vldzoeken = (this.props.zoeken) ? (
       <TextField
          style={{marginRight: '15px',marginLeft: '15px', width: '75%'}}
          floatingLabelText="Zoeken"
          ref="TabFilter" 
          onChange={this._filterRowsBy} />):null;

    if (this.state.tHead !== undefined) {
      if (this.props.fixedHeader) {
        this.state.headerTable = (
          <div className='mui-header-table'>
            <table ref='headerTable' className={className} style={styles.root}>
              {this.state.tHead}
            </table>
          </div>
        );
      }
      else {
        this.state.inlineHeader = this.state.tHead;
      }
    }

    if (tFoot !== undefined) {
      if (this.props.fixedFooter) {
        footerTable = (
          <div className='mui-footer-table'>
            <table ref='footerTable' className={className} style={styles.root}>
              {tFoot}
            </table>
          </div>
        );
      }
      else {
        inlineFooter = tFoot;
      }
    }



 
    return (
      <div className='mui-table-wrapper' style={styles.tableWrapper}>
        {vldzoeken}     
        {this.state.headerTable}
        <div className='mui-body-table' style={styles.bodyTable}>
          <table ref='bodyTable' className={className} style={styles.root}>
            {this.state.inlineHeader}
            {inlineFooter}
            {this.state.tBody}
          </table>
        </div>
        {footerTable}
      </div>
    );
  },

  _getHeader() {
    if (! this.state) {return};
    if (this.props.header) return this.props.header;
    let sortcol = (this.state.sortcol) ? this.state.sortcol : '';
    let sorttype = (this.state.sorttype) ? this.state.sorttype : '';
    let selected = (this.state.allRowsSelected) ? this.state.allRowsSelected : false;

    if (this.state.headerColumns !== undefined) {
      this.state.orderedHeaderColumns = this._orderColumnBasedData(this.state.headerColumns);
      return (
        <TableHeader
          columns={this.state.orderedHeaderColumns}
          enableSelectAll={this.props.canSelectAll && this.props.selectable}
          displaySelectAll={this.props.displaySelectAll}
          onSelectAll={this._onSelectAll} 
          Selected={selected}
          onHeadClick={this._onHeadClick}
          displayRowCheckbox={this.props.displayRowCheckbox}
          headsortcol={sortcol}
          headsorttype={sorttype}/>
      );
    }
  },

  _getFooter() {
    document.body.style.cursor = 'auto';
    if (this.props.footer) return this.props.footer;

    if (this.props.footerColumns !== undefined) {
      let orderedFooterColumns = this._orderColumnBasedData(this.props.footerColumns);
      if (this.props.displaySelectAll) {
        orderedFooterColumns.splice(0, 0, {content: ''});
      }

      return (
        <TableFooter columns={orderedFooterColumns} />
      );
    }
  },

  _getBody() {
    if (!this.state) {return};
    if (!this.state.rowData) {return};

    let var_rowdata = this.state.rowData;
    
    
    let body = this._orderColumnBasedData(var_rowdata, (rowData, rowNumber) => {
      let selected = this._isRowSelected(rowNumber);
      let striped = this.props.stripedRows && (rowNumber % 2 === 0);
      let border = true;
      if (rowNumber === this.state.rowData.size - 1) 
      {
        border = false;
      }
      let row = (
        <TableRow
          key={'r-' + rowNumber}
          rowNumber={rowNumber}
          columns={rowData}
          Selected={selected}
          striped={striped}
          displayRowCheckbox={this.props.displayRowCheckbox}
          hoverable={this.props.showRowHover}
          displayBorder={border}
          selectable={this.props.selectable}
          onRowClick={this._handleRowClick}
          onCellClick={this._handleCellClick}
          onRowHover={this._handleRowHover}
          onRowHoverExit={this._handleRowHoverExit}
          onCellHover={this._handleCellHover}
          onCellHoverExit={this._handleCellHoverExit} />);
      return row;
    });
    return (
      <tbody style={{height: this.props.height}}>
        {body}
      </tbody>
    );
  },

  ZetData(data){
      this.state.OrgData = data;
      this.setState({rowData:data});
      this.state.allRowsSelected = false;
      this.state.selectedRows = Immutable.List();  
  },

  GetSelectedData() {
      var rows = Immutable.List();
      var resrows = Immutable.List();
      if (this.state.selectedRows.size < 1)
      {
          return resrows;
      }
 

      rows = this.props.rowData.slice();
      for (let rowIdx = 0; rowIdx < rows.size; rowIdx++) 
      {
         for (let i = 0; i < this.state.selectedRows.size; i++) {
            let selection = this.state.selectedRows.get(i);
            if (typeof selection === 'object') {
              if (this._isValueInRange(rowIdx, selection))
              { 
                resrows = resrows.push(rows.get(rowIdx));
              }
          }
          else {
            if (selection === rowIdx) 
            {
              resrows = resrows.push(rows.get(rowIdx));
            }
          }
        }
      }
      return resrows;
  },

  _onHeadClick(e, columnNumber,content, type) {
 
    let arra;
    let arrb;
    let filtered= Immutable.List();
    if (!this.state.OrgData)
    {
      this.state.OrgData = this.state.rowData;
    }
 
    this.state.allRowsSelected = false;
    this.state.selectedRows = Immutable.List();
    var rows = this.state.OrgData;

    if (this.state.sortcol == content)
    {
        if (this.state.sorttype == 'A')
        {
          this.state.sorttype = 'D';
        }
        else
        {
          this.state.sorttype = 'A'; 
        }
    }
    else
    {
        this.state.sortcol = content;
        this.state.sorttype = 'A'; 

    }
    if (type === 'STR')
    {
      filtered = (this.state.sorttype == 'D') ? rows.sort(function (a, b){return eval('a.'+content+'.content') > eval('b.'+content+'.content') ? -1 : 1;}) :
                rows.sort(function (a, b){return eval('a.'+content+'.content') > eval('b.'+content+'.content') ? 1 : -1;}); 
    }


    if (type === 'NUM')
    {

      filtered = (this.state.sorttype == 'D') ? rows.sort(function (a, b){return (parseFloat(eval('a.'+content+'.content')) -  parseFloat(eval('b.'+content+'.content')))}) :
                rows.sort(function (a, b){return (parseFloat(eval('b.'+content+'.content')) -  parseFloat(eval('a.'+content+'.content')))}); 
    }

    if (type === 'DAT')
    {
      var that = this;
      filtered = (this.state.sorttype == 'D') ? rows.sort(function (a, b){return that.stringToDate(eval('a.'+content+'.content'),"dd-mm-yyyy","-") > that.stringToDate(eval('b.'+content+'.content'),"dd-mm-yyyy","-") ? -1 : 1;}) :
                rows.sort(function (a, b){return that.stringToDate(eval('a.'+content+'.content'),"dd-mm-yyyy","-") > that.stringToDate(eval('b.'+content+'.content'),"dd-mm-yyyy","-") ? 1 : -1;}); 
    }
    this.state.rowData =filtered;
    this.setState({tBody:this._getBody});
    this.state.tHead = this._getHeader();    

  },


  stringToDate(_date,_format,_delimiter)
  {
      if (_date)
      {
            var formatLowerCase=_format.toLowerCase();
            var formatItems=formatLowerCase.split(_delimiter);
            var dateItems=_date.split(_delimiter);
            var monthIndex=formatItems.indexOf("mm");
            var dayIndex=formatItems.indexOf("dd");
            var yearIndex=formatItems.indexOf("yyyy");
            var month=parseInt(dateItems[monthIndex]);
            month-=1;
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
            return formatedDate;
      }
      else
      {
        return null;
      }
  },


  _filterRowsBy(e) {

      if (this.state.colFilter)
      {
          this.state.allRowsSelected = false;
          this.state.selectedRows = Immutable.List();
          let arra;
          let filterBy;
          filterBy = e.target.value;
          if (!this.state.OrgData)
          {
            this.state.OrgData = this.props.rowData;
          }
          else
          {
            this.state.rowData = this.state.OrgData;
          }

          var rows = Immutable.List();
          rows = this.state.OrgData;
          let filtertab = this.state.colFilter;
          const regex = new RegExp(filterBy, 'i');
          const filtered = this.state.OrgData.filter(function(row) {

              for (let rowIdx = 0; rowIdx < filtertab.size; rowIdx++) 
              {
                let filCol = filtertab.get(rowIdx);

                  if (eval('row.'+filCol+'.content'))
                  {
                      arra = eval('row.'+filCol+'.content').toLowerCase();            
                      if (arra.search(regex) > -1)
                      {
                        return (arra.search(regex) > -1)
                      }
                  }
              };});
          this.state.rowData =filtered;
          this.setState({tBody:this._getBody});
          this.state.tHead = this._getHeader();    
      }
  },


  _orderColumnBasedData(columnBasedData, cb) {
    // If we do not have a columnOrder, return.
    if (this.props.columnOrder === undefined) return;

    let data = (Object.prototype.toString.call(columnBasedData) !== '[object Array]') ? columnBasedData : Immutable.List(columnBasedData);
    let orderedData = new Immutable.List();
    for (let rowIdx = 0; rowIdx < data.size; rowIdx++) {
      let rowData = Immutable.Map(data.get(rowIdx));
      let orderedRowData = new Immutable.List();

      for (let colIdx = 0; colIdx < this.state.columnOrder.size; colIdx++) {
        let columnId = this.state.columnOrder.get(colIdx);
        let columnData = rowData.get(columnId,{});
        orderedRowData = orderedRowData.push(columnData);
      }

      if (orderedRowData.size) {
        rowData = orderedRowData;
      }

      // Fixed table layout only requires widths on first row.
      if (rowIdx === 1 && data.size > 1) {
        rowData = this._setColumnWidths(rowData);
      }
      orderedData = orderedData.push((cb !== undefined) ? cb(rowData, rowIdx) : rowData);
    }
    return (data.size === 1) ? orderedData.first() : orderedData;
  },

  _setColumnWidths(columnData) {
    columnData.forEach((column) => {
      if (column.style === undefined) {
        column.style = {
          width: this.props.defaultColumnWidth,
          maxWidth: this.props.defaultColumnWidth
        }
      }
      else {
        if (column.style.width === undefined) column.style.width = this.props.defaultColumnWidth;
        if (column.style.maxWidth === undefined) column.style.maxWidth = this.props.defaultColumnWidth;
      }
    });

    return columnData;
  },

  _isRowSelected(rowNumber) {
        if (this.state.allRowsSelected) {
          return true;
        }
        if (this.state.selectedRows.size < 1)
        {
          return false;
        }
        for (let i = 0; i < this.state.selectedRows.size; i++) {
          let selection = this.state.selectedRows.get(i);

          if (typeof selection === 'object') {
            if (this._isValueInRange(rowNumber, selection)) return true;
          }
          else {
            if (selection === rowNumber) return true;
          }
        }
  },

  _isValueInRange(value, range) {
    if ((range.start <= value && value <= range.end) || (range.end <= value && value <= range.start)) {
      return true;
    }

    return false;
  },

  _handleRowClick(e, rowNumber, n) {
    // Prevent text selection while selecting rows.
    window.getSelection().removeAllRanges();
    if (this.props.selectable) {
      this._processRowSelection(e, rowNumber);
    }



    if (this.props.searchkey && n )
    {
        let DataRow;
        DataRow = this.state.rowData.get(rowNumber); 
        let waarde;
        waarde = eval('DataRow.'+this.props.searchkey+'.content');
        if (this.state.timeclick)
        {
          if ( n - this.state.timeclick < 2500)
          {
          }
          else
          {
            this.state.timeclick = n;
            if (this.state.fl_search)
            {
              if (this.props.getRecord) this.props.getRecord(waarde);
            }
          }
        }
        else
        {
            if (this.props.getRecord) this.props.getRecord(waarde);
            this.state.timeclick = n;
        }

    }
  },

  _processRowSelection(e, rowNumber) {
    let selectedRows = this.state.selectedRows;

    if (e.shiftKey && this.props.multiSelectable && selectedRows.size) {
      let lastSelection = selectedRows.get(selectedRows.size - 1);
      let start, end, direction;

      if (typeof lastSelection === 'object') {
        lastSelection.end = rowNumber;
      }
      else {
        selectedRows = selectedRows.push({start: lastSelection, end: rowNumber});
      }
    }
    else if (((e.ctrlKey && !e.metaKey) || (e.metaKey && !e.ctrlKey)) && this.props.multiSelectable) {
      let idx = selectedRows.indexOf(rowNumber);
      if (idx < 0) {
        selectedRows = selectedRows.push(rowNumber);
      }
      else {
        selectedRows = selectedRows.splice(idx, 1);
      }
    }
    else {
      if (selectedRows.size === 1 && selectedRows.first() === rowNumber) {
        selectedRows = Immutable.List();
      }
      else {
        selectedRows = Immutable.List([rowNumber]);
      }
    }

    this.setState({ selectedRows: selectedRows });
    if (this.props.onRowSelection) this.props.onRowSelection(selectedRows);
  },

  _handleCellClick(e, rowNumber, columnNumber) {
    if (this.props.onCellClick) this.props.onCellClick(rowNumber, this._getColumnId(columnNumber));
    if (this.props.displayRowCheckbox && columnNumber < 1) 
    {
      if (this.props.selectable) {
        this.state.allRowsSelected = false;
        this._processRowSelection(e, rowNumber);
        this.setState({tHead:this._getHeader()});
      }
      return;
    }
    else
    {
      this._handleRowClick(e, rowNumber);
    }

  },

  _handleRowHover(e, rowNumber) {
    if (this.props.onRowHover) this.props.onRowHover(rowNumber);
  },

  _handleRowHoverExit(e, rowNumber) {
    if (this.props.onRowHoverExit) this.props.onRowHoverExit(rowNumber);
  },

  _handleCellHover(e, rowNumber, columnNumber) {
    if (this.props.onCellHover) this.props.onCellHover(rowNumber, this._getColumnId(columnNumber));
    this._handleRowHover(e, rowNumber);
  },

  _handleCellHoverExit(e, rowNumber, columnNumber) {
    if (this.props.onCellHoverExit) this.props.onCellHoverExit(rowNumber, this._getColumnId(columnNumber));
    this._handleRowHoverExit(e, rowNumber);
  },

  _onSelectAll() {
    if (this.state.allRowsSelected)
    {
      this.state.allRowsSelected = false;
      this.state.selectedRows = Immutable.List();
    }
    else
    {
      this.state.allRowsSelected = true;
      if (!this.state.OrgData)
      {
        this.state.OrgData = this.props.rowData;
      }


      let resrows=Immutable.List();
      let rows = this.state.rowData.slice();
      for (let rowIdx = 0; rowIdx < rows.size; rowIdx++) 
      {
          resrows = resrows.push(rowIdx);
      }
      this.state.selectedRows = resrows;

    }
    this.setState({tBody: this._getBody()});
  },

  _getColumnId(columnNumber) {
    let columnId = columnNumber;
    if (this.props.displayRowCheckbox) columnId--;
    columnId = (this.props.columnOrder.size) ? this.props.columnOrder[columnId] : columnId;

    return columnId;
  }


});

module.exports = Table;
