    var setImmediate = function(fn){
        setTimeout(fn, 0)
    }
    var clearImmediate = clearTimeout
    /**
     * Utility methods for working with functions.
     * These methods augment the Function prototype.
     *
     * Using {@link #before}
     *
     *      function log(m){
     *          console.log(m)
     *      }
     *
     *      var doLog = function (m){
     *          console.log('LOG ')
     *      }.before(log)
     *
     *      doLog('test')
     *      //will log
     *      //"LOG "
     *      //and then
     *      //"test"
     *
     *
     *
     * Using {@link #bindArgs}:
     *
     *      //returns the sum of all arguments
     *      function add(){
     *          var sum = 0
     *          [].from(arguments).forEach(function(n){
     *              sum += n
     *          })
     *
     *          return sum
     *      }
     *
     *      var add1 = add.bindArgs(1)
     *
     *      add1(2, 3) == 6
     *
     * Using {@link #lockArgs}:
     *
     *      function add(){
     *          var sum = 0
     *          [].from(arguments).forEach(function(n){
     *              sum += n
     *          })
     *
     *          return sum
     *      }
     *
     *      var add1_2   = add.lockArgs(1,2)
     *      var add1_2_3 = add.lockArgs(1,2,3)
     *
     *      add1_2(3,4)  == 3 //args are locked to only be 1 and 2
     *      add1_2_3(6)  == 6 //args are locked to only be 1, 2 and 3
     *
     *
     *
     * Using {@link #compose}:
     *
     *      function multiply(a,b){
     *          return a* b
     *      }
     *
     *      var multiply2 = multiply.curry()(2)
     *
     *      Function.compose(multiply2( add(5,6) )) == multiply2( add(5,6) )
     *
     *
     * @class Function
     */

    var SLICE = Array.prototype.slice

    var curry = require('./src/curry'),

        findFn = function(fn, target, onFound){
            // if (typeof target.find == 'function'){
            //     return target.find(fn)
            // }

            onFound = typeof onFound == 'function'?
                        onFound:
                        function(found, key, target){
                            return found
                        }

            if (Array.isArray(target)){
                var i   = 0
                var len = target.length
                var it

                for(; i < len; i++){
                    it = target[i]
                    if (fn(it, i, target)){
                        return onFound(it, i, target)
                    }
                }

                return
            }

            if (typeof target == 'object'){
                var keys = Object.keys(target)
                var i = 0
                var len = keys.length
                var k
                var it

                for( ; i < len; i++){
                    k  = keys[i]
                    it = target[k]

                    if (fn(it, k, target)){
                        return onFound(it, k, target)
                    }
                }
            }
        },

        find = curry(findFn, 2),

        findIndex = curry(function(fn, target){
            return findFn(fn, target, function(it, i){
                return i
            })
        }),

        bindFunctionsOf = function(obj) {
            Object.keys(obj).forEach(function(k){
                if (typeof obj[k] == 'function'){
                    obj[k] = obj[k].bind(obj)
                }
            })

            return obj
        },

        /*
         * @param {Function...} an enumeration of functions, each consuming the result of the following function.
         *
         * For example: compose(c, b, a)(1,4) == c(b(a(1,4)))
         *
         * @return the result of the first function in the enumeration
         */
        compose = require('./src/compose'),

        chain = require('./src/chain'),

        once = require('./src/once'),

        bindArgsArray = require('./src/bindArgsArray'),

        bindArgs = require('./src/bindArgs'),

        lockArgsArray = require('./src/lockArgsArray'),

        lockArgs = require('./src/lockArgs'),

        skipArgs = function(fn, count){
            return function(){
                var args = SLICE.call(arguments, count || 0)

                return fn.apply(this, args)
            }
        },

        intercept = function(interceptedFn, interceptingFn, withStopArg){

            return function(){
                var args    = [].from(arguments),
                    stopArg = { stop: false }

                if (withStopArg){
                    args.push(stopArg)
                }

                var result = interceptingFn.apply(this, args)

                if (withStopArg){
                    if (stopArg.stop === true){
                        return result
                    }

                } else {
                    if (result === false){
                        return result
                    }
                }

                //the interception was not stopped
                return interceptedFn.apply(this, arguments)
            }

        },

        delay = function(fn, delay, scope){

            var delayIsNumber = delay * 1 == delay

            if (arguments.length == 2 && !delayIsNumber){
                scope = delay
                delay = 0
            } else {
                if (!delayIsNumber){
                    delay = 0
                }
            }

            return function(){
                var self = scope || this,
                    args = arguments

                if (delay < 0){
                    fn.apply(self, args)
                    return
                }

                if (delay || !setImmediate){
                    setTimeout(function(){
                        fn.apply(self, args)
                    }, delay)

                } else {
                    setImmediate(function(){
                        fn.apply(self, args)
                    })
                }
            }
        },

        defer = function(fn, scope){
            return delay(fn, 0, scope)
        },

        buffer = function(fn, delay, scope){

            var timeoutId = -1

            return function(){

                var self = scope || this,
                    args = arguments

                if (delay < 0){
                    fn.apply(self, args)
                    return
                }

                var withTimeout = delay || !setImmediate,
                    clearFn = withTimeout?
                                clearTimeout:
                                clearImmediate,
                    setFn   = withTimeout?
                                setTimeout:
                                setImmediate

                if (timeoutId !== -1){
                    clearFn(timeoutId)
                }

                timeoutId = setFn(function(){
                    fn.apply(self, args)
                    self = null
                }, delay)

            }

        },

        throttle = function(fn, delay, scope) {
            var timeoutId = -1,
                self,
                args

            return function () {

                self = scope || this
                args = arguments

                if (timeoutId !== -1) {
                    //the function was called once again in the delay interval
                } else {
                    timeoutId = setTimeout(function () {
                        fn.apply(self, args)

                        self = null
                        timeoutId = -1
                    }, delay)
                }

            }

        },

        spread = function(fn, delay, scope){

            var timeoutId       = -1
            var callCount       = 0
            var executeCount    = 0
            var nextArgs        = {}
            var increaseCounter = true
            var resultingFnUnbound
            var resultingFn

            resultingFn = resultingFnUnbound = function(){

                var args = arguments,
                    self = scope || this

                if (increaseCounter){
                    nextArgs[callCount++] = {args: args, scope: self}
                }

                if (timeoutId !== -1){
                    //the function was called once again in the delay interval
                } else {
                    timeoutId = setTimeout(function(){
                        fn.apply(self, args)

                        timeoutId = -1
                        executeCount++

                        if (callCount !== executeCount){
                            resultingFn = bindArgsArray(resultingFnUnbound, nextArgs[executeCount].args).bind(nextArgs[executeCount].scope)
                            delete nextArgs[executeCount]

                            increaseCounter = false
                            resultingFn.apply(self)
                            increaseCounter = true
                        } else {
                            nextArgs = {}
                        }
                    }, delay)
                }

            }

            return resultingFn
        },

        /*
         * @param {Array} args the array for which to create a cache key
         * @param {Number} [cacheParamNumber] the number of args to use for the cache key. Use this to limit the args that area actually used for the cache key
         */
        getCacheKey = function(args, cacheParamNumber){
            if (cacheParamNumber == null){
                cacheParamNumber = -1
            }

            var i        = 0,
                len      = Math.min(args.length, cacheParamNumber),
                cacheKey = [],
                it

            for ( ; i < len; i++){
                it = args[i]

                if (root.check.isPlainObject(it) || Array.isArray(it)){
                    cacheKey.push(JSON.stringify(it))
                } else {
                    cacheKey.push(String(it))
                }
            }

            return cacheKey.join(', ')
        },

        /*
         * @param {Function} fn - the function to cache results for
         * @param {Number} skipCacheParamNumber - the index of the boolean parameter that makes this function skip the caching and
         * actually return computed results.
         * @param {Function|String} cacheBucketMethod - a function or the name of a method on this object which makes caching distributed across multiple buckets.
         * If given, cached results will be searched into the cache corresponding to this bucket. If no result found, return computed result.
         *
         * For example this param is very useful when a function from a prototype is cached,
         * but we want to return the same cached results only for one object that inherits that proto, not for all objects. Thus, for example for Wes.Element,
         * we use the 'getId' cacheBucketMethod to indicate cached results for one object only.
         * @param {Function} [cacheKeyBuilder] A function to be used to compose the cache key
         *
         * @return {Function} a new function, which returns results from cache, if they are available, otherwise uses the given fn to compute the results.
         * This returned function has a 'clearCache' function attached, which clears the caching. If a parameter ( a bucket id) is  provided,
         * only clears the cache in the specified cache bucket.
         */
        cache = function(fn, config){
            config = config || {}

            var bucketCache = {},
                cache       = {},
                skipCacheParamNumber = config.skipCacheIndex,
                cacheBucketMethod    = config.cacheBucket,
                cacheKeyBuilder      = config.cacheKey,
                cacheArgsLength      = skipCacheParamNumber == null?
                                            fn.length:
                                            skipCacheParamNumber,
                cachingFn

            cachingFn = function(){
                var result,
                    skipCache = skipCacheParamNumber != null?
                                                arguments[skipCacheParamNumber] === true:
                                                false,
                    args = skipCache?
                                    SLICE.call(arguments, 0, cacheArgsLength):
                                    SLICE.call(arguments),

                    cacheBucketId = cacheBucketMethod != null?
                                        typeof cacheBucketMethod == 'function'?
                                            cacheBucketMethod():
                                            typeof this[cacheBucketMethod] == 'function'?
                                                this[cacheBucketMethod]():
                                                null
                                        :
                                        null,


                    cacheObject = cacheBucketId?
                                        bucketCache[cacheBucketId]:
                                        cache,

                    cacheKey = (cacheKeyBuilder || getCacheKey)(args, cacheArgsLength)

                if (cacheBucketId && !cacheObject){
                    cacheObject = bucketCache[cacheBucketId] = {}
                }

                if (skipCache || cacheObject[cacheKey] == null){
                    cacheObject[cacheKey] = result = fn.apply(this, args)
                } else {
                    result = cacheObject[cacheKey]
                }

                return result
            }

            /*
             * @param {String|Object|Number} [bucketId] the bucket for which to clear the cache. If none given, clears all the cache for this function.
             */
            cachingFn.clearCache = function(bucketId){
                if (bucketId){
                    delete bucketCache[String(bucketId)]
                } else {
                    cache = {}
                    bucketCache = {}
                }
            }

            /*
             *
             * @param {Array} cacheArgs The array of objects from which to create the cache key
             * @param {Number} [cacheParamNumber] A limit for the cache args that are actually used to compute the cache key.
             * @param {Function} [cacheKeyBuilder] The function to be used to compute the cache key from the given cacheArgs and cacheParamNumber
             */
            cachingFn.getCache = function(cacheArgs, cacheParamNumber, cacheKeyBuilder){
                return cachingFn.getBucketCache(null, cacheArgs, cacheParamNumber, cacheKeyBuilder)
            }

            /*
             *
             * @param {String} bucketId The id of the cache bucket from which to retrieve the cached value
             * @param {Array} cacheArgs The array of objects from which to create the cache key
             * @param {Number} [cacheParamNumber] A limit for the cache args that are actually used to compute the cache key.
             * @param {Function} [cacheKeyBuilder] The function to be used to compute the cache key from the given cacheArgs and cacheParamNumber
             */
            cachingFn.getBucketCache = function(bucketId, cacheArgs, cacheParamNumber, cacheKeyBuilder){
                var cacheObject = cache,
                    cacheKey = (cacheKeyBuilder || getCacheKey)(cacheArgs, cacheParamNumber)

                if (bucketId){
                    bucketId = String(bucketId);

                    cacheObject = bucketCache[bucketId] = bucketCache[bucketId] || {}
                }

                return cacheObject[cacheKey]
            }

            /*
             *
             * @param {Object} value The value to set in the cache
             * @param {Array} cacheArgs The array of objects from which to create the cache key
             * @param {Number} [cacheParamNumber] A limit for the cache args that are actually used to compute the cache key.
             * @param {Function} [cacheKeyBuilder] The function to be used to compute the cache key from the given cacheArgs and cacheParamNumber
             */
            cachingFn.setCache = function(value, cacheArgs, cacheParamNumber, cacheKeyBuilder){
                return cachingFn.setBucketCache(null, value, cacheArgs, cacheParamNumber, cacheKeyBuilder)
            }

            /*
             *
             * @param {String} bucketId The id of the cache bucket for which to set the cache value
             * @param {Object} value The value to set in the cache
             * @param {Array} cacheArgs The array of objects from which to create the cache key
             * @param {Number} [cacheParamNumber] A limit for the cache args that are actually used to compute the cache key.
             * @param {Function} [cacheKeyBuilder] The function to be used to compute the cache key from the given cacheArgs and cacheParamNumber
             */
            cachingFn.setBucketCache = function(bucketId, value, cacheArgs, cacheParamNumber, cacheKeyBuilder){

                var cacheObject = cache,
                    cacheKey = (cacheKeyBuilder || getCacheKey)(cacheArgs, cacheParamNumber)

                if (bucketId){
                    bucketId = String(bucketId)

                    cacheObject = bucketCache[bucketId] = bucketCache[bucketId] || {};
                }

                return cacheObject[cacheKey] = value
            }

            return cachingFn
        }

module.exports = {

    map: require('./src/map'),

    dot: require('./src/dot'),

    maxArgs: require('./src/maxArgs'),

    /**
     * @method compose
     *
     * Example:
     *
     *      zippy.Function.compose(c, b, a)
     *
     * See {@link Function#compose}
     */
    compose: compose,

    /**
     * See {@link Function#self}
     */
    self: function(fn){
        return fn
    },

    /**
     * See {@link Function#buffer}
     */
    buffer: buffer,

    /**
     * See {@link Function#delay}
     */
    delay: delay,

    /**
     * See {@link Function#defer}
     * @param {Function} fn
     * @param {Object} scope
     */
    defer:defer,

    /**
     * See {@link Function#skipArgs}
     * @param {Function} fn
     * @param {Number} [count=0] how many args to skip when calling the resulting function
     * @return {Function} The function that will call the original fn without the first count args.
     */
    skipArgs: skipArgs,

    /**
     * See {@link Function#intercept}
     */
    intercept: function(fn, interceptedFn, withStopArgs){
        return intercept(interceptedFn, fn, withStopArgs)
    },

    /**
     * See {@link Function#throttle}
     */
    throttle: throttle,

    /**
     * See {@link Function#spread}
     */
    spread: spread,

    /**
     * See {@link Function#chain}
     */
    chain: function(fn, where, mainFn){
        return chain(where, mainFn, fn)
    },

    /**
     * See {@link Function#before}
     */
    before: function(fn, otherFn){
        return chain('before', otherFn, fn)
    },

    /**
     * See {@link Function#after}
     */
    after: function(fn, otherFn){
        return chain('after', otherFn, fn)
    },

    /**
     * See {@link Function#curry}
     */
    curry: curry,

    /**
     * See {@link Function#once}
     */
    once: once,

    /**
     * See {@link Function#bindArgs}
     */
    bindArgs: bindArgs,

    /**
     * See {@link Function#bindArgsArray}
     */
    bindArgsArray: bindArgsArray,

    /**
     * See {@link Function#lockArgs}
     */
    lockArgs: lockArgs,

    /**
     * See {@link Function#lockArgsArray}
     */
    lockArgsArray: lockArgsArray,

    bindFunctionsOf: bindFunctionsOf,

    find: find,

    findIndex: findIndex,

    newify: require('./src/newify')
}