const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const StylePropable = require('../matui/mixins/style-propable');
const StyleResizable = require('../matui/mixins/style-resizable');
const TextField = require('../matui/text-field.jsx');
const Dialog = require('../matui/dialog.jsx');
const Zoekpopup = require('./zoekpopup.jsx');
const Snackbar = require('../matui/snackbar.jsx');
const DateTime = require('../matui/utils/date-time');
const Algfunc = require('../functions/algemeen');
var Panel = require("react-bootstrap").Panel;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Button = require("react-bootstrap").Button;
const Toolbar = require('../matui/toolbar/toolbar.jsx');
var ButtonGroup = require("react-bootstrap").ButtonGroup;
const IconButton = require('../matui/icon-button.jsx');
const Contstafunc = require('./cont_func.jsx');
const ClsAct = require('./cls_fixtures.jsx');
const InputColor = require('react-input-color');
const Checkbox = require('../matui/checkbox.jsx');
const PopupFix = require('./popdeftexture.jsx');
const ClsFixt = require('./cls_fixtures.jsx');

// Definietie classe
const Popdefadres = React.createClass({
  mixins: [StylePropable,StyleResizable,PureRenderMixin],
  getInitialState() {
    return {
      disabelen: false,
      popupfix:null,
      color:'#3599db',
      dispvoet:'inherit',
      color:'',
      origin:'',
      sleutel:0,
      err_naam: '',
      soort:'',
      updatecol:'',
      updatetxt:'',          
      templates:[],
      fixmdata:[],
      Klanten:App.account,
      data:{}, 
      disaoms: false,
      disasoort: false,
      disastatus: false,
      disauitvdat: false,
      discreadat: false,
      disafsldat:false,
      disacreator: true,
      disauitv: false,
      err_oms:'',
      err_soort:'',
      err_statklacht:'',
      err_uitvdat:'',
      err_creadat:'',
      err_afsldat:'',
      err_klant:'',
      err_klacht:'',
      err_uur: '',
      err_end_time: '',
      err_creator:'',
      err_uitvoerder:'',
      sleutel:0,
      klachtid:0,
      klachtnaam:'',
      lidid:0,
      lidnaam:'',      
    };
  },
  componentWillMount() {
    App.master.AppAccount();
  },
  componentDidMount() {
    if (this.props.data) 
    {
      this.openen(this.props.data);
    }
  },   
  render() {
    var h = window.innerHeight * .8;
    var ch = window.innerHeight * .78;
    let formatChars = {"9": "[0-9]",};

    var maxhdial = window.innerHeight + 'px';
    var maxhdialrol = (window.innerHeight - 250) + 'px';
    let vld_oms = (<TextField
        style={{marginRight: '15px', width: '95%', marginLeft: '10px'}}
          floatingLabelText="Name"
          defaultValue={this.state.data.name}
          ref="ActInpOms" 
          errorText={this.state.errnaam} />); 
    let vld_lid = (<TextField
        style={{marginRight: '15px', width: '95%',marginLeft: '10px'}}
            floatingLabelText="Manufacturer"
            defaultValue={this.state.data.accountname}
            Key={this.state.data.accountid}
            ref="InpLid" 
            PopUp={true}
            CalPopup={this.LidZoek}            
            menu={false}
            setfilter={false}
            autotext={true}
            errorText={this.state.errklant}
            menuItems={App.account}
            zien={true}  />);
    let vld_steps = (<TextField
        style={{marginRight: '15px', marginLeft: '10px', width: '80%'}}
          floatingLabelText="Steps"
          defaultValue={this.state.data.steps}      
          ref="InpSteps" 
          id="InpSteps" 
          mask='999'
          errorText={this.state.errsteps}          
          formatChars={formatChars}/>);
    let vld_color = (<InputColor
        value={this.state.color}
        defaultValue={this.state.data.color}
        onChange={this.ColorChange}/>);
    let vld_color2 = (<input
          style={{marginTop: '10px', width:'80%'}} 
          type="color"
          value={this.state.color}
          defaultValue={this.state.data.color}
          onChange={this._onColorChange}/>);
    let txtcol = (<h1 dir="ltr" style={{fontSize:'10px',fontWeight: '100',lineHeight: '14px', marginBottom: '2px'}}>Kleur :</h1>);
    let vld_dim0=(<Checkbox
        style={{marginTop: '15px', marginLeft: '20px'}}
        name="Chk_dim0"
        ref="Chk_dim0"
        id="Chk_dim0"
        onCheck={this.Handledim}
        label="Channel gaat zo naar buiten (settings, presets,...)"/>);
    let vld_dim1=(<Checkbox
        style={{marginTop: '15px', marginLeft: '20px'}}
        name="Chk_dim1"
        ref="Chk_dim1"
        id="Chk_dim1"
        onCheck={this.Handledim}
        label="Waarde wordt vermenigvuldigd met master en submaster (intensity van channel)"/>);
    let vld_push0=(<Checkbox
        style={{marginTop: '15px', marginLeft: '20px'}}
        name="Chk_push0"
        ref="Chk_push0"
        id="Chk_push0"
        onCheck={this.Handlepush}
        label="Indrukken van joggle wordt genegeerd (settings, CCT, GN,...)"/>);
    let vld_push1=(<Checkbox
        style={{marginTop: '15px', marginLeft: '20px'}}
        name="Chk_push1"
        ref="Chk_push1"
        id="Chk_push1"
        onCheck={this.Handlepush}
        label="Bij indrukken joggle: uitgang naar max"/>);
    let vld_look0=(<Checkbox
        style={{marginTop: '15px', marginLeft: '20px'}}
        name="Chk_look0"
        ref="Chk_look0"
        id="Chk_look0"
        onCheck={this.Handlelook}
        label="0  Kanaal is in %. #steps (boven) moet ook op 100 staan Link naar lookup (boven) wordt genegeerd."/>);
    let vld_look1=(<Checkbox
        style={{marginTop: '15px', marginLeft: '20px'}}
        name="Chk_look1"
        ref="Chk_look1"
        id="Chk_look1"
        onCheck={this.Handlelook}
        label="Kanaal heeft eigen tekst en bijhorende DMX output Link naar lookup is noodzakelijk"/>);
    let vld_uit0=(<Checkbox
        style={{marginTop: '15px', marginLeft: '20px'}}
        name="Chk_uit0"
        ref="Chk_uit0"
        id="Chk_uit0"
        onCheck={this.Handleuit}
        label="Uitgang is in 8 bit mode"/>);
    let vld_uit1=(<Checkbox
        style={{marginTop: '15px', marginLeft: '20px'}}
        name="Chk_uit1"
        ref="Chk_uit1"
        id="Chk_uit1"
        onCheck={this.Handleuit}
        label="Uitgang is in 16 bit mode"/>);
    let vld_fader = (<TextField
        style={{marginRight: '15px', width: '95%',marginLeft: '10px'}}
            floatingLabelText="Fader"
            defaultValue={this.state.data.fname}
            Key={this.state.data.fixid}
            ref="InpFix" 
            PopUp={true}
            onMenuChange={this.FixChange}
            CalPopup={this.FixZoek}            
            menu={false}
            setfilter={false}
            autotext={true}
            toevoegen={true}
            toevFunc = {this.FixNew}
            errorText={this.state.errfix}
            menuItems={this.state.fixmdata}
            zien={true}  />);
    let alggeg = (
      <div className='row' style={{width:'100%',marginBottom:'15px',display:'inline-block',overflowY:'hidden',overflowX:'hidden'}}>
        <Row className="show-grid">
          <Panel  header='General info' eventKey="11" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff'}}>
            <Row className="show-grid">
              <Col xs={12} md={12} sm={12} >
                {vld_oms}
              </Col>
            </Row>
          </Panel>
        </Row>
        <Row className="show-grid">
          <Panel  header='Fader' eventKey="11" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff'}}>
            <Row className="show-grid">
              <Col xs={12} md={12} sm={12} >
                {vld_fader}
              </Col>
            </Row>
            <Row className="show-grid" >
              <Col xs={6} md={6} sm={6} >
                {vld_steps}
              </Col>
              <Col xs={6} md={6} sm={6} style={{marginTop: '10px'}} >
                {txtcol}
                {vld_color2}
              </Col>    
            </Row>
          </Panel>
        </Row>
      </div>);
    let settings = (
        <Panel  header='Settings' eventKey="12" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff'}}>
          <Row className="show-grid">
            <Col xs={12} md={12} sm={12} >
              <Col xs={6} md={6} sm={6} >
                {vld_dim0}
              </Col>
              <Col xs={6} md={6} sm={6} >
                {vld_dim1}
              </Col>
            </Col>
            <Col xs={12} md={12} sm={12} >
              <Col xs={6} md={6} sm={6} >
                {vld_push0}
              </Col>
              <Col xs={6} md={6} sm={6} >
                {vld_push1}
              </Col>
            </Col>
            <Col xs={12} md={12} sm={12} >
              <Col xs={6} md={6} sm={6} >
                {vld_look0}
              </Col>
              <Col xs={6} md={6} sm={6} >
                {vld_look1}
              </Col>
            </Col>
            <Col xs={12} md={12} sm={12} >
              <Col xs={6} md={6} sm={6} >
                {vld_uit0}
              </Col>
              <Col xs={6} md={6} sm={6} >
                {vld_uit1}
              </Col>
            </Col>
          </Row>
        </Panel>);    
    let vldzoekpopup = (<Zoekpopup  id='zoekpopup1' route = '' zindex={9999998} klik={this.klikzkpop} sluiten={this.sluitzkpop} paddingtop={true} third={true} ref='RefZoekPopup'></Zoekpopup>);
    let tot_zicht1 = (screen.width < 750) ? (
      <div className='row' style={{width:'100%',marginBottom:'15px',display:'inline-block',overflowY:'auto',overflowX:'hidden'}}>
        {vldzoekpopup}
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol}}
          message={this.state.updatetxt}/>
        <Row className="show-grid">
            <Col xs={6} md={6} sm={6} >
              {alggeg}
            </Col>
            <Col xs={6} md={6} sm={6} >
              {settings}
            </Col>

        </Row>
      </div>) : (
      <div className='row' style={{marginRight: '2%',marginLeft: '2%', width:'99%',marginBottom:'15px',display:'inline-block',overflowY:'auto',overflowX:'hidden', maxHeight:maxhdialrol}}>
        {vldzoekpopup}
        {this.state.popupfix}
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol}}
          message={this.state.updatetxt}/>
        <Row className="show-grid">
           <Col xs={6} md={6} sm={6} >
              {alggeg}
            </Col>
            <Col xs={6} md={6} sm={6} >
              {settings}
            </Col>
        </Row>
      </div>)    ;    
    // Einde velden definitei
    let voet = null;
    if (screen.width < 750)
    {
      voet = (<Toolbar style={{height:'40px',bottom:'100px',padding:'',zIndex:'9999999996',display:this.state.dispvoet}} zbottom={true}>
          <Button id="ButUpdPop" style={{float:'right', marginRight:'10px',marginTop:'5px'}} bsStyle="primary" bsSize="small" onClick={this.Butbewaren}>Save</Button> 
        </Toolbar>);
    }
    else
    {
      voet = (<div style = {{margin:0,paddingTop:'10px',paddingLeft:'10px',paddingBottom:'10px',color:'white',lineHeight:'32px',bottom:'0px',background: 'rgba(0, 143, 183, .05)',position:'absolute', width: '100%', borderTop: 'rgba(0, 143, 183, .2)', borderTopStyle: 'solid', borderTopWidth: 'thin', }} >
              <Button id="ButUpdPop" style={{float:'right', marginRight:'10px'}} bsStyle="primary" bsSize="small" onClick={this.Butbewaren}>Save</Button> 
              </div>) ;
    }

    let bgroep = null;
    if (screen.width < 750) 
    {   
      let hrbut1 = (
                      <IconButton
                      style={{width:'30px',height:'30px', paddingLeft:'5px', paddingRight:'5px',display:this.state.ldisplay}}
                      iconClassName="fa fa-floppy-o" 
                      iconStyle={{color:'white', iconHoverColor:'gray',fontSize:'small'}}
                      onClick={this.Butbewaren} />

                    ) ;

      bgroep = (
       <ButtonGroup vertical style={{position:'fixed', top:'25%', right:'10px',
          zIndex:'99999999',
          display:this.state.dispvoet,
          boxShadow: '0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22)',
          borderRadius: '2px',
          borderTopLeftRadius: '2px',
          borderTopRightRadius: '2px',
          borderBottomRightRadius: '2px',
          borderBottomLeftRadius: '2px',
          backgroundColor: 'rgba(0, 143, 183,.7)',
          width:'30px',
          paddingTop: '5px',
          paddingBottom: '5px'}}>
          {hrbut1}
       </ButtonGroup>);                                       
    }
    let mwdth = (screen.width < 750) ? '100%' : '90%';
    let wdth = (screen.width < 750) ? '100%' : '90%';
    let cmwdth = (screen.width < 750) ? '100%' : '98%';
    let cwdth = (screen.width < 750) ? '100%' : '98%';
    let clft = (screen.width < 750) ? '0' : '5%';
    return ( 
     <Dialog 
        ref="dafwcontrole"
        title="Detail Channel"
        second={true}
        sluiten={this.sluiten}
        onDismiss={this.zetsluiten}
        rbutgroep={bgroep}
        style={{fontSize: '100%', width:wdth, maxWidth:mwdth}}
        contentStyle={{width:cwdth, maxWidth:cmwdth, left:clft,maxHeight:maxhdial,overflowY:'hidden'}}
        footer= {voet}
        id="actcont1"
        modal={true}
        hidetoolb={true}        
        notoolb={true}
        paddingtop={this.props.paddingtop}
        zindex={this.props.zindex}        
        openImmediately={false}
        repositionOnUpdate={false}>
        {tot_zicht1}
      </Dialog>)
  },
  Handledim(e,checked) {
    this.refs.Chk_dim0.setChecked(false);
    this.refs.Chk_dim1.setChecked(false);
    switch (e.target.name) {
      case 'Chk_dim0':
        this.refs.Chk_dim0.setChecked(true);
        this.state.data.dim = '0';
        break;

      case 'Chk_dim1':
        this.refs.Chk_dim1.setChecked(true);
        this.state.data.dim = '1';
        break;
    }
  },  
  Handlepush(e,checked) {
    this.refs.Chk_push0.setChecked(false);
    this.refs.Chk_push1.setChecked(false);
    switch (e.target.name) {
      case 'Chk_push0':
        this.refs.Chk_push0.setChecked(true);
        this.state.data.push = '0';
        break;
      case 'Chk_push1':
        this.refs.Chk_push1.setChecked(true);
        this.state.data.push = '1';
        break;
    }
  },  
  Handlelook(e,checked) {
    this.refs.Chk_look0.setChecked(false);
    this.refs.Chk_look1.setChecked(false);


    switch (e.target.name) {
      case 'Chk_look0':
        this.refs.Chk_look0.setChecked(true);
        this.state.data.look = '0';

        break;

      case 'Chk_look1':
        this.refs.Chk_look1.setChecked(true);
        this.state.data.look = '1';
        break;
    }
  },  
  Handleuit(e,checked) {
    this.refs.Chk_uit0.setChecked(false);
    this.refs.Chk_uit1.setChecked(false);


    switch (e.target.name) {
      case 'Chk_uit0':
        this.refs.Chk_uit0.setChecked(true);
        this.state.data.uit = '0';
        break;

      case 'Chk_uit1':
        this.refs.Chk_uit1.setChecked(true);
        this.state.data.uit = '1';
        break;
    }
  },  
  ColorChange(color) {
    this.setState({
      color: color
    });

  },  
  _onColorChange(e) {
    this.setState({
      color: e.target.value
    });
    this.state.data.color = e.target.value;
  },
  sluiten(data){
    App.master.zethfdmen(1);
    if (this.props.Sluiten) {this.props.Sluiten(data)}
  },
  zetsluiten() {
    App.master.zethfdmen(1);
    if (this.props.Sluiten) {this.props.Sluiten()}
  },
  zetfixmenu(data) {
      ClsAct.UitvSQL({id:data.accountid},'GetDropFixAcc')
      .then(data => {
        let imdata = Immutable.List(data.data);
        this.setState({fixmdata:imdata});
        if (this.refs.InpFix) {this.refs.InpFix.zetmenu(imdata)}
      })
      .catch(error => {
        alert('niet gevonden');
        this.refs.dafwcontrole.dismiss();
        return true;
      });
  },
  FixNew() {
    let hudata = {
        id:'0',
        name:'',
        accountname:this.state.data.accountname,
        accountid:this.state.data.accountid,
        mihigh:'0',
        mahigh:'0',
        stphigh:'0',
        milow:'0',
        malow:'0',
        stplow:'0',
        statextnumb:'0',
        stptextnumb:'0',
        textchar:''};
    this.setState({popupfix:(<PopupFix  id='popupact1' data={hudata} notoolb={true} route = 'activiteit' zindex={999999} Sluiten={this.sluitfixpopup}  ></PopupFix>)})
  },
  sluitfixpopup(waarde) {
    this.setState({popupfix:null})    
    App.master.zethfdmen(0);    
    if (waarde)
    {
      ClsAct.UitvSQL({id:waarde.id},'GetSteps')
      .then(data => {
        this.refs.InpSteps.setValue(data.steps);
      })
      .catch(error => {
        alert('niet gevonden');
        return true;
      });
      this.refs.InpFix.setValue(waarde.name);
      this.refs.InpFix.setKey(waarde.id);
    }
  },

  openen(hudata){
    this.state.sleutel = hudata.id;
    this.refs.dafwcontrole.show();
    let sleutel = hudata.id;
    this.state.origin = hudata.Origin,
    this.setState({err_naam: ""});
    this.setState({err_oms: ""});
    this.setState({err_klant: ""});
    if (sleutel.length > 3)
    {
      ClsAct.UitvSQL({id:hudata.id},'GetSlider')
      .then(data => {
        this.setState({data:data});
        this.state.data.accountid = hudata.accountid;
        if (data.dim == '0')
        {
          this.refs.Chk_dim0.setChecked(true);
          this.refs.Chk_dim1.setChecked(false);
        }
        else
        {
          this.refs.Chk_dim0.setChecked(false);
          this.refs.Chk_dim1.setChecked(true);
        }
        if (data.push == '0')
        {
          this.refs.Chk_push0.setChecked(true);
          this.refs.Chk_push1.setChecked(false);
        }
        else
        {
          this.refs.Chk_push0.setChecked(false);
          this.refs.Chk_push1.setChecked(true);
        }
        if (data.uit == '0')
        {
          this.refs.Chk_uit0.setChecked(true);
          this.refs.Chk_uit1.setChecked(false);
        }
        else
        {
          this.refs.Chk_uit0.setChecked(false);
          this.refs.Chk_uit1.setChecked(true);
        }
        if (data.look == '0')
        {
          this.refs.Chk_look0.setChecked(true);
          this.refs.Chk_look1.setChecked(false);
        }
        else
        {
          this.refs.Chk_look0.setChecked(false);
          this.refs.Chk_look1.setChecked(true);
        }
        this.state.color = data.color;
        this.zetfixmenu(hudata);        
      })
      .catch(error => {
        alert('niet gevonden');
        this.refs.dafwcontrole.dismiss();
        return true;
      });
    }
    else
    {
      this.sluiten(hudata);
    }
  },
  ZetActGeg(hdata){
    this.setState({data:hdata});
  },
  Butbewaren() {
    // Update Data
    this.setState({errnaam:''});
    let snackb = this.refs.snackbar;
    this.state.data.name = this.refs.ActInpOms.getValue();
    this.state.data.fixid = this.refs.InpFix.getKey();
    this.state.data.steps = this.refs.InpSteps.getValue();
    ClsAct.UitvSQL(this.state.data,'UpdSlider')    
      .then(data => {
          App.master.zethfdmen(1);
          this.sluiten(data);
      })
      .catch(data => {
          this.setState({errnaam: data.errnaam});
          this.setState({errklant: data.errklant});
          this.setState({updatecol: 'red'});
          this.setState({updatetxt: 'Gegevens zijn niet aangepast'});
          snackb.show();
          return true;
    });
  },  
  LidZoek() {
    this.setState({dispvoet:'none'});
    this.refs.RefZoekPopup.openen('/klanten','InpLid');
  },
  FixZoek() {
    let hdata = {id:this.state.data.accountid};
    this.refs.RefZoekPopup.openen('GetAllFixturesAcc','InpFix',hdata);    
    this.setState({dispvoet:'none'});
  },
  FixChange(id,text) {
      ClsAct.UitvSQL({id:id},'GetSteps')
        .then(data => {
          this.refs.InpSteps.setValue(data.steps);
          this.state.data.steps = data.steps;
      })
      .catch(error => {
        return true;
      });

  },


  klikzkpop(waarde,veld) {
    switch (veld) {
        case 'InpLid':
          this.refs.InpLid.setValue(waarde.vld1);
          this.refs.InpLid.setKey(waarde.id);
          break;
        case 'InpFix':
          ClsAct.UitvSQL({id:waarde.id},'GetSteps')
            .then(data => {
              this.refs.InpSteps.setValue(data.steps);
          })
          .catch(error => {
            alert('niet gevonden');
            return true;
          });


        
          this.refs.InpFix.setValue(waarde.name);
          this.refs.InpFix.setKey(waarde.id);
          break;

    }
    this.refs.RefZoekPopup.sluiten();
    this.setState({dispvoet:'inherit'});
  }, 
  sluitzkpop() {
    this.setState({dispvoet:'inherit'});
  }, 
});
module.exports = Popdefadres;






