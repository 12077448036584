const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const StylePropable = require('../matui/mixins/style-propable');
const StyleResizable = require('../matui/mixins/style-resizable');
const TextField = require('../matui/text-field.jsx');
const Dialog = require('../matui/dialog.jsx');
const Zoekpopup = require('./zoekpopup.jsx');
const Snackbar = require('../matui/snackbar.jsx');
const DateTime = require('../matui/utils/date-time');
const Algfunc = require('../functions/algemeen');
const AlgFuncUpd = require('../functions/algemeen');
var Panel = require("react-bootstrap").Panel;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Button = require("react-bootstrap").Button;
const Checkbox = require('../matui/checkbox.jsx');
const Contstafunc = require('./cont_func.jsx');

const ClsAccount = require('./cls_account.jsx');


// Definietie classe
const PopupAcc = React.createClass({
  mixins: [StylePropable,StyleResizable,PureRenderMixin],

  getInitialState() {
    return {
      data: Contstafunc.Dat_account(),
      nwfoto:'',
      actief:'N',
      err_mail:'',
      err_naam:'',
      err_vnaam:'',
      err_login:'',
      err_paswoord:'',
      err_klantnr:'',
      sleutel:'',
      updatecol:'',
      updatetxt:'', 
      street1:'',
      street2:'',
      zipcode:'',
      zipcodetxt:'',
      email:'',
      telefoon:'',
      fax:'',
    };
  },
  componentWillMount() {
    App.master.AppAccadrtype();
    App.master.AppPostcode();
  },
  componentDidMount() {
    if (this.props.data) 
    {
        this.openen(this.props.data);
    }
  },   

  render() {
    var h = window.innerHeight * .8;
    var ch = window.innerHeight * .78;
    var maxhdial = window.innerHeight + 'px';
    var maxhdialrol = (window.innerHeight * .72) + 'px';

    // Velden definitie
    let geg1 = (<TextField
        style={{width: '95%'}}
        defaultValue={this.state.data.account_name}  
        floatingLabelText="Name"
        ref="InpNaam" 
        id="InpNaamId" 
        errorText={this.state.err_naam} />);
    let geg2 = (<TextField
        style={{width: '95%'}}
        defaultValue={this.state.data.website}  
        floatingLabelText="Website"
        ref="InpWebsite" />);
    // Adres bedrijf
    let geg5a = (<TextField
        style={{width: '95%'}}
        floatingLabelText="Type Adress"
        defaultValue={this.state.typeadres}      
        Key={this.state.typeadrafk} 
        ref="InpTypeAdr" 
        menu={true}
        menuItems={App.accadrtype}
        onMenuChange={this._TypeAdrChange}
        zien={true} />);
    let geg5 = (<TextField
        style={{width: '95%'}}
        defaultValue={this.state.street1}           
        floatingLabelText="Adress 1"
        id="InpAdres1" 
        ref="InpAdres1" />);
    let geg6 = (<TextField
        style={{width: '95%'}}
        defaultValue={this.state.street2}           
        floatingLabelText="Adress 2"
        id="InpAdres2" 
        ref="InpAdres2" />);
    let geg7 = (<TextField
        style={{width: '95%'}}
        floatingLabelText="City"
        defaultValue={this.state.zipcodetxt}      
        Key={this.state.zipcode} 
        ref="InpZipcode" 
        autotext={true}
        PopUp={true}
        CalPopup={this.PostcodeZoek}               
        menuItems={App.postcode}
        zien={false} />);
    let geg8 = (<TextField
        style={{width: '95%'}}
        defaultValue={this.state.email}                
        floatingLabelText="Email"
        ref="InpEmail" 
        errorText={this.state.err_mail} />);
    let geg9 = (<TextField
        style={{width: '95%'}}
        defaultValue={this.state.telefoon}                
        floatingLabelText="Phone"
        ref="InpTelefoon" 
        mask='+99(0)99 99 99 99 9999999'/>);
    let geg10 = (<TextField
        style={{width: '95%'}}
        defaultValue={this.state.fax}                
        hintText="Fax"
        ref="InpFax" 
        mask='+99(0)99 99 99 99 9999999'/>);
    // einde adres
    // Opzoekschermen
    let vldzoekpopup = (<Zoekpopup  id='zoekpopup1' route = '' zindex={999999} klik={this.klikzkpop} ref='RefZoekPopup'></Zoekpopup>);
    // Verder layout met panels
    let PTitalg = (<h3 className="panel-title"><span>General</span></h3>);

    let pan1 = (<div className='rowlist' style={{marginBottom: '60px', marginRight: '10px'}}>
                  <Row className="show-grid" >
                    <Col xs={12} md={12} sm={12}>
                      <Panel  header={PTitalg} eventKey="13"  bsStyle="blok" collapsible defaultExpanded style={{background: '#fff'}}>
                        <Row className="show-grid">
                          <Col xs={6} md={6} sm={6} style={{marginTop: '20px'}} >
                            {geg1}
                            {geg2}
                          </Col>  
                          <Col xs={6} md={6} sm={6} style={{marginTop: '20px'}} >
                            {geg5a}
                            {geg5}
                            {geg6}
                            {geg7}
                            {geg8}
                            {geg9}
                            {geg10}
                          </Col>
                        </Row>
                      </Panel>
                    </Col>
                  </Row>
                </div>);
    
    let tot_zicht1 = (<div className='row' style={{width:'100%',marginBottom:'15px',display:'inline-block',overflowY:'scroll',overflowX:'hidden',height:maxhdialrol}}>
                        {vldzoekpopup}
                        <Snackbar
                          ref="snackbar"
                          style={{backgroundColor: this.state.updatecol}}
                          message={this.state.updatetxt}/>
                        {pan1}
                      </div>);
    // Einde velden definitei
    let voet = (<div style = {{margin:0,paddingTop:'10px',paddingLeft:'10px',paddingBottom:'10px',color:'white',lineHeight:'32px',bottom:'0px',background: 'rgba(0, 143, 183, .05)',position:'absolute', width: '100%', borderTop: 'rgba(0, 143, 183, .2)', borderTopStyle: 'solid', borderTopWidth: 'thin', }} >
              <Button id="ButUpdPop" style={{float:'right', marginRight:'10px'}} bsStyle="primary" bsSize="small" onClick={this.Butbewaren}>Save</Button> 
              </div>) ;
    let mwdth = (screen.width < 750) ? '100%' : '90%';
    let wdth = (screen.width < 750) ? '100%' : '90%';
    let cmwdth = (screen.width < 750) ? '100%' : '98%';
    let cwdth = (screen.width < 750) ? '100%' : '98%';
    let clft = (screen.width < 750) ? '0' : '5%';
    return ( 
     <Dialog 
        ref="dafwcontrole"
        title="Detail Manufacturer"
        second={true}
        sluiten={this.sluiten}
        onDismiss={this.zetsluiten}
        style={{fontSize: '100%', width:wdth, maxWidth:mwdth}}
        contentStyle={{width:cwdth, maxWidth:cmwdth, left:clft,maxHeight:maxhdial,overflowY:'hidden'}}
        footer= {voet}
        id="actcont1"
        modal={true}
        hidetoolb={true}
        notoolb={true}
        paddingtop={this.props.paddingtop}
        zindex={this.props.zindex}        
        openImmediately={false}
        repositionOnUpdate={false}>
        {tot_zicht1}
      </Dialog>)
  },
  // Algemene functies
  sluiten(data){
    App.master.zethfdmen(1);
    if (this.props.Sluiten) {this.props.Sluiten(data)}
  },
  zetsluiten() {
    App.master.zethfdmen(1);
    if (this.props.Sluiten) {this.props.Sluiten()}
  },
  openen(hudata){
    this.state.sleutel = hudata.id;
    this.refs.dafwcontrole.show();
    let that = this;
    let status,creator,uitvoerder,klacht
    let sleutel = hudata.id;
    this.state.data = Contstafunc.Dat_account();
    this.setState({data:Contstafunc.Dat_account(),
                    typeadres:'Hoofdadres',
                    typeadrafk:'HFD',
                    street1:'',
                    street2:'',
                    zipcode:'',
                    zipcodetxt:'',
                    email:'',
                    telefoon:'',
                    fax:'',});

    this.state.origin = hudata.Origin,
    this.setState({err_naam: ""});
    this.setState({err_mail: ""});
    this.setState({err_klantnr: ""});
    if (hudata.id.length > 3)
    {
      ClsAccount.GetAccount({id:hudata.id})
      .then(data => {
          this.setState({data:data});
          this.setState({typeadres:'Hoofdadres'});
          this.setState({typeadrafk:'HFD'});
          if (data.adressen)
          {
            this._ZetTypeAdr('HFD');
          }
          else
          {
            this.state.data.adressen =[];
            this._ZetTypeAdr('HFD');
          }
          if (this.refs.InpTypeAdr)  
          {
            this.refs.InpTypeAdr.setKey('HFD'); 
            this.refs.InpTypeAdr.setValue('Hoofdadres'); 
          }
          this.zetklantgeg(data);
          document.getElementById("InpNaamId").focus();
      })
      .catch(error => {
        alert('niet gevonden');
        this.refs.dafwcontrole.dismiss();
        return true;
      });
    }
    else
    {
      this.state.data = Contstafunc.Dat_account();
      this.setState({data:Contstafunc.Dat_account()});
      this._ZetTypeAdr('');
      this.setState({typeadres:'Hoofdadres'});
      this.setState({typeadrafk:'HFD'});
      this.zetklantgeg(Contstafunc.Dat_account());
      return;
    }    
  },
  Toevoegen(data) {
    this.state.sleutel = 0;
    this.refs.dafwcontrole.show();
    this.state.data = data;
    this.setState({err_naam: "",
                    err_mail: "",
                    err_klantnr: "",
                    sleutel:0,
                    data:data,
                    typeadres:'Hoofdadres',
                    typeadrafk:'HFD',
                    street1:'',
                    street2:'',
                    zipcode:'',
                    zipcodetxt:'',
                    email:'',
                    telefoon:'',
                    fax:'',});
    this.zetklantgeg(Contstafunc.Dat_account());
    return;
  },  
  _ZetTypeAdr(waarde) {
    // Controleren of er iets in de adres array zit
    let gevonden = 0;
    if (this.state.data.adressen.length > 0)
    {
      for (let rowIdx = 0; rowIdx < this.state.data.adressen.length; rowIdx++) 
      {
        let filrow = this.state.data.adressen[rowIdx];
        if (filrow.afk == waarde)
        {
          gevonden = 1;
          if (filrow.adres1 == null) {filrow.adres1 = '';}
          if (filrow.adres2 == null) {filrow.adres2 = '';}
          if (filrow.postcodetxt == null) {filrow.postcodetxt = '';}
          if (filrow.postcode == null) {filrow.postcode = 0;}
          if (filrow.email == null) {filrow.email = '';}
          if (filrow.telefoon == null) {filrow.telefoon = '';}
          if (filrow.fax == null) {filrow.fax = '';}

          this.setState({street1:filrow.adres1});
          this.setState({street2:filrow.adres2});
          this.setState({zipcode:filrow.postcode});
          this.setState({zipcodetxt:filrow.postcodetxt});  
          this.setState({email:filrow.email});  
          this.setState({telefoon:filrow.telefoon});  
          this.setState({fax:filrow.fax});  
          this.refs.InpEmail.setValue(filrow.email);
          this.refs.InpFax.setValue(filrow.fax);
          this.refs.InpTelefoon.setValue(filrow.telefoon);
          if (this.refs.InpAdres1) {this.refs.InpAdres1.setValue(filrow.adres1)}
          if (this.refs.InpAdres2) {this.refs.InpAdres2.setValue(filrow.adres2)}
          this.refs.InpZipcode.setValue(filrow.postcodetxt);
          this.refs.InpZipcode.setKey(filrow.postcode);
        }
      }      
    }

    if (gevonden == 0)
    {
      switch (waarde) {
        case 'FAK':
          this.setState({street1:this.state.data.fact_street});
          this.setState({street2:this.state.data.fact_street2});
          this.setState({zipcode:this.state.data.fact_zipcode_id});
          this.setState({zipcodetxt:this.state.data.fact_zipcode_txt});     
          this.setState({contact:0});  
          this.setState({contacttxt:''});  
          this.setState({email:''});  
          this.setState({telefoon:''});  
          this.setState({fax:''});  

          if (this.refs.InpAdres1) {this.refs.InpAdres1.setValue(this.state.data.fact_street)}
          if (this.refs.InpAdres2) {this.refs.InpAdres2.setValue(this.state.data.fact_street2)}
          if (this.refs.InpZipcode) {this.refs.InpZipcode.setValue(this.state.data.fact_zipcode_txt)}
          if (this.refs.InpZipcode) {this.refs.InpZipcode.setKey(this.state.data.fact_zipcode_id)}
          if (this.refs.InpEmail) {this.refs.InpEmail.setValue('')}
          if (this.refs.InpFax) {this.refs.InpFax.setValue('')}
          if (this.refs.InpTelefoon) {this.refs.InpTelefoon.setValue('')}
          break;

        case 'HFD':
          this.setState({street1:this.state.data.street});
          this.setState({street2:''});
          this.setState({telefoon:this.state.data.main_phone});  
          this.setState({fax:this.state.data.fax});  
          this.setState({zipcode:this.state.data.zipcode_id});
          this.setState({zipcodetxt:this.state.data.zipcode_txt});     
          this.setState({email:this.state.data.E_mail});  
          if (this.refs.InpFax) {this.refs.InpFax.setValue(this.state.data.fax)}
          if (this.refs.InpEmail) {this.refs.InpEmail.setValue(this.state.data.E_mail)}
          if (this.refs.InpAdres1) {this.refs.InpAdres1.setValue(this.state.data.street)}
          if (this.refs.InpAdres2) {this.refs.InpAdres2.setValue('')}
          if (this.refs.InpZipcode) {this.refs.InpZipcode.setValue(this.state.data.zipcode_txt)}
          if (this.refs.InpZipcode) {this.refs.InpZipcode.setKey(this.state.data.zipcode_id)}
          if (this.refs.InpTelefoon) {this.refs.InpTelefoon.setValue(this.state.data.main_phone)}
          break;
       
        default: 
          this.setState({street1:''});
          this.setState({street2:''});
          this.setState({zipcode:0});
          this.setState({zipcodetxt:''});     
          this.setState({email:''});  
          this.setState({telefoon:''});  
          this.setState({fax:''});  
          if (this.refs.InpAdres1) {this.refs.InpAdres1.setValue('')}
          if (this.refs.InpAdres2) {this.refs.InpAdres2.setValue('')}
          if (this.refs.InpZipcode) {this.refs.InpZipcode.setValue('')}
          if (this.refs.InpZipcode) {this.refs.InpZipcode.setKey(0)}
          if (this.refs.InpEmail) {this.refs.InpEmail.setValue('')}
          if (this.refs.InpFax) {this.refs.InpFax.setValue('')}
          if (this.refs.InpTelefoon) {this.refs.InpTelefoon.setValue('')}
          break;
      }     
    }   
  },
  zetklantgeg(data) {
    if (this.refs.InpTypeAdr) {this.refs.InpTypeAdr.zetmenu(App.master.AppAccadrtype())};
  },
  klikzkpop(waarde,veld) {
    if (this.state.laden == 1)
    {
      this.setState({laden:0});
      return;
    }
    switch (veld) {
        case 'InpZipcode':
          let city = waarde.primtxt + ' ' + waarde.sectxtva;
          this.refs.InpZipcode.setValue(city);
          this.refs.InpZipcode.setKey(waarde.id);
          break;

    }
    this.refs.RefZoekPopup.sluiten();
  },  
  sluitzkpop() {
    this.setState({dispvoet:'inherit'});
  }, 
  _TypeAdrChange(waarde) {
    // Controleren of er iets in de adres array zit
    let arra = [];
    let hulparra = {
        afk:this.state.typeadrafk,
        adres1:this.refs.InpAdres1.getValue(),
        adres2:this.refs.InpAdres2.getValue(),
        postcodetxt:this.refs.InpZipcode.getValue(),
        postcode:this.refs.InpZipcode.getKey(),
        email:this.refs.InpEmail.getValue(),
        telefoon:this.refs.InpTelefoon.getValue(),
        fax:this.refs.InpFax.getValue(),}; 

    if (this.state.data.adressen) {arra = this.state.data.adressen};

    if (arra.length > 0)
    {
      // Eerst gegevens invullen oud adress
      let gevonden = 0;

      for (let rowIdx = 0; rowIdx < arra.length; rowIdx++) 
      {
        let filrow = arra[rowIdx];
        if (filrow.afk == this.state.typeadrafk)
        {
          gevonden = 1;
          arra[rowIdx] = hulparra;
        }
      }
      if (gevonden == 0)
      {
        arra.push(hulparra);   
      }
      this.state.data.adressen = arra;
      // einde opvullen adressen tabel

      // zoeken gegevens nieuw adres
      gevonden = 0;

      for (let nrowIdx = 0; nrowIdx < arra.length; nrowIdx++) 
      {
        let nfilrow = arra[nrowIdx];
        if (nfilrow.afk == waarde)
        {
          gevonden = 1;
          if (nfilrow.adres1 == null) {nfilrow.adres1 = '';}
          if (nfilrow.adres2 == null) {nfilrow.adres2 = '';}
          if (nfilrow.postcodetxt == null) {nfilrow.postcodetxt = '';}
          if (nfilrow.postcode == null) {nfilrow.postcode = 0;}
          if (nfilrow.email == null) {nfilrow.email = '';}
          if (nfilrow.telefoon == null) {nfilrow.telefoon = '';}
          if (nfilrow.fax == null) {nfilrow.fax = '';}

          this.setState({street1:nfilrow.adres1});
          this.setState({street2:nfilrow.adres2});
          this.setState({zipcode:nfilrow.postcode});
          this.setState({zipcodetxt:nfilrow.postcodetxt});  
          this.setState({email:nfilrow.email});  
          this.setState({telefoon:nfilrow.telefoon});  
          this.setState({fax:nfilrow.fax});  
          this.refs.InpFax.setValue(nfilrow.fax);
          this.refs.InpEmail.setValue(nfilrow.email);
          this.refs.InpTelefoon.setValue(nfilrow.telefoon);
          this.refs.InpAdres1.setValue(nfilrow.adres1);
          this.refs.InpAdres2.setValue(nfilrow.adres2);
          this.refs.InpZipcode.setValue(nfilrow.postcodetxt);
          this.refs.InpZipcode.setKey(nfilrow.postcode);
        }
      }
      if (gevonden == 0)
      {
        this._ZetTypeAdr(waarde);
      }
      // einde zoeken gegevens nieuw adres
    }
    else
    {
      // Eerst gegevens invullen oud adress
      arra.push(hulparra);  
      this._ZetTypeAdr(waarde);
    }
    this.state.typeadrafk = waarde;        
  },  
  PostcodeZoek() {
    this.setState({laden:1});
    let hudata = {id:App.ConsId};
    this.refs.RefZoekPopup.openen('/Postcodes','InpZipcode',hudata);
  },
  Butbewaren() {
    this.setState({err_mail:'',err_naam:'',err_klantnr:''});
    let snackb = this.refs.snackbar;
    let insql = (App.KlantId.length > 3) ? 'UPD' : 'INS';
    
    // Update Data
    // Updaten tabel adressen
    let arra = [];
    let afkadres = this.state.typeadrafk;
    let hulparra = {
        afk:this.state.typeadrafk,
        adres1:this.refs.InpAdres1.getValue(),
        adres2:this.refs.InpAdres2.getValue(),
        postcodetxt:this.refs.InpZipcode.getValue(),
        postcode:this.refs.InpZipcode.getKey(),
        contact:0,
        contacttxt:'',
        email:this.refs.InpEmail.getValue(),
        telefoon:this.refs.InpTelefoon.getValue(),
        fax:this.refs.InpFax.getValue(),};
    if (this.state.data.adressen) {arra = this.state.data.adressen};

    if (arra.length > 0)
    {
      // Eerst gegevens invullen oud adress
      let gevonden = 0;
      for (let rowIdx = 0; rowIdx < arra.length; rowIdx++) 
      {
        let filrow = arra[rowIdx];
        if (filrow.afk == afkadres)
        {
          gevonden = 1;
          arra[rowIdx] = hulparra;
        }
      }
      if (gevonden == 0)
      {
        arra.push(hulparra);   
      }
      this.state.data.adressen = arra;
    }
    else
    {
      arra.push(hulparra);
      this.state.data.adressen = arra;
    }
    this.state.data.account_name= this.refs.InpNaam.getValue();
    this.state.data.website= this.refs.InpWebsite.getValue();
    this.state.data.id = App.KlantId;

    ClsAccount.Bewaren(this.state.data,insql)
      .then(data => {
          App.KlantId = data.id;
          this.sluiten(data);
      })
      .catch(data => {
          this.setState({err_naam: data.err_naam});
          this.setState({err_mail: data.err_mail});
          this.setState({updatecol: 'red'});
          this.setState({updatetxt: 'Gegevens zijn niet aangepast'});
          snackb.show();
          return true;
    });
  }, 
});
module.exports = PopupAcc;






