const React = require('react');
const Checkbox = require('../checkbox.jsx');
const StylePropable = require('../mixins/style-propable');
const TableHeaderColumn = require('./table-header-column.jsx');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../../immutab/dist/immutable');


let TableHeader = React.createClass({

  mixins: [StylePropable, PureRenderMixin],

  contextTypes: {
    muiTheme: React.PropTypes.object
  },

  propTypes: {
    onSelectAll: React.PropTypes.func,
    displaySelectAll: React.PropTypes.bool,
    enableSelectAll: React.PropTypes.bool,
    fixed: React.PropTypes.bool
  },

  getDefaultProps() {
    return {
      displaySelectAll: true,
      enableSelectAll: true,
      fixed: true
    };
  },

  getInitialState() {
    return {
            muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  getTheme() {
    return this.state.muiTheme.tableHeader;
  },

  getStyles() {
    let styles = {
      root:  {
      }
    };

    return styles;
  },

  render() {
    let className = 'mui-table-header';

    return (
      <thead className={className} style={this.getStyles().root}>
        {this._getSuperHeaderRow()}
        {this._getHeaderRow()}
      </thead>
    );
  },

  getSuperHeaderRow() {
    return this.refs.superHeader;
  },

  getHeaderRow() {
    return this.refs.header;
  },

  _getSuperHeaderRow() {
    if (this.props.superHeaderColumns !== undefined) {
      return (
        <tr className='mui-table-super-header-row' ref='superHeader'>
          {this._getColumnHeaders(this.props.superHeaderColumns, 'sh')}
        </tr>
      );
    }
  },

  _getHeaderRow() {
    let columns = this.props.columns.slice();
    if (this.props.displayRowCheckbox) {
      if (this.props.displaySelectAll) {
        columns.splice(0, 0, this._getSelectAllCheckbox());
      }
    }

    return (
      <tr className='mui-table-header-row' ref='header'>
        {this._getHeaderColumns(columns, 'h')}
      </tr>
    );
  },

  _getHeaderColumns(headerData, keyPrefix) {
    let styles = this.getStyles();
    let headers = Immutable.List();
    for (let index = 0; index < headerData.size; index++) {
      let {
        content,
        tooltip,
        style,
        type,
        sortd,
        ...props
      } = headerData.get(index);
      let key = keyPrefix + index;
      sortd = null;
      if (this.props.headsortcol == content)
      {
        sortd = (this.props.headsorttype == 'A') ? ' ↓' : ' ↑';
      }
      headers = headers.push(
        <TableHeaderColumn key={key} style={style} tooltip={tooltip} columnNumber={index} content={content} type={type} 
          onSelectAll={this._onSelectAll}  Selected={this.props.Selected} onClick={this.props.onHeadClick} headsortcol={this.props.headsortcol}
          headsorttype={this.props.headsorttype} {...props}>
          {content}
          {sortd} 
        </TableHeaderColumn>
      );
    }

    return headers;
  },

  _getSelectAllCheckbox() {
    let checkbox =
      <Checkbox
        name='selectallcb'
        value='selected'
        disabled={true}
        onCheck={this._onSelectAll} />;

    return {
      content: checkbox,
      tooltip: 'CHKBOX',
      style: {
        paddingLeft: 24,
        paddingRight: 24,
        width:'24px'
      }
    };
  },

  _onSelectAll() {
    if (this.props.onSelectAll) this.props.onSelectAll();
  },

  _onColumnClick(e, columnNumber) {
    if (this.props.onColumnClick) this.props.onColumnClick(e, columnNumber);
  }

});

module.exports = TableHeader;
