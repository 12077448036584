module.exports={
  "supplemental": {
    "version": {
      "_number": "$Revision: 13254 $",
      "_unicodeVersion": "9.0.0",
      "_cldrVersion": "31.0.1"
    },
    "currencyData": {
      "fractions": {
        "ADP": {
          "_rounding": "0",
          "_digits": "0"
        },
        "AFN": {
          "_rounding": "0",
          "_digits": "0"
        },
        "ALL": {
          "_rounding": "0",
          "_digits": "0"
        },
        "AMD": {
          "_rounding": "0",
          "_digits": "0"
        },
        "BHD": {
          "_rounding": "0",
          "_digits": "3"
        },
        "BIF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "BYN": {
          "_rounding": "0",
          "_digits": "2"
        },
        "BYR": {
          "_rounding": "0",
          "_digits": "0"
        },
        "CAD": {
          "_rounding": "0",
          "_digits": "2",
          "_cashRounding": "5"
        },
        "CHF": {
          "_rounding": "0",
          "_digits": "2",
          "_cashRounding": "5"
        },
        "CLF": {
          "_rounding": "0",
          "_digits": "4"
        },
        "CLP": {
          "_rounding": "0",
          "_digits": "0"
        },
        "COP": {
          "_rounding": "0",
          "_digits": "0"
        },
        "CRC": {
          "_rounding": "0",
          "_digits": "2",
          "_cashRounding": "0",
          "_cashDigits": "0"
        },
        "CZK": {
          "_rounding": "0",
          "_digits": "2",
          "_cashRounding": "0",
          "_cashDigits": "0"
        },
        "DEFAULT": {
          "_rounding": "0",
          "_digits": "2"
        },
        "DJF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "ESP": {
          "_rounding": "0",
          "_digits": "0"
        },
        "GNF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "GYD": {
          "_rounding": "0",
          "_digits": "0"
        },
        "HUF": {
          "_rounding": "0",
          "_digits": "2",
          "_cashRounding": "0",
          "_cashDigits": "0"
        },
        "IDR": {
          "_rounding": "0",
          "_digits": "0"
        },
        "IQD": {
          "_rounding": "0",
          "_digits": "0"
        },
        "IRR": {
          "_rounding": "0",
          "_digits": "0"
        },
        "ISK": {
          "_rounding": "0",
          "_digits": "0"
        },
        "ITL": {
          "_rounding": "0",
          "_digits": "0"
        },
        "JOD": {
          "_rounding": "0",
          "_digits": "3"
        },
        "JPY": {
          "_rounding": "0",
          "_digits": "0"
        },
        "KMF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "KPW": {
          "_rounding": "0",
          "_digits": "0"
        },
        "KRW": {
          "_rounding": "0",
          "_digits": "0"
        },
        "KWD": {
          "_rounding": "0",
          "_digits": "3"
        },
        "LAK": {
          "_rounding": "0",
          "_digits": "0"
        },
        "LBP": {
          "_rounding": "0",
          "_digits": "0"
        },
        "LUF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "LYD": {
          "_rounding": "0",
          "_digits": "3"
        },
        "MGA": {
          "_rounding": "0",
          "_digits": "0"
        },
        "MGF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "MMK": {
          "_rounding": "0",
          "_digits": "0"
        },
        "MNT": {
          "_rounding": "0",
          "_digits": "0"
        },
        "MRO": {
          "_rounding": "0",
          "_digits": "0"
        },
        "MUR": {
          "_rounding": "0",
          "_digits": "0"
        },
        "OMR": {
          "_rounding": "0",
          "_digits": "3"
        },
        "PKR": {
          "_rounding": "0",
          "_digits": "0"
        },
        "PYG": {
          "_rounding": "0",
          "_digits": "0"
        },
        "RSD": {
          "_rounding": "0",
          "_digits": "0"
        },
        "RWF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "SLL": {
          "_rounding": "0",
          "_digits": "0"
        },
        "SOS": {
          "_rounding": "0",
          "_digits": "0"
        },
        "STD": {
          "_rounding": "0",
          "_digits": "0"
        },
        "SYP": {
          "_rounding": "0",
          "_digits": "0"
        },
        "TMM": {
          "_rounding": "0",
          "_digits": "0"
        },
        "TND": {
          "_rounding": "0",
          "_digits": "3"
        },
        "TRL": {
          "_rounding": "0",
          "_digits": "0"
        },
        "TWD": {
          "_rounding": "0",
          "_digits": "2",
          "_cashRounding": "0",
          "_cashDigits": "0"
        },
        "TZS": {
          "_rounding": "0",
          "_digits": "0"
        },
        "UGX": {
          "_rounding": "0",
          "_digits": "0"
        },
        "UYI": {
          "_rounding": "0",
          "_digits": "0"
        },
        "UZS": {
          "_rounding": "0",
          "_digits": "0"
        },
        "VND": {
          "_rounding": "0",
          "_digits": "0"
        },
        "VUV": {
          "_rounding": "0",
          "_digits": "0"
        },
        "XAF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "XOF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "XPF": {
          "_rounding": "0",
          "_digits": "0"
        },
        "YER": {
          "_rounding": "0",
          "_digits": "0"
        },
        "ZMK": {
          "_rounding": "0",
          "_digits": "0"
        },
        "ZWD": {
          "_rounding": "0",
          "_digits": "0"
        }
      },
      "region": {
        "AC": [
          {
            "SHP": {
              "_from": "1976-01-01"
            }
          }
        ],
        "AD": [
          {
            "ESP": {
              "_from": "1873-01-01",
              "_to": "2002-02-28"
            }
          },
          {
            "ADP": {
              "_from": "1936-01-01",
              "_to": "2001-12-31"
            }
          },
          {
            "FRF": {
              "_from": "1960-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "AE": [
          {
            "AED": {
              "_from": "1973-05-19"
            }
          }
        ],
        "AF": [
          {
            "AFA": {
              "_from": "1927-03-14",
              "_to": "2002-12-31"
            }
          },
          {
            "AFN": {
              "_from": "2002-10-07"
            }
          }
        ],
        "AG": [
          {
            "XCD": {
              "_from": "1965-10-06"
            }
          }
        ],
        "AI": [
          {
            "XCD": {
              "_from": "1965-10-06"
            }
          }
        ],
        "AL": [
          {
            "ALK": {
              "_from": "1946-11-01",
              "_to": "1965-08-16"
            }
          },
          {
            "ALL": {
              "_from": "1965-08-16"
            }
          }
        ],
        "AM": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1991-12-25"
            }
          },
          {
            "RUR": {
              "_from": "1991-12-25",
              "_to": "1993-11-22"
            }
          },
          {
            "AMD": {
              "_from": "1993-11-22"
            }
          }
        ],
        "AO": [
          {
            "AOK": {
              "_from": "1977-01-08",
              "_to": "1991-03-01"
            }
          },
          {
            "AON": {
              "_from": "1990-09-25",
              "_to": "2000-02-01"
            }
          },
          {
            "AOR": {
              "_from": "1995-07-01",
              "_to": "2000-02-01"
            }
          },
          {
            "AOA": {
              "_from": "1999-12-13"
            }
          }
        ],
        "AQ": [
          {
            "XXX": {
              "_tender": "false"
            }
          }
        ],
        "AR": [
          {
            "ARM": {
              "_from": "1881-11-05",
              "_to": "1970-01-01"
            }
          },
          {
            "ARL": {
              "_from": "1970-01-01",
              "_to": "1983-06-01"
            }
          },
          {
            "ARP": {
              "_from": "1983-06-01",
              "_to": "1985-06-14"
            }
          },
          {
            "ARA": {
              "_from": "1985-06-14",
              "_to": "1992-01-01"
            }
          },
          {
            "ARS": {
              "_from": "1992-01-01"
            }
          }
        ],
        "AS": [
          {
            "USD": {
              "_from": "1904-07-16"
            }
          }
        ],
        "AT": [
          {
            "ATS": {
              "_from": "1947-12-04",
              "_to": "2002-02-28"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "AU": [
          {
            "AUD": {
              "_from": "1966-02-14"
            }
          }
        ],
        "AW": [
          {
            "ANG": {
              "_from": "1940-05-10",
              "_to": "1986-01-01"
            }
          },
          {
            "AWG": {
              "_from": "1986-01-01"
            }
          }
        ],
        "AX": [
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "AZ": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1991-12-25"
            }
          },
          {
            "RUR": {
              "_from": "1991-12-25",
              "_to": "1994-01-01"
            }
          },
          {
            "AZM": {
              "_from": "1993-11-22",
              "_to": "2006-12-31"
            }
          },
          {
            "AZN": {
              "_from": "2006-01-01"
            }
          }
        ],
        "BA": [
          {
            "YUD": {
              "_from": "1966-01-01",
              "_to": "1990-01-01"
            }
          },
          {
            "YUN": {
              "_from": "1990-01-01",
              "_to": "1992-07-01"
            }
          },
          {
            "YUR": {
              "_from": "1992-07-01",
              "_to": "1993-10-01"
            }
          },
          {
            "BAD": {
              "_from": "1992-07-01",
              "_to": "1994-08-15"
            }
          },
          {
            "BAN": {
              "_from": "1994-08-15",
              "_to": "1997-07-01"
            }
          },
          {
            "BAM": {
              "_from": "1995-01-01"
            }
          }
        ],
        "BB": [
          {
            "XCD": {
              "_from": "1965-10-06",
              "_to": "1973-12-03"
            }
          },
          {
            "BBD": {
              "_from": "1973-12-03"
            }
          }
        ],
        "BD": [
          {
            "INR": {
              "_from": "1835-08-17",
              "_to": "1948-04-01"
            }
          },
          {
            "PKR": {
              "_from": "1948-04-01",
              "_to": "1972-01-01"
            }
          },
          {
            "BDT": {
              "_from": "1972-01-01"
            }
          }
        ],
        "BE": [
          {
            "NLG": {
              "_from": "1816-12-15",
              "_to": "1831-02-07"
            }
          },
          {
            "BEF": {
              "_from": "1831-02-07",
              "_to": "2002-02-28"
            }
          },
          {
            "BEC": {
              "_tender": "false",
              "_from": "1970-01-01",
              "_to": "1990-03-05"
            }
          },
          {
            "BEL": {
              "_tender": "false",
              "_from": "1970-01-01",
              "_to": "1990-03-05"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "BF": [
          {
            "XOF": {
              "_from": "1984-08-04"
            }
          }
        ],
        "BG": [
          {
            "BGO": {
              "_from": "1879-07-08",
              "_to": "1952-05-12"
            }
          },
          {
            "BGM": {
              "_from": "1952-05-12",
              "_to": "1962-01-01"
            }
          },
          {
            "BGL": {
              "_from": "1962-01-01",
              "_to": "1999-07-05"
            }
          },
          {
            "BGN": {
              "_from": "1999-07-05"
            }
          }
        ],
        "BH": [
          {
            "BHD": {
              "_from": "1965-10-16"
            }
          }
        ],
        "BI": [
          {
            "BIF": {
              "_from": "1964-05-19"
            }
          }
        ],
        "BJ": [
          {
            "XOF": {
              "_from": "1975-11-30"
            }
          }
        ],
        "BL": [
          {
            "FRF": {
              "_from": "1960-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "BM": [
          {
            "BMD": {
              "_from": "1970-02-06"
            }
          }
        ],
        "BN": [
          {
            "MYR": {
              "_from": "1963-09-16",
              "_to": "1967-06-12"
            }
          },
          {
            "BND": {
              "_from": "1967-06-12"
            }
          }
        ],
        "BO": [
          {
            "BOV": {
              "_tender": "false"
            }
          },
          {
            "BOL": {
              "_from": "1863-06-23",
              "_to": "1963-01-01"
            }
          },
          {
            "BOP": {
              "_from": "1963-01-01",
              "_to": "1986-12-31"
            }
          },
          {
            "BOB": {
              "_from": "1987-01-01"
            }
          }
        ],
        "BQ": [
          {
            "ANG": {
              "_from": "2010-10-10",
              "_to": "2011-01-01"
            }
          },
          {
            "USD": {
              "_from": "2011-01-01"
            }
          }
        ],
        "BR": [
          {
            "BRZ": {
              "_from": "1942-11-01",
              "_to": "1967-02-13"
            }
          },
          {
            "BRB": {
              "_from": "1967-02-13",
              "_to": "1986-02-28"
            }
          },
          {
            "BRC": {
              "_from": "1986-02-28",
              "_to": "1989-01-15"
            }
          },
          {
            "BRN": {
              "_from": "1989-01-15",
              "_to": "1990-03-16"
            }
          },
          {
            "BRE": {
              "_from": "1990-03-16",
              "_to": "1993-08-01"
            }
          },
          {
            "BRR": {
              "_from": "1993-08-01",
              "_to": "1994-07-01"
            }
          },
          {
            "BRL": {
              "_from": "1994-07-01"
            }
          }
        ],
        "BS": [
          {
            "BSD": {
              "_from": "1966-05-25"
            }
          }
        ],
        "BT": [
          {
            "INR": {
              "_from": "1907-01-01"
            }
          },
          {
            "BTN": {
              "_from": "1974-04-16"
            }
          }
        ],
        "BU": [
          {
            "BUK": {
              "_from": "1952-07-01",
              "_to": "1989-06-18"
            }
          }
        ],
        "BV": [
          {
            "NOK": {
              "_from": "1905-06-07"
            }
          }
        ],
        "BW": [
          {
            "ZAR": {
              "_from": "1961-02-14",
              "_to": "1976-08-23"
            }
          },
          {
            "BWP": {
              "_from": "1976-08-23"
            }
          }
        ],
        "BY": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1991-12-25"
            }
          },
          {
            "RUR": {
              "_from": "1991-12-25",
              "_to": "1994-11-08"
            }
          },
          {
            "BYB": {
              "_from": "1994-08-01",
              "_to": "2000-12-31"
            }
          },
          {
            "BYR": {
              "_from": "2000-01-01",
              "_to": "2017-01-01"
            }
          },
          {
            "BYN": {
              "_from": "2016-07-01"
            }
          }
        ],
        "BZ": [
          {
            "BZD": {
              "_from": "1974-01-01"
            }
          }
        ],
        "CA": [
          {
            "CAD": {
              "_from": "1858-01-01"
            }
          }
        ],
        "CC": [
          {
            "AUD": {
              "_from": "1966-02-14"
            }
          }
        ],
        "CD": [
          {
            "ZRZ": {
              "_from": "1971-10-27",
              "_to": "1993-11-01"
            }
          },
          {
            "ZRN": {
              "_from": "1993-11-01",
              "_to": "1998-07-01"
            }
          },
          {
            "CDF": {
              "_from": "1998-07-01"
            }
          }
        ],
        "CF": [
          {
            "XAF": {
              "_from": "1993-01-01"
            }
          }
        ],
        "CG": [
          {
            "XAF": {
              "_from": "1993-01-01"
            }
          }
        ],
        "CH": [
          {
            "CHE": {
              "_tender": "false"
            }
          },
          {
            "CHW": {
              "_tender": "false"
            }
          },
          {
            "CHF": {
              "_from": "1799-03-17"
            }
          }
        ],
        "CI": [
          {
            "XOF": {
              "_from": "1958-12-04"
            }
          }
        ],
        "CK": [
          {
            "NZD": {
              "_from": "1967-07-10"
            }
          }
        ],
        "CL": [
          {
            "CLF": {
              "_tender": "false"
            }
          },
          {
            "CLE": {
              "_from": "1960-01-01",
              "_to": "1975-09-29"
            }
          },
          {
            "CLP": {
              "_from": "1975-09-29"
            }
          }
        ],
        "CM": [
          {
            "XAF": {
              "_from": "1973-04-01"
            }
          }
        ],
        "CN": [
          {
            "CNY": {
              "_from": "1953-03-01"
            }
          },
          {
            "CNX": {
              "_tender": "false",
              "_from": "1979-01-01",
              "_to": "1998-12-31"
            }
          }
        ],
        "CO": [
          {
            "COU": {
              "_tender": "false"
            }
          },
          {
            "COP": {
              "_from": "1905-01-01"
            }
          }
        ],
        "CP": [
          {
            "XXX": {
              "_tender": "false"
            }
          }
        ],
        "CR": [
          {
            "CRC": {
              "_from": "1896-10-26"
            }
          }
        ],
        "CS": [
          {
            "YUM": {
              "_from": "1994-01-24",
              "_to": "2002-05-15"
            }
          },
          {
            "CSD": {
              "_from": "2002-05-15",
              "_to": "2006-06-03"
            }
          },
          {
            "EUR": {
              "_from": "2003-02-04",
              "_to": "2006-06-03"
            }
          }
        ],
        "CU": [
          {
            "CUP": {
              "_from": "1859-01-01"
            }
          },
          {
            "USD": {
              "_from": "1899-01-01",
              "_to": "1959-01-01"
            }
          },
          {
            "CUC": {
              "_from": "1994-01-01"
            }
          }
        ],
        "CV": [
          {
            "PTE": {
              "_from": "1911-05-22",
              "_to": "1975-07-05"
            }
          },
          {
            "CVE": {
              "_from": "1914-01-01"
            }
          }
        ],
        "CW": [
          {
            "ANG": {
              "_from": "2010-10-10"
            }
          }
        ],
        "CX": [
          {
            "AUD": {
              "_from": "1966-02-14"
            }
          }
        ],
        "CY": [
          {
            "CYP": {
              "_from": "1914-09-10",
              "_to": "2008-01-31"
            }
          },
          {
            "EUR": {
              "_from": "2008-01-01"
            }
          }
        ],
        "CZ": [
          {
            "CSK": {
              "_from": "1953-06-01",
              "_to": "1993-03-01"
            }
          },
          {
            "CZK": {
              "_from": "1993-01-01"
            }
          }
        ],
        "DD": [
          {
            "DDM": {
              "_from": "1948-07-20",
              "_to": "1990-10-02"
            }
          }
        ],
        "DE": [
          {
            "DEM": {
              "_from": "1948-06-20",
              "_to": "2002-02-28"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "DG": [
          {
            "USD": {
              "_from": "1965-11-08"
            }
          }
        ],
        "DJ": [
          {
            "DJF": {
              "_from": "1977-06-27"
            }
          }
        ],
        "DK": [
          {
            "DKK": {
              "_from": "1873-05-27"
            }
          }
        ],
        "DM": [
          {
            "XCD": {
              "_from": "1965-10-06"
            }
          }
        ],
        "DO": [
          {
            "USD": {
              "_from": "1905-06-21",
              "_to": "1947-10-01"
            }
          },
          {
            "DOP": {
              "_from": "1947-10-01"
            }
          }
        ],
        "DZ": [
          {
            "DZD": {
              "_from": "1964-04-01"
            }
          }
        ],
        "EA": [
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "EC": [
          {
            "ECS": {
              "_from": "1884-04-01",
              "_to": "2000-10-02"
            }
          },
          {
            "ECV": {
              "_tender": "false",
              "_from": "1993-05-23",
              "_to": "2000-01-09"
            }
          },
          {
            "USD": {
              "_from": "2000-10-02"
            }
          }
        ],
        "EE": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1992-06-20"
            }
          },
          {
            "EEK": {
              "_from": "1992-06-21",
              "_to": "2010-12-31"
            }
          },
          {
            "EUR": {
              "_from": "2011-01-01"
            }
          }
        ],
        "EG": [
          {
            "EGP": {
              "_from": "1885-11-14"
            }
          }
        ],
        "EH": [
          {
            "MAD": {
              "_from": "1976-02-26"
            }
          }
        ],
        "ER": [
          {
            "ETB": {
              "_from": "1993-05-24",
              "_to": "1997-11-08"
            }
          },
          {
            "ERN": {
              "_from": "1997-11-08"
            }
          }
        ],
        "ES": [
          {
            "ESP": {
              "_from": "1868-10-19",
              "_to": "2002-02-28"
            }
          },
          {
            "ESB": {
              "_tender": "false",
              "_from": "1975-01-01",
              "_to": "1994-12-31"
            }
          },
          {
            "ESA": {
              "_tender": "false",
              "_from": "1978-01-01",
              "_to": "1981-12-31"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "ET": [
          {
            "ETB": {
              "_from": "1976-09-15"
            }
          }
        ],
        "EU": [
          {
            "XEU": {
              "_tender": "false",
              "_from": "1979-01-01",
              "_to": "1998-12-31"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "FI": [
          {
            "FIM": {
              "_from": "1963-01-01",
              "_to": "2002-02-28"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "FJ": [
          {
            "FJD": {
              "_from": "1969-01-13"
            }
          }
        ],
        "FK": [
          {
            "FKP": {
              "_from": "1901-01-01"
            }
          }
        ],
        "FM": [
          {
            "JPY": {
              "_from": "1914-10-03",
              "_to": "1944-01-01"
            }
          },
          {
            "USD": {
              "_from": "1944-01-01"
            }
          }
        ],
        "FO": [
          {
            "DKK": {
              "_from": "1948-01-01"
            }
          }
        ],
        "FR": [
          {
            "FRF": {
              "_from": "1960-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "GA": [
          {
            "XAF": {
              "_from": "1993-01-01"
            }
          }
        ],
        "GB": [
          {
            "GBP": {
              "_from": "1694-07-27"
            }
          }
        ],
        "GD": [
          {
            "XCD": {
              "_from": "1967-02-27"
            }
          }
        ],
        "GE": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1991-12-25"
            }
          },
          {
            "RUR": {
              "_from": "1991-12-25",
              "_to": "1993-06-11"
            }
          },
          {
            "GEK": {
              "_from": "1993-04-05",
              "_to": "1995-09-25"
            }
          },
          {
            "GEL": {
              "_from": "1995-09-23"
            }
          }
        ],
        "GF": [
          {
            "FRF": {
              "_from": "1960-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "GG": [
          {
            "GBP": {
              "_from": "1830-01-01"
            }
          }
        ],
        "GH": [
          {
            "GHC": {
              "_from": "1979-03-09",
              "_to": "2007-12-31"
            }
          },
          {
            "GHS": {
              "_from": "2007-07-03"
            }
          }
        ],
        "GI": [
          {
            "GIP": {
              "_from": "1713-01-01"
            }
          }
        ],
        "GL": [
          {
            "DKK": {
              "_from": "1873-05-27"
            }
          }
        ],
        "GM": [
          {
            "GMD": {
              "_from": "1971-07-01"
            }
          }
        ],
        "GN": [
          {
            "GNS": {
              "_from": "1972-10-02",
              "_to": "1986-01-06"
            }
          },
          {
            "GNF": {
              "_from": "1986-01-06"
            }
          }
        ],
        "GP": [
          {
            "FRF": {
              "_from": "1960-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "GQ": [
          {
            "GQE": {
              "_from": "1975-07-07",
              "_to": "1986-06-01"
            }
          },
          {
            "XAF": {
              "_from": "1993-01-01"
            }
          }
        ],
        "GR": [
          {
            "GRD": {
              "_from": "1954-05-01",
              "_to": "2002-02-28"
            }
          },
          {
            "EUR": {
              "_from": "2001-01-01"
            }
          }
        ],
        "GS": [
          {
            "GBP": {
              "_from": "1908-01-01"
            }
          }
        ],
        "GT": [
          {
            "GTQ": {
              "_from": "1925-05-27"
            }
          }
        ],
        "GU": [
          {
            "USD": {
              "_from": "1944-08-21"
            }
          }
        ],
        "GW": [
          {
            "GWE": {
              "_from": "1914-01-01",
              "_to": "1976-02-28"
            }
          },
          {
            "GWP": {
              "_from": "1976-02-28",
              "_to": "1997-03-31"
            }
          },
          {
            "XOF": {
              "_from": "1997-03-31"
            }
          }
        ],
        "GY": [
          {
            "GYD": {
              "_from": "1966-05-26"
            }
          }
        ],
        "HK": [
          {
            "HKD": {
              "_from": "1895-02-02"
            }
          }
        ],
        "HM": [
          {
            "AUD": {
              "_from": "1967-02-16"
            }
          }
        ],
        "HN": [
          {
            "HNL": {
              "_from": "1926-04-03"
            }
          }
        ],
        "HR": [
          {
            "YUD": {
              "_from": "1966-01-01",
              "_to": "1990-01-01"
            }
          },
          {
            "YUN": {
              "_from": "1990-01-01",
              "_to": "1991-12-23"
            }
          },
          {
            "HRD": {
              "_from": "1991-12-23",
              "_to": "1995-01-01"
            }
          },
          {
            "HRK": {
              "_from": "1994-05-30"
            }
          }
        ],
        "HT": [
          {
            "HTG": {
              "_from": "1872-08-26"
            }
          },
          {
            "USD": {
              "_from": "1915-01-01"
            }
          }
        ],
        "HU": [
          {
            "HUF": {
              "_from": "1946-07-23"
            }
          }
        ],
        "IC": [
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "ID": [
          {
            "IDR": {
              "_from": "1965-12-13"
            }
          }
        ],
        "IE": [
          {
            "GBP": {
              "_from": "1800-01-01",
              "_to": "1922-01-01"
            }
          },
          {
            "IEP": {
              "_from": "1922-01-01",
              "_to": "2002-02-09"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "IL": [
          {
            "ILP": {
              "_from": "1948-08-16",
              "_to": "1980-02-22"
            }
          },
          {
            "ILR": {
              "_from": "1980-02-22",
              "_to": "1985-09-04"
            }
          },
          {
            "ILS": {
              "_from": "1985-09-04"
            }
          }
        ],
        "IM": [
          {
            "GBP": {
              "_from": "1840-01-03"
            }
          }
        ],
        "IN": [
          {
            "INR": {
              "_from": "1835-08-17"
            }
          }
        ],
        "IO": [
          {
            "USD": {
              "_from": "1965-11-08"
            }
          }
        ],
        "IQ": [
          {
            "EGP": {
              "_from": "1920-11-11",
              "_to": "1931-04-19"
            }
          },
          {
            "INR": {
              "_from": "1920-11-11",
              "_to": "1931-04-19"
            }
          },
          {
            "IQD": {
              "_from": "1931-04-19"
            }
          }
        ],
        "IR": [
          {
            "IRR": {
              "_from": "1932-05-13"
            }
          }
        ],
        "IS": [
          {
            "DKK": {
              "_from": "1873-05-27",
              "_to": "1918-12-01"
            }
          },
          {
            "ISJ": {
              "_from": "1918-12-01",
              "_to": "1981-01-01"
            }
          },
          {
            "ISK": {
              "_from": "1981-01-01"
            }
          }
        ],
        "IT": [
          {
            "ITL": {
              "_from": "1862-08-24",
              "_to": "2002-02-28"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "JE": [
          {
            "GBP": {
              "_from": "1837-01-01"
            }
          }
        ],
        "JM": [
          {
            "JMD": {
              "_from": "1969-09-08"
            }
          }
        ],
        "JO": [
          {
            "JOD": {
              "_from": "1950-07-01"
            }
          }
        ],
        "JP": [
          {
            "JPY": {
              "_from": "1871-06-01"
            }
          }
        ],
        "KE": [
          {
            "KES": {
              "_from": "1966-09-14"
            }
          }
        ],
        "KG": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1991-12-25"
            }
          },
          {
            "RUR": {
              "_from": "1991-12-25",
              "_to": "1993-05-10"
            }
          },
          {
            "KGS": {
              "_from": "1993-05-10"
            }
          }
        ],
        "KH": [
          {
            "KHR": {
              "_from": "1980-03-20"
            }
          }
        ],
        "KI": [
          {
            "AUD": {
              "_from": "1966-02-14"
            }
          }
        ],
        "KM": [
          {
            "KMF": {
              "_from": "1975-07-06"
            }
          }
        ],
        "KN": [
          {
            "XCD": {
              "_from": "1965-10-06"
            }
          }
        ],
        "KP": [
          {
            "KPW": {
              "_from": "1959-04-17"
            }
          }
        ],
        "KR": [
          {
            "KRO": {
              "_from": "1945-08-15",
              "_to": "1953-02-15"
            }
          },
          {
            "KRH": {
              "_from": "1953-02-15",
              "_to": "1962-06-10"
            }
          },
          {
            "KRW": {
              "_from": "1962-06-10"
            }
          }
        ],
        "KW": [
          {
            "KWD": {
              "_from": "1961-04-01"
            }
          }
        ],
        "KY": [
          {
            "JMD": {
              "_from": "1969-09-08",
              "_to": "1971-01-01"
            }
          },
          {
            "KYD": {
              "_from": "1971-01-01"
            }
          }
        ],
        "KZ": [
          {
            "KZT": {
              "_from": "1993-11-05"
            }
          }
        ],
        "LA": [
          {
            "LAK": {
              "_from": "1979-12-10"
            }
          }
        ],
        "LB": [
          {
            "LBP": {
              "_from": "1948-02-02"
            }
          }
        ],
        "LC": [
          {
            "XCD": {
              "_from": "1965-10-06"
            }
          }
        ],
        "LI": [
          {
            "CHF": {
              "_from": "1921-02-01"
            }
          }
        ],
        "LK": [
          {
            "LKR": {
              "_from": "1978-05-22"
            }
          }
        ],
        "LR": [
          {
            "LRD": {
              "_from": "1944-01-01"
            }
          }
        ],
        "LS": [
          {
            "ZAR": {
              "_from": "1961-02-14"
            }
          },
          {
            "LSL": {
              "_from": "1980-01-22"
            }
          }
        ],
        "LT": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1992-10-01"
            }
          },
          {
            "LTT": {
              "_from": "1992-10-01",
              "_to": "1993-06-25"
            }
          },
          {
            "LTL": {
              "_from": "1993-06-25",
              "_to": "2014-12-31"
            }
          },
          {
            "EUR": {
              "_from": "2015-01-01"
            }
          }
        ],
        "LU": [
          {
            "LUF": {
              "_from": "1944-09-04",
              "_to": "2002-02-28"
            }
          },
          {
            "LUC": {
              "_tender": "false",
              "_from": "1970-01-01",
              "_to": "1990-03-05"
            }
          },
          {
            "LUL": {
              "_tender": "false",
              "_from": "1970-01-01",
              "_to": "1990-03-05"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "LV": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1992-07-20"
            }
          },
          {
            "LVR": {
              "_from": "1992-05-07",
              "_to": "1993-10-17"
            }
          },
          {
            "LVL": {
              "_from": "1993-06-28",
              "_to": "2013-12-31"
            }
          },
          {
            "EUR": {
              "_from": "2014-01-01"
            }
          }
        ],
        "LY": [
          {
            "LYD": {
              "_from": "1971-09-01"
            }
          }
        ],
        "MA": [
          {
            "MAF": {
              "_from": "1881-01-01",
              "_to": "1959-10-17"
            }
          },
          {
            "MAD": {
              "_from": "1959-10-17"
            }
          }
        ],
        "MC": [
          {
            "FRF": {
              "_from": "1960-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "MCF": {
              "_from": "1960-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "MD": [
          {
            "MDC": {
              "_from": "1992-06-01",
              "_to": "1993-11-29"
            }
          },
          {
            "MDL": {
              "_from": "1993-11-29"
            }
          }
        ],
        "ME": [
          {
            "YUM": {
              "_from": "1994-01-24",
              "_to": "2002-05-15"
            }
          },
          {
            "DEM": {
              "_from": "1999-10-02",
              "_to": "2002-05-15"
            }
          },
          {
            "EUR": {
              "_from": "2002-01-01"
            }
          }
        ],
        "MF": [
          {
            "FRF": {
              "_from": "1960-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "MG": [
          {
            "MGF": {
              "_from": "1963-07-01",
              "_to": "2004-12-31"
            }
          },
          {
            "MGA": {
              "_from": "1983-11-01"
            }
          }
        ],
        "MH": [
          {
            "USD": {
              "_from": "1944-01-01"
            }
          }
        ],
        "MK": [
          {
            "MKN": {
              "_from": "1992-04-26",
              "_to": "1993-05-20"
            }
          },
          {
            "MKD": {
              "_from": "1993-05-20"
            }
          }
        ],
        "ML": [
          {
            "XOF": {
              "_from": "1958-11-24",
              "_to": "1962-07-02"
            }
          },
          {
            "MLF": {
              "_from": "1962-07-02",
              "_to": "1984-08-31"
            }
          },
          {
            "XOF": {
              "_from": "1984-06-01"
            }
          }
        ],
        "MM": [
          {
            "BUK": {
              "_from": "1952-07-01",
              "_to": "1989-06-18"
            }
          },
          {
            "MMK": {
              "_from": "1989-06-18"
            }
          }
        ],
        "MN": [
          {
            "MNT": {
              "_from": "1915-03-01"
            }
          }
        ],
        "MO": [
          {
            "MOP": {
              "_from": "1901-01-01"
            }
          }
        ],
        "MP": [
          {
            "USD": {
              "_from": "1944-01-01"
            }
          }
        ],
        "MQ": [
          {
            "FRF": {
              "_from": "1960-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "MR": [
          {
            "XOF": {
              "_from": "1958-11-28",
              "_to": "1973-06-29"
            }
          },
          {
            "MRO": {
              "_from": "1973-06-29"
            }
          }
        ],
        "MS": [
          {
            "XCD": {
              "_from": "1967-02-27"
            }
          }
        ],
        "MT": [
          {
            "MTP": {
              "_from": "1914-08-13",
              "_to": "1968-06-07"
            }
          },
          {
            "MTL": {
              "_from": "1968-06-07",
              "_to": "2008-01-31"
            }
          },
          {
            "EUR": {
              "_from": "2008-01-01"
            }
          }
        ],
        "MU": [
          {
            "MUR": {
              "_from": "1934-04-01"
            }
          }
        ],
        "MV": [
          {
            "MVR": {
              "_from": "1981-07-01"
            }
          }
        ],
        "MW": [
          {
            "MWK": {
              "_from": "1971-02-15"
            }
          }
        ],
        "MX": [
          {
            "MXV": {
              "_tender": "false"
            }
          },
          {
            "MXP": {
              "_from": "1822-01-01",
              "_to": "1992-12-31"
            }
          },
          {
            "MXN": {
              "_from": "1993-01-01"
            }
          }
        ],
        "MY": [
          {
            "MYR": {
              "_from": "1963-09-16"
            }
          }
        ],
        "MZ": [
          {
            "MZE": {
              "_from": "1975-06-25",
              "_to": "1980-06-16"
            }
          },
          {
            "MZM": {
              "_from": "1980-06-16",
              "_to": "2006-12-31"
            }
          },
          {
            "MZN": {
              "_from": "2006-07-01"
            }
          }
        ],
        "NA": [
          {
            "ZAR": {
              "_from": "1961-02-14"
            }
          },
          {
            "NAD": {
              "_from": "1993-01-01"
            }
          }
        ],
        "NC": [
          {
            "XPF": {
              "_from": "1985-01-01"
            }
          }
        ],
        "NE": [
          {
            "XOF": {
              "_from": "1958-12-19"
            }
          }
        ],
        "NF": [
          {
            "AUD": {
              "_from": "1966-02-14"
            }
          }
        ],
        "NG": [
          {
            "NGN": {
              "_from": "1973-01-01"
            }
          }
        ],
        "NI": [
          {
            "NIC": {
              "_from": "1988-02-15",
              "_to": "1991-04-30"
            }
          },
          {
            "NIO": {
              "_from": "1991-04-30"
            }
          }
        ],
        "NL": [
          {
            "NLG": {
              "_from": "1813-01-01",
              "_to": "2002-02-28"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "NO": [
          {
            "SEK": {
              "_from": "1873-05-27",
              "_to": "1905-06-07"
            }
          },
          {
            "NOK": {
              "_from": "1905-06-07"
            }
          }
        ],
        "NP": [
          {
            "INR": {
              "_from": "1870-01-01",
              "_to": "1966-10-17"
            }
          },
          {
            "NPR": {
              "_from": "1933-01-01"
            }
          }
        ],
        "NR": [
          {
            "AUD": {
              "_from": "1966-02-14"
            }
          }
        ],
        "NU": [
          {
            "NZD": {
              "_from": "1967-07-10"
            }
          }
        ],
        "NZ": [
          {
            "NZD": {
              "_from": "1967-07-10"
            }
          }
        ],
        "OM": [
          {
            "OMR": {
              "_from": "1972-11-11"
            }
          }
        ],
        "PA": [
          {
            "PAB": {
              "_from": "1903-11-04"
            }
          },
          {
            "USD": {
              "_from": "1903-11-18"
            }
          }
        ],
        "PE": [
          {
            "PES": {
              "_from": "1863-02-14",
              "_to": "1985-02-01"
            }
          },
          {
            "PEI": {
              "_from": "1985-02-01",
              "_to": "1991-07-01"
            }
          },
          {
            "PEN": {
              "_from": "1991-07-01"
            }
          }
        ],
        "PF": [
          {
            "XPF": {
              "_from": "1945-12-26"
            }
          }
        ],
        "PG": [
          {
            "AUD": {
              "_from": "1966-02-14",
              "_to": "1975-09-16"
            }
          },
          {
            "PGK": {
              "_from": "1975-09-16"
            }
          }
        ],
        "PH": [
          {
            "PHP": {
              "_from": "1946-07-04"
            }
          }
        ],
        "PK": [
          {
            "INR": {
              "_from": "1835-08-17",
              "_to": "1947-08-15"
            }
          },
          {
            "PKR": {
              "_from": "1948-04-01"
            }
          }
        ],
        "PL": [
          {
            "PLZ": {
              "_from": "1950-10-28",
              "_to": "1994-12-31"
            }
          },
          {
            "PLN": {
              "_from": "1995-01-01"
            }
          }
        ],
        "PM": [
          {
            "FRF": {
              "_from": "1972-12-21",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "PN": [
          {
            "NZD": {
              "_from": "1969-01-13"
            }
          }
        ],
        "PR": [
          {
            "ESP": {
              "_from": "1800-01-01",
              "_to": "1898-12-10"
            }
          },
          {
            "USD": {
              "_from": "1898-12-10"
            }
          }
        ],
        "PS": [
          {
            "JOD": {
              "_from": "1950-07-01",
              "_to": "1967-06-01"
            }
          },
          {
            "ILP": {
              "_from": "1967-06-01",
              "_to": "1980-02-22"
            }
          },
          {
            "ILS": {
              "_from": "1985-09-04"
            }
          },
          {
            "JOD": {
              "_from": "1996-02-12"
            }
          }
        ],
        "PT": [
          {
            "PTE": {
              "_from": "1911-05-22",
              "_to": "2002-02-28"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "PW": [
          {
            "USD": {
              "_from": "1944-01-01"
            }
          }
        ],
        "PY": [
          {
            "PYG": {
              "_from": "1943-11-01"
            }
          }
        ],
        "QA": [
          {
            "QAR": {
              "_from": "1973-05-19"
            }
          }
        ],
        "RE": [
          {
            "FRF": {
              "_from": "1975-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "RO": [
          {
            "ROL": {
              "_from": "1952-01-28",
              "_to": "2006-12-31"
            }
          },
          {
            "RON": {
              "_from": "2005-07-01"
            }
          }
        ],
        "RS": [
          {
            "YUM": {
              "_from": "1994-01-24",
              "_to": "2002-05-15"
            }
          },
          {
            "CSD": {
              "_from": "2002-05-15",
              "_to": "2006-10-25"
            }
          },
          {
            "RSD": {
              "_from": "2006-10-25"
            }
          }
        ],
        "RU": [
          {
            "RUR": {
              "_from": "1991-12-25",
              "_to": "1998-12-31"
            }
          },
          {
            "RUB": {
              "_from": "1999-01-01"
            }
          }
        ],
        "RW": [
          {
            "RWF": {
              "_from": "1964-05-19"
            }
          }
        ],
        "SA": [
          {
            "SAR": {
              "_from": "1952-10-22"
            }
          }
        ],
        "SB": [
          {
            "AUD": {
              "_from": "1966-02-14",
              "_to": "1978-06-30"
            }
          },
          {
            "SBD": {
              "_from": "1977-10-24"
            }
          }
        ],
        "SC": [
          {
            "SCR": {
              "_from": "1903-11-01"
            }
          }
        ],
        "SD": [
          {
            "EGP": {
              "_from": "1889-01-19",
              "_to": "1958-01-01"
            }
          },
          {
            "GBP": {
              "_from": "1889-01-19",
              "_to": "1958-01-01"
            }
          },
          {
            "SDP": {
              "_from": "1957-04-08",
              "_to": "1998-06-01"
            }
          },
          {
            "SDD": {
              "_from": "1992-06-08",
              "_to": "2007-06-30"
            }
          },
          {
            "SDG": {
              "_from": "2007-01-10"
            }
          }
        ],
        "SE": [
          {
            "SEK": {
              "_from": "1873-05-27"
            }
          }
        ],
        "SG": [
          {
            "MYR": {
              "_from": "1963-09-16",
              "_to": "1967-06-12"
            }
          },
          {
            "SGD": {
              "_from": "1967-06-12"
            }
          }
        ],
        "SH": [
          {
            "SHP": {
              "_from": "1917-02-15"
            }
          }
        ],
        "SI": [
          {
            "SIT": {
              "_from": "1992-10-07",
              "_to": "2007-01-14"
            }
          },
          {
            "EUR": {
              "_from": "2007-01-01"
            }
          }
        ],
        "SJ": [
          {
            "NOK": {
              "_from": "1905-06-07"
            }
          }
        ],
        "SK": [
          {
            "CSK": {
              "_from": "1953-06-01",
              "_to": "1992-12-31"
            }
          },
          {
            "SKK": {
              "_from": "1992-12-31",
              "_to": "2009-01-01"
            }
          },
          {
            "EUR": {
              "_from": "2009-01-01"
            }
          }
        ],
        "SL": [
          {
            "GBP": {
              "_from": "1808-11-30",
              "_to": "1966-02-04"
            }
          },
          {
            "SLL": {
              "_from": "1964-08-04"
            }
          }
        ],
        "SM": [
          {
            "ITL": {
              "_from": "1865-12-23",
              "_to": "2001-02-28"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "SN": [
          {
            "XOF": {
              "_from": "1959-04-04"
            }
          }
        ],
        "SO": [
          {
            "SOS": {
              "_from": "1960-07-01"
            }
          }
        ],
        "SR": [
          {
            "NLG": {
              "_from": "1815-11-20",
              "_to": "1940-05-10"
            }
          },
          {
            "SRG": {
              "_from": "1940-05-10",
              "_to": "2003-12-31"
            }
          },
          {
            "SRD": {
              "_from": "2004-01-01"
            }
          }
        ],
        "SS": [
          {
            "SDG": {
              "_from": "2007-01-10",
              "_to": "2011-09-01"
            }
          },
          {
            "SSP": {
              "_from": "2011-07-18"
            }
          }
        ],
        "ST": [
          {
            "STD": {
              "_from": "1977-09-08"
            }
          }
        ],
        "SU": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1991-12-25"
            }
          }
        ],
        "SV": [
          {
            "SVC": {
              "_from": "1919-11-11",
              "_to": "2001-01-01"
            }
          },
          {
            "USD": {
              "_from": "2001-01-01"
            }
          }
        ],
        "SX": [
          {
            "ANG": {
              "_from": "2010-10-10"
            }
          }
        ],
        "SY": [
          {
            "SYP": {
              "_from": "1948-01-01"
            }
          }
        ],
        "SZ": [
          {
            "SZL": {
              "_from": "1974-09-06"
            }
          }
        ],
        "TA": [
          {
            "GBP": {
              "_from": "1938-01-12"
            }
          }
        ],
        "TC": [
          {
            "USD": {
              "_from": "1969-09-08"
            }
          }
        ],
        "TD": [
          {
            "XAF": {
              "_from": "1993-01-01"
            }
          }
        ],
        "TF": [
          {
            "FRF": {
              "_from": "1959-01-01",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "TG": [
          {
            "XOF": {
              "_from": "1958-11-28"
            }
          }
        ],
        "TH": [
          {
            "THB": {
              "_from": "1928-04-15"
            }
          }
        ],
        "TJ": [
          {
            "RUR": {
              "_from": "1991-12-25",
              "_to": "1995-05-10"
            }
          },
          {
            "TJR": {
              "_from": "1995-05-10",
              "_to": "2000-10-25"
            }
          },
          {
            "TJS": {
              "_from": "2000-10-26"
            }
          }
        ],
        "TK": [
          {
            "NZD": {
              "_from": "1967-07-10"
            }
          }
        ],
        "TL": [
          {
            "TPE": {
              "_from": "1959-01-02",
              "_to": "2002-05-20"
            }
          },
          {
            "IDR": {
              "_from": "1975-12-07",
              "_to": "2002-05-20"
            }
          },
          {
            "USD": {
              "_from": "1999-10-20"
            }
          }
        ],
        "TM": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1991-12-25"
            }
          },
          {
            "RUR": {
              "_from": "1991-12-25",
              "_to": "1993-11-01"
            }
          },
          {
            "TMM": {
              "_from": "1993-11-01",
              "_to": "2009-01-01"
            }
          },
          {
            "TMT": {
              "_from": "2009-01-01"
            }
          }
        ],
        "TN": [
          {
            "TND": {
              "_from": "1958-11-01"
            }
          }
        ],
        "TO": [
          {
            "TOP": {
              "_from": "1966-02-14"
            }
          }
        ],
        "TP": [
          {
            "TPE": {
              "_from": "1959-01-02",
              "_to": "2002-05-20"
            }
          },
          {
            "IDR": {
              "_from": "1975-12-07",
              "_to": "2002-05-20"
            }
          }
        ],
        "TR": [
          {
            "TRL": {
              "_from": "1922-11-01",
              "_to": "2005-12-31"
            }
          },
          {
            "TRY": {
              "_from": "2005-01-01"
            }
          }
        ],
        "TT": [
          {
            "TTD": {
              "_from": "1964-01-01"
            }
          }
        ],
        "TV": [
          {
            "AUD": {
              "_from": "1966-02-14"
            }
          }
        ],
        "TW": [
          {
            "TWD": {
              "_from": "1949-06-15"
            }
          }
        ],
        "TZ": [
          {
            "TZS": {
              "_from": "1966-06-14"
            }
          }
        ],
        "UA": [
          {
            "SUR": {
              "_from": "1961-01-01",
              "_to": "1991-12-25"
            }
          },
          {
            "RUR": {
              "_from": "1991-12-25",
              "_to": "1992-11-13"
            }
          },
          {
            "UAK": {
              "_from": "1992-11-13",
              "_to": "1993-10-17"
            }
          },
          {
            "UAH": {
              "_from": "1996-09-02"
            }
          }
        ],
        "UG": [
          {
            "UGS": {
              "_from": "1966-08-15",
              "_to": "1987-05-15"
            }
          },
          {
            "UGX": {
              "_from": "1987-05-15"
            }
          }
        ],
        "UM": [
          {
            "USD": {
              "_from": "1944-01-01"
            }
          }
        ],
        "US": [
          {
            "USN": {
              "_tender": "false"
            }
          },
          {
            "USS": {
              "_tender": "false",
              "_to": "2014-03-01"
            }
          },
          {
            "USD": {
              "_from": "1792-01-01"
            }
          }
        ],
        "UY": [
          {
            "UYI": {
              "_tender": "false"
            }
          },
          {
            "UYP": {
              "_from": "1975-07-01",
              "_to": "1993-03-01"
            }
          },
          {
            "UYU": {
              "_from": "1993-03-01"
            }
          }
        ],
        "UZ": [
          {
            "UZS": {
              "_from": "1994-07-01"
            }
          }
        ],
        "VA": [
          {
            "ITL": {
              "_from": "1870-10-19",
              "_to": "2002-02-28"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "VC": [
          {
            "XCD": {
              "_from": "1965-10-06"
            }
          }
        ],
        "VE": [
          {
            "VEB": {
              "_from": "1871-05-11",
              "_to": "2008-06-30"
            }
          },
          {
            "VEF": {
              "_from": "2008-01-01"
            }
          }
        ],
        "VG": [
          {
            "USD": {
              "_from": "1833-01-01"
            }
          },
          {
            "GBP": {
              "_from": "1833-01-01",
              "_to": "1959-01-01"
            }
          }
        ],
        "VI": [
          {
            "USD": {
              "_from": "1837-01-01"
            }
          }
        ],
        "VN": [
          {
            "VNN": {
              "_from": "1978-05-03",
              "_to": "1985-09-14"
            }
          },
          {
            "VND": {
              "_from": "1985-09-14"
            }
          }
        ],
        "VU": [
          {
            "VUV": {
              "_from": "1981-01-01"
            }
          }
        ],
        "WF": [
          {
            "XPF": {
              "_from": "1961-07-30"
            }
          }
        ],
        "WS": [
          {
            "WST": {
              "_from": "1967-07-10"
            }
          }
        ],
        "XK": [
          {
            "YUM": {
              "_from": "1994-01-24",
              "_to": "1999-09-30"
            }
          },
          {
            "DEM": {
              "_from": "1999-09-01",
              "_to": "2002-03-09"
            }
          },
          {
            "EUR": {
              "_from": "2002-01-01"
            }
          }
        ],
        "YD": [
          {
            "YDD": {
              "_from": "1965-04-01",
              "_to": "1996-01-01"
            }
          }
        ],
        "YE": [
          {
            "YER": {
              "_from": "1990-05-22"
            }
          }
        ],
        "YT": [
          {
            "KMF": {
              "_from": "1975-01-01",
              "_to": "1976-02-23"
            }
          },
          {
            "FRF": {
              "_from": "1976-02-23",
              "_to": "2002-02-17"
            }
          },
          {
            "EUR": {
              "_from": "1999-01-01"
            }
          }
        ],
        "YU": [
          {
            "YUD": {
              "_from": "1966-01-01",
              "_to": "1990-01-01"
            }
          },
          {
            "YUN": {
              "_from": "1990-01-01",
              "_to": "1992-07-24"
            }
          },
          {
            "YUM": {
              "_from": "1994-01-24",
              "_to": "2002-05-15"
            }
          }
        ],
        "ZA": [
          {
            "ZAR": {
              "_from": "1961-02-14"
            }
          },
          {
            "ZAL": {
              "_tender": "false",
              "_from": "1985-09-01",
              "_to": "1995-03-13"
            }
          }
        ],
        "ZM": [
          {
            "ZMK": {
              "_from": "1968-01-16",
              "_to": "2013-01-01"
            }
          },
          {
            "ZMW": {
              "_from": "2013-01-01"
            }
          }
        ],
        "ZR": [
          {
            "ZRZ": {
              "_from": "1971-10-27",
              "_to": "1993-11-01"
            }
          },
          {
            "ZRN": {
              "_from": "1993-11-01",
              "_to": "1998-07-31"
            }
          }
        ],
        "ZW": [
          {
            "RHD": {
              "_from": "1970-02-17",
              "_to": "1980-04-18"
            }
          },
          {
            "ZWD": {
              "_from": "1980-04-18",
              "_to": "2008-08-01"
            }
          },
          {
            "ZWR": {
              "_from": "2008-08-01",
              "_to": "2009-02-02"
            }
          },
          {
            "ZWL": {
              "_from": "2009-02-02",
              "_to": "2009-04-12"
            }
          },
          {
            "USD": {
              "_from": "2009-04-12"
            }
          }
        ],
        "ZZ": [
          {
            "XAG": {
              "_tender": "false"
            }
          },
          {
            "XAU": {
              "_tender": "false"
            }
          },
          {
            "XBA": {
              "_tender": "false"
            }
          },
          {
            "XBB": {
              "_tender": "false"
            }
          },
          {
            "XBC": {
              "_tender": "false"
            }
          },
          {
            "XBD": {
              "_tender": "false"
            }
          },
          {
            "XDR": {
              "_tender": "false"
            }
          },
          {
            "XPD": {
              "_tender": "false"
            }
          },
          {
            "XPT": {
              "_tender": "false"
            }
          },
          {
            "XSU": {
              "_tender": "false"
            }
          },
          {
            "XTS": {
              "_tender": "false"
            }
          },
          {
            "XUA": {
              "_tender": "false"
            }
          },
          {
            "XXX": {
              "_tender": "false"
            }
          },
          {
            "XRE": {
              "_tender": "false",
              "_to": "1999-11-30"
            }
          },
          {
            "XFU": {
              "_tender": "false",
              "_to": "2013-11-30"
            }
          },
          {
            "XFO": {
              "_tender": "false",
              "_from": "1930-01-01",
              "_to": "2003-04-01"
            }
          }
        ]
      }
    }
  }
}
