const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const BlokItem = require('./blokitem.jsx');
var Panel = require("react-bootstrap").Panel;
var PanelGroup = require("react-bootstrap").PanelGroup;
const IconButOver = require('../matui/icon_but_over.jsx');
const DgFunc = require('../datagrid/src/functions.jsx');


var OrgData  = [];
var GrData     = [];
var SELECTED_ID = {} 
 
const LijsBlok = React.createClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      route: this.props.route,
      blocks:null,
      height: '300px',
      zoeken: true,
      nwgrid: true,
      DgrowData:[],
      OrgData:[],
      dkol:[],
      colFilter: [],
      colNum: [],
      searchkey: 'id',
      Tabelnaam:'',
      GrData:[],
      ListData:[],
      dgcolumns:[],      
      colFilter: [],
      Rupdate: false,
      overscanRowCount: 0,
      rowCount: 0,
      actblk:'',
      scrollToIndex: undefined,
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false,
      RowHeight:0,                        
      immuData:Immutable.List(),
      wrap:null

    };
  },

  componentWillMount() {
    if (!App.master)
    {
      window.location.hash = "/";
      return;
    }    
      this.state.height = this.props.height ? this.props.height : '300px';
      this.state.zoeken = (this.props.zoeken === undefined) ? true : this.props.zoeken;
      this.setState({route:this.props.route});
      this.setState({actblk:''});
      this.loadData(this.props.sleutel,this.props.hdata);
  },

  componentWillUpdate() {
    if (this.state.route != this.props.route)
    {
      this.setState({route:this.props.route});
      this.state.zoeken = (this.props.zoeken === undefined) ? true : this.props.zoeken;
      this.state.height = this.props.height ? this.props.height : '300px';
    }
  },  

  componentDidUpdate() {

    if (this.state.Rupdate == true)
    {
      this.setState({Rupdate:false});
    }
  },  


  render() {
    // Definieren van de headers
    var w = (window.innerWidth);
    var h = (window.innerHeight);
    if (this.props.breed) {w = this.props.breed};
    if (this.props.hoog) {h = this.props.hoog};

    const {
      overscanRowCount,
      rowCount,
      scrollToIndex,
      showScrollingPlaceholder,
      useDynamicRowHeight,
      RowHeight,
      data,
      route
    } = this.state


    return (
        <PanelGroup defaultActiveKey="PBLK0" accordion>
            {this.state.blocks}
        </PanelGroup>)
  },

  ExpaUit() {
    this.setState({expa:true});
  },

  ExpaAan() {
    this.setState({expa:false});
  },    

  _getBlocks() {
    let menuItem,
      itemComponent,
      isDisabled,
      hfd,
      sub,
      kol;

    this._children = [];
    //This array is used to keep track of all nested menu refs
    for (let i=0; i < this.state.OrgData.length; i++) {

          hfd =  this.state.OrgData[i].HfdBlk;
          sub =  this.state.OrgData[i].SubBlk;
          kol =  this.state.OrgData[i].kol;

          let hoog = (screen.width < 750) ?  (Immutable.List(sub).size) * 90 : (Immutable.List(sub).size) * 50;
          let PTitact = (this.state.route == 'GetKandBesBlk')? 
                        (<div>
                          <span style={{fontSize:'medium'}}>{hfd.consname}</span> 
                          <span style={{fontSize:'small',color:'#008cc9',marginLeft:'10px'}}> ({hfd.jobtitle})</span><span style={{fontSize:'small',marginLeft:'20px'}}>Beschikbaar:</span> <span style={{fontSize:'small',color:'#008cc9'}}>{hfd.date_available}</span><span style={{fontSize:'small',marginLeft:'20px'}}>Tarief:</span><span style={{fontSize:'small',color:'#008cc9'}}>{hfd.tarief}</span>
                          <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-user" 
                            tooltip="Naar consultant" 
                            onClick={(event)=>this.func_naarcons(event, this.state.OrgData[i].HfdBlk,i)}/>
                          </div>) : 
                        (this.state.route == 'GetKandAcc' || this.state.route == 'GetKandCont')? (<div><span style={{fontSize:'110%',color:'#008cc9'}}>{hfd.consname}</span> 
                            <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-user" 
                            tooltip="Naar consultant" 
                            onClick={(event)=>this.func_naarcons(event, this.state.OrgData[i].HfdBlk,i)}/></div>) : 

                        (this.state.route == 'GetTeFakt' || this.state.route == 'GetFakVerv')? 
                        (<div onClick={(event)=>this.func_hoofdclick(event, this.state.OrgData[i].HfdBlk,i)} ><span style={{fontSize:'100%',color:'#008cc9'}}>{hfd.consname}</span> </div>) : 


                        (this.state.route == 'GetAccContr')? (<div><span style={{fontSize:'small',color:'#008cc9'}}>{hfd.contract_date}</span> <span style={{fontSize:'small',color:'#008cc9',marginLeft:'10px'}}>  {hfd.contract_num}</span>
                            <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-pencil-square-o" 
                            tooltip="Aanpassen Hoofdcontract" 
                            onClick={(event)=>this.func_editcontr(event, this.state.OrgData[i].HfdBlk,i)}/>                          
                            <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-plus" 
                            tooltip="Toevoegen nieuwe bijlage" 
                            onClick={(event)=>this.func_newbijlage(event, this.state.OrgData[i].HfdBlk,i)}/>
                            <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-file-text" 
                            tooltip="Open Document" 
                            onClick={(event)=>this.func_opendoc(event, this.state.OrgData[i].HfdBlk,i)}/>
                            </div>) : 

                      (screen.width < 750 && this.state.route == 'GetRepAfwMnd') ? (<h3 className="panel-title"><span style={{fontSize:'12px !important'}}>{hfd.consname}</span></h3>) :
                      (screen.width < 750) ? (<h3 className="panel-title"><span style={{fontSize:'12px !important'}}>{hfd.consname}</span></h3>) :
                      (screen.width > 749 && this.state.route == 'GetRepAfwMnd') ? (<h6 className="panel-title"><span>{hfd.consname}</span><span style={{fontSize:'12px !important', float:'right',marginRight:'15px'}}>Gewerkt vorige maand: {hfd.gewerkt}</span></h6>) :
                      (<h6 className="panel-title"><span style={{fontSize:'medium',color:'#008cc9'}}>{hfd.consname}</span></h6>);


          let hblokitem = (sub.size > 0) ? (
              <BlokItem
                key={'PBLKG'+i+1}
                route={this.props.route}
                breed={this.props.breed} 
                klik={this.RowKlik} 
                klik2={this.RowKlik2} 
                klik3={this.RowKlik3} 
                hoog={hoog}
                data={sub}
                kol={kol}/>) : null;


          itemComponent = (this.state.route == 'GetTeFakt' || this.state.route == 'GetFakVerv') ? (
            <Panel  header={PTitact} bsStyle="grblok" key={'PBLK'+i} eventKey={'PBLK'+i} collapsible style={{background: 'rgb(245, 245, 245)', width: '99%',overflow: 'hidden'}}>
              {hblokitem}
            </Panel>):(
            <Panel  header={PTitact} bsStyle="grblok" key={'PBLK'+i} eventKey={'PBLK'+i} collapsible style={{background: '#fff', width: '99%',overflow: 'hidden'}}>
              {hblokitem}
            </Panel>);
          this._children.push(itemComponent);
    }
    return this._children;
  },
  _ZetFillBlocks(data) {
    let menuItem,
      itemComponent,
      isDisabled,
      hfd,
      sub,
      kol;

    this._children = [];
    //This array is used to keep track of all nested menu refs
    for (let i=0; i < data.length; i++) {

          hfd =  data[i].HfdBlk;
          sub =  data[i].SubBlk;
          kol =  data[i].kol;
          let hoog = (screen.width < 750) ?  (Immutable.List(sub).size) * 90 : (Immutable.List(sub).size) * 50;
          let PTitact = (this.state.route == 'GetKandBesBlk')? 
                        (<div>
                          <span style={{fontSize:'medium'}}>{hfd.consname}</span> 
                          <span style={{fontSize:'small',color:'#008cc9',marginLeft:'10px'}}> ({hfd.jobtitle})</span><span style={{fontSize:'small',marginLeft:'20px'}}>Beschikbaar:</span> <span style={{fontSize:'small',color:'#008cc9'}}>{hfd.date_available}</span><span style={{fontSize:'small',marginLeft:'20px'}}>Tarief:</span><span style={{fontSize:'small',color:'#008cc9'}}>{hfd.tarief}</span>
                          <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-user" 
                            tooltip="Naar consultant" 
                            onClick={(event)=>this.func_naarcons(event, data[i].HfdBlk,i)}/>
                          </div>) : 
                        (this.state.route == 'GetKandAcc' || this.state.route == 'GetKandCont')? (<div><span style={{fontSize:'110%',color:'#008cc9'}}>{hfd.consname}</span> 
                            <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-user" 
                            tooltip="Naar consultant" 
                            onClick={(event)=>this.func_naarcons(event, data[i].HfdBlk,i)}/></div>) : 

                        (this.state.route == 'GetTeFakt' || this.state.route == 'GetFakVerv')? (<div><span style={{fontSize:'100%',color:'#008cc9'}}>{hfd.consname}</span> </div>) : 


                        (this.state.route == 'GetAccContr')? (<div><span style={{fontSize:'small',color:'#008cc9'}}>{hfd.contract_date}</span> <span style={{fontSize:'small',color:'#008cc9',marginLeft:'10px'}}>  {hfd.contract_num}</span>
                            <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-pencil-square-o" 
                            tooltip="Aanpassen Hoofdcontract" 
                            onClick={(event)=>this.func_editcontr(event, this.state.OrgData[i].HfdBlk,i)}/>                          
                            <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-plus" 
                            tooltip="Toevoegen nieuwe bijlage" 
                            onClick={(event)=>this.func_newbijlage(event, this.state.OrgData[i].HfdBlk,i)}/>
                            <IconButOver                       
                            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'90%'}}
                            iconClassName="fa fa-file-text" 
                            tooltip="Open Document" 
                            onClick={(event)=>this.func_opendoc(event, this.state.OrgData[i].HfdBlk,i)}/>
                            </div>) : 

                      (screen.width < 750 && this.state.route == 'GetRepAfwMnd') ? (<h3 className="panel-title"><span style={{fontSize:'12px !important'}}>{hfd.consname}</span></h3>) :
                      (screen.width < 750) ? (<h3 className="panel-title"><span style={{fontSize:'12px !important'}}>{hfd.consname}</span></h3>) :
                      (screen.width > 749 && this.state.route == 'GetRepAfwMnd') ? (<h6 className="panel-title"><span>{hfd.consname}</span><span style={{fontSize:'12px !important', float:'right',marginRight:'15px'}}>Gewerkt vorige maand: {hfd.gewerkt}</span></h6>) :
                      (<h6 className="panel-title"><span style={{fontSize:'medium',color:'#008cc9'}}>{hfd.consname}</span></h6>);


          let hblokitem = (sub.size > 0) ? (
              <BlokItem
                key={'PBLKG'+i+1}
                route={this.props.route}
                breed={this.props.breed} 
                klik={this.RowKlik} 
                klik2={this.RowKlik2} 
                klik3={this.RowKlik3} 
                hoog={hoog}
                data={sub}
                kol={kol}/>) : null;


          itemComponent = (this.state.route == 'GetTeFakt' || this.state.route == 'GetFakVerv') ? (
            <Panel  header={PTitact} bsStyle="grblok" key={'PBLK'+i} eventKey={'PBLK'+i} collapsible style={{background: 'rgb(245, 245, 245)', width: '99%',overflow: 'hidden'}}>
              {hblokitem}
            </Panel>):(
            <Panel  header={PTitact} bsStyle="grblok" key={'PBLK'+i} eventKey={'PBLK'+i} collapsible style={{background: '#fff', width: '99%',overflow: 'hidden'}}>
              {hblokitem}
            </Panel>);
          this._children.push(itemComponent);
    }
    return this._children;
  },


  NaarCons(data,index,e) {
    if (this.props.KzNaarCons) this.props.KzNaarCons(data);       
  },
  GetStartRecord() {
    return this.state.OrgData[0].HfdBlk;
  },

  loadData(sleutel, hdata) {
    if (!App.master)
    {
      window.location.hash = "/";
      return;
    }
    let that = this;
    let propContainerStyle = {
      width: '200px',
      overflow: 'hidden',
      margin: '20px auto 0 auto'
    };    
    let vurl = (this.state.route.length > 3) ? './server/index.php?function='+that.state.route : ''; 
    this.MaakLeeg();
    this.forceUpdate();        
    if (vurl.length > 2)
    {
      App.master.zetpointer('progress');

       let jqxhr =  $.ajax({
        url: vurl,
        dataType: 'json',
        type: 'POST',
        data: JSON.stringify(hdata)
        }).always(function(data){
        if (that.isMounted()) 
        {
          let blok = 0;
          let blokhfd={};
          let blokdata=[];
          let subblok = Immutable.List();
          let listblok = {};
          if (that.props.zetgeg) {
            if (data.length > 0)
            {
              that.props.zetgeg(data)
            }
          }

          for (let i=0; i < data.length; i++) 
          {
              listblok.blokhfd = data[i].HfdBlk;
              subblok = new Immutable.List(data[i].SubBlk);
              data[i].FilBlk = data[i].SubBlk;
              data[i].SubBlk = subblok;
          }      
          that.state.OrgData    = data;
          that.state.GrData     = data;
          that.setState({colFilter:data[0].colFilter});
          that.setState({colNum:data[0].colNum});
          that.setState({GrData:data});
          OrgData    = data.data;
          GrData     = data.data;
          that.setState({blocks:that._getBlocks()});
          that.setState({scrollToIndex:0});
          App.master.zetpointer('auto');
        }
      });
    }
  },

  MaakLeeg() {
     this.state.OrgData    = [];
     this.state.GrData     = [];
     this.setState({GrData:[]});
     OrgData    = [];
     GrData     = [];
     this.setState({blocks:null});

  },

  handleFilter(value,zet){
    if (this.state.colFilter)
    {
      let blok = 0;
      let blokhfd={};
      let blokdata=[];
      let subblok = Immutable.List();
      let listblok = {};
      let data = this.state.OrgData;
      for (let i=0; i < data.length; i++) 
      {
          let fildata = DgFunc.SetFilter(value,data[i].FilBlk,this.state.colFilter,this.state.colNum);
          listblok.blokhfd = data[i].HfdBlk;
          subblok = new Immutable.List(fildata);
          data[i].SubBlk = subblok;
      }      
      this.setState({blocks:null});
      this.forceUpdate()
      this.setState({blocks:this._ZetFillBlocks(data)});
      this.setState({scrollToIndex:0});
      this.forceUpdate()
      return;
    }
  },


  loadDataNw(sleutel, hdata) {
    this.setState({blocks:null});
    if (!App.master)
    {
      window.location.hash = "/";
      return;
    }
    let that = this;
    let propContainerStyle = {
      width: '200px',
      overflow: 'hidden',
      margin: '20px auto 0 auto'
    };    
    let vurl = (this.state.route.length > 3) ? './server/index.php?function='+that.state.route : ''; 
    if (vurl.length > 2)
    {
      App.master.zetpointer('progress');

       let jqxhr =  $.ajax({
        url: vurl,
        dataType: 'json',
        type: 'POST',
        data: JSON.stringify(hdata)
        }).always(function(data){
        if (that.isMounted()) 
        {
          let blok = 0;
          let blokhfd={};
          let blokdata=[];
          let subblok = Immutable.List();
          let listblok = {};
          if (that.props.zetgeg && sleutel == 1) {
            if (data.length > 0)
            {
              that.props.zetgeg(data)
            }
          }

          for (let i=0; i < data.length; i++) 
          {
              listblok.blokhfd = data[i].HfdBlk;
              subblok = new Immutable.List(data[i].SubBlk);
              data[i].FilBlk = data[i].SubBlk;
              data[i].SubBlk = subblok;
          }      
          that.state.OrgData    = data;
          that.state.GrData     = data;
          that.setState({GrData:data});
          that.setState({OrgData:data});
          that.setState({colFilter:data[0].colFilter});
          that.setState({colNum:data[0].colNum});

          OrgData    = data.data;
          GrData     = data.data;
          that.setState({blocks:that._getBlocks()});
          App.master.zetpointer('auto');
        }
      });
    }
  },

  RowKlik(waarde,data,route)  {
      //Naar request gaan
    if (this.props.klik)
    {
      this.props.klik(waarde,data)
    }
    // einde 
  
  },

  func_naarcons(e, data,eindex) {
    if (this.props.naarcons)
    {
      this.props.naarcons(data)
    }
  },

  func_hoofdclick(e, data,eindex) {
    if (this.props.hoofdclick)
    {
      this.props.hoofdclick(data)
    }
  },
  func_newbijlage(e, data,eindex) {
    if (this.props.newbijlage)
    {
      this.props.newbijlage(data)
    }
  },

  func_editcontr(e, data,eindex) {
    if (this.props.editcontr)
    {
      this.props.editcontr(data)
    }
  },

  func_opendoc(e, data,eindex) {
    if (this.props.opendoc)
    {
      this.props.opendoc(data)
    }
  },

  RowKlik2(waarde,data,route)  {
      //Naar request gaan
    if (this.props.klik2)
    {
      this.props.klik2(waarde,data)
    }
    // einde 
  
  },

  RowKlik3(waarde,data,route)  {
      //Naar request gaan
    if (this.props.klik3)
    {
      this.props.klik3(waarde,data)
    }
    // einde 
  
  }

});

module.exports = LijsBlok;






