const React = require('react');
const ReactDOM = require('react-dom');
const StylePropable = require('./mixins/style-propable');
const Transitions = require('./styles/transitions');
import transitions from './styles/transitions';
const FocusRipple = require('./ripples/focus-ripple.jsx');
const DefaultRawTheme = require('./styles/raw-themes/light-raw-theme');
const ThemeManager = require('./styles/theme-manager');
import keycode from 'keycode';

import PropTypes from 'prop-types';
//import keycode from 'keycode';
import warning from 'warning';

/**
  * Verifies min/max range.
  * @param   {Object} props         Properties of the React component.
  * @param   {String} propName      Name of the property to validate.
  * @param   {String} componentName Name of the component whose property is being validated.
  * @returns {Object} Returns an Error if min >= max otherwise null.
  */
let minMaxPropType = (props, propName, componentName) => {
  let error = React.PropTypes.number(props, propName, componentName);
  if (error !== null) return error;

  if (props.min >= props.max) {
    let errorMsg = (propName === 'min') ? 'min should be less than max' : 'max should be greater than min';
    return new Error(errorMsg);
  }
};

/**
  * Verifies value is within the min/max range.
  * @param   {Object} props         Properties of the React component.
  * @param   {String} propName      Name of the property to validate.
  * @param   {String} componentName Name of the component whose property is being validated.
  * @returns {Object} Returns an Error if the value is not within the range otherwise null.
  */
let valueInRangePropType = (props, propName, componentName) => {
  let error = React.PropTypes.number(props, propName, componentName);
  if (error !== null) return error;

  let value = props[propName];
  if (value < props.min || props.max < value) {
    return new Error(propName + ' should be within the range specified by min and max');
  }
};


const crossAxisProperty = {
  x: 'height',
  'x-reverse': 'height',
  y: 'width',
  'y-reverse': 'width',
};

const crossAxisOffsetProperty = {
  x: 'top',
  'x-reverse': 'top',
  y: 'left',
  'y-reverse': 'left',
};

const mainAxisProperty = {
  x: 'width',
  'x-reverse': 'width',
  y: 'height',
  'y-reverse': 'height',
};

const mainAxisMarginFromEnd = {
  x: 'marginRight',
  'x-reverse': 'marginLeft',
  y: 'marginTop',
  'y-reverse': 'marginBottom',
};

const mainAxisMarginFromStart = {
  x: 'marginLeft',
  'x-reverse': 'marginRight',
  y: 'marginBottom',
  'y-reverse': 'marginTop',
};

const mainAxisOffsetProperty = {
  x: 'left',
  'x-reverse': 'right',
  y: 'bottom',
  'y-reverse': 'top',
};

const mainAxisClientProperty = {
  x: 'clientWidth',
  'x-reverse': 'clientWidth',
  y: 'clientHeight',
  'y-reverse': 'clientHeight',
};

const mainAxisClientOffsetProperty = {
  x: 'clientX',
  'x-reverse': 'clientX',
  y: 'clientY',
  'y-reverse': 'clientY',
};

const reverseMainAxisOffsetProperty = {
  x: 'right',
  'x-reverse': 'left',
  y: 'top',
  'y-reverse': 'bottom',
};

const isMouseControlInverted = (axis) => axis === 'x-reverse' || axis === 'y';

const calculateAxis = (axis, isRtl) => {
  if (isRtl) {
    switch (axis) {
      case 'x':
        return 'x-reverse';
      case 'x-reverse':
        return 'x';
    }
  }
  return axis;
};

function getPercent(value, min, max) {
  let percent = (value - min) / (max - min);
  if (isNaN(percent)) {
    percent = 0;
  }

  return percent;
}

const getStyles = (props, context, state) => {
  const {
    axis,
    disabled,
    max,
    min,
    bcolor,
    scolor,
  } = props;

  const {
    isRtl,
    slider: {
      handleColorZero,
      handleFillColor,
      handleSize,
      handleSizeDisabled,
      handleSizeActive,
      trackSize,
      trackColor,
      trackColorSelected,
      rippleColor,
      selectionColor,
    },
  } = context.muiTheme;

  const fillGutter = handleSize / 2;
  const disabledGutter = trackSize + handleSizeDisabled / 2;
  const calcDisabledSpacing = disabled ? ` - ${disabledGutter}px` : '';
  const percent = getPercent(state.value, min, max);
  const calculatedAxis = calculateAxis(axis, isRtl);

  const styles = {
    slider: {
      touchCallout: 'none',
      userSelect: 'none',
      cursor: 'default',
      [crossAxisProperty[calculatedAxis]]: handleSizeActive,
      [mainAxisProperty[calculatedAxis]]: '100%',
      position: 'relative',
      marginTop: 24,
      marginBottom: 48,
    },
    track: {
      position: 'absolute',
      [crossAxisOffsetProperty[calculatedAxis]]: (handleSizeActive - trackSize) / 2,
      [mainAxisOffsetProperty[calculatedAxis]]: 0,
      [mainAxisProperty[calculatedAxis]]: '100%',
      [crossAxisProperty[calculatedAxis]]: trackSize,
    },
    filledAndRemaining: {
      directionInvariant: true,
      position: 'absolute',
      [crossAxisOffsetProperty]: 0,
      [crossAxisProperty[calculatedAxis]]: '100%',
      transition: transitions.easeOut(null, 'margin'),
    },
    handle: {
      directionInvariant: true,
      boxSizing: 'border-box',
      position: 'absolute',
      cursor: 'pointer',
      pointerEvents: 'inherit',
      [crossAxisOffsetProperty[calculatedAxis]]: 0,
      [mainAxisOffsetProperty[calculatedAxis]]: percent === 0 ? '0%' : `${(percent * 100)}%`,
      zIndex: 1,
      margin: ({
        x: `${(trackSize / 2)}px 0 0 0`,
        'x-reverse': `${(trackSize / 2)}px 0 0 0`,
        y: `0 0 0 ${(trackSize / 2)}px`,
        'y-reverse': `0 0 0 ${(trackSize / 2)}px`,
      })[calculatedAxis],
      width: handleSize,
      height: handleSize,
      backgroundColor: (scolor) ? scolor : selectionColor,
      backgroundClip: 'padding-box',
      border: '0px solid transparent',
      borderRadius: '50%',
      transform: ({
        x: 'translate(-50%, -50%)',
        'x-reverse': 'translate(50%, -50%)',
        y: 'translate(-50%, 50%)',
        'y-reverse': 'translate(-50%, -50%)',
      })[calculatedAxis],
      transition:
        `${transitions.easeOut('450ms', 'background')}, ${
        transitions.easeOut('450ms', 'border-color')}, ${
        transitions.easeOut('450ms', 'width')}, ${
        transitions.easeOut('450ms', 'height')}`,
      overflow: 'visible',
      outline: 'none',
    },
    handleWhenDisabled: {
      boxSizing: 'content-box',
      cursor: 'not-allowed',
      backgroundColor: trackColor,
      width: handleSizeDisabled,
      height: handleSizeDisabled,
      border: 'none',
    },
    handleWhenPercentZero: {
      border: `${trackSize}px solid ${handleColorZero}`,
      backgroundColor: (bcolor) ? bcolor : handleFillColor,
      boxShadow: 'none',
    },
    handleWhenPercentZeroAndDisabled: {
      cursor: 'not-allowed',
      width: handleSizeDisabled,
      height: handleSizeDisabled,
    },
    handleWhenPercentZeroAndFocused: {
      border: `${trackSize}px solid ${trackColorSelected}`,
    },
    handleWhenActive: {
      width: handleSizeActive,
      height: handleSizeActive,
    },
    ripple: {
      height: handleSize,
      width: handleSize,
      overflow: 'visible',
    },
    rippleWhenPercentZero: {
      top: -trackSize,
      left: -trackSize,
    },
    rippleInner: {
      height: '300%',
      width: '300%',
      top: -handleSize,
      left: -handleSize,
    },
    rippleColor: {
      fill: percent === 0 ? handleColorZero : rippleColor,
    },
  };
  styles.filled = Object.assign({}, styles.filledAndRemaining, {
    [mainAxisOffsetProperty[calculatedAxis]]: 0,
    backgroundColor: disabled ? trackColor : bcolor ? bcolor : selectionColor,
    [mainAxisMarginFromEnd[calculatedAxis]]: fillGutter,
    [mainAxisProperty[calculatedAxis]]: `calc(${(percent * 100)}%${calcDisabledSpacing})`,
  });
  styles.remaining = Object.assign({}, styles.filledAndRemaining, {
    [reverseMainAxisOffsetProperty[calculatedAxis]]: 0,
    backgroundColor: (state.hovered || state.focused) &&
      !disabled ? trackColorSelected : trackColor,
    [mainAxisMarginFromStart[calculatedAxis]]: fillGutter,
    [mainAxisProperty[calculatedAxis]]: `calc(${((1 - percent) * 100)}%${calcDisabledSpacing})`,
  });

  return styles;
};

const Slider = React.createClass({

  mixins: [StylePropable],

  contextTypes: {
    muiTheme: React.PropTypes.object,
  },

  propTypes: {
    name: React.PropTypes.string.isRequired,
    defaultValue: valueInRangePropType,
    description: React.PropTypes.string,
    disabled: React.PropTypes.bool,
    error: React.PropTypes.string,
    max: minMaxPropType,
    min: minMaxPropType,
    required: React.PropTypes.bool,
    step: React.PropTypes.number,
    onBlur: React.PropTypes.func,
    onChange: React.PropTypes.func,
    onDragStart: React.PropTypes.func,
    onDragStop: React.PropTypes.func,
    onFocus: React.PropTypes.func,
    value: valueInRangePropType,
  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
  },

  getChildContext () {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

  getDefaultProps() {
    return {
      axis: 'x',
      disabled: false,
      disableFocusRipple: false,
      max: 1,
      min: 0,
      required: true,
      step: 0.01,
      style: {},      
      defaultValue: 0,
      disabled: false,
    };
  },

  getInitialState() {
    let value = this.props.value;
    if (value === undefined) {
      value = this.props.defaultValue;
    }
    let percent = (value - this.props.min) / (this.props.max - this.props.min);
    if (isNaN(percent)) percent = 0;

    return {
      active: false,
      dragging: false,
      focused: false,
      hovered: false,
      percent: percent,
      value: value,
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },
  componentWillMount() {
    const {
      defaultValue,
      min,
      max,
    } = this.props;

    let {
      value,
    } = this.props;

    if (value === undefined) {
      value = defaultValue !== undefined ? defaultValue : min;
    }

    this.setState({
      value: this.resolveValue(value, min, max),
    });
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== undefined && !this.state.dragging) {
      const {
        min = this.props.min,
        max = this.props.max,
      } = nextProps;

      this.setState({
        value: this.resolveValue(nextProps.value, min, max),
      });
    }
  },

//  track = null;
//  handle = null;

  resolveValue(value, min, max) {
    if (value > max) {
      return max;
    }

    if (value < min) {
      return min;
    }

    return value;
  },


  handleKeyDown(event) {
    const {
      axis,
      min,
      max,
      step,
    } = this.props;
    const {isRtl} = this.context.muiTheme;

    const calculatedAxis = calculateAxis(axis, isRtl);

    let action;

    switch (keycode(event)) {
      case 'page down':
      case 'down':
        if (calculatedAxis === 'y-reverse') {
          action = 'increase';
        } else {
          action = 'decrease';
        }
        break;
      case 'left':
        if (calculatedAxis === 'x-reverse') {
          action = 'increase';
        } else {
          action = 'decrease';
        }
        break;
      case 'page up':
      case 'up':
        if (calculatedAxis === 'y-reverse') {
          action = 'decrease';
        } else {
          action = 'increase';
        }
        break;
      case 'right':
        if (calculatedAxis === 'x-reverse') {
          action = 'decrease';
        } else {
          action = 'increase';
        }
        break;
      case 'home':
        action = 'min';
        break;
      case 'end':
        action = 'max';
        break;
    }

    if (action) {
      let newValue;

      // Cancel scroll
      event.preventDefault();

      switch (action) {
        case 'decrease':
          newValue = this.state.value - step;
          break;
        case 'increase':
          newValue = this.state.value + step;
          break;
        case 'min':
          newValue = min;
          break;
        case 'max':
          newValue = max;
          break;
      }

      // We need to use toFixed() because of float point errors.
      // For example, 0.01 + 0.06 = 0.06999999999999999
      newValue = this.resolveValue(parseFloat(newValue.toFixed(5)), min, max);

      if (this.state.value !== newValue) {
        this.setState({
          value: newValue,
        });

        if (this.props.onChange) {
          this.props.onChange(event, newValue);
        }
      }
    }
  },

  handleDragMouseMove(event) {
    this.onDragUpdate(event, 'mouse');
  },

  handleTouchMove(event) {
    this.onDragUpdate(event, 'touch');
  },

  handleMouseEnd(event) {
    document.removeEventListener('mousemove', this.handleDragMouseMove);
    document.removeEventListener('mouseup', this.handleMouseEnd);
    this.onDragStop(event);
  },
  handleTouchEnd(event) {
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchup', this.handleTouchEnd);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchcancel', this.handleTouchEnd);
    this.onDragStop(event);
  },

  getTheme() {
    return this.state.muiTheme.slider;
  },

  getStyles() {
    let fillGutter = this.getTheme().handleSize / 2;
    let disabledGutter = this.getTheme().trackSize + this.getTheme().handleSizeDisabled / 2;
    let calcDisabledSpacing = this.props.disabled ? ' - ' + disabledGutter + 'px' : '';
    let styles = {
      root: {
        touchCallout: 'none',
        userSelect: 'none',
        cursor: 'default',
        height: this.getTheme().handleSizeActive,
        position: 'relative',
        marginTop: 24,
        marginBottom: 48,
      },
      track: {
        position: 'absolute',
        top: (this.getTheme().handleSizeActive - this.getTheme().trackSize) / 2,
        left: 0,
        width: '100%',
        height: this.getTheme().trackSize,
      },
      filledAndRemaining: {
        position: 'absolute',
        top: 0,
        height: '100%',
        transition: Transitions.easeOut(null, 'margin'),
      },
      handle: {
        boxSizing: 'border-box',
        position: 'absolute',
        cursor: 'pointer',
        pointerEvents: 'inherit',
        top: 0,
        left: '0%',
        zIndex: 1,
        margin: (this.getTheme().trackSize / 2) + 'px 0 0 0',
        width: this.getTheme().handleSize,
        height: this.getTheme().handleSize,
        backgroundColor: (scolor) ? scolor : this.getTheme().selectionColor,
        backgroundClip: 'padding-box',
        border: '0px solid transparent',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        transition:
          Transitions.easeOut('450ms', 'background') + ',' +
          Transitions.easeOut('450ms', 'border-color') + ',' +
          Transitions.easeOut('450ms', 'width') + ',' +
          Transitions.easeOut('450ms', 'height'),
        overflow: 'visible',
      },
      handleWhenDisabled: {
        boxSizing: 'content-box',
        cursor: 'not-allowed',
        backgroundColor: this.getTheme().trackColor,
        width: this.getTheme().handleSizeDisabled,
        height: this.getTheme().handleSizeDisabled,
        border: 'none',
      },
      handleWhenPercentZero: {
        border: this.getTheme().trackSize + 'px solid ' + this.getTheme().handleColorZero,
        backgroundColor: this.getTheme().handleFillColor,
        boxShadow: 'none',
      },
      handleWhenPercentZeroAndDisabled: {
        cursor: 'not-allowed',
        width: this.getTheme().handleSizeDisabled,
        height: this.getTheme().handleSizeDisabled,
      },
      handleWhenPercentZeroAndFocused: {
        border: this.getTheme().trackSize + 'px solid ' +
          this.getTheme().trackColorSelected,
      },
      handleWhenActive: {
        width: this.getTheme().handleSizeActive,
        height: this.getTheme().handleSizeActive,
      },
      ripple: {
        height: this.getTheme().handleSize,
        width: this.getTheme().handleSize,
        overflow: 'visible',
      },
      rippleWhenPercentZero: {
        top: -this.getTheme().trackSize,
        left: -this.getTheme().trackSize,
      },
      rippleInner: {
        height: '300%',
        width: '300%',
        top: -this.getTheme().handleSize,
        left: -this.getTheme().handleSize,
      },
    };
    styles.filled = this.mergeAndPrefix(styles.filledAndRemaining, {
      left: 0,
      backgroundColor: (this.props.disabled) ?
        this.getTheme().trackColor :
        (this.props.scolor) ? this.props.scolor : this.getTheme().selectionColor,
      marginRight: fillGutter,
      width: 'calc(' + (this.state.percent * 100) + '%' + calcDisabledSpacing + ')',
    });
    styles.remaining = this.mergeAndPrefix(styles.filledAndRemaining, {
      right: 0,
      backgroundColor: this.getTheme().trackColor,
      marginLeft: fillGutter,
      width: 'calc(' + ((1 - this.state.percent) * 100) + '%' + calcDisabledSpacing + ')',
    });

    return styles;
  },

  handleFocus(event) {
    this.setState({
      focused: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  },

  handleBlur(event) {
    this.setState({
      focused: false,
      active: false,
    });

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  },

  handleMouseDown(event) {
    const {
      axis,
      disabled,
    } = this.props;
    const {isRtl} = this.context.muiTheme;

    if (disabled) {
      return;
    }

    const calculatedAxis = calculateAxis(axis, isRtl);

    let position;
    if (isMouseControlInverted(calculatedAxis)) {
      position = this.getTrackOffset() - event[mainAxisClientOffsetProperty[calculatedAxis]];
    } else {
      position = event[mainAxisClientOffsetProperty[calculatedAxis]] - this.getTrackOffset();
    }
    this.setValueFromPosition(event, position);

    document.addEventListener('mousemove', this.handleDragMouseMove);
    document.addEventListener('mouseup', this.handleMouseEnd);

    // Cancel text selection
    event.preventDefault();

    // Set focus manually since we called preventDefault()
    this.handle.focus();

    this.onDragStart(event);
  },

  handleMouseUp() {
    if (!this.props.disabled) {
      this.setState({
        active: false,
      });
    }
  },

  handleMouseEnter() {
    this.setState({
      hovered: true,
    });
  },

  handleMouseLeave() {
    this.setState({
      hovered: false,
    });
  },

  getTrackOffset() {
    const {axis} = this.props;
    const {isRtl} = this.context.muiTheme;

    const calculatedAxis = calculateAxis(axis, isRtl);
    
    if (!this.track) return 0;
    return this.track.getBoundingClientRect()[mainAxisOffsetProperty[calculatedAxis]];
  },

  onDragStart(event) {
    this.setState({
      dragging: true,
      active: true,
    });

    if (this.props.onDragStart) {
      this.props.onDragStart(event);
    }
  },

  onDragUpdate(event, type) {
    const {
      axis,
      disabled,
    } = this.props;
    const {isRtl} = this.context.muiTheme;

    if (this.dragRunning) {
      return;
    }
    this.dragRunning = true;

    requestAnimationFrame(() => {
      this.dragRunning = false;

      const calculatedAxis = calculateAxis(axis, isRtl);
      const source = type === 'touch' ? event.touches[0] : event;

      let position;
      if (isMouseControlInverted(calculatedAxis)) {
        position = this.getTrackOffset() - source[mainAxisClientOffsetProperty[calculatedAxis]];
      } else {
        position = source[mainAxisClientOffsetProperty[calculatedAxis]] - this.getTrackOffset();
      }

      if (!disabled) {
        this.setValueFromPosition(event, position);
      }
    });
  },

  onDragStop(event) {
    this.setState({
      dragging: false,
      active: false,
    });

    if (this.props.onDragStop) {
      this.props.onDragStop(event);
    }
  },

  setValueFromPosition(event, position) {
    const {
      axis,
      step,
      min,
      max,
    } = this.props;
    const {isRtl} = this.context.muiTheme;

    const calculatedAxis = calculateAxis(axis, isRtl);
    const positionMax = this.track[mainAxisClientProperty[calculatedAxis]];

    let value;

    if (position <= 0) {
      value = min;
    } else if (position >= positionMax) {
      value = max;
    } else {
      value = position / positionMax * (max - min);
      value = Math.round(value / step) * step + min;
      value = parseFloat(value.toFixed(5));
    }

    value = this.resolveValue(value, min, max);

    if (this.state.value !== value) {
      this.setState({
        value: value,
      });

      if (this.props.onChange) {
        this.props.onChange(event, value);
      }
    }
  },


  render() {
    const {
      axis, // eslint-disable-line no-unused-vars
      disabled,
      disableFocusRipple,
      max,
      min,
      name,
      onBlur, // eslint-disable-line no-unused-vars
      onChange, // eslint-disable-line no-unused-vars
      onDragStart, // eslint-disable-line no-unused-vars
      onDragStop, // eslint-disable-line no-unused-vars
      onFocus, // eslint-disable-line no-unused-vars
      required,
      sliderStyle,
      step,
      style,
      value: propValue, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;

    const {
      active,
      focused,
      hovered,
      value,
    } = this.state;

    let { ...others } = this.props;
    const {prepareStyles} = this.context.muiTheme;
    const styles = getStyles(this.props, this.context, this.state);
    const percent = getPercent(value, min, max);

    let handleStyles = {};
    if (percent === 0) {
      handleStyles = Object.assign(
        {},
        styles.handle,
        styles.handleWhenPercentZero,
        active && styles.handleWhenActive,
        (hovered || focused) && !disabled &&
        styles.handleWhenPercentZeroAndFocused,
        disabled && styles.handleWhenPercentZeroAndDisabled
      );
    } else {
      handleStyles = Object.assign(
        {},
        styles.handle,
        active && styles.handleWhenActive,
        disabled && styles.handleWhenDisabled
      );
    }

    const rippleStyle = Object.assign(
      {},
      styles.ripple,
      percent === 0 && styles.rippleWhenPercentZero
    );

    return (
      <div {...other} style={this.prepareStyles(Object.assign({}, style))}>
        <div
          style={this.prepareStyles(Object.assign({}, styles.slider, sliderStyle))}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onMouseDown={this.handleMouseDown}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onMouseUp={this.handleMouseUp}
          onTouchStart={this.handleTouchStart}
          onKeyDown={!disabled ? this.handleKeyDown : undefined}
        >
          <div ref={(node) => this.track = node} style={this.prepareStyles(styles.track)}>
            <div style={this.prepareStyles(styles.filled)} />
            <div style={this.prepareStyles(styles.remaining)} />
            <div
              ref={(node) => this.handle = node}
              style={this.prepareStyles(handleStyles)}
              tabIndex={0}
            >
              {!disabled && !disableFocusRipple && (
                <FocusRipple
                  style={rippleStyle}
                  innerStyle={styles.rippleInner}
                  show={(hovered || focused) && !active}
                  color={styles.rippleColor.fill}
                />
              )}
            </div>
          </div>
        </div>
        <input
          type="hidden"
          name={name}
          value={value}
          required={required}
          min={min}
          max={max}
          step={step}
        />
      </div>
    );
  },

  handleTouchStart(event) {
    const {
      axis,
      disabled,
    } = this.props;
    const {isRtl} = this.context.muiTheme;

    if (disabled) {
      return;
    }

    const calculatedAxis = calculateAxis(axis, isRtl);

    let position;
    if (isMouseControlInverted(calculatedAxis)) {
      position = this.getTrackOffset() - event.touches[0][mainAxisClientOffsetProperty[calculatedAxis]];
    } else {
      position = event.touches[0][mainAxisClientOffsetProperty[calculatedAxis]] - this.getTrackOffset();
    }
    this.setValueFromPosition(event, position);

    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('touchup', this.handleTouchEnd);
    document.addEventListener('touchend', this.handleTouchEnd);
    document.addEventListener('touchcancel', this.handleTouchEnd);

    this.onDragStart(event);

    // Cancel scroll and context menu
    event.preventDefault();
  },


  _onHandleTouchStart(e) {
    if (document) {
      document.addEventListener('touchmove', this._dragTouchHandler, false);
      document.addEventListener('touchup', this._dragTouchEndHandler, false);
      document.addEventListener('touchend', this._dragTouchEndHandler, false);
      document.addEventListener('touchcancel', this._dragTouchEndHandler, false);
    }
    this._onDragStart(e);
  },

  _onHandleMouseDown(e) {
    if (document) {
      document.addEventListener('mousemove', this._dragHandler, false);
      document.addEventListener('mouseup', this._dragEndHandler, false);
    }
    this._onDragStart(e);
  },

  _dragHandler(e) {
    if (this._dragRunning) { return; }
    this._dragRunning = true;
    requestAnimationFrame(() => {
      this._onDragUpdate(e, e.clientX - this._getTrackLeft());
      this._dragRunning = false;
    });
  },

  _dragTouchHandler(e) {
    if (this._dragRunning) { return; }
    this._dragRunning = true;
    requestAnimationFrame(() => {
      this._onDragUpdate(e, e.touches[0].clientX - this._getTrackLeft());
      this._dragRunning = false;
    });
  },

  _dragEndHandler(e) {
    if (document) {
      document.removeEventListener('mousemove', this._dragHandler, false);
      document.removeEventListener('mouseup', this._dragEndHandler, false);
    }

    this._onDragStop(e);
  },

  _dragTouchEndHandler(e) {
    if (document) {
      document.removeEventListener('touchmove', this._dragTouchHandler, false);
      document.removeEventListener('touchup', this._dragTouchEndHandler, false);
      document.removeEventListener('touchend', this._dragTouchEndHandler, false);
      document.removeEventListener('touchcancel', this._dragTouchEndHandler, false);
    }

    this._onDragStop(e);
  },

  getValue() {
    return this.state.value;
  },

  setValue(i) {
    // calculate percentage
    let percent = (i - this.props.min) / (this.props.max - this.props.min);
    if (isNaN(percent)) percent = 0;
    // update state
    this.setState({
      value: i,
      percent: percent,
    });
  },

  getPercent() {
    return this.state.percent;
  },

  setPercent(percent, callback) {
    let value = this._alignValue(this._percentToValue(percent));
    let { min, max } = this.props;
    let alignedPercent = (value - min) / (max - min);
    if (this.state.value !== value) {
      this.setState({value: value, percent: alignedPercent}, callback);
    }
  },

  clearValue() {
      this.setState({value: this.props.min,});
  },

  _alignValue(val) {
    let { step, min } = this.props;
    let alignValue = Math.round((val - min) / step) * step + min;
    return parseFloat(alignValue.toFixed(5));
  },

  _onFocus(e) {
    this.setState({focused: true});
    if (this.props.onFocus) this.props.onFocus(e);
  },

  _onBlur(e) {
    this.setState({focused: false, active: false});
    if (this.props.onBlur) this.props.onBlur(e);
  },

  _onMouseDown(e) {
    if (!this.props.disabled) this._pos = e.clientX;
  },

  _onMouseEnter() {
    this.setState({hovered: true});
  },

  _onMouseLeave() {
    this.setState({hovered: false});
  },

  _getTrackLeft() {
    return ReactDOM.findDOMNode(this.refs.track).getBoundingClientRect().left;
  },

  _onMouseUp(e) {
    if (!this.props.disabled) this.setState({active: false});
    if (!this.state.dragging && Math.abs(this._pos - e.clientX) < 5) {
      let pos = e.clientX - this._getTrackLeft();
      this._dragX(e, pos);
    }

    this._pos = undefined;
  },

  _onDragStart(e) {
    this.setState({
      dragging: true,
      active: true,
    });
    if (this.props.onDragStart) this.props.onDragStart(e);
  },

  _onDragStop(e) {
    this.setState({
      dragging: false,
      active: false,
    });
    if (this.props.onDragStop) this.props.onDragStop(e);
  },

  _onDragUpdate(e, pos) {
    if (!this.state.dragging) return;
    if (!this.props.disabled) this._dragX(e, pos);
  },

  _dragX(e, pos) {
    let max = ReactDOM.findDOMNode(this.refs.track).clientWidth;
    if (pos < 0) pos = 0; else if (pos > max) pos = max;
    this._updateWithChangeEvent(e, pos / max);
  },

  _updateWithChangeEvent(e, percent) {
    this.setPercent(percent, () => {
      if (this.props.onChange) this.props.onChange(e, this.state.value);
    });
  },

  _percentToValue(percent) {
    return percent * (this.props.max - this.props.min) + this.props.min;
  },

});

module.exports = Slider;
