const React = require('react');
const ReactDOM = require('react-dom');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const TextField = require('../matui/text-field.jsx');
const Checkbox = require('../matui/checkbox.jsx');
const IconButton = require('../matui/icon-button.jsx');
const IconButOver = require('../matui/icon_but_over.jsx');
const FontIcon = require('../matui/font-icon.jsx');

const AlgFuncUpd = require('../functions/algemeen');
var ReactTags = require('react-tag-input').WithContext;
const Mattag = require('../matui/mattag.jsx');
const Taglist = require('../matui/lists/taglist.jsx');
const SubLijst = require('./sublijstnw.jsx');
var Button = require("react-bootstrap").Button;
const FlatButton = require('../matui/flat-button.jsx');

const DateTime = require('../matui/utils/date-time');
const Snackbar = require('../matui/snackbar.jsx');
var Panel = require("react-bootstrap").Panel;
var PanelGroup = require("react-bootstrap").PanelGroup;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Nav = require("react-bootstrap").Nav;
var NavItem = require("react-bootstrap").NavItem;
const Contstafunc = require('./cont_func.jsx');
var Image = require("react-bootstrap").Image;
var MenuItem = require("react-bootstrap").MenuItem;

const PopupChTxt = require('./popchgtxtfixtcons.jsx');
const PopupSubFixt = require('./popsubfixtcons.jsx');
const PopupCopyFixt = require('./popcopyfixtcons.jsx');

const Slider = require('../matui/slider.jsx');
const ClsFixt = require('./cls_fixtures.jsx');

const Dialog = require('../matui/dialog.jsx');


const Zoekpopup = require('./zoekpopup.jsx');
const Algfunc = require('../functions/algemeen');
const Toolbar = require('../matui/toolbar/toolbar.jsx');
var ButtonGroup = require("react-bootstrap").ButtonGroup;

//import {Radar} from 'react-chartjs-2';

let PopupfixtDefCons = React.createClass({
  mixins: [PureRenderMixin],
  contextTypes:{
    router: React.PropTypes.object    
  },
  getInitialState() {
    return {
    disabelen: false,
    sleutel:'',
      dispvoet:'inherit',
      selcol:0,
      seltxtcol:0,
      slider2:null,
      slidtxt:'',
      slidmax:0,
      dispmeer:'none',
      popsubfixt:null,
      popcopyfixt:null,      
      popupcons:null,
      popupchtxt:null,
      lichtData: [],   
      txtdata:null,  
      lichtkol:[{"datakey":"fixture_text","label":"","width":"40","sortable":"0","colsorttype":"Str","type":"","cellrender":"Y"},
          {"datakey":"fixture_low","label":"Level Low","width":"25","sortable":"0","colsorttype":"","type":"","cellrender":"Y"},
          {"datakey":"fixture_high","label":"Level High","width":"25","sortable":"0","colsorttype":"","type":"","cellrender":"Y"},],
      txtzien:(<div style={{marginTop: 'auto',marginBottom: 'auto', display: 'inline-block'}}><span style={{fontSize:'10px',fontWeight: 'normal',color: '#2fa5c7'}} >Meer zien</span>
                <FontIcon
                  className="fa fa-sort-desc"
                  style={{color:'#2fa5c7', iconHoverColor:'darkslateblue',fontSize:'15px',paddingLeft: '5px',bottom:'2px'}}>
                </FontIcon> </div>),
      tagpadtop:'0px',
      jaar:0,
      expa:false,      
      ConsId:'',
      vorm:'CONS',
      sqldata: {param: 0},   
      edit:0,
      activkey:1,
      err_naam:'',
      updatecol:'',
      updatetxt:'', 
      data: {},      
      route: '',
      searchkey: 'id',
      height: (window.innerHeight).toString(),
      data:{id:0},
      HListData:[],
      dgcolumns:[],      
      colFilter: [],
      immuData:Immutable.List(),
      contcons:Immutable.List(),
      dragstart:0,
    };
  },
  componentWillMount() {
    let nw_date = new Date();
    this.state.jaar = nw_date.getFullYear();
  },
  componentDidMount() {
    this.setState({activkey:1})
    if (this.props.data) 
    {
       this.openen(this.props.data);
    }    
  },
  render() {
    var maxhdial = (window.innerHeight -100) + 'px';
    var maxhdialrol = (window.innerHeight - 250) + 'px';

    var slider2 = (<div style={{display: 'flex',
        height: 350,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 300}} 
            axis="y" 
            defaultValue={0} 
            min={0}
            max={this.state.slidmax}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onChange={this.verander} />
        </div>);

    var slidertxt = (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox', display: 'flex', WebkitFlexDirection: 'column', marginTop:'0px',
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '88px', width: '88px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'14px',fontWeight: '400',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>

                      </div>);


    let icont = (this.state.seltxtcol > 0) ? (<IconButOver
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
                  tooltip="Change text selected rows"
                  iconClassName="fa fa-newspaper-o" 
                  onClick={this.TextClick}/>) : null;


    let icdel = (this.state.seltxtcol > 0) ? (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                  iconClassName="fa fa-trash-o" 
                  tooltip="Delete selected rows" 
                  onClick={this.DeleteClick}/>) : null;

    let iccopy = (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                  iconClassName="fa fa-files-o" 
                  tooltip="Copy fixture" 
                  onClick={this.CopyClick}/>);


    let icexport = (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                  iconClassName="fa fa-file-excel-o" 
                  tooltip="Export fixture" 
                  onClick={this.handleExport}/>);

    
    

    let tot_zichtpop = null;
    let tot_zicht1 = null;
    let tot_zicht2 = null;
    let brgrrechts = (this.props.brgrrechts) ? (this.props.brgrrechts * .8) :296;

    // Definieren van de headers
    var w = window.innerWidth - 5;
    var h = window.innerHeight - 300;
    let beeld = screen.width;
    let beeldh = screen.height;
    var winbreed = window.innerWidth;

    let gridbreed = (winbreed > 1850) ? ((winbreed / 100) * 95) -1050 : ((winbreed / 100) * 95) -750;   
    if (winbreed > 1850 && gridbreed > 900) {gridbreed = 850}
    if (winbreed < 1850 && gridbreed > 2000) {gridbreed = 1650}
    if (gridbreed < 1000) {gridbreed = 650}

    let lst_licht = (<SubLijst ref="LichtLijst" id='LichtLijstRef' route='lichtlijst'  hdata={{route:'lichtlijst', data:this.state.lichtData, kol:this.state.lichtkol}} height={375} mhoog={375} breed={gridbreed} hoog={375} sleutel={App.ConsId} behandel={false} zoeken={false} ctrlklik={this.CtrlKlik} klik={this.KlikAct} klikken={this.KlikKennisM}/>);

      // bovenste paneel
    let PTitsocial = (this.state.edit == 0 && beeld > 749 ) ? 
                    (<h3 className="panel-title">
                      <Col xs={3} md={3} sm={3} >
                        {iccopy}
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                        {icexport}
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col></h3>) :
                      (this.state.edit == 0 && beeld < 750 ) ? 
                    (<h3 className="panel-title">
                      <Col xs={4} md={4} sm={4} >
                        {iccopy}                      
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                        {icexport}
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                      </Col></h3>) : null;
    let PTitedit = (<h3 className="panel-title">
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                        {icdel}
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                        {icont}
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col></h3>) ;
    let pan2deel1 = (<div style={{marginBottom: '0', display: '-webkit-flex', display: '-ms-flexbox',display: 'flex', margin: '12px 0', marginLeft: '0', marginRight: '0', padding: '0', wordWrap: 'break-word',
                        backgroundColor: '#fff',borderRadius:'2px', position: 'relative', listStyle: 'none'}}>
                        <div style={{border: '0', textDecoration: 'none', MsTouchAction: 'manipulation', touchAction: 'manipulation', padding: '8px 12px 12px', WebkitFlex: '1 1 0',MsFlex: '1 1 0px', flex: '1 1 0',
                             fontWeight: '700',backgroundColor: 'transparent', color: '#0084bf', margin: '0',fontSize: '100%', background: '0 0', verticalAlign: 'baseline'}}>
                          {this.state.slider2}
                          {slidertxt}
                        </div>
                         <div style={{border: '0', textDecoration: 'none', MsTouchAction: 'manipulation', touchAction: 'manipulation', padding: '8px 12px 12px', WebkitFlex: '1 1 0',MsFlex: '1 1 0px', flex: '1 1 0',
                          fontWeight: '700',backgroundColor: 'transparent', color: '#0084bf', margin: '0',fontSize: '100%', background: '0 0', verticalAlign: 'baseline', borderLeft: '1px solid rgba(0,0,0,.10)'}}>
                           {lst_licht}
                          </div>
                        </div>);
    let pan2 = (
                  <div id='cns_hfdpan' style={{marginBottom: '16px', marginTop:'10px', padding: '24px', wordWrap: 'break-word', boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2)', display: 'block', backgroundColor: '#fff', borderRadius: '2px',position: 'relative', listStyle: 'none', outline: '0', fontSize: '100%', verticalAlign: 'baseline', boxSizing: 'inherit',}}>
                     <div id='cns_hfdpan1' style={{margin:'0',padding:'0',border:'0',outline:'0',fontSize:'100%',verticalAlign:'baseline',background: '0 0', boxSizing: 'inherit', display: 'block'}}>
                       <div id='card_header1' style={{margin: '-85px auto 0', position: 'relative', width: 'auto'}}>
                            <div id='photo_header1' style={{textAlign: 'center', boxSizing: 'content-box', margin: 'auto', position: 'relative', marginTop:'-65px',width:'128px',height:'128px',borderRadius:'50%'}}>
                          </div>
                          <div id='card_header1' style={{left: '24px', position: 'absolute', top: '75px'}}>
                            {PTitsocial}
                          </div>
 
                          <div id='carbody1' style={{textAlign:'center',position: 'relative',marginBottom:'30px'}}>
                            <div style={{padding: '0 40px',marginBottom:'0'}}>
                              {pan2deel1}
                             </div>
                          </div>
                          <div id='card_header1' style={{right: '24px', position: 'absolute', top: '75px'}}>
                            {PTitedit}
                          </div>
                       </div>
                     </div>
                  </div>)
    // einde bovenste paneel
    // Gedeelte voor lezen documenten
  


    // einde enkel voor mobile
    tot_zicht2 = (
      <div className='rowlist' style={{marginLeft:'15px', marginBottom: '60px', marginRight: '10px'}}>
        {pan2}
      </div>);    

    tot_zichtpop = (<div>
            {this.state.popupchtxt}
            {this.state.popsubfixt}
            {this.state.popcopyfixt}
          </div>);
    
    // Einde opbouw 

    tot_zicht1 = (
      <div className='row' style={{width:'100%',marginBottom:'0px',display:'inline-block',overflowY:'hidden',overflowX:'hidden'}}>
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol, bottom:'60px'}}
          message={this.state.updatetxt}/>
        {tot_zichtpop}
        {tot_zicht2}
      </div>); 

    // Einde velden definitei
    let voet = null;
 
    let bgroep = null;
    if (screen.width < 750) 
    {   
      let hrbut1 = (
                      <IconButton
                      style={{width:'30px',height:'30px', paddingLeft:'5px', paddingRight:'5px',display:this.state.ldisplay}}
                      iconClassName="fa fa-floppy-o" 
                      iconStyle={{color:'white', iconHoverColor:'gray',fontSize:'small'}}
                      onClick={this.Butbewaren}/>
                    ) ;

      bgroep = (
       <ButtonGroup vertical style={{position:'fixed', top:'25%', right:'10px',
          zIndex:'99999999',
          display:this.state.dispvoet,
          boxShadow: '0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22)',
          borderRadius: '2px',
          borderTopLeftRadius: '2px',
          borderTopRightRadius: '2px',
          borderBottomRightRadius: '2px',
          borderBottomLeftRadius: '2px',
          backgroundColor: 'rgba(0, 143, 183,.7)',
          width:'30px',
          paddingTop: '5px',
          paddingBottom: '5px'}}>
          {hrbut1}
       </ButtonGroup>);                                       
    }
    let mwdth = (screen.width < 750) ? '100%' : '90%';
    let wdth = (screen.width < 750) ? '100%' : '90%';
    let cmwdth = (screen.width < 750) ? '100%' : '98%';
    let cwdth = (screen.width < 750) ? '100%' : '98%';
    let clft = (screen.width < 750) ? '0' : '5%';
    let titel = "Fixture Detail: "+this.state.data.name;
    return ( 
     <Dialog 
        ref="dafwcontrole"
        title={titel}
        second={true}
        sluiten={this.sluiten}
        hidetoolb={true}        
        style={{fontSize: '100%', width:wdth, maxWidth:mwdth}}
        contentStyle={{width:cwdth, maxWidth:cmwdth, left:clft,maxHeight:maxhdial,overflow:'hidden'}}
        footer= {voet}
        id="actcont1"
        notoolb={true}
        onDismiss={this.zetsluiten}
        paddingtop={this.props.paddingtop}
        zindex={this.props.zindex}        
        openImmediately={false}
        repositionOnUpdate={false}>
        {tot_zicht1}
      </Dialog>)
  },  

  sluiten(data){
    if (this.props.Sluiten) {this.props.Sluiten(data)}
  },
  zetsluiten() {
    if (this.props.Sluiten) {this.props.Sluiten(this.state.data)}
  }, 
  // Algemene funkties
  openen(hudata){
    this.state.sleutel = hudata.id;
    this.refs.dafwcontrole.show();
    this.state.origin = hudata.Origin;
    if (hudata.id.length > 3)
    {
      this.setState({slider2:null});
      this.setState({sleutel:hudata.id});
      this.setState({seltxtcol:0});
      let skey = hudata.id; 
      ClsFixt.GetFixtureCons({id:skey})
        .then(data => {
          this.setState({data:data});
          this.setState({lichtData:data.subdata});
          let maxslide = data.subdata.length - 1;
          if (maxslide < 0) {maxslide = 0};
          this.setState({slidmax:maxslide});

          let sdat = data.subdata;
          let vdat = sdat[0];
          vdat.checked = true;
          sdat[0] = vdat;
          this.state.selcol = 0;
          this.setState({slidtxt:vdat.fixture_text});
          if (this.refs.LichtLijst)
          {
            this.refs.LichtLijst.ZetSelect(0,sdat); 
            var slider2 = (<div style={{display: 'flex',
              height: 300,
              flexDirection: 'row',
              justifyContent: 'space-around',}}>
              <Slider name="slider4" 
                  style={{height: 250}} 
                  axis="y" 
                  defaultValue={0} 
                  min={0}
                  max={maxslide}
                  step={1}
                  onDragStop={this.dragstop} 
                  onDragStart={this.dragstart} 
                  onChange={this.verander} />
              </div>);
            this.setState({slider2:slider2});

          }

        })
        .catch(data => {
            return true;
      });
      this.setState({edit:0});
    }
  },

  zetedit(skey){
    if (this.isMounted)
    {
      this.setState({slider2:null});
      this.setState({sleutel:skey});
      this.setState({seltxtcol:0});
      ClsFixt.GetFixtureCons({id:skey})
        .then(data => {
          this.setState({data:data});
          this.setState({lichtData:data.subdata});
          let maxslide = data.subdata.length - 1;
          this.setState({slidmax:maxslide});

          let sdat = data.subdata;
          let vdat = sdat[0];
          vdat.checked = true;
          sdat[0] = vdat;
          this.state.selcol = 0;
          this.setState({slidtxt:vdat.fixture_text});
          if (this.refs.LichtLijst)
          {
            this.refs.LichtLijst.ZetSelect(0,sdat); 
            var slider2 = (<div style={{display: 'flex',
              height: 400,
              flexDirection: 'row',
              justifyContent: 'space-around',}}>
              <Slider name="slider4" 
                  style={{height: 350}} 
                  axis="y" 
                  defaultValue={0} 
                  min={0}
                  max={maxslide}
                  step={1}
                  onDragStop={this.dragstop} 
                  onDragStart={this.dragstart} 
                  onChange={this.verander} />
              </div>);
            this.setState({slider2:slider2});

          }

        })
        .catch(data => {
            return true;
      });
      this.setState({edit:0});
    }
  },
  dragstop(e, ui) {
    this.setState({dragstart:0});
  },
  dragstart(e, ui) {
    this.setState({dragstart:1});
  },
  verander(e,value){
      let sdat = this.state.lichtData;
      let vdat = sdat[this.state.selcol];
      vdat.checked = false;
      sdat[this.state.selcol] = vdat;
      this.state.selcol = value;
      vdat = sdat[this.state.selcol];
      vdat.checked = true;
      sdat[this.state.selcol] = vdat;
      this.setState({lichtData:sdat});
      if (this.refs.LichtLijst)
      {
        this.setState({slidtxt:vdat.fixture_text});
        this.refs.LichtLijst.ZetSelect(value,sdat); 
      }
  },

  editsluiten(data) {
    this.setState({popupcons:null});
  },

  txtsluiten(data) {
    this.setState({popupchtxt:null});
    this.zetfixgeg();
  },

  DeleteClick() {
    let hudata = {data:this.state.txtdata}
    ClsFixt.DelSubFixtureCons(hudata)
    .then(data => {
      this.zetfixgeg();
    })
    .catch(data => {
        return true;
    });
  },

  zetfixgeg(){
      this.setState({slider2:null});
      let skey = this.state.sleutel; 
      this.setState({seltxtcol:0});
      ClsFixt.GetFixtureCons({id:skey})
        .then(data => {
          this.setState({data:data});
          this.setState({lichtData:data.subdata});
          let maxslide = data.subdata.length - 1;
          if (maxslide < 0) {maxslide = 0};
          this.setState({slidmax:maxslide});
          let sdat = data.subdata;
          let vdat = sdat[0];
          vdat.checked = true;
          sdat[0] = vdat;
          this.state.selcol = 0;
          this.setState({slidtxt:vdat.fixture_text});
          if (this.refs.LichtLijst)
          {
            this.refs.LichtLijst.ZetSelect(0,sdat); 
            var slider2 = (<div style={{display: 'flex',
              height: 350,
              flexDirection: 'row',
              justifyContent: 'space-around',}}>
              <Slider name="slider4" 
                  style={{height: 300}} 
                  axis="y" 
                  defaultValue={0} 
                  min={0}
                  max={maxslide}
                  step={1}
                  onDragStop={this.dragstop} 
                  onDragStart={this.dragstart} 
                  onChange={this.verander} />
              </div>);
            this.setState({slider2:slider2});
          }
        })
        .catch(data => {
            return true;
      });
      this.setState({edit:0});
  },
  EditClick(selectedKey) {
    let hdata = {id:App.ConsId}; 
    this.setState({popupcons:(<PopupCons  id='popupcons' data={hdata} zindex={999999} Sluiten={this.editsluiten}></PopupCons>)})
    return;
  },
  TextClick() {
    this.setState({popupchtxt:(<PopupChTxt  id='PopupChTxt' data={this.state.txtdata} zindex={999999} Sluiten={this.txtsluiten}></PopupChTxt>)})
    return;
  },

  CopyClick() {
    this.setState({popcopyfixt:(<PopupCopyFixt  id='PopupChTxt' data={this.state.data} zindex={999999} Sluiten={this.copysluiten}></PopupCopyFixt>)})
    return;
  },
  copysluiten(data) {
    this.setState({popcopyfixt:null});
    this.sluiten();
  },


  // Einde funkties popup openen
  // functies activiteiten
  sluitactpopup(data) {
    this.setState({popsubfixt:null});
    this.zetfixgeg();        
  },

  CtrlKlik(data) {
    var index, column;    
    let arra;
    // controleren of arra && of || bevat
    let hudata = data;
    let ColFilter = ['checked'];
    let value = '2';
    var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = eval('row.'+filCol);            
          }
          else
          {
            arra = ''; 
          }
          if (typeof arra === 'string' || arra instanceof String)
          {

          }
          else
          {
            arra='';
          }
          if(arra.indexOf(value) >= 0)
          {
            return arra.indexOf(value) >= 0
          }
        }
      }) : data;
      this.setState({seltxtcol:filteredRows.length});
      this.setState({txtdata:data});
  },

  KlikAct(waarde, data) {
    this.setState({laden:1});
    this.setState({popsubfixt:(<PopupSubFixt  id='popupact1' data={data} route = 'activiteit' zindex={999999} Sluiten={this.sluitactpopup} ></PopupSubFixt>)})
  },  

  // einde functies activiteiten

  clickaccount() {
    if (this.props.clickaccount)
    {
      this.props.clickaccount({id:this.props.data.parent_account});
    }
    // Einde ophalen gegevens
  },

  handleExport(){
    var arrData = this.state.data.subdata;
    var CSV = '';    
    let ReportTitle = this.state.data.name;
    CSV += '';
    let ShowLabel = true;
    var row = "";
    row += "(internal);";
    row += "text;";
    row += "high;";
    row += "low;";
    row += "(internal);";
    row += "output;";
    row = row.slice(0, -1);
    CSV += row + '\r\n';

    let arra = '';
    let colex = ['fixture_text','fixture_high','fixture_low','fixture_internal','fixture_output'];
    for (var i = 0; i < arrData.length; i++) 
    {
      var row = "";
      let gegrow = arrData[i];
      row += i + ";";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (let rowIdx = 0; rowIdx < colex.length; rowIdx++) 
      {
        let filCol = colex[rowIdx];
        if (eval('gegrow.'+filCol))
        {
          arra = eval('gegrow.'+filCol);      
     
          row += arra +  ";";
        }
      }
      row.slice(0, row.length - 1);
      //add a line break after each row
      CSV += row + '\r\n';
    }
    if (CSV == '') 
    {        
      alert("Invalid data");
      return;
    }   
    //Generate a file name
    var fileName = "Fixt_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g,"_");   
    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");    
    link.href = uri;
    
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); 
  },



});

module.exports = PopupfixtDefCons;






