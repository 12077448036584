module.exports={
  "main": {
    "nl-BE": {
      "identity": {
        "version": {
          "_number": "$Revision: 13133 $",
          "_cldrVersion": "31.0.1"
        },
        "language": "nl",
        "territory": "BE"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "jan.",
                  "2": "feb.",
                  "3": "mrt.",
                  "4": "apr.",
                  "5": "mei",
                  "6": "jun.",
                  "7": "jul.",
                  "8": "aug.",
                  "9": "sep.",
                  "10": "okt.",
                  "11": "nov.",
                  "12": "dec."
                },
                "narrow": {
                  "1": "J",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "A",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "januari",
                  "2": "februari",
                  "3": "maart",
                  "4": "april",
                  "5": "mei",
                  "6": "juni",
                  "7": "juli",
                  "8": "augustus",
                  "9": "september",
                  "10": "oktober",
                  "11": "november",
                  "12": "december"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "jan.",
                  "2": "feb.",
                  "3": "mrt.",
                  "4": "apr.",
                  "5": "mei",
                  "6": "jun.",
                  "7": "jul.",
                  "8": "aug.",
                  "9": "sep.",
                  "10": "okt.",
                  "11": "nov.",
                  "12": "dec."
                },
                "narrow": {
                  "1": "J",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "A",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "januari",
                  "2": "februari",
                  "3": "maart",
                  "4": "april",
                  "5": "mei",
                  "6": "juni",
                  "7": "juli",
                  "8": "augustus",
                  "9": "september",
                  "10": "oktober",
                  "11": "november",
                  "12": "december"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "zo",
                  "mon": "ma",
                  "tue": "di",
                  "wed": "wo",
                  "thu": "do",
                  "fri": "vr",
                  "sat": "za"
                },
                "narrow": {
                  "sun": "Z",
                  "mon": "M",
                  "tue": "D",
                  "wed": "W",
                  "thu": "D",
                  "fri": "V",
                  "sat": "Z"
                },
                "short": {
                  "sun": "zo",
                  "mon": "ma",
                  "tue": "di",
                  "wed": "wo",
                  "thu": "do",
                  "fri": "vr",
                  "sat": "za"
                },
                "wide": {
                  "sun": "zondag",
                  "mon": "maandag",
                  "tue": "dinsdag",
                  "wed": "woensdag",
                  "thu": "donderdag",
                  "fri": "vrijdag",
                  "sat": "zaterdag"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "zo",
                  "mon": "ma",
                  "tue": "di",
                  "wed": "wo",
                  "thu": "do",
                  "fri": "vr",
                  "sat": "za"
                },
                "narrow": {
                  "sun": "Z",
                  "mon": "M",
                  "tue": "D",
                  "wed": "W",
                  "thu": "D",
                  "fri": "V",
                  "sat": "Z"
                },
                "short": {
                  "sun": "zo",
                  "mon": "ma",
                  "tue": "di",
                  "wed": "wo",
                  "thu": "do",
                  "fri": "vr",
                  "sat": "za"
                },
                "wide": {
                  "sun": "zondag",
                  "mon": "maandag",
                  "tue": "dinsdag",
                  "wed": "woensdag",
                  "thu": "donderdag",
                  "fri": "vrijdag",
                  "sat": "zaterdag"
                }
              }
            },
            "quarters": {
              "format": {
                "abbreviated": {
                  "1": "K1",
                  "2": "K2",
                  "3": "K3",
                  "4": "K4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1e kwartaal",
                  "2": "2e kwartaal",
                  "3": "3e kwartaal",
                  "4": "4e kwartaal"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "K1",
                  "2": "K2",
                  "3": "K3",
                  "4": "K4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1e kwartaal",
                  "2": "2e kwartaal",
                  "3": "3e kwartaal",
                  "4": "4e kwartaal"
                }
              }
            },
            "dayPeriods": {
              "format": {
                "abbreviated": {
                  "midnight": "middernacht",
                  "am": "a.m.",
                  "pm": "p.m.",
                  "morning1": "ochtend",
                  "afternoon1": "middag",
                  "evening1": "avond",
                  "night1": "nacht"
                },
                "narrow": {
                  "midnight": "middernacht",
                  "am": "a.m.",
                  "pm": "p.m.",
                  "morning1": "‘s ochtends",
                  "afternoon1": "‘s middags",
                  "evening1": "‘s avonds",
                  "night1": "‘s nachts"
                },
                "wide": {
                  "midnight": "middernacht",
                  "am": "a.m.",
                  "pm": "p.m.",
                  "morning1": "‘s ochtends",
                  "afternoon1": "‘s middags",
                  "evening1": "‘s avonds",
                  "night1": "‘s nachts"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "midnight": "middernacht",
                  "am": "a.m.",
                  "pm": "p.m.",
                  "morning1": "ochtend",
                  "afternoon1": "middag",
                  "evening1": "avond",
                  "night1": "nacht"
                },
                "narrow": {
                  "midnight": "middernacht",
                  "am": "a.m.",
                  "pm": "p.m.",
                  "morning1": "ochtend",
                  "afternoon1": "middag",
                  "evening1": "avond",
                  "night1": "nacht"
                },
                "wide": {
                  "midnight": "middernacht",
                  "am": "a.m.",
                  "pm": "p.m.",
                  "morning1": "ochtend",
                  "afternoon1": "middag",
                  "evening1": "avond",
                  "night1": "nacht"
                }
              }
            },
            "eras": {
              "eraNames": {
                "0": "voor Christus",
                "0-alt-variant": "vóór gewone jaartelling",
                "1": "na Christus",
                "1-alt-variant": "gewone jaartelling"
              },
              "eraAbbr": {
                "0": "v.Chr.",
                "0-alt-variant": "v.g.j.",
                "1": "n.Chr.",
                "1-alt-variant": "g.j."
              },
              "eraNarrow": {
                "0": "v.C.",
                "0-alt-variant": "vgj",
                "1": "n.C.",
                "1-alt-variant": "gj"
              }
            },
            "dateFormats": {
              "full": "EEEE d MMMM y",
              "long": "d MMMM y",
              "medium": "d MMM y",
              "short": "d/MM/yy"
            },
            "timeFormats": {
              "full": "HH:mm:ss zzzz",
              "long": "HH:mm:ss z",
              "medium": "HH:mm:ss",
              "short": "HH:mm"
            },
            "dateTimeFormats": {
              "full": "{1} 'om' {0}",
              "long": "{1} 'om' {0}",
              "medium": "{1} {0}",
              "short": "{1} {0}",
              "availableFormats": {
                "d": "d",
                "E": "ccc",
                "Ed": "E d",
                "Ehm": "E h:mm a",
                "EHm": "E HH:mm",
                "Ehms": "E h:mm:ss a",
                "EHms": "E HH:mm:ss",
                "Gy": "y G",
                "GyMMM": "MMM y G",
                "GyMMMd": "d MMM y G",
                "GyMMMEd": "E d MMM y G",
                "h": "h a",
                "H": "HH",
                "hm": "h:mm a",
                "Hm": "HH:mm",
                "hms": "h:mm:ss a",
                "Hms": "HH:mm:ss",
                "hmsv": "h:mm:ss a v",
                "Hmsv": "HH:mm:ss v",
                "hmv": "h:mm a v",
                "Hmv": "HH:mm v",
                "M": "L",
                "Md": "d/M",
                "MEd": "E d/M",
                "MMM": "LLL",
                "MMMd": "d MMM",
                "MMMEd": "E d MMM",
                "MMMMd": "d MMMM",
                "MMMMW-count-one": "'week' W 'van' MMM",
                "MMMMW-count-other": "'week' W 'van' MMM",
                "ms": "mm:ss",
                "y": "y",
                "yM": "M/y",
                "yMd": "d/M/y",
                "yMEd": "E d/M/y",
                "yMMM": "MMM y",
                "yMMMd": "d MMM y",
                "yMMMEd": "E d MMM y",
                "yMMMM": "MMMM y",
                "yQQQ": "QQQ y",
                "yQQQQ": "QQQQ y",
                "yw-count-one": "'week' w 'in' y",
                "yw-count-other": "'week' w 'in' y"
              },
              "appendItems": {
                "Day": "{0} ({2}: {1})",
                "Day-Of-Week": "{0} {1}",
                "Era": "{1} {0}",
                "Hour": "{0} ({2}: {1})",
                "Minute": "{0} ({2}: {1})",
                "Month": "{0} ({2}: {1})",
                "Quarter": "{0} ({2}: {1})",
                "Second": "{0} ({2}: {1})",
                "Timezone": "{0} {1}",
                "Week": "{0} ({2}: {1})",
                "Year": "{1} {0}"
              },
              "intervalFormats": {
                "intervalFormatFallback": "{0} - {1}",
                "d": {
                  "d": "d–d"
                },
                "h": {
                  "a": "h a – h a",
                  "h": "h–h a"
                },
                "H": {
                  "H": "HH–HH"
                },
                "hm": {
                  "a": "h:mm a – h:mm a",
                  "h": "h:mm–h:mm a",
                  "m": "h:mm–h:mm a"
                },
                "Hm": {
                  "H": "HH:mm–HH:mm",
                  "m": "HH:mm–HH:mm"
                },
                "hmv": {
                  "a": "h:mm a – h:mm a v",
                  "h": "h:mm–h:mm a v",
                  "m": "h:mm–h:mm a v"
                },
                "Hmv": {
                  "H": "HH:mm–HH:mm v",
                  "m": "HH:mm–HH:mm v"
                },
                "hv": {
                  "a": "h a – h a v",
                  "h": "h–h a v"
                },
                "Hv": {
                  "H": "HH–HH v"
                },
                "M": {
                  "M": "M–M"
                },
                "Md": {
                  "d": "d/MM – d/MM",
                  "M": "d/MM – d/MM"
                },
                "MEd": {
                  "d": "E d/MM – E d/MM",
                  "M": "E d/MM – E d/MM"
                },
                "MMM": {
                  "M": "MMM–MMM"
                },
                "MMMd": {
                  "d": "d–d MMM",
                  "M": "d MMM – d MMM"
                },
                "MMMEd": {
                  "d": "E d – E d MMM",
                  "M": "E d MMM – E d MMM"
                },
                "MMMM": {
                  "M": "MMMM–MMMM"
                },
                "y": {
                  "y": "y–y"
                },
                "yM": {
                  "M": "MM/y – MM/y",
                  "y": "MM/y – MM/y"
                },
                "yMd": {
                  "d": "d/MM/y – d/MM/y",
                  "M": "d/MM/y – d/MM/y",
                  "y": "d/MM/y – d/MM/y"
                },
                "yMEd": {
                  "d": "E d/MM/y – E d/MM/y",
                  "M": "E d/MM/y – E d/MM/y",
                  "y": "E d/MM/y – E d/MM/y"
                },
                "yMMM": {
                  "M": "MMM–MMM y",
                  "y": "MMM y – MMM y"
                },
                "yMMMd": {
                  "d": "d–d MMM y",
                  "M": "d MMM – d MMM y",
                  "y": "d MMM y – d MMM y"
                },
                "yMMMEd": {
                  "d": "E d – E d MMM y",
                  "M": "E d MMM – E d MMM y",
                  "y": "E d MMM y – E d MMM y"
                },
                "yMMMM": {
                  "M": "MMMM–MMMM y",
                  "y": "MMMM y – MMMM y"
                }
              }
            }
          }
        }
      }
    }
  }
}
