import React from 'react';
import Region from 'region';
import ReactDOM from 'react-dom';

import PureRenderMixin from 'react-addons-pure-render-mixin';
import PropTypes from '../utils/prop-types';
const UniqueId = require('../utils/unique-id');

import StylePropable from '../mixins/style-propable';
import Typography from '../styles/typography';
const Mattag = require('../mattag.jsx');
import Paper from '../paper.jsx';
const TextField = require('../text-field.jsx');
import ListDivider from './list-divider.jsx';
import DefaultRawTheme from '../styles/raw-themes/light-raw-theme';
import ThemeManager from '../styles/theme-manager';

var Popover = require("react-bootstrap").Popover;
var OverlayTrigger = require("react-bootstrap").OverlayTrigger;

const ListTag = React.createClass({

  mixins: [
    PureRenderMixin,
    StylePropable,
  ],

  contextTypes: {
    muiTheme: React.PropTypes.object,
    listItems:React.PropTypes.array,

  },

  propTypes: {
    children: React.PropTypes.node,
    insetSubheader: React.PropTypes.bool,

    /**
     * Override the inline-styles of the root element.
     */
    style: React.PropTypes.object,
    subheader: React.PropTypes.node,
    subheaderStyle: React.PropTypes.object,
    zDepth: PropTypes.zDepth,
  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
    listItems:React.PropTypes.array,
  },

  getChildContext() {
    return {
      muiTheme: this.state.muiTheme,
      listItems: this.state.lijst,

    };
  },

  getDefaultProps() {
    return {
      zDepth: 0,
    };
  },

  getInitialState() {
    return {
      positie:'niks',
      Vtags:[],
      children:null,
      lijst:this.props.listItems,
      tagkey:0,
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  //to update theme inside state whenever a new theme is passed down
  //from the parent / owner using context
  componentDidMount() {
    if (this.state.lijst.length < 1)
    {
       this.setState({children: null});
    }

  },

  componentWillReceiveProps(nextProps, nextContext) {
    let newMuiTheme = nextContext.muiTheme ? nextContext.muiTheme : this.state.muiTheme;
    this.setState({muiTheme: newMuiTheme});
    let newList = nextProps.listItems ? nextProps.listItems : this.state.lijst;
    this.setState({lijst: newList});
    if (newList.length >0)
    {
        this.setState({children: this._getChildren()});

    }
    else
    {
       this.setState({children: null});

    }
  },
  LabClick(column) {
      if (this.props.LabClick)
      {
        this.props.LabClick(column);
      }
  },   

  
  _filterRowsBy(e) {
      if (this.props.onFilter)
      {
        this.props.onFilter(e.target.value);
      }
  },      

 _handleListItemTouchTap(sleutel) {
    if (this.props.onKlik) this.props.onKlik(sleutel);
  },  
  _getChildren() {
    if (!this.state.lijst) 
    {
      return
    }
    let  listItem,
      itemComponent,
      isSelected,
      isDisabled,
      rightAvatar,
      leftAvatar;
    let _vchildren = [];
    //This array is used to keep track of all nested menu refs
    for (let i=0; i < this.state.lijst.length; i++) {
      listItem = this.state.lijst[i];

      var likey =UniqueId.generate();
      if(listItem.primaryText)
      {
        if (listItem.primaryText.length > 0)
        {
            itemComponent = (
            <Mattag iconClassName="fa fa-times"
              aantal={listItem.secondaryText}
              label={listItem.primaryText}
              tooltip={listItem.tooltip}
              key={likey}
              column={listItem}
              menu={this.props.menu}
              nodrag={this.props.nodrag}
              nodelete={this.props.nodelete}
              menuitems={this.props.menuitems}
              onMouseDown={this.down}
              onMouseUp={this.delete}
              onMenuChange={this.onMenuChange}
              LabClick={this.LabClick}
              onStart={this.onStart.bind(this, listItem)}
              onStop={this.onStop.bind(this, listItem)}
              onDrag={this.onDrag}>  
            </Mattag>)
            if (listItem.tooltip)
            {
              if (listItem.tooltip.length > 3)
              {
                var ttkey = 'tt'+likey;
                var popoverFocus = (
                    <Popover id={ttkey}>
                      <p style={{fontSize:'x-small'}}>{listItem.tooltip}<br/></p>
                    </Popover>);


                itemComponent = (
                    <OverlayTrigger style={{zIndex: 9999999999}} key={likey} trigger={['hover', 'focus']} placement="bottom" overlay={popoverFocus}>
                      <Mattag iconClassName="fa fa-times"
                        aantal={listItem.secondaryText}
                        label={listItem.primaryText}
                        tooltip={listItem.tooltip}
                        column={listItem}
                        menu={this.props.menu}
                        nodrag={this.props.nodrag}
                        nodelete={this.props.nodelete}
                        menuitems={this.props.menuitems}
                        onMouseDown={this.down}
                        onMouseUp={this.delete}
                        onMenuChange={this.onMenuChange}
                        LabClick={this.LabClick}
                        onStart={this.onStart.bind(this, listItem)}
                        onStop={this.onStop.bind(this, listItem)}
                        onDrag={this.onDrag}>  
                      </Mattag>
                    </OverlayTrigger>)

              }
            }

             _vchildren.push(itemComponent);
        }

      }
    }
    this.setState({children:_vchildren});
    return _vchildren;
  },  



  onStart: function(column,e) {
        var dragColumn = column;
        var headerNode = ReactDOM.findDOMNode(this);

        var headerRegion = Region.from(headerNode);
        var dragColumnIndex
        var columnData
        var shiftRegion
        var that = this;
        var columnHeaders = headerNode.querySelectorAll('.react-draggable')

        this.setState({Vtags:columnHeaders});
        this.setState({
        deltaPosition: {
          left:0,
          top:0,
        },
        itzoneTop:0,
        mvTop:0} )    ;   

        this.setState({
          dragColumn: column,
          dragging  : false
        })

        this.setState({columnData:null});
        this.setState({dragColumnIndex:0});
        this.setState({dragColumn:''});
        this.setState({shiftRegion:0});
        this.setState({orgtarg:null});

            columnData = that.state.lijst.map(function(column, i){
                if (i <= columnHeaders.length)
                {
                var region = Region.from(columnHeaders[i])
                if (column === dragColumn)
                {
                    dragColumnIndex = i
                    shiftRegion = region.clone()
                    that.setState({itzoneTop:region.top})
                    that.setState({positie:region.top})
                }

                return {
                    column: column,
                    index: i,
                    region: region
                }
                }
            })

            this.setState({
                dragColumn: column,
                dragging  : true
            })

        this.setState({columnData:columnData});
        this.setState({dragColumnIndex:dragColumnIndex});
        this.setState({dragColumn:dragColumn});
        this.setState({shiftRegion:shiftRegion});
        this.setState({orgtarg:e.target});
  },

  onDrag: function (e, ui) {
        var left = this.state.deltaPosition.left
        var top = this.state.deltaPosition.top
        var diff = this.state.deltaPosition.left + ui.deltaX;
        var dtop = this.state.deltaPosition.top + ui.deltaY;

        var directionSign = diff < 0? -1: 1
        var directionTopSign = ui.deltaY < 0? -1: 1

        var state = {
            dragColumnIndex  : this.state.dragColumnIndex,
            dragColumn  : this.state.dragColumn,
            dragLeft    : diff,
            dropIndex   : null,
            shiftIndexes: null,
            shiftSize   : null
        }
        if (this.state.dragColumnIndex == 0)
        {
            if (diff < 0)
            {
                e.target.style.WebkitTransform= 'translate(0px,0px)';
                e.target.style.transform = 'translate(0px,0px)'; 
                e.target.style.msTransform = 'translate(0px,0px)'; 
                return
            }
        }
        var shift,shifttop
        var shiftSize, shiftHeight
        var newLeft   = this.state.shiftRegion.left + diff
        var newRight  = newLeft + this.state.shiftRegion.width
        var newbTop   = this.state.shiftRegion.top + dtop
        var newTop  = newbTop - 10;


        var shiftZone = { left: newLeft, right: newRight, top:newTop}
        this.setState({
            deltaPosition: {
            left: left + ui.deltaX,
            top: top + ui.deltaY,}
        });        
        let that=this;
        this.state.columnData.forEach(function(columnData, index, arr){
            var itColumn = columnData.column
            var itRegion = columnData.region

            if (shift || itColumn === that.state.dragColumn){
                return
            }

            var itLeft  = itRegion.left
            var itRight = itRegion.right
            var itTop = itRegion.top

            var itZone  = directionSign == -1?
                { left: itLeft, right: itLeft + itRegion.width }:
                { left: itRight - itRegion.width, right: itRight }
            if (directionSign == -1)
            {
                if (directionTopSign == -1)
                {
                    itZone = { left: itLeft, right: itLeft + itRegion.width, top:itTop }
                }
                else
                {
                    itZone = { left: itLeft, right: itLeft + itRegion.width, top:itTop}
                }

            }
            else
            {
                if (directionTopSign == -1)
                {
                    itZone = { left: itRight - itRegion.width, right: itRight, top:itTop }
                }
                else
                {
                    itZone = { left: itRight - itRegion.width, right: itRight, top:itTop}
                }

            }

            if (that.state.shiftRegion.width < itRegion.width){
                //shift region is smaller than itRegion
                shift = Region.getIntersectionWidth(
                    itZone,
                    shiftZone
                ) >= Math.min(
                    itRegion.width,
                    that.state.shiftRegion.width
                ) / 2

            } else {
                //shift region is bigger than itRegion
                shift = Region.getIntersectionWidth(itRegion, shiftZone) >= itRegion.width / 2
            }

            if (directionTopSign == 1)
            {
                if (newTop >= that.state.itzoneTop +15 )
                {
                  if (that.state.itzoneTop < itRegion.top )
                  {
                      that.setState({itzoneTop:itRegion.top});
                  }
                }
            }
            else
            {
                if (newTop <= that.state.itzoneTop -15 )
                {
                  if (that.state.itzoneTop > itRegion.top )
                  {
                      that.setState({itzoneTop:itRegion.top});
                  }
                }
            }

            if (that.state.itzoneTop == itRegion.top )
            {
                if (shift) {
                    shiftSize = -directionSign * that.state.shiftRegion.width
                    state.dropIndex = index
                    state.shiftIndexes = that.buildIndexes(directionSign, index, that.state.dragColumnIndex)
                    state.shiftSize = shiftSize
                }
                else
                {
                  if (state.dropIndex == null)
                  {
                    shiftSize = -directionSign * that.state.shiftRegion.width
                    state.dropIndex = index
                    state.shiftIndexes = that.buildIndexes(directionSign, index, that.state.dragColumnIndex)
                    state.shiftSize = shiftSize
                  }
  
                }
            }
        })
        that.setState({startpos:0});        
        that.setState(state)
  },    

  onStop: function(column,e) {
    this.setState({startpos:999999});
    this.state.orgtarg.style.WebkitTransform= 'translate(0px,0px)';
    this.state.orgtarg.style.transform = 'translate(0px,0px)'; 
    this.state.orgtarg.style.msTransform = 'translate(0px,0px)'; 
    this.setState({orgtarg:null});
    this.onDrop(e);
  },

  onDrop: function(event){
    var state = this.state
    var props = this.props

    if (state.dragging){
      event.stopPropagation()
    }

    var dragIndex = state.dragColumnIndex
    var dropIndex = state.dropIndex
    if (dropIndex != null) {
        this.props.onDropColumn(dragIndex, dropIndex);
    }
  },

  zetLijst(lijst, tagkey) {
    this.setState({tagkey:tagkey});
    this.setState({children: this._getChildrennw(lijst)});
  },

  _getChildrennw(lijst) {
    if (!lijst) 
    {
      return
    }
    this.state.lijst = lijst;
    let  listItem,
      itemComponent,
      isSelected,
      isDisabled,
      rightAvatar,
      leftAvatar;
    let _vchildren = [];
    //This array is used to keep track of all nested menu refs
    for (let i=0; i < lijst.length; i++) {
      listItem = lijst[i];
      var likey =UniqueId.generate();
      if(listItem.primaryText)
      {
        if (listItem.primaryText.length > 0)
        {
      itemComponent = (
        <Mattag iconClassName="fa fa-times"
          aantal={listItem.secondaryText}
          label={listItem.primaryText}
          tooltip={listItem.tooltip}
          key={likey}
          likey={likey}
          column={listItem}
          menu={this.props.menu}
          nodrag={this.props.nodrag}
          nodelete={this.props.nodelete}
          menuitems={this.props.menuitems}
          onMouseDown={this.down}
          onMouseUp={this.delete}
          onMenuChange={this.onMenuChange}
          LabClick={this.LabClick}
          onStart={this.onStart.bind(this, listItem)}
          onStop={this.onStop.bind(this, listItem)}
          onDrag={this.onDrag}>  
        </Mattag>)
            if (listItem.tooltip)
            {
              if (listItem.tooltip.length > 3)
              {
                var ttkey = 'tt'+likey;
                var popoverFocus = (
                    <Popover id={ttkey}>
                      <p style={{fontSize:'small'}}>{listItem.tooltip}<br/></p>
                    </Popover>);


                itemComponent = (
                    <OverlayTrigger style={{zIndex: 9999999999}} key={likey} trigger={['hover', 'focus']} placement="bottom" overlay={popoverFocus}>
                      <Mattag iconClassName="fa fa-times"
                        aantal={listItem.secondaryText}
                        label={listItem.primaryText}
                        tooltip={listItem.tooltip}
                        column={listItem}
                        menu={this.props.menu}
                        nodrag={this.props.nodrag}
                        nodelete={this.props.nodelete}
                        menuitems={this.props.menuitems}
                        onMouseDown={this.down}
                        onMouseUp={this.delete}
                        LabClick={this.LabClick}
                        onMenuChange={this.onMenuChange}
                        onStart={this.onStart.bind(this, listItem)}
                        onStop={this.onStop.bind(this, listItem)}
                        onDrag={this.onDrag}>  
                      </Mattag>
                    </OverlayTrigger>)

              }
            }

      _vchildren.push(itemComponent);
        }
      }
    }
    return _vchildren;
  },  

  delete(event,column){
     this.props.onDeleteColumn(column);
  },

  onMenuChange(key,column) {
    if (this.props.onMenuChange) this.props.onMenuChange(key,column);
  },
  buildIndexes(direction, index, dragIndex){
      var indexes = direction < 0 ?
          this.range(index, dragIndex):
          this.range(dragIndex, index)
      var result = {}
      indexes.forEach(function(value){
        result[value] = true
      })
      return result
    },    
    range(start, end){
        var res = []
        for ( ; start <= end; start++){
            res.push(start)
        }
        return res
    },

  render() {
    const {
      insetSubheader,
      style,
      subheader,
      subheaderStyle,
      zDepth,
      ...other,
    } = this.props;

    const styles = {
      root: {
        padding: 0,
        paddingBottom: 8,
        paddingTop: subheader ? 0 : 8,
      },

      subheader: {
        color: Typography.textLightBlack,
        fontSize: 14,
        fontWeight: Typography.fontWeightMedium,
        lineHeight: '48px',
        paddingLeft: insetSubheader ? 72 : 16,
      },
    };
    let extra = (this.props.extraheight) ? ( <div style={{width:'100%',height:this.props.extraheight}}></div>) : null;
    return (
      <div id='TagList'>
       {this.state.children}
       {extra}
      </div>
    );
  },
});

export default ListTag;
