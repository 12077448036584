const React = require('react');
const StylePropable = require('../mixins/style-propable');
const DefaultRawTheme = require('../styles/raw-themes/light-raw-theme');
const ThemeManager = require('../styles/theme-manager');
const PureRenderMixin = require('react-addons-pure-render-mixin');

const Toolbar = React.createClass({

  mixins: [StylePropable,PureRenderMixin],

  contextTypes: {
    muiTheme: React.PropTypes.object,
  },

  propTypes: {
    className: React.PropTypes.string,
    style: React.PropTypes.object,
  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
  },

  getChildContext () {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

  getInitialState () {
    return {
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  //to update theme inside state whenever a new theme is passed down
  //from the parent / owner using context
  componentWillReceiveProps (nextProps, nextContext) {
    let newMuiTheme = nextContext.muiTheme ? nextContext.muiTheme : this.state.muiTheme;
    this.setState({muiTheme: newMuiTheme});
  },

  getTheme() {
    return this.state.muiTheme.toolbar;
  },

  getStyles() {
    let hoogte = (window.innerWidth < 500 || window.innerHeight < 450 ) ? '56px' : this.getTheme().height;
    if (this.props.zbottom)
    {
      return this.mergeStyles({
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      backgroundColor: this.getTheme().backgroundColor,
      height: hoogte,
      width: '100%',
      padding: this.props.noGutter ? 0 : '0px ' + this.state.muiTheme.rawTheme.spacing.desktopGutter + 'px',
      position: 'fixed',
      zIndex:999999,
      tableLayout: 'auto',
      borderCollapse: 'collapse',
      overflow: 'show',
      bottom:'0px',
      }, this.props.style);

    }
    else
    {
      return this.mergeStyles({
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      backgroundColor: 'rgb(1, 115, 199)',
      height: hoogte,
      width: '100%',
      padding: this.props.noGutter ? 0 : '0px ' + this.state.muiTheme.rawTheme.spacing.desktopGutter + 'px',
      position: 'fixed',
      zIndex:99999,
      tableLayout: 'auto',
      borderCollapse: 'collapse',
      overflow: 'show',
      }, this.props.style);
    }
  },










   
   

  render() {
    return (
      <div className={this.props.className} style={this.prepareStyles(this.getStyles())}>
        {this.props.children}
      </div>
    );
  },

});

module.exports = Toolbar;
