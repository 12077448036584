const React = require('react');
const ReactDOM = require('react-dom');
module.exports = {
    GetDrop(hdata,sql){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function='+sql,
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
    },
    GetBankid(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetBankId',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      if (data.id)
                      {
                        resolve(data);
                      }
                      else
                      {
                        reject('');
                      }
                    },
                    error: function(data){
                        reject('');
                    }
                    });
            });
   
    },  

    InsAfschrift(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=InsAfschrift',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },  
    ZetAfsAccount(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=ZetAfsAccount',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },  

    ZetAfsGeenVer(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=ZetAfsGeenVer',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },  

    GetAfschrift(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetAfschrift',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },  








       
};


