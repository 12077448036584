'use strict'

function composeTwo(f, g) {
    return function () {
        return f(g.apply(this, arguments))
    }
}

/*
 * @param {Function...} an enumeration of functions, each consuming the result of the following function.
 *
 * For example: compose(c, b, a)(1,4) == c(b(a(1,4)))
 *
 * @return the result of the first function in the enumeration
 */
module.exports = function(){

    var args = arguments
    var len  = args.length
    var i    = 0
    var f    = args[0]

    while (++i < len) {
        f = composeTwo(f, args[i])
    }

    return f
}