const React = require('react');
const ReactDOM = require('react-dom');
const ContextPure = require('./mixins/context-pure');
const Transitions = require('./styles/transitions');
const Children = require('./utils/children');
const ColorManipulator = require('./utils/color-manipulator');
const ImmutabilityHelper = require('./utils/immutability-helper');
const Typography = require('./styles/typography');
const EnhancedButton = require('./enhanced-button.jsx');
const FlatButtonLabel = require('./buttons/flat-button-label.jsx');
const DefaultRawTheme = require('./styles/raw-themes/light-raw-theme');
const ThemeManager = require('./styles/theme-manager');
const ClickAwayable = require('./mixins/click-awayable');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const Menu = require('./menu/menu.jsx');
const Paper = require('./paper.jsx');
const StylePropable = require('./mixins/style-propable');
const WindowListenable = require('./mixins/window-listenable');
const PropTypes = require('./utils/prop-types');


function validateLabel (props, propName, componentName) {
  if (!props.children && !props.label) {
    return new Error('Required prop label or children was not ' +
      'specified in ' + componentName + '.');
  }
}

const FlatButton = React.createClass({

  mixins: [StylePropable,ContextPure,ClickAwayable],

  statics: {
    getRelevantContextKeys(muiTheme) {
      const buttonTheme = muiTheme.button;
      const flatButtonTheme = muiTheme.flatButton;

      return {
        buttonColor: flatButtonTheme.color,
        buttonHeight: buttonTheme.height,
        buttonMinWidth: buttonTheme.minWidth,
        disabledTextColor: flatButtonTheme.disabledTextColor,
        primaryTextColor: flatButtonTheme.primaryTextColor,
        secondaryTextColor: flatButtonTheme.secondaryTextColor,
        textColor: flatButtonTheme.textColor,
        textTransform: flatButtonTheme.textTransform ? flatButtonTheme.textTransform :
                      (buttonTheme.textTransform ? buttonTheme.textTransform : 'uppercase'),
      };
    },
    getChildrenClasses() {
      return [
        EnhancedButton,
        FlatButtonLabel,
      ];
    },
  },

  contextTypes: {
    muiTheme: React.PropTypes.object,
  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
  },

  getChildContext () {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

 windowListeners: {
    keyup: '_handleWindowKeyUp',
    resize: '_setZoom',
  },

  _setZoom() {
    let zoomStyle = (window.innerWidth < 650) ? {zoom:'1'} : {zoom:'1'};
    this.setState({zoomStyle:zoomStyle});
    this.render;

  },


  propTypes: {
    disabled: React.PropTypes.bool,
    hoverColor: React.PropTypes.string,
    label: validateLabel,
    labelPosition: React.PropTypes.oneOf(['before', 'after']),
    labelStyle: React.PropTypes.object,
    onKeyboardFocus: React.PropTypes.func,
    onMouseEnter: React.PropTypes.func,
    onMouseLeave: React.PropTypes.func,
    onTouchStart: React.PropTypes.func,
    primary: React.PropTypes.bool,
    rippleColor: React.PropTypes.string,
    secondary: React.PropTypes.bool,
    onMenuChange: React.PropTypes.func,
    selectedIndex: React.PropTypes.number
  },

  getDefaultProps() {
    return {
      labelStyle: {},
      edata:'',
      labelPosition: 'before',
      onKeyboardFocus: () => {},
      onMouseEnter: () => {},
      onMouseLeave: () => {},
      onTouchStart: () => {},
      valueMember:'payload',
      displayMember:'text',      
      iconStyle: {},
      tooltipPosition: 'bottom-center',
      menuItems:Immutable.List(),
    };
  },

  getInitialState() {
    let zDepth = this.props.disabled ? 0 : this.props.diepte;
    return {
      hovered: false,
      isKeyboardFocused: false,
      initialZDepth: zDepth,
      zDepth: zDepth,
      open: false,
      touch: false,
      selectedIndex: 0, 
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  //to update theme inside state whenever a new theme is passed down
  //from the parent / owner using context
  componentWillReceiveProps (nextProps, nextContext) {
    let newMuiTheme = nextContext.muiTheme ? nextContext.muiTheme : this.state.muiTheme;
    this.setState({muiTheme: newMuiTheme});
  },

  componentClickAway() {
    this.setState({open:false});
  },

  getStyles() {

    const spacing = this.state.muiTheme.rawTheme.spacing;
    let zIndex = 5;
    let themeVariables = this.state.muiTheme.floatingActionButton;
    let mleft =  this.props.left ? this.props.left : 0; 
    let zien = (this.props.zien) ? "" : "-1000px";
    let positie = (this.props.zien) ? "" : "absolute";
    let rechts = (this.props.rechts) ? this.props.rechts : "0px";    
    let vierkant = (this.props.vierkant) ?  '0%' : '50%';

    const {
      iconSize,
      textColor,
      disabledColor,
    } = this.constructor.getRelevantContextKeys(this.state.muiTheme);

    let styles = {
      root: {
        transition: Transitions.easeOut(),
        display: 'inline-block',
        position: 'relative',
        boxSizing: 'border-box',
        transition: Transitions.easeOut(),
        height: themeVariables.IconSize,
        width: themeVariables.IconSize,
        padding: 0,
        marginLeft:'5px',
        marginTop:'5px',
        fontSize: 0,        
        marginRight: rechts,  

      },
      rootWhenOpen: {
        opacity: 1
      },      
      menu: {
        zIndex: zIndex + 1,
        left:mleft,
      },      
      menuItem: {
        color:'black',
        paddingRight: spacing.iconSize +
                      spacing.desktopGutterLess +
                      spacing.desktopGutterMini,
        height: spacing.desktopDropDownMenuItemHeight,
        lineHeight: spacing.desktopDropDownMenuItemHeight + 'px',
        whiteSpace: 'nowrap',
        fontSize:'small',
      },      
      tooltip: {
        boxSizing: 'border-box',
      },      
      overlay: {
        transition: Transitions.easeOut(),
        top: 0,
      },

    };
    return styles;
  },


  render() {
    const {
      children,
      disabled,
      hoverColor,
      backgroundColor,
      label,
      labelStyle,
      labelPosition,
      onKeyboardFocus,
      onMouseLeave,
      onMouseEnter,
      onTouchStart,
      primary,
      rippleColor,
      secondary,
      kleur,
      menu,
      style,
      edata,
      ...other,
      } = this.props;

    const {
      buttonColor,
      buttonHeight,
      buttonMinWidth,
      disabledTextColor,
      primaryTextColor,
      secondaryTextColor,
      textColor,
      textTransform,
    } = this.constructor.getRelevantContextKeys(this.state.muiTheme);

    const defaultColor = disabled ? disabledTextColor :
      kleur ? kleur :
      primary ? primaryTextColor :
      secondary ? secondaryTextColor :
      textColor;

    const defaultHoverColor = ColorManipulator.fade(ColorManipulator.lighten(defaultColor, 0.4), 0.15);
    const defaultRippleColor = ColorManipulator.fade(defaultColor, 0.8);
    const buttonHoverColor = hoverColor || defaultHoverColor;
    const buttonRippleColor = rippleColor || defaultRippleColor;
    const hovered = (this.state.hovered || this.state.isKeyboardFocused) && !disabled;
    const buttonBackgroundColor = backgroundColor || buttonColor;

    const mergedRootStyles = ImmutabilityHelper.merge({
      color: defaultColor,
      transition: Transitions.easeOut(),
      fontSize: '100%',
      letterSpacing: 0,
      textTransform: textTransform,
      borderRadius: 2,
      userSelect: 'none',
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: hovered ? buttonHoverColor : buttonBackgroundColor,
      lineHeight: buttonHeight + 'px',
      minWidth: buttonMinWidth,
      padding: 0,
      margin: 0,
      //This is need so that ripples do not bleed past border radius.
      //See: http://stackoverflow.com/questions/17298739
      transform: 'translate3d(0, 0, 0)',
    }, style);
    let _this = this;
    let styles = this.getStyles();
    let selectedIndex = this.state.selectedIndex;

    const labelElement = label ? (
      <FlatButtonLabel label={label} style={labelStyle} />
    ) : undefined;
    // Place label before or after children.
    const childrenFragment = labelPosition === 'before' ? { labelElement, children } : { children, labelElement };
    const enhancedButtonChildren = Children.create(childrenFragment);
   let menuItems = {};
    if (menu)
    {
      menuItems = this.props.menuItems.map((item) => {
        item.text = item[_this.props.displayMember];
        item.payload = item[_this.props.valueMember];
        return item;
      });    
    }


    let menuElement = menu ? (         
      <Menu
        ref="menuItems"
        autoWidth={this.props.autoWidth}
        selectedIndex={selectedIndex}
        menuItems={menuItems}
        style={styles.menu}
        menuItemStyle={this.mergeStyles(styles.menuItem, this.props.menuItemStyle)}
        hideable={true}
        visible={this.state.open}
        onRequestClose={this._onMenuRequestClose}
        onItemTap={this._onMenuItemClick} />) : null;




    return (
      <EnhancedButton
        {...other}
        disabled={disabled}
        focusRippleColor={buttonRippleColor}
        onKeyboardFocus={this._handleKeyboardFocus}
        onMouseLeave={this._handleMouseLeave}
        onMouseEnter={this._handleMouseEnter}
        onTouchStart={this._handleTouchStart}
        style={mergedRootStyles}
        edata={this.props.edata}
        touchRippleColor={buttonRippleColor}>
        {enhancedButtonChildren}
      </EnhancedButton>

    );
  },

  _handleKeyboardFocus(e, isKeyboardFocused) {
    this.setState({isKeyboardFocused: isKeyboardFocused});
    this.props.onKeyboardFocus(e, isKeyboardFocused);
  },

  _handleMouseEnter(e) {
      this.setState({ zDepth: this.state.initialZDepth + 1 });
      this.setState({ open: !this.state.open });

    //Cancel hover styles for touch devices
    if (!this.state.touch) this.setState({hovered: true});
    this.props.onMouseEnter(e);
  },

  _handleMouseLeave(e) {
    this.setState({hovered: false});
    this.props.onMouseLeave(e);
  },

  _handleTouchStart(e) {
    this.setState({touch: true});
    this.props.onTouchStart(e); 
  },

});

module.exports = FlatButton;
