let React = require('react');
let Checkbox = require('../checkbox.jsx');
let StylePropable = require('../mixins/style-propable');
let TableRowColumn = require('./table-row-column.jsx');
let Tooltip = require('../tooltip.jsx');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../../immutab/dist/immutable');


let TableRow = React.createClass({

  mixins: [StylePropable,PureRenderMixin],

  contextTypes: {
    muiTheme: React.PropTypes.object
  },

  propTypes: {
    rowNumber: React.PropTypes.number.isRequired,
    onRowClick: React.PropTypes.func,
    onCellClick: React.PropTypes.func,
    onRowHover: React.PropTypes.func,
    onRowHoverExit: React.PropTypes.func,
    onCellHover: React.PropTypes.func,
    onCellHoverExit: React.PropTypes.func,
    Selected: React.PropTypes.bool,
    selectable: React.PropTypes.bool,
    striped: React.PropTypes.bool,
    hoverable: React.PropTypes.bool,
    displayBorder: React.PropTypes.bool,
    displayRowCheckbox: React.PropTypes.bool
  },

  getDefaultProps() {
    return {
      selected: false,
      selectable: true,
      striped: false,
      hoverable: false,
      displayBorder: true,
      displayRowCheckbox: true
    };
  },

  getInitialState() {
    return {
      hovered: false,
      pcheckbox: false,
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),

    };
  },

  getTheme() {
    return this.state.muiTheme.tableRow;
  },  



  getStyles() {
    let theme = this.getTheme();
    let cellBgColor = 'inherit';
    if (this.state.hovered) {
      cellBgColor = theme.hoverColor;
    }
    else if (this.props.Selected) {
      cellBgColor = theme.selectedColor;
    }
    else if (this.props.striped) {
      cellBgColor = theme.stripeColor;
    }

    let styles = {
      root: {
        borderBottom: '1px solid ' + this.getTheme().borderColor,
        cursor: 'pointer'
      },
      cell: {
        backgroundColor: cellBgColor,
        color: this.getTheme().textColor,
        width: this.props.width
      },
      checkbox: {
        width:'1px'

      }
    };

    if (!this.props.displayBorder) {
      styles.root.borderBottom = '';
    }

    return styles;
  },

  render() {
    let className = 'mui-table-row';
    let columns = this.props.columns.slice();
    if (this.props.displayRowCheckbox) {
        columns.splice(0, 0, this._getRowCheckbox());
    }

    return (
      <tr className={className} onClick={this._onRowClick} style={this.getStyles().root}>
        {this._getColumns(columns)}
      </tr>
    );
  },

  _getColumns(columns) {
    let rowColumns = Immutable.List();
    let styles = this.getStyles();
    for (let index = 0; index < columns.size; index++) {
      let key = this.props.rowNumber + '-' + index;
      let {
        content,
        style,
        width
      } = columns.get(index);

      let style2 = '';
      if (width) {
        style2 = {width:width};
      }


      if (content === undefined) content = columns.get(index);
          let columnComponent = (
          <TableRowColumn
            key={key}
            displayRowCheckbox={this.props.displayRowCheckbox}
            columnNumber={index}
            style={this.mergeStyles(styles.cell, style, style2)}
            hoverable={this.props.hoverable}
            onClick={this._onCellClick}
            onHover={this._onCellHover}
            onHoverExit={this._onCellHoverExit}>
            {content}
          </TableRowColumn>);
        rowColumns = rowColumns.push(columnComponent);
    }

    return rowColumns;
  },

  _getRowCheckbox() {
    let styles = this.getStyles();
    let key = this.props.rowNumber + '-cb';
    let checkbox =
      <Checkbox
        ref='rowSelectCB'
        name={key}
        value='selected'
        disabled={!this.props.selectable}
        defaultChecked={this.props.Selected}
        style={this.mergeStyles(styles.checkbox)}
        onCheck={this._onCheck} />;

    return {
      content: checkbox,
      style: {
        width:'25px',
        paddingLeft: 24
      }
    };
  },

  _onRowClick(e) {
    var d = new Date();
    var n = d.getTime();
 //   if (this.props.onRowClick) this.props.onRowClick(e, this.props.rowNumber, n);
  },

  _onRowHover(e) {
    if (this.props.onRowHover) this.props.onRowHover(e, this.props.rowNumber);
  },

  _onRowHoverExit(e) {
    if (this.props.onRowHoverExit) this.props.onRowHoverExit(e, this.props.rowNumber);
  },

  _onCellClick(e, columnIndex) {
    e.ctrlKey = true;

    if (this.props.selectable && this.props.onCellClick) this.props.onCellClick(e, this.props.rowNumber, columnIndex);
    if (this.refs.rowSelectCB !== undefined) this.refs.rowSelectCB.setChecked(!this.refs.rowSelectCB.isChecked());
    if (this.props.displayRowCheckbox && columnIndex < 1) 
    {
      return;
    }
    else
    {
      var d = new Date();
      var n = d.getTime();
      if (this.props.onRowClick) this.props.onRowClick(e, this.props.rowNumber, n);      
    }


  },

  _onCellHover(e, columnIndex) {
    if (this.props.hoverable) {
      this.setState({hovered: true});
      if (this.props.onCellHover) this.props.onCellHover(e, this.props.rowNumber, columnIndex);
      this._onRowHover(e);
    }
  },

  _onCellHoverExit(e, columnIndex) {
    if (this.props.hoverable) {
      this.setState({hovered: false});
      if (this.props.onCellHoverExit) this.props.onCellHoverExit(e, this.props.rowNumber, columnIndex);
      this._onRowHoverExit(e);
    }
  },

  _onCheck(e) {
    e.ctrlKey = true;
//    if (this.props.selectable && this.props.onCellClick) this.props.onCellClick(e, this.props.rowNumber, columnIndex);
//    if (this.refs.rowSelectCB !== undefined) this.refs.rowSelectCB.setChecked(!this.refs.rowSelectCB.isChecked());

//    this._onCellClick(e, 0);
  }

});

module.exports = TableRow;
