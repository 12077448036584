module.exports={
  "main": {
    "pt": {
      "identity": {
        "version": {
          "_number": "$Revision: 13259 $",
          "_cldrVersion": "31.0.1"
        },
        "language": "pt"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;-HH:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "Horário {0}",
          "regionFormat-type-daylight": "{0} (+1)",
          "regionFormat-type-standard": "{0} (+0)",
          "fallbackFormat": "{1} ({0})",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "Adak"
              },
              "Anchorage": {
                "exemplarCity": "Anchorage"
              },
              "Anguilla": {
                "exemplarCity": "Anguilla"
              },
              "Antigua": {
                "exemplarCity": "Antígua"
              },
              "Araguaina": {
                "exemplarCity": "Araguaína"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "Rio Gallegos"
                },
                "San_Juan": {
                  "exemplarCity": "San Juan"
                },
                "Ushuaia": {
                  "exemplarCity": "Ushuaia"
                },
                "La_Rioja": {
                  "exemplarCity": "La Rioja"
                },
                "San_Luis": {
                  "exemplarCity": "San Luis"
                },
                "Salta": {
                  "exemplarCity": "Salta"
                },
                "Tucuman": {
                  "exemplarCity": "Tucumã"
                }
              },
              "Aruba": {
                "exemplarCity": "Aruba"
              },
              "Asuncion": {
                "exemplarCity": "Assunção"
              },
              "Bahia": {
                "exemplarCity": "Bahia"
              },
              "Bahia_Banderas": {
                "exemplarCity": "Bahia Banderas"
              },
              "Barbados": {
                "exemplarCity": "Barbados"
              },
              "Belem": {
                "exemplarCity": "Belém"
              },
              "Belize": {
                "exemplarCity": "Belize"
              },
              "Blanc-Sablon": {
                "exemplarCity": "Blanc-Sablon"
              },
              "Boa_Vista": {
                "exemplarCity": "Boa Vista"
              },
              "Bogota": {
                "exemplarCity": "Bogotá"
              },
              "Boise": {
                "exemplarCity": "Boise"
              },
              "Buenos_Aires": {
                "exemplarCity": "Buenos Aires"
              },
              "Cambridge_Bay": {
                "exemplarCity": "Cambridge Bay"
              },
              "Campo_Grande": {
                "exemplarCity": "Campo Grande"
              },
              "Cancun": {
                "exemplarCity": "Cancún"
              },
              "Caracas": {
                "exemplarCity": "Caracas"
              },
              "Catamarca": {
                "exemplarCity": "Catamarca"
              },
              "Cayenne": {
                "exemplarCity": "Caiena"
              },
              "Cayman": {
                "exemplarCity": "Caimã"
              },
              "Chicago": {
                "exemplarCity": "Chicago"
              },
              "Chihuahua": {
                "exemplarCity": "Chihuahua"
              },
              "Coral_Harbour": {
                "exemplarCity": "Atikokan"
              },
              "Cordoba": {
                "exemplarCity": "Córdoba"
              },
              "Costa_Rica": {
                "exemplarCity": "Costa Rica"
              },
              "Creston": {
                "exemplarCity": "Creston"
              },
              "Cuiaba": {
                "exemplarCity": "Cuiabá"
              },
              "Curacao": {
                "exemplarCity": "Curaçao"
              },
              "Danmarkshavn": {
                "exemplarCity": "Danmarkshavn"
              },
              "Dawson": {
                "exemplarCity": "Dawson"
              },
              "Dawson_Creek": {
                "exemplarCity": "Dawson Creek"
              },
              "Denver": {
                "exemplarCity": "Denver"
              },
              "Detroit": {
                "exemplarCity": "Detroit"
              },
              "Dominica": {
                "exemplarCity": "Dominica"
              },
              "Edmonton": {
                "exemplarCity": "Edmonton"
              },
              "Eirunepe": {
                "exemplarCity": "Eirunepé"
              },
              "El_Salvador": {
                "exemplarCity": "El Salvador"
              },
              "Fort_Nelson": {
                "exemplarCity": "Fort Nelson"
              },
              "Fortaleza": {
                "exemplarCity": "Fortaleza"
              },
              "Glace_Bay": {
                "exemplarCity": "Glace Bay"
              },
              "Godthab": {
                "exemplarCity": "Nuuk"
              },
              "Goose_Bay": {
                "exemplarCity": "Goose Bay"
              },
              "Grand_Turk": {
                "exemplarCity": "Grand Turk"
              },
              "Grenada": {
                "exemplarCity": "Granada"
              },
              "Guadeloupe": {
                "exemplarCity": "Guadalupe"
              },
              "Guatemala": {
                "exemplarCity": "Guatemala"
              },
              "Guayaquil": {
                "exemplarCity": "Guaiaquil"
              },
              "Guyana": {
                "exemplarCity": "Guiana"
              },
              "Halifax": {
                "exemplarCity": "Halifax"
              },
              "Havana": {
                "exemplarCity": "Havana"
              },
              "Hermosillo": {
                "exemplarCity": "Hermosillo"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "Vincennes, Indiana"
                },
                "Petersburg": {
                  "exemplarCity": "Petersburg, Indiana"
                },
                "Tell_City": {
                  "exemplarCity": "Tell City, Indiana"
                },
                "Knox": {
                  "exemplarCity": "Knox, Indiana"
                },
                "Winamac": {
                  "exemplarCity": "Winamac, Indiana"
                },
                "Marengo": {
                  "exemplarCity": "Marengo, Indiana"
                },
                "Vevay": {
                  "exemplarCity": "Vevay, Indiana"
                }
              },
              "Indianapolis": {
                "exemplarCity": "Indianápolis"
              },
              "Inuvik": {
                "exemplarCity": "Inuvik"
              },
              "Iqaluit": {
                "exemplarCity": "Iqaluit"
              },
              "Jamaica": {
                "exemplarCity": "Jamaica"
              },
              "Jujuy": {
                "exemplarCity": "Jujuy"
              },
              "Juneau": {
                "exemplarCity": "Juneau"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "Monticello, Kentucky"
                }
              },
              "Kralendijk": {
                "exemplarCity": "Kralendijk"
              },
              "La_Paz": {
                "exemplarCity": "La Paz"
              },
              "Lima": {
                "exemplarCity": "Lima"
              },
              "Los_Angeles": {
                "exemplarCity": "Los Angeles"
              },
              "Louisville": {
                "exemplarCity": "Louisville"
              },
              "Lower_Princes": {
                "exemplarCity": "Lower Prince’s Quarter"
              },
              "Maceio": {
                "exemplarCity": "Maceió"
              },
              "Managua": {
                "exemplarCity": "Manágua"
              },
              "Manaus": {
                "exemplarCity": "Manaus"
              },
              "Marigot": {
                "exemplarCity": "Marigot"
              },
              "Martinique": {
                "exemplarCity": "Martinica"
              },
              "Matamoros": {
                "exemplarCity": "Matamoros"
              },
              "Mazatlan": {
                "exemplarCity": "Mazatlan"
              },
              "Mendoza": {
                "exemplarCity": "Mendoza"
              },
              "Menominee": {
                "exemplarCity": "Menominee"
              },
              "Merida": {
                "exemplarCity": "Mérida"
              },
              "Metlakatla": {
                "exemplarCity": "Metlakatla"
              },
              "Mexico_City": {
                "exemplarCity": "Cidade do México"
              },
              "Miquelon": {
                "exemplarCity": "Miquelon"
              },
              "Moncton": {
                "exemplarCity": "Moncton"
              },
              "Monterrey": {
                "exemplarCity": "Monterrei"
              },
              "Montevideo": {
                "exemplarCity": "Montevidéu"
              },
              "Montserrat": {
                "exemplarCity": "Montserrat"
              },
              "Nassau": {
                "exemplarCity": "Nassau"
              },
              "New_York": {
                "exemplarCity": "Nova York"
              },
              "Nipigon": {
                "exemplarCity": "Nipigon"
              },
              "Nome": {
                "exemplarCity": "Nome"
              },
              "Noronha": {
                "exemplarCity": "Fernando de Noronha"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "Beulah, Dakota do Norte"
                },
                "New_Salem": {
                  "exemplarCity": "New Salen, Dakota do Norte"
                },
                "Center": {
                  "exemplarCity": "Central, Dakota do Norte"
                }
              },
              "Ojinaga": {
                "exemplarCity": "Ojinaga"
              },
              "Panama": {
                "exemplarCity": "Panamá"
              },
              "Pangnirtung": {
                "exemplarCity": "Pangnirtung"
              },
              "Paramaribo": {
                "exemplarCity": "Paramaribo"
              },
              "Phoenix": {
                "exemplarCity": "Phoenix"
              },
              "Port-au-Prince": {
                "exemplarCity": "Porto Príncipe"
              },
              "Port_of_Spain": {
                "exemplarCity": "Porto Espanha"
              },
              "Porto_Velho": {
                "exemplarCity": "Porto Velho"
              },
              "Puerto_Rico": {
                "exemplarCity": "Porto Rico"
              },
              "Rainy_River": {
                "exemplarCity": "Rainy River"
              },
              "Rankin_Inlet": {
                "exemplarCity": "Rankin Inlet"
              },
              "Recife": {
                "exemplarCity": "Recife"
              },
              "Regina": {
                "exemplarCity": "Regina"
              },
              "Resolute": {
                "exemplarCity": "Resolute"
              },
              "Rio_Branco": {
                "exemplarCity": "Rio Branco"
              },
              "Santa_Isabel": {
                "exemplarCity": "Santa Isabel"
              },
              "Santarem": {
                "exemplarCity": "Santarém"
              },
              "Santiago": {
                "exemplarCity": "Santiago"
              },
              "Santo_Domingo": {
                "exemplarCity": "Santo Domingo"
              },
              "Sao_Paulo": {
                "exemplarCity": "São Paulo"
              },
              "Scoresbysund": {
                "exemplarCity": "Ittoqqortoormiit"
              },
              "Sitka": {
                "exemplarCity": "Sitka"
              },
              "St_Barthelemy": {
                "exemplarCity": "São Bartolomeu"
              },
              "St_Johns": {
                "exemplarCity": "St. John’s"
              },
              "St_Kitts": {
                "exemplarCity": "São Cristóvão"
              },
              "St_Lucia": {
                "exemplarCity": "Santa Lúcia"
              },
              "St_Thomas": {
                "exemplarCity": "St. Thomas"
              },
              "St_Vincent": {
                "exemplarCity": "São Vicente"
              },
              "Swift_Current": {
                "exemplarCity": "Swift Current"
              },
              "Tegucigalpa": {
                "exemplarCity": "Tegucigalpa"
              },
              "Thule": {
                "exemplarCity": "Thule"
              },
              "Thunder_Bay": {
                "exemplarCity": "Thunder Bay"
              },
              "Tijuana": {
                "exemplarCity": "Tijuana"
              },
              "Toronto": {
                "exemplarCity": "Toronto"
              },
              "Tortola": {
                "exemplarCity": "Tortola"
              },
              "Vancouver": {
                "exemplarCity": "Vancouver"
              },
              "Whitehorse": {
                "exemplarCity": "Whitehorse"
              },
              "Winnipeg": {
                "exemplarCity": "Winnipeg"
              },
              "Yakutat": {
                "exemplarCity": "Yakutat"
              },
              "Yellowknife": {
                "exemplarCity": "Yellowknife"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "Açores"
              },
              "Bermuda": {
                "exemplarCity": "Bermudas"
              },
              "Canary": {
                "exemplarCity": "Canárias"
              },
              "Cape_Verde": {
                "exemplarCity": "Cabo Verde"
              },
              "Faeroe": {
                "exemplarCity": "Ilhas Faroe"
              },
              "Madeira": {
                "exemplarCity": "Madeira"
              },
              "Reykjavik": {
                "exemplarCity": "Reykjavík"
              },
              "South_Georgia": {
                "exemplarCity": "Geórgia do Sul"
              },
              "St_Helena": {
                "exemplarCity": "Santa Helena"
              },
              "Stanley": {
                "exemplarCity": "Stanley"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "Amsterdã"
              },
              "Andorra": {
                "exemplarCity": "Andorra"
              },
              "Astrakhan": {
                "exemplarCity": "Astracã"
              },
              "Athens": {
                "exemplarCity": "Atenas"
              },
              "Belgrade": {
                "exemplarCity": "Belgrado"
              },
              "Berlin": {
                "exemplarCity": "Berlim"
              },
              "Bratislava": {
                "exemplarCity": "Bratislava"
              },
              "Brussels": {
                "exemplarCity": "Bruxelas"
              },
              "Bucharest": {
                "exemplarCity": "Bucareste"
              },
              "Budapest": {
                "exemplarCity": "Budapeste"
              },
              "Busingen": {
                "exemplarCity": "Büsingen"
              },
              "Chisinau": {
                "exemplarCity": "Chisinau"
              },
              "Copenhagen": {
                "exemplarCity": "Copenhague"
              },
              "Dublin": {
                "long": {
                  "daylight": "Horário Padrão da Irlanda"
                },
                "exemplarCity": "Dublin"
              },
              "Gibraltar": {
                "exemplarCity": "Gibraltar"
              },
              "Guernsey": {
                "exemplarCity": "Guernsey"
              },
              "Helsinki": {
                "exemplarCity": "Helsinque"
              },
              "Isle_of_Man": {
                "exemplarCity": "Ilha de Man"
              },
              "Istanbul": {
                "exemplarCity": "Istambul"
              },
              "Jersey": {
                "exemplarCity": "Jersey"
              },
              "Kaliningrad": {
                "exemplarCity": "Kaliningrado"
              },
              "Kiev": {
                "exemplarCity": "Kiev"
              },
              "Kirov": {
                "exemplarCity": "Kirov"
              },
              "Lisbon": {
                "exemplarCity": "Lisboa"
              },
              "Ljubljana": {
                "exemplarCity": "Liubliana"
              },
              "London": {
                "long": {
                  "daylight": "Horário de Verão Britânico"
                },
                "exemplarCity": "Londres"
              },
              "Luxembourg": {
                "exemplarCity": "Luxemburgo"
              },
              "Madrid": {
                "exemplarCity": "Madri"
              },
              "Malta": {
                "exemplarCity": "Malta"
              },
              "Mariehamn": {
                "exemplarCity": "Mariehamn"
              },
              "Minsk": {
                "exemplarCity": "Minsk"
              },
              "Monaco": {
                "exemplarCity": "Mônaco"
              },
              "Moscow": {
                "exemplarCity": "Moscou"
              },
              "Oslo": {
                "exemplarCity": "Oslo"
              },
              "Paris": {
                "exemplarCity": "Paris"
              },
              "Podgorica": {
                "exemplarCity": "Podgorica"
              },
              "Prague": {
                "exemplarCity": "Praga"
              },
              "Riga": {
                "exemplarCity": "Riga"
              },
              "Rome": {
                "exemplarCity": "Roma"
              },
              "Samara": {
                "exemplarCity": "Samara"
              },
              "San_Marino": {
                "exemplarCity": "San Marino"
              },
              "Sarajevo": {
                "exemplarCity": "Sarajevo"
              },
              "Simferopol": {
                "exemplarCity": "Simferopol"
              },
              "Skopje": {
                "exemplarCity": "Skopje"
              },
              "Sofia": {
                "exemplarCity": "Sófia"
              },
              "Stockholm": {
                "exemplarCity": "Estocolmo"
              },
              "Tallinn": {
                "exemplarCity": "Tallinn"
              },
              "Tirane": {
                "exemplarCity": "Tirana"
              },
              "Ulyanovsk": {
                "exemplarCity": "Ulianovsk"
              },
              "Uzhgorod": {
                "exemplarCity": "Uzhgorod"
              },
              "Vaduz": {
                "exemplarCity": "Vaduz"
              },
              "Vatican": {
                "exemplarCity": "Vaticano"
              },
              "Vienna": {
                "exemplarCity": "Viena"
              },
              "Vilnius": {
                "exemplarCity": "Vilnius"
              },
              "Volgograd": {
                "exemplarCity": "Volgogrado"
              },
              "Warsaw": {
                "exemplarCity": "Varsóvia"
              },
              "Zagreb": {
                "exemplarCity": "Zagreb"
              },
              "Zaporozhye": {
                "exemplarCity": "Zaporizhia"
              },
              "Zurich": {
                "exemplarCity": "Zurique"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "Abidjan"
              },
              "Accra": {
                "exemplarCity": "Acra"
              },
              "Addis_Ababa": {
                "exemplarCity": "Adis-Abeba"
              },
              "Algiers": {
                "exemplarCity": "Argel"
              },
              "Asmera": {
                "exemplarCity": "Asmara"
              },
              "Bamako": {
                "exemplarCity": "Bamaco"
              },
              "Bangui": {
                "exemplarCity": "Bangui"
              },
              "Banjul": {
                "exemplarCity": "Banjul"
              },
              "Bissau": {
                "exemplarCity": "Bissau"
              },
              "Blantyre": {
                "exemplarCity": "Blantyre"
              },
              "Brazzaville": {
                "exemplarCity": "Brazzaville"
              },
              "Bujumbura": {
                "exemplarCity": "Bujumbura"
              },
              "Cairo": {
                "exemplarCity": "Cairo"
              },
              "Casablanca": {
                "exemplarCity": "Casablanca"
              },
              "Ceuta": {
                "exemplarCity": "Ceuta"
              },
              "Conakry": {
                "exemplarCity": "Conacri"
              },
              "Dakar": {
                "exemplarCity": "Dacar"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "Dar es Salaam"
              },
              "Djibouti": {
                "exemplarCity": "Djibuti"
              },
              "Douala": {
                "exemplarCity": "Douala"
              },
              "El_Aaiun": {
                "exemplarCity": "El Aaiún"
              },
              "Freetown": {
                "exemplarCity": "Freetown"
              },
              "Gaborone": {
                "exemplarCity": "Gaborone"
              },
              "Harare": {
                "exemplarCity": "Harare"
              },
              "Johannesburg": {
                "exemplarCity": "Johannesburgo"
              },
              "Juba": {
                "exemplarCity": "Juba"
              },
              "Kampala": {
                "exemplarCity": "Campala"
              },
              "Khartoum": {
                "exemplarCity": "Cartum"
              },
              "Kigali": {
                "exemplarCity": "Kigali"
              },
              "Kinshasa": {
                "exemplarCity": "Kinshasa"
              },
              "Lagos": {
                "exemplarCity": "Lagos"
              },
              "Libreville": {
                "exemplarCity": "Libreville"
              },
              "Lome": {
                "exemplarCity": "Lomé"
              },
              "Luanda": {
                "exemplarCity": "Luanda"
              },
              "Lubumbashi": {
                "exemplarCity": "Lubumbashi"
              },
              "Lusaka": {
                "exemplarCity": "Lusaca"
              },
              "Malabo": {
                "exemplarCity": "Malabo"
              },
              "Maputo": {
                "exemplarCity": "Maputo"
              },
              "Maseru": {
                "exemplarCity": "Maseru"
              },
              "Mbabane": {
                "exemplarCity": "Mbabane"
              },
              "Mogadishu": {
                "exemplarCity": "Mogadíscio"
              },
              "Monrovia": {
                "exemplarCity": "Monróvia"
              },
              "Nairobi": {
                "exemplarCity": "Nairóbi"
              },
              "Ndjamena": {
                "exemplarCity": "Ndjamena"
              },
              "Niamey": {
                "exemplarCity": "Niamei"
              },
              "Nouakchott": {
                "exemplarCity": "Nouakchott"
              },
              "Ouagadougou": {
                "exemplarCity": "Ouagadougou"
              },
              "Porto-Novo": {
                "exemplarCity": "Porto Novo"
              },
              "Sao_Tome": {
                "exemplarCity": "São Tomé"
              },
              "Tripoli": {
                "exemplarCity": "Trípoli"
              },
              "Tunis": {
                "exemplarCity": "Túnis"
              },
              "Windhoek": {
                "exemplarCity": "Windhoek"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "Adem"
              },
              "Almaty": {
                "exemplarCity": "Almaty"
              },
              "Amman": {
                "exemplarCity": "Amã"
              },
              "Anadyr": {
                "exemplarCity": "Anadyr"
              },
              "Aqtau": {
                "exemplarCity": "Aqtau"
              },
              "Aqtobe": {
                "exemplarCity": "Aqtöbe"
              },
              "Ashgabat": {
                "exemplarCity": "Asgabate"
              },
              "Baghdad": {
                "exemplarCity": "Bagdá"
              },
              "Bahrain": {
                "exemplarCity": "Bahrein"
              },
              "Baku": {
                "exemplarCity": "Baku"
              },
              "Bangkok": {
                "exemplarCity": "Bangkok"
              },
              "Barnaul": {
                "exemplarCity": "Barnaul"
              },
              "Beirut": {
                "exemplarCity": "Beirute"
              },
              "Bishkek": {
                "exemplarCity": "Bishkek"
              },
              "Brunei": {
                "exemplarCity": "Brunei"
              },
              "Calcutta": {
                "exemplarCity": "Kolkata"
              },
              "Chita": {
                "exemplarCity": "Chita"
              },
              "Choibalsan": {
                "exemplarCity": "Choibalsan"
              },
              "Colombo": {
                "exemplarCity": "Colombo"
              },
              "Damascus": {
                "exemplarCity": "Damasco"
              },
              "Dhaka": {
                "exemplarCity": "Dacca"
              },
              "Dili": {
                "exemplarCity": "Dili"
              },
              "Dubai": {
                "exemplarCity": "Dubai"
              },
              "Dushanbe": {
                "exemplarCity": "Duchambe"
              },
              "Gaza": {
                "exemplarCity": "Gaza"
              },
              "Hebron": {
                "exemplarCity": "Hebrom"
              },
              "Hong_Kong": {
                "exemplarCity": "Hong Kong"
              },
              "Hovd": {
                "exemplarCity": "Hovd"
              },
              "Irkutsk": {
                "exemplarCity": "Irkutsk"
              },
              "Jakarta": {
                "exemplarCity": "Jacarta"
              },
              "Jayapura": {
                "exemplarCity": "Jayapura"
              },
              "Jerusalem": {
                "exemplarCity": "Jerusalém"
              },
              "Kabul": {
                "exemplarCity": "Kabul"
              },
              "Kamchatka": {
                "exemplarCity": "Kamchatka"
              },
              "Karachi": {
                "exemplarCity": "Carachi"
              },
              "Katmandu": {
                "exemplarCity": "Catmandu"
              },
              "Khandyga": {
                "exemplarCity": "Khandyga"
              },
              "Krasnoyarsk": {
                "exemplarCity": "Krasnoyarsk"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "Kuala Lampur"
              },
              "Kuching": {
                "exemplarCity": "Kuching"
              },
              "Kuwait": {
                "exemplarCity": "Kuwait"
              },
              "Macau": {
                "exemplarCity": "Macau"
              },
              "Magadan": {
                "exemplarCity": "Magadan"
              },
              "Makassar": {
                "exemplarCity": "Macáçar"
              },
              "Manila": {
                "exemplarCity": "Manila"
              },
              "Muscat": {
                "exemplarCity": "Mascate"
              },
              "Nicosia": {
                "exemplarCity": "Nicósia"
              },
              "Novokuznetsk": {
                "exemplarCity": "Novokuznetsk"
              },
              "Novosibirsk": {
                "exemplarCity": "Novosibirsk"
              },
              "Omsk": {
                "exemplarCity": "Omsk"
              },
              "Oral": {
                "exemplarCity": "Oral"
              },
              "Phnom_Penh": {
                "exemplarCity": "Phnom Penh"
              },
              "Pontianak": {
                "exemplarCity": "Pontianak"
              },
              "Pyongyang": {
                "exemplarCity": "Pyongyang"
              },
              "Qatar": {
                "exemplarCity": "Qatar"
              },
              "Qyzylorda": {
                "exemplarCity": "Qyzylorda"
              },
              "Rangoon": {
                "exemplarCity": "Yangon"
              },
              "Riyadh": {
                "exemplarCity": "Riade"
              },
              "Saigon": {
                "exemplarCity": "Ho Chi Minh"
              },
              "Sakhalin": {
                "exemplarCity": "Sacalina"
              },
              "Samarkand": {
                "exemplarCity": "Samarcanda"
              },
              "Seoul": {
                "exemplarCity": "Seul"
              },
              "Shanghai": {
                "exemplarCity": "Xangai"
              },
              "Singapore": {
                "exemplarCity": "Cingapura"
              },
              "Srednekolymsk": {
                "exemplarCity": "Srednekolymsk"
              },
              "Taipei": {
                "exemplarCity": "Taipei"
              },
              "Tashkent": {
                "exemplarCity": "Tashkent"
              },
              "Tbilisi": {
                "exemplarCity": "Tbilisi"
              },
              "Tehran": {
                "exemplarCity": "Teerã"
              },
              "Thimphu": {
                "exemplarCity": "Timphu"
              },
              "Tokyo": {
                "exemplarCity": "Tóquio"
              },
              "Tomsk": {
                "exemplarCity": "Tomsk"
              },
              "Ulaanbaatar": {
                "exemplarCity": "Ulan Bator"
              },
              "Urumqi": {
                "exemplarCity": "Urumqi"
              },
              "Ust-Nera": {
                "exemplarCity": "Ust-Nera"
              },
              "Vientiane": {
                "exemplarCity": "Vientiane"
              },
              "Vladivostok": {
                "exemplarCity": "Vladivostok"
              },
              "Yakutsk": {
                "exemplarCity": "Yakutsk"
              },
              "Yekaterinburg": {
                "exemplarCity": "Ecaterimburgo"
              },
              "Yerevan": {
                "exemplarCity": "Yerevan"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "Antananarivo"
              },
              "Chagos": {
                "exemplarCity": "Chagos"
              },
              "Christmas": {
                "exemplarCity": "Christmas"
              },
              "Cocos": {
                "exemplarCity": "Cocos"
              },
              "Comoro": {
                "exemplarCity": "Comores"
              },
              "Kerguelen": {
                "exemplarCity": "Kerguelen"
              },
              "Mahe": {
                "exemplarCity": "Mahé"
              },
              "Maldives": {
                "exemplarCity": "Maldivas"
              },
              "Mauritius": {
                "exemplarCity": "Maurício"
              },
              "Mayotte": {
                "exemplarCity": "Mayotte"
              },
              "Reunion": {
                "exemplarCity": "Reunião"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "Adelaide"
              },
              "Brisbane": {
                "exemplarCity": "Brisbane"
              },
              "Broken_Hill": {
                "exemplarCity": "Broken Hill"
              },
              "Currie": {
                "exemplarCity": "Currie"
              },
              "Darwin": {
                "exemplarCity": "Darwin"
              },
              "Eucla": {
                "exemplarCity": "Eucla"
              },
              "Hobart": {
                "exemplarCity": "Hobart"
              },
              "Lindeman": {
                "exemplarCity": "Lindeman"
              },
              "Lord_Howe": {
                "exemplarCity": "Lord Howe"
              },
              "Melbourne": {
                "exemplarCity": "Melbourne"
              },
              "Perth": {
                "exemplarCity": "Perth"
              },
              "Sydney": {
                "exemplarCity": "Sydney"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "Ápia"
              },
              "Auckland": {
                "exemplarCity": "Auckland"
              },
              "Bougainville": {
                "exemplarCity": "Bougainville"
              },
              "Chatham": {
                "exemplarCity": "Chatham"
              },
              "Easter": {
                "exemplarCity": "Ilha de Páscoa"
              },
              "Efate": {
                "exemplarCity": "Éfaté"
              },
              "Enderbury": {
                "exemplarCity": "Enderbury"
              },
              "Fakaofo": {
                "exemplarCity": "Fakaofo"
              },
              "Fiji": {
                "exemplarCity": "Fiji"
              },
              "Funafuti": {
                "exemplarCity": "Funafuti"
              },
              "Galapagos": {
                "exemplarCity": "Galápagos"
              },
              "Gambier": {
                "exemplarCity": "Gambier"
              },
              "Guadalcanal": {
                "exemplarCity": "Guadalcanal"
              },
              "Guam": {
                "exemplarCity": "Guam"
              },
              "Honolulu": {
                "exemplarCity": "Honolulu"
              },
              "Johnston": {
                "exemplarCity": "Johnston"
              },
              "Kiritimati": {
                "exemplarCity": "Kiritimati"
              },
              "Kosrae": {
                "exemplarCity": "Kosrae"
              },
              "Kwajalein": {
                "exemplarCity": "Kwajalein"
              },
              "Majuro": {
                "exemplarCity": "Majuro"
              },
              "Marquesas": {
                "exemplarCity": "Marquesas"
              },
              "Midway": {
                "exemplarCity": "Midway"
              },
              "Nauru": {
                "exemplarCity": "Nauru"
              },
              "Niue": {
                "exemplarCity": "Niue"
              },
              "Norfolk": {
                "exemplarCity": "Norfolk"
              },
              "Noumea": {
                "exemplarCity": "Nouméa"
              },
              "Pago_Pago": {
                "exemplarCity": "Pago Pago"
              },
              "Palau": {
                "exemplarCity": "Palau"
              },
              "Pitcairn": {
                "exemplarCity": "Pitcairn"
              },
              "Ponape": {
                "exemplarCity": "Pohnpei"
              },
              "Port_Moresby": {
                "exemplarCity": "Port Moresby"
              },
              "Rarotonga": {
                "exemplarCity": "Rarotonga"
              },
              "Saipan": {
                "exemplarCity": "Saipan"
              },
              "Tahiti": {
                "exemplarCity": "Taiti"
              },
              "Tarawa": {
                "exemplarCity": "Taraua"
              },
              "Tongatapu": {
                "exemplarCity": "Tongatapu"
              },
              "Truk": {
                "exemplarCity": "Chuuk"
              },
              "Wake": {
                "exemplarCity": "Wake"
              },
              "Wallis": {
                "exemplarCity": "Wallis"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "Longyearbyen"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "Casey"
              },
              "Davis": {
                "exemplarCity": "Davis"
              },
              "DumontDUrville": {
                "exemplarCity": "Dumont d’Urville"
              },
              "Macquarie": {
                "exemplarCity": "Macquarie"
              },
              "Mawson": {
                "exemplarCity": "Mawson"
              },
              "McMurdo": {
                "exemplarCity": "McMurdo"
              },
              "Palmer": {
                "exemplarCity": "Palmer"
              },
              "Rothera": {
                "exemplarCity": "Rothera"
              },
              "Syowa": {
                "exemplarCity": "Showa"
              },
              "Troll": {
                "exemplarCity": "Troll"
              },
              "Vostok": {
                "exemplarCity": "Vostok"
              }
            },
            "Etc": {
              "GMT": {
                "exemplarCity": "GMT"
              },
              "GMT1": {
                "exemplarCity": "GMT+1"
              },
              "GMT10": {
                "exemplarCity": "GMT+10"
              },
              "GMT11": {
                "exemplarCity": "GMT+11"
              },
              "GMT12": {
                "exemplarCity": "GMT+12"
              },
              "GMT2": {
                "exemplarCity": "GMT+2"
              },
              "GMT3": {
                "exemplarCity": "GMT+3"
              },
              "GMT4": {
                "exemplarCity": "GMT+4"
              },
              "GMT5": {
                "exemplarCity": "GMT+5"
              },
              "GMT6": {
                "exemplarCity": "GMT+6"
              },
              "GMT7": {
                "exemplarCity": "GMT+7"
              },
              "GMT8": {
                "exemplarCity": "GMT+8"
              },
              "GMT9": {
                "exemplarCity": "GMT+9"
              },
              "GMT-1": {
                "exemplarCity": "GMT-1"
              },
              "GMT-10": {
                "exemplarCity": "GMT-10"
              },
              "GMT-11": {
                "exemplarCity": "GMT-11"
              },
              "GMT-12": {
                "exemplarCity": "GMT-12"
              },
              "GMT-13": {
                "exemplarCity": "GMT-13"
              },
              "GMT-14": {
                "exemplarCity": "GMT-14"
              },
              "GMT-2": {
                "exemplarCity": "GMT-2"
              },
              "GMT-3": {
                "exemplarCity": "GMT-3"
              },
              "GMT-4": {
                "exemplarCity": "GMT-4"
              },
              "GMT-5": {
                "exemplarCity": "GMT-5"
              },
              "GMT-6": {
                "exemplarCity": "GMT-6"
              },
              "GMT-7": {
                "exemplarCity": "GMT-7"
              },
              "GMT-8": {
                "exemplarCity": "GMT-8"
              },
              "GMT-9": {
                "exemplarCity": "GMT-9"
              },
              "UTC": {
                "long": {
                  "standard": "Tempo Universal Coordenado"
                },
                "short": {
                  "standard": "UTC"
                },
                "exemplarCity": "UTC"
              },
              "Unknown": {
                "exemplarCity": "Cidade desconhecida"
              }
            }
          },
          "metazone": {
            "Acre": {
              "long": {
                "generic": "Horário do Acre",
                "standard": "Horário Padrão do Acre",
                "daylight": "Horário de Verão do Acre"
              },
              "short": {
                "generic": "ACT",
                "standard": "ACT",
                "daylight": "ACST"
              }
            },
            "Afghanistan": {
              "long": {
                "standard": "Horário do Afeganistão"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "Horário da África Central"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "Horário da África Oriental"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "Horário da África do Sul"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "Horário da África Ocidental",
                "standard": "Horário Padrão da África Ocidental",
                "daylight": "Horário de Verão da África Ocidental"
              }
            },
            "Alaska": {
              "long": {
                "generic": "Horário do Alasca",
                "standard": "Horário Padrão do Alasca",
                "daylight": "Horário de Verão do Alasca"
              }
            },
            "Almaty": {
              "long": {
                "generic": "Horário do Almaty",
                "standard": "Horário Padrão do Almaty",
                "daylight": "Horário de Verão do Almaty"
              }
            },
            "Amazon": {
              "long": {
                "generic": "Horário do Amazonas",
                "standard": "Horário Padrão do Amazonas",
                "daylight": "Horário de Verão do Amazonas"
              },
              "short": {
                "generic": "AMT",
                "standard": "AMT",
                "daylight": "AMST"
              }
            },
            "America_Central": {
              "long": {
                "generic": "Horário Central",
                "standard": "Horário Padrão Central",
                "daylight": "Horário de Verão Central"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "Horário Oriental",
                "standard": "Horário Padrão Oriental",
                "daylight": "Horário de Verão Oriental"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "Horário da Montanha",
                "standard": "Horário Padrão da Montanha",
                "daylight": "Horário de Verão da Montanha"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "Horário do Pacífico",
                "standard": "Horário Padrão do Pacífico",
                "daylight": "Horário de Verão do Pacífico"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "Horário de Anadyr",
                "standard": "Horário Padrão do Anadyr",
                "daylight": "Horário de Verão do Anadyr"
              }
            },
            "Apia": {
              "long": {
                "generic": "Horário de Apia",
                "standard": "Horário Padrão de Apia",
                "daylight": "Horário de Verão de Apia"
              }
            },
            "Aqtau": {
              "long": {
                "generic": "Horário do Aqtau",
                "standard": "Horário Padrão do Aqtau",
                "daylight": "Horário de Verão do Aqtau"
              }
            },
            "Aqtobe": {
              "long": {
                "generic": "Horário do Aqtobe",
                "standard": "Horário Padrão do Aqtobe",
                "daylight": "Horário de Verão do Aqtobe"
              }
            },
            "Arabian": {
              "long": {
                "generic": "Horário da Arábia",
                "standard": "Horário Padrão da Arábia",
                "daylight": "Horário de Verão da Arábia"
              }
            },
            "Argentina": {
              "long": {
                "generic": "Horário da Argentina",
                "standard": "Horário Padrão da Argentina",
                "daylight": "Horário de Verão da Argentina"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "Horário da Argentina Ocidental",
                "standard": "Horário Padrão da Argentina Ocidental",
                "daylight": "Horário de Verão da Argentina Ocidental"
              }
            },
            "Armenia": {
              "long": {
                "generic": "Horário da Armênia",
                "standard": "Horário Padrão da Armênia",
                "daylight": "Horário de Verão da Armênia"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "Horário do Atlântico",
                "standard": "Horário Padrão do Atlântico",
                "daylight": "Horário de Verão do Atlântico"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "Horário da Austrália Central",
                "standard": "Horário Padrão da Austrália Central",
                "daylight": "Horário de Verão da Austrália Central"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "Horário da Austrália Centro-Ocidental",
                "standard": "Horário Padrão da Austrália Centro-Ocidental",
                "daylight": "Horário de Verão da Austrália Centro-Ocidental"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "Horário da Austrália Oriental",
                "standard": "Horário Padrão da Austrália Oriental",
                "daylight": "Horário de Verão da Austrália Oriental"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "Horário da Austrália Ocidental",
                "standard": "Horário Padrão da Austrália Ocidental",
                "daylight": "Horário de Verão da Austrália Ocidental"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "Horário do Arzeibaijão",
                "standard": "Horário Padrão do Arzeibaijão",
                "daylight": "Horário de Verão do Arzeibaijão"
              }
            },
            "Azores": {
              "long": {
                "generic": "Horário dos Açores",
                "standard": "Horário Padrão dos Açores",
                "daylight": "Horário de Verão dos Açores"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "Horário de Bangladesh",
                "standard": "Horário Padrão de Bangladesh",
                "daylight": "Horário de Verão de Bangladesh"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "Horário do Butão"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "Horário da Bolívia"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "Horário de Brasília",
                "standard": "Horário Padrão de Brasília",
                "daylight": "Horário de Verão de Brasília"
              },
              "short": {
                "generic": "BRT",
                "standard": "BRT",
                "daylight": "BRST"
              }
            },
            "Brunei": {
              "long": {
                "standard": "Horário de Brunei Darussalam"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "Horário do Cabo Verde",
                "standard": "Horário Padrão do Cabo Verde",
                "daylight": "Horário de Verão do Cabo Verde"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "Horário de Chamorro"
              }
            },
            "Chatham": {
              "long": {
                "generic": "Horário de Chatham",
                "standard": "Horário Padrão de Chatham",
                "daylight": "Horário de Verão de Chatham"
              }
            },
            "Chile": {
              "long": {
                "generic": "Horário do Chile",
                "standard": "Horário Padrão do Chile",
                "daylight": "Horário de Verão do Chile"
              }
            },
            "China": {
              "long": {
                "generic": "Horário da China",
                "standard": "Horário Padrão da China",
                "daylight": "Horário de Verão da China"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "Horário de Choibalsan",
                "standard": "Horário Padrão de Choibalsan",
                "daylight": "Horário de Verão de Choibalsan"
              }
            },
            "Christmas": {
              "long": {
                "standard": "Horário da Ilha Christmas"
              }
            },
            "Cocos": {
              "long": {
                "standard": "Horário das Ilhas Coco"
              }
            },
            "Colombia": {
              "long": {
                "generic": "Horário da Colômbia",
                "standard": "Horário Padrão da Colômbia",
                "daylight": "Horário de Verão da Colômbia"
              }
            },
            "Cook": {
              "long": {
                "generic": "Horário das Ilhas Cook",
                "standard": "Horário Padrão das Ilhas Cook",
                "daylight": "Meio Horário de Verão das Ilhas Cook"
              }
            },
            "Cuba": {
              "long": {
                "generic": "Horário de Cuba",
                "standard": "Horário Padrão de Cuba",
                "daylight": "Horário de Verão de Cuba"
              }
            },
            "Davis": {
              "long": {
                "standard": "Horário de Davis"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "Horário de Dumont-d’Urville"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "Horário do Timor-Leste"
              }
            },
            "Easter": {
              "long": {
                "generic": "Horário da Ilha de Páscoa",
                "standard": "Horário Padrão da Ilha de Páscoa",
                "daylight": "Horário de Verão da Ilha de Páscoa"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "Horário do Equador"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "Horário da Europa Central",
                "standard": "Horário Padrão da Europa Central",
                "daylight": "Horário de Verão da Europa Central"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "Horário da Europa Oriental",
                "standard": "Horário Padrão da Europa Oriental",
                "daylight": "Horário de Verão da Europa Oriental"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "Horário do Extremo Leste Europeu"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "Horário da Europa Ocidental",
                "standard": "Horário Padrão da Europa Ocidental",
                "daylight": "Horário de Verão da Europa Ocidental"
              }
            },
            "Falkland": {
              "long": {
                "generic": "Horário das Ilhas Falkland",
                "standard": "Horário Padrão das Ilhas Falkland",
                "daylight": "Horário de Verão das Ilhas Falkland"
              }
            },
            "Fiji": {
              "long": {
                "generic": "Horário de Fiji",
                "standard": "Horário Padrão de Fiji",
                "daylight": "Horário de Verão de Fiji"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "Horário da Guiana Francesa"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "Horário da Antártida e do Sul da França"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "Horário de Galápagos"
              }
            },
            "Gambier": {
              "long": {
                "standard": "Horário de Gambier"
              }
            },
            "Georgia": {
              "long": {
                "generic": "Horário da Geórgia",
                "standard": "Horário Padrão da Geórgia",
                "daylight": "Horário de Verão da Geórgia"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "Horário das Ilhas Gilberto"
              }
            },
            "GMT": {
              "long": {
                "standard": "Horário do Meridiano de Greenwich"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "Horário da Groelândia Oriental",
                "standard": "Horário Padrão da Groelândia Oriental",
                "daylight": "Horário de Verão da Groelândia Oriental"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "Horário da Groenlândia Ocidental",
                "standard": "Horário Padrão da Groenlândia Ocidental",
                "daylight": "Horário de Verão da Groenlândia Ocidental"
              }
            },
            "Guam": {
              "long": {
                "standard": "Horário Padrão de Guam"
              }
            },
            "Gulf": {
              "long": {
                "standard": "Horário do Golfo"
              }
            },
            "Guyana": {
              "long": {
                "standard": "Horário da Guiana"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "Horário do Havaí e Ilhas Aleutas",
                "standard": "Horário Padrão do Havaí e Ilhas Aleutas",
                "daylight": "Horário de Verão do Havaí e Ilhas Aleutas"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "Horário de Hong Kong",
                "standard": "Horário Padrão de Hong Kong",
                "daylight": "Horário de Verão de Hong Kong"
              }
            },
            "Hovd": {
              "long": {
                "generic": "Horário de Hovd",
                "standard": "Horário Padrão de Hovd",
                "daylight": "Horário de Verão de Hovd"
              }
            },
            "India": {
              "long": {
                "standard": "Horário Padrão da Índia"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "Horário do Oceano Índico"
              }
            },
            "Indochina": {
              "long": {
                "standard": "Horário da Indochina"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "Horário da Indonésia Central"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "Horário da Indonésia Oriental"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "Horário da Indonésia Ocidental"
              }
            },
            "Iran": {
              "long": {
                "generic": "Horário do Irã",
                "standard": "Horário Padrão do Irã",
                "daylight": "Horário de Verão do Irã"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "Horário de Irkutsk",
                "standard": "Horário Padrão de Irkutsk",
                "daylight": "Horário de Verão de Irkutsk"
              }
            },
            "Israel": {
              "long": {
                "generic": "Horário de Israel",
                "standard": "Horário Padrão de Israel",
                "daylight": "Horário de Verão de Israel"
              }
            },
            "Japan": {
              "long": {
                "generic": "Horário do Japão",
                "standard": "Horário Padrão do Japão",
                "daylight": "Horário de Verão do Japão"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "Horário de Petropavlovsk-Kamchatski",
                "standard": "Horário Padrão de Petropavlovsk-Kamchatski",
                "daylight": "Horário de Verão de Petropavlovsk-Kamchatski"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "Horário do Casaquistão Oriental"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "Horário do Casaquistão Ocidental"
              }
            },
            "Korea": {
              "long": {
                "generic": "Horário da Coreia",
                "standard": "Horário Padrão da Coreia",
                "daylight": "Horário de Verão da Coreia"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "Horário de Kosrae"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "Horário de Krasnoyarsk",
                "standard": "Horário Padrão de Krasnoyarsk",
                "daylight": "Horário de Verão de Krasnoyarsk"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "Horário do Quirguistão"
              }
            },
            "Lanka": {
              "long": {
                "standard": "Horário de Lanka"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "Horário das Ilhas Line"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "Horário de Lord Howe",
                "standard": "Horário Padrão de Lord Howe",
                "daylight": "Horário de Verão de Lord Howe"
              }
            },
            "Macau": {
              "long": {
                "generic": "Horário de Macau",
                "standard": "Horário Padrão de Macau",
                "daylight": "Horário de Verão de Macau"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "Horário da Ilha Macquarie"
              }
            },
            "Magadan": {
              "long": {
                "generic": "Horário de Magadan",
                "standard": "Horário Padrão de Magadan",
                "daylight": "Horário de Verão de Magadan"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "Horário da Malásia"
              }
            },
            "Maldives": {
              "long": {
                "standard": "Horário das Ilhas Maldivas"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "Horário das Marquesas"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "Horário das Ilhas Marshall"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "Horário das Ilhas Maurício",
                "standard": "Horário Padrão das Ilhas Maurício",
                "daylight": "Horário de Verão das Ilhas Maurício"
              }
            },
            "Mawson": {
              "long": {
                "standard": "Horário de Mawson"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "Horário do Noroeste do México",
                "standard": "Horário Padrão do Noroeste do México",
                "daylight": "Horário de Verão do Noroeste do México"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "Horário do Pacífico do México",
                "standard": "Horário Padrão do Pacífico do México",
                "daylight": "Horário de Verão do Pacífico do México"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "Horário de Ulan Bator",
                "standard": "Horário Padrão de Ulan Bator",
                "daylight": "Horário de Verão de Ulan Bator"
              }
            },
            "Moscow": {
              "long": {
                "generic": "Horário de Moscou",
                "standard": "Horário Padrão de Moscou",
                "daylight": "Horário de Verão de Moscou"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "Horário de Mianmar"
              }
            },
            "Nauru": {
              "long": {
                "standard": "Horário de Nauru"
              }
            },
            "Nepal": {
              "long": {
                "standard": "Horário do Nepal"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "Horário da Nova Caledônia",
                "standard": "Horário Padrão da Nova Caledônia",
                "daylight": "Horário de Verão da Nova Caledônia"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "Horário da Nova Zelândia",
                "standard": "Horário Padrão da Nova Zelândia",
                "daylight": "Horário de Verão da Nova Zelândia"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "Horário de Terra Nova",
                "standard": "Horário Padrão de Terra Nova",
                "daylight": "Horário de Verão de Terra Nova"
              }
            },
            "Niue": {
              "long": {
                "standard": "Horário de Niue"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "Horário da Ilha Norfolk"
              }
            },
            "Noronha": {
              "long": {
                "generic": "Horário de Fernando de Noronha",
                "standard": "Horário Padrão de Fernando de Noronha",
                "daylight": "Horário de Verão de Fernando de Noronha"
              }
            },
            "North_Mariana": {
              "long": {
                "standard": "Horário das Ilhas Mariana do Norte"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "Horário de Novosibirsk",
                "standard": "Horário Padrão de Novosibirsk",
                "daylight": "Horário de Verão de Novosibirsk"
              }
            },
            "Omsk": {
              "long": {
                "generic": "Horário de Omsk",
                "standard": "Horário Padrão de Omsk",
                "daylight": "Horário de Verão de Omsk"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "Horário do Paquistão",
                "standard": "Horário Padrão do Paquistão",
                "daylight": "Horário de Verão do Paquistão"
              }
            },
            "Palau": {
              "long": {
                "standard": "Horário de Palau"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "Horário de Papua Nova Guiné"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "Horário do Paraguai",
                "standard": "Horário Padrão do Paraguai",
                "daylight": "Horário de Verão do Paraguai"
              }
            },
            "Peru": {
              "long": {
                "generic": "Horário do Peru",
                "standard": "Horário Padrão do Peru",
                "daylight": "Horário de Verão do Peru"
              }
            },
            "Philippines": {
              "long": {
                "generic": "Horário das Filipinas",
                "standard": "Horário Padrão das Filipinas",
                "daylight": "Horário de Verão das Filipinas"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "Horário das Ilhas Fênix"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "Horário de Saint Pierre e Miquelon",
                "standard": "Horário Padrão de Saint Pierre e Miquelon",
                "daylight": "Horário de Verão de Saint Pierre e Miquelon"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "Horário de Pitcairn"
              }
            },
            "Ponape": {
              "long": {
                "standard": "Horário de Ponape"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "Horário de Pyongyang"
              }
            },
            "Qyzylorda": {
              "long": {
                "generic": "Horário de Qyzylorda",
                "standard": "Horário Padrão de Qyzylorda",
                "daylight": "Horário de Verão de Qyzylorda"
              }
            },
            "Reunion": {
              "long": {
                "standard": "Horário das Ilhas Reunião"
              }
            },
            "Rothera": {
              "long": {
                "standard": "Horário de Rothera"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "Horário de Sacalina",
                "standard": "Horário Padrão de Sacalina",
                "daylight": "Horário de Verão de Sacalina"
              }
            },
            "Samara": {
              "long": {
                "generic": "Horário de Samara",
                "standard": "Horário Padrão de Samara",
                "daylight": "Horário de Verão de Samara"
              }
            },
            "Samoa": {
              "long": {
                "generic": "Horário de Samoa",
                "standard": "Horário Padrão de Samoa",
                "daylight": "Horário de Verão de Samoa"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "Horário das Ilhas Seychelles"
              }
            },
            "Singapore": {
              "long": {
                "standard": "Horário Padrão de Cingapura"
              }
            },
            "Solomon": {
              "long": {
                "standard": "Horário das Ilhas Salomão"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "Horário da Geórgia do Sul"
              }
            },
            "Suriname": {
              "long": {
                "standard": "Horário do Suriname"
              }
            },
            "Syowa": {
              "long": {
                "standard": "Horário de Syowa"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "Horário do Taiti"
              }
            },
            "Taipei": {
              "long": {
                "generic": "Horário de Taipei",
                "standard": "Horário Padrão de Taipei",
                "daylight": "Horário de Verão de Taipei"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "Horário do Tajiquistão"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "Horário de Tokelau"
              }
            },
            "Tonga": {
              "long": {
                "generic": "Horário de Tonga",
                "standard": "Horário Padrão de Tonga",
                "daylight": "Horário de Verão de Tonga"
              }
            },
            "Truk": {
              "long": {
                "standard": "Horário de Chuuk"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "Horário do Turcomenistão",
                "standard": "Horário Padrão do Turcomenistão",
                "daylight": "Horário de Verão do Turcomenistão"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "Horário de Tuvalu"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "Horário do Uruguai",
                "standard": "Horário Padrão do Uruguai",
                "daylight": "Horário de Verão do Uruguai"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "Horário do Uzbequistão",
                "standard": "Horário Padrão do Uzbequistão",
                "daylight": "Horário de Verão do Uzbequistão"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "Horário de Vanuatu",
                "standard": "Horário Padrão de Vanuatu",
                "daylight": "Horário de Verão de Vanuatu"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "Horário da Venezuela"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "Horário de Vladivostok",
                "standard": "Horário Padrão de Vladivostok",
                "daylight": "Horário de Verão de Vladivostok"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "Horário de Volgogrado",
                "standard": "Horário Padrão de Volgogrado",
                "daylight": "Horário de Verão de Volgogrado"
              }
            },
            "Vostok": {
              "long": {
                "standard": "Horário de Vostok"
              }
            },
            "Wake": {
              "long": {
                "standard": "Horário das Ilhas Wake"
              }
            },
            "Wallis": {
              "long": {
                "standard": "Horário de Wallis e Futuna"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "Horário de Yakutsk",
                "standard": "Horário Padrão de Yakutsk",
                "daylight": "Horário de Verão de Yakutsk"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "Horário de Ecaterimburgo",
                "standard": "Horário Padrão de Ecaterimburgo",
                "daylight": "Horário de Verão de Ecaterimburgo"
              }
            }
          }
        }
      }
    }
  }
}
