const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');

const Chronom = React.createClass({

  mixins: [PureRenderMixin],

  propTypes: {
  },

  getInitialState() {
   return {
      timerinterval:null,
      startdate:'',
      tijdwaarde:null,    
      timeinterval:null,
      verloop:null,
    };
  },

  //to update theme inside state whenever a new theme is passed down
  //from the parent / owner using context
  componentWillReceiveProps (nextProps, nextContext) {

  },
  componentDidMount() {
    if (this.props.date)
    {
      if (this.props.date.length > 8 || this.props.start)
      {
        this.state.startdate = this.props.date;
        this.updateClock()
        this.Starttimer(this.props.date);
      }
      else
      {
        this.state.startdate = null;
        this.state.tijdwaarde = null;
      }
    }
  },
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.children && nextProps.children.props) {
      nextProps = nextProps.children.props;
    }
    if (nextProps.date)
    {
      if (nextProps.date !== this.props.date)
      {
        if (nextProps.date.length > 8)
        {
          this.state.startdate = nextProps.date;
          this.updateClock()
          this.Starttimer(nextProps.date);
        }
        else
        {
          this.state.startdate = null;
          this.setState({tijdwaarde:null})
          var timeinterval = clearInterval(this.state.timeinterval);

        }
      }
    }
    else
    {
      var timeinterval = clearInterval(this.state.timeinterval);
      this.state.startdate = null;
      this.setState({tijdwaarde:null})
    }
  },

  render() {
    let {
      data,
      date,
      Stop,
      ...other,
    } = this.props;
    return (
      <div>
       {this.state.tijdwaarde} 
      </div>
    );
  },

  Starttimer(datum) {
    this.setState({startdate:datum});
    this.updateClock(); // run function once at first to avoid delay
    this.state.timeinterval = setInterval(this.updateClock,1000);
  },

  getTimeRemaining(endtime){
    var t = Date.parse(new Date()) - Date.parse(endtime);
    var seconds = Math.floor( (t/1000) % 60 );
    var minutes = Math.floor( (t/1000/60) % 60 );
    var hours = Math.floor( (t/(1000*60*60)) % 24 );
    var days = Math.floor( t/(1000*60*60*24) );
    if( hours   < 10) { hours   = "0"+ hours }
    if( minutes < 10) { minutes = "0"+ minutes }
    if( seconds < 10) { seconds = "0"+ seconds }
    this.state.verloop = t;
    return {
      'total': t,
      'days': days,
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds
    };
  },

  updateClock(){
    if (this.isMounted()) 
    {
      var t = this.getTimeRemaining(this.state.startdate);
      var tijdwaarde = (<span> {t.hours}:{t.minutes}:{t.seconds} <i onClick={(event)=>this.Stoptimer()} style={{color: 'red'}} className="fa fa-clock-o fa-spin fa-stopX"/> </span>);  
      this.setState({tijdwaarde:tijdwaarde});
    }
    else
    {
      var timeinterval = clearInterval(this.state.timeinterval);
    }
  },

  Stoptimer(){
    var timeinterval = clearInterval(this.state.timeinterval);
    this.setState({tijdwaarde:null})
    if (this.props.Stop) {
      this.props.Stop(this.props.data);
    }
  },
});

module.exports = Chronom;
