const React = require('react');
const Checkbox = require('../checkbox.jsx');
const FlatButton = require('../flat-button.jsx');
const StylePropable = require('../mixins/style-propable');
const Tooltip = require('../tooltip.jsx');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../../immutab/dist/immutable');


let TableHeaderColumn = React.createClass({

  mixins: [StylePropable, PureRenderMixin],

  contextTypes: {
    muiTheme: React.PropTypes.object
  },

  propTypes: {
    tooltip: React.PropTypes.string,
    columnNumber: React.PropTypes.number.isRequired,
    onClick: React.PropTypes.func
  },

  getInitialState() {
    return {
      hovered: false,
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  getTheme() {
    return this.state.muiTheme.tableHeaderColumn;
  },  


  getStyles() {
    let theme = this.getTheme();
    let styles = {
      root:  {
        fontWeight: 'normal',
        fontSize: '100%',
        height: theme.height,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: this.getTheme().textColor,
        position: 'relative',
        width:'100%'
      },
      tooltip: {
        boxSizing: 'border-box',
        marginTop: theme.height
      }, 
      checkbox: {
        paddingLeft: 24,
        width:'24px'
      },
    };

    return styles;
  },

  render() {
    let className = 'mui-table-header-column';
    let styles = this.getStyles();
    let handlers = {
      onMouseOver: this._onMouseOver,
      onMouseOut: this._onMouseOut,
      onClick: this._onClick
    };
    let tooltip;
    let flatbutton;

    if (this.props.tooltip !== undefined) {
      tooltip = (
        <Tooltip
          label={this.props.tooltip}
          show={this.state.hovered}
          style={this.mergeStyles(styles.tooltip)} />
      );
      
      let labelf;
      if (this.props.headsortcol == this.props.content)
      {
        labelf = (this.props.headsorttype == 'A') ? this.props.tooltip + '  ↓' : this.props.tooltip + '  ↑';
      }
      else
      {
         labelf = this.props.tooltip;
      }        

      flatbutton = (<FlatButton label={labelf} kleur="#00bcd4" />);

      if (this.props.tooltip == 'CHKBOX')
      {
        flatbutton = (<Checkbox name='selectallcb'
        value='selected'
        style={styles.checkbox}
        disabled={false}
        defaultChecked={this.props.Selected}
        onCheck={this._oncheckall} />);
      }


    }


    return (
      <th
        key={this.props.key}
        className={className}
        style={this.mergeAndPrefix(styles.root, this.props.style)}
        {...handlers}>
        {flatbutton}
      </th>
    );
  },

  _onMouseOver() {
    if (this.props.tooltip !== undefined) this.setState({hovered: true});
  },

  _onMouseOut() {
    if (this.props.tooltip !== undefined) this.setState({hovered: false});
  },

  _onClick(e) {
    if (this.props.onClick) this.props.onClick(e, this.props.columnNumber,this.props.content, this.props.type);
  },

  _oncheckall() {
    if (this.props.onSelectAll) this.props.onSelectAll();
  },

});

module.exports = TableHeaderColumn;
