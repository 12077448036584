module.exports={
  "main": {
    "pt": {
      "identity": {
        "version": {
          "_number": "$Revision: 13259 $",
          "_cldrVersion": "31.0.1"
        },
        "language": "pt"
      },
      "numbers": {
        "currencies": {
          "ADP": {
            "displayName": "Peseta de Andorra",
            "displayName-count-one": "Peseta de Andorra",
            "displayName-count-other": "Pesetas de Andorra",
            "symbol": "ADP"
          },
          "AED": {
            "displayName": "Dirrã dos Emirados Árabes Unidos",
            "displayName-count-one": "Dirrã dos Emirados Árabes Unidos",
            "displayName-count-other": "Dirrãs dos Emirados Árabes Unidos",
            "symbol": "AED"
          },
          "AFA": {
            "displayName": "Afegane (1927–2002)",
            "displayName-count-one": "Afegane do Afeganistão (AFA)",
            "displayName-count-other": "Afeganes do Afeganistão (AFA)",
            "symbol": "AFA"
          },
          "AFN": {
            "displayName": "Afegane afegão",
            "displayName-count-one": "Afegane afegão",
            "displayName-count-other": "Afeganes afegãos",
            "symbol": "AFN"
          },
          "ALK": {
            "displayName": "Lek Albanês (1946–1965)",
            "displayName-count-one": "Lek Albanês (1946–1965)",
            "displayName-count-other": "Leks Albaneses (1946–1965)"
          },
          "ALL": {
            "displayName": "Lek albanês",
            "displayName-count-one": "Lek albanês",
            "displayName-count-other": "Leks albaneses",
            "symbol": "ALL"
          },
          "AMD": {
            "displayName": "Dram armênio",
            "displayName-count-one": "Dram armênio",
            "displayName-count-other": "Drams armênios",
            "symbol": "AMD"
          },
          "ANG": {
            "displayName": "Florim das Antilhas Holandesas",
            "displayName-count-one": "Florim das Antilhas Holandesas",
            "displayName-count-other": "Florins das Antilhas Holandesas",
            "symbol": "ANG"
          },
          "AOA": {
            "displayName": "Kwanza angolano",
            "displayName-count-one": "Kwanza angolano",
            "displayName-count-other": "Kwanzas angolanos",
            "symbol": "AOA",
            "symbol-alt-narrow": "Kz"
          },
          "AOK": {
            "displayName": "Cuanza angolano (1977–1990)",
            "displayName-count-one": "Kwanza angolano (AOK)",
            "displayName-count-other": "Kwanzas angolanos (AOK)",
            "symbol": "AOK"
          },
          "AON": {
            "displayName": "Novo cuanza angolano (1990–2000)",
            "displayName-count-one": "Novo kwanza angolano (AON)",
            "displayName-count-other": "Novos kwanzas angolanos (AON)",
            "symbol": "AON"
          },
          "AOR": {
            "displayName": "Cuanza angolano reajustado (1995–1999)",
            "displayName-count-one": "Kwanza angolano reajustado (AOR)",
            "displayName-count-other": "Kwanzas angolanos reajustados (AOR)",
            "symbol": "AOR"
          },
          "ARA": {
            "displayName": "Austral argentino",
            "displayName-count-one": "Austral argentino",
            "displayName-count-other": "Austrais argentinos",
            "symbol": "ARA"
          },
          "ARL": {
            "displayName": "Peso lei argentino (1970–1983)",
            "displayName-count-one": "Peso lei argentino (1970–1983)",
            "displayName-count-other": "Pesos lei argentinos (1970–1983)",
            "symbol": "ARL"
          },
          "ARM": {
            "displayName": "Peso argentino (1881–1970)",
            "displayName-count-one": "Peso argentino (1881–1970)",
            "displayName-count-other": "Pesos argentinos (1881–1970)",
            "symbol": "ARM"
          },
          "ARP": {
            "displayName": "Peso argentino (1983–1985)",
            "displayName-count-one": "Peso argentino (1983–1985)",
            "displayName-count-other": "Pesos argentinos (1983–1985)",
            "symbol": "ARP"
          },
          "ARS": {
            "displayName": "Peso argentino",
            "displayName-count-one": "Peso argentino",
            "displayName-count-other": "Pesos argentinos",
            "symbol": "ARS",
            "symbol-alt-narrow": "$"
          },
          "ATS": {
            "displayName": "Xelim austríaco",
            "displayName-count-one": "Schilling australiano",
            "displayName-count-other": "Schillings australianos",
            "symbol": "ATS"
          },
          "AUD": {
            "displayName": "Dólar australiano",
            "displayName-count-one": "Dólar australiano",
            "displayName-count-other": "Dólares australianos",
            "symbol": "AU$",
            "symbol-alt-narrow": "$"
          },
          "AWG": {
            "displayName": "Florim arubano",
            "displayName-count-one": "Florim arubano",
            "displayName-count-other": "Florins arubanos",
            "symbol": "AWG"
          },
          "AZM": {
            "displayName": "Manat azerbaijano (1993–2006)",
            "displayName-count-one": "Manat do Azeibaijão (1993–2006)",
            "displayName-count-other": "Manats do Azeibaijão (1993–2006)",
            "symbol": "AZM"
          },
          "AZN": {
            "displayName": "Manat azeri",
            "displayName-count-one": "Manat azeri",
            "displayName-count-other": "Manats azeris",
            "symbol": "AZN"
          },
          "BAD": {
            "displayName": "Dinar da Bósnia-Herzegovina (1992–1994)",
            "displayName-count-one": "Dinar da Bósnia Herzegovina",
            "displayName-count-other": "Dinares da Bósnia Herzegovina",
            "symbol": "BAD"
          },
          "BAM": {
            "displayName": "Marco bósnio-herzegovino conversível",
            "displayName-count-one": "Marco bósnio-herzegovino conversível",
            "displayName-count-other": "Marco bósnio-herzegovino conversível",
            "symbol": "BAM",
            "symbol-alt-narrow": "KM"
          },
          "BAN": {
            "displayName": "Novo dinar da Bósnia-Herzegovina (1994–1997)",
            "displayName-count-one": "Novo dinar da Bósnia-Herzegovina",
            "displayName-count-other": "Novos dinares da Bósnia-Herzegovina",
            "symbol": "BAN"
          },
          "BBD": {
            "displayName": "Dólar barbadense",
            "displayName-count-one": "Dólar barbadense",
            "displayName-count-other": "Dólares barbadenses",
            "symbol": "BBD",
            "symbol-alt-narrow": "$"
          },
          "BDT": {
            "displayName": "Taka bengalesa",
            "displayName-count-one": "Taka bengalesa",
            "displayName-count-other": "Takas bengalesas",
            "symbol": "BDT",
            "symbol-alt-narrow": "৳"
          },
          "BEC": {
            "displayName": "Franco belga (conversível)",
            "displayName-count-one": "Franco belga (conversível)",
            "displayName-count-other": "Francos belgas (conversíveis)",
            "symbol": "BEC"
          },
          "BEF": {
            "displayName": "Franco belga",
            "displayName-count-one": "Franco belga",
            "displayName-count-other": "Francos belgas",
            "symbol": "BEF"
          },
          "BEL": {
            "displayName": "Franco belga (financeiro)",
            "displayName-count-one": "Franco belga (financeiro)",
            "displayName-count-other": "Francos belgas (financeiros)",
            "symbol": "BEL"
          },
          "BGL": {
            "displayName": "Lev forte búlgaro",
            "displayName-count-one": "Lev forte búlgaro",
            "displayName-count-other": "Levs fortes búlgaros",
            "symbol": "BGL"
          },
          "BGM": {
            "displayName": "Lev socialista búlgaro",
            "displayName-count-one": "Lev socialista búlgaro",
            "displayName-count-other": "Levs socialistas búlgaros",
            "symbol": "BGM"
          },
          "BGN": {
            "displayName": "Lev búlgaro",
            "displayName-count-one": "Lev búlgaro",
            "displayName-count-other": "Levs búlgaros",
            "symbol": "BGN"
          },
          "BGO": {
            "displayName": "Lev búlgaro (1879–1952)",
            "displayName-count-one": "Lev búlgaro (1879–1952)",
            "displayName-count-other": "Levs búlgaros (1879–1952)",
            "symbol": "BGO"
          },
          "BHD": {
            "displayName": "Dinar bareinita",
            "displayName-count-one": "Dinar bareinita",
            "displayName-count-other": "Dinares bareinitas",
            "symbol": "BHD"
          },
          "BIF": {
            "displayName": "Franco burundiano",
            "displayName-count-one": "Franco burundiano",
            "displayName-count-other": "Francos burundianos",
            "symbol": "BIF"
          },
          "BMD": {
            "displayName": "Dólar bermudense",
            "displayName-count-one": "Dólar bermudense",
            "displayName-count-other": "Dólares bermudenses",
            "symbol": "BMD",
            "symbol-alt-narrow": "$"
          },
          "BND": {
            "displayName": "Dólar bruneano",
            "displayName-count-one": "Dólar bruneano",
            "displayName-count-other": "Dólares bruneanos",
            "symbol": "BND",
            "symbol-alt-narrow": "$"
          },
          "BOB": {
            "displayName": "Boliviano",
            "displayName-count-one": "Boliviano",
            "displayName-count-other": "Bolivianos",
            "symbol": "BOB",
            "symbol-alt-narrow": "Bs"
          },
          "BOL": {
            "displayName": "Boliviano (1863–1963)",
            "displayName-count-one": "Boliviano (1863–1963)",
            "displayName-count-other": "Bolivianos (1863–1963)",
            "symbol": "BOL"
          },
          "BOP": {
            "displayName": "Peso boliviano",
            "displayName-count-one": "Peso boliviano",
            "displayName-count-other": "Pesos bolivianos",
            "symbol": "BOP"
          },
          "BOV": {
            "displayName": "Mvdol boliviano",
            "displayName-count-one": "Mvdol boliviano",
            "displayName-count-other": "Mvdols bolivianos",
            "symbol": "BOV"
          },
          "BRB": {
            "displayName": "Cruzeiro novo brasileiro (1967–1986)",
            "displayName-count-one": "Cruzeiro novo brasileiro (BRB)",
            "displayName-count-other": "Cruzeiros novos brasileiros (BRB)",
            "symbol": "BRB"
          },
          "BRC": {
            "displayName": "Cruzado brasileiro (1986–1989)",
            "displayName-count-one": "Cruzado brasileiro",
            "displayName-count-other": "Cruzados brasileiros",
            "symbol": "BRC"
          },
          "BRE": {
            "displayName": "Cruzeiro brasileiro (1990–1993)",
            "displayName-count-one": "Cruzeiro brasileiro (BRE)",
            "displayName-count-other": "Cruzeiros brasileiros (BRE)",
            "symbol": "BRE"
          },
          "BRL": {
            "displayName": "Real brasileiro",
            "displayName-count-one": "Real brasileiro",
            "displayName-count-other": "Reais brasileiros",
            "symbol": "R$",
            "symbol-alt-narrow": "R$"
          },
          "BRN": {
            "displayName": "Cruzado novo brasileiro (1989–1990)",
            "displayName-count-one": "Cruzado novo brasileiro",
            "displayName-count-other": "Cruzados novos brasileiros",
            "symbol": "BRN"
          },
          "BRR": {
            "displayName": "Cruzeiro brasileiro (1993–1994)",
            "displayName-count-one": "Cruzeiro brasileiro",
            "displayName-count-other": "Cruzeiros brasileiros",
            "symbol": "BRR"
          },
          "BRZ": {
            "displayName": "Cruzeiro brasileiro (1942–1967)",
            "displayName-count-one": "Cruzeiro brasileiro antigo",
            "displayName-count-other": "Cruzeiros brasileiros antigos",
            "symbol": "BRZ"
          },
          "BSD": {
            "displayName": "Dólar bahamense",
            "displayName-count-one": "Dólar bahamense",
            "displayName-count-other": "Dólares bahamenses",
            "symbol": "BSD",
            "symbol-alt-narrow": "$"
          },
          "BTN": {
            "displayName": "Ngultrum butanês",
            "displayName-count-one": "Ngultrum butanês",
            "displayName-count-other": "Ngultruns butaneses",
            "symbol": "BTN"
          },
          "BUK": {
            "displayName": "Kyat birmanês",
            "displayName-count-one": "Kyat burmês",
            "displayName-count-other": "Kyats burmeses",
            "symbol": "BUK"
          },
          "BWP": {
            "displayName": "Pula botsuanesa",
            "displayName-count-one": "Pula botsuanesa",
            "displayName-count-other": "Pulas botsuanesas",
            "symbol": "BWP",
            "symbol-alt-narrow": "P"
          },
          "BYB": {
            "displayName": "Rublo novo bielo-russo (1994–1999)",
            "displayName-count-one": "Novo rublo bielorusso (BYB)",
            "displayName-count-other": "Novos rublos bielorussos (BYB)",
            "symbol": "BYB"
          },
          "BYN": {
            "displayName": "Rublo bielorrusso",
            "displayName-count-one": "Rublo bielorrusso",
            "displayName-count-other": "Rublos bielorrussos",
            "symbol": "BYN",
            "symbol-alt-narrow": "р."
          },
          "BYR": {
            "displayName": "Rublo bielorrusso (2000–2016)",
            "displayName-count-one": "Rublo bielorrusso (2000–2016)",
            "displayName-count-other": "Rublos bielorrussos (2000–2016)",
            "symbol": "BYR"
          },
          "BZD": {
            "displayName": "Dólar belizenho",
            "displayName-count-one": "Dólar belizenho",
            "displayName-count-other": "Dólares belizenhos",
            "symbol": "BZD",
            "symbol-alt-narrow": "$"
          },
          "CAD": {
            "displayName": "Dólar canadense",
            "displayName-count-one": "Dólar canadense",
            "displayName-count-other": "Dólares canadenses",
            "symbol": "CA$",
            "symbol-alt-narrow": "$"
          },
          "CDF": {
            "displayName": "Franco congolês",
            "displayName-count-one": "Franco congolês",
            "displayName-count-other": "Francos congoleses",
            "symbol": "CDF"
          },
          "CHE": {
            "displayName": "Euro WIR",
            "displayName-count-one": "Euro WIR",
            "displayName-count-other": "Euros WIR",
            "symbol": "CHE"
          },
          "CHF": {
            "displayName": "Franco suíço",
            "displayName-count-one": "Franco suíço",
            "displayName-count-other": "Francos suíços",
            "symbol": "CHF"
          },
          "CHW": {
            "displayName": "Franco WIR",
            "displayName-count-one": "Franco WIR",
            "displayName-count-other": "Francos WIR",
            "symbol": "CHW"
          },
          "CLE": {
            "displayName": "Escudo chileno",
            "displayName-count-one": "Escudo chileno",
            "displayName-count-other": "Escudos chilenos",
            "symbol": "CLE"
          },
          "CLF": {
            "displayName": "Unidades de Fomento chilenas",
            "displayName-count-one": "Unidade de fomento chilena",
            "displayName-count-other": "Unidades de fomento chilenas",
            "symbol": "CLF"
          },
          "CLP": {
            "displayName": "Peso chileno",
            "displayName-count-one": "Peso chileno",
            "displayName-count-other": "Pesos chilenos",
            "symbol": "CLP",
            "symbol-alt-narrow": "$"
          },
          "CNX": {
            "displayName": "Dólar do Banco Popular da China",
            "displayName-count-one": "Dólar do Banco Popular da China",
            "displayName-count-other": "Dólares do Banco Popular da China"
          },
          "CNY": {
            "displayName": "Yuan chinês",
            "displayName-count-one": "Yuan chinês",
            "displayName-count-other": "Yuans chineses",
            "symbol": "CN¥",
            "symbol-alt-narrow": "¥"
          },
          "COP": {
            "displayName": "Peso colombiano",
            "displayName-count-one": "Peso colombiano",
            "displayName-count-other": "Pesos colombianos",
            "symbol": "COP",
            "symbol-alt-narrow": "$"
          },
          "COU": {
            "displayName": "Unidade de Valor Real",
            "displayName-count-one": "Unidade de valor real",
            "displayName-count-other": "Unidades de valor real",
            "symbol": "COU"
          },
          "CRC": {
            "displayName": "Colón costarriquenho",
            "displayName-count-one": "Colón costarriquenho",
            "displayName-count-other": "Colóns costarriquenhos",
            "symbol": "CRC",
            "symbol-alt-narrow": "₡"
          },
          "CSD": {
            "displayName": "Dinar sérvio (2002–2006)",
            "displayName-count-one": "Dinar antigo da Sérvia",
            "displayName-count-other": "Dinares antigos da Sérvia",
            "symbol": "CSD"
          },
          "CSK": {
            "displayName": "Coroa Forte checoslovaca",
            "displayName-count-one": "Coroa forte tchecoslovaca",
            "displayName-count-other": "Coroas fortes tchecoslovacas",
            "symbol": "CSK"
          },
          "CUC": {
            "displayName": "Peso cubano conversível",
            "displayName-count-one": "Peso cubano conversível",
            "displayName-count-other": "Pesos cubanos conversíveis",
            "symbol": "CUC",
            "symbol-alt-narrow": "$"
          },
          "CUP": {
            "displayName": "Peso cubano",
            "displayName-count-one": "Peso cubano",
            "displayName-count-other": "Pesos cubanos",
            "symbol": "CUP",
            "symbol-alt-narrow": "$"
          },
          "CVE": {
            "displayName": "Escudo cabo-verdiano",
            "displayName-count-one": "Escudo cabo-verdiano",
            "displayName-count-other": "Escudos cabo-verdianos",
            "symbol": "CVE"
          },
          "CYP": {
            "displayName": "Libra cipriota",
            "displayName-count-one": "Libra cipriota",
            "displayName-count-other": "Libras cipriotas",
            "symbol": "CYP"
          },
          "CZK": {
            "displayName": "Coroa tcheca",
            "displayName-count-one": "Coroa tcheca",
            "displayName-count-other": "Coroas tchecas",
            "symbol": "CZK",
            "symbol-alt-narrow": "Kč"
          },
          "DDM": {
            "displayName": "Ostmark da Alemanha Oriental",
            "displayName-count-one": "Marco da Alemanha Oriental",
            "displayName-count-other": "Marcos da Alemanha Oriental",
            "symbol": "DDM"
          },
          "DEM": {
            "displayName": "Marco alemão",
            "displayName-count-one": "Marco alemão",
            "displayName-count-other": "Marcos alemães",
            "symbol": "DEM"
          },
          "DJF": {
            "displayName": "Franco djibutiense",
            "displayName-count-one": "Franco djibutiense",
            "displayName-count-other": "Francos djibutienses",
            "symbol": "DJF"
          },
          "DKK": {
            "displayName": "Coroa dinamarquesa",
            "displayName-count-one": "Coroa dinamarquesa",
            "displayName-count-other": "Coroas dinamarquesas",
            "symbol": "DKK",
            "symbol-alt-narrow": "kr"
          },
          "DOP": {
            "displayName": "Peso dominicano",
            "displayName-count-one": "Peso dominicano",
            "displayName-count-other": "Pesos dominicanos",
            "symbol": "DOP",
            "symbol-alt-narrow": "$"
          },
          "DZD": {
            "displayName": "Dinar argelino",
            "displayName-count-one": "Dinar argelino",
            "displayName-count-other": "Dinares argelinos",
            "symbol": "DZD"
          },
          "ECS": {
            "displayName": "Sucre equatoriano",
            "displayName-count-one": "Sucre equatoriano",
            "displayName-count-other": "Sucres equatorianos",
            "symbol": "ECS"
          },
          "ECV": {
            "displayName": "Unidade de Valor Constante (UVC) do Equador",
            "displayName-count-one": "Unidade de valor constante equatoriana (UVC)",
            "displayName-count-other": "Unidades de valor constante equatorianas (UVC)",
            "symbol": "ECV"
          },
          "EEK": {
            "displayName": "Coroa estoniana",
            "displayName-count-one": "Coroa estoniana",
            "displayName-count-other": "Coroas estonianas",
            "symbol": "EEK"
          },
          "EGP": {
            "displayName": "Libra egípcia",
            "displayName-count-one": "Libra egípcia",
            "displayName-count-other": "Libras egípcias",
            "symbol": "EGP",
            "symbol-alt-narrow": "E£"
          },
          "ERN": {
            "displayName": "Nakfa da Eritreia",
            "displayName-count-one": "Nakfa da Eritreia",
            "displayName-count-other": "Nakfas da Eritreia",
            "symbol": "ERN"
          },
          "ESA": {
            "displayName": "Peseta espanhola (conta A)",
            "displayName-count-one": "Peseta espanhola (conta A)",
            "displayName-count-other": "Pesetas espanholas (conta A)",
            "symbol": "ESA"
          },
          "ESB": {
            "displayName": "Peseta espanhola (conta conversível)",
            "displayName-count-one": "Peseta espanhola (conta conversível)",
            "displayName-count-other": "Pesetas espanholas (conta conversível)",
            "symbol": "ESB"
          },
          "ESP": {
            "displayName": "Peseta espanhola",
            "displayName-count-one": "Peseta espanhola",
            "displayName-count-other": "Pesetas espanholas",
            "symbol": "ESP",
            "symbol-alt-narrow": "₧"
          },
          "ETB": {
            "displayName": "Birr etíope",
            "displayName-count-one": "Birr etíope",
            "displayName-count-other": "Birrs etíopes",
            "symbol": "ETB"
          },
          "EUR": {
            "displayName": "Euro",
            "displayName-count-one": "Euro",
            "displayName-count-other": "Euros",
            "symbol": "€",
            "symbol-alt-narrow": "€"
          },
          "FIM": {
            "displayName": "Marca finlandesa",
            "displayName-count-one": "Marco finlandês",
            "displayName-count-other": "Marcos finlandeses",
            "symbol": "FIM"
          },
          "FJD": {
            "displayName": "Dólar fijiano",
            "displayName-count-one": "Dólar fijiano",
            "displayName-count-other": "Dólares fijianos",
            "symbol": "FJD",
            "symbol-alt-narrow": "$"
          },
          "FKP": {
            "displayName": "Libra malvinense",
            "displayName-count-one": "Libra malvinense",
            "displayName-count-other": "Libras malvinenses",
            "symbol": "FKP",
            "symbol-alt-narrow": "£"
          },
          "FRF": {
            "displayName": "Franco francês",
            "displayName-count-one": "Franco francês",
            "displayName-count-other": "Francos franceses",
            "symbol": "FRF"
          },
          "GBP": {
            "displayName": "Libra britânica",
            "displayName-count-one": "Libra britânica",
            "displayName-count-other": "Libras britânicas",
            "symbol": "£",
            "symbol-alt-narrow": "£"
          },
          "GEK": {
            "displayName": "Cupom Lari georgiano",
            "displayName-count-one": "Kupon larit da Geórgia",
            "displayName-count-other": "Kupon larits da Geórgia",
            "symbol": "GEK"
          },
          "GEL": {
            "displayName": "Lari georgiano",
            "displayName-count-one": "Lari georgiano",
            "displayName-count-other": "Laris georgianos",
            "symbol": "GEL",
            "symbol-alt-narrow": "₾",
            "symbol-alt-variant": "₾"
          },
          "GHC": {
            "displayName": "Cedi de Gana (1979–2007)",
            "displayName-count-one": "Cedi de Gana (1979–2007)",
            "displayName-count-other": "Cedis de Gana (1979–2007)",
            "symbol": "GHC"
          },
          "GHS": {
            "displayName": "Cedi ganês",
            "displayName-count-one": "Cedi ganês",
            "displayName-count-other": "Cedis ganeses",
            "symbol": "GHS"
          },
          "GIP": {
            "displayName": "Libra de Gibraltar",
            "displayName-count-one": "Libra de Gibraltar",
            "displayName-count-other": "Libras de Gibraltar",
            "symbol": "GIP",
            "symbol-alt-narrow": "£"
          },
          "GMD": {
            "displayName": "Dalasi gambiano",
            "displayName-count-one": "Dalasi gambiano",
            "displayName-count-other": "Dalasis gambianos",
            "symbol": "GMD"
          },
          "GNF": {
            "displayName": "Franco guineano",
            "displayName-count-one": "Franco guineano",
            "displayName-count-other": "Francos guineanos",
            "symbol": "GNF",
            "symbol-alt-narrow": "FG"
          },
          "GNS": {
            "displayName": "Syli da Guiné",
            "displayName-count-one": "Syli guineano",
            "displayName-count-other": "Sylis guineanos",
            "symbol": "GNS"
          },
          "GQE": {
            "displayName": "Ekwele da Guiné Equatorial",
            "displayName-count-one": "Ekwele da Guiné Equatorial",
            "displayName-count-other": "Ekweles da Guiné Equatorial",
            "symbol": "GQE"
          },
          "GRD": {
            "displayName": "Dracma grego",
            "displayName-count-one": "Dracma grego",
            "displayName-count-other": "Dracmas gregos",
            "symbol": "GRD"
          },
          "GTQ": {
            "displayName": "Quetzal guatemalense",
            "displayName-count-one": "Quetzal guatemalense",
            "displayName-count-other": "Quetzais guatemalenses",
            "symbol": "GTQ",
            "symbol-alt-narrow": "Q"
          },
          "GWE": {
            "displayName": "Escudo da Guiné Portuguesa",
            "displayName-count-one": "Escudo da Guiné Portuguesa",
            "displayName-count-other": "Escudos da Guinéa Portuguesa",
            "symbol": "GWE"
          },
          "GWP": {
            "displayName": "Peso da Guiné-Bissau",
            "displayName-count-one": "Peso de Guiné-Bissau",
            "displayName-count-other": "Pesos de Guiné-Bissau",
            "symbol": "GWP"
          },
          "GYD": {
            "displayName": "Dólar guianense",
            "displayName-count-one": "Dólar guianense",
            "displayName-count-other": "Dólares guianenses",
            "symbol": "GYD",
            "symbol-alt-narrow": "$"
          },
          "HKD": {
            "displayName": "Dólar de Hong Kong",
            "displayName-count-one": "Dólar de Hong Kong",
            "displayName-count-other": "Dólares de Hong Kong",
            "symbol": "HK$",
            "symbol-alt-narrow": "$"
          },
          "HNL": {
            "displayName": "Lempira hondurenha",
            "displayName-count-one": "Lempira hondurenha",
            "displayName-count-other": "Lempiras hondurenhas",
            "symbol": "HNL",
            "symbol-alt-narrow": "L"
          },
          "HRD": {
            "displayName": "Dinar croata",
            "displayName-count-one": "Dinar croata",
            "displayName-count-other": "Dinares croatas",
            "symbol": "HRD"
          },
          "HRK": {
            "displayName": "Kuna croata",
            "displayName-count-one": "Kuna croata",
            "displayName-count-other": "Kunas croatas",
            "symbol": "HRK",
            "symbol-alt-narrow": "kn"
          },
          "HTG": {
            "displayName": "Gourde haitiano",
            "displayName-count-one": "Gourde haitiano",
            "displayName-count-other": "Gourdes haitianos",
            "symbol": "HTG"
          },
          "HUF": {
            "displayName": "Forint húngaro",
            "displayName-count-one": "Forint húngaro",
            "displayName-count-other": "Forints húngaros",
            "symbol": "HUF",
            "symbol-alt-narrow": "Ft"
          },
          "IDR": {
            "displayName": "Rupia indonésia",
            "displayName-count-one": "Rupia indonésia",
            "displayName-count-other": "Rupias indonésias",
            "symbol": "IDR",
            "symbol-alt-narrow": "Rp"
          },
          "IEP": {
            "displayName": "Libra irlandesa",
            "displayName-count-one": "Libra irlandesa",
            "displayName-count-other": "Libras irlandesas",
            "symbol": "IEP"
          },
          "ILP": {
            "displayName": "Libra israelita",
            "displayName-count-one": "Libra israelita",
            "displayName-count-other": "Libras israelitas",
            "symbol": "ILP"
          },
          "ILR": {
            "displayName": "Sheqel antigo israelita",
            "displayName-count-one": "Sheqel antigo israelita",
            "displayName-count-other": "Sheqels antigos israelitas"
          },
          "ILS": {
            "displayName": "Sheqel novo israelita",
            "displayName-count-one": "Sheqel novo israelita",
            "displayName-count-other": "Sheqels novos israelita",
            "symbol": "₪",
            "symbol-alt-narrow": "₪"
          },
          "INR": {
            "displayName": "Rupia indiana",
            "displayName-count-one": "Rupia indiana",
            "displayName-count-other": "Rupias indianas",
            "symbol": "₹",
            "symbol-alt-narrow": "₹"
          },
          "IQD": {
            "displayName": "Dinar iraquiano",
            "displayName-count-one": "Dinar iraquiano",
            "displayName-count-other": "Dinares iraquianos",
            "symbol": "IQD"
          },
          "IRR": {
            "displayName": "Rial iraniano",
            "displayName-count-one": "Rial iraniano",
            "displayName-count-other": "Riales iranianos",
            "symbol": "IRR"
          },
          "ISJ": {
            "displayName": "Coroa antiga islandesa",
            "displayName-count-one": "Coroa antiga islandesa",
            "displayName-count-other": "Coroas antigas islandesas"
          },
          "ISK": {
            "displayName": "Coroa islandesa",
            "displayName-count-one": "Coroa islandesa",
            "displayName-count-other": "Coroas islandesas",
            "symbol": "ISK",
            "symbol-alt-narrow": "kr"
          },
          "ITL": {
            "displayName": "Lira italiana",
            "displayName-count-one": "Lira italiana",
            "displayName-count-other": "Liras italianas",
            "symbol": "ITL"
          },
          "JMD": {
            "displayName": "Dólar jamaicano",
            "displayName-count-one": "Dólar jamaicano",
            "displayName-count-other": "Dólares jamaicanos",
            "symbol": "JMD",
            "symbol-alt-narrow": "$"
          },
          "JOD": {
            "displayName": "Dinar jordaniano",
            "displayName-count-one": "Dinar jordaniano",
            "displayName-count-other": "Dinares jordanianos",
            "symbol": "JOD"
          },
          "JPY": {
            "displayName": "Iene japonês",
            "displayName-count-one": "Iene japonês",
            "displayName-count-other": "Ienes japoneses",
            "symbol": "JP¥",
            "symbol-alt-narrow": "¥"
          },
          "KES": {
            "displayName": "Xelim queniano",
            "displayName-count-one": "Xelim queniano",
            "displayName-count-other": "Xelins quenianos",
            "symbol": "KES"
          },
          "KGS": {
            "displayName": "Som quirguiz",
            "displayName-count-one": "Som quirguiz",
            "displayName-count-other": "Sons quirguizes",
            "symbol": "KGS"
          },
          "KHR": {
            "displayName": "Riel cambojano",
            "displayName-count-one": "Riel cambojano",
            "displayName-count-other": "Rieles cambojanos",
            "symbol": "KHR",
            "symbol-alt-narrow": "៛"
          },
          "KMF": {
            "displayName": "Franco comorense",
            "displayName-count-one": "Franco comorense",
            "displayName-count-other": "Francos comorenses",
            "symbol": "KMF",
            "symbol-alt-narrow": "CF"
          },
          "KPW": {
            "displayName": "Won norte-coreano",
            "displayName-count-one": "Won norte-coreano",
            "displayName-count-other": "Wons norte-coreanos",
            "symbol": "KPW",
            "symbol-alt-narrow": "₩"
          },
          "KRH": {
            "displayName": "Hwan da Coreia do Sul (1953–1962)",
            "displayName-count-one": "Hwan da Coreia do Sul",
            "displayName-count-other": "Hwans da Coreia do Sul",
            "symbol": "KRH"
          },
          "KRO": {
            "displayName": "Won da Coreia do Sul (1945–1953)",
            "displayName-count-one": "Won antigo da Coreia do Sul",
            "displayName-count-other": "Wons antigos da Coreia do Sul",
            "symbol": "KRO"
          },
          "KRW": {
            "displayName": "Won sul-coreano",
            "displayName-count-one": "Won sul-coreano",
            "displayName-count-other": "Wons sul-coreanos",
            "symbol": "₩",
            "symbol-alt-narrow": "₩"
          },
          "KWD": {
            "displayName": "Dinar kuwaitiano",
            "displayName-count-one": "Dinar kuwaitiano",
            "displayName-count-other": "Dinares kuwaitianos",
            "symbol": "KWD"
          },
          "KYD": {
            "displayName": "Dólar das Ilhas Caiman",
            "displayName-count-one": "Dólar das Ilhas Caiman",
            "displayName-count-other": "Dólares das Ilhas Caiman",
            "symbol": "KYD",
            "symbol-alt-narrow": "$"
          },
          "KZT": {
            "displayName": "Tenge cazaque",
            "displayName-count-one": "Tenge cazaque",
            "displayName-count-other": "Tenges cazaques",
            "symbol": "KZT",
            "symbol-alt-narrow": "₸"
          },
          "LAK": {
            "displayName": "Kip laosiano",
            "displayName-count-one": "Kip laosiano",
            "displayName-count-other": "Kips laosianos",
            "symbol": "LAK",
            "symbol-alt-narrow": "₭"
          },
          "LBP": {
            "displayName": "Libra libanesa",
            "displayName-count-one": "Libra libanesa",
            "displayName-count-other": "Libras libanesas",
            "symbol": "LBP",
            "symbol-alt-narrow": "L£"
          },
          "LKR": {
            "displayName": "Rupia ceilandesa",
            "displayName-count-one": "Rupia ceilandesa",
            "displayName-count-other": "Rupias ceilandesas",
            "symbol": "LKR",
            "symbol-alt-narrow": "Rs"
          },
          "LRD": {
            "displayName": "Dólar liberiano",
            "displayName-count-one": "Dólar liberiano",
            "displayName-count-other": "Dólares liberianos",
            "symbol": "LRD",
            "symbol-alt-narrow": "$"
          },
          "LSL": {
            "displayName": "Loti do Lesoto",
            "displayName-count-one": "Loti do Lesoto",
            "displayName-count-other": "Lotis do Lesoto",
            "symbol": "LSL"
          },
          "LTL": {
            "displayName": "Litas lituano",
            "displayName-count-one": "Litas lituano",
            "displayName-count-other": "Litai lituanos",
            "symbol": "LTL",
            "symbol-alt-narrow": "Lt"
          },
          "LTT": {
            "displayName": "Talonas lituano",
            "displayName-count-one": "Talonas lituanas",
            "displayName-count-other": "Talonases lituanas",
            "symbol": "LTT"
          },
          "LUC": {
            "displayName": "Franco conversível de Luxemburgo",
            "displayName-count-one": "Franco conversível de Luxemburgo",
            "displayName-count-other": "Francos conversíveis de Luxemburgo",
            "symbol": "LUC"
          },
          "LUF": {
            "displayName": "Franco luxemburguês",
            "displayName-count-one": "Franco de Luxemburgo",
            "displayName-count-other": "Francos de Luxemburgo",
            "symbol": "LUF"
          },
          "LUL": {
            "displayName": "Franco financeiro de Luxemburgo",
            "displayName-count-one": "Franco financeiro de Luxemburgo",
            "displayName-count-other": "Francos financeiros de Luxemburgo",
            "symbol": "LUL"
          },
          "LVL": {
            "displayName": "Lats letão",
            "displayName-count-one": "Lats letão",
            "displayName-count-other": "Lati letões",
            "symbol": "LVL",
            "symbol-alt-narrow": "Ls"
          },
          "LVR": {
            "displayName": "Rublo letão",
            "displayName-count-one": "Rublo da Letônia",
            "displayName-count-other": "Rublos da Letônia",
            "symbol": "LVR"
          },
          "LYD": {
            "displayName": "Dinar líbio",
            "displayName-count-one": "Dinar líbio",
            "displayName-count-other": "Dinares líbios",
            "symbol": "LYD"
          },
          "MAD": {
            "displayName": "Dirrã marroquino",
            "displayName-count-one": "Dirrã marroquino",
            "displayName-count-other": "Dirrãs marroquinos",
            "symbol": "MAD"
          },
          "MAF": {
            "displayName": "Franco marroquino",
            "displayName-count-one": "Franco marroquino",
            "displayName-count-other": "Francos marroquinos",
            "symbol": "MAF"
          },
          "MCF": {
            "displayName": "Franco monegasco",
            "displayName-count-one": "Franco monegasco",
            "displayName-count-other": "Francos monegascos",
            "symbol": "MCF"
          },
          "MDC": {
            "displayName": "Cupon moldávio",
            "displayName-count-one": "Cupon moldávio",
            "displayName-count-other": "Cupon moldávio",
            "symbol": "MDC"
          },
          "MDL": {
            "displayName": "Leu moldávio",
            "displayName-count-one": "Leu moldávio",
            "displayName-count-other": "Leus moldávios",
            "symbol": "MDL"
          },
          "MGA": {
            "displayName": "Ariary malgaxe",
            "displayName-count-one": "Ariary malgaxe",
            "displayName-count-other": "Ariarys malgaxes",
            "symbol": "MGA",
            "symbol-alt-narrow": "Ar"
          },
          "MGF": {
            "displayName": "Franco de Madagascar",
            "displayName-count-one": "Franco de Madagascar",
            "displayName-count-other": "Francos de Madagascar",
            "symbol": "MGF"
          },
          "MKD": {
            "displayName": "Dinar macedônio",
            "displayName-count-one": "Dinar macedônio",
            "displayName-count-other": "Dinares macedônios",
            "symbol": "MKD"
          },
          "MKN": {
            "displayName": "Dinar macedônio (1992–1993)",
            "displayName-count-one": "Dinar macedônio (1992–1993)",
            "displayName-count-other": "Dinares macedônios (1992–1993)",
            "symbol": "MKN"
          },
          "MLF": {
            "displayName": "Franco de Mali",
            "displayName-count-one": "Franco de Mali",
            "displayName-count-other": "Francos de Mali",
            "symbol": "MLF"
          },
          "MMK": {
            "displayName": "Kyat mianmarense",
            "displayName-count-one": "Kyat mianmarense",
            "displayName-count-other": "Kyats mianmarenses",
            "symbol": "MMK",
            "symbol-alt-narrow": "K"
          },
          "MNT": {
            "displayName": "Tugrik mongol",
            "displayName-count-one": "Tugrik mongol",
            "displayName-count-other": "Tugriks mongóis",
            "symbol": "MNT",
            "symbol-alt-narrow": "₮"
          },
          "MOP": {
            "displayName": "Pataca macaense",
            "displayName-count-one": "Pataca macaense",
            "displayName-count-other": "Patacas macaenses",
            "symbol": "MOP"
          },
          "MRO": {
            "displayName": "Ouguiya mauritana",
            "displayName-count-one": "Ouguiya mauritana",
            "displayName-count-other": "Ouguiyas mauritanas",
            "symbol": "MRO"
          },
          "MTL": {
            "displayName": "Lira maltesa",
            "displayName-count-one": "Lira Maltesa",
            "displayName-count-other": "Liras maltesas",
            "symbol": "MTL"
          },
          "MTP": {
            "displayName": "Libra maltesa",
            "displayName-count-one": "Libra maltesa",
            "displayName-count-other": "Libras maltesas",
            "symbol": "MTP"
          },
          "MUR": {
            "displayName": "Rupia mauriciana",
            "displayName-count-one": "Rupia mauriciana",
            "displayName-count-other": "Rupias mauricianas",
            "symbol": "MUR",
            "symbol-alt-narrow": "Rs"
          },
          "MVR": {
            "displayName": "Rupia maldiva",
            "displayName-count-one": "Rupia maldiva",
            "displayName-count-other": "Rupias maldivas",
            "symbol": "MVR"
          },
          "MWK": {
            "displayName": "Kwacha malawiana",
            "displayName-count-one": "Kwacha malawiana",
            "displayName-count-other": "Kwachas malawianas",
            "symbol": "MWK"
          },
          "MXN": {
            "displayName": "Peso mexicano",
            "displayName-count-one": "Peso mexicano",
            "displayName-count-other": "Pesos mexicanos",
            "symbol": "MX$",
            "symbol-alt-narrow": "$"
          },
          "MXP": {
            "displayName": "Peso Prata mexicano (1861–1992)",
            "displayName-count-one": "Peso de prata mexicano (1861–1992)",
            "displayName-count-other": "Pesos de prata mexicanos (1861–1992)",
            "symbol": "MXP"
          },
          "MXV": {
            "displayName": "Unidade Mexicana de Investimento (UDI)",
            "displayName-count-one": "Unidade de investimento mexicana (UDI)",
            "displayName-count-other": "Unidades de investimento mexicanas (UDI)",
            "symbol": "MXV"
          },
          "MYR": {
            "displayName": "Ringgit malaio",
            "displayName-count-one": "Ringgit malaio",
            "displayName-count-other": "Ringgits malaios",
            "symbol": "MYR",
            "symbol-alt-narrow": "RM"
          },
          "MZE": {
            "displayName": "Escudo de Moçambique",
            "displayName-count-one": "Escudo de Moçambique",
            "displayName-count-other": "Escudos de Moçambique",
            "symbol": "MZE"
          },
          "MZM": {
            "displayName": "Metical de Moçambique (1980–2006)",
            "displayName-count-one": "Metical antigo de Moçambique",
            "displayName-count-other": "Meticales antigos de Moçambique",
            "symbol": "MZM"
          },
          "MZN": {
            "displayName": "Metical moçambicano",
            "displayName-count-one": "Metical moçambicano",
            "displayName-count-other": "Meticales moçambicanos",
            "symbol": "MZN"
          },
          "NAD": {
            "displayName": "Dólar namibiano",
            "displayName-count-one": "Dólar namibiano",
            "displayName-count-other": "Dólares namibianos",
            "symbol": "NAD",
            "symbol-alt-narrow": "$"
          },
          "NGN": {
            "displayName": "Naira nigeriana",
            "displayName-count-one": "Naira nigeriana",
            "displayName-count-other": "Nairas nigerianas",
            "symbol": "NGN",
            "symbol-alt-narrow": "₦"
          },
          "NIC": {
            "displayName": "Córdoba nicaraguense (1988–1991)",
            "displayName-count-one": "Córdoba nicaraguense (1988–1991)",
            "displayName-count-other": "Córdobas nicaraguense (1988–1991)",
            "symbol": "NIC"
          },
          "NIO": {
            "displayName": "Córdoba nicaraguense",
            "displayName-count-one": "Córdoba nicaraguense",
            "displayName-count-other": "Córdobas nicaraguenses",
            "symbol": "NIO",
            "symbol-alt-narrow": "C$"
          },
          "NLG": {
            "displayName": "Florim holandês",
            "displayName-count-one": "Florim holandês",
            "displayName-count-other": "Florins holandeses",
            "symbol": "NLG"
          },
          "NOK": {
            "displayName": "Coroa norueguesa",
            "displayName-count-one": "Coroa norueguesa",
            "displayName-count-other": "Coroas norueguesas",
            "symbol": "NOK",
            "symbol-alt-narrow": "kr"
          },
          "NPR": {
            "displayName": "Rupia nepalesa",
            "displayName-count-one": "Rupia nepalesa",
            "displayName-count-other": "Rupias nepalesas",
            "symbol": "NPR",
            "symbol-alt-narrow": "Rs"
          },
          "NZD": {
            "displayName": "Dólar neozelandês",
            "displayName-count-one": "Dólar neozelandês",
            "displayName-count-other": "Dólares neozelandeses",
            "symbol": "NZ$",
            "symbol-alt-narrow": "$"
          },
          "OMR": {
            "displayName": "Rial omanense",
            "displayName-count-one": "Rial omanense",
            "displayName-count-other": "Riales omanenses",
            "symbol": "OMR"
          },
          "PAB": {
            "displayName": "Balboa panamenha",
            "displayName-count-one": "Balboa panamenha",
            "displayName-count-other": "Balboas panamenhas",
            "symbol": "PAB"
          },
          "PEI": {
            "displayName": "Inti peruano",
            "displayName-count-one": "Inti peruano",
            "displayName-count-other": "Intis peruanos",
            "symbol": "PEI"
          },
          "PEN": {
            "displayName": "Sol peruano",
            "displayName-count-one": "Sol peruano",
            "displayName-count-other": "Sóis peruanos",
            "symbol": "PEN"
          },
          "PES": {
            "displayName": "Sol peruano (1863–1965)",
            "displayName-count-one": "Sol peruano (1863–1965)",
            "displayName-count-other": "Sóis peruanos (1863–1965)",
            "symbol": "PES"
          },
          "PGK": {
            "displayName": "Kina papuásia",
            "displayName-count-one": "Kina papuásia",
            "displayName-count-other": "Kinas papuásias",
            "symbol": "PGK"
          },
          "PHP": {
            "displayName": "Peso filipino",
            "displayName-count-one": "Peso filipino",
            "displayName-count-other": "Pesos filipinos",
            "symbol": "PHP",
            "symbol-alt-narrow": "₱"
          },
          "PKR": {
            "displayName": "Rupia paquistanesa",
            "displayName-count-one": "Rupia paquistanesa",
            "displayName-count-other": "Rupias paquistanesas",
            "symbol": "PKR",
            "symbol-alt-narrow": "Rs"
          },
          "PLN": {
            "displayName": "Zloti polonês",
            "displayName-count-one": "Zloti polonês",
            "displayName-count-other": "Zlotis poloneses",
            "symbol": "PLN",
            "symbol-alt-narrow": "zł"
          },
          "PLZ": {
            "displayName": "Zloti polonês (1950–1995)",
            "displayName-count-one": "Zloti polonês (1950–1995)",
            "displayName-count-other": "Zlotis poloneses (1950–1995)",
            "symbol": "PLZ"
          },
          "PTE": {
            "displayName": "Escudo português",
            "displayName-count-one": "Escudo português",
            "displayName-count-other": "Escudos portugueses",
            "symbol": "Esc."
          },
          "PYG": {
            "displayName": "Guarani paraguaio",
            "displayName-count-one": "Guarani paraguaio",
            "displayName-count-other": "Guaranis paraguaios",
            "symbol": "PYG",
            "symbol-alt-narrow": "₲"
          },
          "QAR": {
            "displayName": "Rial catariano",
            "displayName-count-one": "Rial catariano",
            "displayName-count-other": "Riales catarianos",
            "symbol": "QAR"
          },
          "RHD": {
            "displayName": "Dólar rodesiano",
            "displayName-count-one": "Dólar da Rodésia",
            "displayName-count-other": "Dólares da Rodésia",
            "symbol": "RHD"
          },
          "ROL": {
            "displayName": "Leu romeno (1952–2006)",
            "displayName-count-one": "Leu antigo da Romênia",
            "displayName-count-other": "Leus antigos da Romênia",
            "symbol": "ROL"
          },
          "RON": {
            "displayName": "Leu romeno",
            "displayName-count-one": "Leu romeno",
            "displayName-count-other": "Leus romenos",
            "symbol": "RON",
            "symbol-alt-narrow": "L"
          },
          "RSD": {
            "displayName": "Dinar sérvio",
            "displayName-count-one": "Dinar sérvio",
            "displayName-count-other": "Dinares sérvios",
            "symbol": "RSD"
          },
          "RUB": {
            "displayName": "Rublo russo",
            "displayName-count-one": "Rublo russo",
            "displayName-count-other": "Rublos russos",
            "symbol": "RUB",
            "symbol-alt-narrow": "₽"
          },
          "RUR": {
            "displayName": "Rublo russo (1991–1998)",
            "displayName-count-one": "Rublo russo (1991–1998)",
            "displayName-count-other": "Rublos russos (1991–1998)",
            "symbol": "RUR",
            "symbol-alt-narrow": "р."
          },
          "RWF": {
            "displayName": "Franco ruandês",
            "displayName-count-one": "Franco ruandês",
            "displayName-count-other": "Francos ruandeses",
            "symbol": "RWF",
            "symbol-alt-narrow": "RF"
          },
          "SAR": {
            "displayName": "Riyal saudita",
            "displayName-count-one": "Riyal saudita",
            "displayName-count-other": "Riyales sauditas",
            "symbol": "SAR"
          },
          "SBD": {
            "displayName": "Dólar das Ilhas Salomão",
            "displayName-count-one": "Dólar das Ilhas Salomão",
            "displayName-count-other": "Dólares das Ilhas Salomão",
            "symbol": "SBD",
            "symbol-alt-narrow": "$"
          },
          "SCR": {
            "displayName": "Rupia seichelense",
            "displayName-count-one": "Rupia seichelense",
            "displayName-count-other": "Rupias seichelenses",
            "symbol": "SCR"
          },
          "SDD": {
            "displayName": "Dinar sudanês (1992–2007)",
            "displayName-count-one": "Dinar antigo do Sudão",
            "displayName-count-other": "Dinares antigos do Sudão",
            "symbol": "SDD"
          },
          "SDG": {
            "displayName": "Libra sudanesa",
            "displayName-count-one": "Libra sudanesa",
            "displayName-count-other": "Libras sudanesas",
            "symbol": "SDG"
          },
          "SDP": {
            "displayName": "Libra sudanesa (1957–1998)",
            "displayName-count-one": "Libra antiga sudanesa",
            "displayName-count-other": "Libras antigas sudanesas",
            "symbol": "SDP"
          },
          "SEK": {
            "displayName": "Coroa sueca",
            "displayName-count-one": "Coroa sueca",
            "displayName-count-other": "Coroas suecas",
            "symbol": "SEK",
            "symbol-alt-narrow": "kr"
          },
          "SGD": {
            "displayName": "Dólar singapuriano",
            "displayName-count-one": "Dólar singapuriano",
            "displayName-count-other": "Dólares singapurianos",
            "symbol": "SGD",
            "symbol-alt-narrow": "$"
          },
          "SHP": {
            "displayName": "Libra de Santa Helena",
            "displayName-count-one": "Libra de Santa Helena",
            "displayName-count-other": "Libras de Santa Helena",
            "symbol": "SHP",
            "symbol-alt-narrow": "£"
          },
          "SIT": {
            "displayName": "Tolar Bons esloveno",
            "displayName-count-one": "Tolar da Eslovênia",
            "displayName-count-other": "Tolares da Eslovênia",
            "symbol": "SIT"
          },
          "SKK": {
            "displayName": "Coroa eslovaca",
            "displayName-count-one": "Coroa eslovaca",
            "displayName-count-other": "Coroas eslovacas",
            "symbol": "SKK"
          },
          "SLL": {
            "displayName": "Leone de Serra Leoa",
            "displayName-count-one": "Leone de Serra Leoa",
            "displayName-count-other": "Leones de Serra Leoa",
            "symbol": "SLL"
          },
          "SOS": {
            "displayName": "Xelim somaliano",
            "displayName-count-one": "Xelim somaliano",
            "displayName-count-other": "Xelins somalianos",
            "symbol": "SOS"
          },
          "SRD": {
            "displayName": "Dólar surinamês",
            "displayName-count-one": "Dólar surinamês",
            "displayName-count-other": "Dólares surinameses",
            "symbol": "SRD",
            "symbol-alt-narrow": "$"
          },
          "SRG": {
            "displayName": "Florim do Suriname",
            "displayName-count-one": "Florim do Suriname",
            "displayName-count-other": "Florins do Suriname",
            "symbol": "SRG"
          },
          "SSP": {
            "displayName": "Libra sul-sudanesa",
            "displayName-count-one": "Libra sul-sudanesa",
            "displayName-count-other": "Libras sul-sudanesas",
            "symbol": "SSP",
            "symbol-alt-narrow": "£"
          },
          "STD": {
            "displayName": "Dobra de São Tomé e Príncipe",
            "displayName-count-one": "Dobra de São Tomé e Príncipe",
            "displayName-count-other": "Dobras de São Tomé e Príncipe",
            "symbol": "STD",
            "symbol-alt-narrow": "Db"
          },
          "SUR": {
            "displayName": "Rublo soviético",
            "displayName-count-one": "Rublo soviético",
            "displayName-count-other": "Rublos soviéticos",
            "symbol": "SUR"
          },
          "SVC": {
            "displayName": "Colom salvadorenho",
            "displayName-count-one": "Colon de El Salvador",
            "displayName-count-other": "Colons de El Salvador",
            "symbol": "SVC"
          },
          "SYP": {
            "displayName": "Libra síria",
            "displayName-count-one": "Libra síria",
            "displayName-count-other": "Libras sírias",
            "symbol": "SYP",
            "symbol-alt-narrow": "£"
          },
          "SZL": {
            "displayName": "Lilangeni suazi",
            "displayName-count-one": "Lilangeni suazi",
            "displayName-count-other": "Lilangenis suazis",
            "symbol": "SZL"
          },
          "THB": {
            "displayName": "Baht tailandês",
            "displayName-count-one": "Baht tailandês",
            "displayName-count-other": "Bahts tailandeses",
            "symbol": "฿",
            "symbol-alt-narrow": "฿"
          },
          "TJR": {
            "displayName": "Rublo do Tadjiquistão",
            "displayName-count-one": "Rublo do Tajaquistão",
            "displayName-count-other": "Rublos do Tajaquistão",
            "symbol": "TJR"
          },
          "TJS": {
            "displayName": "Somoni tadjique",
            "displayName-count-one": "Somoni tadjique",
            "displayName-count-other": "Somonis tadjiques",
            "symbol": "TJS"
          },
          "TMM": {
            "displayName": "Manat do Turcomenistão (1993–2009)",
            "displayName-count-one": "Manat do Turcomenistão (1993–2009)",
            "displayName-count-other": "Manats do Turcomenistão (1993–2009)",
            "symbol": "TMM"
          },
          "TMT": {
            "displayName": "Manat turcomeno",
            "displayName-count-one": "Manat turcomeno",
            "displayName-count-other": "Manats turcomenos",
            "symbol": "TMT"
          },
          "TND": {
            "displayName": "Dinar tunisiano",
            "displayName-count-one": "Dinar tunisiano",
            "displayName-count-other": "Dinares tunisianos",
            "symbol": "TND"
          },
          "TOP": {
            "displayName": "Paʻanga tonganesa",
            "displayName-count-one": "Paʻanga tonganesa",
            "displayName-count-other": "Paʻangas tonganesas",
            "symbol": "TOP",
            "symbol-alt-narrow": "T$"
          },
          "TPE": {
            "displayName": "Escudo timorense",
            "displayName-count-one": "Escudo do Timor",
            "displayName-count-other": "Escudos do Timor",
            "symbol": "TPE"
          },
          "TRL": {
            "displayName": "Lira turca (1922–2005)",
            "displayName-count-one": "Lira turca antiga",
            "displayName-count-other": "Liras turcas antigas",
            "symbol": "TRL"
          },
          "TRY": {
            "displayName": "Lira turca",
            "displayName-count-one": "Lira turca",
            "displayName-count-other": "Liras turcas",
            "symbol": "TRY",
            "symbol-alt-narrow": "₺",
            "symbol-alt-variant": "TL"
          },
          "TTD": {
            "displayName": "Dólar de Trinidad e Tobago",
            "displayName-count-one": "Dólar de Trinidad e Tobago",
            "displayName-count-other": "Dólares de Trinidad e Tobago",
            "symbol": "TTD",
            "symbol-alt-narrow": "$"
          },
          "TWD": {
            "displayName": "Novo dólar taiwanês",
            "displayName-count-one": "Novo dólar taiwanês",
            "displayName-count-other": "Novos dólares taiwaneses",
            "symbol": "NT$",
            "symbol-alt-narrow": "NT$"
          },
          "TZS": {
            "displayName": "Xelim tanzaniano",
            "displayName-count-one": "Xelim tanzaniano",
            "displayName-count-other": "Xelins tanzanianos",
            "symbol": "TZS"
          },
          "UAH": {
            "displayName": "Hryvnia ucraniano",
            "displayName-count-one": "Hryvnia ucraniano",
            "displayName-count-other": "Hryvnias ucranianos",
            "symbol": "UAH",
            "symbol-alt-narrow": "₴"
          },
          "UAK": {
            "displayName": "Karbovanetz ucraniano",
            "displayName-count-one": "Karbovanetz da Ucrânia",
            "displayName-count-other": "Karbovanetzs da Ucrânia",
            "symbol": "UAK"
          },
          "UGS": {
            "displayName": "Xelim ugandense (1966–1987)",
            "displayName-count-one": "Shilling de Uganda (1966–1987)",
            "displayName-count-other": "Shillings de Uganda (1966–1987)",
            "symbol": "UGS"
          },
          "UGX": {
            "displayName": "Xelim ugandense",
            "displayName-count-one": "Xelim ugandense",
            "displayName-count-other": "Xelins ugandenses",
            "symbol": "UGX"
          },
          "USD": {
            "displayName": "Dólar americano",
            "displayName-count-one": "Dólar americano",
            "displayName-count-other": "Dólares americanos",
            "symbol": "US$",
            "symbol-alt-narrow": "$"
          },
          "USN": {
            "displayName": "Dólar norte-americano (Dia seguinte)",
            "displayName-count-one": "Dólar americano (dia seguinte)",
            "displayName-count-other": "Dólares americanos (dia seguinte)",
            "symbol": "USN"
          },
          "USS": {
            "displayName": "Dólar norte-americano (Mesmo dia)",
            "displayName-count-one": "Dólar americano (mesmo dia)",
            "displayName-count-other": "Dólares americanos (mesmo dia)",
            "symbol": "USS"
          },
          "UYI": {
            "displayName": "Peso uruguaio en unidades indexadas",
            "displayName-count-one": "Peso uruguaio em unidades indexadas",
            "displayName-count-other": "Pesos uruguaios em unidades indexadas",
            "symbol": "UYI"
          },
          "UYP": {
            "displayName": "Peso uruguaio (1975–1993)",
            "displayName-count-one": "Peso uruguaio (1975–1993)",
            "displayName-count-other": "Pesos uruguaios (1975–1993)",
            "symbol": "UYP"
          },
          "UYU": {
            "displayName": "Peso uruguaio",
            "displayName-count-one": "Peso uruguaio",
            "displayName-count-other": "Pesos uruguaios",
            "symbol": "UYU",
            "symbol-alt-narrow": "$"
          },
          "UZS": {
            "displayName": "Som uzbeque",
            "displayName-count-one": "Som uzbeque",
            "displayName-count-other": "Sons uzbeques",
            "symbol": "UZS"
          },
          "VEB": {
            "displayName": "Bolívar venezuelano (1871–2008)",
            "displayName-count-one": "Bolívar venezuelano (1871–2008)",
            "displayName-count-other": "Bolívares venezuelanos (1871–2008)",
            "symbol": "VEB"
          },
          "VEF": {
            "displayName": "Bolívar venezuelano",
            "displayName-count-one": "Bolívar venezuelano",
            "displayName-count-other": "Bolívares venezuelanos",
            "symbol": "VEF",
            "symbol-alt-narrow": "Bs"
          },
          "VND": {
            "displayName": "Dong vietnamita",
            "displayName-count-one": "Dong vietnamita",
            "displayName-count-other": "Dongs vietnamitas",
            "symbol": "₫",
            "symbol-alt-narrow": "₫"
          },
          "VNN": {
            "displayName": "Dong vietnamita (1978–1985)",
            "displayName-count-one": "Dong vietnamita (1978–1985)",
            "displayName-count-other": "Dong vietnamita (1978–1985)",
            "symbol": "VNN"
          },
          "VUV": {
            "displayName": "Vatu vanuatuense",
            "displayName-count-one": "Vatu vanuatuense",
            "displayName-count-other": "Vatus vanuatuenses",
            "symbol": "VUV"
          },
          "WST": {
            "displayName": "Tala samoano",
            "displayName-count-one": "Tala samoano",
            "displayName-count-other": "Talas samoanos",
            "symbol": "WST"
          },
          "XAF": {
            "displayName": "Franco CFA de BEAC",
            "displayName-count-one": "Franco CFA de BEAC",
            "displayName-count-other": "Francos CFA de BEAC",
            "symbol": "FCFA"
          },
          "XAG": {
            "displayName": "Prata",
            "displayName-count-one": "Prata",
            "displayName-count-other": "Pratas",
            "symbol": "XAG"
          },
          "XAU": {
            "displayName": "Ouro",
            "displayName-count-one": "Ouro",
            "displayName-count-other": "Ouros",
            "symbol": "XAU"
          },
          "XBA": {
            "displayName": "Unidade Composta Europeia",
            "displayName-count-one": "Unidade de composição europeia",
            "displayName-count-other": "Unidades de composição europeias",
            "symbol": "XBA"
          },
          "XBB": {
            "displayName": "Unidade Monetária Europeia",
            "displayName-count-one": "Unidade monetária europeia",
            "displayName-count-other": "Unidades monetárias europeias",
            "symbol": "XBB"
          },
          "XBC": {
            "displayName": "Unidade de Conta Europeia (XBC)",
            "displayName-count-one": "Unidade europeia de conta (XBC)",
            "displayName-count-other": "Unidades europeias de conta (XBC)",
            "symbol": "XBC"
          },
          "XBD": {
            "displayName": "Unidade de Conta Europeia (XBD)",
            "displayName-count-one": "Unidade europeia de conta (XBD)",
            "displayName-count-other": "Unidades europeias de conta (XBD)",
            "symbol": "XBD"
          },
          "XCD": {
            "displayName": "Dólar do Caribe Oriental",
            "displayName-count-one": "Dólar do Caribe Oriental",
            "displayName-count-other": "Dólares do Caribe Oriental",
            "symbol": "EC$",
            "symbol-alt-narrow": "$"
          },
          "XDR": {
            "displayName": "Direitos Especiais de Giro",
            "displayName-count-one": "Direitos de desenho especiais",
            "displayName-count-other": "Direitos de desenho especiais",
            "symbol": "XDR"
          },
          "XEU": {
            "displayName": "Unidade de Moeda Europeia",
            "displayName-count-one": "Unidade de moeda europeia",
            "displayName-count-other": "Unidades de moedas europeias",
            "symbol": "XEU"
          },
          "XFO": {
            "displayName": "Franco-ouro francês",
            "displayName-count-one": "Franco de ouro francês",
            "displayName-count-other": "Francos de ouro franceses",
            "symbol": "XFO"
          },
          "XFU": {
            "displayName": "Franco UIC francês",
            "displayName-count-one": "Franco UIC francês",
            "displayName-count-other": "Francos UIC franceses",
            "symbol": "XFU"
          },
          "XOF": {
            "displayName": "Franco CFA de BCEAO",
            "displayName-count-one": "Franco CFA de BCEAO",
            "displayName-count-other": "Francos CFA de BCEAO",
            "symbol": "CFA"
          },
          "XPD": {
            "displayName": "Paládio",
            "displayName-count-one": "Paládio",
            "displayName-count-other": "Paládios",
            "symbol": "XPD"
          },
          "XPF": {
            "displayName": "Franco CFP",
            "displayName-count-one": "Franco CFP",
            "displayName-count-other": "Francos CFP",
            "symbol": "CFPF"
          },
          "XPT": {
            "displayName": "Platina",
            "displayName-count-one": "Platina",
            "displayName-count-other": "Platinas",
            "symbol": "XPT"
          },
          "XRE": {
            "displayName": "Fundos RINET",
            "displayName-count-one": "Fundos RINET",
            "displayName-count-other": "Fundos RINET",
            "symbol": "XRE"
          },
          "XSU": {
            "displayName": "XSU",
            "symbol": "XSU"
          },
          "XTS": {
            "displayName": "Código de Moeda de Teste",
            "displayName-count-one": "Código de moeda de teste",
            "displayName-count-other": "Códigos de moeda de teste",
            "symbol": "XTS"
          },
          "XUA": {
            "displayName": "XUA",
            "symbol": "XUA"
          },
          "XXX": {
            "displayName": "Moeda desconhecida",
            "displayName-count-one": "(moeda desconhecida)",
            "displayName-count-other": "(moedas desconhecidas)",
            "symbol": "XXX"
          },
          "YDD": {
            "displayName": "Dinar iemenita",
            "displayName-count-one": "Dinar do Iêmen",
            "displayName-count-other": "Dinares do Iêmen",
            "symbol": "YDD"
          },
          "YER": {
            "displayName": "Rial iemenita",
            "displayName-count-one": "Rial iemenita",
            "displayName-count-other": "Riales iemenitas",
            "symbol": "YER"
          },
          "YUD": {
            "displayName": "Dinar forte iugoslavo (1966–1990)",
            "displayName-count-one": "Dinar forte iugoslavo",
            "displayName-count-other": "Dinares fortes iugoslavos",
            "symbol": "YUD"
          },
          "YUM": {
            "displayName": "Dinar noviy iugoslavo (1994–2002)",
            "displayName-count-one": "Dinar noviy da Iugoslávia",
            "displayName-count-other": "Dinares noviy da Iugoslávia",
            "symbol": "YUM"
          },
          "YUN": {
            "displayName": "Dinar conversível iugoslavo (1990–1992)",
            "displayName-count-one": "Dinar conversível da Iugoslávia",
            "displayName-count-other": "Dinares conversíveis da Iugoslávia",
            "symbol": "YUN"
          },
          "YUR": {
            "displayName": "Dinar reformado iugoslavo (1992–1993)",
            "displayName-count-one": "Dinar iugoslavo reformado",
            "displayName-count-other": "Dinares iugoslavos reformados",
            "symbol": "YUR"
          },
          "ZAL": {
            "displayName": "Rand sul-africano (financeiro)",
            "displayName-count-one": "Rand da África do Sul (financeiro)",
            "displayName-count-other": "Rands da África do Sul (financeiro)",
            "symbol": "ZAL"
          },
          "ZAR": {
            "displayName": "Rand sul-africano",
            "displayName-count-one": "Rand sul-africano",
            "displayName-count-other": "Rands sul-africanos",
            "symbol": "ZAR",
            "symbol-alt-narrow": "R"
          },
          "ZMK": {
            "displayName": "Cuacha zambiano (1968–2012)",
            "displayName-count-one": "Kwacha da Zâmbia (1968–2012)",
            "displayName-count-other": "Kwachas da Zâmbia (1968–2012)",
            "symbol": "ZMK"
          },
          "ZMW": {
            "displayName": "Kwacha zambiano",
            "displayName-count-one": "Kwacha zambiano",
            "displayName-count-other": "Kwachas zambianos",
            "symbol": "ZMW",
            "symbol-alt-narrow": "ZK"
          },
          "ZRN": {
            "displayName": "Zaire Novo zairense (1993–1998)",
            "displayName-count-one": "Novo zaire do Zaire",
            "displayName-count-other": "Novos zaires do Zaire",
            "symbol": "ZRN"
          },
          "ZRZ": {
            "displayName": "Zaire zairense (1971–1993)",
            "displayName-count-one": "Zaire do Zaire",
            "displayName-count-other": "Zaires do Zaire",
            "symbol": "ZRZ"
          },
          "ZWD": {
            "displayName": "Dólar do Zimbábue (1980–2008)",
            "displayName-count-one": "Dólar do Zimbábue",
            "displayName-count-other": "Dólares do Zimbábue",
            "symbol": "ZWD"
          },
          "ZWL": {
            "displayName": "Dólar do Zimbábue (2009)",
            "displayName-count-one": "Dólar do Zimbábue (2009)",
            "displayName-count-other": "Dólares do Zimbábue (2009)",
            "symbol": "ZWL"
          },
          "ZWR": {
            "displayName": "Dólar do Zimbábue (2008)",
            "displayName-count-one": "Dólar do Zimbábue (2008)",
            "displayName-count-other": "Dólares do Zimbábue (2008)",
            "symbol": "ZWR"
          }
        }
      }
    }
  }
}
