let React = require('react');


 
let Leeg = React.createClass({

  render: function() {
    return ( 
    <div>
    </div>)
  },
});

module.exports = Leeg;






