'use strict'

var SLICE = Array.prototype.slice

module.exports = function(fn, args){

    return function(){
        if (!Array.isArray(args)){
            args = SLICE.call(args || [])
        }

        return fn.apply(this, args)
    }
}