const React = require('react');
const SvgIcon = require('../matui/svg-icon.jsx');
const Paper = require('../matui/paper.jsx');
const Immutable = require('../immutab/dist/immutable');
const DgFunc = require('../datagrid/src/functions.jsx');

const MainMenLabel = React.createClass({
  componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.search)
    {
       if (nextProps.search.length > 0)
       {
          this.handleFilter(nextProps.search) 
       }
       else
       {
          this.setState({aantal:0});
          this.setState({FiltData:[]});
       }
    }
    else
    {
      this.setState({aantal:0});
      this.setState({FiltData:[]});
    }

    if (nextProps.data)
    {
      if (this.state.url != nextProps.data.url )
      {
        this.setState({url: nextProps.data.url});
        this.loadData(nextProps.data.url,nextProps.data.key) 
      }
    }
  },
  componentDidMount() {
    if (this.props.data)
    {
        if (this.state.url != this.props.data.url)
        {
          this.state.url = this.props.data.url;
          this.loadData(this.props.data.url,this.props.data.key) 
        }
    }
  },
  getInitialState () {
    return {
      iconcolor:'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.9)) rgba(0, 143, 183,.24)',
      image:"",
      label:'',
      aantal:0,
      url:'',
      OrgData:[],
      ColFilter:[],
      FiltData:[],
    };
  },
  render() {
    let {
      style,
      label,
      image,
      aantal,
      vierkant,
      zoek,
      id,
      ...other,
    } = this.props;

    let cirkel = (vierkant) ? false : true;


    /* margin-top: 25%; */
    /* margin-left: -30%; */



    let tel_style = (this.state.aantal > 0) ? {
      backgroundColor: '#f44336',
      display: 'inline-block',
      position: 'absolute',
      marginTop: '-20px',
      marginRight: '0px',
      width: '30px',
      height: '30px',
      padding: '5px',
      textAlign: 'center',
      color: 'white'}:{};    

    let tel_style2 = (this.state.aantal > 0) ? {
      fontSize:'10px',
      verticalAlign:'middle',
      textAlign: 'center',
      position: 'relative',
      color: 'white'}:{};    


    let teller = (this.state.aantal > 0) ? (
      <Paper
        style={tel_style}
        zDepth={1}
        circle={cirkel}>
        <span style={tel_style2}> {this.state.aantal}</span> </Paper>
    ) : null;


    let inhoud = label ? (<div id="cellDiv_0_0" 
              onMouseLeave = {(event)=>this.IconMouseOut(1)} 
              onMouseEnter = {(event)=>this.IconMouseOver(1)} 
              oncontextmenu="event.returnValue = false;" 
              style={{width:'100px',height:'100px'}}>
              <table style={{width: '100%', height: '100%', textAlign:'center',background: this.state.iconcolor, cursor:'pointer'}}
                   id="cellTable_0_1" onClick={(event)=>this.OpenMenu(id)}  class="CellFilled" border="0" cellpadding="0" cellspacing="0">
                <tbody style={{width: '100px', height: '100px', textAlign:'center',boxShadow: 'rgba(0, 0, 0, 0.10) 5px 4px 45px, rgba(0, 0, 0, 0.025) 1px 10px 17px',cursor:'pointer'}}>
                  <tr class="AlertText">
                    <td class="AlertText"></td>
                     
                  </tr>
                  <tr class="ButtonImage">
                    <td class="ButtonImage">
                      <img src={image} alt={label} title={label} height="40" width="40"/>
                      {teller}
                    </td>
                  </tr>
                  <tr class="ButtonDesc">
                    <td class="ButtonDesc" align="center">
                      <div id="caption_0_0" class="CellCaption">{label}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>) : 
            (<div id="cellDiv_0_0" 
              onMouseLeave = {(event)=>this.IconMouseOut(1)} 
              onMouseEnter = {(event)=>this.IconMouseOver(1)} 
              oncontextmenu="event.returnValue = false;" 
              style={{width:'100px',height:'100px'}}>
              <table style={{width: '100%', height: '100%', textAlign:'center',background: this.state.iconcolor, cursor:'pointer'}}
                   id="cellTable_0_1" onClick={(event)=>this.OpenMenu(id)}  class="CellFilled" border="0" cellpadding="0" cellspacing="0">
                <tbody style={{width: '100px', height: '100px', textAlign:'center',boxShadow: 'rgba(0, 0, 0, 0.10) 5px 4px 45px, rgba(0, 0, 0, 0.025) 1px 10px 17px',cursor:'pointer'}}>
                  <tr class="AlertText">
                    <td class="AlertText"></td>
                  </tr>
                  <tr class="ButtonImage">
                    <td class="ButtonImage">
                    </td>
                  </tr>
                  <tr class="ButtonDesc">
                    <td class="ButtonDesc" align="center">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>);
    return (
        <td class="CellFilled droppable" style={{width: '100px', height: '100px', textAlign:'center'}}>
          <div style={{marginLeft:'10px',backgroundColor:'rgba(255,255,255,0.6)'}}>
            {inhoud}
          </div>
        </td>
    );
  },
  loadData(url,hdata) {
    let that = this;
    let propContainerStyle = {
      width: '200px',
      overflow: 'hidden',
      margin: '20px auto 0 auto'
    };    
    let vurl = (url.length > 3) ? './server/index.php?function='+url : ''; 
    if (!hdata) {hdata = {param:''}};
    if (vurl.length > 2)
    {
      let jqxhr =  $.ajax({
        url: vurl,
        dataType: 'json',
        type: 'POST',
        data: JSON.stringify(hdata)
        }).always(function(data){
          if (that.isMounted()) 
          {
            that.state.OrgData = data.data;
            that.state.FiltData = [];
            that.setState({colFilter:data.colFilter});
            that.setState({colNum:data.colNum});
            that.setState({dkol:data.kol});
            that.setState({GrData:data.data});
            that.setState({immuData:Immutable.List(data.data)});
            that.setState({rowCount:Immutable.List(data.data).size});



          }
        });
      return;
    }
  },
  handleFilter(value){
    if (this.state.colFilter)
    {
      let fildata = DgFunc.SetFilter(value,this.state.OrgData,this.state.colFilter,this.state.colNum);
      this.setState({immuData:Immutable.List(fildata)});
      this.state.FiltData = fildata;
      this.setState({aantal:Immutable.List(fildata).size});
    }
  },

  IconMouseOver(keuze) {
    this.setState({iconcolor:'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.9)) rgb(0, 143, 183)'});
  },
  IconMouseOut(keuze) {
    this.setState({iconcolor:'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.9)) rgba(0, 143, 183,.24)'});
  },
  OpenMenu(keuze) {
    let terug = {
      keuze:keuze,
      aantal:this.state.aantal,
      OrgData:this.state.OrgData,
      colFilter:this.state.colFilter,
      FiltData:this.state.FiltData,
      colNum:this.state.colNum,
      dkol:this.state.dkol
    };
    if (this.props.OpenMenu) {this.props.OpenMenu(terug)};
  },

});

module.exports = MainMenLabel;
