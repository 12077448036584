  const React = require('react');
  const ReactDOM=require('react-dom');
  const {Router, Route, browserHistory, IndexRoute} = require('react-router');
  const AppRoutes = require('./app-routes.jsx');


import localizer from 'react-big-calendar/lib/localizers/globalize';
import globalize from 'globalize';
import "babel";

//globalize.load( require( "cldr-data" ).entireSupplemental() );
//globalize.load( require( "cldr-data" ).entireMainFor( "en", "es" ) );
//globalize.loadTimeZone( require( "iana-tz-data" ) );

globalize.load(
    require( 'cldr-data/main/nl-BE/ca-gregorian' ),
    require( 'cldr-data/main/nl-BE/timeZoneNames' ),
    require( 'cldr-data/main/nl-BE/numbers' ),
    require( 'cldr-data/main/nl-BE/currencies' ),
    require( 'cldr-data/main/pt/ca-gregorian' ),
    require( 'cldr-data/main/pt/timeZoneNames' ),
    require( 'cldr-data/main/pt/numbers' ),
    require( 'cldr-data/main/pt/currencies' ),
    require( 'cldr-data/supplemental/currencyData' ),
    require( 'cldr-data/supplemental/plurals' ),
    require( 'cldr-data/supplemental/likelySubtags' ),
    require( 'cldr-data/supplemental/timeData' ),
    require( 'cldr-data/supplemental/weekData' )
);


globalize.locale("nl-BE");
localizer(globalize);

 
// Initialize Globalize and load your CLDR data
// See https://github.com/jquery/globalize for details on Globalize usage
 

  //const injectTapEventPlugin = require('react-tap-event-plugin');
  $.ajaxSetup({
    type: 'POST',
    contentType: "application/json; charset=utf-8",
    dataType: "json"});


  //Needed for React Developer Tools
  window.React = React;
  window.App = {};
  //Needed for onTouchTap
  //Can go away when react 1.0 release
  //Check this repo:
  //https://github.com/zilverline/react-tap-event-plugin
//  injectTapEventPlugin();
  ReactDOM.render(
    <Router
      history={browserHistory}
      style={{backgroundColor:'#FAFAFA'}} 
      onUpdate={() => window.scrollTo(0, 0)}
    >
    {AppRoutes}
  </Router>
  , document.getElementById('app'));

