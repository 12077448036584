// To include this file in your project:
// let mui = require('mui');
// let Colors = mui.Styles.Colors;
const React = require('react');
const ReactDOM = require('react-dom');
var Popover = require("react-bootstrap").Popover;
const DateTime = require('../matui/utils/date-time');


module.exports = {
 Dat_activity() {
  let activity = { id:0,
            ConsId:0,
            Consnaam:'',
            Type:'',
            TypeTxt:'',
            startdate:'',
            Incidentid:0,
            TikName:'',
            Aanvrid:0,
            Aanvrtxt:'',
            Acountid:0,
            Accountname:'',
            Contid:0,
            ContName:'',
            Origin:'',
            accountid:0,
            contactid:0,
            userid:0,
            cons_id:0,
            request_id:0,
            incidentid:0,
            sms:0,
            uur:'',
            end_time:'',
            date:'',
            end_date:'',
            status:'O',
            statustxt:'Open',
            opmerkingen:'',
            subject:'',
            description:'',
            accountname:'',
            contname:'',
            consname:'',
            username:'',
            reqname:'',   
            tikname:'', };  
   return activity;
 },
 Dat_asset() {
  let asset = { id:0,
            naam:'',
            staat:'',
            assettype:'',
            accountid:0,
            contactid:0,
            product:0,
            contract:0,
            account_name:'',
            typetxt:'',
            contname:'',
            productname:'',
            vorm:'ASS',
            remarks:'',
            serialnum:'',
            partnum:'',
            comptag:'',
            servicetag:'',
            garstart:'',
            garend:'',
            manufacturer:'',
            supplier:'',
            model:'',
            hostname:'',
            expresscode:'',
            plocation:'',
            room:'',
            rack:'',
            rackpos:'',
            privip:'',
            publip:'',
            nic:'',
            switch:'',
            port:'',
            pubdns:'',
            privdns:'',            
            sannas:'',
            drivecount:'',
            os:'',
            osversion:'',
            url:'',
            servicepackos:'', 
            memory:'',
            diskspace:'',
            cpu:'',
            cpunum:'',
            mac:'',
            uuid:'',
            ipadres:'',
            subnetmask:'',
            domain:'',
            firmware:'',
            firmwarev:'',
            poorten:'',
            lickey:'',
            lictype:'',
            installpath:'',
            startlic:'',
            endlic:'',
            installon:'',
            footprint:'',
            stroomverb:'',
            units:'',
            unitsused:'',                        
            linkto:'',
            partof:'',
        };  


   return asset;
 },

Dat_asstype() {
  let asset = { id:0,
            naam:'',
            description:'',
            parent:'',
            type:'',
            server:0,
            network:0,
            hardware:0,
            software:0,
            computer:0,
            cloud:0,
            rack:0,
            accespoint:0,};  
   return asset;
 },






 Dat_contact() {
  let contact = {
        id:0,
        vld1:'',
        vld2:'',
        vorm:'CONT',
        last_name:'',
        first_name:'',
        main_phone:'',
        street:'',
        zipcode_id:0,
        parent_account:0,
        account_name:'',
        zipcode_txt:'',
        Salutation:'',
        Mobile_phone:'',
        E_mail:'',
        Facebook:'',
        Linkedin:'',
        Twitter:'',
        Gender:'',
        Marital_status:'',
        Birthday:'',
        type:'',
        typetxt:'', 
        remarks:'',
        actief:0,
        dateind:'',
        dateuit:'',
        jobtitle:'' 
      };
   return contact;
 },

Dat_doc() {
  let doc = {
    id:0,
    cons_id:'',
    account_id:'',
    tik_id:'',
    cont_id:'',
    asset_id:'',
    datum:DateTime.Get_VandaagTxtDat(),
    omschrijving:'',
    document:'',
    type:''};
    return doc;
},


Dat_request() {
  let request = {
    id:0,
    profile:'',
    req_date:'',
    req_period_start:'',
    req_period_end:'',
    description:'   ',
    tech_skills:'   ',
    pers_skills:'   ',
    account_id:'',
    contact_id:'',
    client_id:0,
    status:'NW',
    statustxt:'Nieuw',
    remarks:'   ',
    location:'',
    tasks:'   ',
    website:'N',
    account_name:'',
    clienttxt:'',
    contname:'',                  
    userid:App.userid,
    vorm:'REQ',
    usertxt:App.username};
    return request;
},

Dat_tik() {
    let hudata = {id:0,
                profile:'',
                  crea_date:'',
                  start_date:'',
                  end_date:'',
                  description:'   ',
                  solution:'   ',
                  pers_skills:'   ',
                  account_id:0,
                  contact_id:0,
                  asset_id:0,
                  status:'NW',
                  statustxt:'Nieuw',
                  remarks:'   ',
                  invoiceid:0,
                  gefact:'N',
                  rate:0,
                  timetefact:0,
                  spendedtime:0,
                  travelcost:0,
                  assetcost:0,
                  account_name:'',
                  contname:'',                  
                  userid:0,
                  vorm:'TIK',
                  usertxt:''};
    return hudata;
},

 Dat_account() {
  let account = {
        id:0,
        adressen:[],
        vld1:'',
        account_name:'',
        account_number:'',
        zipcode_txt:'',
        fact_street:'',
        fact_street2:'', 
        fact_unit:'', 
        fact_vat:'', 
        fact_betaald:0, 
        type:'',
        prim_contact:0,
        par_account:0,
        par_accounttxt:'',
        sectorid:0,
        sectortxt:'',
        owner:0,
        ownertxt:'',
        status:'',
        statustxt:'',
        fact_contact_id:0,
        fact_l_regel:'',
        fact_zipcode_id:0,
        fact_zipcode_txt:'',
        vat_number:'',
        main_phone:'',
        website:'',
        street:'',
        zipcode_id:0,
        email:'',
        email2:'',
        gsm:'',
        gsm2:'',
        fax:'',
        vorm:'ACC',
        zipcode_txt:'',
        btwweerleg:0,
      };                  
   return account;
 },

 Dat_cons() {
  let cons = {
        id:0,
        adressen:[],
        oldact:0,
        oldindienst:'',
        olduitdienst:'',
        vld1:'',
        last_name:'',
        first_name:'',
        main_phone:'',
        city_name:'',
        street:'',
        zipcode_id:0,
        parent_account:0,
        account_name:'',
        zipcode_txt:'',
        Salutation:'',
        Mobile_phone:'',
        E_mail:'',
        Facebook:'',
        Linkedin:'',
        Twitter:'',
        Othersocmed:'',
        Gender:'',
        Marital_status:'',
        Birthday:'',
        type:'',
        typetxt:'', 
        verk_nor:0,
        verk_min:0,
        available:'',
        date_available:'',
        opleiding:'',
        motivering:'',
        orgcv:'',
        asscv:'',
        remarks:'',
        nwfoto:'',
        plaatnr:'',
        wn_nummer:'',
        Rijksregnum:'',
        login:'',
        paswoord:'',
        actief:0,
        dateind:'',
        dateuit:'',
        jobtitle:'',
        vorm:'CONS'
      };                  
   return cons;
 },      

 Dat_contbijl() {
  let contbijl = {id:0, 
            contract_id:0, 
            consultant_id:0, 
            projectleaderid:0,
            contact_id:0,
            client:0, 
            account:0,
            usr_id:0,
            bijlage_num:'',
            description:'',
            remarks:'',
            function:'',
            place:'',
            status:'',
            po_number:'',
            document:' ',
            enddate:'',
            startdate:'',
            rate:0,
            day:'',
            statustxt:'',
            consname:'', 
            projleadername:'', 
            contname:'',
            clienttxt:'',
            accmantxt:'',
            account_id:0};            
   return contbijl;
 },
 Dat_zkcons() {
  let request = {
    id:0,
    profile:'',
    date:'',
    userid:'',
    ctype:'',
    type:'',
    typetxt:'',
    zken:'N',
    zac:'N',
    datact:'',
    finan:'N',
    actief:'N',
    beschikbaar:'N',
    zoektype:'',    
    usertxt:'',
    dataken:[],
    ckeuze:'OR',
    account_id:'',
    eindklant:'',
    plaats:'',
    accountname:'',
    eklantnaam:'',
    am_id:'',
    amnaam:'',
    cons_id:'',
    consnaam:'',
    };
    return request;
},

 Dat_consproj() {
  let request = {
    id:0,
    consid:'',
    accountid:'',
    projfuncid:'',
    startdatum:'',
    einddatum:'',
    accountname:'',
    consname:'',
    proj_title:'',
    proj_function:'',
    proj_situation:'',
    };
    return request;
},


 Cons_param() {
  let consparam = {       
        verlof: 0,
        over_verl: 0,
        adv: 0,
        feestdagen: 0,
        tverlof: 0,
        tadv: 0,
        tover:0 ,
        tfeestdagen: 0,
        vverlof:0,
        vadv:0,
        vfeestdagen:0,
        vover:0};            
   return consparam;
 },

Cons_fin() {
  let consfin = {       
        id:0,
        cons_id:0,
        soll_id:0, 
        datum:'', 
        brutoloon:0, 
        onkosten:0,
        wagenkost:0,
        km_vergoeding:0, 
        verpl_kosten:0,
        diversen:0,
        groepsverz:0,
        hospitalisatie:0,
        groepsverzperc:0,
        opmerking:'',
        resulttxt:'Onderhandeling',
        resultaat:'O',};
   return consfin;
},  
Hfd_contr() {
  let contr = {
        id:0,
        vld1:'',
        accounttxt:'',
        contname:'',
        contname2:'',
        contname3:'',
        account_id:0,
        contact_id:0,
        contact_id2:0,
        contact_id3:0,
        contract_num:'',
        contract_date:'',
        status:'',
        statustxt:''
      };    
   return contr;
},  

 Dat_prod() {
  let prod = { id:0,
            naam:'',
            description:'',
            assettype:'',
            typetxt:'',
            producent:0,
            account_name:'',};  
   return prod;
 },


 ZetApidLeeg(){
    App.KlantId = 0;
    App.KlantNaam = ''; 
    return;
  },  

 
 ColumnResize(naam, firstSize, sleutel, ColFilter){
    var index, column;    
    for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
    {
        let filCol = ColFilter[rowIdx];
        if (filCol.name == naam)
        {
          ColFilter[rowIdx].width = firstSize;
        }
    }    
    this.updategrid(sleutel,ColFilter);
    return ColFilter;
  },  




};


