const React = require('react');
const ReactDOM = require('react-dom');
const ColorManipulator = require('./utils/color-manipulator');
const StylePropable = require('./mixins/style-propable');
const Transitions = require('./styles/transitions');
const UniqueId = require('./utils/unique-id');
const EnhancedTextarea = require('./enhanced-textarea.jsx');
const DefaultRawTheme = require('./styles/raw-themes/light-raw-theme');
const ThemeManager = require('./styles/theme-manager');
const ContextPure = require('./mixins/context-pure');
const Immutable = require('../immutab/dist/immutable');
const ClickAwayable = require('./mixins/click-awayable');
const IconButton = require('./icon-button.jsx');

const Menu = require('./menu/menu.jsx');
const DatePickerDialog = require('./date-picker/date-picker-dialog.jsx');



/**
 * Check if a value is valid to be displayed inside an input.
 *
 * @param The value to check.
 * @returns True if the string provided is valid, false otherwise.
 */
function isValid(value) {
  return value || value === 0;
}

const TextField = React.createClass({
  defaultCharsRules: {
      "9": "[0-9]",
      "a": "[A-Za-z]",
      "*": "[A-Za-z0-9]"
  },
  defaultMaskChar: " ",
  lastCaretPos: null,
  mixins: [
    ContextPure,
    StylePropable,
    ClickAwayable
  ],
  isAndroidBrowser: function () {
    var windows = new RegExp("windows", "i");
    var firefox = new RegExp("firefox", "i");
    var android = new RegExp("android", "i");
    var ua = navigator.userAgent;
    return !windows.test(ua) && !firefox.test(ua) && android.test(ua);
  },
  isWindowsPhoneBrowser: function () {
    var windows = new RegExp("windows", "i");
    var phone = new RegExp("phone", "i");
    var ua = navigator.userAgent;
    return windows.test(ua) && phone.test(ua);
 },
 isAndroidFirefox: function () {
    var windows = new RegExp("windows", "i");
    var firefox = new RegExp("firefox", "i");
    var android = new RegExp("android", "i");
    var ua = navigator.userAgent;
    return !windows.test(ua) && firefox.test(ua) && android.test(ua);
 },

  contextTypes: {
    muiTheme: React.PropTypes.object,
  },

  propTypes: {
    errorStyle: React.PropTypes.object,
    errorText: React.PropTypes.string,
    floatingLabelStyle: React.PropTypes.object,
    floatingLabelText: React.PropTypes.string,
    fullWidth: React.PropTypes.bool,
    hintText: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.element,
    ]),
    hintStyle: React.PropTypes.object,
    id: React.PropTypes.string,
    inputStyle: React.PropTypes.object,
    multiLine: React.PropTypes.bool,
    CalPopup:React.PropTypes.func,
    onBlur: React.PropTypes.func,
    onChange: React.PropTypes.func,
    onEnterKeyDown: React.PropTypes.func,
    onFocus: React.PropTypes.func,
    onKeyDown: React.PropTypes.func,
    rows: React.PropTypes.number,
    rowsMax: React.PropTypes.number,
    type: React.PropTypes.string,
    underlineStyle: React.PropTypes.object,
    underlineFocusStyle: React.PropTypes.object,
    underlineDisabledStyle: React.PropTypes.object,
    autotext: React.PropTypes.bool,
    mask: React.PropTypes.string,

  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
  },

  getChildContext () {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

  getDefaultProps() {
    return {
      fullWidth: false,
      type: 'text',
      rows: 1,
      menuItems:Immutable.List()
    };
  },

  statics: {
    getRelevantContextKeys(muiTheme) {
      const textFieldTheme = muiTheme.textField

      return {
        floatingLabelColor: textFieldTheme.floatingLabelColor,
        focusColor: textFieldTheme.focusColor,
        borderColor: textFieldTheme.borderColor,
        textColor: textFieldTheme.textColor,
        disabledTextColor: textFieldTheme.textColor,
        backgroundColor: textFieldTheme.backgroundColor,
        hintColor: textFieldTheme.hintColor,
        errorColor: textFieldTheme.errorColor,
      };
    },
    getChildrenClasses() {
      return [
        EnhancedTextarea,
      ];
    },
  },

  parseMask: function (mask) {
    var _this5 = this;
    if (!mask || typeof mask !== "string") {
        return {
          mask: null,
          lastEditablePos: null,
          permanents: []
        };
    }
    var str = "";
    var permanents = [];
    var isPermanent = false;
    var lastEditablePos = null;

    mask.split("").forEach(function (character) {
      if (!isPermanent && character === "\\") {
          isPermanent = true;
      } else {
        if (isPermanent || !_this5.charsRules[character]) {
          permanents.push(str.length);
        } else {
          lastEditablePos = str.length + 1;
       }
       str += character;
       isPermanent = false;
     }
   });
   return {
   mask: str,
   lastEditablePos: lastEditablePos,
   permanents: permanents
   };
  },
  getStringValue: function (value) {
    return !value && value !== 0 ? "" : value + "";
  },  
    formatValue: function (value) {
        var _this3 = this;

        var maskChar = this.maskChar;
        var mask = this.mask;

        if (!maskChar) {
            var prefix = this.getPrefix();
            var prefixLen = prefix.length;
            value = this.insertRawSubstr("", value, 0);
            while (value.length > prefixLen && this.isPermanentChar(value.length - 1)) {
                value = value.slice(0, value.length - 1);
            }

            if (value.length < prefixLen) {
                value = prefix;
            }

            return value;
        }
        if (value) {
            var emptyValue = this.formatValue("");
            return this.insertRawSubstr(emptyValue, value, 0);
        }
        return value.split("").concat(this.createFilledArray(mask.length - value.length, null)).map(function (character, pos) {
            if (_this3.isAllowedChar(character, pos)) {
                return character;
            } else if (_this3.isPermanentChar(pos)) {
                return mask[pos];
            }
            return maskChar;
        }).join("");
    },
  getInitialState() {
    let props = (this.props.children) ? this.props.children.props : this.props;

    this.charsRules = "formatChars" in this.props ? this.props.formatChars : this.defaultCharsRules;

    var mask = this.parseMask(this.props.mask);
    var defaultValue = this.props.defaultValue != null ? this.props.defaultValue : '';
    var value = this.props.value != null ? this.props.value : defaultValue;

    value = this.getStringValue(value);
    this.mask = mask.mask;
    this.permanents = mask.permanents;
    this.lastEditablePos = mask.lastEditablePos;
    this.maskChar = this.props.maskChar ? this.props.maskChar : this.defaultMaskChar;

    if (this.mask && (this.props.alwaysShowMask || value)) {
        value = this.formatValue(value);
    }

    return {
      errorText: this.props.errorText,
      open: false,
      rowData: this.props.menuItems,
      Key:this.props.Key,
      mask: this.props.mask,
      type: (this.props.type == 'date' && value.length < 10 && !this.props.floatingLabelText) ? 'text' : this.props.type,
      orgtype:this.props.type,
      date: this.props.defaultDate,
      autoOk: this.props.datclick,
      showYearSelector: true,
      dialogDate: new Date(),      
      menuElement: null,
      autoText:'',
      selectedIndex: 0,
      hasValue: isValid(props.value) || isValid(props.defaultValue) ||
        (props.valueLink && isValid(props.valueLink.value)),
      value: value,
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  componentDidMount() {
    this._uniqueId = UniqueId.generate();
    if (this.props.alwaysShowMask == true)
    {
      this._ChkMask();
    }

    var defaultValue = this.props.defaultValue != null ? this.props.defaultValue : '';
    var value = this.props.value != null ? this.props.value : defaultValue;
    if (this.props.type == 'date' && value.length < 10 && !this.props.floatingLabelText)
    {
      this.setState({type:'text'});
    }
    else
    {
      if (this.props.type == 'date')
      {
        this.setState({type:'date'});
      }
    }
  },
  componentWillMount: function () {
    var mask = this.mask;
//    this.value = this
    var value = this.state.value;
    if (mask && value) {
      this.setState({ value: value });
    }
  },
  componentWillReceiveProps(nextProps, nextContext) {
    let newState = {};
    newState.muiTheme = nextContext.muiTheme ? nextContext.muiTheme : this.state.muiTheme;

    newState.errorText = nextProps.errorText;
    if (nextProps.children && nextProps.children.props) {
      nextProps = nextProps.children.props;
    }

    let hasValueLinkProp = nextProps.hasOwnProperty('valueLink');
    let hasValueProp = nextProps.hasOwnProperty('value');
    let hasNewDefaultValue = nextProps.defaultValue !== this.props.defaultValue;
    let hasNewKey = nextProps.Key !== this.props.Key;

    if (hasValueLinkProp) {
      newState.hasValue = isValid(nextProps.valueLink.value);
    }
    else if (hasValueProp) {
      newState.hasValue = isValid(nextProps.value);
    }
    else if (hasNewDefaultValue) {
      newState.hasValue = isValid(nextProps.defaultValue);
      this.setValue(nextProps.defaultValue);
    }
    if (hasNewKey) {
      newState.Key = isValid(nextProps.Key);
      this.setKey(nextProps.Key);
    }
    var value = nextProps.defaultValue != null ? nextProps.defaultValue : '';
    if (this.props.type == 'date' && value.length < 10 && !this.props.floatingLabelText)
    {
      newState.type ='text';
    }
    else
    {
      if (this.props.type == 'date')
      {
        newState.type ='date';
      }
    }

    if (newState) this.setState(newState);


  },

  componentClickAway() {
    this.setState({open:false});
  },

  getStyles() {
    const spacing = this.state.muiTheme.rawTheme.spacing;
    const props = this.props;
    const {
      floatingLabelColor,
      focusColor,
      borderColor,
      textColor,
      disabledTextColor,
      backgroundColor,
      hintColor,
      errorColor,
    } = this.constructor.getRelevantContextKeys(this.state.muiTheme);

    let vtextColor = (props.textColor) ? props.textColor : textColor;
    let vdisabledTextColor = (props.textColor) ? props.textColor : disabledTextColor;

    let styles = {
      root: {
        fontSize: '90%',
        lineHeight: '24px',
        width: props.fullWidth ? '100%' : 256,
        height: (props.rows - 1) * 24 + (props.floatingLabelText ? 72 : 48),
        display: 'inline-block',
        position: 'relative',
        fontFamily: this.state.muiTheme.rawTheme.fontFamily,
        transition: Transitions.easeOut('200ms', 'height'),
      },
      error: {
        position: 'relative',
        bottom: 5,
        fontSize: '90%',
        lineHeight: '12px',
        color: errorColor,
        transition: Transitions.easeOut(),
      },
      hint: {
        position: 'absolute',
        lineHeight: '22px',
        opacity: 1,
        color: hintColor,
        transition: Transitions.easeOut(),
        top: 12,
      },
      input: {
        tapHighlightColor: 'rgba(0,0,0,0)',
        padding: 0,
        position: 'relative',
        width: '100%',
        height: '100%',
        border: 'none',
        outline: 'none',
        backgroundColor: backgroundColor,
        color: props.disabled ? vdisabledTextColor : vtextColor,
        font: 'inherit',
        fontSize: '90%',
        marginTop:'',
      },
      underline: {
        border: 'none',
        borderBottom: 'solid 1px ' + borderColor,
        position: 'absolute',
        width: '100%',
        bottom: 8,
        margin: 0,
        MozBoxSizing: 'content-box',
        boxSizing: 'content-box',
        height: 0,
      },
      underlineAfter: {
        position: 'absolute',
        width: '100%',
        overflow: 'hidden',
        userSelect: 'none',
        cursor: 'default',
        bottom: 8,
        display:'none',
        borderBottom: 'dotted 2px ' + disabledTextColor,
      },
      rootWhenOpen: {
        opacity: 1
      }, 
      menu: {
        width:'90%',
        minWidth:'90%',
        top:'0px',
        marginTop: props.floatingLabelText ? '70px' : '40px',
        outlineStyle: 'none',
        zIndex:'999999999',
        overflowX: 'hidden !important',
        overflowY: 'scroll !important',
        maxHeight:'250px'
      },      
      menuItem: {
        color:'black',
        paddingRight: spacing.iconSize +
                      spacing.desktopGutterLess +
                      spacing.desktopGutterMini,
        height: spacing.desktopDropDownMenuItemHeight,
        lineHeight: spacing.desktopDropDownMenuItemHeight + 'px',
        whiteSpace: 'nowrap',
        fontSize: '90%',
        width:'85%',
        minWidth:'85%',
        top:'70px'        
      },      
      labelWhenOpen: {
        opacity: 0,
        top: spacing.desktopToolbarHeight / 2
      },      
      overlay: {
        transition: Transitions.easeOut(),
        top: 0
      },      

      underlineFocus: {
        borderBottom: 'solid 2px',
        borderColor: focusColor,
        transform: 'scaleX(0)',
        transition: Transitions.easeOut(),
      },
    };

    styles.error = this.mergeAndPrefix(styles.error, props.errorStyle);
    styles.underline = this.mergeAndPrefix(styles.underline, props.underlineStyle);
    styles.underlineAfter = this.mergeAndPrefix(styles.underlineAfter, props.underlineDisabledStyle);

    styles.floatingLabel = this.mergeStyles(styles.hint, {
      lineHeight: '22px',
      top: 38,
      bottom: 'none',
      opacity: 1,
      transform: 'scale(1) translate3d(0, 0, 0)',
      transformOrigin: 'left top',
    });
   
    styles.autoLabel = this.mergeStyles(styles.hint, {
      lineHeight: '20px',
      top: props.floatingLabelText ? 36 : 12,
      opacity: 1,
      transform: 'scale(1) translate3d(0, 0, 0)',
      transformOrigin: 'left top',
      fontSize: '90%',
      marginTop: '-10000px'

    });

    styles.textarea = this.mergeStyles(styles.input, {
      marginTop: props.floatingLabelText ? 36 : 12,
      marginBottom: props.floatingLabelText ? -36 : -12,
      height: this.props.tahoog ? this.props.tahoog : '',
      boxSizing: 'border-box',
      font: 'inherit',
    });
    

    styles.focusUnderline = this.mergeStyles(styles.underline, styles.underlineFocus, props.underlineFocusStyle);

    if (this.state.isFocused) {
      styles.floatingLabel.color = focusColor;
      styles.floatingLabel.transform = 'perspective(1px) scale(0.75) translate3d(2px, -28px, 0)';
      styles.focusUnderline.transform = 'scaleX(1)';
      styles.autoLabel.marginTop = '25px';
    }

    if (this.state.hasValue || this.state.type == 'date') {
      styles.floatingLabel.color = ColorManipulator.fade(props.disabled ? disabledTextColor : floatingLabelColor, 0.5);
      styles.floatingLabel.transform = 'perspective(1px) scale(0.75) translate3d(2px, -28px, 0)';
      styles.hint.opacity = 0;
    }


    if (props.floatingLabelText) {
      styles.hint.opacity = 0;
      styles.input.boxSizing = 'border-box';
      if (this.state.isFocused && !this.state.hasValue) styles.hint.opacity = 1;
    }

    if (props.style && props.style.height) {
      styles.hint.lineHeight = props.style.height;
    }

   if (props.klein) {
      styles.menuItem.lineHeight = '20px';
      styles.menuItem.height = '20px';
      styles.menuItem.fontSize = '90%';
    }    

    if (props.floatingLabelText && !props.multiLine) styles.input.marginTop = 14;
    if (props.floatingLabelText && props.multiLine) styles.input.marginTop = 0;


    if (this.state.type == 'date')
    {
      styles.input.height = '';
      styles.input.marginTop = this.props.floatingLabelText ? '40px' : '14px';
    }
    if (this.state.errorText && this.state.isFocused) styles.floatingLabel.color = styles.error.color;

    if (this.state.errorText) {
      styles.focusUnderline.borderColor = styles.error.color;
      styles.focusUnderline.transform = 'scaleX(1)';
    }

    return styles;
  },

  render() {
    let _this = this;
    let {
      className,
      errorStyle,
      errorText,
      floatingLabelText,
      fullWidth,
      hintText,
      hintStyle,
      id,
      multiLine,
      onBlur,
      onChange,
      onFocus,
      type,
      rows,
      menu,
      menuItems,
      PopUp,
      CalPopup,
      CallDetail,
      toevFunc,
      third,
      detail,
      toevoegen,
      DialDatum,
      rowsMax,
      tahoog,
      sluiten, 
      minjaar, 
      maxjaar, 
      zoom, 
      DatClick,
      datChange, 
      veld, 
      margintop, 
      breed,
      Key,
      setfilter,
      autotext, 
      textColor,
      onMenuChange,
      zien,
      maskChar,    
      alwaysShowMask,  
      formatChars,
      ...other,
    } = this.props;

 

    let styles = this.getStyles();
    let inputId = id || this._uniqueId;

    if (this.state.rowData)
    {
      menuItems = this.state.rowData;
    }

    let selectedIndex = this.state.selectedIndex;


    let menuElement = menu ? (         
      <Menu
        ref="menuItems"
        autoWidth={false}
        selectedIndex={selectedIndex}
        menuItems={menuItems}
        style={styles.menu}
        menuItemStyle={this.mergeStyles(styles.menuItem, this.props.menuItemStyle)}
        hideable={true}
        doefocus={this.focus}
        zetwaarde={this.zetwaardemen}
        visible={this.state.open}
        onRequestClose={this._onMenuRequestClose}
        onItemTap={this._onMenuItemClick} />) : null;

    let modelands = this.props.mode !== undefined ? this.props.mode : (window.innerWidth < window.innerHeight) ? 'portrait': 'landscape';

    let martop = (screen.width < 750) ? '0px' : this.props.margintop;

    let DatElement = DialDatum ? (<DatePickerDialog
          ref= "dialogWindow"
          mode={modelands}
          autoOk={true}
          minDate={this.state.minDate}
          maxDate={this.state.maxDate}
          onDismiss={this.sluitdat}
          second={this.props.second}
          third={this.props.third}
          margintop = {martop}
          breed={this.props.breed}
          mode={this.props.mode}
          zoom = {this.props.zoom}
          initialDate= {this.state.dialogDate}
          onAccept={this._handleDialogAccept}
          showYearSelector={this.state.showYearSelector} />) : null;
 
    let errorTextElement = this.state.errorText ? (
      <div style={this.prepareStyles(styles.error)}>{this.state.errorText}</div>
    ) : null;

    let hintTextElement = hintText ? (
      <div style={this.prepareStyles(styles.hint, hintStyle)}>{hintText}</div>
    ) : null;

    let floatingLabelTextElement = floatingLabelText ? (
      <label
        style={this.prepareStyles(styles.floatingLabel, this.props.floatingLabelStyle)}
        htmlFor={inputId}>
        {floatingLabelText}
      </label>
    ) : null;

    let autoLabelTextElement = this.props.autotext ? (
      <label
        style={this.mergeAndPrefix(styles.autoLabel, this.props.autoLabelStyle)}
        htmlFor={inputId}>
        {this.state.autoText}
      </label>
    ) : null;
   let topbutico = floatingLabelText ? '30px' : '5px';
   let lefticon = menu  ? ( <IconButton iconClassName="fa fa-arrow-down" 
                    size={12} 
                    viewBox="-1 -1 10 10" 
                    disabled={this.props.disabled}
                    onClick={this.FlbDown}
                    iconStyle={{color:'rgba(0, 143, 183,.5)', iconHoverColor:'rgba(0, 143, 183,.9)',fontSize: '90%'}}
                    style={{float:'right',position: 'absolute',float: 'right',zIndex: '64500', fontSize: '90%',right: '5px', top: topbutico}}/> ) : 

                  DialDatum ? ( <IconButton  iconClassName="fa fa-calendar" 
                    size={12} 
                    viewBox="-1 -1 10 10" 
                    disabled={this.props.disabled}                    
                    onClick={this.CalDown}                    
                    iconStyle={{color:'rgba(0, 143, 183,.5)', iconHoverColor:'rgba(0, 143, 183,.9)',fontSize: '90%'}}
                    style={{float:'right',position: 'absolute',float: 'right',zIndex: '64500',fontSize: '90%', right: '5px', top: topbutico}}/> ) :
                  PopUp ? ( <IconButton iconClassName="fa fa-bars" 
                    size={12} 
                    viewBox="-1 -1 10 10" 
                    onClick={this.CalPopup}
                    disabled={this.props.disabled}                    
                    iconStyle={{color:'rgba(0, 143, 183,.5)', iconHoverColor:'rgba(0, 143, 183,.9)',fontSize: '90%'}}
                    style={{float:'right',position: 'absolute',float: 'right',zIndex: '64500',fontSize: '90%', right: '5px', top: topbutico}}/> ) :                  
                  null;
    let plusicon = toevoegen ? ( <IconButton iconClassName="fa fa-plus" 
                    size={12} 
                    viewBox="-1 -1 10 10" 
                    disabled={this.props.disabled}                    
                    onClick={this.PlusDown}
                    iconStyle={{color:'rgba(0, 143, 183,.5)', iconHoverColor:'rgba(0, 143, 183,.9)',fontSize: '90%'}}
                    style={{float:'right',position: 'absolute',float: 'right',zIndex: '9999',fontSize: '90%', top: topbutico,right: '-25px'}}/> ) : 
                  detail ? ( <IconButton iconClassName="fa fa-search" 
                    size={12} 
                    viewBox="-1 -1 10 10" 
                    onClick={this.Detail}                    
                    iconStyle={{color:'rgba(0, 143, 183,.5)', iconHoverColor:'rgba(0, 143, 183,.9)',fontSize: '90%'}}
                    style={{float:'right',position: 'absolute',float: 'right',zIndex: '9999',fontSize: '90%',  top: topbutico, right: '-25px'}}/> ) :     
                  null;






    let inputProps;
    let inputElement;

    inputProps = {
      id: inputId,
      ref: this._getRef(),
      onBlur: this._handleInputBlur,
      onFocus: this._handleInputFocus,
      disabled: this.props.disabled,
      onKeyDown: this._handleInputKeyDown,
    };
    const inputStyle = this.mergeStyles(styles.input, this.props.inputStyle);

    if (!this.props.hasOwnProperty('valueLink')) {
      inputProps.onChange = this._handleInputChange;
    }
    if (this.props.children) {
      inputElement = React.cloneElement(this.props.children, {...inputProps, ...this.props.children.props});
    }
    else {
      inputElement = multiLine ? (
        <EnhancedTextarea
          {...other}
          {...inputProps}
          style={inputStyle}
          rows={rows}
          rowsMax={rowsMax}
          tahoog= {tahoog}
          onHeightChange={this._handleTextAreaHeightChange}
          textareaStyle={this.mergeAndPrefix(styles.textarea)} />
      ) : ( <input
          {...other}
          {...inputProps}
          style={this.prepareStyles(inputStyle)}
          type={this.state.type} />
      );
    }

    let underlineElement = multiLine ? null : this.props.disabled ? (
      <div style={this.prepareStyles(styles.underlineAfter)}></div>
    ) : (
      <hr style={this.prepareStyles(styles.underline)}/>
    );
    let focusUnderlineElement = multiLine ? null : (<hr style={this.prepareStyles(styles.focusUnderline)} />);

    return (
      <div className={className} style={this.prepareStyles(styles.root, this.props.style)}>
        {floatingLabelTextElement}
        {autoLabelTextElement}
        {hintTextElement}
        {inputElement}
        {underlineElement}
        {focusUnderlineElement}
        {errorTextElement}
        {lefticon}
        {plusicon}
        {menuElement}
        {DatElement}
      </div>
    );
  },

  blur() {
    if (this.isMounted()) this._getInputNode().blur();
  },

  clearValue() {
    this.setValue('');
  },
  createFilledArray: function (length, val) {
    var array = [];
    for (var i = 0; i < length; i++) {
        array[i] = val;
    }
    return array;
  },

  focus() {
    if (this.isMounted()) this._getInputNode().focus();
  },

  getValue() {
    return this.isMounted() ? this._getInputNode().value : undefined;
  },

  setErrorText(newErrorText) {
    if (process.env.NODE_ENV !== 'production' && this.props.hasOwnProperty('errorText')) {
      console.error('Cannot call TextField.setErrorText when errorText is defined as a property.');
    }
    else if (this.isMounted()) {
      this.setState({errorText: newErrorText});
    }
  },

  setValue(newValue) {
    if (process.env.NODE_ENV !== 'production' && this._isControlled()) {
      console.error('Cannot call TextField.setValue when value or valueLink is defined as a property.');
    }
    else if (this.isMounted()) {
      if (this.props.multiLine) {
        this.refs[this._getRef()].setValue(newValue);
      }
      else {
            if (this.props.type == 'date' && newValue.length < 10)
            {
              this.setState({type:'text'});
            }
            else
            {
              if (this.props.type == 'date')
              {
                this.setState({type:'date'});
              }
            }
            this._getInputNode().value = newValue;
          }
        this.value = newValue;
        this.state.Value = newValue;
        this.state.value = newValue;
        this.state.hasValue = newValue;
        this.setState({Value: newValue});
        this.setState({value: newValue});
        this.setState({hasValue: newValue});
    }
  },

  _getRef() {
//    return this.props.ref ? this.props.ref : 'input';
    return 'input';
  },

  _getInputNode() {
    return (this.props.children || this.props.multiLine) ?
      this.refs[this._getRef()].getInputNode() : ReactDOM.findDOMNode(this.refs[this._getRef()]);
  },

  _handleInputBlur(e) {
    if (this.state.orgtype == 'date')
    {
      var value = this.getValue();
      if (value == '')
      {
        this.setState({type:'text'});
      }
    }
    if (this.state.mask) {
      if (this.formatValue('') == this.getValue())
      {
        this.clearValue();
      }
    }
    this.setState({isFocused: false});
    if (this.props.onBlur) this.props.onBlur(e);
  },

  setKey(newValue) {
    this.setState({Key: newValue});
    if (newValue == 0)
    {
      this.setState({autoText: ''});
      this.setState({Value: ''});
      this.setState({value: ''});
      this.setState({hasValue: ''});
      this._getInputNode().value = '';

    }
    // zoeken naar juiste sleutel in array
    if (this.props.setfilter)
    {
      let filterBy;
      filterBy = this.state.hasValue;
      if (!this.state.OrgData)
      {
        this.state.OrgData = this.props.menuItems;
      }
      if (filterBy)
      {
        if (filterBy.length > 0)
        {
          const regex = new RegExp(filterBy, 'i');
          const filtered = this.state.OrgData.filter(function(row) {
            return (row.text.search(regex) > -1);});
          if (this.props.setfilter)
          {
            this.setState({rowData: filtered});
            this.setState({selectedIndex:0});
            let selectedItem = this.state.rowData.first();
          }
        }
        else
        {
          this.state.rowData =this.props.menuItems;
          // Einde filter zetten
          let selectedItem = this.state.rowData.first();
          this.setState({ selectedIndex: 0 });
        }
      }
    }
  },

  zetmenu(data) {
    this.setState({rowData: data});
    this.setState({OrgData: data});
    this.setState({selectedIndex:0});
    this.state.OrgData = data;

    let selectedItem = this.state.rowData.first();
  },

  getKey() {
    var key;
    if (this.state.Key == null || this.state.Key == undefined)
    {
      key = 0;
    }
    else
    {
      key = this.state.Key;
    }
    return key;
  },


  _handleInputChange(e) {
    if (this.props.menu)
    {

      if (!this.state.OrgData)
      {
        this.state.OrgData = this.props.menuItems;
      }
      e.preventDefault();
      this.setState({ open: true });
      this.refs.menuItems._setActIndex(0);
      const regex = new RegExp(e.target.value, 'i');
      const filtered = this.state.OrgData.filter(function(row) {
            return (row.text.search(regex) > -1);});

      if (this.props.setfilter)
      {
        this.setState({rowData: filtered});
        this.setState({selectedIndex:0});
        let selectedItem = this.state.rowData.first();
      }
      if (this.props.autotext)
      {
        // Hier filter zetten
        this.setState({rowData: filtered});
        this.setState({selectedIndex:0});
        if (e.target.value.length > 0 && filtered.size > 0)
        {     
          this.setState({autoText: filtered.first().text});
          this.setState({Key: filtered.first().payload});          
        }
        else
        {
          this.setState({autoText: ''});
          this.setState({Key:0});          
        }
      }
      this.refs.menuItems._renderVisibility();
    }
    else
    {
      if (!this.state.OrgData)
      {
        this.state.OrgData = this.props.menuItems;
      }

      if (this.props.autotext)
      {
        // Hier filter zetten
        if (!this.state.OrgData)
        {
          this.state.OrgData = this.props.menuItems;
        }
        e.preventDefault();
        const regex = new RegExp(e.target.value, 'i');
        const test = this.state.rowData.first();
        const filtered = this.state.OrgData.filter(function(row) {
            return (row.text.search(regex) > -1);});
        this.setState({rowData: filtered});
        this.setState({selectedIndex:0});
        if (e.target.value.length > 0 && filtered.size > 0)
        {     
          this.setState({autoText: filtered.first().text});
          this.setState({Key: filtered.first().payload});          
        }
        else
        {
          this.setState({autoText: ''});
          this.setState({Key:0});          
        }
      }
      else
      {
        if (this.state.mask)
        {
          this._ChkMask();
        }
      }
      this.setState({hasValue: e.target.value});      
    }
    if (this.props.onChange) this.props.onChange(e);
  },
  getSelection: function () {
        var input = this._getInputNode();
        var start = 0;
        var end = 0;

        if ("selectionStart" in input && "selectionEnd" in input) {
            start = input.selectionStart;
            end = input.selectionEnd;
        } else {
            var range = document.selection.createRange();
            if (range.parentElement() === input) {
                start = -range.moveStart("character", -input.value.length);
                end = -range.moveEnd("character", -input.value.length);
            }
        }

        return {
            start: start,
            end: end,
            length: end - start
        };
    },
    getCaretPos: function () {
        return this.getSelection().start;
    },
    getPrefix: function () {
        var prefix = "";
        var mask = this.mask;
        if (this.mask)
        {
          for (var i = 0; i < mask.length && this.isPermanentChar(i); ++i) {
            prefix += mask[i];
          }
        }
        return prefix;

    },
    isPermanentChar: function (pos) {
        return this.permanents.indexOf(pos) !== -1;
    },

    isFilled: function () {
        var value = arguments.length <= 0 || arguments[0] === undefined ? this.state.value : arguments[0];

        return this.getFilledLength(value) === this.mask.length;
    },
    getFilledLength: function () {
        var value = arguments.length <= 0 || arguments[0] === undefined ? this.state.value : arguments[0];

        var i;
        var maskChar = this.maskChar;

        if (!maskChar) {
            return value.length;
        }

        for (i = value.length - 1; i >= 0; --i) {
            var character = value[i];
            if (!this.isPermanentChar(i) && this.isAllowedChar(character, i)) {
                break;
            }
        }
        return ++i || this.getPrefix().length;
    },
    isAllowedChar: function (character, pos) {
        var allowMaskChar = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];
        var mask = this.mask;
        var maskChar = this.maskChar;

        if (this.isPermanentChar(pos)) {
            return mask[pos] === character;
        }
        var ruleChar = mask[pos];
        var charRule = this.charsRules[ruleChar];
        return new RegExp(charRule).test(character || "") || allowMaskChar && character === maskChar;
    },
    replaceSubstr: function (value, newSubstr, pos) {
        return value.slice(0, pos) + newSubstr + value.slice(pos + newSubstr.length);
    },
    insertRawSubstr: function (value, substr, pos) {
        var mask = this.mask;
        var maskChar = this.maskChar;

        var isFilled = this.isFilled(value);
        var prefixLen = this.getPrefix().length;
        substr = substr.split("");

        if (!maskChar && pos > value.length) {
            value += mask.slice(value.length, pos);
        }

        for (var i = pos; i < mask.length && substr.length;) {
            var isPermanent = this.isPermanentChar(i);
            if (!isPermanent || mask[i] === substr[0]) {
                var character = substr.shift();
                if (this.isAllowedChar(character, i, true)) {
                    if (i < value.length) {
                        if (maskChar || isFilled || i < prefixLen) {
                            value = this.replaceSubstr(value, character, i);
                        } else {
                            value = this.formatValue(value.substr(0, i) + character + value.substr(i));
                        }
                    } else if (!maskChar) {
                        value += character;
                    }
                    ++i;
                }
            } else {
                if (!maskChar && i >= value.length) {
                    value += mask[i];
                } else if (maskChar && isPermanent && substr[0] === maskChar) {
                    substr.shift();
                }
                ++i;
            }
        }
        return value;
    },
    getLeftEditablePos: function (pos) {
        for (var i = pos; i >= 0; --i) {
            if (!this.isPermanentChar(i)) {
                return i;
            }
        }
        return null;
    },
    getRightEditablePos: function (pos) {
        var mask = this.mask;

        for (var i = pos; i < mask.length; ++i) {
            if (!this.isPermanentChar(i)) {
                return i;
            }
        }
        return null;
    },
  disableValueAccessors: function () {
    var valueDescriptor = this.valueDescriptor;
    if (!valueDescriptor) {
        return;
    }
    this.valueDescriptor = null;
    var input = this._getInputNode();
    Object.defineProperty(input, 'value', valueDescriptor);
  },
  pasteText: function (value, text, selection, event) {
    var caretPos = selection.start;
    if (selection.length) {
      value = this.clearRange(value, caretPos, selection.length);
    }
    var textLen = this.getRawSubstrLength(value, text, caretPos);
    value = this.insertRawSubstr(value, text, caretPos);
    caretPos += textLen;
    caretPos = this.getRightEditablePos(caretPos) || caretPos;
    if (value !== this.getValue()) {
      if (event) {
        this.setValue(value);
      }
      this.setState({value: this.hasValue ? this.state.value : value});
      if (event && typeof this.props.onChange === "function") {
          this.props.onChange(event);
      }
    }
    this.setCaretPos(caretPos);
  },
  getRawSubstrLength: function (value, substr, pos) {
    var mask = this.mask;
    var maskChar = this.maskChar;
    substr = substr.split("");
    for (var i = pos; i < mask.length && substr.length;) {
        if (!this.isPermanentChar(i) || mask[i] === substr[0]) {
            var character = substr.shift();
            if (this.isAllowedChar(character, i, true)) {
                ++i;
            }
        } else {
            ++i;
        }
    }
    return i - pos;
  },  
  clearRange: function (value, start, len) {
    var _this4 = this;
    var end = start + len;
    var maskChar = this.maskChar;
    var mask = this.mask;
    if (!maskChar) {
      var prefixLen = this.getPrefix().length;
      value = value.split("").filter(function (character, i) {
      return i < prefixLen || i < start || i >= end;
      }).join("");
      return this.formatValue(value);
    }
    return value.split("").map(function (character, i) {
    if (i < start || i >= end) {
      return character;
    }
    if (_this4.isPermanentChar(i)) {
       return mask[i];
    }
    return maskChar;
    }).join("");
  },
  setCaretPos: function (pos) {
    var raf = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (fn) {
        return setTimeout(fn, 0);
    };
    var setPos = this.setSelection.bind(this, pos, 0);
    setPos();
    raf(setPos);
    this.lastCaretPos = pos;
  },
  setSelection: function (start) {
    var len = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];
    var input = this._getInputNode();
    if (!input) {
      return;
    }
    var end = start + len;
    if ("selectionStart" in input && "selectionEnd" in input) {
      input.selectionStart = start;
      input.selectionEnd = end;
    } else {
      var range = input.createTextRange();
        range.collapse(true);
        range.moveStart("character", start);
        range.moveEnd("character", end - start);
        range.select();
      }
  },



 _ChkMask() {
    let leninp;
    let lenmask;
    let val;
    let lingchar;
    let zkstr;
    let machar, dd, mm ;

    if (this.state.mask) {
        var _this6 = this;
        var pasteSelection = this.pasteSelection;
        var mask = this.mask;
        var maskChar = this.maskChar;
        var lastEditablePos = this.lastEditablePos;
        var preventEmptyChange = this.preventEmptyChange;
        var value = this.getValue();
        if (!value && this.preventEmptyChange) {
            this.disableValueAccessors();
            this.preventEmptyChange = false;
            this.setValue(this.state.value);
            return;
        }
        var oldValue = this.state.value;
        if (pasteSelection) {
            this.pasteSelection = null;
            this.pasteText(oldValue, value, pasteSelection, event);
            return;
        }
        var selection = this.getSelection();
        var caretPos = selection.end;
        var maskLen = mask.length;
        var valueLen = value.length;
        var oldValueLen = oldValue.length;
        var prefixLen = this.getPrefix().length;
        var clearedValue;

        if (valueLen > oldValueLen) {
            var substrLen = valueLen - oldValueLen;
            var startPos = selection.end - substrLen;
            var enteredSubstr = value.substr(startPos, substrLen);

            if (startPos < lastEditablePos && (substrLen !== 1 || enteredSubstr !== mask[startPos])) {
                caretPos = this.getRightEditablePos(startPos);
            } else {
                caretPos = startPos;
            }

            value = value.substr(0, startPos) + value.substr(startPos + substrLen);

            clearedValue = this.clearRange(value, startPos, maskLen - startPos);
            clearedValue = this.insertRawSubstr(clearedValue, enteredSubstr, caretPos);

            value = this.insertRawSubstr(oldValue, enteredSubstr, caretPos);

            if (substrLen !== 1 || caretPos >= prefixLen && caretPos < lastEditablePos) {
                caretPos = this.getFilledLength(clearedValue);
            } else if (caretPos < lastEditablePos) {
                caretPos++;
            }
        } else if (valueLen < oldValueLen) {
            var removedLen = maskLen - valueLen;
            clearedValue = this.clearRange(oldValue, selection.end, removedLen);
            var substr = value.substr(0, selection.end);
            var clearOnly = substr === oldValue.substr(0, selection.end);

            if (maskChar) {
                value = this.insertRawSubstr(clearedValue, substr, 0);
            }

            clearedValue = this.clearRange(clearedValue, selection.end, maskLen - selection.end);
            clearedValue = this.insertRawSubstr(clearedValue, substr, 0);

            if (!clearOnly) {
                caretPos = this.getFilledLength(clearedValue);
            } else if (caretPos < prefixLen) {
                caretPos = prefixLen;
            }
        }        

        value = this.formatValue(value)
        if (!this.canUseAccessors || !this.isAndroidBrowser && !this.isWindowsPhoneBrowser) {
            this.setValue(value);
        }
        if (this.canUseAccessors && (this.isAndroidFirefox && value && !this.getValue() || this.isAndroidBrowser || this.isWindowsPhoneBrowser)) {
            this.value = value;
            this.enableValueAccessors();
            if (this.isAndroidFirefox) {
                this.preventEmptyChange = true;
            }
            setTimeout(function () {
                _this6.preventEmptyChange = false;
                _this6.disableValueAccessors();
            }, 0);
        }
        this.setState({value: this.hasValue ? this.state.value : value});
        this.setCaretPos(caretPos);
      }
   },

  _ChkMaskOld(e) {
    let leninp;
    let lenmask;
    let val;
    let lingchar;
    let zkstr;
    let machar, dd, mm ;

    if (this.state.mask) {
        leninp = e.target.value.length;
        lenmask = this.state.mask.length;
        waarde = e.target.value;

        if (leninp <= lenmask)
        {  
          val = e.target.value;
          lingchar = val.substr(leninp-1, 1);

          if ((this.state.type == 'DEC') && (lingchar == '.'))
          {
            lingchar = ',';
            var waarde = e.target.value.substring(0, leninp - 1)+',';
            e.target.value = waarde;
          }
          val = e.target.value;
          lingchar = val.substr(leninp-1, 1);
               
          if (this.state.type == 'MOB') {
            zkstr = '*()-./';
            machar = this.state.mask.substr(leninp-1, 1);
            var n = zkstr.indexOf(machar);
            if (n > 0) 
            { 
              var waarde = val.substring(0, leninp - 1)+machar+lingchar;
              val = waarde;
              e.target.value = val;
            }
            leninp = e.target.value.length;
          }  
          if (this.state.type == 'NUM') {
            zkstr = '*.,';
            machar = this.state.mask.substr(leninp-1, 1);
            var n = zkstr.indexOf(machar);
            if (n > 0) 
            { 
              var waarde = val.substring(0, leninp - 1)+machar+lingchar;
              val = waarde;
              e.target.value = val;
            }
            leninp = e.target.value.length;
          }  
          if (this.state.type == 'DAT') {
            zkstr = '*-/';
            let kar = this.state.mask.substr(2, 1); 
            machar = this.state.mask.substr(leninp-1, 1);
            var n = zkstr.indexOf(machar);
            if (n > 0) 
            { 
              var waarde = val.substring(0, leninp - 1)+machar+lingchar;
              val = waarde;
              e.target.value = val;
            }
            leninp = e.target.value.length;

            if (leninp === 1)
            {
              machar = e.target.value;
              zkstr = '0123';
              var n = zkstr.indexOf(e.target.value);
              if (n < 0) 
              { 
                e.target.value = "";
              }
            }
            if (leninp === 2)
            {
              machar = e.target.value;
              var pint = parseInt(machar);
              if (pint > 31) 
              { 
                var res = machar.substring(0, leninp - 1);
                e.target.value = res+kar;                        
              }
            }
            if (leninp === 4)
            {
              machar = val.substr(leninp-1, 1);
              zkstr = '01';
              var n = zkstr.indexOf(machar);
              if (n < 0) 
              { 
                var res = e.target.value.substring(0, leninp - 1);
                e.target.value = res;                        
              }
            }
            if (leninp === 5)
            {
              machar = val.substr(leninp-2, 2);
              var pint = parseInt(machar);
              if (pint > 12) 
              { 
                var res = e.target.value.substring(0, leninp - 1);
                e.target.value = res;                        
              }
              else
              {
                dd = parseInt(e.target.value.substring(0, 2));
                mm = pint;
                var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];  
                if (mm==1 || mm>2)  
                {  
                  if (dd>ListofDays[mm-1])  
                  {  
                    var res = e.target.value.substring(0, leninp - 1);
                    e.target.value = res+kar;                        
                  }  
                }  
              }
            }
            if (leninp === 7)
            {
              machar = val.substr(leninp-1, 1);
              zkstr = '12';
              var n = zkstr.indexOf(machar);
              if (n < 0) 
              { 
                var res = e.target.value.substring(0, leninp - 1);
                e.target.value = res;                        
              }
            }
            if (leninp === 8)
            {
              machar = val.substr(leninp-1, 1);
              zkstr = '019';
              var n = zkstr.indexOf(machar);
              if (n < 0) 
              { 
                var res = e.target.value.substring(0, leninp - 1);
                e.target.value = res;                        
              }
            }
          }  
          machar = this.state.mask.substr(leninp-1, 1);


          if (this.state.type == 'DEC' || this.state.type == 'NUM' || this.state.type == 'MOB' )
          {
            if (machar == '9')
            {
              zkstr = 'z01234567890123456789';
              if (this.state.type == 'MOB') { zkstr = 'z01234567890123456789.()+'; }
              if (this.state.type == 'DAT') { zkstr = 'z01234567890123456789 '; }
              if (this.state.type == 'DEC') { zkstr = 'z01234567890123456789,.-'; }
              lingchar = val.substr(leninp-1, 1);
              var n = zkstr.indexOf(lingchar);
              if (n > 0) 
              { 
              }
              else
              {
                val = e.target.value;
                var res = val.substring(0, leninp - 1);
                e.target.value = res;
                this.setValue(res);
              }
            }  
          }
          if (machar == 'A')
          {
            zkstr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            if (this.state.type == 'MOB') { zkstr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; }
          }  

        }
        else
        {
          val = e.target.value;
          var res = val.substring(0, leninp - 1);
          e.target.value = res;
          this.setValue(res);
        }
      }
   },

  _onMenuItemClick(e, key, payload) {
      let selectedItem = this.state.rowData.get(key);
      this.setState({ selectedIndex: key });
      this.setState({hasValue: this.state.rowData.get(key).text});
      this.setState({Key: this.state.rowData.get(key).payload});

      this.setValue(this.state.rowData.get(key).text);
      this.setState({open:false});
      if (this.props.onMenuChange) this.props.onMenuChange(this.state.rowData.get(key).payload, this.state.rowData.get(key).text);
  },

  zetwaardemen(key) {
      let selectedItem = this.state.rowData.get(key);
      this.setState({ selectedIndex: key });
      this.setState({hasValue: this.state.rowData.get(key).text});
      this.setState({Key: this.state.rowData.get(key).payload});
      this.setValue(this.state.rowData.get(key).text);
      this.setState({open:false});
      if (this.props.onMenuChange) this.props.onMenuChange(this.state.rowData.get(key).payload,this.state.rowData.get(key).text);            

  },

  _onMenuRequestClose() {
    this.setState({open:false});
  },  

  _handleInputFocus(e) {
    if (this.state.orgtype == 'date') {this.setState({type:'date'})};
    if (this.props.disabled)
      return;
    if (this.state.mask) {
      if (this.getValue().length < 1)
      {
        this._ChkMask();
        this.setCaretToStart()
      }
    }

    this.setState({isFocused: true});
    if (this.props.onFocus) this.props.onFocus(e);
  },

    setCaretToStart: function () {
        var filledLen = this.getFilledLength();
        var pos = this.getLeftEditablePos(filledLen);
        if (pos !== null) {
            this.setCaretPos(pos);
        }
    },


  _handleInputKeyDown(e) {
    if (this.state.mask) {
        var caretPos = this.getCaretPos();
        var value = this.state.value;
        var key = event.key;
        var preventDefault = false;
        switch (key) {
            case "Backspace":
            case "Delete":
                var prefixLen = this.getPrefix().length;
                var deleteFromRight = key === "Delete";
                var selectionRange = this.getSelection();
                if (selectionRange.length) {
                    value = this.clearRange(value, selectionRange.start, selectionRange.length);
                } else if (caretPos < prefixLen || !deleteFromRight && caretPos === prefixLen) {
                    caretPos = prefixLen;
                } else {
                    var editablePos = deleteFromRight ? this.getRightEditablePos(caretPos) : this.getLeftEditablePos(caretPos - 1);
                    if (editablePos !== null) {
                        value = this.clearRange(value, editablePos, 1);
                        caretPos = editablePos;
                    }
                }
                preventDefault = true;
                break;
            default:
                break;
        }
        if (this.props.onKeyDown) this.props.onKeyDown(e);
        if (value !== this.state.value) {
            this.setValue(value);
            this.setState({
                value: this.hasValue ? this.state.value : value
            });
            preventDefault = true;
            if (typeof this.props.onChange === "function") {
                this.props.onChange(event);
            }
        }
        if (preventDefault) {
            event.preventDefault();
            this.setCaretPos(caretPos);
        }
    }
    else
    {
      if(e.shiftKey && e.keyCode == 9) 
      {
      }
      if (this.props.menu || this.props.autotext)
      {
        switch(e.keyCode) 
        {
          case 38:
            if (this.props.menu)
            {
              this.setState({open:true});
              this.refs.menuItems._onKeyDown(e);
            }
            if (this.props.autotext)
            {
                let rownum = this.state.selectedIndex;
                if (rownum == (0))
                {
                  return;
                }
                else
                {
                  rownum --;
                  this.setState({selectedIndex: rownum });
                  this.setState({autoText: this.state.rowData.first().text});
                }
            }            
            break;
          case 40:
            if (this.props.menu)
            {
              this.setState({open:true});
              this.refs.menuItems._onKeyDown(e);
            }
            if (this.props.autotext)
            {
                let rownum = this.state.selectedIndex;
                if (rownum == (this.state.rowData.size -1))
                {
                  return;
                }
                else
                {
                  rownum ++;
                  this.setState({ selectedIndex: rownum });
                  this.setState({autoText: this.state.rowData.get(rownum).text});
                }
            }


            break;
          case 39:
            this.setState({open:false});
            break;
          case 37:
            this.setState({open:false});
            break;
          case 27:
            this.setState({open:false});
            break;
          case 9:
            this.setState({open:false});
            if (this.props.onKeyDown) this.props.onKeyDown(e, this.props.veld);
            break;            
          case 13:
            if (this.props.menu)
            {
              let sleutel = 0;
              sleutel = this.refs.menuItems._getActIndex();
              let selectedItem = this.state.rowData.get(sleutel);
              this.setState({ selectedIndex: sleutel });
              if (this.state.rowData.get(sleutel))
              {
                this.setState({hasValue: this.state.rowData.get(sleutel).text});
                this.setValue(this.state.rowData.get(sleutel).text);
                this.setState({Key: this.state.rowData.get(sleutel).payload});
              }
              this.setState({open:false});
              if (this.props.onMenuChange) this.props.onMenuChange(this.state.rowData.get(sleutel).payload);            

            }
            if (this.props.autotext && this.state.rowData)
            {      
              if (this.state.autoText.length > 0 && this.state.rowData.size > 0)      
              {
                if (this.state.selectedIndex > 0)
                {
                  var sleutel = this.state.selectedIndex;
                  this.setState({hasValue: this.state.rowData.get(sleutel).text});
                  this.setValue(this.state.rowData.get(sleutel).text);
                  this.setState({Key: this.state.rowData.get(sleutel).payload});
                  this.setState({autoText: ''});
                  if (this.props.onMenuChange) this.props.onMenuChange(this.state.rowData.get(sleutel).payload,this.state.rowData.get(sleutel).text);            
                }
                else
                {
                  this.setState({hasValue: this.state.rowData.first().text});
                  this.setValue(this.state.rowData.first().text);
                  this.setState({Key: this.state.rowData.first().payload});
                  this.setState({autoText: ''});
                  if (this.props.onMenuChange) this.props.onMenuChange(this.state.rowData.first().payload,this.state.rowData.first().text);            
                }
              }
              else
              {
                this.setState({Key: 0});
                this.setState({autoText: ''});
                if (this.props.onMenuChange) this.props.onMenuChange(0,'');            
              }

            }
            break
        }
      }
      else
      {
        if (e.keyCode === 13 && this.props.onEnterKeyDown) this.props.onEnterKeyDown(e);
        if (this.props.onKeyDown) this.props.onKeyDown(e);
      }
    }
//    this.focus();
  },

  FlbDown(e) {
    if (e.button === 0) {
      this.setState({ open: !this.state.open });
    }
  },

  PlusDown(e) {
    if (e.button === 0) {
       if (this.props.toevFunc) this.props.toevFunc();
    }
  },

  CalPopup(e) {
    const { CalPopup } = this.props;    
    if (e.button === 0) {
       if (this.props.CalPopup) this.props.CalPopup();
    }
  },

  Detail(e) {
    if (e.button === 0) {
       let waarde = this.getValue();
       if (this.props.CallDetail) this.props.CallDetail(waarde);
    }
  },


  CalDown(e) {
    if (e.button === 0) {
      if (this.props.DatClick) this.props.DatClick();
      this.setState({
        dialogDate: this.getDate()
      });      
      this.refs.dialogWindow.show();
    }
  },

  getDate() {
    if (this.getValue().length == 10)
    {
      var parts =this.getValue().split('-');
      var mydate = new Date(parts[2],parts[1]-1,parts[0]); 
      return mydate;
    }
    else
    {
      var mydate = new Date();
      return mydate;
    }

  },

 _handleDialogAccept(d) {
    let dag = d.getDate();
    let maand = d.getMonth()+1;
    let jaar = d.getFullYear();
    let sjaar = jaar.toString();
    let sdag = dag.toString();
    let smaand = maand.toString();

    if (dag < 10)
    {
      sdag = '0'+sdag;
    }
    if (maand < 10)
    {
      smaand = '0'+smaand;
    }
    let datum = sdag+"-"+smaand+"-"+sjaar;
    this.setValue(datum);
    this.setState({hasValue: datum});
    if (this.props.datChange) this.props.datChange(datum);
  },

  sluitdat() {
    if (this.props.sluiten) this.props.sluiten();
  },

  _handleTextAreaHeightChange(e, height) {
    let newHeight = height + 24;
    if (this.props.floatingLabelText) newHeight += 24;
    ReactDOM.findDOMNode(this).style.height = newHeight + 'px';
  },

  _isControlled() {
    return this.props.hasOwnProperty('value') ||
      this.props.hasOwnProperty('valueLink');
  },

});

module.exports = TextField;
