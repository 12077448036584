const React = require('react');
const ReactDOM = require('react-dom');
const StylePropable = require('./mixins/style-propable');
const Transitions = require('./styles/transitions');
const ColorManipulator = require('./utils/color-manipulator');
const Typography = require('./styles/typography');
const EnhancedButton = require('./enhanced-button.jsx');
const Paper = require('./paper.jsx');
const DefaultRawTheme = require('./styles/raw-themes/light-raw-theme');
const ThemeManager = require('./styles/theme-manager');
const WindowListenable = require('./mixins/window-listenable');
const PropTypes = require('./utils/prop-types');
const Menu = require('./menu/menu.jsx');
const Children = require('./utils/children');
const ContextPure = require('./mixins/context-pure');
const ClickAwayable = require('./mixins/click-awayable');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');


function validateLabel (props, propName, componentName) {
  if (!props.children && !props.label) {
    return new Error('Required prop label or children was not ' +
      'specified in ' + componentName + '.');
  }
};

let getZDepth = function(disabled,diepte) {
  let zDepth = disabled ? 0 : diepte;
  return {
    zDepth: zDepth,
    initialZDepth: zDepth,
  };
};




const RaisedButton = React.createClass({

  mixins: [StylePropable,ContextPure,ClickAwayable],

  contextTypes: {
    muiTheme: React.PropTypes.object,
  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
  },

 windowListeners: {
    keyup: '_handleWindowKeyUp',
    resize: '_setZoom',
  },

  _setZoom() {
    let zoomStyle = (window.innerWidth < 650) ? {zoom:'1'} : {zoom:'1'};
    this.setState({zoomStyle:zoomStyle});
    this.render;

  },

  statics: {
    getRelevantContextKeys(muiTheme) {
      const spacing = muiTheme.rawTheme.spacing;
      const palette = muiTheme.rawTheme.palette;

      return {
        iconSize: spacing.iconSize,
        textColor: palette.textColor,
        disabledColor: palette.disabledColor,
      };
    },

    getChildrenClasses() {
      return [
        EnhancedButton,
        FontIcon,
        Tooltip,
      ];
    },
  },

  getChildContext () {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

  getDefaultProps() {
    return {
      autoWidth: true,
      valueMember:'payload',
      displayMember:'text',      
      iconStyle: {},
      tooltipPosition: 'bottom-center',
      menuItems:Immutable.List(),
    };
  },   

  propTypes: {
    className: React.PropTypes.string,
    disabled: React.PropTypes.bool,
    onMouseDown: React.PropTypes.func,
    onMouseUp: React.PropTypes.func,
    onMouseLeave: React.PropTypes.func,
    onTouchEnd: React.PropTypes.func,
    onTouchStart: React.PropTypes.func,
    primary: React.PropTypes.bool,
    secondary: React.PropTypes.bool,
    labelStyle: React.PropTypes.object,
    backgroundColor: React.PropTypes.string,
    labelColor: React.PropTypes.string,
    disabledBackgroundColor: React.PropTypes.string,
    disabledLabelColor: React.PropTypes.string,
    fullWidth: React.PropTypes.bool,
    menuItemStyle: React.PropTypes.object,    
    onMenuChange: React.PropTypes.func,
    selectedIndex: React.PropTypes.number
  },

  getInitialState() {
    let zDepth = this.props.disabled ? 0 : this.props.diep;
    return {
      hovered: false,
      touched: false,
      initialZDepth: zDepth,
      zDepth: zDepth,
      open: false,
      isHovered: false,
      selectedIndex: 0,       
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  componentWillReceiveProps(nextProps, nextContext) {
    let zDepth = nextProps.disabled ? 0 : 1;
    let newMuiTheme = nextContext.muiTheme ? nextContext.muiTheme : this.state.muiTheme;
    this.setState({
      zDepth: zDepth,
      initialZDepth: zDepth,
      muiTheme: newMuiTheme,
    });
  },

  componentClickAway() {
    this.setState({open:false});
  },


  _getBackgroundColor() {
    let disabledColor = this.props.disabledBackgroundColor ? this.props.disabledBackgroundColor :
      this.getTheme().disabledColor;

    return this.props.disabled ? disabledColor :
      this.props.backgroundColor ? this.props.backgroundColor :
      this.props.primary ? this.getTheme().primaryColor :
      this.props.secondary ? this.getTheme().secondaryColor :
      this.getTheme().color;
  },

  _getLabelColor() {
    let disabledColor = this.props.disabledLabelColor ? this.props.disabledLabelColor :
      this.getTheme().disabledTextColor;

    return this.props.disabled ? disabledColor :
      this.props.labelColor ? this.props.labelColor :
      this.props.primary ? this.getTheme().primaryTextColor :
      this.props.secondary ? this.getTheme().secondaryTextColor :
      this.getTheme().textColor;
  },

  getThemeButton() {
    return this.state.muiTheme.button;
  },

  getTheme() {
    return this.state.muiTheme.raisedButton;
  },

  getStyles() {

    let amount = (this.props.primary || this.props.secondary) ? 0.4 : 0.08;
    let mleft =  this.props.left ? this.props.left : 0; 
    let mtop = this.props.top ? this.props.top : 0; 
    let zIndex = 5;
    const spacing = this.state.muiTheme.rawTheme.spacing;
    let themeVariables = this.state.muiTheme.floatingActionButton;
    let zien = (this.props.zien) ? "" : "-1000px";
    let positie = (this.props.zien) ? "" : "absolute";
    let rechts = (this.props.rechts) ? this.props.rechts : "0px";    
    let vierkant = (this.props.vierkant) ?  '0%' : '50%';

    let styles = {
      root: {
        backgroundColor: 'none',
        display: 'inline-block',
        minWidth: this.props.fullWidth ? '100%' : this.getThemeButton().minWidth,
        height: this.getThemeButton().height,
        transition: Transitions.easeOut(),
      },
      menu: {
        zIndex: zIndex + 1,
        top:mtop,
      },      
      menuItem: {
        color:'black',
        paddingRight: spacing.iconSize +
                      spacing.desktopGutterLess +
                      spacing.desktopGutterMini,
        height: spacing.desktopDropDownMenuItemHeight,
        lineHeight: spacing.desktopDropDownMenuItemHeight + 'px',
        whiteSpace: 'nowrap',
        fontSize:'small',
      },      
      container: {
        position: 'relative',
        height: '100%',
        width: '100%',
        padding: 0,
        overflow: 'hidden',
        borderRadius: 2,
        transition: Transitions.easeOut(),
        backgroundColor: this._getBackgroundColor(),

        //This is need so that ripples do not bleed
        //past border radius.
        //See: http://stackoverflow.com/questions/17298739/css-overflow-hidden-not-working-in-chrome-when-parent-has-border-radius-and-chil
        transform: 'translate3d(0, 0, 0)',
      },
      label: {
        position: 'relative',
        opacity: 1,
        fontSize: '14px',
        letterSpacing: 0,
        textTransform: this.getTheme().textTransform ? this.getTheme().textTransform :
                    (this.getThemeButton().textTransform ? this.getThemeButton().textTransform : 'uppercase'),
        fontWeight: Typography.fontWeightMedium,
        margin: 0,
        padding: '0px ' + this.state.muiTheme.rawTheme.spacing.desktopGutterLess + 'px',
        userSelect: 'none',
        lineHeight: (this.props.style && this.props.style.height) ?
         this.props.style.height : this.getThemeButton().height + 'px',
        color:  this._getLabelColor(),
      },
      overlay: {
        transition: Transitions.easeOut(),
        top: 0,
      },
      overlayWhenHovered: {
        backgroundColor: ColorManipulator.fade(this._getLabelColor(), amount),
      },
    };
    return styles;
  },

  render() {
    let {
      disabled,
      label,
      image,
      primary,
      menu,
      secondary,
      ...other } = this.props;

    let _this = this;
    let styles = this.getStyles();
    let selectedIndex = this.state.selectedIndex;


    let labelElement, tekElement;
    if (image) {
      tekElement = (<img src={image} style={this.prepareStyles(styles.label, this.props.imgStyle)}/>);
    }


    if (label) {
      labelElement = (
        <span style={this.prepareStyles(styles.label, this.props.labelStyle)}>
          {label}
        </span>
      );
    }

    let rippleColor = styles.label.color;
    let rippleOpacity = !(primary || secondary) ? 0.1 : 0.16;
    // Menu
    let menuItems = {};
    if (menu)
    {
      menuItems = this.props.menuItems.map((item) => {
        item.text = item[_this.props.displayMember];
        item.payload = item[_this.props.valueMember];
        return item;
      });    
    }


    let menuElement = menu ? (         
      <Menu
        ref="menuItems"
        autoWidth={this.props.autoWidth}
        selectedIndex={selectedIndex}
        menuItems={menuItems}
        style={this.mergeStyles(styles.menu, this.props.menuStyle)}
        menuItemStyle={this.mergeStyles(styles.menuItem, this.props.menuItemStyle)}
        hideable={true}
        visible={this.state.open}
        noscroll={this.props.noscroll}
        onRequestClose={this._onMenuRequestClose}
        onItemTap={this._onMenuItemClick} />) : null;

    let buttonEventHandlers = disabled ? null : {
      onMouseDown: this._handleMouseDown,
      onMouseUp: this._handleMouseUp,
      onClick: this._handleClick,
      onMouseLeave: this._handleMouseLeave,
      onMouseEnter: this._handleMouseEnter,
      onTouchStart: this._handleTouchStart,
      onTouchEnd: this._handleTouchEnd,
      onKeyboardFocus: this._handleKeyboardFocus,
    };

    return (
      <Paper
        style={this.mergeStyles(styles.root, this.props.style)}
        zDepth={this.props.diep}
        {...buttonEventHandlers}>
    
          <EnhancedButton
            {...other}
            ref="container"
            disabled={disabled}
            style={this.mergeStyles(styles.container)}
            focusRippleColor={rippleColor}
            touchRippleColor={rippleColor}
            focusRippleOpacity={rippleOpacity}
            touchRippleOpacity={rippleOpacity}>
              <div ref="overlay" style={this.prepareStyles(
                  styles.overlay,
                  (this.state.hovered && !this.props.disabled) && styles.overlayWhenHovered
                )}>
                {tekElement}
                  {labelElement}
                  {this.props.children}
              </div>
          </EnhancedButton>
          {menuElement}
      </Paper>
    );
  },

  _handleMouseDown(e) {
    //only listen to left clicks
    if (e.button === 0) {
      this.setState({ zDepth: this.state.initialZDepth + 1 });
      this.setState({ open: !this.state.open });

    }
    if (this.props.onMouseDown) this.props.onMouseDown(e);
  },

  _handleMouseUp(e) {
    this.setState({ zDepth: this.state.initialZDepth });
    if (this.props.onMouseUp) this.props.onMouseUp(e);
  },

  _handleMouseLeave(e) {
    if (!this.refs.container.isKeyboardFocused()) this.setState({ zDepth: this.state.initialZDepth, hovered: false });
    this.setState({ open: false });

    if (this.props.onMouseLeave) this.props.onMouseLeave(e);
  },

  _handleMouseEnter(e) {
    if (!this.refs.container.isKeyboardFocused() && !this.state.touch) {
      this.setState({ open: true });

      this.setState({hovered: true});
    }
    if (this.props.onMouseEnter) this.props.onMouseEnter(e);
  },

  _handleTouchStart(e) {
    this.setState({
      touch: true,
      zDepth: this.state.initialZDepth + 1,
    });
    if (this.props.onTouchStart) this.props.onTouchStart(e);
  },

  _handleTouchEnd(e) {
    this.setState({ zDepth: this.state.initialZDepth });
    if (this.props.onTouchEnd) this.props.onTouchEnd(e);
  },

  _handleClick(e) {
    if (this.props.onKlik) this.props.onKlik(e);
  },

  _handleKeyboardFocus(e, keyboardFocused) {
    if (keyboardFocused && !this.props.disabled) {
      this.setState({ zDepth: this.state.initialZDepth + 1 });
      let amount = (this.props.primary || this.props.secondary) ? 0.4 : 0.08;
      ReactDOM.findDOMNode(this.refs.overlay).style.backgroundColor = ColorManipulator.fade(this.prepareStyles(this.getStyles().label, this.props.labelStyle).color, amount);
    }
    else if (!this.state.hovered) {
      this.setState({ zDepth: this.state.initialZDepth });
      ReactDOM.findDOMNode(this.refs.overlay).style.backgroundColor = 'transparent';
    }
  },
  _onMenuItemClick(e, key, payload) {
      let selectedItem = this.props.menuItems[key];
      if (this.props.onMenuChange) this.props.onMenuChange(key);
  },
  
});

module.exports = RaisedButton;
