const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const AlgFunc = require('../pages/functions.jsx');
const IconButOver = require('../matui/icon_but_over.jsx');
const Chronom = require('../matui/chronom.jsx');

var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
import {Column, Table, CellMeasurer, CellMeasurerCache, List, AutoSizer, SortDirection, SortIndicator } from 'react-virtualized';
 
const BlokItem = React.createClass({

  getInitialState() {
    return {
      height: '300px',
      dkol:[],
      DgrowData:this.props.data,
      OrgData:this.props.data,
      GrData:this.props.data,
      Rupdate: false,
      overscanRowCount: 0,
      rowCount: 0,
      scrollToIndex: undefined,
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false,
      virtualScrollHeight: 300,
      virtualScrollRowHeight: 100,
      RowHeight:0,
      sortBy: '',
      sortDirection: SortDirection.ASC,                         
    };
  },
  componentWillMount() {
    if (!App.master)
    {
      window.location.hash = "/";
      return;
    }    
    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 25
    });
    this.state.height = this.props.height ? this.props.height : '300px';
    this.state.dkol = this.props.kol;
    this.setState({GrData:this.props.data});
    this.setState({rowCount:this.props.data.size});

  },
  _columnCellRenderer ({ columnIndex,cellData, columnData, dataKey, parent, rowData, rowIndex }) {
    let content = cellData;
    let defcont = 1;

    switch (this.props.route) {
        case 'GetOverzBlokContr' :
            switch (dataKey) {
              case 'klantnaam':
                content = (<div className='.VtableColumn' onClick={(event)=>this.KlantClick(event,rowIndex,rowData)} style={{whiteSpace: 'normal'}} >
                            {content}
                          </div>); 
                defcont = 0;
                break;

              case 'consname':
                content = (<div className='.VtableColumn' onClick={(event)=>this.ConsClick(event,rowIndex,rowData)} style={{whiteSpace: 'normal'}} >
                            {content}
                          </div>); 
                defcont = 0;
                break;
            }
          break;
        case 'GetAccContr' :
            if (dataKey == 'tek1')
            {            
              content = (<IconButOver                       
                          style={{display:'block', float:'right', marginLeft: '.5em',fontSize:'100%',marginTop: '-10px'}}
                          iconClassName="fa fa-share-alt" 
                          tooltip='Copie maken'
                          onClick={(event)=>this.ConsClick(event,rowIndex,rowData)}/>);
              defcont = 0;
            }
            if (dataKey == 'tek2' && rowData.document.length > 2)
            {            
              content = (<IconButOver                       
                          style={{display:'block', float:'right', marginLeft: '.5em',fontSize:'100%',marginTop: '-10px'}}
                          iconClassName="fa fa-file-text" 
                          tooltip="Open Document" 
                          onClick={(event)=>this.OpenDoc(event,rowIndex,rowData)}/>);
              defcont = 0;
            }
            break;   

       case 'GetActUser' :
            if (dataKey == 'tek1')
            {            
                if (rowData.startdate.length > 2)
                {
                  content = (<Chronom data={rowData} date={rowData.startdate} start={true} Stop={this.Stoptimer}></Chronom>);
                  defcont = 0;
                }
            }
            break;     

               

        case 'GetRepAfwMnd' :
            switch (dataKey) {
              case 'nm_uren':
                content = (parseFloat(cellData) == 0) ? (<div className='.VtableColumn' style={{whiteSpace: 'normal'}} ></div>):
                          (<div className='.VtableColumn' style={{whiteSpace: 'normal'}} >
                            {content}
                          </div>); 
                defcont = 0;
                break;

            }
          break;


        }

    let hfdcont = (defcont == 0 ) ? (<div> {content} </div>) : 
                                    (<div className='.VtableColumn' onClick={(event)=>this.LRowClick(event,rowIndex,rowData)} style={{whiteSpace: 'normal'}} >
                                      {content}
                                     </div>); 
    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={0}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
      {hfdcont}
      </CellMeasurer>
    )
  },
  _columnCellRendererDef ({ columnIndex,cellData, columnData, dataKey, parent, rowData, rowIndex }) {
    let content = cellData;
    return (
         <div
          className='.VtableColumn'
          onClick={(event)=>this.LRowClick(event,rowIndex,rowData)}
          style={{whiteSpace: 'normal'}}>
          {content}
        </div>
 
    )
  },  
 _rowGetter ({ index }) {
    const  list = this.state.GrData;
    return list.get(index % list.size)
  },
 _rowStyle ({ index }) {
    let rowclass = index % 2 === 0 ? 'VtableRow' : 'VtableRowOd';
    return rowclass;
  },

  _getRowData (list,index) {
    if (list.size > 0)
    {
      return list.get(index % list.size)
    }
    else
    {
      return null;
    }
  },

 _isSortEnabled () {
    if (this.state.GrData)
    {
      const list = this.state.GrData;
      return this.state.rowCount <= list.size
    }
    else
    {
      return false;
    }
  },

 _sort ({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection })
  },  

 _headerRenderer ({columnData,dataKey,disableSort,label,sortBy,sortDirection}) {
    return (
      <div>
        Full Name
        {sortBy === dataKey &&
          <SortIndicator sortDirection={sortDirection} />
        }
      </div>
    )
  },

  stringToDate(_date,_format,_delimiter) {
      if (_date)
      {
            var formatLowerCase=_format.toLowerCase();
            var formatItems=formatLowerCase.split(_delimiter);
            var dateItems=_date.split(_delimiter);
            var monthIndex=formatItems.indexOf("mm");
            var dayIndex=formatItems.indexOf("dd");
            var yearIndex=formatItems.indexOf("yyyy");
            var month=parseInt(dateItems[monthIndex]);
            month-=1;
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
            return formatedDate;
      }
      else
      {
        return null;
      }
  },   

  getSortType(tabel,label) {
    let sorttype = 'STR';
    tabel.forEach((item, i) => {
      if (item.datakey == label)
      {
        sorttype = item.colsorttype;
      }

    });
    return sorttype;
  },   
  _rowRendererblk ({ index, isScrolling }) {
    const {
      showScrollingPlaceholder,
      useDynamicRowHeight
    } = this.state

    const data = this._getRowData(index)
    if (data == null) {return null};


    let inhoud = (this.props.route == 'GetKandAcc') ? AlgFunc._rowInhoud (index, this._getRowData(index), 'GetKandAcc',this.LRowClick) :
                 (this.props.route == 'GetKandCont') ? AlgFunc._rowInhoud (index, this._getRowData(index), 'GetKandAcc',this.LRowClick) :
                 (this.props.route == 'GetTeFakt') ? AlgFunc._rowInhoud (index, this._getRowData(index), 'GetTeFakt',this.LRowClick) :
                 (this.props.route == 'GetKandBesBlk') ? AlgFunc._rowInhoud (index, this._getRowData(index), 'GetKandBesBlk',this.LRowClick) :
                 (this.props.route == 'GetOverzBlokContr') ? AlgFunc._rowInhoud (index, this._getRowData(index), 'GetOverzBlokContr',this.LRowClick,this.KlantClick) :
                 (this.props.route == 'GetRepAfwMnd') ? AlgFunc._rowInhoud (index, this._getRowData(index), 'GetRepAfwMnd',this.LRowClick,this.KlantClick) :
                 (this.props.route == 'GetActUser') ? AlgFunc._rowInhoud (index, this._getRowData(index), 'GetActUser',this.LRowClick) :
                 (this.props.route == 'GetFakVerv') ? AlgFunc._rowInhoud (index, this._getRowData(index), 'GetFakVerv',this.LRowClick) :
                 AlgFunc._rowInhoud (index, this._getRowData(index), 'type');

    return (
          <div style={{height: '100%', width:'99%', display: 'flex', flexDirection: 'row', alignItems: 'center',  backgroundColor: '#fff', overflow: 'hidden'}}>
            <Row className="show-grid" style={{width: '99%', marginLeft: '0px', overflow: 'hidden' }}>
              <Col xs={12} md={12} sm={12} >
                {inhoud}
              </Col>
            </Row>
          </div>
        )
  },

  getHoogte({ index }) {
    let hoog = (screen.width < 750 ) ? 70:30;   
    const data = this._getRowData(index)
    let size = 30;
    if (this.props.route == 'GetActUser')
    {
      var vlengte = data.subject.length + 12;
      var w = window.innerWidth - 5;
      var hulph = (w * 0.3) *0.08;
      var hoog1 = vlengte / hulph;
      if (hoog1 < 1) {hoog1 = 1};
      hoog = hoog1 * 30;  
    }  
    return hoog;
  },

  render() {
    // Definieren van de headers
    var w = (window.innerWidth);
    var h = (window.innerHeight);
    if (this.props.breed) {w = this.props.breed};
    if (this.props.hoog) {h = this.props.hoog};


    const {
      overscanRowCount,
      rowCount,
      scrollToIndex,
      showScrollingPlaceholder,
      useDynamicRowHeight,
      virtualScrollHeight,
      virtualScrollRowHeight,
      RowHeight,
      data,
      dkol,
      sortBy,
      route,
      immuData,
      GrData,
      sortDirection
    } = this.state


    let that = this;
    let arra;
    let arrb;   

    const sorttype = this.getSortType(dkol,sortBy); 
    const list = GrData;
    var sortedList = list;

    if (sorttype == 'STR' && sortBy.length > 0 )
    {
      sortedList = this._isSortEnabled()
      ? list
        .sortBy(item => item[sortBy].toLowerCase())
        .update(list =>
          sortDirection === SortDirection.DESC
            ? list.reverse()
            : list
        )
      : list
    }


    if (sorttype == 'NUM' && sortBy.length > 0 )
    {
      sortedList = this._isSortEnabled()
      ? list
        .sortBy(item => parseFloat(item[sortBy].replace('.','')))
        .update(list =>
          sortDirection === SortDirection.DESC
            ? list.reverse()
            : list
        )
      : list
    }
    if (sorttype == 'DAT' && sortBy.length > 0 )
    {
      sortedList = this._isSortEnabled()
      ? list
        .sortBy(item => that.stringToDate(item[sortBy],"dd-mm-yyyy","-"))
        .update(list =>
          sortDirection === SortDirection.DESC
            ? list.reverse()
            : list
        )
      : list
    }

    const rowGetter = ({ index }) => this._getRowData(sortedList, index);









    let cardList = [];

    this.state.dkol.forEach((item, i) => {
        let colw = (w/100)*item.width;
        let hkey = 'VTCOLBL'+i;
        let sorteer = (item.sortable == '1') ? false : true;
        let colstyle = (item.colsorttype == 'NUM') ? { textAlign:'right'} : { textAlign:'left'};
        let headclass = (item.colsorttype == 'NUM') ? 'VHeaderNum' : 'VHeaderStr';


        if (item.cellrender == 'N')
        {
          cardList.push(<Column
                    dataKey={item.datakey}
                    headerClassName= {headclass}
                    disableSort={sorteer}
                    style={colstyle}
                    key={hkey}
                    cellRenderer={this._columnCellRendererDef}
                    className='VtableColumn'
                    label={item.label}
                    width={colw}
                  />);
        }
        else
        {
          cardList.push(<Column
                    dataKey={item.datakey}
                    key={hkey}
                    headerClassName= {headclass}
                    disableSort={sorteer}
                    style={colstyle}
                    cellRenderer={this._columnCellRenderer}
                    className='VtableColumn'
                    label={item.label}
                    width={colw}
                  />);
        }

    });

    let vld_dgrid = (<div style={{postion:'relative',display:'block'}}>
                  <Table
                    deferredMeasurementCache={this._cache}
                    headerHeight={20}
                    height={h}
                    overscanRowCount={2}
                    rowClassName={this._rowStyle}
                    rowHeight={this._cache.rowHeight}
                    rowGetter={rowGetter}
                    rowCount={this.state.rowCount}
                    sort={this._sort}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    width={w}
                  >
                  {cardList}
                  </Table>
                </div>);  

    return (<div>
            {vld_dgrid}
          </div>)
  },


  LRowClick(event,index,rowData) {
    if (this.props.klik) this.props.klik(rowData.id, rowData, this.props.route);       
  },

  RowKlik(waarde) {
    if (this.props.klik) this.props.klik(waarde, this.state.OrgData, this.props.route);       
   
  },

  KlantClick(event,rowIndex,rowData) {
    if (this.props.klik3) this.props.klik3(rowData.id,rowData,'');       
  },
  ConsClick(event,rowIndex,rowData) {
    if (this.props.klik2) this.props.klik2(rowData.id,rowData,'');       
  },
  OpenDoc(event,rowIndex,rowData) {
    if (this.props.klik3) this.props.klik3(rowData.id,rowData,'');       
  },

  RowKlik2(waarde) {
    if (this.props.klik2) this.props.klik2(waarde);       
  },
  Stoptimer(rowData) {
    if (this.props.klik2) this.props.klik2(0,rowData);       
  },
  

});

module.exports = BlokItem;






