const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const StylePropable = require('../matui/mixins/style-propable');
const StyleResizable = require('../matui/mixins/style-resizable');
const TextField = require('../matui/text-field.jsx');
const Dialog = require('../matui/dialog.jsx');
const Zoekpopup = require('./zoekpopup.jsx');
const Snackbar = require('../matui/snackbar.jsx');
const DateTime = require('../matui/utils/date-time');
const Algfunc = require('../functions/algemeen');
var Panel = require("react-bootstrap").Panel;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Button = require("react-bootstrap").Button;
const Toolbar = require('../matui/toolbar/toolbar.jsx');
var ButtonGroup = require("react-bootstrap").ButtonGroup;
const IconButton = require('../matui/icon-button.jsx');
const Contstafunc = require('./cont_func.jsx');
const ClsAct = require('./cls_fixtures.jsx');

import TinyMCE from 'react-tinymce';
// Definietie classe
const Popdeftexture = React.createClass({
  mixins: [StylePropable,StyleResizable,PureRenderMixin],
  getInitialState() {
    return {
      disabelen: false,
      dispvoet:'inherit',
      origin:'',
      sleutel:0,
      err_naam: '',
      soort:'',
      updatecol:'',
      updatetxt:'',          
      templates:[],
      Klanten:App.account,
      data:Contstafunc.Dat_activity(), 
      disaoms: false,
      disasoort: false,
      disastatus: false,
      disauitvdat: false,
      discreadat: false,
      disafsldat:false,
      disacreator: true,
      disauitv: false,
      err_oms:'',
      err_soort:'',
      err_statklacht:'',
      err_uitvdat:'',
      err_creadat:'',
      err_afsldat:'',
      err_klant:'',
      err_klacht:'',
      err_uur: '',
      err_end_time: '',
      err_creator:'',
      err_uitvoerder:'',
      sleutel:0,
      klachtid:0,
      klachtnaam:'',
      lidid:0,
      lidnaam:'',      
    };
  },
  componentWillMount() {
    App.master.AppAccount();
  },
  componentDidMount() {
    if (this.props.data) 
    {
      this.openen(this.props.data);
    }
  },   
  render() {
    var h = window.innerHeight * .8;
    var ch = window.innerHeight * .78;
    let formatChars = {"9": "[0-9,.-]",};

    var maxhdial = window.innerHeight + 'px';
    var maxhdialrol = (window.innerHeight - 250) + 'px';
    // Velden definitie
    let vld_oms = (<TextField
        style={{marginRight: '15px', width: '95%', marginLeft: '10px'}}
          floatingLabelText="Fixture Type"
          defaultValue={this.state.data.name}
          ref="ActInpOms" 
          errorText={this.state.errnaam} />); 
    let vld_lid = (<TextField
        style={{marginRight: '15px', width: '95%',marginLeft: '10px'}}
            floatingLabelText="Manufacturer"
            defaultValue={this.state.data.accountname}
            Key={this.state.data.accountid}
            ref="InpLid" 
            PopUp={true}
            CalPopup={this.LidZoek}            
            menu={false}
            setfilter={false}
            autotext={true}
            onMenuChange={this.AccChange}
            errorText={this.state.errklant}
            menuItems={App.account}
            zien={true}  />);

    let vld_mihigh = (<TextField
        style={{marginRight: '15px', marginLeft: '20px', width: '80%'}}
          floatingLabelText="Min. High"
          defaultValue={this.state.data.mihigh}      
          ref="Inpmihigh" 
          id="Inpmihigh" 
          mask='9999999'
          errorText={this.state.errhigh}          
          formatChars={formatChars}/>);



    let vld_mahigh = (<TextField
        style={{marginRight: '15px', marginLeft: '20px', width: '80%'}}
          floatingLabelText="Max. High"
          defaultValue={this.state.data.mahigh}      
          ref="Inpmahigh" 
          id="Inpmahigh" 
          mask='9999999'
          formatChars={formatChars}/>);

    let vld_stphigh = (<TextField
        style={{marginRight: '15px', marginLeft: '20px', width: '80%'}}
          floatingLabelText="Step High"
          defaultValue={this.state.data.stphigh}      
          ref="Inpstphigh" 
          id="Inpstphigh" 
          mask='99999'
          errorText={this.state.errstep}          
          formatChars={formatChars}/>);

    let vld_milow = (<TextField
        style={{marginRight: '15px', marginLeft: '20px', width: '80%'}}
          floatingLabelText="Min. Low"
          defaultValue={this.state.data.milow}      
          ref="Inpmilow" 
          id="Inpmilow" 
          mask='9999999'
          errorText={this.state.errlow}          
          formatChars={formatChars}/>);

    let vld_malow = (<TextField
        style={{marginRight: '15px', marginLeft: '20px', width: '80%'}}
          floatingLabelText="Max. Low"
          defaultValue={this.state.data.malow}      
          ref="Inpmalow" 
          id="Inpmalow" 
          mask='9999999'
          errorText={this.state.errlow}          
          formatChars={formatChars}/>);

    let vld_stplow = (<TextField
        style={{marginRight: '15px', marginLeft: '20px', width: '80%'}}
          floatingLabelText="Step Low"
          defaultValue={this.state.data.stplow}      
          ref="Inpstplow" 
          id="Inpstplow" 
          mask='99999'
          errorText={this.state.errstep}          
          formatChars={formatChars}/>);

    let vld_sttxt1 = (<TextField
        style={{marginRight: '15px', marginLeft: '20px', width: '80%'}}
          floatingLabelText="Start Textnumb"
          defaultValue={this.state.data.statextnumb}      
          ref="Inpstatextnumb" 
          id="Inpstatextnumb" 
          mask='999999999'
          formatChars={formatChars}/>);

    let vld_stptxt1 = (<TextField
        style={{marginRight: '15px', marginLeft: '20px', width: '80%'}}
          floatingLabelText="Step Textnumb"
          defaultValue={this.state.data.stptextnumb}      
          ref="Inpstptxtnumb" 
          id="Inpstptxtnumb" 
          mask='99999'
          formatChars={formatChars}/>);

    let vld_txtchar = (<TextField
        style={{marginRight: '15px', marginLeft: '20px', width: '80%'}}
          floatingLabelText="Textchar"
          defaultValue={this.state.data.textchar}      
          ref="Inptxtchar" 
          id="Inptxtchar" />);

    let alggeg = (
        <Panel  header='General info' eventKey="11" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff'}}>
          <Row className="show-grid">
            <Col xs={12} md={12} sm={12} >
              {vld_oms}
            </Col>
            <Col xs={12} md={12} sm={12} >
              {vld_lid}
            </Col>
          </Row>
        </Panel>);
    let betrokken =  (
        <Panel  header='Definition' eventKey="21" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff'}}>
          <Row className="show-grid">
            <Col xs={4} md={4} sm={4} >
              {vld_milow}
              {vld_mihigh}
              {vld_sttxt1}
            </Col>
            <Col xs={4} md={4} sm={4}>
              {vld_stplow}
              {vld_stphigh}
              {vld_stptxt1}
            </Col>
            <Col xs={4} md={4} sm={4}>
              {vld_malow}
              {vld_mahigh}
              {vld_txtchar}
            </Col>

          </Row>
        </Panel>);
    let korteoms = (
        <Panel  header='Result' eventKey="31" bsStyle="warning" collapsible defaultExpanded style={{background: '#fff', overflow:'hidden'}}>
          <Row className="show-grid">
            <Col xs={12} md={12} sm={12} >
            </Col>
          </Row>
        </Panel>);
    let vldzoekpopup = (<Zoekpopup  id='zoekpopup1' route = '' zindex={9999998} klik={this.klikzkpop} sluiten={this.sluitzkpop} paddingtop={true} third={true} ref='RefZoekPopup'></Zoekpopup>);
    let tot_zicht1 = (screen.width < 750) ? (
      <div className='row' style={{width:'100%',marginBottom:'15px',display:'inline-block',overflowY:'scroll',overflowX:'hidden'}}>
        {vldzoekpopup}
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol}}
          message={this.state.updatetxt}/>
        <Row className="show-grid">
            <Col xs={12} md={12} sm={12} >
              {alggeg}
            </Col>
            <Col xs={12} md={12} sm={12} >
              {betrokken}
            </Col>
        </Row>
      </div>) : (
      <div className='row' style={{marginRight: '2%',marginLeft: '2%', width:'99%',marginBottom:'15px',display:'inline-block',overflowY:'scroll',overflowX:'hidden', maxHeight:maxhdialrol}}>
        {vldzoekpopup}
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol}}
          message={this.state.updatetxt}/>
        <Row className="show-grid">
            <Col xs={12} md={6} sm={6} >
              {alggeg}
            </Col>
            <Col xs={12} md={6} sm={6} >
              {betrokken}
            </Col>
        </Row>
      </div>)    ;    
    // Einde velden definitei
    let voet = null;
    if (screen.width < 750)
    {
      voet = (<Toolbar style={{height:'40px',bottom:'100px',padding:'',zIndex:'9999999996',display:this.state.dispvoet}} zbottom={true}>
          <Button id="ButUpdPop" style={{float:'right', marginRight:'10px',marginTop:'5px'}} bsStyle="primary" bsSize="small" onClick={this.Butbewaren}>Bewaren</Button> 
        </Toolbar>);
    }
    else
    {
      voet = (<div style = {{margin:0,paddingTop:'10px',paddingLeft:'10px',paddingBottom:'10px',color:'white',lineHeight:'32px',bottom:'0px',background: 'rgba(0, 143, 183, .05)',position:'absolute', width: '100%', borderTop: 'rgba(0, 143, 183, .2)', borderTopStyle: 'solid', borderTopWidth: 'thin', }} >
              <Button id="ButUpdPop" style={{float:'right', marginRight:'10px'}} bsStyle="primary" bsSize="small" onClick={this.Butbewaren}>Bewaren</Button> 
              </div>) ;
    }

    let bgroep = null;
    if (screen.width < 750) 
    {   
      let hrbut1 = (
                      <IconButton
                      style={{width:'30px',height:'30px', paddingLeft:'5px', paddingRight:'5px',display:this.state.ldisplay}}
                      iconClassName="fa fa-floppy-o" 
                      iconStyle={{color:'white', iconHoverColor:'gray',fontSize:'small'}}
                      onClick={this.Butbewaren} />
                    ) ;

      let hrbut2 = (
                      <IconButton
                      style={{width:'30px',height:'30px', paddingLeft:'5px', paddingRight:'5px',display:this.state.ldisplay}}
                      iconClassName="fa fa-trash-o" 
                      iconStyle={{color:'white', iconHoverColor:'gray',fontSize:'small'}}
                      onClick={this.Butverwijder}/>
                    ) ;


      bgroep = (
       <ButtonGroup vertical style={{position:'fixed', top:'25%', right:'10px',
          zIndex:'99999999',
          display:this.state.dispvoet,
          boxShadow: '0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22)',
          borderRadius: '2px',
          borderTopLeftRadius: '2px',
          borderTopRightRadius: '2px',
          borderBottomRightRadius: '2px',
          borderBottomLeftRadius: '2px',
          backgroundColor: 'rgba(0, 143, 183,.7)',
          width:'30px',
          paddingTop: '5px',
          paddingBottom: '5px'}}>
          {hrbut1}
          {hrbut2}
       </ButtonGroup>);                                       
    }
    let mwdth = (screen.width < 750) ? '100%' : '90%';
    let wdth = (screen.width < 750) ? '100%' : '90%';
    let cmwdth = (screen.width < 750) ? '100%' : '98%';
    let cwdth = (screen.width < 750) ? '100%' : '98%';
    let clft = (screen.width < 750) ? '0' : '5%';
    return ( 
     <Dialog 
        ref="dafwcontrole"
        title="Detail Fixture"
        second={true}
        sluiten={this.sluiten}
        onDismiss={this.zetsluiten}
        rbutgroep={bgroep}
        style={{fontSize: '100%', width:wdth, maxWidth:mwdth}}
        contentStyle={{width:cwdth, maxWidth:cmwdth, left:clft,maxHeight:maxhdial,overflowY:'hidden'}}
        footer= {voet}
        id="actcont1"
        hidetoolb={true}        
        notoolb={true}
        paddingtop={this.props.paddingtop}
        zindex={this.props.zindex}        
        openImmediately={false}
        repositionOnUpdate={false}>
        {tot_zicht1}
      </Dialog>)
  },
  sluiten(data){
    if (this.props.Sluiten) {this.props.Sluiten(data)}
  },
  zetsluiten() {
    if (this.props.Sluiten) {this.props.Sluiten()}
  },
  openen(hudata){
    this.state.sleutel = hudata.id;
    this.refs.dafwcontrole.show();
    let status,creator,uitvoerder,klacht
    let sleutel = hudata.id;

    this.state.origin = hudata.Origin,
    this.setState({err_naam: ""});
      let hdata = {
        id:'0',
        name:'',
        accountname:'',
        consid:App.ConsId,
        accountid:'',
        mihigh:'0',
        mahigh:'0',
        stphigh:'0',
        milow:'0',
        malow:'0',
        stplow:'0',
        statextnumb:'0',
        stptextnumb:'0',
        textchar:''};
      if (hudata.accountid)
      {
        hdata.accountid = hudata.accountid;
        hdata.accountname = hudata.accountname;
      }
      this.setState({data:hdata});
  },
  ZetActGeg(hdata){
    this.setState({data:hdata});
  },
  Butbewaren() {
    // Update Data
    this.setState({errnaam:'',errhigh:'',errlow:'',errstep:'',errklant:''});
    let snackb = this.refs.snackbar;
    let insql = (this.state.sleutel.length >3) ? 'UPD' : 'INS';
    this.state.data.id = this.state.sleutel;
    this.state.data.mihigh = this.refs.Inpmihigh.getValue();
    this.state.data.mahigh = this.refs.Inpmahigh.getValue();
    this.state.data.stphigh = this.refs.Inpstphigh.getValue();
    this.state.data.milow = this.refs.Inpmilow.getValue();
    this.state.data.malow = this.refs.Inpmalow.getValue();
    this.state.data.stplow = this.refs.Inpstplow.getValue();
    this.state.data.statextnumb = this.refs.Inpstatextnumb.getValue();
    this.state.data.stptextnumb = this.refs.Inpstptxtnumb.getValue();
    this.state.data.textchar = this.refs.Inptxtchar.getValue();
    this.state.data.name = this.refs.ActInpOms.getValue();
    this.state.data.accountid = this.refs.InpLid.getKey();
    this.state.data.accountname = this.refs.InpLid.getValue();
    this.state.data.consid = App.ConsId;
    ClsAct.Bewarencons(this.state.data,insql)
      .then(data => {
          this.sluiten(data);
      })
      .catch(data => {
          this.setState({errnaam: data.errnaam});
          this.setState({errhigh: data.errhigh});
          this.setState({errlow: data.errlow});
          this.setState({errstep: data.errstep});
          this.setState({errklant: data.errklant});
          this.setState({updatecol: 'red'});
          this.setState({updatetxt: 'Gegevens zijn niet aangepast'});
          snackb.show();
          return true;
    });
  },  
  LidZoek() {
    this.setState({dispvoet:'none'});
    this.refs.RefZoekPopup.openen('/klanten','InpLid');
  },
  klikzkpop(waarde,veld) {
    switch (veld) {
        case 'InpLid':
          this.refs.InpLid.setValue(waarde.vld1);
          this.refs.InpLid.setKey(waarde.id);
          if(this.refs.InpContact) {this.refs.InpContact.setValue('')}
          if(this.refs.InpContact) {this.refs.InpContact.setKey(0)}
          if(this.refs.InpKlacht) {this.refs.InpKlacht.setValue('')}
          if(this.refs.InpKlacht) {this.refs.InpKlacht.setKey('')}
          if(this.refs.Inpticket) {this.refs.Inpticket.setValue('')};
          if(this.refs.Inpticket) {this.refs.Inpticket.setKey('')};

          if(this.refs.InpContact) {this.ZetContData(waarde.id)}
          if(this.refs.InpKlacht) {this.ZetAccReqData(waarde.id)}
          if(this.refs.Inpticket) {this.ZetAccTikData(waarde.id)}
          break;
    }
    this.refs.RefZoekPopup.sluiten();
    this.setState({dispvoet:'inherit'});
  }, 
  sluitzkpop() {
    this.setState({dispvoet:'inherit'});
  }, 
});
module.exports = Popdeftexture;






