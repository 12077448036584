module.exports={
  "name": "babel-core",
  "version": "5.8.25",
  "description": "A compiler for writing next generation JavaScript",
  "author": {
    "name": "Sebastian McKenzie",
    "email": "sebmck@gmail.com"
  },
  "homepage": "https://babeljs.io/",
  "license": "MIT",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/babel/babel.git"
  },
  "browser": {
    "./lib/api/register/node.js": "./lib/api/register/browser.js"
  },
  "keywords": [
    "6to5",
    "babel",
    "classes",
    "const",
    "es6",
    "harmony",
    "let",
    "modules",
    "transpile",
    "transpiler",
    "var"
  ],
  "scripts": {
    "bench": "make bench",
    "test": "make test"
  },
  "dependencies": {
    "babel-plugin-constant-folding": "^1.0.1",
    "babel-plugin-dead-code-elimination": "^1.0.2",
    "babel-plugin-eval": "^1.0.1",
    "babel-plugin-inline-environment-variables": "^1.0.1",
    "babel-plugin-jscript": "^1.0.4",
    "babel-plugin-member-expression-literals": "^1.0.1",
    "babel-plugin-property-literals": "^1.0.1",
    "babel-plugin-proto-to-assign": "^1.0.3",
    "babel-plugin-react-constant-elements": "^1.0.3",
    "babel-plugin-react-display-name": "^1.0.3",
    "babel-plugin-remove-console": "^1.0.1",
    "babel-plugin-remove-debugger": "^1.0.1",
    "babel-plugin-runtime": "^1.0.7",
    "babel-plugin-undeclared-variables-check": "^1.0.2",
    "babel-plugin-undefined-to-void": "^1.1.6",
    "babylon": "^5.8.23",
    "bluebird": "^2.9.33",
    "chalk": "^1.0.0",
    "convert-source-map": "^1.1.0",
    "core-js": "^1.0.0",
    "debug": "^2.1.1",
    "detect-indent": "^3.0.0",
    "esutils": "^2.0.0",
    "fs-readdir-recursive": "^0.1.0",
    "globals": "^6.4.0",
    "home-or-tmp": "^1.0.0",
    "is-integer": "^1.0.4",
    "js-tokens": "1.0.1",
    "json5": "^0.4.0",
    "line-numbers": "0.2.0",
    "lodash": "^3.10.0",
    "minimatch": "^2.0.3",
    "output-file-sync": "^1.1.0",
    "path-exists": "^1.0.0",
    "path-is-absolute": "^1.0.0",
    "private": "^0.1.6",
    "regenerator": "0.8.35",
    "regexpu": "^1.1.2",
    "repeating": "^1.1.2",
    "resolve": "^1.1.6",
    "shebang-regex": "^1.0.0",
    "slash": "^1.0.0",
    "source-map": "^0.4.0",
    "source-map-support": "^0.2.10",
    "to-fast-properties": "^1.0.0",
    "trim-right": "^1.0.0",
    "try-resolve": "^1.0.0"
  },
  "bugs": {
    "url": "https://github.com/babel/babel/issues"
  },
  "_id": "babel-core@5.8.25",
  "_shasum": "4d05fbd6497651105c016ccb278f5bf1cabd1f73",
  "_from": "babel-core@>=5.8.21 <6.0.0",
  "_npmVersion": "2.11.3",
  "_nodeVersion": "0.12.7",
  "_npmUser": {
    "name": "sebmck",
    "email": "sebmck@gmail.com"
  },
  "dist": {
    "shasum": "4d05fbd6497651105c016ccb278f5bf1cabd1f73",
    "tarball": "http://registry.npmjs.org/babel-core/-/babel-core-5.8.25.tgz"
  },
  "maintainers": [
    {
      "name": "sebmck",
      "email": "sebmck@gmail.com"
    }
  ],
  "directories": {},
  "_resolved": "https://registry.npmjs.org/babel-core/-/babel-core-5.8.25.tgz",
  "readme": "ERROR: No README data found!"
}
