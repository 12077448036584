
// used by the tree-walking updates and springs. Avoids some allocations
"use strict";

exports.__esModule = true;
exports["default"] = zero;

function zero() {
  return 0;
}

module.exports = exports["default"];