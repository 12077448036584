module.exports = {

  addDays(d, days) {
    let newDate = this.clone(d);
    newDate.setDate(d.getDate() + days);
    return newDate;
  },

  HandleVeldLeeg(data,veld, vorm) {
    let terug = {data:"",
      fout: false,
      boodschap:"",
    };
    if (!data) 
    { 
        terug.fout = true;
        terug.data = '';
        terug.boodschap='Veld mag niet leeg zijn.';
    }
    else
    {
        if (data.length < 1)
        {
          terug.fout = true;
          terug.data = '';
          terug.boodschap='Veld mag niet leeg zijn.';
        }
        else
        {
          terug.fout = false;
          terug.data = data;
          terug.boodschap='';
        }
    }
    return terug
},     
ZetDatumOutp(datum){
  if (datum && datum.length > 9)
  {
    var res = datum.split("-");
    let d = res[0];
    let m = res[1];
    let y = res[2];
    if (res[0].length == 4)
    {
       d = res[2];
       m = res[1];
       y = res[0];

    }
    if ((isNaN(d)==false) && (isNaN(m)==false) && (isNaN(y)==false) )
    {
      var hdatum = d+"-"+m+"-"+y;
      return(hdatum);
    }
    else
    {
      return('');                    
    }
  }
  else
  {
    return datum;
  }
},
  stringToDate(_date,_format,_delimiter)
  {
      if (_date)
      {
            var formatLowerCase=_format.toLowerCase();
            var formatItems=formatLowerCase.split(_delimiter);
            var dateItems=_date.split(_delimiter);
            var monthIndex=formatItems.indexOf("mm");
            var dayIndex=formatItems.indexOf("dd");
            var yearIndex=formatItems.indexOf("yyyy");
            var month=parseInt(dateItems[monthIndex]);
            month-=1;
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
            return formatedDate;
      }
      else
      {
        return null;
      }
  },  


InsLog(id,type,actie,sqlstat,functie){
  let hdata = {id:id, type:type,actie:actie};
  var aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=toevlogging',
      data: JSON.stringify(hdata),
      success: function(data){
        if (functie)
        {
          if (sqlstat == 'GetAllLog')
          {
              functie('false');
          }
        }
      },
      error: function(data){
      }
  });
},

GetLand(sleutel){
  let hdata;
  var aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=GetLand&id='+sleutel,
      data: JSON.stringify(hdata),
      success: function(data){
        var land = data.land;
        alert(land);
        return land;
      },
      error: function(data){
        alert(fout);
        return '';
      }
  });
  
},


HandleChkMail(data,veld, vorm) {
  let terug = {data:"",
      fout: false,
      boodschap:""};

  if (data) 
  { 
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let restest = re.test(data);
    if (!restest) {
      terug.fout = true;
      terug.data = "";
      terug.boodschap='Dit is geen correct E-mail adres';
      return terug
    }
    else
    {
      terug.fout = false;
      terug.data = data;
      terug.boodschap='';
      return terug
    };
  }
  return terug
},     


};
