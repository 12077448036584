'use strict';

var _extends = require('babel-runtime/helpers/extends')['default'];

var _objectWithoutProperties = require('babel-runtime/helpers/object-without-properties')['default'];

var _interopRequireDefault = require('babel-runtime/helpers/interop-require-default')['default'];

exports.__esModule = true;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _reactPropTypesLibElementType = require('react-prop-types/lib/elementType');

var _reactPropTypesLibElementType2 = _interopRequireDefault(_reactPropTypesLibElementType);

var _utilsCreateChainedFunction = require('./utils/createChainedFunction');

var _utilsCreateChainedFunction2 = _interopRequireDefault(_utilsCreateChainedFunction);


var Row = _react2['default'].createClass({
  displayName: 'Row',

  propTypes: {
    /**
     * You can use a custom element for this component
     */
 //   componentClass: _reactPropTypesLibElementType2['default'],
    onClick: _react2['default'].PropTypes.func
  },

  getDefaultProps: function getDefaultProps() {
    return {
 //     componentClass: 'div'
    };
  },
  


  render: function render() {
//    var ComponentClass = this.props.componentClass;
    var ComponentClass = 'div';
    var _props = this.props;
    var onClick = _props.onClick;

    var props = _objectWithoutProperties(_props, ['active', 'disabled', 'role', 'href', 'onClick', 'className', 'style','componentClass','edata']);


    return _react2['default'].createElement(
      ComponentClass,
      _extends({}, this.props, { className: _classnames2['default'](this.props.className, 'row'),
            onClick: _utilsCreateChainedFunction2['default'](onClick, this.handleClick)   }),
      this.props.children
    );
  },

  handleClick: function handleClick(e) {
    if (this.props.edata)
    {
      this.props.onClick(e,this.props.edata);
      e.preventDefault();

    }
    else
    {
      if (this.props.onClick)
      {
        this.props.onClick(e);
        e.preventDefault();
      }
    }
  }  
});


exports['default'] = Row;
module.exports = exports['default'];