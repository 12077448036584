module.exports = {
    toLowerFirst     : require('./src/toLowerFirst'),
    toUpperFirst     : require('./src/toUpperFirst'),
    separate         : require('./src/separate'),
    stripWhitespace  : require('./src/stripWhitespace'),
    compactWhitespace: require('./src/compactWhitespace'),
    camelize         : require('./src/camelize'),
    humanize         : require('./src/humanize'),
    hyphenate        : require('./src/hyphenate'),
    endsWith         : require('./src/endsWith'),

    is: require('./src/is')
}