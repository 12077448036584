const React = require('react');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');

const TextField = require('../matui/text-field.jsx');
const Paper = require('../matui/paper.jsx');
const Dialog = require('../matui/dialog.jsx');
const DialogWindow = require('../matui/dialog.jsx');

const FlatButton = require('../matui/flat-button.jsx');
const FloatingActionButton = require('../matui/floating-action-button.jsx');
const Snackbar = require('../matui/snackbar.jsx');


const Changepasw = React.createClass({
  mixins: [PureRenderMixin],
  getInitialState() {
    return {
      data: {
              opasw: "",
              npasw: "",
              cons_id:0}, 
    }
  },

  handleChange(field, event) {
    s = {};
    s[field] = event.target.value;
    this.setState(s);
  },

  sluiten(){
    this.refs.DLoginPW.dismiss();
  },

  openen(){
    this.state.render = false;
    this.refs.DLoginPW.show();
  },

  login() {
    if (this.state.render) {return};
    this.state.data.opasw = this.refs.RLogin.getValue();
    this.state.data.npasw = this.refs.RPassword.getValue();
    this.state.data.cons_id = App.userid;      
    let snackb = this.refs.snackbar;

    var that = this;
    if (this.state.data.opasw.length < 6)
    {
      this.setState({err_login: "Paswoord moet meer dan 5 tekens bevatten"});
      return;
    }

    if (this.state.data.npasw.length < 6)
    {
      this.setState({err_passw: "Paswoord moet meer dan 5 tekens bevatten"});
      return;
    }

    let aj2 = $.ajax({
    type: "POST",
    dataType: "json",
    url: './server/index.php?function=SetPassword',
    data: JSON.stringify(that.state.data),
    success: function(data){
      that.refs.DLoginPW.dismiss();          
      that.props.onLogin(data, that.props.Hoofd);
    },
    error: function(data){
      that.refs.DLoginPW.dismiss();
      that.props.onLogin(data, that.props.Hoofd);
    }
    });
  },

  cancel() {
      that.refs.DLoginPW.dismiss();          
  },  
    

  componentDidMount() {
    $(":input:first").focus();
    $( "#cont1" ).keypress(function(e) 
    {
      if ( e.which == 13 ) 
      {
        $( "#logclick" ).click();
      }
    });
    $( "#kl3" ).keypress(function(e) 
    {
      if ( e.which == 13 ) 
      {
        $( "#logclick" ).click();
      }
    });
    $( "#kl4" ).keypress(function(e) 
    {
      if ( e.which == 13 ) 
      {
        $( "#logclick" ).click();
      }
    });


  },

  render() {
    const props = this.props;
    let customActions = [
      <FlatButton
        id="logclickcanc"
        key="logclickcanc"
        label="Terug"
        primary={true}
        onClick={this.cancel}
        onTouchTap={this.cancel} />,
      <FlatButton
        id="logclick"
        key="logclick11"
        label="Aanpassen"
        primary={true}
        onClick={this.login}
        onTouchTap={this.login} />,

    ];

    return (
      <Dialog
        ref='DLoginPW'
        title="Veranderen paswoord"
        id="cont1"
        actions={customActions}
        onKeyDown= {this._onKeyDown}
        style={{fontSize: '14px', width:'70%', maxWidth:'70%'}}
        contentStyle={{width:'50%', maxWidth:'60%', left:'25%'}}
        openImmediately={false}
        modal={false}>
        <Snackbar key='lgsnb1' id="snb1" ref="snackbar" style={{backgroundColor: 'red'}} message="Verkeerde login gegevens"/>
        <div  id="kl2" className="row" style={{marginRight: '15px',marginLeft: '15px'}}>
          <TextField
            id="kl3"
            style={{marginRight: '15px',marginLeft: '15px', width: '100%'}}
            floatingLabelText="Oud paswoord"
            ref="RLogin" 
            type='password'
            onChange={this._HandleLogin} 
            errorText={this.state.err_login} 
            onSubmit={this.login} />
        </div>
        <div id="kl4" className="row" style={{marginRight: '15px',marginLeft: '15px'}}>
            <TextField
              id="kl5"
              style={{marginRight: '15px',marginLeft: '15px', width: '100%'}}
              hintText="Nieuw paswoord"
              ref="RPassword" 
              type="password"
              floatingLabelText="Paswoord"
              errorText={this.state.err_passw}
              onChange={this._HandlePassw}
              onSubmit={this.login}  />
        </div>
      </Dialog>
    );
  },

  _HandleLogin(e) {

    this.setState({err_login: e.target.value ? '' : 'Verplichte ingave'});
    this.state.data.opasw = e.target.value;
  },
   
  _HandlePassw(e) {
    this.setState({err_passw: e.target.value ? '' : 'Verplichte ingave'});
    this.state.data.npasw = e.target.value;    
  },

  _onKeyDown(e) {
    switch(e.which) {
      case KeyCode.UP:
        if (!this.state.open)
          this._selectPreviousItem();
        else
          if (e.altKey)
            this.setState({open:false});
        break;
      case KeyCode.DOWN:
        if (!this.state.open)
          if (e.altKey)
            this.setState({open:true});
          else
            this._selectNextItem();
        break;
      case KeyCode.ENTER:
      case KeyCode.SPACE:
        this.setState({open:true});
        break;
      default:
        return; //important
    }
    e.preventDefault();
  }    

});
module.exports = Changepasw;


