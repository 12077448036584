const React = require('react');

const PureRenderMixin = require('react-addons-pure-render-mixin');
const TextField = require('../matui/text-field.jsx');
var Modal = require("react-bootstrap").Modal;
var Button = require("react-bootstrap").Button;

const Paper = require('../matui/paper.jsx');
const Dialog = require('../matui/dialog.jsx');
const DialogWindow = require('../matui/dialog.jsx');

const Toolbar = require('../matui/toolbar/toolbar.jsx');

const ToolbarGroup = require('../matui/toolbar/toolbar-group.jsx');
const ToolbarSeparator = require('../matui/toolbar/toolbar-separator.jsx');
const ToolbarTitle = require('../matui/toolbar/toolbar-title.jsx');

const FlatButton = require('../matui/flat-button.jsx');
const FloatingActionButton = require('../matui/floating-action-button.jsx');
const Snackbar = require('../matui/snackbar.jsx');

import SocialButton from '../soclogin/src/socialButton.js';


const handleSocialLogin = (user, err) => {
  console.log(user)
  console.log(err)
};

const Login = React.createClass({
  mixins: [PureRenderMixin],
  getInitialState() {
    return {
      login: '',
      password: '',
      error: '',
      openi: false,
      dopen: true,
      render: false,
    }
  },
  componentWillMount(){
    this.state = {
      logged: false,
      user: {},
      currentProvider: ''
    }
    this.nodes = {}
  },

  setNodeRef (provider, node) {
    if (node) {
      this.nodes[ provider ] = node
    }
  },

  onLoginSuccess (user) {
    let that =this;
        that.state.render = true;
        $.ajax({
          type: "POST",
          dataType: "json",
          url: './server/index.php?function=Login',
          data: JSON.stringify(user),
          success: function(data){
            that.setState({error: data.err});
            if (data.allowed) {
              that.props.Hoofd.state.loggedIn = false;
              that.props.onLogin(data, that.props.Hoofd);
              return;
            } 
            else
            {
              that.setState({err_login: 'Verkeerde Login'});
              that.setState({err_passw: 'Foutief paswoord'});
              that.state.render = false;
              return;
            }
          },
          error: function(data){
            that.setState({err_login: 'Verkeerde Login'});
            that.setState({err_passw: 'Foutief paswoord'});
            that.state.render = false;
            return;
          }
        });

  

    this.setState({
      logged: true,
      currentProvider: user._provider,
      user
    })
  },

  onLoginFailure (err) {
    console.error(err)

    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    })
  },

  onLogoutSuccess () {
    this.setState({
      logged: false,
      currentProvider: '',
      user: {}
    })
  },

  onLogoutFailure (err) {
    console.error(err)
  },

  logout () {
    const { logged, currentProvider } = this.state

    if (logged && currentProvider) {
      this.nodes[currentProvider].props.triggerLogout()
    }
  },


  handleChange(field, event) {
    s = {};
    s[field] = event.target.value;
    this.setState(s);
  },

  sluiten(){
    this.refs.DLogin.dismiss();
  },

  openen(){
    this.state.render = false;
    if (screen.width < 750)
    {
      let loginapp =   docCookies.getItem("apollo_login");
      let paswoord =  docCookies.getItem("apollo_password");   
      let uid = docCookies.getItem("apollo_uid"); 
      if (login)
      {
        this.state.login = login;
        this.state.password = paswoord;
        var that = this;
        $.ajax({
          type: "POST",
          dataType: "json",
          url: './server/index.php?function=Login',
          data: JSON.stringify(this.state),
          success: function(data){
            that.setState({error: data.err});
            if (data.allowed) {
              that.props.Hoofd.state.loggedIn = false;
              that.props.onLogin(data, that.props.Hoofd);
              return;
            } 
            else
            {
              that.setState({err_login: 'Verkeerde Login'});
              that.setState({err_passw: 'Foutief paswoord'});
              that.state.render = false;
              return;
            }
          },
          error: function(data){
            that.setState({err_login: 'Verkeerde Login'});
            that.setState({err_passw: 'Foutief paswoord'});
            that.state.render = false;
            return;
          }
        });
      }
    }
    else
    {
      this.refs.DLogin.show();
    }
  },


  login() {
    if (this.state.render) {return};
    this.state.login = this.refs.RLogin.getValue();
    this.state.password = this.refs.RPassword.getValue();
    var that = this;
    if (!this.state.password)
    {
      this.setState({err_passw: 'Verplichte ingave'});
      if (!this.state.login)
      {
        this.setState({err_login: 'Verplichte ingave'});
      }
    }
    else
    {
      if (!this.state.login)
      {
        this.setState({err_login: 'Verplichte ingave'});
      }
      else
      {
        that.state.render = true;
        $.ajax({
          type: "POST",
          dataType: "json",
          url: './server/index.php?function=Login',
          data: JSON.stringify(this.state),
          success: function(data){
            that.setState({error: data.err});
            if (data.allowed) {
              that.props.Hoofd.state.loggedIn = false;
              that.props.onLogin(data, that.props.Hoofd);
              return;
            } 
            else
            {
              that.setState({err_login: 'Verkeerde Login'});
              that.setState({err_passw: 'Foutief paswoord'});
              that.state.render = false;
              return;
            }
          },
          error: function(data){
            that.setState({err_login: 'Verkeerde Login'});
            that.setState({err_passw: 'Foutief paswoord'});
            that.state.render = false;
            return;
          }
        });
      }
    }
  },

  loginlink() {
    if (this.state.render) {return};
    var that = this;
    that.state.render = true;
    $.ajax({
      url: './server/login_with_linkedin2.php',
          dataType: 'json',
          type: 'POST',
          data: JSON.stringify(this.state)
      }).done(function(data) {
        that.setState({error: data.err});
        if (data.allowed) {
          that.props.Hoofd.state.loggedIn = false;
          that.props.onLogin(data, that.props.Hoofd);
          return;
       } else
       {
        snackb.show();
        that.state.render = false;
       }
    });
  },  
    

  componentDidMount() {
    $("#kl3").focus();
    $( "#cont1" ).keypress(function(e) 
    {
      if ( e.which == 13 ) 
      {
        $( "#logclick" ).click();
      }
    });
    $( "#kl3" ).keypress(function(e) 
    {
      if ( e.which == 13 ) 
      {
        $( "#logclick" ).click();
      }
    });
    $( "#kl4" ).keypress(function(e) 
    {
      if ( e.which == 13 ) 
      {
        $( "#logclick" ).click();
      }
    });


    if (screen.width < 750)
    {
      let loginapp =   docCookies.getItem("apollo_login");
      let paswoord =  docCookies.getItem("apollo_password");   
      let uid = docCookies.getItem("apollo_uid"); 
      if (loginapp)
      {
        this.state.login = loginapp;
        this.state.password = paswoord;
        var that = this;
        $.ajax({
          type: "POST",
          dataType: "json",
          url: './server/index.php?function=Login',
          data: JSON.stringify(this.state),
          success: function(data){
            that.setState({error: data.err});
            if (data.allowed) {
              that.props.Hoofd.state.loggedIn = false;
              that.props.onLogin(data, that.props.Hoofd);
              return;
            } 
            else
            {
              that.refs.DLogin.show();              
              that.setState({err_login: 'Verkeerde Login'});
              that.setState({err_passw: 'Foutief paswoord'});
              that.state.render = false;
              return;
            }
          },
          error: function(data){
            that.refs.DLogin.show();            
            that.setState({err_login: 'Verkeerde Login'});
            that.setState({err_passw: 'Foutief paswoord'});
            that.state.render = false;
            return;
          }
        });
      }
    }
    else
    {
      this.refs.DLogin.show();
    }

  },

  render() {
    const props = this.props;
//    let voet = (<div style = {{margin:0,paddingTop:'10px',paddingLeft:'10px',paddingBottom:'10px',color:'white',lineHeight:'32px',bottom:'0px',position:'absolute', 
//                              width: '100%', borderTop: 'rgba(0, 143, 183, .8)', borderTopStyle: 'solid', borderTopWidth: 'thin', }} >
//                <Button id="logclick" style={{float:'right', marginRight:'10px'}}bsStyle="primary" bsSize="small" onClick={this.login}>Inloggen</Button> </div>);

    let socloglin = ( 
        <SocialButton
          id='linkbut'
          provider='linkedin'
          appId='86ij047htl8h2m'
          onLoginSuccess={this.onLoginSuccess}
          onLoginFailure={this.onLoginFailure}
          onLogoutSuccess={this.onLogoutSuccess}
          getInstance={this.setNodeRef.bind(this, 'linkedin')}
          key={'linkedin'}
        >
          Linkedin Login
        </SocialButton>);

    let soclogfb = ( 
        <SocialButton
          provider='facebook'
          appId='158153771488484'
          onLoginSuccess={this.onLoginSuccess}
          onLoginFailure={this.onLoginFailure}
          onLogoutSuccess={this.onLogoutSuccess}
          getInstance={this.setNodeRef.bind(this, 'facebook')}
          key={'facebook'}
          id='fbbut'
        >
          Facebook Login
        </SocialButton>);

    let socloggoog = ( 
        <SocialButton
          provider='google'
          appId='503335531390-ssm0p9a82r4ploj68lt7jov9cfamn8d7.apps.googleusercontent.com'
          onLoginSuccess={this.onLoginSuccess}
          onLoginFailure={this.onLoginFailure}
          onLogoutSuccess={this.onLogoutSuccess}
          onLogoutFailure={this.onLogoutFailure}
          getInstance={this.setNodeRef.bind(this, 'google')}
          key={'google'}
          id='google'
        >
          Google Login
        </SocialButton>);


    let voet = (screen.width < 750) ? 
    (<Toolbar style={{height:'40px',bottom:'0px',padding:'',zIndex:'999999999'}} zbottom={true}>
     <Button id="logclick" style={{float:'right', marginRight:'10px',marginTop:'5px'}} bsStyle="primary" bsSize="small" onClick={this.login}>Inloggen</Button>
     </Toolbar>):
    (<div style = {{margin:0,paddingTop:'10px',paddingLeft:'10px',paddingBottom:'10px',color:'white',lineHeight:'32px',bottom:'0px',position:'absolute', 
                              width: '100%' }} >
                <Button id="logclick" style={{float:'right', marginRight:'10px'}}bsStyle="primary" bsSize="small" onClick={this.login}>Inloggen</Button> 
                
    </div>);

    return (
      <Dialog
        ref='DLogin'
        title="Login"
        footer= {voet}
        id="cont1"
        notoolb={true}
        onKeyDown= {this._onKeyDown}
        openImmediately={true}
        modal={true}>
        <Snackbar key='lgsnb1' id="snb1" ref="snackbar" style={{backgroundColor: 'red'}} message="Verkeerde login gegevens"/>
        <div  id="kl2" className="row" style={{marginRight: '15px',marginLeft: '15px'}}>
          <TextField
            id="kl3"
            style={{marginRight: '15px',marginLeft: '15px', width: '95%'}}
            floatingLabelText="Login"
            ref="RLogin" 
            onChange={this._HandleLogin} 
            errorText={this.state.err_login} 
            onSubmit={this.login} />
        </div>
        <div id="kl4" className="row" style={{marginRight: '15px',marginLeft: '15px',paddingBottom: '25px'}}>
            <TextField
              id="kl5"
              style={{marginRight: '15px',marginLeft: '15px', width: '95%'}}
              hintText="Paswoord"
              ref="RPassword" 
              type="password"
              floatingLabelText="Paswoord"
              errorText={this.state.err_passw}
              onChange={this._HandlePassw}
              onSubmit={this.login}  />
        </div>
      </Dialog>
    );
  },



  _HandleLogin(e) {

    this.setState({err_login: e.target.value ? '' : 'Verplichte ingave'});
    this.setState({login: e.target.value});
  },
   
  _HandlePassw(e) {
    this.setState({err_passw: e.target.value ? '' : 'Verplichte ingave'});
    this.setState({password: e.target.value});
  },

  _onKeyDown(e) {
    switch(e.which) {
      case KeyCode.UP:
        if (!this.state.open)
          this._selectPreviousItem();
        else
          if (e.altKey)
            this.setState({open:false});
        break;
      case KeyCode.DOWN:
        if (!this.state.open)
          if (e.altKey)
            this.setState({open:true});
          else
            this._selectNextItem();
        break;
      case KeyCode.ENTER:
      case KeyCode.SPACE:
        this.setState({open:true});
        break;
      default:
        return; //important
    }
    e.preventDefault();
  }    

});
module.exports = Login;

let docCookies = {
  getItem: function (sKey) {
      if (!sKey) { return null; }
      return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
      if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
      var sExpires = "";
      if (vEnd) {
        switch (vEnd.constructor) {
          case Number:
            sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
            break;
          case String:
            sExpires = "; expires=" + vEnd;
            break;
          case Date:
            sExpires = "; expires=" + vEnd.toUTCString();
            break;
        }
      }
      document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
      return true;
  },
  removeItem: function (sKey, sPath, sDomain) {
      if (!this.hasItem(sKey)) { return false; }
        document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
        return true;
  },
  hasItem: function (sKey) {
      if (!sKey) { return false; }
        return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },
  keys: function () {
      var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
      for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
      return aKeys;
  }

};

