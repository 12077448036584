const React = require('react');
const ReactDOM = require('react-dom');
const IconButOver = require('../matui/icon_but_over.jsx');
const Contstafunc = require('./cont_func.jsx');
const AlgFuncUpd = require('../functions/algemeen');

module.exports = {
    GetAccount(hdata){
        return new Promise(
            function (resolve, reject) {
                
                let aj2 = $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: './server/index.php?function=GetAccount',
                    data: JSON.stringify(hdata),
                    success: function(data){
                      resolve(data);
                    },
                    error: function(data){
                        resolve([]);
                    }
                    });
            });
   
    },
   Bewaren(hdata,Sql) {
        return new Promise(
            function (resolve, reject) {
            let foutdisp = {err_naam:'',err_mail:'',error:false};
            let fout = false;
            let Controle  = '';
            Controle = AlgFuncUpd.HandleVeldLeeg(hdata.account_name, '','');
            foutdisp.err_naam = Controle.boodschap;

            if (!foutdisp.error) {foutdisp.error = Controle.fout};
            if (hdata.adressen) {arra = hdata.adressen};
            if (arra.length > 0)
            {
                for (let rowIdx = 0; rowIdx < arra.length; rowIdx++) 
                {
                    let filrow = arra[rowIdx];
                    Controle = AlgFuncUpd.HandleChkMail(filrow.email,'','');
                    if (!foutdisp.error) {foutdisp.error = Controle.fout};
                    if (Controle.fout) {foutdisp.err_mail = Controle.boodschap}
                }
            }
            if (foutdisp.error) {
               reject(foutdisp);
               return;
            }
            let arra = [];            
            let insql = (Sql == 'UPD') ? 'UpdAccount' : 'CreaAccount';
            let aj2 = $.ajax({
                type: "POST",
                dataType: "json",
                url: './server/index.php?function='+insql,
                data: JSON.stringify(hdata),
                success: function(data){
                   if (data.error) 
                   {
                      reject({error:'Aanpassingen niet doorgevoerd'});
                   }
                   else
                   {
                      resolve(data);
                   }
                },
                error: function(data){
                   reject({error:'Aanpassingen niet doorgevoerd'});
                  }
                });
            });
    },     


};


