import React, { Component } from 'react';
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');

const TextField = require('../matui/text-field.jsx');
const FlatButton = require('../matui/flat-button.jsx');
const Snackbar = require('../matui/snackbar.jsx');

const MobButton = require('../mobile/src/ui/button');

import SocialButton from '../soclogin/src/socialButton.js'




const LoginNw = React.createClass({
  mixins: [PureRenderMixin],
  getInitialState() {
    return {
      login: '',
      password: '',
      error: '',
      render: false,
    }
  },

  handleChange(field, event) {
    s = {};
    s[field] = event.target.value;
    this.setState(s);
  },

  sluiten(){
    this.refs.DLogin.dismiss();
  },

  openen(){
    this.state.render = false;
    this.refs.DLogin.show();
  },

  login() {
    if (this.state.render) {return};
    this.state.login = this.refs.RLogin.getValue();
    this.state.password = this.refs.RPassword.getValue();
    let snackb = this.refs.snackbar;
    var that = this;
    if (!this.state.password)
    {
      this.setState({err_passw: 'Verplichte ingave'});
      if (!this.state.login)
      {
        this.setState({err_login: 'Verplichte ingave'});
      }
    }
    else
    {
      if (!this.state.login)
      {
        this.setState({err_login: 'Verplichte ingave'});
      }
      else
      {
        that.state.render = true;
        $.ajax({
          url: './server/index.php?function=Login&login=eugene',
          dataType: 'json',
          type: 'POST',
          data: JSON.stringify(this.state)
        }).done(function(data) {
          that.setState({error: data.err});
          if (data.allowed) {
            that.props.Hoofd.state.loggedIn = false;
            that.props.onLogin(data, that.props.Hoofd);
            return;
          } else
          {
            snackb.show();
            that.state.render = false;
          }
        });
      }
    }
  },
  loginlink() {
    if (this.state.render) {return};
    var that = this;
    that.state.render = true;
    $.ajax({
      url: './server/login_with_linkedin2.php',
          dataType: 'json',
          type: 'GET',
          data: JSON.stringify(this.state)
      }).done(function(data) {
        that.setState({error: data.err});
        if (data.allowed) {
          that.props.Hoofd.state.loggedIn = false;
          that.props.onLogin(data, that.props.Hoofd);
          return;
       } else
       {
        snackb.show();
        that.state.render = false;
       }
    });
  },

  componentDidMount() {
    $(":input:first").focus();
    $( "#cont1" ).keypress(function(e) 
    {
      if ( e.which == 13 ) 
      {
        $( "#logclick" ).click();
      }
    });
    $( "#kl3" ).keypress(function(e) 
    {
      if ( e.which == 13 ) 
      {
        $( "#logclick" ).click();
      }
    });
    $( "#kl4" ).keypress(function(e) 
    {
      if ( e.which == 13 ) 
      {
        $( "#logclick" ).click();
      }
    });


  },

  render() {
    const props = this.props;
    let customActions = [
      <FlatButton
        id="logclick"
        key="logclick11"
        label="Inloggen"
        primary={true}
        onClick={this.login}
        onTouchTap={this.login} />,
      <FlatButton
        id="logclick"
        key="logclick11"
        label="Linkedin"
        primary={true}
        onClick={this.loginlink}
        onTouchTap={this.loginlink} />        
    ];

    let breed = (App.Mobile == true) ? '99%' : '70%';
    let mbreed = (App.Mobile == true) ? '99%' : '70%';
    let cbreed = (App.Mobile == true) ? '99%' : '50%';
    let cmbreed = (App.Mobile == true) ? '99%' : '60%';
    let cleft = (App.Mobile == true) ? '0%' : '25%';


    return (
      <div
        style={{width: '80%',
            maxWidth: '90%',
            height: '100%',
            paddingTop: '1%',
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingBottom: '5%',
            border: '#00bcd4',
            borderWidth: 'thin',
            borderStyle: 'solid',
            marginTop: '5%',
            marginLeft: '5%',
            marginRight: '5%',
            marginBottom: '5%',}}>
        <Snackbar key='lgsnb1' id="snb1" ref="snackbar" style={{backgroundColor: 'red'}} message="Verkeerde login gegevens"/>
        <div  id="kl2" className="row" style={{marginRight: '15px',marginLeft: '15px'}}>
          <TextField
            id="kl3"
            style={{marginRight: '15px',marginLeft: '15px', width: '100%'}}
            hintText="Login"
            ref="RLogin" 
            onChange={this._HandleLogin} 
            errorText={this.state.err_login} 
            onSubmit={this.login} />
        </div>
        <div id="kl4" className="row" style={{marginRight: '15px',marginLeft: '15px'}}>
            <TextField
              id="kl5"
              style={{marginRight: '15px',marginLeft: '15px', width: '100%'}}
              hintText="Paswoord"
              ref="RPassword" 
              type="password"
              errorText={this.state.err_passw}
              onChange={this._HandlePassw}
              onSubmit={this.login}  />
        </div>
      </div>
    );
  },

  _HandleLogin(e) {

    this.setState({err_login: e.target.value ? '' : 'Verplichte ingave'});
    this.setState({login: e.target.value});
  },
   
  _HandlePassw(e) {
    this.setState({err_passw: e.target.value ? '' : 'Verplichte ingave'});
    this.setState({password: e.target.value});
  },

  _onKeyDown(e) {
      alert("keyp");
    switch(e.which) {
      case KeyCode.UP:
        if (!this.state.open)
          this._selectPreviousItem();
        else
          if (e.altKey)
            this.setState({open:false});
        break;
      case KeyCode.DOWN:
        if (!this.state.open)
          if (e.altKey)
            this.setState({open:true});
          else
            this._selectNextItem();
        break;
      case KeyCode.ENTER:
      case KeyCode.SPACE:
        this.setState({open:true});
        break;
      default:
        return; //important
    }
    e.preventDefault();
  }    

});
module.exports = LoginNw;


