'use strict'

var separate     = require('./separate')
var camelize     = require('./camelize')
var toUpperFirst = require('./toUpperFirst')
var hyphenRe     = require('./hyphenRe')

function toLowerAndSpace(str, letter){
    return letter? ' ' + letter.toLowerCase(): ' '
}

module.exports = function(name, config){

    var str = config && config.capitalize?
                    separate(camelize(name), ' '):
                    separate(name, ' ').replace(hyphenRe, toLowerAndSpace)

    return toUpperFirst(str.trim())
}
