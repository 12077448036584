module.exports={
  "supplemental": {
    "version": {
      "_number": "$Revision: 13254 $",
      "_unicodeVersion": "9.0.0",
      "_cldrVersion": "31.0.1"
    },
    "timeData": {
      "AX": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "BQ": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "CP": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "CZ": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "DK": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "FI": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "ID": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "IS": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "ML": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "NE": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "RU": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "SE": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "SJ": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "SK": {
        "_allowed": "H",
        "_preferred": "H"
      },
      "AS": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "BT": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "DJ": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "ER": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "GH": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "GL": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "IN": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "LS": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "PG": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "PW": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "SO": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "TO": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "VU": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "WS": {
        "_allowed": "h H",
        "_preferred": "h"
      },
      "001": {
        "_allowed": "H h",
        "_preferred": "H"
      },
      "AL": {
        "_allowed": "h H hB",
        "_preferred": "h"
      },
      "fr_CA": {
        "_allowed": "h H hB",
        "_preferred": "h"
      },
      "TD": {
        "_allowed": "h H hB",
        "_preferred": "h"
      },
      "ca_ES": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "CF": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "CM": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "gl_ES": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "LU": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "NP": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "PF": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "SC": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "SN": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "TF": {
        "_allowed": "H h hB",
        "_preferred": "H"
      },
      "CY": {
        "_allowed": "h H hb hB",
        "_preferred": "h"
      },
      "GR": {
        "_allowed": "h H hb hB",
        "_preferred": "h"
      },
      "CO": {
        "_allowed": "h H hB hb",
        "_preferred": "h"
      },
      "DO": {
        "_allowed": "h H hB hb",
        "_preferred": "h"
      },
      "KP": {
        "_allowed": "h H hB hb",
        "_preferred": "h"
      },
      "KR": {
        "_allowed": "h H hB hb",
        "_preferred": "h"
      },
      "NA": {
        "_allowed": "h H hB hb",
        "_preferred": "h"
      },
      "PA": {
        "_allowed": "h H hB hb",
        "_preferred": "h"
      },
      "PR": {
        "_allowed": "h H hB hb",
        "_preferred": "h"
      },
      "VE": {
        "_allowed": "h H hB hb",
        "_preferred": "h"
      },
      "AC": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "AI": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "BW": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "BZ": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "CC": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "CK": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "CX": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "DG": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "FK": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "GB": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "GG": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "GI": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "IE": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "IM": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "IO": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "JE": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "LT": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "MK": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "MN": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "MS": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "NF": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "NG": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "NR": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "NU": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "PN": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "SH": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "SX": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "TA": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "ZA": {
        "_allowed": "H h hb hB",
        "_preferred": "H"
      },
      "af_ZA": {
        "_allowed": "H h hB hb",
        "_preferred": "H"
      },
      "KG": {
        "_allowed": "H h hB hb",
        "_preferred": "H"
      },
      "KM": {
        "_allowed": "H h hB hb",
        "_preferred": "H"
      },
      "LK": {
        "_allowed": "H h hB hb",
        "_preferred": "H"
      },
      "MA": {
        "_allowed": "H h hB hb",
        "_preferred": "H"
      },
      "JP": {
        "_allowed": "H h K",
        "_preferred": "H"
      },
      "AD": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "AM": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "AO": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "AT": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "AW": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "BE": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "BF": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "BJ": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "BL": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "BR": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "CG": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "CI": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "CV": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "DE": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "EE": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "FR": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "GA": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "GF": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "GN": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "GP": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "GW": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "HR": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "IL": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "IT": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "KZ": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "MC": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "MD": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "MF": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "MQ": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "MZ": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "NC": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "NL": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "PM": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "PT": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "RE": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "RO": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "SI": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "SM": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "SR": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "ST": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "TG": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "TR": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "WF": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "YT": {
        "_allowed": "H hB",
        "_preferred": "H"
      },
      "BD": {
        "_allowed": "h hB H",
        "_preferred": "h"
      },
      "PK": {
        "_allowed": "h hB H",
        "_preferred": "h"
      },
      "AZ": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "BA": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "BG": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "CH": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "GE": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "LI": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "ME": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "RS": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "UA": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "UZ": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "VA": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "XK": {
        "_allowed": "H hB h",
        "_preferred": "H"
      },
      "AG": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "AU": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "BB": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "BM": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "BS": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "CA": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "DM": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "FJ": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "FM": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "GD": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "GM": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "GU": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "GY": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "JM": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "KI": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "KN": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "KY": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "LC": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "LR": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "MH": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "MP": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "MW": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "NZ": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "SB": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "SG": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "SL": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "SS": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "SZ": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "TC": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "TT": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "UM": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "US": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "VC": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "VG": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "VI": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "ZM": {
        "_allowed": "h hb H hB",
        "_preferred": "h"
      },
      "AR": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "BO": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "CL": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "CR": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "CU": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "EA": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "EC": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "ES": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "GQ": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "GT": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "HN": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "IC": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "MX": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "NI": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "PE": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "SV": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "UY": {
        "_allowed": "H hB h hb",
        "_preferred": "H"
      },
      "AE": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "BH": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "DZ": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "EG": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "EH": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "IQ": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "JO": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "KW": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "LB": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "LY": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "MR": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "OM": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "PH": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "PS": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "QA": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "SA": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "SD": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "SY": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "TN": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "YE": {
        "_allowed": "h hB hb H",
        "_preferred": "h"
      },
      "AF": {
        "_allowed": "H hb hB h",
        "_preferred": "H"
      },
      "IR": {
        "_allowed": "H hb hB h",
        "_preferred": "H"
      },
      "LA": {
        "_allowed": "H hb hB h",
        "_preferred": "H"
      },
      "LV": {
        "_allowed": "H hB hb h",
        "_preferred": "H"
      },
      "TL": {
        "_allowed": "H hB hb h",
        "_preferred": "H"
      },
      "zu_ZA": {
        "_allowed": "H hB hb h",
        "_preferred": "H"
      },
      "CD": {
        "_allowed": "hB H",
        "_preferred": "H"
      },
      "kn_IN": {
        "_allowed": "hB h H",
        "_preferred": "h"
      },
      "ml_IN": {
        "_allowed": "hB h H",
        "_preferred": "h"
      },
      "te_IN": {
        "_allowed": "hB h H",
        "_preferred": "h"
      },
      "KH": {
        "_allowed": "hB h H hb",
        "_preferred": "h"
      },
      "ta_IN": {
        "_allowed": "hB h hb H",
        "_preferred": "h"
      },
      "BN": {
        "_allowed": "hb hB H h",
        "_preferred": "h"
      },
      "MY": {
        "_allowed": "hb hB H h",
        "_preferred": "h"
      },
      "ET": {
        "_allowed": "hB hb h H",
        "_preferred": "h"
      },
      "gu_IN": {
        "_allowed": "hB hb h H",
        "_preferred": "h"
      },
      "mr_IN": {
        "_allowed": "hB hb h H",
        "_preferred": "h"
      },
      "pa_IN": {
        "_allowed": "hB hb h H",
        "_preferred": "h"
      },
      "KE": {
        "_allowed": "hB hb h H",
        "_preferred": "H"
      },
      "MM": {
        "_allowed": "hB hb h H",
        "_preferred": "H"
      },
      "TZ": {
        "_allowed": "hB hb h H",
        "_preferred": "H"
      },
      "UG": {
        "_allowed": "hB hb h H",
        "_preferred": "H"
      },
      "CN": {
        "_allowed": "hB hb H h",
        "_preferred": "h"
      },
      "HK": {
        "_allowed": "hB hb H h",
        "_preferred": "h"
      },
      "MO": {
        "_allowed": "hB hb H h",
        "_preferred": "h"
      },
      "TW": {
        "_allowed": "hB hb H h",
        "_preferred": "h"
      }
    }
  }
}
