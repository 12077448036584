'use strict'

module.exports = {
    'numeric'  : require('./numeric'),
    'number'   : require('./number'),
    'int'      : require('./int'),
    'float'    : require('./float'),
    'string'   : require('./string'),
    'function' : require('./function'),
    'object'   : require('./object'),
    'arguments': require('./arguments'),
    'boolean'  : require('./boolean'),
    'date'     : require('./date'),
    'regexp'   : require('./regexp'),
    'array'    : require('./array')
}