module.exports={
  "main": {
    "nl-BE": {
      "identity": {
        "version": {
          "_number": "$Revision: 13133 $",
          "_cldrVersion": "31.0.1"
        },
        "language": "nl",
        "territory": "BE"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 duizend",
              "1000-count-other": "0 duizend",
              "10000-count-one": "00 duizend",
              "10000-count-other": "00 duizend",
              "100000-count-one": "000 duizend",
              "100000-count-other": "000 duizend",
              "1000000-count-one": "0 miljoen",
              "1000000-count-other": "0 miljoen",
              "10000000-count-one": "00 miljoen",
              "10000000-count-other": "00 miljoen",
              "100000000-count-one": "000 miljoen",
              "100000000-count-other": "000 miljoen",
              "1000000000-count-one": "0 miljard",
              "1000000000-count-other": "0 miljard",
              "10000000000-count-one": "00 miljard",
              "10000000000-count-other": "00 miljard",
              "100000000000-count-one": "000 miljard",
              "100000000000-count-other": "000 miljard",
              "1000000000000-count-one": "0 biljoen",
              "1000000000000-count-other": "0 biljoen",
              "10000000000000-count-one": "00 biljoen",
              "10000000000000-count-other": "00 biljoen",
              "100000000000000-count-one": "000 biljoen",
              "100000000000000-count-other": "000 biljoen"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0K",
              "1000-count-other": "0K",
              "10000-count-one": "00K",
              "10000-count-other": "00K",
              "100000-count-one": "000K",
              "100000-count-other": "000K",
              "1000000-count-one": "0 mln'.'",
              "1000000-count-other": "0 mln'.'",
              "10000000-count-one": "00 mln'.'",
              "10000000-count-other": "00 mln'.'",
              "100000000-count-one": "000 mln'.'",
              "100000000-count-other": "000 mln'.'",
              "1000000000-count-one": "0 mld'.'",
              "1000000000-count-other": "0 mld'.'",
              "10000000000-count-one": "00 mld'.'",
              "10000000000-count-other": "00 mld'.'",
              "100000000000-count-one": "000 mld'.'",
              "100000000000-count-other": "000 mld'.'",
              "1000000000000-count-one": "0 bln'.'",
              "1000000000000-count-other": "0 bln'.'",
              "10000000000000-count-one": "00 bln'.'",
              "10000000000000-count-other": "00 bln'.'",
              "100000000000000-count-one": "000 bln'.'",
              "100000000000000-count-other": "000 bln'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "¤ #,##0.00;(¤ #,##0.00)",
          "short": {
            "standard": {
              "1000-count-one": "¤ 0K",
              "1000-count-other": "¤ 0K",
              "10000-count-one": "¤ 00K",
              "10000-count-other": "¤ 00K",
              "100000-count-one": "¤ 000K",
              "100000-count-other": "¤ 000K",
              "1000000-count-one": "¤ 0 mln'.'",
              "1000000-count-other": "¤ 0 mln'.'",
              "10000000-count-one": "¤ 00 mln'.'",
              "10000000-count-other": "¤ 00 mln'.'",
              "100000000-count-one": "¤ 000 mln'.'",
              "100000000-count-other": "¤ 000 mln'.'",
              "1000000000-count-one": "¤ 0 mld'.'",
              "1000000000-count-other": "¤ 0 mld'.'",
              "10000000000-count-one": "¤ 00 mld'.'",
              "10000000000-count-other": "¤ 00 mld'.'",
              "100000000000-count-one": "¤ 000 mld'.'",
              "100000000000-count-other": "¤ 000 mld'.'",
              "1000000000000-count-one": "¤ 0 bln'.'",
              "1000000000000-count-other": "¤ 0 bln'.'",
              "10000000000000-count-one": "¤ 00 bln'.'",
              "10000000000000-count-other": "¤ 00 bln'.'",
              "100000000000000-count-one": "¤ 000 bln'.'",
              "100000000000000-count-other": "¤ 000 bln'.'"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "atLeast": "{0}+",
          "range": "{0}-{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs": "{0} dag",
          "pluralMinimalPairs": "{0} dagen",
          "other": "Neem de {0}e afslag rechts."
        }
      }
    }
  }
}
