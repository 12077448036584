module.exports = {
  updategrid(sleutel,kolom){
    let aj2 = $.ajax({
      type: "POST",
      dataType: "json",
      url: './server/index.php?function=UpdGrid&id='+sleutel,
        data: JSON.stringify(kolom),
        success: function(data){
        },
        error: function(data){
        }
        });

  },

 ColumnResize(naam, firstSize, sleutel, ColFilter){
    var index, column;    
    for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
    {
        let filCol = ColFilter[rowIdx];
        if (filCol.name == naam)
        {
          ColFilter[rowIdx].width = firstSize;
        }
    }    
    this.updategrid(sleutel,ColFilter);
    return ColFilter;
  },  

  ColumnOrderChange(index, dropIndex, dgcolumns, sleutel){
    var col = dgcolumns[index]
    dgcolumns.splice(index, 1) //delete from index, 1 item
    dgcolumns.splice(dropIndex, 0, col)
    this.updategrid(sleutel,dgcolumns);
    return dgcolumns;
  },  

  SetFilter: function(value,data,ColFilter,ColNum){
    var index, column;    
    let arra;
    // controleren of arra && of || bevat
    let hudata = data;
    if (value.search("&&") > 0 || value.search("--") > 0 )
    {
      if (value.search("--") > 0) 
      {
        hudata = this.SetfiltOr(value,hudata,ColFilter,ColNum);
      }
      if (value.search("&&") > 0) 
      {
        hudata = this.SetfiltAnd(value,hudata,ColFilter,ColNum);
      }
      return hudata;
    }

    if (value.search("<") >= 0 && value.search(">") >= 0 )
    {
      var rest = value.split(">");
      if (rest[0].length > 1)
      {
        hudata = this.SetFilterIntern(rest[0],hudata,ColFilter);
      }
      hudata = this.filterInRange(value,hudata,ColNum);
      return hudata;
    }
    else
    {
      if (value.search("<") >= 0)
      {
        var rest = value.split("<");
        if (rest[0].length > 1)
        {
          hudata = this.SetFilterIntern(rest[0],hudata,ColFilter);
        }
        hudata = this.filterInRangeMax(value,hudata,ColNum);
        return hudata;
      }
      else
      {
        if (value.search(">") >= 0)
        {
          var rest = value.split(">");
          if (rest[0].length > 1)
          {
            hudata = this.SetFilterIntern(rest[0],hudata,ColFilter);
          }
          hudata = this.filterInRangeMin(value,hudata,ColNum);
          return hudata;
        }
      }
    }

    var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = eval('row.'+filCol).toLowerCase();            
          }
          else
          {
            arra = ''; 
          }
          if(arra.indexOf(value.toLowerCase()) >= 0)
          {
            return arra.indexOf(value.toLowerCase()) >= 0
          }
        }
      }) : data;
      return filteredRows;
  },  

  SetfiltAnd: function(value,data,ColFilter,ColNum){
    var hvalue = value;
    var htabres = value.split("--");
    if (htabres.length > 0)
    {
      hvalue = htabres[htabres.length - 1];
    }
    var tabres = hvalue.split("&&");
    let zoektabel = data;

    let start = 0;
    if (htabres.length > 0)
    {
      start = 0;
    }
    for (let TabresIdx = start; TabresIdx < tabres.length; TabresIdx++) 
    {
      let zoekwaarde = tabres[TabresIdx];
      if (zoekwaarde.search("<") >= 0 && zoekwaarde.search(">") >= 0 )
      {
        var rest = zoekwaarde.split(">");
        if (rest[0].length > 1)
        {
          zoektabel = this.SetFilterIntern(rest[0],zoektabel,ColFilter);
        }
        zoektabel = this.filterInRange(zoekwaarde,zoektabel,ColNum);
      }
      else
      {
        if (zoekwaarde.search("<") >= 0)
        {
          var rest = zoekwaarde.split("<");
          if (rest[0].length > 1)
          {
            zoektabel = this.SetFilterIntern(rest[0],zoektabel,ColFilter);
          }
          zoektabel = this.filterInRangeMax(zoekwaarde,zoektabel,ColNum);
        }
        else
        {
          if (zoekwaarde.search(">") >= 0)
          {
            var rest = zoekwaarde.split(">");
            if (rest[0].length > 1)
            { 
              zoektabel = this.SetFilterIntern(rest[0],zoektabel,ColFilter);
            }
            zoektabel = this.filterInRangeMin(zoekwaarde,zoektabel,ColNum);
          }
          else
          {
            zoektabel = this.SetFilterIntern(zoekwaarde,zoektabel,ColFilter);
          }
        }
      }
    }
    return zoektabel;
  },

  SetFilterIntern: function(value,data,ColFilter){
    var index, column;    
    let arra;
    // controleren of arra && of || bevat

    var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = eval('row.'+filCol).toLowerCase();            
          }
          else
          {
            arra = ''; 
          }
          if(arra.indexOf(value.toLowerCase()) >= 0)
          {
            return arra.indexOf(value.toLowerCase()) >= 0
          }
        }
      }) : data;
      return filteredRows;
  },  

  SetfiltOr: function(value,data,ColFilter,ColNum){
    var hvalue = value;
    var htabres = value.split("&&");
    if (htabres.length > 0)
    {
      hvalue = htabres[0];
    }
    var tabres = hvalue.split("--");
    let result = [];
    for (let TabresIdx = 0; TabresIdx < tabres.length; TabresIdx++) 
    {
      let zoektabel = data;
      let zoekwaarde = tabres[TabresIdx].replace('&&','');
      zoekwaarde = tabres[TabresIdx].replace('&','');

      if (zoekwaarde.search("<") >= 0 && zoekwaarde.search(">") >= 0 )
      {
        var rest = zoekwaarde.split(">");
        if (rest[0].length > 1)
        {
          zoektabel = this.SetFilterIntern(rest[0],zoektabel,ColFilter);
        }
        zoektabel = this.filterInRange(zoekwaarde,zoektabel,ColNum);
      }
      else
      {
        if (zoekwaarde.search("<") >= 0)
        {
          var rest = zoekwaarde.split("<");
          if (rest[0].length > 1)
          {
            zoektabel = this.SetFilterIntern(rest[0],zoektabel,ColFilter);
          }
          zoektabel = this.filterInRangeMax(zoekwaarde,zoektabel,ColNum);
        }
        else
        {
          if (zoekwaarde.search(">") >= 0)
          {
            var rest = zoekwaarde.split(">");
            if (rest[0].length > 1)
            { 
              zoektabel = this.SetFilterIntern(rest[0],zoektabel,ColFilter);
            }
            zoektabel = this.filterInRangeMin(zoekwaarde,zoektabel,ColNum);
          }
          else
          {
            zoektabel = this.SetFilterIntern(zoekwaarde,zoektabel,ColFilter);
          }
        }
      }
      result = result.concat(zoektabel);
    }
    let filtered = result.filter(function (a) {
        if (!this[a.id]) {
            this[a.id] = true;
            return true;
        }
    }, Object.create(null));
    return filtered;
  },


  filterInRange: function(value,data,ColFilter) {
    let arra;
    var rest = value.split(">");
    if (rest[1]) {var res = rest[1].split("<")}
    else {var res = rest[0].split("<")}
    let min = res[0];
    let max = res[1];
    let hmin = min;
    let hmax = max;

    if (min && max)
    {
      if (hmin > hmax) 
      {
        min = hmax;
        max = hmin;
      }
      var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = parseFloat(eval('row.'+filCol));            
          }
          else
          {
            arra = ''; 
          }
          if (arra >= min && arra < max) {
            return true;
          }
        }
      }) : data;
      return filteredRows;
    }
    else
    {
      if (min) 
      {
        let hudata = this.filterInRangeMin(value,data,ColFilter);
        return hudata;        
      }
      if (max) 
      {
        let hudata = this.filterInRangeMax(value,data,ColFilter);
        return hudata;        
      }
    }
    return data;
  },

  filterInRangeMin: function(value,data,ColFilter) {
    let arra;
    var rest = value.split(">");
    if (rest[1]) {var res = rest[1].split("<")}
    else {var res = rest[0].split("<")}
    let min = res[0];
    if (min)
    {
      var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = parseFloat(eval('row.'+filCol));            
          }
          else
          {
            arra = ''; 
          }
          if (arra >= min) {
            return true;
          }
        }
      }) : data;
      return filteredRows;
    }
    else
    {
      return data;
    }
  },
  filterInRangeMax: function(value,data,ColFilter) {
    let arra ;
    var rest = value.split(">");
    if (rest[1]) {var res = rest[1].split("<")}
    else {var res = rest[0].split("<")}
    let max = res[1];
    if (max)
    {
      var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = parseFloat(eval('row.'+filCol));            
          }
          else
          {
            arra = ''; 
          }
          if (arra < max) {
            return true;
          }
        }
      }) : data;
      return filteredRows;
    }
    else
    {
      return data;
    }
  },



  SetCheck: function(value,data,Col,waarde){
    var index, column;    
    let arra = data;

    for (let rowIdx = 0; rowIdx < arra.length; rowIdx++) 
    {
      let filrow = arra[rowIdx];
      if (filrow.id == value)
      {
        if (filrow.gsm == waarde)
        {
          arra[rowIdx].gsm = '';
        }
        else
        {
          arra[rowIdx].gsm = waarde;          
        }

      }
    }
    return arra;
  },  

  MenuChange(key, payload, ColFilter) {
    if (ColFilter[key].visible == true)
    {
      ColFilter[key].visible = false;
    }
    else
    {
      ColFilter[key].visible = true;
    }
    let hulpmenu = [];
    let hitem, hicon, lengte;
    let htrue = 0;
    for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
    {
      if (ColFilter[rowIdx].visible == true) {htrue = htrue + 1};
    }
    if (htrue < 1)
    {
      ColFilter[key].visible = true;
    }

    for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
    {
        let filCol = ColFilter[rowIdx];
        hicon = (ColFilter[rowIdx].visible == true) ? 'fa fa-check-square-o' : 'fa fa-square-o';
        hitem = {payload: ColFilter[rowIdx].name, text:ColFilter[rowIdx].title, iconClassName:hicon};
        lengte = hulpmenu.length;
        hulpmenu.splice(lengte, 0, hitem);
    }      
    var retwaarde = {iconMenuItems:hulpmenu, dgcolumns:ColFilter};
    return retwaarde
  },


  Sorteer(sortInfo, ColFilter, GrData ){
    var SortData = GrData.slice();
    var index, column;
    let arra;
    let arrb;   
    for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
    {
        let filCol = ColFilter[rowIdx];
        if (ColFilter[rowIdx].name == sortInfo)
        {

          // Nu sorteren
          if (ColFilter[rowIdx].sorttype == 'A') 
          {
            ColFilter[rowIdx].sorttype = 'D';
          }
          else
          {
            ColFilter[rowIdx].sorttype = 'A';
          }         
    
          if (ColFilter[rowIdx].type === 'STR')
          {
            SortData.sort(function (a, b) {
              if (eval('a.'+sortInfo))
              {
                arra = eval('a.'+sortInfo).toLowerCase();
              }
              else
              {
                arra = ''; 
              }
              if (eval('b.'+sortInfo))
              {
                arrb = eval('b.'+sortInfo).toLowerCase();
              }
              else
              {
               arrb = ''; 
              }

              if (arra > arrb) {
                return 1;
              }
              if (arra < arrb) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });   
          }

          if (ColFilter[rowIdx].type === 'NUM')
          {
            SortData.sort(function (a, b) {
              let arra = parseFloat(eval('a.'+sortInfo));
              let arrb = parseFloat(eval('b.'+sortInfo));
              return arra - arrb;
            });   
          }

          if (ColFilter[rowIdx].type === 'DAT')
          {
            var that = this;
            SortData.sort(function (a, b) {
              let arra = that.stringToDate(eval('a.'+sortInfo),"dd-mm-yyyy","-");
              let arrb = that.stringToDate(eval('b.'+sortInfo),"dd-mm-yyyy","-");
              if (arra > arrb) {
                return 1;
              }
              if (arra < arrb) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });   
          }
          if (ColFilter[rowIdx].sorttype == 'D')
          {
            SortData.reverse();
          }
          // einde sorteren
        }
        else
        {
          ColFilter[rowIdx].sorttype = '';
        }
    }  
    var retwaarde = {data:SortData, kol:ColFilter};
    return retwaarde
  },

  stringToDate(_date,_format,_delimiter)
  {
      if (_date)
      {
            var formatLowerCase=_format.toLowerCase();
            var formatItems=formatLowerCase.split(_delimiter);
            var dateItems=_date.split(_delimiter);
            var monthIndex=formatItems.indexOf("mm");
            var dayIndex=formatItems.indexOf("dd");
            var yearIndex=formatItems.indexOf("yyyy");
            var month=parseInt(dateItems[monthIndex]);
            month-=1;
            var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
            return formatedDate;
      }
      else
      {
        return null;
      }
  },  


};
