module.exports = {
  DOWN: 40,
  ESC: 27,
  ENTER: 13,
  LEFT: 37,
  RIGHT: 39,
  SPACE: 32,
  TAB: 9,
  UP: 38,
};
