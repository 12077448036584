'use once'

module.exports = function once(fn, scope){

    var called
    var result

    return function(){
        if (called){
            return result
        }

        called = true

        return result = fn.apply(scope || this, arguments)
    }
}