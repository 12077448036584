module.exports={
  "main": {
    "nl-BE": {
      "identity": {
        "version": {
          "_number": "$Revision: 13133 $",
          "_cldrVersion": "31.0.1"
        },
        "language": "nl",
        "territory": "BE"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;-HH:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "{0}-tijd",
          "regionFormat-type-daylight": "zomertijd {0}",
          "regionFormat-type-standard": "standaardtijd {0}",
          "fallbackFormat": "{1} ({0})",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "Adak"
              },
              "Anchorage": {
                "exemplarCity": "Anchorage"
              },
              "Anguilla": {
                "exemplarCity": "Anguilla"
              },
              "Antigua": {
                "exemplarCity": "Antigua"
              },
              "Araguaina": {
                "exemplarCity": "Araguaina"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "Río Gallegos"
                },
                "San_Juan": {
                  "exemplarCity": "San Juan"
                },
                "Ushuaia": {
                  "exemplarCity": "Ushuaia"
                },
                "La_Rioja": {
                  "exemplarCity": "La Rioja"
                },
                "San_Luis": {
                  "exemplarCity": "San Luis"
                },
                "Salta": {
                  "exemplarCity": "Salta"
                },
                "Tucuman": {
                  "exemplarCity": "Tucumán"
                }
              },
              "Aruba": {
                "exemplarCity": "Aruba"
              },
              "Asuncion": {
                "exemplarCity": "Asunción"
              },
              "Bahia": {
                "exemplarCity": "Bahia"
              },
              "Bahia_Banderas": {
                "exemplarCity": "Bahía de Banderas"
              },
              "Barbados": {
                "exemplarCity": "Barbados"
              },
              "Belem": {
                "exemplarCity": "Belém"
              },
              "Belize": {
                "exemplarCity": "Belize"
              },
              "Blanc-Sablon": {
                "exemplarCity": "Blanc-Sablon"
              },
              "Boa_Vista": {
                "exemplarCity": "Boa Vista"
              },
              "Bogota": {
                "exemplarCity": "Bogota"
              },
              "Boise": {
                "exemplarCity": "Boise"
              },
              "Buenos_Aires": {
                "exemplarCity": "Buenos Aires"
              },
              "Cambridge_Bay": {
                "exemplarCity": "Cambridge Bay"
              },
              "Campo_Grande": {
                "exemplarCity": "Campo Grande"
              },
              "Cancun": {
                "exemplarCity": "Cancun"
              },
              "Caracas": {
                "exemplarCity": "Caracas"
              },
              "Catamarca": {
                "exemplarCity": "Catamarca"
              },
              "Cayenne": {
                "exemplarCity": "Cayenne"
              },
              "Cayman": {
                "exemplarCity": "Cayman"
              },
              "Chicago": {
                "exemplarCity": "Chicago"
              },
              "Chihuahua": {
                "exemplarCity": "Chihuahua"
              },
              "Coral_Harbour": {
                "exemplarCity": "Atikokan"
              },
              "Cordoba": {
                "exemplarCity": "Córdoba"
              },
              "Costa_Rica": {
                "exemplarCity": "Costa Rica"
              },
              "Creston": {
                "exemplarCity": "Creston"
              },
              "Cuiaba": {
                "exemplarCity": "Cuiabá"
              },
              "Curacao": {
                "exemplarCity": "Curaçao"
              },
              "Danmarkshavn": {
                "exemplarCity": "Danmarkshavn"
              },
              "Dawson": {
                "exemplarCity": "Dawson"
              },
              "Dawson_Creek": {
                "exemplarCity": "Dawson Creek"
              },
              "Denver": {
                "exemplarCity": "Denver"
              },
              "Detroit": {
                "exemplarCity": "Detroit"
              },
              "Dominica": {
                "exemplarCity": "Dominica"
              },
              "Edmonton": {
                "exemplarCity": "Edmonton"
              },
              "Eirunepe": {
                "exemplarCity": "Eirunepe"
              },
              "El_Salvador": {
                "exemplarCity": "El Salvador"
              },
              "Fort_Nelson": {
                "exemplarCity": "Fort Nelson"
              },
              "Fortaleza": {
                "exemplarCity": "Fortaleza"
              },
              "Glace_Bay": {
                "exemplarCity": "Glace Bay"
              },
              "Godthab": {
                "exemplarCity": "Nuuk"
              },
              "Goose_Bay": {
                "exemplarCity": "Goose Bay"
              },
              "Grand_Turk": {
                "exemplarCity": "Grand Turk"
              },
              "Grenada": {
                "exemplarCity": "Grenada"
              },
              "Guadeloupe": {
                "exemplarCity": "Guadeloupe"
              },
              "Guatemala": {
                "exemplarCity": "Guatemala"
              },
              "Guayaquil": {
                "exemplarCity": "Guayaquil"
              },
              "Guyana": {
                "exemplarCity": "Guyana"
              },
              "Halifax": {
                "exemplarCity": "Halifax"
              },
              "Havana": {
                "exemplarCity": "Havana"
              },
              "Hermosillo": {
                "exemplarCity": "Hermosillo"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "Vincennes, Indiana"
                },
                "Petersburg": {
                  "exemplarCity": "Petersburg, Indiana"
                },
                "Tell_City": {
                  "exemplarCity": "Tell City, Indiana"
                },
                "Knox": {
                  "exemplarCity": "Knox, Indiana"
                },
                "Winamac": {
                  "exemplarCity": "Winamac, Indiana"
                },
                "Marengo": {
                  "exemplarCity": "Marengo, Indiana"
                },
                "Vevay": {
                  "exemplarCity": "Vevay, Indiana"
                }
              },
              "Indianapolis": {
                "exemplarCity": "Indianapolis"
              },
              "Inuvik": {
                "exemplarCity": "Inuvik"
              },
              "Iqaluit": {
                "exemplarCity": "Iqaluit"
              },
              "Jamaica": {
                "exemplarCity": "Jamaica"
              },
              "Jujuy": {
                "exemplarCity": "Jujuy"
              },
              "Juneau": {
                "exemplarCity": "Juneau"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "Monticello, Kentucky"
                }
              },
              "Kralendijk": {
                "exemplarCity": "Kralendijk"
              },
              "La_Paz": {
                "exemplarCity": "La Paz"
              },
              "Lima": {
                "exemplarCity": "Lima"
              },
              "Los_Angeles": {
                "exemplarCity": "Los Angeles"
              },
              "Louisville": {
                "exemplarCity": "Louisville"
              },
              "Lower_Princes": {
                "exemplarCity": "Beneden Prinsen Kwartier"
              },
              "Maceio": {
                "exemplarCity": "Maceió"
              },
              "Managua": {
                "exemplarCity": "Managua"
              },
              "Manaus": {
                "exemplarCity": "Manaus"
              },
              "Marigot": {
                "exemplarCity": "Marigot"
              },
              "Martinique": {
                "exemplarCity": "Martinique"
              },
              "Matamoros": {
                "exemplarCity": "Matamoros"
              },
              "Mazatlan": {
                "exemplarCity": "Mazatlán"
              },
              "Mendoza": {
                "exemplarCity": "Mendoza"
              },
              "Menominee": {
                "exemplarCity": "Menominee"
              },
              "Merida": {
                "exemplarCity": "Mérida"
              },
              "Metlakatla": {
                "exemplarCity": "Metlakatla"
              },
              "Mexico_City": {
                "exemplarCity": "Mexico-Stad"
              },
              "Miquelon": {
                "exemplarCity": "Miquelon"
              },
              "Moncton": {
                "exemplarCity": "Moncton"
              },
              "Monterrey": {
                "exemplarCity": "Monterrey"
              },
              "Montevideo": {
                "exemplarCity": "Montevideo"
              },
              "Montserrat": {
                "exemplarCity": "Montserrat"
              },
              "Nassau": {
                "exemplarCity": "Nassau"
              },
              "New_York": {
                "exemplarCity": "New York"
              },
              "Nipigon": {
                "exemplarCity": "Nipigon"
              },
              "Nome": {
                "exemplarCity": "Nome"
              },
              "Noronha": {
                "exemplarCity": "Noronha"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "Beulah, Noord-Dakota"
                },
                "New_Salem": {
                  "exemplarCity": "New Salem, Noord-Dakota"
                },
                "Center": {
                  "exemplarCity": "Center, Noord-Dakota"
                }
              },
              "Ojinaga": {
                "exemplarCity": "Ojinaga"
              },
              "Panama": {
                "exemplarCity": "Panama"
              },
              "Pangnirtung": {
                "exemplarCity": "Pangnirtung"
              },
              "Paramaribo": {
                "exemplarCity": "Paramaribo"
              },
              "Phoenix": {
                "exemplarCity": "Phoenix"
              },
              "Port-au-Prince": {
                "exemplarCity": "Port-au-Prince"
              },
              "Port_of_Spain": {
                "exemplarCity": "Port of Spain"
              },
              "Porto_Velho": {
                "exemplarCity": "Porto Velho"
              },
              "Puerto_Rico": {
                "exemplarCity": "Puerto Rico"
              },
              "Rainy_River": {
                "exemplarCity": "Rainy River"
              },
              "Rankin_Inlet": {
                "exemplarCity": "Rankin Inlet"
              },
              "Recife": {
                "exemplarCity": "Recife"
              },
              "Regina": {
                "exemplarCity": "Regina"
              },
              "Resolute": {
                "exemplarCity": "Resolute"
              },
              "Rio_Branco": {
                "exemplarCity": "Rio Branco"
              },
              "Santa_Isabel": {
                "exemplarCity": "Santa Isabel"
              },
              "Santarem": {
                "exemplarCity": "Santarem"
              },
              "Santiago": {
                "exemplarCity": "Santiago"
              },
              "Santo_Domingo": {
                "exemplarCity": "Santo Domingo"
              },
              "Sao_Paulo": {
                "exemplarCity": "São Paulo"
              },
              "Scoresbysund": {
                "exemplarCity": "Ittoqqortoormiit"
              },
              "Sitka": {
                "exemplarCity": "Sitka"
              },
              "St_Barthelemy": {
                "exemplarCity": "Saint-Barthélemy"
              },
              "St_Johns": {
                "exemplarCity": "St. John’s"
              },
              "St_Kitts": {
                "exemplarCity": "St. Kitts"
              },
              "St_Lucia": {
                "exemplarCity": "St. Lucia"
              },
              "St_Thomas": {
                "exemplarCity": "St. Thomas"
              },
              "St_Vincent": {
                "exemplarCity": "St. Vincent"
              },
              "Swift_Current": {
                "exemplarCity": "Swift Current"
              },
              "Tegucigalpa": {
                "exemplarCity": "Tegucigalpa"
              },
              "Thule": {
                "exemplarCity": "Thule"
              },
              "Thunder_Bay": {
                "exemplarCity": "Thunder Bay"
              },
              "Tijuana": {
                "exemplarCity": "Tijuana"
              },
              "Toronto": {
                "exemplarCity": "Toronto"
              },
              "Tortola": {
                "exemplarCity": "Tortola"
              },
              "Vancouver": {
                "exemplarCity": "Vancouver"
              },
              "Whitehorse": {
                "exemplarCity": "Whitehorse"
              },
              "Winnipeg": {
                "exemplarCity": "Winnipeg"
              },
              "Yakutat": {
                "exemplarCity": "Yakutat"
              },
              "Yellowknife": {
                "exemplarCity": "Yellowknife"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "Azoren"
              },
              "Bermuda": {
                "exemplarCity": "Bermuda"
              },
              "Canary": {
                "exemplarCity": "Canarische Eilanden"
              },
              "Cape_Verde": {
                "exemplarCity": "Kaapverdië"
              },
              "Faeroe": {
                "exemplarCity": "Faeröer"
              },
              "Madeira": {
                "exemplarCity": "Madeira"
              },
              "Reykjavik": {
                "exemplarCity": "Reykjavik"
              },
              "South_Georgia": {
                "exemplarCity": "Zuid-Georgia"
              },
              "St_Helena": {
                "exemplarCity": "Sint-Helena"
              },
              "Stanley": {
                "exemplarCity": "Stanley"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "Amsterdam"
              },
              "Andorra": {
                "exemplarCity": "Andorra"
              },
              "Astrakhan": {
                "exemplarCity": "Astrakhan"
              },
              "Athens": {
                "exemplarCity": "Athene"
              },
              "Belgrade": {
                "exemplarCity": "Belgrado"
              },
              "Berlin": {
                "exemplarCity": "Berlijn"
              },
              "Bratislava": {
                "exemplarCity": "Bratislava"
              },
              "Brussels": {
                "exemplarCity": "Brussel"
              },
              "Bucharest": {
                "exemplarCity": "Boekarest"
              },
              "Budapest": {
                "exemplarCity": "Boedapest"
              },
              "Busingen": {
                "exemplarCity": "Busingen"
              },
              "Chisinau": {
                "exemplarCity": "Chisinau"
              },
              "Copenhagen": {
                "exemplarCity": "Kopenhagen"
              },
              "Dublin": {
                "long": {
                  "daylight": "Ierse zomertijd"
                },
                "exemplarCity": "Dublin"
              },
              "Gibraltar": {
                "exemplarCity": "Gibraltar"
              },
              "Guernsey": {
                "exemplarCity": "Guernsey"
              },
              "Helsinki": {
                "exemplarCity": "Helsinki"
              },
              "Isle_of_Man": {
                "exemplarCity": "Isle of Man"
              },
              "Istanbul": {
                "exemplarCity": "Istanboel"
              },
              "Jersey": {
                "exemplarCity": "Jersey"
              },
              "Kaliningrad": {
                "exemplarCity": "Kaliningrad"
              },
              "Kiev": {
                "exemplarCity": "Kiev"
              },
              "Kirov": {
                "exemplarCity": "Kirov"
              },
              "Lisbon": {
                "exemplarCity": "Lissabon"
              },
              "Ljubljana": {
                "exemplarCity": "Ljubljana"
              },
              "London": {
                "long": {
                  "daylight": "Britse zomertijd"
                },
                "exemplarCity": "Londen"
              },
              "Luxembourg": {
                "exemplarCity": "Luxemburg"
              },
              "Madrid": {
                "exemplarCity": "Madrid"
              },
              "Malta": {
                "exemplarCity": "Malta"
              },
              "Mariehamn": {
                "exemplarCity": "Mariehamn"
              },
              "Minsk": {
                "exemplarCity": "Minsk"
              },
              "Monaco": {
                "exemplarCity": "Monaco"
              },
              "Moscow": {
                "exemplarCity": "Moskou"
              },
              "Oslo": {
                "exemplarCity": "Oslo"
              },
              "Paris": {
                "exemplarCity": "Parijs"
              },
              "Podgorica": {
                "exemplarCity": "Podgorica"
              },
              "Prague": {
                "exemplarCity": "Praag"
              },
              "Riga": {
                "exemplarCity": "Riga"
              },
              "Rome": {
                "exemplarCity": "Rome"
              },
              "Samara": {
                "exemplarCity": "Samara"
              },
              "San_Marino": {
                "exemplarCity": "San Marino"
              },
              "Sarajevo": {
                "exemplarCity": "Sarajevo"
              },
              "Simferopol": {
                "exemplarCity": "Simferopol"
              },
              "Skopje": {
                "exemplarCity": "Skopje"
              },
              "Sofia": {
                "exemplarCity": "Sofia"
              },
              "Stockholm": {
                "exemplarCity": "Stockholm"
              },
              "Tallinn": {
                "exemplarCity": "Tallinn"
              },
              "Tirane": {
                "exemplarCity": "Tirana"
              },
              "Ulyanovsk": {
                "exemplarCity": "Ulyanovsk"
              },
              "Uzhgorod": {
                "exemplarCity": "Oezjhorod"
              },
              "Vaduz": {
                "exemplarCity": "Vaduz"
              },
              "Vatican": {
                "exemplarCity": "Vaticaanstad"
              },
              "Vienna": {
                "exemplarCity": "Wenen"
              },
              "Vilnius": {
                "exemplarCity": "Vilnius"
              },
              "Volgograd": {
                "exemplarCity": "Wolgograd"
              },
              "Warsaw": {
                "exemplarCity": "Warschau"
              },
              "Zagreb": {
                "exemplarCity": "Zagreb"
              },
              "Zaporozhye": {
                "exemplarCity": "Zaporizja"
              },
              "Zurich": {
                "exemplarCity": "Zürich"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "Abidjan"
              },
              "Accra": {
                "exemplarCity": "Accra"
              },
              "Addis_Ababa": {
                "exemplarCity": "Addis Abeba"
              },
              "Algiers": {
                "exemplarCity": "Algiers"
              },
              "Asmera": {
                "exemplarCity": "Asmara"
              },
              "Bamako": {
                "exemplarCity": "Bamako"
              },
              "Bangui": {
                "exemplarCity": "Bangui"
              },
              "Banjul": {
                "exemplarCity": "Banjul"
              },
              "Bissau": {
                "exemplarCity": "Bissau"
              },
              "Blantyre": {
                "exemplarCity": "Blantyre"
              },
              "Brazzaville": {
                "exemplarCity": "Brazzaville"
              },
              "Bujumbura": {
                "exemplarCity": "Bujumbura"
              },
              "Cairo": {
                "exemplarCity": "Caïro"
              },
              "Casablanca": {
                "exemplarCity": "Casablanca"
              },
              "Ceuta": {
                "exemplarCity": "Ceuta"
              },
              "Conakry": {
                "exemplarCity": "Conakry"
              },
              "Dakar": {
                "exemplarCity": "Dakar"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "Dar es Salaam"
              },
              "Djibouti": {
                "exemplarCity": "Djibouti"
              },
              "Douala": {
                "exemplarCity": "Douala"
              },
              "El_Aaiun": {
                "exemplarCity": "El Aaiun"
              },
              "Freetown": {
                "exemplarCity": "Freetown"
              },
              "Gaborone": {
                "exemplarCity": "Gaborone"
              },
              "Harare": {
                "exemplarCity": "Harare"
              },
              "Johannesburg": {
                "exemplarCity": "Johannesburg"
              },
              "Juba": {
                "exemplarCity": "Juba"
              },
              "Kampala": {
                "exemplarCity": "Kampala"
              },
              "Khartoum": {
                "exemplarCity": "Khartoem"
              },
              "Kigali": {
                "exemplarCity": "Kigali"
              },
              "Kinshasa": {
                "exemplarCity": "Kinshasa"
              },
              "Lagos": {
                "exemplarCity": "Lagos"
              },
              "Libreville": {
                "exemplarCity": "Libreville"
              },
              "Lome": {
                "exemplarCity": "Lomé"
              },
              "Luanda": {
                "exemplarCity": "Luanda"
              },
              "Lubumbashi": {
                "exemplarCity": "Lubumbashi"
              },
              "Lusaka": {
                "exemplarCity": "Lusaka"
              },
              "Malabo": {
                "exemplarCity": "Malabo"
              },
              "Maputo": {
                "exemplarCity": "Maputo"
              },
              "Maseru": {
                "exemplarCity": "Maseru"
              },
              "Mbabane": {
                "exemplarCity": "Mbabane"
              },
              "Mogadishu": {
                "exemplarCity": "Mogadishu"
              },
              "Monrovia": {
                "exemplarCity": "Monrovia"
              },
              "Nairobi": {
                "exemplarCity": "Nairobi"
              },
              "Ndjamena": {
                "exemplarCity": "Ndjamena"
              },
              "Niamey": {
                "exemplarCity": "Niamey"
              },
              "Nouakchott": {
                "exemplarCity": "Nouakchott"
              },
              "Ouagadougou": {
                "exemplarCity": "Ouagadougou"
              },
              "Porto-Novo": {
                "exemplarCity": "Porto-Novo"
              },
              "Sao_Tome": {
                "exemplarCity": "Sao Tomé"
              },
              "Tripoli": {
                "exemplarCity": "Tripoli"
              },
              "Tunis": {
                "exemplarCity": "Tunis"
              },
              "Windhoek": {
                "exemplarCity": "Windhoek"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "Aden"
              },
              "Almaty": {
                "exemplarCity": "Alma-Ata"
              },
              "Amman": {
                "exemplarCity": "Amman"
              },
              "Anadyr": {
                "exemplarCity": "Anadyr"
              },
              "Aqtau": {
                "exemplarCity": "Aqtau"
              },
              "Aqtobe": {
                "exemplarCity": "Aqtöbe"
              },
              "Ashgabat": {
                "exemplarCity": "Asjchabad"
              },
              "Baghdad": {
                "exemplarCity": "Bagdad"
              },
              "Bahrain": {
                "exemplarCity": "Bahrein"
              },
              "Baku": {
                "exemplarCity": "Bakoe"
              },
              "Bangkok": {
                "exemplarCity": "Bangkok"
              },
              "Barnaul": {
                "exemplarCity": "Barnaul"
              },
              "Beirut": {
                "exemplarCity": "Beiroet"
              },
              "Bishkek": {
                "exemplarCity": "Bisjkek"
              },
              "Brunei": {
                "exemplarCity": "Brunei"
              },
              "Calcutta": {
                "exemplarCity": "Calcutta"
              },
              "Chita": {
                "exemplarCity": "Chita"
              },
              "Choibalsan": {
                "exemplarCity": "Choibalsan"
              },
              "Colombo": {
                "exemplarCity": "Colombo"
              },
              "Damascus": {
                "exemplarCity": "Damascus"
              },
              "Dhaka": {
                "exemplarCity": "Dhaka"
              },
              "Dili": {
                "exemplarCity": "Dili"
              },
              "Dubai": {
                "exemplarCity": "Dubai"
              },
              "Dushanbe": {
                "exemplarCity": "Doesjanbe"
              },
              "Gaza": {
                "exemplarCity": "Gaza"
              },
              "Hebron": {
                "exemplarCity": "Hebron"
              },
              "Hong_Kong": {
                "exemplarCity": "Hongkong"
              },
              "Hovd": {
                "exemplarCity": "Hovd"
              },
              "Irkutsk": {
                "exemplarCity": "Irkoetsk"
              },
              "Jakarta": {
                "exemplarCity": "Jakarta"
              },
              "Jayapura": {
                "exemplarCity": "Jayapura"
              },
              "Jerusalem": {
                "exemplarCity": "Jeruzalem"
              },
              "Kabul": {
                "exemplarCity": "Kabul"
              },
              "Kamchatka": {
                "exemplarCity": "Kamtsjatka"
              },
              "Karachi": {
                "exemplarCity": "Karachi"
              },
              "Katmandu": {
                "exemplarCity": "Kathmandu"
              },
              "Khandyga": {
                "exemplarCity": "Khandyga"
              },
              "Krasnoyarsk": {
                "exemplarCity": "Krasnojarsk"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "Kuala Lumpur"
              },
              "Kuching": {
                "exemplarCity": "Kuching"
              },
              "Kuwait": {
                "exemplarCity": "Koeweit"
              },
              "Macau": {
                "exemplarCity": "Macau"
              },
              "Magadan": {
                "exemplarCity": "Magadan"
              },
              "Makassar": {
                "exemplarCity": "Makassar"
              },
              "Manila": {
                "exemplarCity": "Manilla"
              },
              "Muscat": {
                "exemplarCity": "Muscat"
              },
              "Nicosia": {
                "exemplarCity": "Nicosia"
              },
              "Novokuznetsk": {
                "exemplarCity": "Novokuznetsk"
              },
              "Novosibirsk": {
                "exemplarCity": "Novosibirsk"
              },
              "Omsk": {
                "exemplarCity": "Omsk"
              },
              "Oral": {
                "exemplarCity": "Oral"
              },
              "Phnom_Penh": {
                "exemplarCity": "Phnom-Penh"
              },
              "Pontianak": {
                "exemplarCity": "Pontianak"
              },
              "Pyongyang": {
                "exemplarCity": "Pyongyang"
              },
              "Qatar": {
                "exemplarCity": "Qatar"
              },
              "Qyzylorda": {
                "exemplarCity": "Qyzylorda"
              },
              "Rangoon": {
                "exemplarCity": "Rangoon"
              },
              "Riyadh": {
                "exemplarCity": "Riyad"
              },
              "Saigon": {
                "exemplarCity": "Ho Chi Minhstad"
              },
              "Sakhalin": {
                "exemplarCity": "Sachalin"
              },
              "Samarkand": {
                "exemplarCity": "Samarkand"
              },
              "Seoul": {
                "exemplarCity": "Seoul"
              },
              "Shanghai": {
                "exemplarCity": "Sjanghai"
              },
              "Singapore": {
                "exemplarCity": "Singapore"
              },
              "Srednekolymsk": {
                "exemplarCity": "Srednekolymsk"
              },
              "Taipei": {
                "exemplarCity": "Taipei"
              },
              "Tashkent": {
                "exemplarCity": "Tasjkent"
              },
              "Tbilisi": {
                "exemplarCity": "Tbilisi"
              },
              "Tehran": {
                "exemplarCity": "Teheran"
              },
              "Thimphu": {
                "exemplarCity": "Thimphu"
              },
              "Tokyo": {
                "exemplarCity": "Tokio"
              },
              "Tomsk": {
                "exemplarCity": "Tomsk"
              },
              "Ulaanbaatar": {
                "exemplarCity": "Ulaanbaatar"
              },
              "Urumqi": {
                "exemplarCity": "Urumqi"
              },
              "Ust-Nera": {
                "exemplarCity": "Ust-Nera"
              },
              "Vientiane": {
                "exemplarCity": "Vientiane"
              },
              "Vladivostok": {
                "exemplarCity": "Vladivostok"
              },
              "Yakutsk": {
                "exemplarCity": "Jakoetsk"
              },
              "Yekaterinburg": {
                "exemplarCity": "Jekaterinenburg"
              },
              "Yerevan": {
                "exemplarCity": "Jerevan"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "Antananarivo"
              },
              "Chagos": {
                "exemplarCity": "Chagosarchipel"
              },
              "Christmas": {
                "exemplarCity": "Christmaseiland"
              },
              "Cocos": {
                "exemplarCity": "Cocoseilanden"
              },
              "Comoro": {
                "exemplarCity": "Comoro"
              },
              "Kerguelen": {
                "exemplarCity": "Kerguelen"
              },
              "Mahe": {
                "exemplarCity": "Mahé"
              },
              "Maldives": {
                "exemplarCity": "Maldiven"
              },
              "Mauritius": {
                "exemplarCity": "Mauritius"
              },
              "Mayotte": {
                "exemplarCity": "Mayotte"
              },
              "Reunion": {
                "exemplarCity": "Réunion"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "Adelaide"
              },
              "Brisbane": {
                "exemplarCity": "Brisbane"
              },
              "Broken_Hill": {
                "exemplarCity": "Broken Hill"
              },
              "Currie": {
                "exemplarCity": "Currie"
              },
              "Darwin": {
                "exemplarCity": "Darwin"
              },
              "Eucla": {
                "exemplarCity": "Eucla"
              },
              "Hobart": {
                "exemplarCity": "Hobart"
              },
              "Lindeman": {
                "exemplarCity": "Lindeman"
              },
              "Lord_Howe": {
                "exemplarCity": "Lord Howe"
              },
              "Melbourne": {
                "exemplarCity": "Melbourne"
              },
              "Perth": {
                "exemplarCity": "Perth"
              },
              "Sydney": {
                "exemplarCity": "Sydney"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "Apia"
              },
              "Auckland": {
                "exemplarCity": "Auckland"
              },
              "Bougainville": {
                "exemplarCity": "Bougainville"
              },
              "Chatham": {
                "exemplarCity": "Chatham"
              },
              "Easter": {
                "exemplarCity": "Paaseiland"
              },
              "Efate": {
                "exemplarCity": "Efate"
              },
              "Enderbury": {
                "exemplarCity": "Enderbury"
              },
              "Fakaofo": {
                "exemplarCity": "Fakaofo"
              },
              "Fiji": {
                "exemplarCity": "Fiji"
              },
              "Funafuti": {
                "exemplarCity": "Funafuti"
              },
              "Galapagos": {
                "exemplarCity": "Galapagos"
              },
              "Gambier": {
                "exemplarCity": "Îles Gambier"
              },
              "Guadalcanal": {
                "exemplarCity": "Guadalcanal"
              },
              "Guam": {
                "exemplarCity": "Guam"
              },
              "Honolulu": {
                "short": {
                  "generic": "HST",
                  "standard": "HST",
                  "daylight": "HDT"
                },
                "exemplarCity": "Honolulu"
              },
              "Johnston": {
                "exemplarCity": "Johnston"
              },
              "Kiritimati": {
                "exemplarCity": "Kiritimati"
              },
              "Kosrae": {
                "exemplarCity": "Kosrae"
              },
              "Kwajalein": {
                "exemplarCity": "Kwajalein"
              },
              "Majuro": {
                "exemplarCity": "Majuro"
              },
              "Marquesas": {
                "exemplarCity": "Marquesaseilanden"
              },
              "Midway": {
                "exemplarCity": "Midway"
              },
              "Nauru": {
                "exemplarCity": "Nauru"
              },
              "Niue": {
                "exemplarCity": "Niue"
              },
              "Norfolk": {
                "exemplarCity": "Norfolk"
              },
              "Noumea": {
                "exemplarCity": "Nouméa"
              },
              "Pago_Pago": {
                "exemplarCity": "Pago Pago"
              },
              "Palau": {
                "exemplarCity": "Palau"
              },
              "Pitcairn": {
                "exemplarCity": "Pitcairn"
              },
              "Ponape": {
                "exemplarCity": "Pohnpei"
              },
              "Port_Moresby": {
                "exemplarCity": "Port Moresby"
              },
              "Rarotonga": {
                "exemplarCity": "Rarotonga"
              },
              "Saipan": {
                "exemplarCity": "Saipan"
              },
              "Tahiti": {
                "exemplarCity": "Tahiti"
              },
              "Tarawa": {
                "exemplarCity": "Tarawa"
              },
              "Tongatapu": {
                "exemplarCity": "Tongatapu"
              },
              "Truk": {
                "exemplarCity": "Chuuk"
              },
              "Wake": {
                "exemplarCity": "Wake"
              },
              "Wallis": {
                "exemplarCity": "Wallis"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "Longyearbyen"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "Casey"
              },
              "Davis": {
                "exemplarCity": "Davis"
              },
              "DumontDUrville": {
                "exemplarCity": "Dumont d’Urville"
              },
              "Macquarie": {
                "exemplarCity": "Macquarie"
              },
              "Mawson": {
                "exemplarCity": "Mawson"
              },
              "McMurdo": {
                "exemplarCity": "McMurdo"
              },
              "Palmer": {
                "exemplarCity": "Palmer"
              },
              "Rothera": {
                "exemplarCity": "Rothera"
              },
              "Syowa": {
                "exemplarCity": "Syowa"
              },
              "Troll": {
                "exemplarCity": "Troll"
              },
              "Vostok": {
                "exemplarCity": "Vostok"
              }
            },
            "Etc": {
              "GMT": {
                "exemplarCity": "GMT"
              },
              "GMT1": {
                "exemplarCity": "GMT+1"
              },
              "GMT10": {
                "exemplarCity": "GMT+10"
              },
              "GMT11": {
                "exemplarCity": "GMT+11"
              },
              "GMT12": {
                "exemplarCity": "GMT+12"
              },
              "GMT2": {
                "exemplarCity": "GMT+2"
              },
              "GMT3": {
                "exemplarCity": "GMT+3"
              },
              "GMT4": {
                "exemplarCity": "GMT+4"
              },
              "GMT5": {
                "exemplarCity": "GMT+5"
              },
              "GMT6": {
                "exemplarCity": "GMT+6"
              },
              "GMT7": {
                "exemplarCity": "GMT+7"
              },
              "GMT8": {
                "exemplarCity": "GMT+8"
              },
              "GMT9": {
                "exemplarCity": "GMT+9"
              },
              "GMT-1": {
                "exemplarCity": "GMT-1"
              },
              "GMT-10": {
                "exemplarCity": "GMT-10"
              },
              "GMT-11": {
                "exemplarCity": "GMT-11"
              },
              "GMT-12": {
                "exemplarCity": "GMT-12"
              },
              "GMT-13": {
                "exemplarCity": "GMT-13"
              },
              "GMT-14": {
                "exemplarCity": "GMT-14"
              },
              "GMT-2": {
                "exemplarCity": "GMT-2"
              },
              "GMT-3": {
                "exemplarCity": "GMT-3"
              },
              "GMT-4": {
                "exemplarCity": "GMT-4"
              },
              "GMT-5": {
                "exemplarCity": "GMT-5"
              },
              "GMT-6": {
                "exemplarCity": "GMT-6"
              },
              "GMT-7": {
                "exemplarCity": "GMT-7"
              },
              "GMT-8": {
                "exemplarCity": "GMT-8"
              },
              "GMT-9": {
                "exemplarCity": "GMT-9"
              },
              "UTC": {
                "long": {
                  "standard": "Gecoördineerde wereldtijd"
                },
                "short": {
                  "standard": "UTC"
                },
                "exemplarCity": "UTC"
              },
              "Unknown": {
                "exemplarCity": "onbekende stad"
              }
            }
          },
          "metazone": {
            "Acre": {
              "long": {
                "generic": "Acre-tijd",
                "standard": "Acre-standaardtijd",
                "daylight": "Acre-zomertijd"
              }
            },
            "Afghanistan": {
              "long": {
                "standard": "Afghaanse tijd"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "Centraal-Afrikaanse tijd"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "Oost-Afrikaanse tijd"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "Zuid-Afrikaanse tijd"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "West-Afrikaanse tijd",
                "standard": "West-Afrikaanse standaardtijd",
                "daylight": "West-Afrikaanse zomertijd"
              }
            },
            "Alaska": {
              "long": {
                "generic": "Alaska-tijd",
                "standard": "Alaska-standaardtijd",
                "daylight": "Alaska-zomertijd"
              }
            },
            "Almaty": {
              "long": {
                "generic": "Alma-Ata-tijd",
                "standard": "Alma-Ata-standaardtijd",
                "daylight": "Alma-Ata-zomertijd"
              }
            },
            "Amazon": {
              "long": {
                "generic": "Amazone-tijd",
                "standard": "Amazone-standaardtijd",
                "daylight": "Amazone-zomertijd"
              }
            },
            "America_Central": {
              "long": {
                "generic": "Central-tijd",
                "standard": "Central-standaardtijd",
                "daylight": "Central-zomertijd"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "Eastern-tijd",
                "standard": "Eastern-standaardtijd",
                "daylight": "Eastern-zomertijd"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "Mountain-tijd",
                "standard": "Mountain-standaardtijd",
                "daylight": "Mountain-zomertijd"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "Pacific-tijd",
                "standard": "Pacific-standaardtijd",
                "daylight": "Pacific-zomertijd"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "Anadyr-tijd",
                "standard": "Anadyr-standaardtijd",
                "daylight": "Anadyr-zomertijd"
              }
            },
            "Apia": {
              "long": {
                "generic": "Apia-tijd",
                "standard": "Apia-standaardtijd",
                "daylight": "Apia-zomertijd"
              }
            },
            "Aqtau": {
              "long": {
                "generic": "Aqtau-tijd",
                "standard": "Aqtau-standaardtijd",
                "daylight": "Aqtau-zomertijd"
              }
            },
            "Aqtobe": {
              "long": {
                "generic": "Aqtöbe-tijd",
                "standard": "Aqtöbe-standaardtijd",
                "daylight": "Aqtöbe-zomertijd"
              }
            },
            "Arabian": {
              "long": {
                "generic": "Arabische tijd",
                "standard": "Arabische standaardtijd",
                "daylight": "Arabische zomertijd"
              }
            },
            "Argentina": {
              "long": {
                "generic": "Argentijnse tijd",
                "standard": "Argentijnse standaardtijd",
                "daylight": "Argentijnse zomertijd"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "West-Argentijnse tijd",
                "standard": "West-Argentijnse standaardtijd",
                "daylight": "West-Argentijnse zomertijd"
              }
            },
            "Armenia": {
              "long": {
                "generic": "Armeense tijd",
                "standard": "Armeense standaardtijd",
                "daylight": "Armeense zomertijd"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "Atlantic-tijd",
                "standard": "Atlantic-standaardtijd",
                "daylight": "Atlantic-zomertijd"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "Midden-Australische tijd",
                "standard": "Midden-Australische standaardtijd",
                "daylight": "Midden-Australische zomertijd"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "Midden-Australische westelijke tijd",
                "standard": "Midden-Australische westelijke standaardtijd",
                "daylight": "Midden-Australische westelijke zomertijd"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "Oost-Australische tijd",
                "standard": "Oost-Australische standaardtijd",
                "daylight": "Oost-Australische zomertijd"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "West-Australische tijd",
                "standard": "West-Australische standaardtijd",
                "daylight": "West-Australische zomertijd"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "Azerbeidzjaanse tijd",
                "standard": "Azerbeidzjaanse standaardtijd",
                "daylight": "Azerbeidzjaanse zomertijd"
              }
            },
            "Azores": {
              "long": {
                "generic": "Azoren-tijd",
                "standard": "Azoren-standaardtijd",
                "daylight": "Azoren-zomertijd"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "Bengalese tijd",
                "standard": "Bengalese standaardtijd",
                "daylight": "Bengalese zomertijd"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "Bhutaanse tijd"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "Boliviaanse tijd"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "Braziliaanse tijd",
                "standard": "Braziliaanse standaardtijd",
                "daylight": "Braziliaanse zomertijd"
              }
            },
            "Brunei": {
              "long": {
                "standard": "Bruneise tijd"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "Kaapverdische tijd",
                "standard": "Kaapverdische standaardtijd",
                "daylight": "Kaapverdische zomertijd"
              }
            },
            "Casey": {
              "long": {
                "standard": "Casey tijd"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "Chamorro-tijd"
              }
            },
            "Chatham": {
              "long": {
                "generic": "Chatham-tijd",
                "standard": "Chatham-standaardtijd",
                "daylight": "Chatham-zomertijd"
              }
            },
            "Chile": {
              "long": {
                "generic": "Chileense tijd",
                "standard": "Chileense standaardtijd",
                "daylight": "Chileense zomertijd"
              }
            },
            "China": {
              "long": {
                "generic": "Chinese tijd",
                "standard": "Chinese standaardtijd",
                "daylight": "Chinese zomertijd"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "Tsjojbalsan-tijd",
                "standard": "Tsjojbalsan-standaardtijd",
                "daylight": "Tsjojbalsan-zomertijd"
              }
            },
            "Christmas": {
              "long": {
                "standard": "Christmaseilandse tijd"
              }
            },
            "Cocos": {
              "long": {
                "standard": "Cocoseilandse tijd"
              }
            },
            "Colombia": {
              "long": {
                "generic": "Colombiaanse tijd",
                "standard": "Colombiaanse standaardtijd",
                "daylight": "Colombiaanse zomertijd"
              }
            },
            "Cook": {
              "long": {
                "generic": "Cookeilandse tijd",
                "standard": "Cookeilandse standaardtijd",
                "daylight": "Cookeilandse halve zomertijd"
              }
            },
            "Cuba": {
              "long": {
                "generic": "Cubaanse tijd",
                "standard": "Cubaanse standaardtijd",
                "daylight": "Cubaanse zomertijd"
              }
            },
            "Davis": {
              "long": {
                "standard": "Davis-tijd"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "Dumont-d’Urville-tijd"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "Oost-Timorese tijd"
              }
            },
            "Easter": {
              "long": {
                "generic": "Paaseilandse tijd",
                "standard": "Paaseilandse standaardtijd",
                "daylight": "Paaseilandse zomertijd"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "Ecuadoraanse tijd"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "Midden-Europese tijd",
                "standard": "Midden-Europese standaardtijd",
                "daylight": "Midden-Europese zomertijd"
              },
              "short": {
                "generic": "CET",
                "standard": "CET",
                "daylight": "CEST"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "Oost-Europese tijd",
                "standard": "Oost-Europese standaardtijd",
                "daylight": "Oost-Europese zomertijd"
              },
              "short": {
                "generic": "EET",
                "standard": "EET",
                "daylight": "EEST"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "Verder-oostelijk-Europese tijd"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "West-Europese tijd",
                "standard": "West-Europese standaardtijd",
                "daylight": "West-Europese zomertijd"
              },
              "short": {
                "generic": "WET",
                "standard": "WET",
                "daylight": "WEST"
              }
            },
            "Falkland": {
              "long": {
                "generic": "Falklandeilandse tijd",
                "standard": "Falklandeilandse standaardtijd",
                "daylight": "Falklandeilandse zomertijd"
              }
            },
            "Fiji": {
              "long": {
                "generic": "Fijische tijd",
                "standard": "Fijische standaardtijd",
                "daylight": "Fijische zomertijd"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "Frans-Guyaanse tijd"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "Franse zuidelijke en Antarctische tijd"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "Galapagoseilandse tijd"
              }
            },
            "Gambier": {
              "long": {
                "standard": "Gambiereilandse tijd"
              }
            },
            "Georgia": {
              "long": {
                "generic": "Georgische tijd",
                "standard": "Georgische standaardtijd",
                "daylight": "Georgische zomertijd"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "Gilberteilandse tijd"
              }
            },
            "GMT": {
              "long": {
                "standard": "Greenwich Mean Time"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "Oost-Groenlandse tijd",
                "standard": "Oost-Groenlandse standaardtijd",
                "daylight": "Oost-Groenlandse zomertijd"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "West-Groenlandse tijd",
                "standard": "West-Groenlandse standaardtijd",
                "daylight": "West-Groenlandse zomertijd"
              }
            },
            "Guam": {
              "long": {
                "standard": "Guamese standaardtijd"
              }
            },
            "Gulf": {
              "long": {
                "standard": "Golf-standaardtijd"
              }
            },
            "Guyana": {
              "long": {
                "standard": "Guyaanse tijd"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "Hawaii-Aleoetische tijd",
                "standard": "Hawaii-Aleoetische standaardtijd",
                "daylight": "Hawaii-Aleoetische zomertijd"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "Hongkongse tijd",
                "standard": "Hongkongse standaardtijd",
                "daylight": "Hongkongse zomertijd"
              }
            },
            "Hovd": {
              "long": {
                "generic": "Hovd-tijd",
                "standard": "Hovd-standaardtijd",
                "daylight": "Hovd-zomertijd"
              }
            },
            "India": {
              "long": {
                "standard": "Indiase tijd"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "Indische Oceaan-tijd"
              }
            },
            "Indochina": {
              "long": {
                "standard": "Indochinese tijd"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "Centraal-Indonesische tijd"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "Oost-Indonesische tijd"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "West-Indonesische tijd"
              }
            },
            "Iran": {
              "long": {
                "generic": "Iraanse tijd",
                "standard": "Iraanse standaardtijd",
                "daylight": "Iraanse zomertijd"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "Irkoetsk-tijd",
                "standard": "Irkoetsk-standaardtijd",
                "daylight": "Irkoetsk-zomertijd"
              }
            },
            "Israel": {
              "long": {
                "generic": "Israëlische tijd",
                "standard": "Israëlische standaardtijd",
                "daylight": "Israëlische zomertijd"
              }
            },
            "Japan": {
              "long": {
                "generic": "Japanse tijd",
                "standard": "Japanse standaardtijd",
                "daylight": "Japanse zomertijd"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "Petropavlovsk-Kamtsjatski-tijd",
                "standard": "Petropavlovsk-Kamtsjatski-standaardtijd",
                "daylight": "Petropavlovsk-Kamtsjatski-zomertijd"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "Oost-Kazachse tijd"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "West-Kazachse tijd"
              }
            },
            "Korea": {
              "long": {
                "generic": "Koreaanse tijd",
                "standard": "Koreaanse standaardtijd",
                "daylight": "Koreaanse zomertijd"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "Kosraese tijd"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "Krasnojarsk-tijd",
                "standard": "Krasnojarsk-standaardtijd",
                "daylight": "Krasnojarsk-zomertijd"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "Kirgizische tijd"
              }
            },
            "Lanka": {
              "long": {
                "standard": "Lanka-tijd"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "Line-eilandse tijd"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "Lord Howe-eilandse tijd",
                "standard": "Lord Howe-eilandse standaardtijd",
                "daylight": "Lord Howe-eilandse zomertijd"
              }
            },
            "Macau": {
              "long": {
                "generic": "Macause tijd",
                "standard": "Macause standaardtijd",
                "daylight": "Macause zomertijd"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "Macquarie-eilandse tijd"
              }
            },
            "Magadan": {
              "long": {
                "generic": "Magadan-tijd",
                "standard": "Magadan-standaardtijd",
                "daylight": "Magadan-zomertijd"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "Maleisische tijd"
              }
            },
            "Maldives": {
              "long": {
                "standard": "Maldivische tijd"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "Marquesaseilandse tijd"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "Marshalleilandse tijd"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "Mauritiaanse tijd",
                "standard": "Mauritiaanse standaardtijd",
                "daylight": "Mauritiaanse zomertijd"
              }
            },
            "Mawson": {
              "long": {
                "standard": "Mawson-tijd"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "Noordwest-Mexicaanse tijd",
                "standard": "Noordwest-Mexicaanse standaardtijd",
                "daylight": "Noordwest-Mexicaanse zomertijd"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "Mexicaanse Pacific-tijd",
                "standard": "Mexicaanse Pacific-standaardtijd",
                "daylight": "Mexicaanse Pacific-zomertijd"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "Ulaanbaatar-tijd",
                "standard": "Ulaanbaatar-standaardtijd",
                "daylight": "Ulaanbaatar-zomertijd"
              }
            },
            "Moscow": {
              "long": {
                "generic": "Moskou-tijd",
                "standard": "Moskou-standaardtijd",
                "daylight": "Moskou-zomertijd"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "Myanmarese tijd"
              }
            },
            "Nauru": {
              "long": {
                "standard": "Nauruaanse tijd"
              }
            },
            "Nepal": {
              "long": {
                "standard": "Nepalese tijd"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "Nieuw-Caledonische tijd",
                "standard": "Nieuw-Caledonische standaardtijd",
                "daylight": "Nieuw-Caledonische zomertijd"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "Nieuw-Zeelandse tijd",
                "standard": "Nieuw-Zeelandse standaardtijd",
                "daylight": "Nieuw-Zeelandse zomertijd"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "Newfoundland-tijd",
                "standard": "Newfoundland-standaardtijd",
                "daylight": "Newfoundland-zomertijd"
              }
            },
            "Niue": {
              "long": {
                "standard": "Niuese tijd"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "Norfolkeilandse tijd"
              }
            },
            "Noronha": {
              "long": {
                "generic": "Fernando de Noronha-tijd",
                "standard": "Fernando de Noronha-standaardtijd",
                "daylight": "Fernando de Noronha-zomertijd"
              }
            },
            "North_Mariana": {
              "long": {
                "standard": "Noordelijk Mariaanse tijd"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "Novosibirsk-tijd",
                "standard": "Novosibirsk-standaardtijd",
                "daylight": "Novosibirsk-zomertijd"
              }
            },
            "Omsk": {
              "long": {
                "generic": "Omsk-tijd",
                "standard": "Omsk-standaardtijd",
                "daylight": "Omsk-zomertijd"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "Pakistaanse tijd",
                "standard": "Pakistaanse standaardtijd",
                "daylight": "Pakistaanse zomertijd"
              }
            },
            "Palau": {
              "long": {
                "standard": "Belause tijd"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "Papoea-Nieuw-Guineese tijd"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "Paraguayaanse tijd",
                "standard": "Paraguayaanse standaardtijd",
                "daylight": "Paraguayaanse zomertijd"
              }
            },
            "Peru": {
              "long": {
                "generic": "Peruaanse tijd",
                "standard": "Peruaanse standaardtijd",
                "daylight": "Peruaanse zomertijd"
              }
            },
            "Philippines": {
              "long": {
                "generic": "Filipijnse tijd",
                "standard": "Filipijnse standaardtijd",
                "daylight": "Filipijnse zomertijd"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "Phoenixeilandse tijd"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "Saint Pierre en Miquelon-tijd",
                "standard": "Saint Pierre en Miquelon-standaardtijd",
                "daylight": "Saint Pierre en Miquelon-zomertijd"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "Pitcairneilandse tijd"
              }
            },
            "Ponape": {
              "long": {
                "standard": "Pohnpei-tijd"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "Pyongyang-tijd"
              }
            },
            "Qyzylorda": {
              "long": {
                "generic": "Qyzylorda-tijd",
                "standard": "Qyzylorda-standaardtijd",
                "daylight": "Qyzylorda-zomertijd"
              }
            },
            "Reunion": {
              "long": {
                "standard": "Réunionse tijd"
              }
            },
            "Rothera": {
              "long": {
                "standard": "Rothera-tijd"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "Sachalin-tijd",
                "standard": "Sachalin-standaardtijd",
                "daylight": "Sachalin-zomertijd"
              }
            },
            "Samara": {
              "long": {
                "generic": "Samara-tijd",
                "standard": "Samara-standaardtijd",
                "daylight": "Samara-zomertijd"
              }
            },
            "Samoa": {
              "long": {
                "generic": "Samoaanse tijd",
                "standard": "Samoaanse standaardtijd",
                "daylight": "Samoaanse zomertijd"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "Seychelse tijd"
              }
            },
            "Singapore": {
              "long": {
                "standard": "Singaporese standaardtijd"
              }
            },
            "Solomon": {
              "long": {
                "standard": "Salomonseilandse tijd"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "Zuid-Georgische tijd"
              }
            },
            "Suriname": {
              "long": {
                "standard": "Surinaamse tijd"
              }
            },
            "Syowa": {
              "long": {
                "standard": "Syowa-tijd"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "Tahitiaanse tijd"
              }
            },
            "Taipei": {
              "long": {
                "generic": "Taipei-tijd",
                "standard": "Taipei-standaardtijd",
                "daylight": "Taipei-zomertijd"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "Tadzjiekse tijd"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "Tokelau-eilandse tijd"
              }
            },
            "Tonga": {
              "long": {
                "generic": "Tongaanse tijd",
                "standard": "Tongaanse standaardtijd",
                "daylight": "Tongaanse zomertijd"
              }
            },
            "Truk": {
              "long": {
                "standard": "Chuukse tijd"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "Turkmeense tijd",
                "standard": "Turkmeense standaardtijd",
                "daylight": "Turkmeense zomertijd"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "Tuvaluaanse tijd"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "Uruguayaanse tijd",
                "standard": "Uruguayaanse standaardtijd",
                "daylight": "Uruguayaanse zomertijd"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "Oezbeekse tijd",
                "standard": "Oezbeekse standaardtijd",
                "daylight": "Oezbeekse zomertijd"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "Vanuatuaanse tijd",
                "standard": "Vanuatuaanse standaardtijd",
                "daylight": "Vanuatuaanse zomertijd"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "Venezolaanse tijd"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "Vladivostok-tijd",
                "standard": "Vladivostok-standaardtijd",
                "daylight": "Vladivostok-zomertijd"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "Wolgograd-tijd",
                "standard": "Wolgograd-standaardtijd",
                "daylight": "Wolgograd-zomertijd"
              }
            },
            "Vostok": {
              "long": {
                "standard": "Vostok-tijd"
              }
            },
            "Wake": {
              "long": {
                "standard": "Wake-eilandse tijd"
              }
            },
            "Wallis": {
              "long": {
                "standard": "Wallis en Futunase tijd"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "Jakoetsk-tijd",
                "standard": "Jakoetsk-standaardtijd",
                "daylight": "Jakoetsk-zomertijd"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "Jekaterinenburg-tijd",
                "standard": "Jekaterinenburg-standaardtijd",
                "daylight": "Jekaterinenburg-zomertijd"
              }
            }
          }
        }
      }
    }
  }
}
