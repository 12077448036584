const React = require('react');
const ReactDOM = require('react-dom');
const PureRenderMixin = require('react-addons-pure-render-mixin');
const Immutable = require('../immutab/dist/immutable');
const TextField = require('../matui/text-field.jsx');
const Checkbox = require('../matui/checkbox.jsx');
const IconButton = require('../matui/icon-button.jsx');
const IconButOver = require('../matui/icon_but_over.jsx');
const FontIcon = require('../matui/font-icon.jsx');

const AlgFuncUpd = require('../functions/algemeen');
var ReactTags = require('react-tag-input').WithContext;
const Mattag = require('../matui/mattag.jsx');
const Taglist = require('../matui/lists/taglist.jsx');
const SubLijst = require('./sublijstnw.jsx');
var Button = require("react-bootstrap").Button;
const FlatButton = require('../matui/flat-button.jsx');

const DateTime = require('../matui/utils/date-time');
const Snackbar = require('../matui/snackbar.jsx');
var Panel = require("react-bootstrap").Panel;
var PanelGroup = require("react-bootstrap").PanelGroup;
var Row = require("react-bootstrap").Row;
var Col = require("react-bootstrap").Col;
var Nav = require("react-bootstrap").Nav;
var NavItem = require("react-bootstrap").NavItem;
const Contstafunc = require('./cont_func.jsx');
var Image = require("react-bootstrap").Image;
var MenuItem = require("react-bootstrap").MenuItem;

const PopupAdres = require('./popdefadres.jsx');
const PopupSubFixt = require('./popsubfixt.jsx');
const PopupCopyFixt = require('./popcopyfixt.jsx');
const PopupSlider = require('./popdefslider.jsx');
const PopupChTxt = require('./popchgtxtfixt.jsx');

const Slider = require('../matui/slider.jsx');
const ClsFixt = require('./cls_fixtures.jsx');
const CircularProgress = require('../matui/circular-progress.jsx');

const Dialog = require('../matui/dialog.jsx');

//import {Radar} from 'react-chartjs-2';


let Schemamainvorm = React.createClass({
  mixins: [PureRenderMixin],
  contextTypes:{
    router: React.PropTypes.object    
  },
  getInitialState() {
    return {
      aanpassen:0,
      circpro:null,
      selcol:0,
      slidtxt:'',
      adrname:'',
      adrmanuf:'',
      selectie:1,
      selchan:0,
      channame:'',
      accountid:'',
      slidmaindata:[],
      lichtData:[],
      sliddata1:[],
      sliddata2:[],
      sliddata3:[],
      sliddata4:[],
      sliddata5:[],
      sliddata6:[],
      sliddata7:[],
      sliddata8:[],
      sliddata9:[],
      sliddata10:[],
      sliddata11:[],
      sliddata12:[],
      sliddata13:[],
      sliddata14:[],
      bgcolor1:'rgba(47, 165, 199, 0.85)',
      bgcolor2:'rgba(47, 165, 199, 0.85)',
      bgcolor3:'rgba(47, 165, 199, 0.85)',
      bgcolor4:'rgba(47, 165, 199, 0.85)',
      bgcolor5:'rgba(47, 165, 199, 0.85)',
      bgcolor6:'rgba(47, 165, 199, 0.85)',
      bgcolor7:'rgba(47, 165, 199, 0.85)',
      bgcolor8:'rgba(47, 165, 199, 0.85)',
      bgcolor9:'rgba(47, 165, 199, 0.85)',
      bgcolor10:'rgba(47, 165, 199, 0.85)',
      bgcolor11:'rgba(47, 165, 199, 0.85)',
      bgcolor12:'rgba(47, 165, 199, 0.85)',
      bgcolor13:'rgba(47, 165, 199, 0.85)',
      bgcolor14:'rgba(47, 165, 199, 0.85)',
      bgcolor15:'rgba(47, 165, 199, 0.85)',
      bgcolor16:'rgba(47, 165, 199, 0.85)',
      bgcolor17:'rgba(47, 165, 199, 0.85)',
      bgcolor18:'rgba(47, 165, 199, 0.85)',
      bgcolor19:'rgba(47, 165, 199, 0.85)',
      bgcolor20:'rgba(47, 165, 199, 0.85)',
      bgcolor21:'rgba(47, 165, 199, 0.85)',
      bgcolor22:'rgba(47, 165, 199, 0.85)',
      bgcolor23:'rgba(47, 165, 199, 0.85)',
      slidvis1:false,
      slidvis2:false,
      slidvis3:false,
      slidvis4:false,
      slidvis5:false,
      slidvis6:false,
      slidvis7:false,
      slidvis8:false,                                          
      slidvis9:false,
      slidvis10:false,
      slidvis11:false,
      slidvis12:false,
      slidvis13:false,
      slidvis14:false,
      slidcol1:'rgb(47, 165, 199)',      
      slidcol2:'rgb(47, 165, 199)',      
      slidcol3:'rgb(47, 165, 199)',      
      slidcol4:'rgb(47, 165, 199)',      
      slidcol5:'rgb(47, 165, 199)',      
      slidcol6:'rgb(47, 165, 199)',      
      slidcol7:'rgb(47, 165, 199)',      
      slidcol8:'rgb(47, 165, 199)',      
      slidcol9:'rgb(47, 165, 199)',      
      slidcol10:'rgb(47, 165, 199)',      
      slidcol11:'rgb(47, 165, 199)',      
      slidcol12:'rgb(47, 165, 199)',      
      slidcol13:'rgb(47, 165, 199)',      
      slidcol14:'rgb(47, 165, 199)',      
      slidmax1:100,      
      slidmax2:100,      
      slidmax3:100,      
      slidmax4:100,      
      slidmax5:100,      
      slidmax6:100,      
      slidmax7:100,      
      slidmax8:100,      
      slidmax9:100,      
      slidmax10:100,      
      slidmax11:100,      
      slidmax12:100,      
      slidmax13:100,      
      slidmax14:100,
      adresdata: [],   
      channeldata: [],
      collapsible:true,
      seltxtcol:0,
      slider2:null,
      slidmax:0,
      dispmeer:'none',
      popadres:null,
      popslider:null,
      popsubfixt:null,
      popcopyfixt:null,      
      popupcons:null,
      popupchtxt:null,
      txtdata:null,  
      lichtkol:[{"datakey":"fixture_text","label":"","width":"40","sortable":"0","colsorttype":"Str","type":"","cellrender":"Y"},
          {"datakey":"fixture_low","label":"Level Low","width":"25","sortable":"0","colsorttype":"","type":"","cellrender":"Y"},
          {"datakey":"fixture_high","label":"Level High","width":"25","sortable":"0","colsorttype":"","type":"","cellrender":"Y"},],
      txtzien:(<div style={{marginTop: 'auto',marginBottom: 'auto', display: 'inline-block'}}><span style={{fontSize:'10px',fontWeight: 'normal',color: '#2fa5c7'}} >Meer zien</span>
                <FontIcon
                  className="fa fa-sort-desc"
                  style={{color:'#2fa5c7', iconHoverColor:'darkslateblue',fontSize:'15px',paddingLeft: '5px',bottom:'2px'}}>
                </FontIcon> </div>),
      tagpadtop:'0px',
      jaar:0,
      expa:false,      
      ConsId:'',
      vorm:'CONS',
      sqldata: {param: 0},   
      edit:0,
      activkey:1,
      err_naam:'',
      updatecol:'',
      updatetxt:'', 
      data: {id:0, name:'',accountname:'',vischan:'',unvischan:''},      
      route: '',
      searchkey: 'id',
      height: (window.innerHeight).toString(),
      HListData:[],
      dgcolumns:[],      
      colFilter: [],
      immuData:Immutable.List(),
      contcons:Immutable.List(),
      dragstart:0,
    };
  },
  componentWillMount() {
    let nw_date = new Date();
    this.state.jaar = nw_date.getFullYear();
  },
  componentDidMount() {
    this.setState({activkey:1})
    if (this.props.popup && this.props.data)
    {
        this.zetedit(this.props.data.id);
        this.state.data.id = this.props.data.id;
    }       
  },
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data != null)
    {
      let data = nextProps.data;
      if (data.id)
      {

        if (data.id != this.state.data.id)
        {
          this.zetedit(data.id);
          this.state.data.id = data.id;
        }
      }
    }
  },

  render() {
    let mwdth = (screen.width < 750) ? '100%' : '90%';
    let wdth = (screen.width < 750) ? '100%' : '90%';
    let cmwdth = (screen.width < 750) ? '100%' : '98%';
    let cwdth = (screen.width < 750) ? '100%' : '98%';
    let clft = (screen.width < 750) ? '0' : '5%';
    var h = window.innerHeight * .8;
    var ch = window.innerHeight * .78;
    var maxhintern = (window.innerHeight - 110) + 'px';

    let formatChars = {"9": "[0-9,.-]",};

    var maxhdial = window.innerHeight + 'px';
    var maxhdialrol = (window.innerHeight - 250) + 'px';


    var slidertxt1 = (this.state.selchan == 1) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '-65px auto 0px -22px', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px', textAlign: 'center',fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt2 = (this.state.selchan == 2) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt3 = (this.state.selchan == 3) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt4 = (this.state.selchan == 4) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt5 = (this.state.selchan == 5) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt6 = (this.state.selchan == 6) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt7 = (this.state.selchan == 7) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt8 = (this.state.selchan == 8) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt9 = (this.state.selchan == 9) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt10 = (this.state.selchan == 10) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt11 = (this.state.selchan == 11) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt12 = (this.state.selchan == 12) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt13 = (this.state.selchan == 13) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;
    var slidertxt14 = (this.state.selchan == 14) ? (<div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox',marginTop: '-50px', WebkitFlexDirection: 'column', 
                        MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '50px', width: '50px', borderRadius: '50%'}}>
                        <h1 dir="ltr" style={{fontSize:'12px',marginLeft:'-10px', fontWeight: '100',lineHeight: '24px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.slidtxt}</h1>
                      </div>) : null;


    let icont = (App.Admin == 'GB' ) ? null : 
                (this.state.seltxtcol > 0) ? (<IconButOver
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
                  tooltip="Change text selected rows"
                  iconClassName="fa fa-newspaper-o" 
                  onClick={this.TextClick}/>) : null;


    let icdel = (App.Admin == 'GB' ) ? null : 
                (this.state.seltxtcol > 0) ? (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                  iconClassName="fa fa-trash-o" 
                  tooltip="Delete selected rows" 
                  onClick={this.DeleteClick}/>) : null;

    let iccopy = (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                  iconClassName="fa fa-files-o" 
                  tooltip="Copy fixture" 
                  onClick={this.CopyClick}/>);


    let icexport = (
                <IconButOver                       
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'100%'}}
                  iconClassName="fa fa-file-excel-o" 
                  tooltip="Export schema" 
                  onClick={this.handleExport}/>);

    let icedit = (App.Admin == 'GB' ) ? null : 
            (<IconButOver
            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
            iconClassName="fa fa-pencil-square-o" 
            tooltip="Aanpassen" 
            onMouseLeave={this.MouseLeave}
            onMouseEnter={this.MouseEnter}
            onClick={this.EditAdr}/>);

    
    

    let tot_zichtpop = null;
    let tot_zicht1 = null;
    let tot_zicht2 = null;
    let brgrrechts = (this.props.brgrrechts) ? (this.props.brgrrechts * .8) :296;

    // Definieren van de headers
    var w = window.innerWidth - 5;
    var h = window.innerHeight - 300;
    let beeld = screen.width;
    let beeldh = screen.height;
    var winbreed = window.innerWidth;

    let gridbreed = (winbreed > 1850) ? ((winbreed / 100) * 95) -1050 : ((winbreed / 100) * 95) -750;   
    if (winbreed > 1850 && gridbreed > 900) {gridbreed = 850}
    if (winbreed < 1850 && gridbreed > 2000) {gridbreed = 1650}
    if (gridbreed < 1000) {gridbreed = 650}

  
    let lst_licht = (App.Admin == 'GB' && this.state.data.name.length > 1 && this.state.channame.length > 1 && this.state.selchan > 0 && this.state.channeldata.length > 0 && this.state.lichtData.length > 0 ) ? (<SubLijst ref="LichtLijst" id='LichtLijstRef' route='lichtlijst'  
        hdata={{route:'lichtlijst', data:this.state.lichtData, kol:this.state.lichtkol}} height={300} mhoog={300} breed={300} hoog={300} 
        sleutel={this.state.channeldata[this.state.selchan -1].id} behandel={false} zoeken={false} />) :
        (this.state.data.name.length > 1 && this.state.channame.length > 1 && this.state.selchan > 0 && this.state.channeldata.length > 0 && this.state.lichtData.length > 0 ) ? (<SubLijst ref="LichtLijst" id='LichtLijstRef' route='lichtlijst'  
        hdata={{route:'lichtlijst', data:this.state.lichtData, kol:this.state.lichtkol}} height={300} mhoog={300} breed={300} hoog={300} 
        sleutel={this.state.channeldata[this.state.selchan -1].id} behandel={false} zoeken={false} ctrlklik={this.CtrlKlik} klik={this.KlikAct} klikken={this.KlikKennisM}/>) : null;

      // bovenste paneel
    let PTitsocial = (this.state.edit == 0 && beeld > 749 ) ? 
                    (<h3 className="panel-title">
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col></h3>) :
                      (this.state.edit == 0 && beeld < 750 ) ? 
                    (<h3 className="panel-title">
                      <Col xs={4} md={4} sm={4} >
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                      </Col></h3>) : null;
    let PTitLicht = (<h3 className="panel-title">
                      <Col xs={3} md={3} sm={3} >
                        {icdel}
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                        {icont}
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col></h3>) ;
    let PTitedit =  (this.state.edit == 0 && beeld > 749 ) ? 
                    (<h3 className="panel-title">
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                      </Col>
                      <Col xs={3} md={3} sm={3} >
                        {icedit}
                      </Col></h3>) :
                      (this.state.edit == 0 && beeld < 750 ) ? 
                    (<h3 className="panel-title">
                      <Col xs={4} md={4} sm={4} >
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                      </Col>
                      <Col xs={4} md={4} sm={4} >
                        {icedit}
                      </Col></h3>) : null;    
    // Definitie knoppen

    let iceditadr = (App.Admin == 'GB' ) ? null : 
            (<IconButOver
            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
            iconClassName="fa fa-pencil-square-o" 
            tooltip="Aanpassen" 
            onMouseLeave={this.MouseLeave}
            onMouseEnter={this.MouseEnter}
            onClick={this.EditAdr}/>);

    let icdeladr = (App.Admin == 'GB' ) ? null : 
            (<IconButOver
            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
            iconClassName="fa fa-trash-o" 
            tooltip="Verwijderen" 
            onMouseLeave={this.MouseLeave}
            onMouseEnter={this.MouseEnter}
            onClick={this.DelAdr}/>);

    let icnewadr = (App.Admin == 'GB' ) ? null : 
            (<IconButOver
            style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
            iconClassName="fa fa-plus" 
            onMouseLeave={this.MouseLeave}
            onMouseEnter={this.MouseEnter}
            tooltip="Toevoegen" 
            onClick={this.EditAdr}/>);


    // einde definitie knoppen
    let PTitadr = (this.state.data.name.length > 1) ? 
      (<h3 className="panel-title"><span>{this.state.data.name} - {this.state.adrmanuf}</span>
          {iceditadr}
          {icdeladr}
        </h3>) : 
      (<h3 className="panel-title"><span></span>
          {icnewadr}
      </h3>);    

    let iceditchan = (App.Admin == 'GB' ) ? null : 
            ( <IconButOver
                  style={{display:'block', float:'right', marginLeft: '.5em', top:'-15px',fontSize:'80%'}}
                  iconClassName="fa fa-pencil-square-o" 
                  tooltip="Aanpassen" 
                  onMouseLeave={this.MouseLeave}
                  onMouseEnter={this.MouseEnter}
                  onClick={this.EditSlid}/>);



    let PTitchan = (this.state.data.name.length > 1 && this.state.channame.length > 1 ) ? (<h3 className="panel-title"><span>Channels ({this.state.channame}) </span>
                      {iceditchan}
                    </h3>) : 
                    (<h3 className="panel-title"><span>Channels </span></h3>) ;    


    let panlicht = (<Panel  header={PTitLicht} bsStyle="blok" defaultExpanded style={{background: '#fff', width:'100%'}}>
                      <Row className="show-grid">
                        <Col xs={12} md={12} sm={12} >
                           {lst_licht}
                        </Col>
                      </Row>
                    </Panel>);



 

    // sliders

    var slider1 = (this.state.slidvis1) ? (<div style={{display: 'flex',height: 300,flexDirection: 'row',justifyContent: 'space-around'}} id= 'slid1' tabindex='0' onFocus= {(event)=>this.SliderClick(1)} >
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol1}
            scolor={this.state.slidcol1}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax1}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onChange={this.verander} />
        </div>) : null;
    var slider2 = (this.state.slidvis2) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol2}
            scolor={this.state.slidcol2}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax2}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(2)}
            onChange={this.verander} />
        </div>) : null;
    var slider3 = (this.state.slidvis3) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol3}
            scolor={this.state.slidcol3}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax3}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(3)}
            onChange={this.verander} />
        </div>) : null;
    var slider4 = (this.state.slidvis4) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol4}
            scolor={this.state.slidcol4}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax4}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(4)}
            onChange={this.verander} />
        </div>) : null;
    var slider5 = (this.state.slidvis5) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol5}
            scolor={this.state.slidcol5}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax5}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(5)}
            onChange={this.verander} />
        </div>) : null;
    var slider6 = (this.state.slidvis6) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol6}
            scolor={this.state.slidcol6}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax6}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(6)}
            onChange={this.verander} />
        </div>) : null;
    var slider7 = (this.state.slidvis7) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol7}
            scolor={this.state.slidcol7}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax7}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(7)}
            onChange={this.verander} />
        </div>) : null;
    var slider8 = (this.state.slidvis8) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol8}
            scolor={this.state.slidcol8}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax8}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(8)}
            onChange={this.verander} />
        </div>) : null;
    var slider9 = (this.state.slidvis9) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol9}
            scolor={this.state.slidcol9}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax9}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(9)}
            onChange={this.verander} />
        </div>) : null;
    var slider10 = (this.state.slidvis10) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol10}
            scolor={this.state.slidcol10}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax10}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(10)}
            onChange={this.verander} />
        </div>) : null;
    var slider11 = (this.state.slidvis11) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol11}
            scolor={this.state.slidcol11}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax11}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(11)}
            onChange={this.verander} />
        </div>) : null;
    var slider12 = (this.state.slidvis12) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol12}
            scolor={this.state.slidcol12}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax12}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(12)}
            onChange={this.verander} />
        </div>) : null;
    var slider13 = (this.state.slidvis13) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol13}
            scolor={this.state.slidcol13}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax13}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(13)}
            onChange={this.verander} />
        </div>) : null;
    var slider14 = (this.state.slidvis14) ? (<div style={{display: 'flex',
        height: 300,
        flexDirection: 'row',
        justifyContent: 'space-around',}}>
        <Slider name="slider4" 
            style={{height: 200}} 
            axis="y" 
            bcolor={this.state.slidcol14}
            scolor={this.state.slidcol14}
            defaultValue={0} 
            min={0}
            max={this.state.slidmax14}
            step={1}
            onDragStop={this.dragstop} 
            onDragStart={this.dragstart} 
            onFocus={(event)=>this.SliderClick(14)}
            onChange={this.verander} />
        </div>) : null;
    // einde slieders

    let pan2deel2 =  (this.state.data.name.length > 1) ? 
        (<Row className="show-grid">
          {this.state.circpro}
                        <Panel  header={PTitchan} bsStyle="blok" defaultExpanded collapsible={this.state.collapsible} style={{marginTop: '0px', background: '#fff', width:'100%'}}>
                            <Row className="show-grid">
                              <Col xs={6} md={6} sm={6} style={{paddingRight: '0px'}} >
                                <Col xs={1} md={1} sm={1} >
                                  {slider1}
                                  {slidertxt1}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider2}
                                  {slidertxt2}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider3}
                                  {slidertxt3}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider4}
                                  {slidertxt4}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider5}
                                  {slidertxt5}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider6}
                                  {slidertxt6}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider7}
                                  {slidertxt7}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider8}
                                  {slidertxt8}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider9}
                                  {slidertxt9}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider10}
                                  {slidertxt10}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider11}
                                  {slidertxt11}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider12}
                                  {slidertxt12}
                                </Col>                             
                              </Col>                             
                              <Col xs={6} md={6} sm={6} style={{paddingLeft: '0px'}}>
                                <Col xs={1} md={1} sm={1} >
                                  {slider13}
                                  {slidertxt13}
                                </Col>                             
                                <Col xs={1} md={1} sm={1} >
                                  {slider14}
                                  {slidertxt14}
                                </Col>                             
                                <Col xs={10} md={10} sm={10} >
                                  {panlicht}
                                </Col>                             
                              </Col>                             
                            </Row>
                          </Panel>
                      </Row>) : null;

    let pan2 = (
                  <div id='cns_hfdpan' style={{marginBottom: '16px', marginTop:'70px', padding: '24px', wordWrap: 'break-word', boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2)', display: 'block', backgroundColor: '#fff', borderRadius: '2px',position: 'relative', listStyle: 'none', outline: '0', fontSize: '100%', verticalAlign: 'baseline', boxSizing: 'inherit',}}>
                     <div id='cns_hfdpan1' style={{margin:'0',padding:'0',border:'0',outline:'0',fontSize:'100%',verticalAlign:'baseline',background: '0 0', boxSizing: 'inherit', display: 'block'}}>
                       <div id='card_header1' style={{margin: '-85px auto 0', position: 'relative', width: 'auto'}}>
                            <div id='photo_header1' style={{backgroundColor:'rgba(47, 165, 199, 0.85)',  textAlign: 'center', border: '4px solid #fff', boxSizing: 'content-box', margin: 'auto', position: 'relative', boxShadow: 'inset 0 1.5px 3px 0 rgba(0,0,0,.15), 0 1.5px 3px 0 rgba(0,0,0,.15)',marginTop:'-65px',width:'128px',height:'128px',borderRadius:'50%'}}>
                            <div style={{width: '100%', height: '100%',backgroundColor: 'transparent',margin: '0 auto', position: 'relative', display: '-webkit-flex', display: '-ms-flexbox', display: 'flex', WebkitFlexDirection: 'column', marginTop:'10px',
                                    MsFlexDirection: 'column', flexDirection: 'column',WebkitJustifyContent: 'center', MsFlexPack: 'center', justifyContent: 'center', WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems: 'center', boxSizing: 'border-box', height: '88px', width: '88px', borderRadius: '50%'}}>
                              <div dir="ltr" style={{fontSize: '14px',fontWeight: '400',lineHeight: '16px;margin-bottom: 2px',color: 'white',width: '128px',height: 'auto', borderRadius: '50%', marginTop: '20px'}}>{this.state.data.name}</div>
                            </div>
                          </div>
                          <div id='card_header1' style={{left: '24px', position: 'absolute', top: '75px'}}>
                            {PTitsocial}
                          </div>
 
                          <div id='carbody1' style={{position: 'relative',marginBottom:'30px'}}>
                            <div style={{WebkitJustifyContent: 'center',MsFlexPack: 'center', justifyContent: 'center',display: '-webkit-flex', display: '-ms-flexbox',display: 'flex',WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems:'center'}}>
                              <h1 dir="ltr" style={{fontSize:'16px',fontWeight: '400',lineHeight: '19px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>{this.state.data.accountname}</h1>
                            </div>         
                            <div style={{WebkitJustifyContent: 'center',MsFlexPack: 'center', justifyContent: 'center',display: '-webkit-flex', display: '-ms-flexbox',display: 'flex',WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems:'center'}}>
                              <h2 dir="ltr" style={{fontSize:'12px',fontWeight: '400',lineHeight: '12px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>Visible Chan: {this.state.data.vischan}</h2>         
                            </div>         
                            <div style={{WebkitJustifyContent: 'center',MsFlexPack: 'center', justifyContent: 'center',display: '-webkit-flex', display: '-ms-flexbox',display: 'flex',WebkitAlignItems: 'center', MsFlexAlign: 'center',alignItems:'center'}}>
                              <h3 dir="ltr" style={{fontSize:'12px',fontWeight: '400',lineHeight: '12px', marginBottom: '2px', color: 'rgba(47,165,199,.85)'}}>Unvisible Chan: {this.state.data.unvischan}</h3>         
                            </div>         

                            <div style={{padding: '0 40px',marginBottom:'0'}}>
                              {pan2deel2}
                             </div>

                          </div>
                          <div id='card_header1' style={{right: '24px', position: 'absolute', top: '75px'}}>
                            {PTitedit}
                          </div>
                       </div>
                     </div>
                  </div>)
    // einde bovenste paneel
    // Gedeelte voor lezen documenten
  


    // einde enkel voor mobile
    tot_zicht2 = (
      <div className='rowlist' style={{marginLeft:'15px', marginBottom: '60px', marginRight: '10px'}}>
        {pan2}
      </div>);    

    tot_zichtpop = (<div>
            {this.state.popupchtxt}
            {this.state.popsubfixt}
            {this.state.popcopyfixt}
            {this.state.popadres}
            {this.state.popslider}
          </div>);
    
    // Einde opbouw 
    let voet = null;
    let totzicht = (this.props.popup) ? (<div id='consvdiv' style={{display:'block',maxHeight:maxhintern,overflowY:'scroll'}}>
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol, bottom:'60px'}}
          message={this.state.updatetxt}/>
        {tot_zichtpop}
        {tot_zicht2}
      </div>) : (<div id='consvdiv' style={{display:'block'}}>
        <Snackbar
          ref="snackbar"
          style={{backgroundColor: this.state.updatecol, bottom:'60px'}}
          message={this.state.updatetxt}/>
        {tot_zichtpop}
        {tot_zicht2}
      </div>)  ;

    // Einde opbouw 
    let terugwaarde = (this.props.popup) ? 
      (<Dialog 
        ref="dafwcontrole"
        title="Detail Fixture"
        second={true}
        sluiten={this.sluiten}
        onDismiss={this.zetsluiten}
        style={{fontSize: '100%', width:wdth, maxWidth:mwdth}}
        contentStyle={{width:cwdth, maxWidth:cmwdth, left:clft,maxHeight:maxhdial,overflowY:'visible'}}
        footer= {voet}
        id="actcont1"
        modal={true}
        hidetoolb={true}        
        notoolb={true}
        paddingtop={this.props.paddingtop}
        zindex={this.props.zindex}        
        openImmediately={false}
        repositionOnUpdate={false}>
        {totzicht}
      </Dialog>) : (<div>{totzicht}</div>);

    return (<div>{terugwaarde}</div>)
  },  
  // Algemene funkties
  sluiten(data){
    App.master.zethfdmen(1);
    if (this.props.Sluiten) {this.props.Sluiten(data)}
  },
  zetsluiten() {
    App.master.zethfdmen(1);
    if (this.props.Sluiten) {this.props.Sluiten()}
  },
  MouseLeave(){
    this.setState({collapsible:true});
  },
  MouseEnter(){
    this.setState({collapsible:false});
  },

  zetedit(skey){
    if (this.isMounted)
    {
      if (App.master.state.AppHfdMenKeuze)
      {
        if (App.master.state.AppHfdMenKeuze.FiltData) {   
          skey = App.master.state.AppHfdMenKeuze.FiltData[0].id;
        }
        App.master.setState({AppHfdMenKeuze:null});
      }
      if (this.refs.dafwcontrole)  {this.refs.dafwcontrole.show()}
      
      this.setState({seltxtcol:0,txtdata:[],adrname:'', selchan:0, channame:'', slidmaindata:[],selcol:0,slidtxt:'',lichtData:[],channeldata:[]});
      this.setState({sleutel:skey});
      this.setState({seltxtcol:0});
      ClsFixt.UitvSQL({id:skey},'GetAdres')
        .then(data => {
          this.setState({data:data});
          this.setState({channeldata:data.subdata});
          this.state.channeldata = data.subdata;
          let that = this;
          var promise = this.zetsliderdata(data.subdata);
          promise.then(function(result) { 
          });        
        })
        .catch(data => {
            return true;
      });
      this.setState({edit:0});
    }
  },

  zetsliderdata(data) {
    var deferred = $.Deferred();
    this.setState({
      sliddata1:[],
      sliddata2:[],
      sliddata3:[],
      sliddata4:[],
      sliddata5:[],
      sliddata6:[],
      sliddata7:[],
      sliddata8:[],
      sliddata9:[],
      sliddata10:[],
      sliddata11:[],
      sliddata12:[],
      sliddata13:[],
      sliddata14:[],
      selcol:0,
      slidvis1:false,
      slidvis2:false,
      slidvis3:false,
      slidvis4:false,
      slidvis5:false,
      slidvis6:false,
      slidvis7:false,
      slidvis8:false,                                          
      slidvis9:false,
      slidvis10:false,
      slidvis11:false,
      slidvis12:false,
      slidvis13:false,
      slidvis14:false,
      slidcol1:'rgb(47, 165, 199)',      
      slidcol2:'rgb(47, 165, 199)',      
      slidcol3:'rgb(47, 165, 199)',      
      slidcol4:'rgb(47, 165, 199)',      
      slidcol5:'rgb(47, 165, 199)',      
      slidcol6:'rgb(47, 165, 199)',      
      slidcol7:'rgb(47, 165, 199)',      
      slidcol8:'rgb(47, 165, 199)',      
      slidcol9:'rgb(47, 165, 199)',      
      slidcol10:'rgb(47, 165, 199)',      
      slidcol11:'rgb(47, 165, 199)',      
      slidcol12:'rgb(47, 165, 199)',      
      slidcol13:'rgb(47, 165, 199)',      
      slidcol14:'rgb(47, 165, 199)',      
      slidmax1:100,      
      slidmax2:100,      
      slidmax3:100,      
      slidmax4:100,      
      slidmax5:100,      
      slidmax6:100,      
      slidmax7:100,      
      slidmax8:100,      
      slidmax9:100,      
      slidmax10:100,      
      slidmax11:100,      
      slidmax12:100,      
      slidmax13:100,      
      slidmax14:100})

    for (let rowIdx = 0; rowIdx <= data.length; rowIdx++) 
    {
      let slidrow = data[rowIdx];
      let color = (slidrow.color == '#FFFFFF') ? 'rgb(47, 165, 199)' : slidrow.color;
      if (slidrow.steps == 0) {slidrow.steps = 1};
      switch (rowIdx) {
        case 0:

          this.setState({slidvis1:true,
            slidcol1:color,
            slidmax1:slidrow.steps -1,
            sliddata1:slidrow.subdata,});
          break;
        case 1:
          this.setState({slidvis2:true,
            slidcol2:color,
            slidmax2:slidrow.steps -1,
            sliddata2:slidrow.subdata});
          break;
        case 2:
          this.setState({slidvis3:true,
            slidcol3:color,
            slidmax3:slidrow.steps -1,
            sliddata3:slidrow.subdata});
          break;
        case 3:
          this.setState({slidvis4:true,
            slidcol4:color,
            slidmax4:slidrow.steps -1,
            sliddata4:slidrow.subdata});
          break;
        case 4:
          this.setState({slidvis5:true,
            slidcol5:color,
            slidmax5:slidrow.steps -1,
            sliddata5:slidrow.subdata});
          break;
        case 5:
          this.setState({slidvis6:true,
            slidcol6:color,
            slidmax6:slidrow.steps -1,
            sliddata6:slidrow.subdata});
          break;
        case 6:
          this.setState({slidvis7:true,
            slidcol7:color,
            slidmax7:slidrow.steps -1,
            sliddata7:slidrow.subdata});
          break;
        case 7:
          this.setState({slidvis8:true,
            slidcol8:color,
            slidmax8:slidrow.steps -1,
            sliddata8:slidrow.subdata});
          break;
        case 8:
          this.setState({slidvis9:true,
            slidcol9:color,
            slidmax9:slidrow.steps -1,
            sliddata9:slidrow.subdata});
          break;
        case 9:
          this.setState({slidvis10:true,
            slidcol10:color,
            slidmax10:slidrow.steps -1,
            sliddata10:slidrow.subdata});
          break;
        case 10:
          this.setState({slidvis11:true,
            slidcol11:color,
            slidmax11:slidrow.steps -1,
            sliddata11:slidrow.subdata});
          break;
        case 11:
          this.setState({slidvis12:true,
            slidcol12:color,
            slidmax12:slidrow.steps -1,
            sliddata12:slidrow.subdata});
          break;
        case 12:
          this.setState({slidvis13:true,
            slidcol13:color,
            slidmax13:slidrow.steps -1,
            sliddata13:slidrow.subdata});
          break;
        case 13:
          this.setState({slidvis14:true,
            slidcol14:color,
            slidmax14:slidrow.steps -1,
            sliddata14:slidrow.subdata});
          break;
      }
      if (rowIdx >= data.length -1)
      {
        return deferred.resolve('1');
      }      
    }
    return deferred.promise();    
  },
  SliderClick(waarde) {
    if (waarde != this.state.selchan)
    {
      this.setState({seltxtcol:0,selchan:waarde, channame:this.state.channeldata[waarde -1].name, slidmaindata:this.state.channeldata[waarde -1].subdata,selcol:0,slidtxt:'',lichtData:this.state.channeldata[waarde -1].subdata,});
      ClsFixt.UitvSQL({id:this.state.channeldata[waarde -1].id},'GetSlider')
        .then(data => {
          if (this.state.channeldata[waarde -1].subdata.length > 0)
          {
            this.setState({selchan:waarde, channame:this.state.channeldata[waarde -1].name, slidmaindata:this.state.channeldata[waarde -1].subdata,selcol:0,slidtxt:this.state.channeldata[waarde -1].subdata[0].fixture_text,lichtData:this.state.channeldata[waarde -1].subdata});
          }
          else
          {
            this.setState({selchan:waarde, channame:this.state.channeldata[waarde -1].name, slidmaindata:this.state.channeldata[waarde -1].subdata,selcol:0,slidtxt:'',lichtData:this.state.channeldata[waarde -1].subdata});
          }
          if (this.refs.LichtLijst)
          {
            this.refs.LichtLijst.ZetSelect(0,this.state.channeldata[waarde -1].subdata); 
          }
        })
        .catch(data => {
            return true;
      });
    }
  },

  dragstop(e, ui) {
    this.setState({dragstart:0});
  },
  dragstart(e, ui) {
    this.setState({dragstart:1});
  },
  verander(e,value){
      let sdat = this.state.lichtData;
      if (sdat.length == 0) { return };
      if (this.state.selcol > sdat.length - 1) {this.state.selcol = sdat.length - 1};
      let vdat = sdat[this.state.selcol];
      vdat.checked = false;
      sdat[this.state.selcol] = vdat;
      this.state.selcol = value;
      vdat = sdat[value];
      vdat.checked = true;
      sdat[this.state.selcol] = vdat;
      this.setState({lichtData:sdat});
      this.setState({slidmaindata:sdat});
      this.setState({slidtxt:vdat.fixture_text});
      if (this.refs.LichtLijst)
      {
        this.refs.LichtLijst.ZetSelect(value,sdat); 
      }
  },
  editsluiten(data) {
    this.setState({popupcons:null});
    if (this.props.popup)
    {
        App.master.zethfdmen(2);    
    }    
    if (this.props.loadDataNw && data) {this.props.loadDataNw(data,'CONS',this.state.activkey) }
  },
  txtsluiten(data) {
    this.setState({popupchtxt:null});
    if (this.props.popup)
    {
        App.master.zethfdmen(2);    
    }    
    this.sluitactpopup(data);
  },
  adressluiten(data,aanpassen) {
    this.setState({popadres:null});
    if (this.props.popup)
    {
        App.master.zethfdmen(2);    
    }    
    ClsFixt.UitvSQL({id:this.state.sleutel},'GetAdres')
      .then(data => {
        this.setState({data:data});
        if (this.state.aanpassen == 1)
        {
          if (this.props.loadDataNw && data) {this.props.loadDataNw(data,'CONS',this.state.activkey) }
        }
        this.state.aanpassen = 0;
        this.setState({channeldata:data.subdata});
        this.state.channeldata = data.subdata;
        let that = this;
        var promise = this.zetsliderdata(data.subdata);
        promise.then(function(result) { 
          that.forceUpdate();
        });        
      })
      .catch(data => {
          return true;
    });
  },
  EditSlid(selectedKey) {
    let hdata = this.state.channeldata[this.state.selchan - 1];
    this.setState({channame:'', slidmaindata:[],selcol:0,slidtxt:''});
    hdata.accountid = this.state.data.accountid;
    this.setState({popslider:(<PopupSlider  id='popupcons' data={hdata} zindex={999999} Sluiten={this.slidsluiten}></PopupSlider>)});
    return;
  },
  slidsluiten(data) {
    this.setState({popslider:null});
    if (this.props.popup)
    {
        App.master.zethfdmen(2);    
    }    
    ClsFixt.UitvSQL({id:this.state.sleutel},'GetAdres')
      .then(data => {
        this.setState({data:data});
        this.setState({channeldata:data.subdata});
        this.state.channeldata = data.subdata;
        let that = this;
        var promise = this.zetsliderdata(data.subdata);
        promise.then(function(result) { 
          that.forceUpdate();
          that.state.selchan = that.state.selchan - 1;
          that.SliderClick(that.state.selchan + 1);
        });        
      })
      .catch(data => {
          return true;
    });
  },
  EditAdr(selectedKey) {
    let hdata = this.state.data;
    this.state.aanpassen = 1;
    this.setState({popadres:(<PopupAdres  id='popupcons' data={hdata} zindex={999999} Sluiten={this.adressluiten}></PopupAdres>)});
    return;
  },
  DelAdr(selectedKey) {
    let hdata = this.state.adresdata[this.state.selectie - 1];
    ClsFixt.DelAdres(hdata)
      .then(data => {
          this.adressluiten(data);
      })
      .catch(data => {
          return true;
    });
    return;
  },
  DeleteClick() {
    let hudata = {data:this.state.txtdata}
    this.setState({lichtData:[]});    
    ClsFixt.DelSubFixture(hudata)
    .then(data => {
      this.sluitactpopup(hudata);
    })
    .catch(data => {
        return true;
    });
  },
  EditClick(selectedKey) {
    let hdata = {id:App.ConsId}; 
    this.setState({popupcons:(<PopupCons  id='popupcons' data={hdata} zindex={999999} Sluiten={this.editsluiten}></PopupCons>)})
    return;
  },
  TextClick() {
    this.setState({popupchtxt:(<PopupChTxt  id='PopupChTxt' data={this.state.txtdata} zindex={999999} Sluiten={this.txtsluiten}></PopupChTxt>)})
    return;
  },
  // Einde funkties popup openen
  // functies activiteiten
  sluitactpopup(data) {
    this.setState({popsubfixt:null});
    if (this.props.popup)
    {
        App.master.zethfdmen(2);    
    }    
    ClsFixt.UitvSQL({id:this.state.sleutel},'GetAdres')
      .then(data => {
        this.setState({channeldata:data.subdata});
        this.state.channeldata = data.subdata;
        let that = this;
        var promise = this.zetsliderdata(data.subdata);
        promise.then(function(result) { 
          that.forceUpdate();
          that.state.selchan = that.state.selchan - 1;
          that.SliderClick(that.state.selchan + 1);
        });        
      })
      .catch(data => {
          return true;
    });
  },
  CtrlKlik(data) {
    var index, column;    
    let arra;
    // controleren of arra && of || bevat
    let hudata = data;
    let ColFilter = ['checked'];
    let value = '2';
    var filteredRows = value ? data.filter(function(row){
      for (let rowIdx = 0; rowIdx < ColFilter.length; rowIdx++) 
        {
          let filCol = ColFilter[rowIdx];
          if (eval('row.'+filCol))
          {
            arra = eval('row.'+filCol);            
          }
          else
          {
            arra = ''; 
          }
          if (typeof arra === 'string' || arra instanceof String)
          {

          }
          else
          {
            arra='';
          }
          if(arra.indexOf(value) >= 0)
          {
            return arra.indexOf(value) >= 0
          }
        }
      }) : data;
      this.setState({seltxtcol:filteredRows.length});
      this.setState({txtdata:data});
  },
  KlikAct(waarde, data) {
    this.setState({laden:1});
    this.setState({popsubfixt:(<PopupSubFixt  id='popupact1' data={data} route = 'activiteit' zindex={999999} Sluiten={this.sluitactpopup} ></PopupSubFixt>)})
  },  

});
module.exports = Schemamainvorm;