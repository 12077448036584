const React = require('react');
const IconButton = require('./icon-button.jsx');
var Popover = require("react-bootstrap").Popover;
var OverlayTrigger = require("react-bootstrap").OverlayTrigger;
const SvgIcon = require('./svg-icon.jsx');




const IconButOver = React.createClass({
  _handleClick(e) {
      if (this.props.onClick) this.props.onClick(e);
  },
 _handleMouseLeave(e) {
    if (this.props.onMouseLeave) this.props.onMouseLeave(e);
  },

  _handleMouseEnter(e) {
    if (this.props.onMouseEnter) this.props.onMouseEnter(e);
  },


  render() {
    const {
      iconClassName,
      style,
      iconStyle,
      svgStyle,
      tooltip,
      tooltip_position,
      path,      
      ...other,
    } = this.props;

    const poptxt = (<Popover id='pope1'><p style={{fontSize:'80%'}}>{this.props.tooltip}</p></Popover>);
    const tooltip_pos = this.props.tooltip_position ? this.props.tooltip_position : 'bottom';
    const icstyle = this.props.iconStyle ? this.props.iconStyle : {color:'#008cc9', iconHoverColor:'darkslateblue',fontSize:'90%'}; 
    const tek1 = this.props.src ? (<img 
                    style={icstyle}
                    src={this.props.src} /> ) : null;
                      
    const svgim = this.props.path ? (<SvgIcon style={svgStyle}>
                    <path d={this.props.path}/>
                  </SvgIcon>) : null



    return (
      <OverlayTrigger trigger={['hover', 'focus']} placement={tooltip_pos} overlay={poptxt}>
        <IconButton                      
          style={this.props.style}
          iconClassName={this.props.iconClassName}
          iconStyle={icstyle}
          onClick={this._handleClick}
          onMouseEnter={this._handleMouseEnter}
          onMouseLeave={this._handleMouseLeave}
          >
          {tek1}
          {svgim}
        </IconButton>
        </OverlayTrigger>          
    );
  },
});

module.exports = IconButOver;
