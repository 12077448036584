'use strict'

function chain(where, fn, secondFn){

    return function(){
        if (where === 'before'){
            secondFn.apply(this, arguments)
        }

        var result = fn.apply(this, arguments)

        if (where !== 'before'){
            secondFn.apply(this, arguments)
        }

        return result
    }
}

module.exports = chain