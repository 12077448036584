const React = require('react');
const {
  Route,
  Redirect,
  IndexRoute,
} = require('react-router');

// Here we define all our material-ui ReactComponents.
const Master = require('./components/main.jsx');
const Leeg = require('./components/pages/leeg.jsx');
const Home = require('./components/pages/homenw.jsx');
const Login = require('./components/pages/loginnw.jsx');
const Lstcons = require('./components/pages/lstcons.jsx');
const Lstacc = require('./components/pages/lstacc.jsx');
const Lstfix = require('./components/pages/lstmain.jsx');
const Lstfixcons = require('./components/pages/lstmaincons.jsx');
const Lstfix2 = require('./components/pages/lstmain2.jsx');
const Lstschema = require('./components/pages/lstschema.jsx');
const Lstschemacons = require('./components/pages/lstschemacons.jsx');
const Lstlamp = require('./components/pages/lstlamp.jsx');
const LstlampCons = require('./components/pages/lstlampcons.jsx');
const Lstlibrary = require('./components/pages/lstlibrary.jsx');
const Homemen = require('./components/pages/homemen.jsx');

/**
 * Routes: https://github.com/rackt/react-router/blob/master/docs/api/components/Route.md
 *
 * Routes are used to declare your view hierarchy.
 *
 * Say you go to http://material-ui.com/#/components/paper
 * The react router will search for a route named 'paper' and will recursively render its
 * handler and its parent handler like so: Paper > Components > Master
 */
const AppRoutes = (
   <Route path="/" component={Master}>
    <Route path='gedaan' component={Master} />
    <Route path='home' component={Lstfix} />
    <Route path='homeg' component={Lstfix2} />
    <Route path='homec' component={Lstfixcons} />
    <Route path='schema' component={Lstschema} />
    <Route path='schemacons' component={Lstschemacons} />    
    <Route path='lampen' component={Lstlamp} />
    <Route path='libraries' component={Lstlibrary} />
    <Route path='lampencons' component={LstlampCons} />
    <Route path='user' component={Lstcons} />
    <Route path='manuf' component={Lstacc} />
    <Route path='fixt' component={Lstfix} />
    <Route path='agenda' component={Home} />
    <Route path='login' component={Login} />
    <Route path='leeg' component={Leeg} />
    <Route path='homemen' component={Homemen} />
    <IndexRoute component={Leeg}/>    
  </Route>
);

module.exports = AppRoutes;
