
// turn {x: {val: 1, config: [1, 2]}, y: 2} generated by
// `{x: spring(1, [1, 2]), y: 2}` into {x: 1, y: 2}

'use strict';

exports.__esModule = true;
exports['default'] = stripStyle;

function stripStyle(style) {
  var ret = {};
  for (var key in style) {
    if (!style.hasOwnProperty(key)) {
      continue;
    }
    ret[key] = style[key] == null || style[key].val == null ? style[key] : style[key].val;
  }
  return ret;
}

module.exports = exports['default'];