const React = require('react');
const ReactDOM = require('react-dom');
const WindowListenable = require('./mixins/window-listenable');
const CssEvent = require('./utils/css-event');
const KeyCode = require('./utils/key-code');
const Transitions = require('./styles/transitions');
const StylePropable = require('./mixins/style-propable');
const FlatButton = require('./flat-button.jsx');
const IconButton = require('./icon-button.jsx');

const Overlay = require('./overlay.jsx');
const Paper = require('./paper.jsx');
const TextField = require('./text-field.jsx');
const DefaultRawTheme = require('./styles/raw-themes/light-raw-theme');
const ThemeManager = require('./styles/theme-manager');
const PureRenderMixin = require('react-addons-pure-render-mixin');

const ReactTransitionGroup = require('react-addons-transition-group');

const TransitionItem = React.createClass({
  mixins: [StylePropable,PureRenderMixin],

  contextTypes: {
    muiTheme: React.PropTypes.object,
  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
  },

  getChildContext () {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

  getInitialState() {
    return {
      style: {},
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  //to update theme inside state whenever a new theme is passed down
  //from the parent / owner using context
  componentWillReceiveProps (nextProps, nextContext) {
    let newMuiTheme = nextContext.muiTheme ? nextContext.muiTheme : this.state.muiTheme;
    this.setState({muiTheme: newMuiTheme});
  },

  componentWillEnter(callback) {
    let spacing = this.state.muiTheme.rawTheme.spacing;
    let setspacing = (window.innerWidth < 500 || (window.innerHeight < 450)) ? 0 : spacing.desktopKeylineIncrement; 
    this.setState({
      style: {
        opacity: 1,
        transform: 'translate3d(0, ' + setspacing + 'px, 0)',
      },
    });

    setTimeout(callback, 450); // matches transition duration
  },

  componentWillLeave(callback) {
    this.setState({
      style: {
        opacity: 0,
        zIndex:this.props.zindex ? this.props.zindex : 65000,
        transform: 'translate3d(0, 0, 0)',
      },
    });

    setTimeout(() => {
      if (this.isMounted()) callback();
    }, 450); // matches transition duration
  },

  render() {
    let {
      style,
      ...other,
    } = this.props;

    return <div {...other} style={this.prepareStyles(this.state.style, style)}>
        {this.props.children}
      </div>;
  },
});

let Dialog = React.createClass({

  mixins: [WindowListenable, StylePropable],

  contextTypes: {
    muiTheme: React.PropTypes.object,
  },

  //for passing default theme context to children
  childContextTypes: {
    muiTheme: React.PropTypes.object,
  },

  getChildContext () {
    return {
      muiTheme: this.state.muiTheme,
    };
  },

  propTypes: {
    actions: React.PropTypes.array,
    autoDetectWindowHeight: React.PropTypes.bool,
    autoScrollBodyContent: React.PropTypes.bool,
    bodyStyle: React.PropTypes.object,
    contentClassName: React.PropTypes.string,
    contentStyle: React.PropTypes.object,
    modal: React.PropTypes.bool,
    openImmediately: React.PropTypes.bool,
    onClickAway: React.PropTypes.func,
    onDismiss: React.PropTypes.func,
    onShow: React.PropTypes.func,
    repositionOnUpdate: React.PropTypes.bool,
    title: React.PropTypes.node,
  },

  windowListeners: {
    keyup: '_handleWindowKeyUp',
    resize: '_positionDialog',
  },

  getDefaultProps() {
    return {
      autoDetectWindowHeight: false,
      autoScrollBodyContent: false,
      actions: [],
      modal: false,
      repositionOnUpdate: true,
    };
  },

  getInitialState() {
    return {
      open: this.props.openImmediately || false,
      muiTheme: this.context.muiTheme ? this.context.muiTheme : ThemeManager.getMuiTheme(DefaultRawTheme),
    };
  },

  //to update theme inside state whenever a new theme is passed down
  //from the parent / owner using context
  componentWillReceiveProps (nextProps, nextContext) {
    let newMuiTheme = nextContext.muiTheme ? nextContext.muiTheme : this.state.muiTheme;
    this.setState({muiTheme: newMuiTheme});
  },

  componentDidMount() {
          let ThisDom = ReactDOM.findDOMNode(this);
//      alert(ThisDom.style.height);

    this._positionDialog();
    if (this.props.openImmediately) {
      this.refs.dialogOverlay.preventScrolling();
      this._onShow();
    }
  },

  componentDidUpdate() {
    this._positionDialog();
  },

  getStyles() {
    let spacing = this.state.muiTheme.rawTheme.spacing;
    let scroll_top = $(window).scrollTop();
    if (scroll_top > 150) {scroll_top = 50}

//    alert(scroll_top);
    let mtop = '0px';
    let paddingTop = '0px';
    let marginTop = (screen.width < 750 && this.props.second) ? '50px' : 
                    (screen.width < 750 && this.props.third) ? '100px' :'0px';           

    let beeld = screen.width;
    let beeldh = screen.height;

    let main = {
      position: 'fixed',
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      zIndex: this.props.zindex ? this.props.zindex : 65000,
      marginTop:marginTop,
      paddingTop:paddingTop,
      top: 0,
      left: -10000,
      width: '100%',
      height: '100%',
      transition: Transitions.easeOut('0ms', 'left', '450ms'),
    };

    let content = {
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)',
      transition: Transitions.easeOut(),
      position: 'relative',
      width: (beeld < 750) ? '100%':'75%',
//      maxWidth: spacing.desktopKeylineIncrement * 12,
      margin: '0 auto',
      zIndex: this.props.zindex ? this.props.zindex : 65000,
    };

    let body = (beeld < 750) ? {
      padding: spacing.desktopGutter,
      overflowY: this.props.overflow ? this.props.overflow :
                 this.props.autoScrollBodyContent ? 'auto' : 'hidden',
      overflowX: 'hidden',
      paddingBottom: '60px',
      paddingLeft:'0px',
      paddingRight:'0px',
      }:{
      padding: spacing.desktopGutter,
      overflowY: this.props.overflow ? this.props.overflow :
                 this.props.autoScrollBodyContent ? 'auto' : 'hidden',
      overflowX: 'hidden',
      paddingBottom: '60px',
      };

    let gutter = spacing.desktopGutter + 'px ';
    let title = {
        margin: 0,
        paddingTop:'10px',
        paddingLeft:'10px',
        paddingBottom:'10px',
        color: 'white',
        fontSize: (beeld < 750) ? 18:24,
        lineHeight: '32px',
        fontWeight: '400',
        backgroundColor: 'rgb(0, 143, 183)',
        boxShadow:'0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22)',        
    };

    let margfoot = '0px';
    if (beeld < 750)
    {
      if (this.props.second)
      {
        margfoot = '50px';
      }
      if (this.props.third)
      {
        margfoot = '100px';
      }

    }
    let footer = {
      margin:0,
      paddingTop:'10px',
      paddingLeft:'10px',
      paddingBottom:'10px',
      color:'white',
      lineHeight:'32px',
      bottom:margfoot,
      position:'absolute',
      width: '100%',
      borderTop: 'rgba(0, 143, 183, .8)',
      borderTopStyle: 'solid',
      borderTopWidth: 'thin',    };


    if (this.state.open) {
      main = this.mergeStyles(main, {
        left: 0,
        transition: Transitions.easeOut('0ms', 'left', '0ms'),
      });
    }

    return {
      main: this.mergeStyles(main, this.props.style),
      content: this.mergeStyles(content, this.props.contentStyle),
      paper: {
        background: this.state.muiTheme.rawTheme.palette.canvasColor,
      },
      body: this.mergeStyles(body, this.props.bodyStyle),
      title: this.mergeStyles(title, this.props.titleStyle),
    };
  },

  render() {
    let styles = this.getStyles();
    let beeld = screen.width;
    let beeldh = screen.height;

    if (beeld < 750)
    {
      if (this.props.second)
      {
        styles.main.height = (beeldh) +'px';
        styles.main.maxHeight = (beeldh) +'px';
        styles.main.marginTop = '50px';
        styles.main.transform = 'translate3d(0, 0px, 0)';
        styles.content.height = (beeldh) +'px';
        styles.paper.height = (beeldh) +'px';
        if (this.props.third)
        {
          styles.main.height = (beeldh -100) +'px';
          styles.main.maxHeight = (beeldh -100) +'px';
          styles.main.marginTop = '50px';
          styles.main.transform = 'translate3d(0, 0px, 0)';
          styles.content.height = (beeldh -100) +'px';
          styles.paper.height = (beeldh -100) +'px';
        }


      }
      else
      {
        styles.main.height = (beeldh) +'px';
        styles.content.height = (beeldh) +'px';
        styles.paper.height = (beeldh) +'px';
        styles.main.marginTop='0px';
        styles.main.paddingTop='0px';
      }

      
      styles.main.width = '100%';
      styles.content.width = '100%';
      styles.paper.width = '100%';
    }
    else
    {
      if (this.props.paddingtop)
      {
        styles.main.marginTop='0px';
        styles.main.paddingTop=this.props.paddingtop;
        styles.content.marginTop='auto';

      }
    }
    let actions = this._getActionsContainer(this.props.actions);
    let title, vld_zoek;
    if (this.props.title) {
      // If the title is a string, wrap in an h3 tag.
      // If not, just use it as a node.
      title = (Object.prototype.toString.call(this.props.title) === '[object String]' && this.props.sluiten) ?
        (<h3 style={this.prepareStyles(styles.title)}>{this.props.title}
          <IconButton
            style={{display:'block', float:'right', marginLeft: '.5em',marginTop:'-10px'}}
            iconClassName="fa fa-times" 
            iconStyle={{color:'white', iconHoverColor:'gray',fontSize:'small'}}
            onClick={this.sluiten}/> </h3>) : (Object.prototype.toString.call(this.props.title) === '[object String]') ?
        (<h3 style={this.prepareStyles(styles.title)}>{this.props.title}</h3>) : this.props.title ;
    }
    let footer = (this.props.footer) ? this.props.footer : null;
    if (beeld < 750 && this.props.rbutgroep)
    {
      footer = this.props.rbutgroep;
    }    

    return (
      <div ref="container" style={this.prepareStyles(styles.main)}>
        <ReactTransitionGroup component="div" ref="dialogWindow">
          {this.state.open &&
            <TransitionItem
              className={this.props.contentClassName}
              style={styles.content}>
              <Paper
                style={styles.paper}
                zDepth={4}>
                {title}
                <div ref="dialogContent" style={this.prepareStyles(styles.body)}>
                  {this.props.children}
                </div>
                {actions}
                {footer}
            </Paper>
          

          </TransitionItem>}
        </ReactTransitionGroup>
        <Overlay
          ref="dialogOverlay"
          show={this.state.open}
          autoLockScrolling={false}
          onClick={this._handleOverlayTouchTap} />

      </div>
    );
  },

  isOpen() {
    return this.state.open;
  },

  dismiss() {
    CssEvent.onTransitionEnd(ReactDOM.findDOMNode(this), () => {
      this.refs.dialogOverlay.allowScrolling();
    });
    this.setState({ open: false });
    this._onDismiss();
  },

  show() {
    this.refs.dialogOverlay.preventScrolling();
    if (App.master && this.props.hidetoolb) 
    {
      App.master.zethfdmen(0);
    }
    this.setState({ open: true }, this._onShow);
  },

  _getAction(actionJSON, key) {
    let styles = {marginRight: 8};
    let props = {
      key: key,
      secondary: true,
      onClick: actionJSON.onClick,
      onTouchTap: () => {
        if (actionJSON.onTouchTap) {
          actionJSON.onTouchTap.call(undefined);
        }
        if (!(actionJSON.onClick || actionJSON.onTouchTap)) {
          this.dismiss();
        }
      },
      label: actionJSON.text,
      style: styles,
    };
    if (actionJSON.ref) {
      props.ref = actionJSON.ref;
      props.keyboardFocused = actionJSON.ref === this.props.actionFocus;
    }
    if (actionJSON.id) {
      props.id = actionJSON.id;
    }

    return (
      <FlatButton
        {...props} />
    );
  },

  _getActionsContainer(actions) {
    let actionContainer;
    let padbottom = (window.innerWidth < 500 || window.innerHeight < 450) ? '40px' : '10px';
    let actionObjects = [];
    let actionStyle = {
        boxSizing: 'border-box',
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        padding: 8,
        marginBottom: 8,
        width: '100%',
        position:'absolute',
        bottom:'10px',
        paddingBottom:padbottom,
        textAlign: 'right',
      };


    if (screen.width < 750)
    {
      actionStyle = {
        boxSizing: 'border-box',
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        height:'40px',
        bottom:'40px',
        paddingBottom:'40px',       
        width: '100%',
        position:'absolute',
        textAlign: 'right',
      };

    }
    else
    {
      actionStyle = {
        boxSizing: 'border-box',
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        padding: 8,
        marginBottom: 8,
        width: '100%',
        position:'absolute',
        bottom:'10px',
        paddingBottom:padbottom,
        textAlign: 'right',
      };
    }

    if (actions.length) {
      for (let i = 0; i < actions.length; i++) {
        let currentAction = actions[i];

        //if the current action isn't a react object, create one
        if (!React.isValidElement(currentAction)) {
          currentAction = this._getAction(currentAction, i);
        }

        actionObjects.push(currentAction);
      }

      actionContainer = (
        <div style={this.prepareStyles(actionStyle)}>
          {actionObjects}
        </div>
      );
    }

    return actionContainer;
  },

  _positionDialog() {
    if (this.state.open) {
      let clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      let container = ReactDOM.findDOMNode(this);
      let dialogWindow = ReactDOM.findDOMNode(this.refs.dialogWindow);
      let dialogContent = ReactDOM.findDOMNode(this.refs.dialogContent);
      let minPaddingTop = 16;
      //Reset the height in case the window was resized.
      dialogWindow.style.height = '';
      dialogContent.style.height = '';

      let dialogWindowHeight = dialogWindow.offsetHeight;
      let dialogContentHeight = dialogContent.offsetHeight;

//      alert(dialogWindowHeight);
      let paddingTop = ((clientHeight - dialogWindowHeight) / 2) - 64;
      if (this.props.VHoog)
      {

      }
      if (paddingTop < minPaddingTop) paddingTop = minPaddingTop;

      //Vertically center the dialog window, but make sure it doesn't
      //transition to that position.
      if (this.props.repositionOnUpdate || !container.style.paddingTop) {
        container.style.paddingTop = paddingTop + 'px';
      }

      if (window.innerWidth < 500 || window.innerHeight < 450)
      {
        container.style.paddingTop = '0px';
        let MargTop = ((dialogWindowHeight - dialogContentHeight) / 2) - 75;
        dialogContent.style.marginTop = '0px';
        dialogContent.style.maxHeight = '80%';       
        dialogContent.style.overflowY = 'auto';       

      }
      else
      {
        if (this.props.paddingtop && screen.width > 749)        
        {
          paddingTop = 0;
          container.style.paddingTop = '0px';
          let MargTop = ((dialogWindowHeight - dialogContentHeight) / 2) - 75;
          dialogContent.style.marginTop = '-20px';
        }
      }





      // Force a height if the dialog is taller than clientHeight
      if (this.props.autoDetectWindowHeight || this.props.autoScrollBodyContent) {
        let styles = this.getStyles();
        let maxDialogContentHeight = clientHeight - 2 * (styles.body.padding + 64);

        if (this.props.title) maxDialogContentHeight -= dialogContent.previousSibling.offsetHeight;
        if (this.props.actions.length) maxDialogContentHeight -= dialogContent.nextSibling.offsetHeight;

        dialogContent.style.maxHeight = maxDialogContentHeight + 'px';
      }
    }
  },

  _onShow() {
    if (App.master && this.props.hidetoolb) 
    {
      App.master.zethfdmen(0);
    }
    
    if (this.props.onShow) this.props.onShow();
  },

  sluiten() {
    if (App.master && this.props.hidetoolb) 
    {
      App.master.zethfdmen(1);
    }
    if (this.props.sluiten) this.props.sluiten();
  },


  _onDismiss() {
    if (this.props.onDismiss) {this.props.onDismiss()};
  },

  _handleOverlayTouchTap(e) {
    if (this.props.modal) {
      e.stopPropagation();
    }
    else {
      this.dismiss();
      if (this.props.onClickAway) this.props.onClickAway();
    }
  },

  _handleWindowKeyUp(e) {
    if (e.keyCode === KeyCode.ESC && !this.props.modal) {
      this.dismiss();
    }
  },

});

module.exports = Dialog;
